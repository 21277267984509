import translate from "../../helpers/translate";

export default [
  {
    label: translate('data_filtering_profiles.alert'),
    value: 'alert'
  },
  {
    label: translate('data_filtering_profiles.block'),
    value: 'block'
  }
];