/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../helpers/translate';
import ConfidenceLevels from './ConfidenceLevels';
import { regexArrayToString } from './regexTransform';

function t(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}

const DataPatternInfo = ({pattern, changedObj, change}) => {
  const {supported_confidence_levels, detection_technique, type, regexes, metadataCriteria, delimiter } = pattern;
  const regexStrings = regexArrayToString(regexes, detection_technique, delimiter).split('\n');

  return(
    <div className='tableContainer'>
      <div className='form-label'>{t('name')}</div>
      <div className={`form-value tw-mb-3 ${change && pattern.name !== changedObj.name ? change : ''}`}>{pattern.name}</div>

      <div className='form-label'>{t('type')}</div>
      <div className={`form-value tw-mb-3 ${change && pattern.type !== changedObj.type ? change : ''}`}>{t(pattern.type)}</div>

      <div className='form-label'>{t('description')}</div>
      <div className={`form-value tw-mb-3 ${change && pattern.description !== changedObj.description ? change : ''}`}>{pattern.description}</div>

      { type === 'custom' &&
        <>
          <div className='form-label'>{t('regular_expression')}</div>
          <div className={`form-value tw-mb-3 ${change && regexes !== changedObj.regexes ? change : ''}`}>
          {
            regexStrings.map((s, i) => (
              <div key={i} className='regex-row'>{s}</div>
            ))
          }
          </div>
          <div className='form-label'>{t('proximity_keywords')}</div>
          <div className={`form-value tw-mb-3 ${change && pattern.proximity_keywords !== changedObj.proximity_keywords ? change : ''}`}>
            {pattern.proximity_keywords? pattern.proximity_keywords.join(', '): ''}
          </div>
        </>
      }

      {
        type === 'file_property' &&
        <>
          <div className='form-label'>{t('file_properties')}</div>
          <table className='form-value file-properties tw-mb-3'>
            <thead>
              <tr>
                <th>{t('file_property_type')}</th>
                <th>{t('name')}</th>
                <th>{t('value')}</th>
              </tr>
            </thead>
            <tbody>
              {
                metadataCriteria.map((r, i) => (
                  <tr key={i}>
                    <td className={`form-value tw-mb-3 ${change && r.type !== changedObj.metadataCriteria[i]?.type ? change : ''}`}>{r.type}</td>
                    <td className={`form-value tw-mb-3 ${change && r.name !== changedObj.metadataCriteria[i]?.name ? change : ''}`}>{r.name}</td>
                    <td className={`form-value tw-mb-3 ${change && r.value !== changedObj.metadataCriteria[i]?.value ? change : ''}`}>{r.value}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </>
      }

      <ConfidenceLevels supported_confidence_levels={supported_confidence_levels}
        detection_technique={detection_technique}/>

    </div>
  )
}

DataPatternInfo.propTypes = {
  pattern: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    detection_technique: PropTypes.string,
    type: PropTypes.string,
    regexes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
    metadataCriteria: PropTypes.array,
    delimiter: PropTypes.string,
    proximity_keywords: PropTypes.arrayOf(PropTypes.string),
    supported_confidence_levels: PropTypes.arrayOf(PropTypes.string)
  }),
  changedObj: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    detection_technique: PropTypes.string,
    type: PropTypes.string,
    regexes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
    metadataCriteria: PropTypes.array,
    delimiter: PropTypes.string,
    proximity_keywords: PropTypes.arrayOf(PropTypes.string),
    supported_confidence_levels: PropTypes.arrayOf(PropTypes.string)
  }),
  change: PropTypes.string,
};

DataPatternInfo.defaultProps = {
  pattern: {},
  changedObj: {},
  change: undefined,
}
export default DataPatternInfo