import translate from '../../helpers/translate';

const logSeverities = [
  {
    label: translate('data_filtering_profiles.informational'),
    value: 'informational'
  },
  {
    label: translate('data_filtering_profiles.critical'),
    value: 'critical'
  },
  {
    label: translate('data_filtering_profiles.high'),
    value: 'high'
  },
  {
    label: translate('data_filtering_profiles.medium'),
    value: 'medium'
  },
  {
    label: translate('data_filtering_profiles.low'),
    value: 'low'
  }
];

export default logSeverities;