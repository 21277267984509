import React from "react";
import { fireEvent, render, screen, waitFor, within } from '@testing-library/react';
import { act } from 'react-dom/test-utils';
import '@testing-library/jest-dom';
import sinon from 'sinon';
import { expect as chaiExpect } from 'chai';
import { IntlProvider } from "react-intl";

import BulkTable from "./BulkTable";
import { tableHeaders } from '../DocumentTypes/components/TableData';
import {
  DOCUMENT_TYPES_COL_LAST_UPDATED_TIME,
  ASC
} from '../constants';

describe('<BulkTable>', () => {
  let sandbox;
  const data = [
    { id: '111', name: 'Profile A', profile_type: 'Custom', type: 'Basic', updated_at: 'Apr 7 2021 at 15:23', updated_by: 'Enterprise DLP App' },
    { id: '112', name: 'Profile B', profile_type: 'Predefined', type: 'Advanced', updated_at: 'Apr 1 2021 at 8:00', updated_by: 'Prisma Access' },
    { id: '113', name: 'Profile C', profile_type: 'Custom - EDM', type: 'Advanced', updated_at: 'Apr 8 2021 at 2:00', updated_by: 'Panorama' },
    { id: '114', name: 'Profile D', profile_type: 'Custom', type: 'Basic', updated_at: 'Apr 6 2021 at 9:42', updated_by: 'SaaS Security' },
    { id: '115', name: 'Profile E', profile_type: 'Predefined', type: 'Basic', updated_at: 'Apr 3 2021 at 16:53', updated_by: 'Enterprise DLP App' }
  ];
  const columns = [
    { header: 'Data Profile', accessor: 'name', id: 'name' },
    { header: 'Mode', accessor: 'profile_type' },
    { header: 'Type', accessor: 'type' },
    { header: 'Last Modified', accessor: 'updated_at' },
    { header: 'Last Updated By', accessor: 'updated_by' }
  ];
  const filters = {
    profile_type: [
      {id: 'custom', name: 'Custom'},
      {id: 'edm', name: 'Custom - EDM'}
    ],
    filterable: true,
    currentFilters: [],
    filterOptions: [
      { name: 'Mode', id: 'profile_type', multiple: true },
      { name: 'Type', id: 'type', multiple: true }
    ],
    currentFilterSelections: {
      profile_type:  [
        {id: 'custom', name: 'Custom'},
      ]
    }
  };
  const updateState = jest.fn();
  const updateFilter = jest.fn();
  const updateSortControls = jest.fn();
  let testUtils;

  const renderView = () => (
    render(
      <BulkTable
        data={data}
        defaultSortBy={'name'}
        defaultSortOrder={'asc'}
        columns={columns}
        selectedItems={[]}
        pageCount={10}
        currentPage={1}
        totalCount={5}
        totalPages={1}
        requestResults={(input) => this.setState(input)}
        updateState={updateState}
        updateFilters={updateFilter}
        updateSortControls={updateSortControls}
        actionable={
          {
            editable: () => true,
            cloneable: () => true,
          }
        }
        selectable
        sortable
        false
        filters={filters}
        sort={{asc: true, field: 'name'}}
      />
    )
  );

  beforeEach(() => {
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    sandbox.restore();
  });

  test('should select all rows', () => {
    testUtils = renderView();

    jest.spyOn($.fn, 'prop').mockImplementation(() => true);
    const check = testUtils.container.querySelector(`th input[type='checkbox']`);
    fireEvent.click(check);

    expect(updateState).toHaveBeenCalledWith({selectedItems: ['111','112','113','114','115']});
    fireEvent.click(check);
  });

  test('should select one row', () => {
    testUtils = renderView();

    $('td input#111').prop('checked', true);
    const check = testUtils.container.querySelector('input[id="111"]');
    fireEvent.click(check);

    expect(updateState).toHaveBeenCalledWith({selectedItems: ['111']});
  });

  test('should update sort', () => {
    testUtils = renderView();

    const sortSpan = testUtils.container.querySelector('th.name span');
    fireEvent.click(sortSpan);
    expect(updateSortControls).toBeCalledWith({sortBy: 'name', sortOrder: 'dsc'});
  });

  test('should hide filters dropdown', async() => {
    testUtils = renderView();

    const filterIcon = testUtils.container.querySelector('.filtersTrigger img');
    fireEvent.click(filterIcon);
    const dropdown = testUtils.container.querySelector('.optionListContainer');
    await waitFor(() => expect(dropdown).toBeVisible());
    const profileTypeCheck = within(dropdown).getByText('Mode');
    fireEvent.click(profileTypeCheck);
    jest.spyOn($.fn, 'is').mockReturnValue(true);
    const table = testUtils.container.querySelector('.table');
    fireEvent.click(table);
    await waitFor(() => expect(testUtils.container.querySelector('.optionListContainer')).not.toBeVisible());
  });

  test('should hide action dropdown', async() => {
    testUtils = renderView();

    const actionBtn = testUtils.container.querySelector('.actions .actionBtn0');
    fireEvent.click(actionBtn);
    jest.spyOn($.fn, 'is').mockReturnValue(true);
    const nameCell = testUtils.getByText('Profile B');
    fireEvent.click(nameCell);
    await waitFor(() => expect(testUtils.container.querySelector('.actions .actionMenu0')).not.toBeVisible());
  });

  test('should include edit and clone buttons when actionable', async() => {
    testUtils = renderView();

    const actionBtn = testUtils.container.querySelector('.actions .actionBtn0');
    fireEvent.click(actionBtn);
    expect(testUtils.container.querySelector('.actionMenu .edit')).toBeVisible();
    expect(testUtils.container.querySelector('.actionMenu .clone')).toBeVisible();
  });

  test('should disable action button when function present but result is false', async() => {
    testUtils = render(
      <BulkTable
        data={data}
        columns={columns}
        selectedItems={[]}
        pageCount={10}
        currentPage={1}
        totalCount={5}
        totalPages={1}
        requestResults={(input) => this.setState(input)}
        updateState={updateState}
        updateFilters={updateFilter}
        updateSortControls={updateSortControls}
        actionable={
          {
            editable: () => false,
            cloneable: () => false,
          }
        }
        selectable
        false
        filters={filters}
        sort={{asc: true, field: 'name'}}
      />
    );
    const actionBtn = testUtils.container.querySelector('.actions .actionBtn0');
    fireEvent.click(actionBtn);
    expect(testUtils.container.querySelector('.actionMenu .edit.disabled')).toBeVisible();
    expect(testUtils.container.querySelector('.actionMenu .clone.disabled')).toBeVisible();
  });

  test('should not show clone button when function is not provided', async() => {
    testUtils = render(
      <BulkTable
        data={data}
        columns={columns}
        selectedItems={[]}
        pageCount={10}
        currentPage={1}
        totalCount={5}
        totalPages={1}
        requestResults={(input) => this.setState(input)}
        updateState={updateState}
        updateFilters={updateFilter}
        updateSortControls={updateSortControls}
        actionable={
          {
            editable: () => false,
          }
        }
        selectable
        false
        filters={filters}
        sort={{asc: true, field: 'name'}}
      />
    );
    const actionBtn = testUtils.container.querySelector('.actions .actionBtn0');
    fireEvent.click(actionBtn);
    expect(testUtils.container.querySelector('.actionMenu .edit.disabled')).toBeVisible();
    expect(testUtils.container.querySelector('.actionMenu .clone')).toBeNull();
  });

  test('toggleOptions', async() => {
    filters.currentFilters = [filters.filterOptions[0]];
    testUtils = render(
      <BulkTable
        data={data}
        columns={columns}
        selectedItems={['111', '114']}
        pageCount={10}
        currentPage={1}
        totalCount={5}
        totalPages={1}
        requestResults={(input) => this.setState(input)}
        updateState={updateState}
        updateFilters={updateFilter}
        updateSortControls={updateSortControls}
        actionable={
          {
            editable: () => true,
            cloneable: () => true,
          }
        }
        selectable
        false
        filters={filters}
        sort={{asc: true, field: 'name'}}
      />
    );
    const filterIcon = testUtils.container.querySelector('.filtersTrigger img');
    fireEvent.click(filterIcon);
    await waitFor(() => expect(testUtils.container.querySelector('.optionListContainer')).toBeVisible());
    const modeCheckbox = testUtils.container.querySelector('.filtersToSelect li input');
    fireEvent.click(modeCheckbox);
    const filterBtn = testUtils.container.querySelector('.selectedFilters .btn-link');
    fireEvent.click(filterBtn);
    jest.runAllTimers();
    const searchBox = testUtils.container.querySelector('.profile_type .optionListContainer input.search');
    fireEvent.focus(searchBox);
    fireEvent.input(searchBox, { target: { value: 'Cust' } });
    const valueCheckbox = testUtils.container.querySelector('.selectedFilters li input');
    fireEvent.click(valueCheckbox);
    jest.spyOn($.fn, 'is').mockReturnValue(true);
    const table = testUtils.container.querySelector('.table');
    fireEvent.click(table);

    await waitFor(() => expect(testUtils.container.querySelector('.profile_type .optionListContainer')).not.toBeVisible());
  });

  test('Remove filter selection', () => {
    testUtils = renderView();

    const deleteIcon = testUtils.container.querySelector('.selectedFilters .profile_type .filterName svg');
    fireEvent.click(deleteIcon);
    expect(updateFilter).toHaveBeenCalled();
  })

  test('should render with new views with actionHandlers', async() => {
    const isRowClickEnabledStub = sandbox.stub();
    isRowClickEnabledStub.returns(true);

    const isEditEnabledStub = sandbox.stub();
    isEditEnabledStub.returns(true);

    const isTestEnabledStub = sandbox.stub();
    isTestEnabledStub.returns(true);

    const isDeleteEnabledStub = sandbox.stub();
    isDeleteEnabledStub.returns(true);

    const onRowClickStub = sandbox.stub();
    const onEditStub = sandbox.stub();
    const onTestStub = sandbox.stub();
    const onDeleteStub = sandbox.stub();

    render(
      <IntlProvider>
      <BulkTable
        data={[{
          category: 'category',
          description: 'description',
          fingerprint_object_path: 'fingerprint_object_path',
          id: 666,
          name: 'name',
          original_file_name: 'original_file_name',
          original_file_size_in_byte: 'original_file_size_in_byte',
          status_note: 'status_note'
        }]}
        defaultSortBy={DOCUMENT_TYPES_COL_LAST_UPDATED_TIME}
        defaultSortOrder={ASC}
        columns={tableHeaders}
        pageData={{
          currentPage: 0,
          pageCount: 10,
          totalCount: 1,
          totalPages: 1
        }}
        requestResults={(input) => this.setState(input)}
        updateSortControls={updateSortControls}
        selectable={false}
        actionHandlers={{
          isRowClickEnabled: isRowClickEnabledStub,
          isEditEnabled: isEditEnabledStub,
          isTestEnabled: isTestEnabledStub,
          isDeleteEnabled: isDeleteEnabledStub,
          onRowClick: onRowClickStub,
          onEdit: onEditStub,
          onTest: onTestStub,
          onDelete: onDeleteStub
        }}
      />
      </IntlProvider>
    );

    await act(async () => {
      fireEvent.click(screen.queryByTestId('row-action-menu-0'));
    });

    await act(async () => {
      fireEvent.click(screen.queryByTestId('row-clickable-666'));
      fireEvent.click(screen.queryByTestId('row-action-edit-666'));
      fireEvent.click(screen.queryByTestId('row-action-delete-666'));
      fireEvent.click(screen.queryByTestId('row-action-test-666'));
    });

    chaiExpect(isRowClickEnabledStub.called).to.be.true;
    chaiExpect(isEditEnabledStub.called).to.be.true;
    chaiExpect(isTestEnabledStub.called).to.be.true;
    chaiExpect(isDeleteEnabledStub.called).to.be.true;
    chaiExpect(onRowClickStub.called).to.be.true;
    chaiExpect(onEditStub.called).to.be.true;
    chaiExpect(onTestStub.called).to.be.true;
    chaiExpect(onDeleteStub.called).to.be.true;
  });
});