import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Tooltip, Popover, CardBody, Button } from '@panwds/react-ui';
import { FaFileDownload } from 'react-icons/fa';
import $ from 'jquery';
import translate from '../../helpers/translate';
import toastr from '../../helpers/toastr';

import {
  SFTP,
  AZURE,
  AWS
} from '../constants';

import './styles.scss';

const moment = require('moment-timezone');

function t(scope, options) {
  return translate(`incidents.show.report_card.${scope}`, options);
}

const ReportCard = ({ incident, downloadURL, userRole }) => {
  const [connectionType, setConnectionType] = useState();
  const [fileLocationInfo, setFileLocationInfo] = useState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(true);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isEmpty = (detailObj) => {
    for (const key in detailObj) {
      if(detailObj[key])
        return false;
    }

    return true;
  }

  const META_DATA_TO_DISPLAY = ['app_details', 'device_details'];

  const renderMetadata = (header, metadata) => {
    if (!isEmpty(metadata) && !(metadata instanceof Array)) {
      return (
        <div key={`metadata-${header}`}>
          <div className='subHeader'>{t(header)}</div>
          <table className='table table-borderless table-condensed metadata'>
            <tbody>
              {
                Object.keys(metadata).map(key => {
                  if (metadata[key]) {
                    return (
                      <tr key={`${header}-${key}`}>
                        <td className='detailCategory'>{t(key)}</td>
                        <td>{metadata[key]}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
        </div>
      );
    }
  }

  const getDestinationIP = (metadata) => {
    if (metadata && metadata['headers']) {
      for (const header of metadata['headers']) {
        if (header['attribute_name'] && header['attribute_name'].toLowerCase() === 'destinationip') {
          return header['attribute_value'];
        }
      }
    }
  }

  const handleClickDownload = () => {
    $('.downloadIcon').removeClass('btn-link');
    $('.downloadIcon').addClass('disabled');

    axios.get(downloadURL)
      .then((response) => {
        $('.downloadIcon').addClass('btn-link');
        $('.downloadIcon').removeClass('disabled');

        if (response.status === 200 && !response.data.hasError) {
          if (response?.data?.additionalDetails?.connectionType === SFTP) {
            setConnectionType(SFTP);
            setFileLocationInfo(response?.data?.additionalDetails?.fileLocationInfo);
          } else {
            toastr.success(t('download_success'));
            window.location.assign(response.data.additionalDetails.URL);

            $('#flash-messages').html(
              `<div class="alert alert-floating alert-dismissible fade show" role="alert">
                <span class="fa fa-check-circle success-icon mr5"></span>
                <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
                ${t('download_success')}
              </div>`);

            setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
          }
        }
        else {
          toastr.error(response.data.errorMessage || t('download_failure'));

          $('#flash-messages').html(
            `<div class="alert alert-floating alert-dismissible fade show" role="alert">
              <span class="fa fa-times error mr5"></span>
              <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
              ${response.data.errorMessage || t('download_failure')}
            </div>`);

          setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
        }
      })
      .catch(() => {
        toastr.error(t('download_failure'));

        $('#flash-messages').html(
          `<div class="alert alert-floating alert-dismissible fade show" role="alert">
            <span class="fa fa-times error mr5"></span>
            <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
            ${t('download_failure')}
          </div>`);

          setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);

        $('.downloadIcon').addClass('btn-link');
        $('.downloadIcon').removeClass('disabled');
      })
  };

  const renderFileDownloadIcon = () => {
    if (userRole === 'READ_WRITE') {
      if (!incident.bucketConfigured || !downloadURL) {
        return (
          <Tooltip label={t('configure_bucket_to_download')} hasTriangle>
            <div className='icon-wrapper'>
              <FaFileDownload className='disabled downloadIcon' />
            </div>
          </Tooltip>
        );
      }

      switch (connectionType) {
        case SFTP:
          return(
            <Popover
              button={
                <Button
                  appearance='tertiary-clear'
                  icon={<FaFileDownload className='btn-link downloadIcon' />}
                />
              }
              showLabel={false}
              isOpen={isPopoverOpen}
              onIsOpenChange={(next) => setIsPopoverOpen(next)}
              defaultPlacement='auto-end'
            >
              <CardBody addClassName='tw-flex  tw-flex-col  tw-space-y-2 dlp-file-dwn-info'>
                {fileLocationInfo}
              </CardBody>
            </Popover>
          );

        case AZURE:
        case AWS:
        default:
          return(
            <FaFileDownload
              onClick={handleClickDownload}
              className='btn-link downloadIcon'
              data-testid='trigger-download'
            />
          );
      }
    }

    return null;
  };

  return (
    <div className='reportDetails'>
      <div className='card-title reportHeader'>
        {t('incident')}
      </div>
      <div className='details'>
        <table className='table table-borderless table-condensed'>
          <tbody>
            <tr>
              <td className='detailCategory'>{t('channel')}</td>
              <td>{translate(incident.channel, {platform: true})}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('report_id')}</td>
              <td>{incident.reportId}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('source')}</td>
              <td>{translate(incident.source, {platform: true})}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('action')}</td>
              <td>{incident.action}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('data_profile')}</td>
              <td>{incident.dataProfileName}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('file')}</td>
              <td>
                <span className='dlp-report-card-filename'>{incident.filename}</span>
                { renderFileDownloadIcon() }
              </td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('scan_date')}</td>
              <td>{moment.utc(incident.scanDate).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z')}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('user_id')}</td>
              <td>{incident.userId}</td>
            </tr>
            {
              getDestinationIP(incident.metadata) &&
              <tr>
                <td className='detailCategory'>{t('destination_ip')}</td>
                <td>{getDestinationIP(incident.metadata)}</td>
              </tr>
            }
          </tbody>
        </table>

        {
          incident.metadata && META_DATA_TO_DISPLAY.map(key => renderMetadata(key, incident.metadata[key]))
        }
      </div>
    </div>
  );
}

ReportCard.propTypes = {
  incident: PropTypes.shape({
    filename: PropTypes.string,
    bucketConfigured: PropTypes.bool,
    reportId: PropTypes.string,
    source: PropTypes.string,
    action: PropTypes.string,
    dataProfileName: PropTypes.string,
    channel: PropTypes.string,
    scanDate: PropTypes.string
  }),
  downloadURL: PropTypes.string,
  userRole: PropTypes.string,
};

ReportCard.defaultProps = {
  incident: {},
  downloadURL: null,
  userRole: null,
};

export default ReportCard;
