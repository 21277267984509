import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button,
  LoadingButton
} from '@panwds/react-ui';

import SidePanel from './components/SlidingPanel';
import NewFingerprintForm from './components/NewFingerprintForm';
import EditFingerprintForm from './components/EditFingerprintForm';
import {
  isFileSizeAllowed
} from './components/helpers';
import translate from '../../helpers/translate';

import {
  NEW,
  EDIT,
  RULE_TECHNIQUE_DOCUMENT_TYPE
} from '../constants';

import './components/drawer.scss';

function localizeString(scope, options) {
  return translate(`document_types.fingerprint_drawer.${scope}`, options);
}

const FingerprintDrawer = ({
  apiEndpoint,
  apiPostfix,
  category: savedCategory,
  description: savedDescription,
  error: savedError,
  fileObj,
  id,
  isOpen,
  mode,
  name: savedName,
  onClose,
  onDelete,
  onRefresh,
  savedFingerprintsArr
}) => {
  const [filesArr, setFilesArr] = useState([]);
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [cachedFileNames, setCachedFileNames] = useState(new Set);
  const [saveButtonStatus, setSaveButtonStatus] = useState({ loading: false });

  useEffect(() => {
    const updatedCachedFileNames = new Set();

    savedFingerprintsArr.forEach(fingerprint => {
      updatedCachedFileNames.add(fingerprint.name);
    });

    setCachedFileNames(previousState => new Set(
      [ ...previousState, ...updatedCachedFileNames ]
    ));

    if (mode === EDIT) {
      setName(savedName);
      setCategory(savedCategory);
      setDescription(savedDescription);
    }
  }, [savedFingerprintsArr, mode, savedName, savedCategory, savedDescription]);

  const isNameUnique = updatedName => !cachedFileNames.has(updatedName);

  const isNewFormValid = () => {
    switch (mode) {
      case NEW:
        return (
          filesArr.length === 1 &&
          name &&
          isNameUnique(name) &&
          category
        );

      case EDIT:
        if (category === savedCategory && description === savedDescription) {
          return false;
        }

        return (
          !!category
        );

      default:
        return false;
    }
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case 'name':
        if (!isNameUnique(event.target.value)) {
          setError(localizeString('status_message_unique_name'));
        } else {
          setError('');
        }

        setName(event.target.value);
        break;

      case 'category':
        setError('');
        setCategory(event.target.value);
        break;

      case 'description':
      default:
        setError('');
        setDescription(event.target.value);
        break;
    }
  }

  const handleFileUpload = files => {
    if (files.length > 1) {
      setError(localizeString('status_message_single_file'));
    } else {
      const updatedFilesArr = [];

      files.forEach(file => {
        if (isFileSizeAllowed(file)) {
          updatedFilesArr.push(file);
          setError('');
        } else {
          setError(localizeString('status_message_file_size'));
        }
      });

      setFilesArr(updatedFilesArr);
    }
  };

  const handleFileDelete = (idx) => {
    setError('');

    const updatedFilesArr = [...filesArr];

    updatedFilesArr.splice(idx, 1);
    setFilesArr(updatedFilesArr);
  };

  const handleDelete = () => {
    onDelete({
      id,
      name: savedName,
      category: savedCategory,
      description: savedDescription
    });
  }

  const handleSave = () => {
    setSaveButtonStatus({ loading: true });
    setError('');

    const handleSuccess = res => {
      if (res.status === 200) {
        onClose();
      } else {
        setError(localizeString('generic_error'));
        setSaveButtonStatus({ loading: false });
      }
    };

    const handleFailure = err => {
      onRefresh();
      setError(err?.response?.data?.message || localizeString('generic_error'));
      setSaveButtonStatus({ loading: false });
    };

    switch (mode) {
      case NEW: {
        const formData = new FormData();

        formData.append('file', filesArr[0]);
        formData.append('json', JSON.stringify({
          name,
          category,
          description,
          original_file_name: filesArr[0].path,
          type: 'custom',
          detection_technique: RULE_TECHNIQUE_DOCUMENT_TYPE
        }));

        axios.post(`${apiEndpoint}${apiPostfix}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          handleSuccess(res);
        })
        .catch(err => {
          handleFailure(err);
        });

        break;
      }

      case EDIT:
      default: {
        axios.put(`${apiEndpoint}/${id}${apiPostfix}`, {
          category,
          description
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          handleSuccess(res);
        })
        .catch(err => {
          handleFailure(err);
        });

        break;
      }
    }
  };

  return (
    <SidePanel
      isOpen={isOpen} onClose={onClose}
      addClassName='drawer-container'
      title={mode === NEW ?
        localizeString('new_fingerprint') : localizeString('edit_fingerprint')
      }
    >
      <main className='drawer-main'>
        <section className='title-area'>
          {localizeString('information')}
        </section>

        { mode === NEW &&
          <NewFingerprintForm
            name={name}
            category={category}
            description={description}
            filesArr={filesArr}
            onInputChange={handleInputChange}
            onFilesUpload={handleFileUpload}
            onFileDelete={handleFileDelete}
          />
        }

        { mode === EDIT &&
          <EditFingerprintForm
            id={id}
            name={name}
            category={category}
            description={description}
            hasError={!!savedError}
            filesArr={[fileObj]}
            onInputChange={handleInputChange}
          />
        }
      </main>

      <div className='error-message'>{error}</div>
      { savedError &&
        <div className='error-message'>{savedError} {localizeString('delete_fingerprint')}</div>
      }

      <section className='drawer-footer tw-flex tw-justify-end tw-space-x-1'>
        { savedError &&
          <Button appearance='primary-destructive' addClassName='delete' onClick={handleDelete}>
            {localizeString('delete')}
          </Button>
        }
        <Button addClassName='cancel' onClick={onClose} >
          {localizeString('cancel')}
        </Button>
        <LoadingButton
          dataMetrics='loading-primary-button'
          onClickCallback={handleSave}
          dataResult={saveButtonStatus}
          appearance='primary'
          disabled={!isNewFormValid()}
        >
          { mode === NEW && localizeString('generate') }
          { mode === EDIT && localizeString('update') }
        </LoadingButton>
      </section>
    </SidePanel>
  );
}

FingerprintDrawer.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  apiPostfix: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.string,
  fileObj: PropTypes.object,
  id: PropTypes.number,
  isOpen: PropTypes.bool,
  mode: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onRefresh: PropTypes.func,
  savedFingerprintsArr: PropTypes.array
};

FingerprintDrawer.defaultProps = {
  apiPostfix: '',
  category: undefined,
  description: undefined,
  error: undefined,
  fileObj: undefined,
  id: undefined,
  isOpen: false,
  mode: NEW,
  name: undefined,
  onClose: () => {},
  onDelete: () => {},
  onRefresh: () => {},
  savedFingerprintsArr: []
};

export default FingerprintDrawer;