import React from 'react';
import {toastr} from 'react-redux-toastr';
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const toastrWrapper = {
  success: (message) =>  {
    toastr.success('', message, {timeOut: 2000, icon: <FaCheckCircle/>});
  },
  error: (message) => {
    toastr.error('', message, {timeOut: 2000, icon: <FaExclamationCircle/>});
  }
}

export default toastrWrapper;