import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import IdentifierDropdown from './IdentifierDropdown';

jest.mock('../../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<IdentifierDropdown/>', () => {
  let wrapper;
  const props = {
    onSelect: jest.fn(),
    value: {
      name: 'test'
    }
  };

  it('matches snapshot', () => {
    wrapper = shallow(<IdentifierDropdown {...props}/>);
    expect(wrapper.find('.identifier-dropdown')).toHaveLength(1);
    expect(wrapper).toMatchSnapshot();
  });
});