// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<EdmConfig/>  should render 1`] = `
<div
  className="reactTable edmConfig settings"
>
  <div
    className="title card-title"
  >
    <span>
      edm_datasets.config.title
    </span>
  </div>
  <div
    className="edmForm"
  >
    <div
      className="enablement"
    >
      <div>
        <span
          className="form-label"
        >
          edm_datasets.config.subTitle
        </span>
        <p>
          edm_datasets.config.description
        </p>
      </div>
      <span
        className="toggle-wrapper"
      >
        <span
          className="toggle-label"
        >
          edm_datasets.config.disabled
        </span>
        <ToggleSwitch
          changeAction={[Function]}
          checked={false}
          sliderDisabled={false}
        />
      </span>
    </div>
    <IntlProvider
      defaultFormats={Object {}}
      defaultLocale="en"
      fallbackOnEmptyString={true}
      formats={Object {}}
      locale="en"
      messages={Object {}}
      onError={[Function]}
      onWarn={[Function]}
      textComponent={Symbol(react.fragment)}
    >
      <Modal
        addClassName="dlp-root"
        confirmClose={false}
        isOpen={false}
        onClose={[Function]}
        size="md"
      >
        <ModalHeader
          enableClose={true}
          title="edm_datasets.config.modal_title"
        />
        <CardBody
          addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
        >
          <div
            className="message-wrapper"
          >
            <p>
              edm_datasets.config.message
            </p>
          </div>
        </CardBody>
        <ModalFooter>
          <ModalCloseButton>
            edm_datasets.config.close
          </ModalCloseButton>
        </ModalFooter>
      </Modal>
    </IntlProvider>
  </div>
</div>
`;

exports[`<EdmConfig/>  should render disabled for read only user 1`] = `
<div
  className="reactTable edmConfig settings"
>
  <div
    className="title card-title"
  >
    <span>
      edm_datasets.config.title
    </span>
  </div>
  <div
    className="edmForm"
  >
    <div
      className="enablement"
    >
      <div>
        <span
          className="form-label"
        >
          edm_datasets.config.subTitle
        </span>
        <p>
          edm_datasets.config.description
        </p>
      </div>
      <span
        className="toggle-wrapper"
      >
        <span
          className="toggle-label"
        >
          edm_datasets.config.disabled
        </span>
        <ToggleSwitch
          changeAction={[Function]}
          checked={false}
          sliderDisabled={true}
        />
      </span>
    </div>
    <IntlProvider
      defaultFormats={Object {}}
      defaultLocale="en"
      fallbackOnEmptyString={true}
      formats={Object {}}
      locale="en"
      messages={Object {}}
      onError={[Function]}
      onWarn={[Function]}
      textComponent={Symbol(react.fragment)}
    >
      <Modal
        addClassName="dlp-root"
        confirmClose={false}
        isOpen={false}
        onClose={[Function]}
        size="md"
      >
        <ModalHeader
          enableClose={true}
          title="edm_datasets.config.modal_title"
        />
        <CardBody
          addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
        >
          <div
            className="message-wrapper"
          >
            <p>
              edm_datasets.config.message
            </p>
          </div>
        </CardBody>
        <ModalFooter>
          <ModalCloseButton>
            edm_datasets.config.close
          </ModalCloseButton>
        </ModalFooter>
      </Modal>
    </IntlProvider>
  </div>
</div>
`;
