import React from 'react';
import { SvgIcon } from '@panwds/icons';

const DetectionMethodsIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox='0 0 21 17'>
      <path d='M9.14893 10.6567C9.14893 8.62549 10.3677 6.87549 12.1489 6.09424V5.65674H7.89893C7.46143 5.65674 7.14893 5.34424 7.14893 4.90674V0.656738H0.898926H0.867676C0.461426 0.656738 0.148926 1.00049 0.148926 1.40674V15.9067V15.938C0.148926 16.3442 0.461426 16.6567 0.867676 16.6567H0.898926H11.3989C11.8052 16.6567 12.1489 16.3442 12.1489 15.938V15.9067V15.2505C10.3677 14.4692 9.14893 12.7192 9.14893 10.6567ZM12.1489 4.46924C12.1177 4.31299 12.0239 4.06299 11.9302 3.93799L8.86768 0.875488C8.74268 0.781738 8.49268 0.656738 8.33643 0.656738H8.14893V4.65674H12.1489V4.46924ZM19.9927 15.1255L17.5552 12.688C17.8677 12.188 18.1177 11.2817 18.1489 10.6567C18.1489 8.46924 16.3364 6.65674 14.1489 6.65674C11.9302 6.65674 10.1489 8.46924 10.1489 10.6567C10.1489 12.8755 11.9302 14.6567 14.1489 14.6567C14.8677 14.6567 15.5552 14.4692 16.1489 14.0942L18.5864 16.5317C18.6489 16.5942 18.8052 16.6567 18.9302 16.6567C19.0552 16.6567 19.2114 16.5942 19.2739 16.5317L19.9927 15.813C20.0552 15.7505 20.1177 15.5942 20.1177 15.4692C20.1177 15.3442 20.0552 15.188 19.9927 15.1255ZM14.1489 12.6567C13.0239 12.6567 12.1489 11.7817 12.1489 10.6567C12.1489 9.56299 13.0239 8.65674 14.1489 8.65674C15.2427 8.65674 16.1489 9.56299 16.1489 10.6567C16.1489 11.7817 15.2427 12.6567 14.1489 12.6567Z' />
    </SvgIcon>
  );
};

export default DetectionMethodsIcon;