import React from "react";
import '@testing-library/react/dont-cleanup-after-each';
import { fireEvent, render, screen, waitForElementToBeRemoved, waitFor } from '@testing-library/react';
import '@testing-library/jest-dom';
import BlockResponse from "./BlockResponse";
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const axiosMock = new MockAdapter(axios);
axiosMock.onPost().reply(200, {});
axiosMock.onGet().reply(200, {
  result: {
    result: {
      'data-filter-block-page': {
        '@loc': 'prisma access'
      }
    }
  }
});
axiosMock.onDelete().reply(200, {});
jest.spyOn(global, 'FileReader').mockImplementation(function () {
  this.readAsDataURL = jest.fn();
});
describe('<BlockResponse/>', () => {
  let testUtils;

  test('Should render the title', () => {
    testUtils = render(<BlockResponse userRole='READ_WRITE'/>);
    expect(testUtils.getByText('Block Response Page')).toBeInTheDocument();
  });

  test('Should open modal', () => {
    const editLink = testUtils.container.querySelector('td.edit-page a');
    fireEvent.click(editLink);
    expect(screen.getByText('Edit Data Filtering Block Page')).toBeInTheDocument();
  });

  test('should call API', async() => {
    const testFile = new File(['<html></html>'], 'test.html', { type: 'text/html' });
    const fileInput= testUtils.getByLabelText('Choose File');
    fireEvent.change(fileInput, {
      target: {
        files: [testFile],
      },
    });
    const saveButton = screen.getByText('Save');
    expect(saveButton).toBeInTheDocument();

    fireEvent.click(saveButton);
    let reader = FileReader.mock.instances[0];
    expect(reader.readAsDataURL).toHaveBeenCalled();
    reader.result = 'a,b';
    reader.onload();
    await waitFor(() => expect(document.querySelector('.modal-dialog')).not.toBeInTheDocument());
  });

  test('should revert block page', () => {
    const revertLink = testUtils.getByText('Revert to System Default Template');
    expect(revertLink).toBeInTheDocument();
    fireEvent.click(revertLink);
    expect(axiosMock.history.delete.length).toBe(1);
  });
});