/* eslint-disable no-shadow */
import React, { useState } from "react";
import { Button } from '@panwds/react-ui';
import { PropTypes } from "prop-types";
import axios from "axios";
import _get from 'lodash.get';

import { Link } from "react-router-dom";
import translate from '../../helpers/translate';
import { CUSTOM } from "./DataPatternTable";
import toastr from '../../helpers/toastr';
import Header from '../DetectionMethods/Header';
import DataPatternMetaData from './DataPatternMetaData';

function t(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}

function withActions(WrappedComponent, type) {
  const customPattern = {
    name: '',
    description: '',
    detection_technique: 'regex',
    delimiter: ';',
    type: 'custom',
    status: 'active',
    regexes: []
  };
  const filePropertyPattern = {
    name: '',
    description: '',
    detection_technique: 'titus_tag',
    status: 'active',
    type: 'file_property',
    metadataCriteria: [{

    }]
  };
  const initialPattern = type === CUSTOM ? customPattern : filePropertyPattern;
  const FormWithActions = ({
    history,
    lookupURL,
    lookupFilePropertiesURL,
    dataPatternAdded,
    userRole,
    dataPattern,
    dataPatternUpdated,
    fawkes
  }) => {
    const [pattern, setPattern] = useState(dataPattern || initialPattern);
    const [doValidation, setDoValidation] = useState(false);
    const [savePending, setSavePending] = useState(false);
    const [duplicateNameError, setDuplicateNameError] = useState(false);

    const returnToGrid = () => {
      if (history) {
        history.push('..');
      }
      else {
        window.location.assign('/detection_methods/data_patterns');
      }
    }

    const updatingDataPattern = (dataPattern) => dataPattern && dataPattern.id;

    const savePattern= () => {
      const method = updatingDataPattern(dataPattern)? 'PUT' : 'POST'
      const url = updatingDataPattern(dataPattern)? `${lookupURL}/${dataPattern.id}${fawkes ? '' : '.json'}` : lookupURL;
      axios({
        url,
        method,
        data: pattern
      }).then((response) => {
        if (updatingDataPattern(dataPattern)) {
          dataPatternUpdated(response.data);
        } else {
          dataPatternAdded(response.data);
        }
        const message = updatingDataPattern(dataPattern)? t('update_success', {name: pattern.name}) : t('create_success', {name: pattern.name});
        toastr.success(message);
        returnToGrid();
      }).catch((error) => {
        console.error('Failed to save data pattern', error);
        const message = _get(error, 'response.data.message', '');
        if (message.toLowerCase().includes('duplicate name')) {
          setDuplicateNameError(true);
        } else {
          toastr.error(translate('errors.server_error'));
        }
      }).finally(() => {
        setSavePending(false);
        setDoValidation(false);
      });
    }

    const updateState = ({pattern, valid}) => {
      if (pattern) {
        setPattern(pattern);
        // Pattern changed, hold off on validation until "Save" is clicked
        setDoValidation(false);
        setSavePending(false);
      }
      if (valid !== undefined) {
        if (valid && savePending) {
          savePattern();
        }
      }
    }

    const nameChanged = (event) => {
      updateState({
        pattern: {
          ...pattern,
          name: event.target.value
        }
      });
      setDoValidation(false);
    }

    const descriptionChanged = (event) => {
      updateState({
        pattern: {
          ...pattern,
          description: event.target.value
        }
      });
    }

    const cancelClicked = () => {
      returnToGrid();
    }

    const saveClicked = async() => {
      setDoValidation(true);
      setSavePending(true);
    }

    return (
      <div className='data-pattern-root'>
        <section className={type === CUSTOM ? 'custom-data-pattern-root' : 'file-property-data-pattern-root'}>
          {!fawkes &&
            <Header activePage={pattern.id? t(`edit_${type.replace(/ /g,"_")}_data_pattern`) : t(`add_${type.replace(/ /g,"_")}_data_pattern`)} basePageLink='/detection_methods/data_patterns' basePage={t('data_patterns')} desc={t(`${type.replace(/ /g,"_")}_edit_instruction`)} />
          }
          <section className='definition-body'>
            {fawkes &&
              <div className='definition-section'>
                <Link className='back-nav-link' to='..'>&lt;{t('back_to_data_patterns')}</Link>
                <div className='data-pattern-form'>
                  <h5>{pattern.id? t(`edit_${type.replace(/ /g,"_")}_data_pattern`) : t(`add_${type.replace(/ /g,"_")}_data_pattern`)}</h5>
                  <div className='helpText'>{t(`${type.replace(/ /g,"_")}_edit_instruction`)}</div>
                </div>
              </div>
            }

            <DataPatternMetaData name={pattern.name}
              allowNameChange={!pattern.id}
              nameChanged={nameChanged}
              description={pattern.description}
              descriptionChanged={descriptionChanged}
              doValidation={doValidation}
              duplicateNameError={duplicateNameError}
            />

            <WrappedComponent
              lookupFilePropertiesURL={lookupFilePropertiesURL}
              pattern={pattern}
              updateState={updateState}
              doValidation={doValidation}
            />
          </section>
        </section>
        <div className='button-bar'>
          <Button addClassName='cancel tw-mr-1'
            appearance='secondary'
            onClick={cancelClicked}
            size='md'>
              {translate('actions.cancel')}
          </Button>
          <Button
            addClassName='save'
            disabled={userRole !== 'READ_WRITE'}
            appearance='primary' onClick={saveClicked}>
              {translate('actions.save')}
          </Button>
        </div>
      </div>
    );
  };

  FormWithActions.propTypes = {
    dataPattern: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      metadataCriteria: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string
      })),
      root_type: PropTypes.string,
      detection_technique: PropTypes.string
    }),
    lookupURL: PropTypes.string.isRequired,
    lookupFilePropertiesURL: PropTypes.string.isRequired,
    history: PropTypes.func,
    userRole: PropTypes.string,
    dataPatternAdded: PropTypes.func,
    dataPatternUpdated: PropTypes.func,
    fawkes: PropTypes.bool,
  };

  FormWithActions.defaultProps = {
    dataPattern: null,
    userRole: 'READ_WRITE',
    dataPatternAdded: () => {},
    dataPatternUpdated: () => {},
    history: undefined,
    fawkes: true,
  };

  return FormWithActions;
}

export default withActions;