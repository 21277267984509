/* eslint-disable camelcase */
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

import translate from "../../helpers/translate";
import styles from './SnippetsConfig.module.scss';
import toastrWrapper from "../../helpers/toastr";

const t = (scope, options) => translate(`snippets_config.${scope}`, options);
const SnippetsConfig = ({userRole, lookupURL, services}) => {
  const [snippetsConfig, setSnippetsConfig] = useState({
    enable_snippets: false,
    mask_level: ''
  });
  const [configChanged, setConfigChanged] = useState(false);

  const loadConfig = () => {
    axios.get(`${lookupURL}?serviceName=${services.includes('access') ? 'prisma-access' : 'ngfw'}`).then((response) => {
      delete response?.data?.created_at;
      delete response?.data?.updated_at;

      setSnippetsConfig(response.data);
    });
  }

  useEffect(() => {
    loadConfig();
  }, []);

  const saveConfig = () => {
    axios.post(lookupURL, snippetsConfig)
    .then(() => {
      setConfigChanged(false);
    })
    .catch(() => {
      toastrWrapper.success(translate('errors.server_error'));
    });
  }

  useEffect(() => {
    if (configChanged) {
      saveConfig();
    }
  }, [configChanged]);

  const handleToggleChange = () => {
    const {enable_snippets} = snippetsConfig;
    setSnippetsConfig( prevConfig => ({
      ...prevConfig,
      enable_snippets: !enable_snippets,
      mask_level: prevConfig.mask_level === '' ? 'partial_mask' : prevConfig.mask_level
    }));
    setConfigChanged(true);
  }

  const maskLevelChanged = (event) => {
    const maskLevel = event.currentTarget.value;
    setSnippetsConfig(prevConfig => ({
      ...prevConfig,
      mask_level: maskLevel
    }));
    setConfigChanged(true);
  }

  const {enable_snippets, mask_level} = snippetsConfig;
  return (
    <div className={`${styles.snippetsMain} formCommon`} data-testid='testRoot'>
      <div className={styles.headerSection}>
        <h5>{t('title')}</h5>
        <div className="helpText">{t('description')}</div>
      </div>

      <div className={styles.formSection}>
        <div className={styles.formRow}>
          <div className={styles.formLabel}>{t('snippets_viewing')}</div>
          <span className={`${styles.formValue} tw-inline-flex`}>
            <ToggleSwitch changeAction={handleToggleChange} checked={enable_snippets} sliderDisabled={userRole !== 'READ_WRITE'} />
            <span className='ml-1'>{enable_snippets? translate('settings.index.enabled') : translate('settings.index.disabled')}</span>
          </span>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formLabel}>{t('snippets_masking')}</div>
          <div className={styles.formValue}>
            <div className='tw-flex tw-items-center'>
              <input className='mr-1' type="radio" id="no-mask" checked={ mask_level === 'no_mask'}
              name="mask-level" value='no_mask' onChange={maskLevelChanged} disabled={ userRole !== 'READ_WRITE'}/>
              <label className={styles.inputLabel} htmlFor="no-mask">{t('do_not_mask')}</label>
            </div>
            <div className='helpText mb-3'>{t('do_not_mask_desc')}</div>

            <div className='tw-flex tw-items-center'>
              <input className='mr-1' type="radio" id="partial-mask" checked={ mask_level === 'partial_mask' }
              name="mask-level" value='partial_mask' onChange={maskLevelChanged} disabled={ userRole !== 'READ_WRITE'}/>
              <label className={styles.inputLabel} htmlFor="partial-mask">{t('partial_mask')}</label>
            </div>
            <div className='helpText mb-3'>{t('partial_mask_desc')}</div>

            <div className='tw-flex tw-items-center'>
              <input className='mr-1' type="radio" id="full-mask" checked={ mask_level === 'full_mask' }
              name="mask-level" value='full_mask' onChange={maskLevelChanged} disabled={ userRole !== 'READ_WRITE'}/>
              <label className={styles.inputLabel} htmlFor="full-mask">{t('full_mask')}</label>
            </div>
            <div className='helpText'>{t('full_mask_desc')}</div>

          </div>
        </div>
      </div>
    </div>
  );
}

SnippetsConfig.propTypes = {
  lookupURL: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired
}

export default SnippetsConfig;