import React from "react";
import {render, waitFor, fireEvent} from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import SnippetsConfig from './SnippetsConfig';

const config = {
  enable_snippets: false,
  mask_level: 'full_mask'
};
const axiosMock = new MockAdapter(axios);
axiosMock.onGet().reply(200, config);
axiosMock.onPost().reply(200, {});

describe('<SnippetsConfig/>', () => {
  let container, getByText, getByLabelText, findByTestId;

  describe('disabled access settings', () => {
    beforeEach(() => {
      ({container, getByText, getByLabelText, findByTestId} = render(<SnippetsConfig lookupURL='/v1/dlp-ui/ocr' userRole='READ_WRITE' services={['access']}/>));
    });

    test('should render the title', async() => {
      expect(await findByTestId('testRoot')).toBeInTheDocument();
      expect(getByText('Snippet Viewing and Masking')).toBeInTheDocument();
    });

    test('should render disabled toggle switch', async() => {
      expect(await findByTestId('testRoot')).toBeInTheDocument();
      await waitFor(() => expect(getByText('Disabled')).toBeInTheDocument());
    });

    test('should render "Full Mask" checked', async() => {
      expect(await findByTestId('testRoot')).toBeInTheDocument();
      await waitFor(() => expect(getByLabelText('Full mask').checked).toEqual(true));
    });

    test('toggling enabled state should call API', async() => {
      await waitFor(() => expect(getByText('Disabled')).toBeInTheDocument());
      const toggle = container.querySelector('img.toggle-icon');
      fireEvent.click(toggle);
      expect(JSON.parse(axiosMock.history.post[0].data)).toEqual(expect.objectContaining({
        enable_snippets: true
      }));
    });

    test('changing mask level should call API', async() => {
      axiosMock.resetHistory();
      await waitFor(() => expect(getByText('Disabled')).toBeInTheDocument());
      const toggle = container.querySelector('input#partial-mask');
      fireEvent.click(toggle);
      expect(JSON.parse(axiosMock.history.post[0].data)).toEqual(expect.objectContaining({
        mask_level: 'partial_mask'
      }));
    });
  });


  test('should render enabled toggle switch based on returned config', async() => {
    const config = {
      enable_snippets: true,
      mask_level: 'partial_mask'
    };
    axiosMock.onGet().reply(200, config);
    const {getByText, getByLabelText, findByTestId} = render(<SnippetsConfig lookupURL='/v1/dlp-ui/ocr' userRole='READ_WRITE' services={['ngfw']}/>);
    expect(await findByTestId('testRoot')).toBeInTheDocument();
    await waitFor(() => expect(getByText('Enabled')).toBeInTheDocument());
    await waitFor(() => expect(getByLabelText('Partial mask').checked).toEqual(true));
  });
});