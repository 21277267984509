import React from 'react';
import PropTypes from 'prop-types';
import { FaLayerGroup } from "react-icons/fa";
import { Tooltip } from "@panwds/react-ui";
import translate from '../../../helpers/translate';

import BracketsIcon from '../../../../assets/images/brackets.svg';

function t(scope, options) {
  return translate(`data_profiles.form.preview_toggle.${scope}`, options);
}

const PreviewToggle = (props) => {
  const { previewType, updateState } = props;

  return (
    <div>
      <Tooltip label={t('detailed_list')} hasTriangle>
        <div className={`detail ${previewType === 'detail' ? 'selected' : 'unselected'}`}>
          <span onClick={() => updateState({previewType: 'detail'})}><FaLayerGroup /></span>
        </div>
      </Tooltip>
      <Tooltip label={t('bracket')} hasTriangle>
        <div className={`bracket ${previewType === 'bracket' ? 'selected' : 'unselected'}`}>
          <span onClick={() => updateState({previewType: 'bracket'})}><img src={BracketsIcon} alt='brackets' /></span>
        </div>
      </Tooltip>
    </div>
  )
}

PreviewToggle.propTypes = {
  previewType: PropTypes.string,
  updateState: PropTypes.func.isRequired,
};

PreviewToggle.defaultProps = {
  previewType: 'bracket',
}
export default PreviewToggle
