import columns from './columns';

describe('columns', () => {
  let result;
  let props;

  it ('returns expected column data for fawkes non-casb portal', () => {
    props = {
      fawkes: true,
      assignees: [],
      portalType: 'fawkes',
      basePath: '/manage/security-services/data-loss-prevention'
    }
    result = columns(props);
    expect(result.length).toEqual(12);
    const settingsLink = result.filter(col => col.accessor === 'incidentFeedbackStatus')[0].header().props.children[1].props.children.props.children[1].props.children;
    expect(settingsLink[0].props.to).toEqual('/manage/security-services/data-loss-prevention/settings');
    expect(settingsLink[1]).toEqual(false);
  });

  it ('returns expected column data for fawkes 1.0 non-casb portal', () => {
    props = {
      fawkes: true,
      assignees: [],
      portalType: 'fawkes',
      basePath: '/manage/prisma-access/data-loss-prevention'
    }
    result = columns(props);
    expect(result.length).toEqual(12);
    const settingsLink = result.filter(col => col.accessor === 'incidentFeedbackStatus')[0].header().props.children[1].props.children.props.children[1].props.children;
    expect(settingsLink[0].props.to).toEqual('/manage/prisma-access/data-loss-prevention/settings');
    expect(settingsLink[1]).toEqual(false);
  });

  it ('returns expected column data for fawkes panorama casb portal', () => {
    props = {
      fawkes: true,
      assignees: [],
      portalType: 'panorama-casb',
      basePath: '/manage/security-services/data-loss-prevention'
    }
    result = columns(props);
    expect(result.length).toEqual(12);
    const settingsLink = result.filter(col => col.accessor === 'incidentFeedbackStatus')[0].header().props.children[1].props.children.props.children[1].props.children;
    expect(settingsLink[0].props.to).toEqual('/casb/data-loss-prevention/settings');
    expect(settingsLink[1]).toEqual(false);
  });

  it ('returns expected column data for standalone dlp', () => {
    props = {
      fawkes: false,
      assignees: [],
      portalType: 'dlp'
    }
    result = columns(props);
    expect(result.length).toEqual(12);
    const settingsLink = result.filter(col => col.accessor === 'incidentFeedbackStatus')[0].header().props.children[1].props.children.props.children[1].props.children;
    expect(settingsLink[0]).toEqual(false);
    expect(settingsLink[1].props.href).toEqual('/settings');
  });
})