import React from 'react';
import { shallow } from 'enzyme';
import sinon from 'sinon';
import { expect as chaiExpect } from 'chai';

import SidePanel from './index';
import CloseIcon from '../../../Icons/CloseIcon';

describe('<SidePanel />', () => {
  let sandbox, wrapper;
  const baseProps = {
    children: 'test children',
    isOpen: false,
    onClose: () => {},
    title: 'test title'
  };
  const mockView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return shallow(<SidePanel { ...props } />);
  };
  const renderView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    render(
      <SftpConfigureModal
        {...props}
      />
    );
  };

  beforeEach(() => {
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    sandbox.restore();
  });

  it('should match snapshot', () => {
    wrapper = mockView();

    expect(wrapper).toMatchSnapshot();
  });

  it('should render', () => {
    wrapper = mockView({ isOpen: true });

    chaiExpect(wrapper.getElement()).not.to.be.undefined;
    chaiExpect(wrapper.find(CloseIcon).prop('size')).to.equal('xs');
  });
});