/* eslint-disable func-names */

import React from 'react';

import { configure, shallow } from 'enzyme';
import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';
import Adapter from 'enzyme-adapter-react-16';
import { expect as chaiExpect } from 'chai';
import BucketSettings from './BucketSettings';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<BucketSettings>', () => {
  let wrapper;
  const lookupURL = 'http://localhost/settings.json';
  const services = ['access', 'ngfw']
  const props = {
    lookupURL,
    services,
    userRole: 'READ_WRITE'
  };

  describe('with a lookupURL', () => {
    let promise, promise1, promise2, promise3;
    const mock = new MockAdapter(axios);

    describe('with a success response', () => {
      let response;

      beforeEach(() => {
        response = {
          enabled_for_prisma_access: true,
          enabled_for_ngfw: false,
          connection_status: 'connected',
          connection_type: 'aws',
          connection_data: {
              aws_account_id: 'test_account_id',
              aws_bucket_name: 'valid_bucket',
              region: 'us-west-1'
          },
          user_name: 'admin@company.com'
        };
        promise1 = new Promise(function(resolve) {
          resolve([ 200, response ]);
        });
        mock.onGet().reply(function() {
          return promise1;
        });
        promise2 = new Promise(function(resolve) {
          resolve([ 200, { status: 200 } ]);
        });
        mock.onPost().reply(function() {
          return promise2;
        });
        promise3 = new Promise(function(resolve) {
          resolve([ 200, { status: 200 } ]);
        });
        mock.onDelete().reply(function() {
          return promise3;
        });
      });

      it('renders', () => {
        wrapper = shallow(<BucketSettings {...props} />);
        wrapper.instance().fetchBucketData().then(() => {
          expect(wrapper.state('data')).toEqual(response);
          expect(wrapper.state('errorMessage')).toEqual('');
          expect(wrapper.find('.configureBtn')).toHaveLength(0);
          expect(wrapper).toMatchSnapshot();
        });
      });

      it('renders with disabled edit for read only user', () => {
        const readOnlyProps = {...props, userRole: 'READ_ONLY'}
        wrapper = shallow(<BucketSettings {...readOnlyProps} />);
        wrapper.instance().fetchBucketData().then(() => {
          expect(wrapper.state('data')).toEqual(response);
          expect(wrapper.state('errorMessage')).toEqual('');
          expect(wrapper.find('.configureBtn')).toHaveLength(0);
          expect(wrapper).toMatchSnapshot();
        });
      });

      it('handleCloseModal', () => {
        const newProps = { ...props, userRole: 'READ_WRITE' }

        wrapper = shallow(<BucketSettings {...newProps} />);

        wrapper.instance().handleCloseModal();

        chaiExpect(wrapper.instance().state.bucketType).to.equal(null);
        chaiExpect(wrapper.instance().state.showAzureModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showAwsModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showSftpModal).to.equal(false);
      });

      it('handleCloseModal', () => {
        const newProps = { ...props, lookupURL: undefined }

        wrapper = shallow(<BucketSettings {...newProps} />);

        wrapper.instance().handleCloseModal();

        chaiExpect(wrapper.instance().state.bucketType).to.equal(null);
        chaiExpect(wrapper.instance().state.showAzureModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showAwsModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showSftpModal).to.equal(false);
      });

      it('handlesOpenModal', () => {
        const newProps = { ...props, lookupURL: undefined }

        wrapper = shallow(<BucketSettings {...newProps} />);

        wrapper.instance().handleOpenModal({
          selectedItem: {
            key: 'sftp'
          }
        });

        chaiExpect(wrapper.instance().state.bucketType).to.equal('sftp');
        chaiExpect(wrapper.instance().state.showAzureModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showAwsModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showSftpModal).to.equal(true);

        wrapper.instance().handleOpenModal({ key: 'azure'});

        chaiExpect(wrapper.instance().state.bucketType).to.equal('azure');
        chaiExpect(wrapper.instance().state.showAzureModal).to.equal(true);
        chaiExpect(wrapper.instance().state.showAwsModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showSftpModal).to.equal(false);

        wrapper.instance().handleOpenModal();

        chaiExpect(wrapper.instance().state.bucketType).to.equal(undefined);
        chaiExpect(wrapper.instance().state.showAzureModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showAwsModal).to.equal(false);
        chaiExpect(wrapper.instance().state.showSftpModal).to.equal(false);
      });

      it('updateStatus', () => {
        const newProps = { ...props, lookupURL: undefined }

        wrapper = shallow(<BucketSettings {...newProps} />);

        wrapper.instance().updateStatus('doge', true);

        chaiExpect(wrapper.instance().state.serviceToChange).to.equal('doge');
        chaiExpect(wrapper.instance().state.enableService).to.equal(true);
        chaiExpect(wrapper.instance().state.showConfirmModal).to.equal(true);
      });

      it('handleDeleteConfig', () => {
        let newProps = { ...props, fawkes: true }

        wrapper = shallow(<BucketSettings { ...newProps } />);

        wrapper.instance().handleDeleteConfig().then(() => {
          chaiExpect(wrapper.instance().state.data).to.deep.equal({});
        });

        newProps = { ...props, fawkes: false }
        wrapper = shallow(<BucketSettings { ...newProps } />);

        wrapper.instance().handleDeleteConfig().then(() => {
          chaiExpect(wrapper.instance().state.data).to.deep.equal({});
        });
      });
    });

    describe('with a bucket validation error', () => {
      let response;

      beforeEach(() => {
        response = {
          enabled_for_prisma_access: true,
          enabled_for_ngfw: false,
          connection_status: 'connected',
          connection_type: 'aws',
          connection_data: {
              aws_account_id: 'test_account_id',
              aws_bucket_name: 'valid_bucket',
              region: 'us-west-1'
          },
          user_name: 'admin@company.com'
        };
        promise = new Promise(function(resolve) {
          resolve([ 200, response ]);
        });
        mock.onGet(lookupURL).reply(function() {
          return promise;
        });
        promise2 = new Promise(function(resolve) {
          resolve([ 200, { status: 400, message: 'bucket not connected' } ]);
        });
        mock.onPost('/settings/validate.json').reply(function() {
          return promise2;
        });
      });

      it('renders with warning if validation unsuccessful', () => {
        wrapper = shallow(<BucketSettings {...props} />);
        wrapper.instance().fetchBucketData().then(() => {
          expect(wrapper.state('data')).toEqual(response);
          expect(wrapper.state('errorMessage')).toEqual('');
          expect(wrapper.find('.configureBtn')).toHaveLength(0);
          expect(wrapper).toMatchSnapshot();
        });
      });
    });

    describe('with a disconnected bucket', () => {
      let response;

      beforeEach(() => {
        response = {
          enabled_for_prisma_access: true,
          enabled_for_ngfw: false,
          connection_status: 'disconnected',
          connection_type: 'aws',
          connection_data: {
              aws_account_id: 'test_account_id',
              aws_bucket_name: 'valid_bucket',
              region: 'us-west-1'
          },
          user_name: 'admin@company.com'
        };
        promise = new Promise(function(resolve) {
          resolve([ 200, response ]);
        });
        mock.onGet(lookupURL).reply(function() {
          return promise;
        });
      });

      it('renders with warning if validation unsuccessful', () => {
        wrapper = shallow(<BucketSettings {...props} />);
        wrapper.instance().fetchBucketData().then(() => {
          expect(wrapper.state('data')).toEqual(response);
          expect(wrapper.state('errorMessage')).toEqual('settings.index.alerts.bucket_disconnected');
          expect(wrapper.find('.configureBtn')).toHaveLength(0);
          expect(wrapper).toMatchSnapshot();
        });
      });
    });

    describe('with an unconfigured bucket', () => {
      let response;

      beforeEach(() => {
        response = {
          enabled_for_prisma_access: null,
          enabled_for_ngfw: null,
          connection_status: '',
          connection_type: '',
          connection_data: { }
        };
        promise = new Promise(function(resolve) {
          resolve([ 200, response ]);
        });
        mock.onGet(lookupURL).reply(function() {
          return promise;
        });
      });

      it('renders setup button', () => {
        wrapper = shallow(<BucketSettings {...props} />);
        wrapper.instance().fetchBucketData().then(() => {
          expect(wrapper.state('data')).toEqual(response);
          expect(wrapper.state('errorMessage')).toEqual('');
          expect(wrapper.find('.configureBtn')).toHaveLength(1);
          expect(wrapper).toMatchSnapshot();
        });
      });
    });

    describe('with an error response', () => {
      beforeEach(() => {
        promise = new Promise(function(resolve, reject) {
          reject(new Error('Request failed with status code 500'));
        });
        mock.onGet(lookupURL).reply(function() {
          return promise;
        });
      });

      it('renders', () => {
        wrapper = shallow(<BucketSettings {...props} />);
        expect(wrapper).toMatchSnapshot();
      });
    });
  });

  it('renders without a fetchUrl', () => {
    wrapper = shallow(<BucketSettings userRole='READ_WRITE' />);
    expect(wrapper).toMatchSnapshot();
  });

  it('is disabled for read only user', () => {
    wrapper = shallow(<BucketSettings userRole='READ_ONLY' />);
    expect(wrapper).toMatchSnapshot();
  });
});
