import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button,
  WarningIcon,
  DangerIcon,
  SuccessIcon
} from '@panwds/react-ui';
import {
  FaSearch,
  FaCalendar
} from 'react-icons/fa';

import BulkTable from '../BulkTable/BulkTable';
import FingerprintDrawer from './FingerprintDrawer';
import TestDrawer from './TestDrawer';
import DeleteModal from './DeleteModal';

import DropDownList from '../DropDown/DropDownList';
import { dateRangeOptions } from './components/MiscData';
import { tableHeaders } from './components/TableData';
import { sortArrObjects } from '../../helpers/utils';
import translate from '../../helpers/translate';

import {
  ASC,
  DOCUMENT_TYPES_COL_CREATED_TIME,
  DOCUMENT_TYPES_COL_LAST_UPDATED_TIME,
  NEW,
  EDIT,
  PAST_24_HOURS,
  PAST_7_DAYS,
  PAST_30_DAYS,
  PAST_90_DAYS,
  PAST_ALL,
  DOCUMENT_STATUS_SUCCESS,
  DOCUMENT_STATUS_FAILED,
  DOCUMENT_STATUS_IN_PROGRESS
} from '../constants';

import './styles.scss';

function localizeString(scope, options) {
  return translate(`document_types.index.${scope}`, options);
}

const DocumentTypes = ({
  lookupApi,
  matchApi,
  apiPostfix
}) => {
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(ASC);
  const [sortBy, setSortBy] = useState(DOCUMENT_TYPES_COL_CREATED_TIME);
  const [data, setData] = useState([]);
  const [dataFull, setDataFull] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [dateRange, setDateRange] = useState(PAST_90_DAYS);
  const [dateRangeConstant, setDateRangeConstant] = useState(90);
  const [fingerprintDrawerMode, setFingerprintDrawerMode] = useState(NEW);
  const [fingerprintDrawerOpen, setFingerprintDrawerOpen] = useState(false);
  const [testDrawerOpen, setTestDrawerOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeFingerprint, setActiveFingerprint] = useState({});

  useEffect(() => {
    setLoading(true);
    fetchDocuments();
  }, []);

  const segmentTableData = (
    tableData,
    updatedCurrentPage,
    updatedItemsPerPage,
    updatedSearchInput,
    updatedDateRangeConstant,
    updatedSortBy,
    updatedSortOrder
  ) => {
    let updatedTableData = [...tableData];
    const selectedDateRange =
      new Date(new Date().setDate(new Date().getDate() - updatedDateRangeConstant)).getTime();

    updatedTableData = updatedTableData.filter(item => {
      return item.created_at > selectedDateRange;
    });

    updatedTableData = updatedTableData.filter(item => (
      item.name.toLowerCase().includes(updatedSearchInput.toLowerCase()) ||
      item.status.toLowerCase().includes(updatedSearchInput.toLowerCase()) ||
      item.category.toLowerCase().includes(updatedSearchInput.toLowerCase()) ||
      item.type.toLowerCase().includes(updatedSearchInput.toLowerCase())
    ));

    updatedTableData = sortArrObjects(
      updatedTableData,
      updatedSortBy,
      updatedSortOrder,
      [ DOCUMENT_TYPES_COL_CREATED_TIME, DOCUMENT_TYPES_COL_LAST_UPDATED_TIME ].includes(updatedSortBy)
    );

    const startIdx =
      updatedCurrentPage > Math.ceil(updatedTableData.length / updatedItemsPerPage) ?
        0 : updatedCurrentPage * updatedItemsPerPage;
    const endIdx = startIdx + updatedItemsPerPage;

    setData(updatedTableData.slice(startIdx, endIdx));
    setCurrentPage(updatedCurrentPage);
    setItemsPerPage(updatedItemsPerPage);
    setTotalCount(updatedTableData.length);
    setTotalPages(Math.ceil(updatedTableData.length / updatedItemsPerPage));
    setSortBy(updatedSortBy);
    setSortOrder(updatedSortOrder);
    setLoading(false);
  };

  const fetchDocuments = () => {
    axios.get(`${lookupApi}${apiPostfix}`)
      .then(response => {
        const documents = response?.data || [];

        setDataFull([ ...documents ]);
        segmentTableData(
          documents,
          currentPage,
          itemsPerPage,
          searchInput,
          dateRangeConstant,
          sortBy,
          sortOrder
        );
      });
  };

  const handleRequestResults = (updatedCurrentPage, updatedItemsPerPage) => {
    segmentTableData(
      dataFull,
      updatedCurrentPage,
      updatedItemsPerPage,
      searchInput,
      dateRangeConstant,
      sortBy,
      sortOrder
    );
  };

  const handleUpdateSortControls = ({ sortBy: newSortBy, sortOrder: newSortOrder }) => {
    segmentTableData(
      dataFull,
      currentPage,
      itemsPerPage,
      searchInput,
      dateRangeConstant,
      newSortBy,
      newSortOrder
    );
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case 'searchInput': {
        setSearchInput(event.target.value);
        segmentTableData(
          dataFull,
          0,
          10,
          event.target.value,
          dateRangeConstant,
          sortBy,
          sortOrder
        );
        break;
      }

      default:
        break;
    }
  }

  const handleDateRangeChange = (newDateRange) => {
    let updatedDateRangeConstant = 90;

    switch (newDateRange) {
      case PAST_24_HOURS:
        updatedDateRangeConstant = 1;
        break;

      case PAST_7_DAYS:
        updatedDateRangeConstant = 7;
        break;

      case PAST_30_DAYS:
        updatedDateRangeConstant = 30;
        break;

      case PAST_90_DAYS:
        updatedDateRangeConstant = 90;
        break;

      case PAST_ALL:
      default:
        updatedDateRangeConstant = 36525;
        break;
    }

    setDateRange(newDateRange);
    setDateRangeConstant(updatedDateRangeConstant);
    segmentTableData(
      dataFull,
      0,
      itemsPerPage,
      searchInput,
      updatedDateRangeConstant,
      sortBy,
      sortOrder
    );
  };

  const handleClickNewDocument = () => {
    setFingerprintDrawerMode(NEW);
    setFingerprintDrawerOpen(true);
  };

  const handleIsRowClickEnabled = item => {
    switch (item.status) {
      case DOCUMENT_STATUS_IN_PROGRESS:
        return false;

      case DOCUMENT_STATUS_FAILED:
      case DOCUMENT_STATUS_SUCCESS:
      default:
        return true;
    }
  };

  const handleIsEditEnabled = item => {
    switch (item.status) {
      case DOCUMENT_STATUS_IN_PROGRESS:
        return false;

      case DOCUMENT_STATUS_FAILED:
      case DOCUMENT_STATUS_SUCCESS:
      default:
        return true;
    }
  };

  const handleIsTestEnabled = item => {
    switch (item.status) {
      case DOCUMENT_STATUS_IN_PROGRESS:
      case DOCUMENT_STATUS_FAILED:
        return false;

      case DOCUMENT_STATUS_SUCCESS:
      default:
        return true;
    }
  };

  const handleIsDeleteEnabled = item => {
    switch (item.status) {
      case DOCUMENT_STATUS_IN_PROGRESS:
        return false;

      case DOCUMENT_STATUS_FAILED:
      case DOCUMENT_STATUS_SUCCESS:
      default:
        return true;
    }
  };

  const handleRowClick = (item) => {
    setActiveFingerprint(item);

    switch (item?.status) {
      case DOCUMENT_STATUS_SUCCESS: {
        setTestDrawerOpen(true);
        break;
      }

      case DOCUMENT_STATUS_FAILED: {
        setFingerprintDrawerMode(EDIT);
        setFingerprintDrawerOpen(true);
        break;
      }

      case DOCUMENT_STATUS_IN_PROGRESS:
      default:
        break;
    }
  };

  const handleEditDocument = (item) => {
    setActiveFingerprint(item);
    setFingerprintDrawerMode(EDIT);
    setFingerprintDrawerOpen(true);
  };

  const handleTestDocument = (item) => {
    setActiveFingerprint(item);
    setTestDrawerOpen(true);
  };

  const handleDeleteDocument = (item) => {
    setActiveFingerprint(item);
    setDeleteModalOpen(true);
    setFingerprintDrawerOpen(false);
  };

  const handleRefreshDocuments = () => {
    fetchDocuments();
  };

  const handleToggleFingerprintDrawer = () => {
    setFingerprintDrawerOpen(!fingerprintDrawerOpen);
    setActiveFingerprint({});
    fetchDocuments();
  };

  const handleToggleTestDrawer = () => {
    setTestDrawerOpen(!testDrawerOpen);
    setActiveFingerprint({});
    fetchDocuments();
  };

  const handleToggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
    setActiveFingerprint({});
    fetchDocuments();
  };

  const handleCustomCellRender = (defaultFunction, id, value) => {
    if (id === 'status') {
      switch (value) {
        case DOCUMENT_STATUS_SUCCESS: {
          return (
            <><SuccessIcon /><span className='mar-left-10'>Completed</span></>
          );
        }

        case DOCUMENT_STATUS_FAILED: {
          return (
            <><DangerIcon addClassName='failure' /><span className='mar-left-10'>Failed</span></>
          );
        }

        case DOCUMENT_STATUS_IN_PROGRESS:
        default: {
          return (
            <><WarningIcon /><span className='mar-left-10'>In-Progress</span></>
          );
        }
      }
    }

    return defaultFunction;
  };

  return (
    <div className='document-types-content'>
      <IntlProvider locale='en'>
        <section className='top-section'>
          <div className='title'>{localizeString('title')}</div>
          <div className='description'>{localizeString('description')}</div>
        </section>

        <section className='above-table-section'>
          <DropDownList
            onSelect={handleDateRangeChange}
            value={dateRange}
            items={dateRangeOptions}
            dropClass='scanDateSelection'
            preLabel={<span className='globalFilterLabel'><FaCalendar className='mr5' /></span>}
            data-testid='select-date-input'
          />

          <section className='actions'>
            <span className='search-bar'>
              <input
                className='form-control'
                name='searchInput'
                onChange={handleInputChange}
                value={searchInput}
                placeholder={localizeString('search')}
                data-testid='search-input'
              />
              <i><FaSearch /></i>
            </span>

            <Button data-testid='add-new-button' onClick={handleClickNewDocument} appearance='primary'>
              {localizeString('add_new')}
            </Button>
          </section>
        </section>

        <BulkTable
          data={data}
          defaultSortBy={sortBy}
          defaultSortOrder={sortOrder}
          columns={tableHeaders}
          pageData={{
            currentPage,
            pageCount: itemsPerPage,
            totalCount,
            totalPages
          }}
          requestResults={handleRequestResults}
          loading={loading}
          updateSortControls={handleUpdateSortControls}
          selectable={false}
          sortable
          sortExclusionList={[]}
          actionHandlers={{
            isRowClickEnabled: handleIsRowClickEnabled,
            isEditEnabled: handleIsEditEnabled,
            isTestEnabled: handleIsTestEnabled,
            isDeleteEnabled: handleIsDeleteEnabled,
            onRowClick: handleRowClick,
            onEdit: handleEditDocument,
            onTest: handleTestDocument,
            onDelete: handleDeleteDocument
          }}
          customCellRender={handleCustomCellRender}
        />

        { fingerprintDrawerOpen &&
          <FingerprintDrawer
            apiEndpoint={lookupApi}
            apiPostfix={apiPostfix}
            category={activeFingerprint?.category}
            description={activeFingerprint?.description}
            error={activeFingerprint?.status === DOCUMENT_STATUS_FAILED ?
              activeFingerprint?.metadata?.status_note : undefined
            }
            fileObj={{
              name: activeFingerprint?.metadata?.original_file_name,
              path: activeFingerprint?.metadata?.document_object_path,
              size: activeFingerprint?.metadata?.original_file_size_in_byte
            }}
            id={activeFingerprint?.id}
            isOpen={fingerprintDrawerOpen}
            mode={fingerprintDrawerMode}
            name={activeFingerprint?.name}
            onClose={handleToggleFingerprintDrawer}
            onDelete={handleDeleteDocument}
            onRefresh={handleRefreshDocuments}
            savedFingerprintsArr={dataFull}
          />
        }

        { testDrawerOpen &&
          <TestDrawer
            apiEndpoint={matchApi}
            apiPostfix={apiPostfix}
            category={activeFingerprint?.category}
            documentObjectPath={activeFingerprint?.metadata?.document_object_path}
            id={activeFingerprint?.id}
            isOpen={testDrawerOpen}
            name={activeFingerprint?.name}
            onClose={handleToggleTestDrawer}
          />
        }

        { deleteModalOpen &&
          <DeleteModal
            apiEndpoint={lookupApi}
            apiPostfix={apiPostfix}
            id={activeFingerprint?.id}
            isOpen={deleteModalOpen}
            name={activeFingerprint?.name}
            onClose={handleToggleDeleteModal}
          />
        }
      </IntlProvider>
    </div>
  );
}

DocumentTypes.propTypes = {
  lookupApi: PropTypes.string.isRequired,
  matchApi: PropTypes.string.isRequired,
  apiPostfix: PropTypes.string,
};

DocumentTypes.defaultProps = {
  apiPostfix: ''
};

export default DocumentTypes;