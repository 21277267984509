// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<Header> Component to have basic classes renders 1`] = `
<Fragment>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <a
        className="btn-link"
        href="/"
      >
        application.dlp
      </a>
      <span
        className="fa fa-chevron-right separator"
      />
      <a
        className="btn-link"
        href="/detection_methods/data_patterns"
      >
        detection_methods.header.detection_methods
      </a>
      <span
        className="fa fa-chevron-right separator"
      />
      Data Patterns
    </div>
    <div
      className="title"
    >
      Data Patterns
    </div>
  </div>
</Fragment>
`;
