import React from 'react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import translate from '../../helpers/translate';

const ConnectWarning = ({service}) => {
  return (
    <div className='connectWarning tw-flex tw-p-2'>
      <FaInfoCircle className='infoIcon tw-mt-2 tw-ml-2' />
      <div className='tw-pl-4 message'>
        {translate('settings.index.evidence_storage_connect_warning', {service})}
      </div>
    </div>
  )
};

ConnectWarning.propTypes = {
  service: PropTypes.string.isRequired
}

export default ConnectWarning;