// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataProfileBracketPreview> renders nested hybrid preview 1`] = `
<div>
  <div
    className="previewArea"
  >
    <span
      className="level0"
    >
      (
      <span
        className="bracketRuleItem"
        key="dataset-0"
        style={Object {}}
      >
        SSN
         &gt; 0; 
        high
      </span>
      <br
        key="1"
      />
      <span
        className="bracketRuleItem"
        key="group-1"
      >
        <span
          className="subExpression"
        >
          <span
            className="previewLabel ml5 mr5"
            style={
              Object {
                "marginLeft": "0px",
              }
            }
          >
            and
          </span>
          <span
            className="level1"
          >
            (
            <span
              className="bracketRuleItem"
              key="group-0"
            >
              <span
                className="subExpression"
              >
                <span
                  className="level2"
                >
                  (
                  <span
                    className="bracketRuleItem"
                    key="dataset-0"
                    style={Object {}}
                  >
                    CCN
                     &gt; 0; 
                    high
                  </span>
                  <br
                    key="1"
                  />
                  <span
                    className="bracketRuleItem"
                    key="dataset-1"
                    style={
                      Object {
                        "marginLeft": "20px",
                      }
                    }
                  >
                    <span
                      className="previewLabel  mr5"
                    >
                      or
                    </span>
                    Dataset B
                     &gt; 0
                  </span>
                  )
                </span>
              </span>
            </span>
            )
          </span>
        </span>
      </span>
      <br
        key="2"
      />
      <span
        className="bracketRuleItem"
        key="group-2"
      >
        <span
          className="subExpression"
        >
          <span
            className="previewLabel ml5 mr5"
            style={
              Object {
                "marginLeft": "0px",
              }
            }
          >
            and
            <span
              className="previewLabel codeRed"
            >
               not
            </span>
          </span>
          <span
            className="level1"
          >
            (
            <span
              className="bracketRuleItem"
              key="dataset-0"
              style={Object {}}
            >
              Dataset A
               &gt; 0
            </span>
            )
          </span>
        </span>
      </span>
      )
    </span>
  </div>
</div>
`;

exports[`<DataProfileBracketPreview> renders pattern only preview 1`] = `
<div>
  <div
    className="previewArea"
  >
    <span
      key="condition-0"
    >
      (
      <span
        key="1234560"
      >
        SSN
        <span>
          <span
            className="previewLabel"
          >
             between 
          </span>
          4, 24; 
        </span>
        high
      </span>
      <br
        key="br0"
      />
      <span
        className="previewLabel"
        key="op0"
      >
         
        or
         
      </span>
      <span
        key="1234561"
      >
        CCN
         &lt;= 9; 
        medium
      </span>
      )
      <br
        key="cbr0"
      />
    </span>
    <span
      key="condition-1"
    >
      <span
        className="previewLabel mr5"
      >
        and
      </span>
      (
      <span
        key="1234562"
      >
        Bank
        <span>
          <span
            className="previewLabel"
          >
             between 
          </span>
          42, 241; 
        </span>
        high
      </span>
      <br
        key="br0"
      />
      <span
        className="previewLabel"
        key="op0"
      >
         
        or
         
      </span>
      <span
        className="previewLabel codeRed"
        key="not0"
      >
        not 
      </span>
      <span
        key="1234563"
      >
        Passport
         &gt; 0; 
        low
      </span>
      )
    </span>
  </div>
</div>
`;

exports[`<DataProfileBracketPreview> renders v1 a not b pattern only preview 1`] = `
<div>
  <div
    className="previewArea"
  >
    <span
      key="condition-0"
    >
      (
      <span
        key="1234560"
      >
        SSN
        <span>
          <span
            className="previewLabel"
          >
             between 
          </span>
          4, 24; 
        </span>
        high
      </span>
      <br
        key="br0"
      />
      <span
        className="previewLabel"
        key="op0"
      >
         
        and
         
      </span>
      <span
        className="previewLabel codeRed"
        key="not0"
      >
        not 
      </span>
      <span
        key="1234561"
      >
        CCN
         ?; 
        medium
      </span>
      )
    </span>
  </div>
</div>
`;

exports[`<DataProfileBracketPreview> renders v1 multiop pattern only preview 1`] = `
<div>
  <div
    className="previewArea"
  >
    <span
      key="condition-0"
    >
      (
      <span
        key="1234560"
      >
        SSN
        <span>
          <span
            className="previewLabel"
          >
             between 
          </span>
          4, 24; 
        </span>
        high
      </span>
      <br
        key="br0"
      />
      <span
        className="previewLabel"
        key="op0"
      >
         
        or
         
      </span>
      <span
        key="1234561"
      >
        CCN
         ?; 
        medium
      </span>
      <br
        key="br1"
      />
      <span
        className="previewLabel"
        key="op1"
      >
         
        and
         
      </span>
      <span
        className="previewLabel codeRed"
        key="not1"
      >
        not 
      </span>
      <span
        key="1234566"
      >
        ABC
         ?; 
        medium
      </span>
      )
      <br
        key="cbr0"
      />
    </span>
    <span
      key="condition-1"
    >
      <span
        className="previewLabel mr5"
      >
        and
      </span>
      (
      <span
        key="1234562"
      >
        Bank
        <span>
          <span
            className="previewLabel"
          >
             between 
          </span>
          42, 241; 
        </span>
        high
      </span>
      <br
        key="br0"
      />
      <span
        className="previewLabel"
        key="op0"
      >
         
        or
         
      </span>
      <span
        className="previewLabel codeRed"
        key="not0"
      >
        not 
      </span>
      <span
        key="1234563"
      >
        Passport
         &gt; 0; 
        low
      </span>
      )
      <br
        key="cbr1"
      />
    </span>
    <span
      key="condition-2"
    >
      <span
        className="previewLabel mr5"
      >
        or
      </span>
      (
      <span
        key="1234565"
      >
        DEF
        <span>
          <span
            className="previewLabel"
          >
             between 
          </span>
          42, 241; 
        </span>
        high
      </span>
      )
    </span>
  </div>
</div>
`;
