import translate from '../../../helpers/translate';

function localizeString(scope, options) {
  return translate(`document_types.table.${scope}`, options);
}

export const tableHeaders = [{
  accessor: 'name',
  header: localizeString('name'),
  viewable: true
}, {
  accessor: 'status',
  header: localizeString('status'),
  viewable: false
}, {
  accessor: 'category',
  header: localizeString('category'),
  viewable: false
}, {
  accessor: 'created_at',
  header: localizeString('created_at'),
  viewable: false
}, {
  accessor: 'updated_at',
  header: localizeString('updated_at'),
  viewable: false
}];