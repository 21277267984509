/* eslint-disable react/prop-types */
import React from "react";
import { Badge } from '@panwds/react-ui';
import translate from "../../helpers/translate";

import {
  DATA_PATTERN_COL_GEOGRAPHIES,
  DATA_PATTERN_COL_NAME,
  DATA_PATTERN_COL_TECHNIQUE,
  DATA_PATTERN_COL_TYPE,
  DATA_PATTERN_COL_UPDATED_AT
} from '../constants';

function t(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}

const columns = [{
  "link": false,
  "header": t('data_pattern'),
  "accessor": DATA_PATTERN_COL_NAME,
  "viewable": true,
  minWidth: '300px'
},
{
  "link": false,
  "header": t("type"),
  "accessor": DATA_PATTERN_COL_TYPE,
  Cell: ({ row }) => (
    <span>
      {t(row.values.type)}
    </span>
  ),
  width: '180px'
},
{
  "link": false,
  "header": t("technique"),
  "accessor": DATA_PATTERN_COL_TECHNIQUE,
  Cell: ({ row }) => (
    <span>
      {t(row.values.detection_technique)}
    </span>
  ),
  width: '180px'
},
{
  "link": false,
  "header": t("last_modified"),
  "accessor": DATA_PATTERN_COL_UPDATED_AT,
  width: '180px'
},
{
  "link": false,
  "header": t("geographies"),
  "accessor": DATA_PATTERN_COL_GEOGRAPHIES,
  "sort": false,
  Cell: ({ row }) => (
    <span className='tw-space-x-4 geographyBtns'>
      {(row.values.tags?.geography || []).map( reg => {
        return(
          <Badge appearance='teal' shape="circle" key={reg} size='xs'>
            {reg}
          </Badge>
        )
      })}
    </span>
  ),
  width: '180px'
}
];
export default columns;