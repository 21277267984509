import React from 'react';
import { IntlProvider } from 'react-intl';

import { render, fireEvent, screen } from '@testing-library/react';
import '@testing-library/jest-dom/extend-expect';
import { expect as chaiExpect } from 'chai';

import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { shallow } from 'enzyme';
import { act } from 'react-dom/test-utils';

import DLPView from './viewDLP';
import { ALLOW, BLOCK } from '../../constants';

const axiosMock = new MockAdapter(axios);

describe('<DLPView />', () => {
  beforeEach(() => {
    axiosMock.reset();
  });

  it('should match snapshot', () => {
    const wrapper = shallow(
      <DLPView
        loading={false}
        actionOnError={ALLOW}
        updateSettings={() => {}}
        userRole='READ_WRITE'
      />
    );

    expect(wrapper).toMatchSnapshot();
  });

  it('should enable/disable save button and call correct api', async() => {
    axiosMock.onPut().reply(function (config) {
      expect(config.url).toBe('/v1/dlp-ui/data-filtering-profile/setting');
      expect(config.data).toBe('{"type":"file","action_on_error":"block"}');

      return [
        200,
        {
          action_on_error: BLOCK
        }
      ];
    });

    const testUtils = render(
      <IntlProvider locale='en'>
        <DLPView
          loading={false}
          actionOnError={ALLOW}
          updateSettings={() => {}}
          userRole='READ_WRITE'
        />
      </IntlProvider>
    );

    await act(async () => {
      expect(screen.getByRole('radio', { name: 'Allow' })).toBeChecked();
      expect(screen.getByRole('radio', { name: 'Block' })).not.toBeChecked();
      expect(screen.getByText(/Save/i).closest('button')).toBeDisabled();

      fireEvent.click(screen.getByText(/Block/i));
    });

    await act(async () => {
      expect(screen.getByRole('radio', { name: 'Allow' })).not.toBeChecked();
      expect(screen.getByRole('radio', { name: 'Block' })).toBeChecked();
      expect(screen.getByText(/Save/i).closest('button')).not.toBeDisabled();

      fireEvent.click(screen.getByText(/Save/i));
    });
  });
});