import React, {useState} from 'react';
import { Button } from '@panwds/react-ui';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`incidents.index.${scope}`, options);
}

const UserForm = ({user, closePopup, saveUser, deleteUser}) => {
  const [firstName, setFirstName] = useState(user? user.firstName : '');
  const [lastName, setLastName] = useState(user? user.lastName: '');
  const [email, setEmail] = useState(user? user.emailAddress : '');

  const firstNameChanged = (event) => {
    setFirstName(event.target.value);
  }

  const lastNameChanged = (event) => {
    setLastName(event.target.value);
  }

  const emailChanged = (event) => {
    setEmail(event.target.value);
  }

  const deleteClicked = async() => {
    const success = await deleteUser(user);
    if(success) {
      closePopup();
    }
  }

  const saveClicked = async() => {
    user.firstName = firstName;
    user.lastName = lastName;
    user.emailAddress = email;
    const success = await saveUser(user);
    if(success) {
      closePopup();
    }
  }

  return (
   <div className='userForm'>
    <div className='userFormInner tw-p-2'>
      <div className='formLabel'>{t('first_name')}</div>
      <input className='firstName form-control' value={firstName} onChange={firstNameChanged}/>
      <div className='formLabel'>{t('last_name')}</div>
      <input className='lastName form-control' value={lastName} onChange={lastNameChanged}/>
      <div className='formLabel'>{t('email')}</div>
      <input className='email form-control' value={email} onChange={emailChanged}/>
    </div>

    <div className='actionsPane'>
      <Button appearance='clear' addClassName='delete' onClick={deleteClicked} disabled={!user.id}>
        {translate('actions.delete')}
      </Button>
      <Button appearance='clear' addClassName='save' onClick={saveClicked}>
        {translate('actions.save')}
      </Button>
    </div>
  </div>
  );
};

export default UserForm;