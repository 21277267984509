import React from 'react';
import '@testing-library/jest-dom/extend-expect';
import { shallow } from 'enzyme';
import { act } from 'react-dom/test-utils';
import { render, fireEvent, screen } from '@testing-library/react';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import sinon from 'sinon';
import { expect as chaiExpect } from 'chai';

import { Modal } from 'reactstrap';
import { Button } from '@panwds/react-ui';
import toastr from '../../helpers/toastr';
import SftpConfigureModal from './SftpConfigureModal';

const axiosMock = new MockAdapter(axios);

describe('<SftpConfigureModal />', () => {
  let sandbox, wrapper;
  const originalLocAssign = window.location.assign;
  const locationAssignStub = sinon.stub();
  const baseProps = {
    accessEnabled: true,
    edit: true,
    fawkes: true,
    initialStep: 2,
    initialPortNumber: 22,
    initialFolderPath: 'home/folder',
    initialHostname: '127.0.0.1',
    initialPrivateKey: '-----BEGIN RSA PRIVATE KEY-----\n*\n-----END RSA PRIVATE KEY-----',
    initialPgpKey: '-----BEGIN PGP PUBLIC KEY BLOCK-----\n*\n-----END PGP PUBLIC KEY BLOCK-----',
    initialUsername: 'system',
    ngfwEnabled: true,
    onModalClose: () => {},
    showModal: false,
    updateURL: '/api/update',
    validateURL: '/api/validate'
  };
  const mockView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return shallow(<SftpConfigureModal {...props} />);
  };
  const renderView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    render(
      <SftpConfigureModal
        {...props}
      />
    );
  };

  beforeEach(() => {
    delete window.location;
    window.location = { assign: locationAssignStub };

    axiosMock.reset();
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    delete window.location;
    window.location = { assign: originalLocAssign };

    sandbox.restore();
  });

  it('should match snapshot', () => {
    wrapper = mockView();

    expect(wrapper).toMatchSnapshot();
  });

  it('should render', () => {
    wrapper = mockView({ showModal: true });

    chaiExpect(wrapper.getElement()).not.to.be.undefined;
    chaiExpect(wrapper.find(Modal).prop('isOpen')).to.equal(true);
  });

  it('should render 1st step view', () => {
    renderView({
      showModal: true,
      initialStep: 1,
      initialFolderPath: null,
      initialHostname: null,
      initialPrivateKey: null,
      initialPgpKey: null,
      initialUsername: null
    });

    expect(screen.queryByText('You can provide the following additional optional settings:')).toBeInTheDocument();
  });

  it('should handle close/cancel', async () => {
    const handleCloseModalStub = sandbox.stub();

    renderView({
      showModal: true,
      initialStep: 1,
      initialFolderPath: null,
      initialHostname: null,
      initialPrivateKey: null,
      initialPgpKey: null,
      initialUsername: null,
      onModalClose: handleCloseModalStub
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-cancel'));
    });

    chaiExpect(handleCloseModalStub.calledOnce).to.be.true;
  });

  it('should render 2nd step view', async () => {
    renderView({ showModal: true, initialStep: 1 });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    expect(screen.queryByText('Instructions and description of cloud storage bucket configuration process for evidence storage.')).toBeInTheDocument();
  });

  it('should render input error', () => {
    wrapper = mockView({
      showModal: true,
      initialStep: 2,
      initialFolderPath: null,
      initialHostname: null,
      initialPrivateKey: null,
      initialPgpKey: null,
      initialUsername: null,
    });

    axiosMock.onPost().reply(() => [200, {}]);

    wrapper.find(Button).at(2).simulate('click');
    chaiExpect(wrapper.find('p.inputLabel.error').length).to.equal(3);
  });

  it('render step 1 -> step 2 -> Connect -> Input Errors -> step 1', async () => {
    axiosMock.onPost().reply(() => [200, {}]);

    renderView({
      showModal: true,
      initialStep: 1,
      initialFolderPath: undefined,
      initialHostname: undefined,
      initialPrivateKey: undefined,
      initialPgpKey: undefined,
      initialUsername: undefined,
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    expect(screen.queryByText('Input Bucket Details - SFTP')).toBeInTheDocument();

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    expect(screen.queryByText('Input Bucket Details - SFTP')).toBeInTheDocument();
    expect(screen.queryByText('Please input a value for Username')).toBeInTheDocument();

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-previous'));
    });
  });

  it('should render validation failure', async () => {
    axiosMock.onPost().reply(() => [200, {
      developerMessage: 'Error validating the SFTP connection',
      errorCode: '500',
      errorMessage: 'Error validating the SFTP connection ',
      hasError: true,
      totalRecords: 0
    }]);

    renderView({ showModal: true });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    expect(screen.queryByText('Error validating the SFTP connection')).toBeInTheDocument();
  });

  it('should render validation success', async () => {
    axiosMock.onPost().reply((config) => {
      expect(config.data).toBe(
        '{"connection_type":"sftp","connection_data":{"aws_bucket_name":null,"aws_kms_enabled":null,"aws_role_arn":null,"azure_end_point":null,"azure_tenant_id":null,"region":null,"sftp_host_name":"DOGE","sftp_path":"DOGE","sftp_pgp_key":"-----BEGIN PGP PUBLIC KEY BLOCK-----DOGE-----END PGP PUBLIC KEY BLOCK-----","sftp_port":666,"sftp_private_key":"-----BEGIN RSA PRIVATE KEY-----DOGE-----END RSA PRIVATE KEY-----","sftp_user_name":"DOGE"}}'
      );

      return [200, {
        hasError: false,
        totalRecords: 0
      }];
    });

    renderView({ showModal: true });

    await act(async () => {
      fireEvent.change(screen.getByTestId('username-input'), {target: {value: 'DOGE'}});
      fireEvent.change(screen.getByTestId('privateKey-input'), {target: {value: '-----BEGIN RSA PRIVATE KEY-----DOGE-----END RSA PRIVATE KEY-----'}});
      fireEvent.change(screen.getByTestId('pgpKey-input'), {target: {value: '-----BEGIN PGP PUBLIC KEY BLOCK-----DOGE-----END PGP PUBLIC KEY BLOCK-----'}});
      fireEvent.change(screen.getByTestId('hostname-input'), {target: {value: 'DOGE'}});
      fireEvent.change(screen.getByTestId('folderPath-input'), {target: {value: 'DOGE'}});
      fireEvent.change(screen.getByTestId('portNumber-input'), {target: {value: '666'}});
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    expect(screen.queryByText("Connection success. Please click 'Save' to persist the settings.")).toBeInTheDocument();
  });

  it('should handle update bucket for fawkes', async () => {
    const toastrSuccessStub = sandbox.stub(toastr, 'success');

    axiosMock.onPut().reply((config) => {
      expect(config.url).toBe('/api/update');

      return [200, {
        hasError: false,
        totalRecords: 0
      }];
    });

    renderView({
      showModal: true,
      edit: true,
      fawkes: true,
      initialStep: 3
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    chaiExpect(toastrSuccessStub.calledWith('Evidence storage configuration saved.')).to.be.true;
  });

  it('should handle update bucket for standalone', async () => {
    axiosMock.onPut().reply((config) => {
      expect(config.url).toBe('/api/update');

      return [200, {
        hasError: false,
        totalRecords: 0
      }];
    });

    renderView({
      showModal: true,
      edit: true,
      fawkes: false,
      initialStep: 3
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    chaiExpect(locationAssignStub.calledWith('/settings/')).to.be.true;
    chaiExpect(locationAssignStub.calledOnce).to.be.true;
  });

  it('should handle save bucket for fawkes', async () => {
    const toastrSuccessStub = sandbox.stub(toastr, 'success');

    axiosMock.onPost().reply((config) => {
      expect(config.url).toBe('/api/update');

      return [200, {
        hasError: false,
        totalRecords: 0
      }];
    });

    renderView({
      showModal: true,
      edit: false,
      fawkes: true,
      initialStep: 3
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    chaiExpect(toastrSuccessStub.calledWith('Evidence storage configuration saved.')).to.be.true;
  });

  it('should handle save bucket for standalone', async () => {
    axiosMock.onPost().reply((config) => {
      expect(config.url).toBe('/api/update');

      return [200, {
        hasError: false,
        totalRecords: 0
      }];
    });

    renderView({
      showModal: true,
      edit: false,
      fawkes: false,
      initialStep: 3
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });

    chaiExpect(locationAssignStub.calledTwice).to.be.true;
  });

  it('should handle error on edit mode', async () => {
    axiosMock.onPost().reply((config) => {
      expect(config.url).toBe('/api/update');

      return [500, {}];
    });

    renderView({
      showModal: true,
      edit: true,
      fawkes: false,
      initialStep: 3
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });
  });

  it('should handle error on create mode', async () => {
    axiosMock.onPost().reply((config) => {
      expect(config.url).toBe('/api/update');

      return [500, {}];
    });

    renderView({
      showModal: true,
      edit: false,
      fawkes: false,
      initialStep: 3
    });

    await act(async () => {
      fireEvent.click(screen.getByTestId('button-false'));
    });
  });
});