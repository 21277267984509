import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../helpers/translate';
import DataProfileBracketPreview from './DataProfileBracketPreview';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}

const DataProfileDefinition = (props) => {
  const { dataPatternsRules, datasets, primaryExpTree, secondaryExpTree, advanced, schema, primaryProfiles, secondaryProfiles, multi } = props;

  return (
    <div style={{padding: '15px'}}>
      <div className='rule'>
        <p className='form-label conditionLabel'>{t('primary_rule')}</p>
        <DataProfileBracketPreview
          dataPatternRule={dataPatternsRules[0]}
          expTree={primaryExpTree}
          datasets={datasets}
          advanced={advanced}
          schema={schema}
          profiles={primaryProfiles}
          multi={multi}
        />
      </div>
      <hr />

      <div className='rule'>
        <p className='form-label conditionLabel'>{t('secondary_rule')}</p>
        <DataProfileBracketPreview
          dataPatternRule={dataPatternsRules[1]}
          expTree={secondaryExpTree}
          datasets={datasets}
          advanced={advanced}
          schema={schema}
          profiles={secondaryProfiles}
          multi={multi}
        />
      </div>
    </div>
  )
}

DataProfileDefinition.propTypes = {
  dataPatternsRules: PropTypes.array,
  datasets: PropTypes.array,
  primaryExpTree: PropTypes.shape({}),
  secondaryExpTree: PropTypes.shape({}),
  advanced: PropTypes.bool,
  schema: PropTypes.number,
  primaryProfiles: PropTypes.array,
  secondaryProfiles: PropTypes.array,
  multi: PropTypes.bool,
};

DataProfileDefinition.defaultProps = {
  dataPatternsRules: [],
  datasets: [],
  primaryExpTree: {},
  secondaryExpTree: {},
  advanced: true,
  schema: 2,
  primaryProfiles: [],
  secondaryProfiles: [],
  multi: false,
}
export default DataProfileDefinition
