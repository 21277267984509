import React from 'react';
import PropTypes from 'prop-types';
import { Multiselect } from 'multiselect-react-dropdown';
import { FaTimes, FaExclamationCircle } from 'react-icons/fa';
import $ from 'jquery';
import IdentifierDropdown from './IdentifierDropdown';
import BasicDropdown from './BasicDropdown';
import occurrences from './occurrences';

import translate from '../../../helpers/translate';
import matchCriterion from './matchCriterion';

function t(scope, options) {
  return translate(`data_profiles.form.dataset_form.${scope}`, options);
}

const EdmDatasetRuleItem = ({def, position, updateRuleItem, deleteRuleItem, datasets, validatePresence, duplicateDatasets}) => {
  const dataset = datasets.find((d) => d.id === def.edm_dataset_id) || {};

  const handleDatasetChange = (newDataset) => {
    def = {
      edm_dataset_id: newDataset.id,
      name: newDataset.name,
      occurrence_operator_type: 'any',
      occurrence_count: null,
      occurrence_low: null,
      occurrence_high: null,
      primary_fields: [],
      primary_match_criteria: 'all',
      primary_match_any_count: null,
      secondary_fields: [],
      secondary_match_criteria: 'all',
      secondary_match_any_count: null,
      detection_technique: 'edm'
    }
    updateRuleItem(position, def);
  };

  const handleOperatorChange = (operator) => {
    def.occurrence_operator_type = operator;
    if (operator === 'any') {
      def.occurrence_count = null;
      def.occurrence_high = null;
      def.occurrence_low = null;
    }
    if (operator === 'between') {
      def.occurrence_count = null;
    }
    if (operator === 'less_than_equal_to' || operator === 'more_than_equal_to') {
      def.occurrence_high = null;
      def.occurrence_low = null;
    }
    updateRuleItem(position, def);
  };

  const handleOccurrenceChange = (event, type) => {
    if (type === 'count') {
      def.occurrence_count = parseInt(event.target.value, 10);
      def.occurrence_low = null;
      def.occurrence_high = null;
    }
    else if(type ==='low') {
      def.occurrence_low = parseInt(event.target.value, 10);
      def.occurrence_count = null;
    }
    else {
      def.occurrence_high = parseInt(event.target.value, 10);
      def.occurrence_count = null;
    }
    updateRuleItem(position, def);
  };

  const handleCriteriaChange = (event, type) => {
    def[type] = parseInt(event.target.value, 10);
    updateRuleItem(position, def);
  };

  const handleChange = (field, value) => {
    def[field] = value;
    if (field === 'primary_match_criteria' && value === 'all') {
      def.primary_match_any_count = null;
    }
    if (field === 'secondary_match_criteria' && value === 'all') {
      def.secondary_match_any_count = null;
    }
    updateRuleItem(position, def);
  }

  const handleRemoveFields = (field, selectedList) => {
    def[field] = selectedList;
    updateRuleItem(position, def);
  };

  const isDatasetRowValid = () => {
    if (!dataset.id || !def.occurrence_operator_type) {
      return false;
    }
    if (def.occurrence_operator_type === 'more_than_equal_to' && def.occurrence_count > 500) {
      return false;
    }
    if (def.occurrence_operator_type === 'between') {
      if (def.occurrence_low >= def.occurrence_high || def.occurrence_high > 500) {
        return false;
      }
    }
    return true;
  }

  const multiselectParams = (errorState = false) => {
    const mp = {
      showCheckbox: true,
      displayValue: 'name',
      hidePlaceholder: true,
      showArrow: true,
      avoidHighlightFirstOption: true,
      closeIcon: 'cancel',
      closeOnSelect: false,
      style: {
        searchBox: { width: '340px', background: '#fff' },
        inputField: { width: '200px' },
        optionContainer: { width: '340px', background: '#fff' },
        chips: { background: '#EAEBEB', color: '#333', borderRadius: '4px' }
      }
    }
    if (errorState) {
      mp.style.searchBox.border = '1px solid #8F000E'
    }
    return mp;
  }

  const multiselectConfig = () => {
    const primaryKey = `#primarySelect${position}`
    const secondaryKey = `#secondarySelect${position}`
    let multiselectOpen = $('.optionListContainer:visible').length > 0 ? `#${$('.optionListContainer:visible').parent().attr('id')}` : '';
    $(document).on('click', function checkMultiselectDropDowns(event) {
      const target = $(event.target);
      if (target.closest('.search-wrapper').length > 0 && target.closest('.icon_cancel').length === 0) {
        if (target.closest(primaryKey).length > 0) {
          multiselectOpen = multiselectOpen === primaryKey ? '' : primaryKey
          if (multiselectOpen === primaryKey) {
            $(`${primaryKey} .optionListContainer`).show();
          }
          else {
            $(`${primaryKey} .optionListContainer`).hide();
          }
        }
        if (target.closest(secondaryKey).length > 0) {
          multiselectOpen = multiselectOpen === secondaryKey ? '' : secondaryKey
          if (multiselectOpen === secondaryKey) {
            $(`${secondaryKey} .optionListContainer`).show();
          }
          else {
            $(`${secondaryKey} .optionListContainer`).hide();
          }
        }
      }
      else if (target.closest('.optionListContainer').length === 0) {
        multiselectOpen = '';
      }
    });
    const selectedPrimaryNames = def.primary_fields.map(pf => dataset.active_fields?.find(af => af.id === pf)?.name) || [];
    const checkSecondaryFields = `#secondarySelect${position} li`;
    $(checkSecondaryFields).each((i, option) => {
      if (selectedPrimaryNames.includes($(option).text())) {
        $(option).addClass('disabledOption')
      }
      else {
        $(option).removeClass('disabledOption');
      }
    })
    const selectedsecondaryNames = (def.secondary_fields || []).map(sf => dataset.active_fields?.find(af => af.id === sf)?.name) || [];
    const checkPrimaryFields = `#primarySelect${position} li`;
    $(checkPrimaryFields).each((i, option) => {
      if (selectedsecondaryNames.includes($(option).text())) {
        $(option).addClass('disabledOption')
      }
      else {
        $(option).removeClass('disabledOption');
      }
    })
  }

  return (
    <div id={`ruleItem${position}`}>
      {multiselectConfig()}
      <div className='tw-float-right'>
        <FaTimes onClick={() => deleteRuleItem(position)} className='muteText removeBtn' />
      </div>
      <table className='occurrenceFields'>
        <tbody>
          <tr>
            <td>
              <IdentifierDropdown
                onSelect={newDataset => handleDatasetChange(newDataset)}
                value={dataset}
                items={datasets}
                error={(validatePresence && !dataset.id) || def.duplicated}
              />
            </td>
            <td>
              <BasicDropdown
                onSelect={newOccurrence => handleOperatorChange(newOccurrence)}
                value={def.occurrence_operator_type}
                items={occurrences}
                placeholder={t('occurrences')}
                dropClass='occurrence'
                error={validatePresence && !def.occurrence_operator_type}
              />
            </td>
              {['less_than_equal_to', 'more_than_equal_to'].includes(def.occurrence_operator_type) && <td><input placeholder='Count' defaultValue={def.occurrence_count || undefined} onChange={(e) => handleOccurrenceChange(e, 'count')} className={`form-control occurrenceInput ${validatePresence && (!def.occurrence_count || def.occurrence_count > 500) ? 'has-error' : ''}`} /></td> }
              {def.occurrence_operator_type === 'between' && <td><input placeholder='Low' defaultValue={def.occurrence_low || undefined} onChange={(e) => handleOccurrenceChange(e, 'low')} className={`form-control occurrenceInput ${validatePresence && (!def.occurrence_low || def.occurrence_low >= def.occurrence_high) ? 'has-error' : ''}`} /></td> }
              {def.occurrence_operator_type === 'between' && <td><input placeholder='High' defaultValue={def.occurrence_high || undefined} onChange={(e) => handleOccurrenceChange(e, 'high')} className={`form-control occurrenceInput ${validatePresence && (!def.occurrence_high || def.occurrence_high > 500) ? 'has-error' : ''}`} /></td> }
          </tr>
          {
            validatePresence && !isDatasetRowValid() &&
            <tr>
              { dataset.id ?
                <td />
                :
                <td className='errorDetail error'>{t('errors.dataset')}</td>
              }
              { def.occurrence_operator_type ?
                <td />
                :
                <td className='errorDetail error'>{t('errors.occurrences')}</td>
              }
              { def.occurrence_count > 500 ?
                <td className='errorDetail error'>{t('errors.occurrence_cap')}</td>
                :
                <td />
              }
              { def.occurrence_operator_type === 'between' && def.occurrence_high > 500 &&
                <td className='errorDetail error'>{t('errors.occurrence_cap')}</td>
              }
              { def.occurrence_operator_type === 'between' && def.occurrence_low && def.occurrence_high <= 500 && def.occurrence_low >= def.occurrence_high &&
                <td className='errorDetail error'>{t('errors.occurrence_low_high')}</td>
              }
            </tr>
          }
        </tbody>
      </table>

      { duplicateDatasets.includes(dataset.id) &&
        <div className='errorBlock'>
          <FaExclamationCircle className='error' />
          <div>{t('duplicate_dataset_error')}</div>
        </div>
      }

      <div className='fields'>
        <span className='fieldsMatch'>
          <div className='helpText'>{t('match')}</div>
          <BasicDropdown
            onSelect={newMatchType => handleChange('primary_match_criteria', newMatchType)}
            value={def.primary_match_criteria}
            items={matchCriterion}
            dropClass='match-criteria'
            error={validatePresence && !def.primary_match_criteria}
          />
          {def.primary_match_criteria === 'any' &&
            <input placeholder={t('count')} className={`form-control criteraInput${validatePresence && (def.primary_match_any_count >= def.primary_fields.length || !def.primary_match_any_count) ? ' has-error' : ''}`} defaultValue={def.primary_match_any_count} onChange={(e) => handleCriteriaChange(e, 'primary_match_any_count')} />
          }
        </span>
        <div className={`${(validatePresence && def.primary_fields.length === 0) || def.duplicated ? 'dropdownError': ''} fieldDropdown`}>
          {/* TODO: switch to remove all button */}
          <Multiselect
            selectedValues={def.primary_fields.map(pf => dataset.active_fields?.find(af => af.id === pf)) || []}
            id={`primarySelect${position}`}
            onSelect={(selectedList) => handleChange('primary_fields', selectedList.map(f => f.id))}
            onRemove={(selectedList) => handleRemoveFields('primary_fields', selectedList.map(f => f.id))}
            placeholder={t('primary_field')}
            options={dataset.active_fields || []}
            {...multiselectParams(validatePresence && def.primary_fields.length === 0)}
          />
        </div>
        { validatePresence && def.primary_match_criteria === 'any' && def.primary_match_any_count >= def.primary_fields.length &&
          <div className='errorDetail error'>{t('errors.match_any_count')}</div>
        }
        { validatePresence && def.primary_fields.length === 0 &&
          <div className='errorDetail error'>{t('errors.primary_fields')}</div>
        }
      </div>

      <div className='fields'>
        <span className='fieldsMatch'>
          <div className='helpText italic'>{t('and')}</div>
          <BasicDropdown
            onSelect={newMatchType => handleChange('secondary_match_criteria', newMatchType)}
            value={def.secondary_match_criteria}
            items={matchCriterion}
            error={validatePresence && !def.secondary_match_criteria && (def.secondary_fields || []).length > 0}
          />
          {def.secondary_match_criteria === 'any' &&
            <input placeholder={t('count')} className={`form-control criteraInput${validatePresence && (!def.secondary_fields || def.secondary_match_any_count >= def.secondary_fields.length || !def.secondary_match_any_count) ? ' has-error' : ''}`} defaultValue={def.secondary_match_any_count} onChange={(e) => handleCriteriaChange(e, 'secondary_match_any_count')} />
          }
        </span>
        <div className={`${(validatePresence && (def.secondary_fields || []).length === 0) || def.duplicated ? 'dropdownError': ''} fieldDropdown`}>
          <Multiselect
            selectedValues={(def.secondary_fields || []).map(sf => dataset.active_fields?.find(af => af.id === sf)) || []}
            id={`secondarySelect${position}`}
            onSelect={(selectedList) => handleChange('secondary_fields', selectedList.map(f => f.id))}
            onRemove={(selectedList) => handleRemoveFields('secondary_fields', selectedList.map(f => f.id))}
            placeholder={t('secondary_field')}
            options={dataset.active_fields || []}
            {...multiselectParams()}
          />
        </div>
        { validatePresence && def.secondary_match_criteria === 'any' && (!def.secondary_fields || def.secondary_match_any_count >= def.secondary_fields.length) &&
          <div className='errorDetail error'>{t('errors.match_any_count')}</div>
        }
      </div>
    </div>
  );
}

EdmDatasetRuleItem.propTypes = {
  def: PropTypes.shape({
    edm_dataset_id: PropTypes.string,
    primary_fields: PropTypes.array,
    secondary_fields: PropTypes.array,
    primary_match_criteria: PropTypes.string,
    primary_match_any_count: PropTypes.number,
    secondary_match_criteria: PropTypes.string,
    secondary_match_any_count: PropTypes.number,
    occurrence_operator_type: PropTypes.string,
    occurrence_count: PropTypes.number,
    occurrence_high: PropTypes.number,
    occurrence_low: PropTypes.number,
    duplicated: PropTypes.bool,
  }),
  position: PropTypes.string,
  datasets: PropTypes.array,
  updateRuleItem: PropTypes.func,
  deleteRuleItem: PropTypes.func,
  validatePresence: PropTypes.bool,
  duplicateDatasets: PropTypes.array,
};

EdmDatasetRuleItem.defaultProps = {
  def: {},
  position: '0',
  datasets: [],
  updateRuleItem: undefined,
  deleteRuleItem: undefined,
  validatePresence: false,
  duplicateDatasets: [],
}

export default EdmDatasetRuleItem;