import React from 'react';

import { render, fireEvent } from '@testing-library/react';
import '@testing-library/jest-dom';
import selectEvent from 'react-select-event';
import DataPatternCondition from './DataPatternCondition';


describe('<DataPatternCondition />', () => {
  let wrapper;
  const ruleItems = [
    {
      id: '12345',
      name: 'CCN',
      occurrence_operator_type: 'any',
      occurrence_count: null,
      confidence_level: 'high',
      supported_confidence_levels: ['high', 'low']
    },
    {
      id: '12347',
      name: 'Passport',
      occurrence_operator_type: 'any',
      occurrence_count: null,
      confidence_level: 'high',
      supported_confidence_levels: ['high', 'low']
    }
  ];
  const patterns = {
    '12345': {
      id: '12345',
      name: 'CCN',
      supported_confidence_levels: ['high', 'low']
    },
    '12346': {
      id: '12346',
      name: 'PII',
      supported_confidence_levels: ['high', 'low']
    },
    '12347': {
      id: '12347',
      name: 'Passport',
      supported_confidence_levels: ['high', 'low']
    }
  }
  const updateRuleItems = jest.fn();
  const deleteRuleItem = jest.fn();
  const props = {
    ruleItems: ruleItems,
    validatePresence: false,
    dataPatterns: patterns,
    updateRuleItems: updateRuleItems,
    deleteRuleItem: deleteRuleItem
  };

  describe('initial setup', () => {
    beforeEach(() => {
      wrapper = render(<DataPatternCondition {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.container.querySelectorAll('.ruleItem')).toHaveLength(2);
      expect(wrapper.container.querySelectorAll('.occurrenceFields')).toHaveLength(2);
    });

    it('matches snapshot', () => {
      expect(wrapper.asFragment()).toMatchSnapshot();
    });

    it('should update ruleItem name', async() => {
      const idDropdown = wrapper.container.querySelector('.identifier-dropdown svg');
      await selectEvent.select(idDropdown, ['PII'])

      expect(updateRuleItems.mock.calls[0][0]).toEqual(
        expect.arrayContaining(
          [expect.objectContaining({name: 'PII'})]
      ));
    });

    it('should call deleteRuleItem', () => {
      const btn = wrapper.container.querySelector('svg.removeBtn');
      fireEvent.click(btn);
      expect(deleteRuleItem).toHaveBeenCalled();
    });

    it('should change occurrence operator', async() => {
      const dropdown = wrapper.container.querySelector('.basic-dropdown.occurrence');
      fireEvent.click(dropdown);
      const operator = wrapper.getByText('Less than or equal to');
      expect(operator).toBeInTheDocument();
      fireEvent.click(operator);
      expect(updateRuleItems.mock.calls[0][0]).toEqual(
        expect.arrayContaining(
          [expect.objectContaining({
            occurrence_operator_type: "less_than_equal_to"
          })]
      ));
    });

    it('should change occurrence count', () => {
      ruleItems[0].occurrence_operator_type = "less_than_equal_to";
      wrapper = render(<DataPatternCondition {...props}/>);
      const input = wrapper.container.querySelector('input.occurrenceInput');
      fireEvent.focus(input);
      fireEvent.input(input, { target: { value: '3' } });
      expect(updateRuleItems.mock.calls[0][0]).toEqual(
        expect.arrayContaining(
          [expect.objectContaining({
            occurrence_operator_type: "less_than_equal_to",
            occurrence_count: 3,
          })]
      ));
    });

    it('should change confidence level', () => {
      const dropdown = wrapper.container.querySelector('.basic-dropdown.confidence');
      fireEvent.click(dropdown);
      const operator = wrapper.getByText('Low');
      expect(operator).toBeInTheDocument();
      fireEvent.click(operator);
      expect(updateRuleItems.mock.calls[0][0]).toEqual(
        expect.arrayContaining(
          [expect.objectContaining({
            confidence_level: "low"
          })]
      ));
    });
  });

  describe('validations', () => {
    it('should have error for missing dp id', () => {
      const invalidRuleItem = [{
        id: null,
        name: 'CCN',
        occurrence_operator_type: 'any',
        occurrence_count: null,
        confidence_level: 'high',
        supported_confidence_levels: ['high', 'low']
      }];
      const invalidProps = {...props, ruleItems: invalidRuleItem, validatePresence: true};
      wrapper = render(<DataPatternCondition {...invalidProps} />);
      const dpError = wrapper.getByText('Select a data pattern');
      expect(dpError).toBeInTheDocument();
    });
    it('should have error for non-numeric occurrence low', () => {
      const invalidRuleItem = [{
        id: '12345',
        name: 'CCN',
        occurrence_operator_type: 'between',
        occurrence_count: null,
        occurrence_low: 'abc',
        confidence_level: 'high',
        supported_confidence_levels: ['high', 'low']
      }];
      const invalidProps = {...props, ruleItems: invalidRuleItem, validatePresence: true};
      wrapper = render(<DataPatternCondition {...invalidProps} />);
      const error = wrapper.getByText('Number only');
      expect(error).toBeInTheDocument();
    });
    it('should have error for non-numeric occurrence high', () => {
      const invalidRuleItem = [{
        id: '12345',
        name: 'CCN',
        occurrence_operator_type: 'between',
        occurrence_low: 5,
        occurrence_high: 'abc',
        confidence_level: 'high',
        supported_confidence_levels: ['high', 'low']
      }];
      const invalidProps = {...props, ruleItems: invalidRuleItem, validatePresence: true};
      wrapper = render(<DataPatternCondition {...invalidProps} />);
      const error = wrapper.getByText('Number only');
      expect(error).toBeInTheDocument();
    });
    it('should have error for occurrence low > occurrence high', () => {
      const invalidRuleItem = [{
        id: '12345',
        name: 'CCN',
        occurrence_operator_type: 'between',
        occurrence_low: 25,
        occurrence_high: 5,
        confidence_level: 'high',
        supported_confidence_levels: ['high', 'low']
      }];
      const invalidProps = {...props, ruleItems: invalidRuleItem, validatePresence: true};
      wrapper = render(<DataPatternCondition {...invalidProps} />);
      const error = wrapper.getByText('Low value must be less than high value');
      expect(error).toBeInTheDocument();
    });
    it('should have error for occurrence high > 500', () => {
      const invalidRuleItem = [{
        id: '12345',
        name: 'CCN',
        occurrence_operator_type: 'between',
        occurrence_low: 25,
        occurrence_high: 501,
        confidence_level: 'high',
        supported_confidence_levels: ['high', 'low']
      }];
      const invalidProps = {...props, ruleItems: invalidRuleItem, validatePresence: true};
      wrapper = render(<DataPatternCondition {...invalidProps} />);
      const error = wrapper.getByText('Max detection limit is 500');
      expect(error).toBeInTheDocument();
    });
    it('should have error for occurrence count > 500', () => {
      const invalidRuleItem = [{
        id: '12345',
        name: 'CCN',
        occurrence_operator_type: 'more_than_equal_to',
        occurrence_count: 501,
        confidence_level: 'high',
        supported_confidence_levels: ['high', 'low']
      }];
      const invalidProps = {...props, ruleItems: invalidRuleItem, validatePresence: true};
      wrapper = render(<DataPatternCondition {...invalidProps} />);
      const error = wrapper.getByText('Max detection limit is 500');
      expect(error).toBeInTheDocument();
    });
    it('should have error for occurrence count > 500 and operator type less_than_equal_to', () => {
      const invalidRuleItem = [{
        id: '12345',
        name: 'CCN',
        occurrence_operator_type: 'less_than_equal_to',
        occurrence_count: 501,
        confidence_level: 'high',
        supported_confidence_levels: ['high', 'low']
      }];
      const invalidProps = {...props, ruleItems: invalidRuleItem, validatePresence: true};
      wrapper = render(<DataPatternCondition {...invalidProps} />);
      const error = wrapper.getByText('Max detection limit is 500');
      expect(error).toBeInTheDocument();
    });
  });
});