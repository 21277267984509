import React, { useEffect } from 'react';
import '@testing-library/jest-dom/extend-expect';
import { act } from 'react-dom/test-utils';
import { render, fireEvent, screen } from '@testing-library/react';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import sinon from 'sinon';

import DocumentTypes from '../DocumentTypes';
import BulkTable from '../BulkTable/BulkTable';
import FingerprintDrawer from './FingerprintDrawer';
import TestDrawer from './TestDrawer';
import DeleteModal from './DeleteModal';
import DropDownList from '../DropDown/DropDownList';

import {
  DOCUMENT_STATUS_SUCCESS,
  DOCUMENT_STATUS_FAILED,
  DOCUMENT_STATUS_IN_PROGRESS,
  PAST_24_HOURS,
  PAST_7_DAYS,
  PAST_30_DAYS,
  PAST_90_DAYS,
  PAST_ALL
} from '../constants';

jest.mock('../BulkTable/BulkTable', () => jest.fn());
BulkTable.mockImplementation(({
  requestResults,
  updateSortControls,
  actionHandlers,
  customCellRender
}) => {
  useEffect(() => {
    actionHandlers.onRowClick({});
  }, []);

  const dummyFunc = () => {};
  const item = {
    category: 'category',
    description: 'description',
    fingerprint_object_path: 'fingerprint_object_path',
    id: 1,
    name: 'name',
    original_file_name: 'original_file_name',
    original_file_size_in_byte: 'original_file_size_in_byte',
    status_note: 'status_note'
  }

  const handleRequestResults = () => {
    requestResults(1, 10);
  };

  const handleRowClickSuccess = () => {
    actionHandlers.onRowClick({ ...item, status: DOCUMENT_STATUS_SUCCESS });
  };

  const handleRowClickFailed = () => {
    actionHandlers.onRowClick({ ...item, status: DOCUMENT_STATUS_FAILED });
  };

  const handleEditDocumentSuccess = () => {
    actionHandlers.onEdit({ ...item, status: DOCUMENT_STATUS_SUCCESS });
  };

  const handleEditDocumentFailed = () => {
    actionHandlers.onEdit({ ...item, status: DOCUMENT_STATUS_FAILED });
  };

  const handleTestDocumentSuccess = () => {
    actionHandlers.onTest({ ...item, status: DOCUMENT_STATUS_SUCCESS });
  };

  const handleDeleteDocumentSuccess = () => {
    actionHandlers.onDelete({ ...item, status: DOCUMENT_STATUS_SUCCESS });
  };

  const handleDeleteDocumentFailed = () => {
    actionHandlers.onDelete({ ...item, status: DOCUMENT_STATUS_FAILED });
  };

  const handleCustomCellRender = () => {
    customCellRender(dummyFunc, 'status', DOCUMENT_STATUS_SUCCESS);
    customCellRender(dummyFunc, 'status', DOCUMENT_STATUS_FAILED);
    customCellRender(dummyFunc, 'status', DOCUMENT_STATUS_IN_PROGRESS);
    customCellRender(dummyFunc, 'noy-status');
  };

  return (
    <>
      <div data-testid='BulkTable-requestResults' onClick={handleRequestResults}>BulkTable-requestResults</div>
      <div data-testid='BulkTable-updateSortControls' onClick={updateSortControls}>BulkTable-updateSortControls</div>

      { actionHandlers.isRowClickEnabled({ status: DOCUMENT_STATUS_SUCCESS }) &&
        <div data-testid='BulkTable-isRowClickEnabled-SUCCESS' onClick={handleRowClickSuccess}>BulkTable Success Item</div>
      }
      { actionHandlers.isRowClickEnabled({ status: DOCUMENT_STATUS_FAILED }) &&
        <div data-testid='BulkTable-isRowClickEnabled-FAILED' onClick={handleRowClickFailed}>BulkTable Failure Item</div>
      }
      { actionHandlers.isRowClickEnabled({ status: DOCUMENT_STATUS_IN_PROGRESS }) &&
        <div data-testid='BulkTable-isRowClickEnabled-IN-PROGRESS'>BulkTable In-Progress Item</div>
      }

      { actionHandlers.isEditEnabled({ status: DOCUMENT_STATUS_SUCCESS }) &&
        <div data-testid='BulkTable-isEditEnabled-SUCCESS' onClick={handleEditDocumentSuccess}>BulkTable Success Item</div>
      }
      { actionHandlers.isEditEnabled({ status: DOCUMENT_STATUS_FAILED }) &&
        <div data-testid='BulkTable-isEditEnabled-FAILED' onClick={handleEditDocumentFailed}>BulkTable Failure Item</div>
      }
      { actionHandlers.isEditEnabled({ status: DOCUMENT_STATUS_IN_PROGRESS }) &&
        <div data-testid='BulkTable-isEditEnabled-IN-PROGRESS'>BulkTable In-Progress Item</div>
      }

      { actionHandlers.isTestEnabled({ status: DOCUMENT_STATUS_SUCCESS }) &&
        <div data-testid='BulkTable-isTestEnabled-SUCCESS' onClick={handleTestDocumentSuccess}>BulkTable Success Item</div>
      }
      { actionHandlers.isTestEnabled({ status: DOCUMENT_STATUS_FAILED }) &&
        <div data-testid='BulkTable-isTestEnabled-FAILED'>BulkTable Failure Item</div>
      }
      { actionHandlers.isTestEnabled({ status: DOCUMENT_STATUS_IN_PROGRESS }) &&
        <div data-testid='BulkTable-isTestEnabled-IN-PROGRESS'>BulkTable In-Progress Item</div>
      }

      { actionHandlers.isDeleteEnabled({ status: DOCUMENT_STATUS_SUCCESS }) &&
        <div data-testid='BulkTable-isDeleteEnabled-SUCCESS' onClick={handleDeleteDocumentSuccess}>BulkTable Success Item</div>
      }
      { actionHandlers.isDeleteEnabled({ status: DOCUMENT_STATUS_FAILED }) &&
        <div data-testid='BulkTable-isDeleteEnabled-FAILED' onClick={handleDeleteDocumentFailed}>BulkTable Failure Item</div>
      }
      { actionHandlers.isDeleteEnabled({ status: DOCUMENT_STATUS_IN_PROGRESS }) &&
        <div data-testid='BulkTable-isDeleteEnabled-IN-PROGRESS'>BulkTable In-Progress Item</div>
      }

      <div data-testid='BulkTable-customCellRender' onClick={handleCustomCellRender}>BulkTable-customCellRender</div>
    </>
  );
});

jest.mock('./FingerprintDrawer', () => jest.fn());
FingerprintDrawer.mockImplementation(({
  isOpen,
  onClose,
  onDelete,
  onRefresh
}) => {
  const handleDelete = () => {
    onDelete({
      category: 'category',
      description: 'description',
      fingerprint_object_path: 'fingerprint_object_path',
      id: 1,
      name: 'name',
      original_file_name: 'original_file_name',
      original_file_size_in_byte: 'original_file_size_in_byte',
      status_note: 'status_note',
      status: DOCUMENT_STATUS_SUCCESS
    });
  };

  return (
    <>
      { isOpen &&
        <>
          <div data-testid='FingerprintDrawer-onClose' onClick={onClose}>FingerprintDrawer</div>
          <div data-testid='FingerprintDrawer-onDelete' onClick={handleDelete}>FingerprintDrawer</div>
          <div data-testid='FingerprintDrawer-onRefresh' onClick={onRefresh}>FingerprintDrawer</div>
        </>
      }
    </>
  );
});

jest.mock('./TestDrawer', () => jest.fn());
TestDrawer.mockImplementation(({
  isOpen,
  onClose
}) => {
  return (
    <>
      { isOpen &&
        <div data-testid='TestDrawer-onClose' onClick={onClose}>TestDrawer</div>
      }
    </>
  );
});

jest.mock('./DeleteModal', () => jest.fn());
DeleteModal.mockImplementation(({
  isOpen,
  onClose
}) => {
  return (
    <>
      { isOpen &&
        <div data-testid='DeleteModal-onClose' onClick={onClose}>FingerprintDrawer</div>
      }
    </>
  );
});

jest.mock('../DropDown/DropDownList', () => jest.fn());
DropDownList.mockImplementation(({
  onSelect
}) => {
  const handleSelect = () => {
    onSelect(PAST_24_HOURS);
    onSelect(PAST_7_DAYS);
    onSelect(PAST_30_DAYS);
    onSelect(PAST_90_DAYS);
    onSelect(PAST_ALL);
  };

  return (
    <div data-testid='DropDownList-onSelect' onClick={handleSelect}>DropDownList</div>
  );
});

const axiosMock = new MockAdapter(axios);

describe('<DocumentTypes />', () => {
  let sandbox, wrapper;
  const baseProps = {
    lookupApi: '/lookupApi',
    matchApi: '/matchApi'
  };
  const renderView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return render(<DocumentTypes {...props} />);
  };

  beforeEach(() => {
    axiosMock.reset();
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    sandbox.restore();
    jest.resetAllMocks();
  });

  it('should render', async () => {
    axiosMock.onGet().reply(() => [200, []]);

    renderView({});

    await act(async () => {
      fireEvent.click(screen.getByTestId('BulkTable-requestResults'));
      fireEvent.click(screen.getByTestId('BulkTable-updateSortControls'));
      fireEvent.click(screen.getByTestId('BulkTable-customCellRender'));

      expect(screen.queryByTestId('BulkTable-isRowClickEnabled-IN-PROGRESS')).not.toBeInTheDocument();
      expect(screen.queryByTestId('BulkTable-isEditEnabled-IN-PROGRESS')).not.toBeInTheDocument();
      expect(screen.queryByTestId('BulkTable-isTestEnabled-FAILED')).not.toBeInTheDocument();
      expect(screen.queryByTestId('BulkTable-isTestEnabled-IN-PROGRESS')).not.toBeInTheDocument();
      expect(screen.queryByTestId('BulkTable-isDeleteEnabled-IN-PROGRESS')).not.toBeInTheDocument();

      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();
    });

    expect(axiosMock.history.get.length).toBe(1);

    await act(async () => {
      fireEvent.click(screen.getByTestId('BulkTable-isEditEnabled-SUCCESS'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('FingerprintDrawer-onRefresh'));
      fireEvent.click(screen.getByTestId('FingerprintDrawer-onDelete'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).toBeInTheDocument();

      fireEvent.click(screen.getByTestId('DeleteModal-onClose'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('BulkTable-isEditEnabled-FAILED'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('FingerprintDrawer-onClose'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('BulkTable-isRowClickEnabled-SUCCESS'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('TestDrawer-onClose'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('BulkTable-isRowClickEnabled-FAILED'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('FingerprintDrawer-onClose'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('add-new-button'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('DropDownList-onSelect'));
      fireEvent.change(screen.getByTestId('search-input'), {target: {value: '666'}});
      fireEvent.click(screen.getByTestId('FingerprintDrawer-onClose'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('BulkTable-isTestEnabled-SUCCESS'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();

      fireEvent.click(screen.getByTestId('TestDrawer-onClose'));
    });

    await act(async () => {
      expect(screen.queryByTestId('FingerprintDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('TestDrawer-onClose')).not.toBeInTheDocument();
      expect(screen.queryByTestId('DeleteModal-onClose')).not.toBeInTheDocument();
    });
  });
});