import profileDetails  from './profileDataTransformer';

describe('profileDataTransformer', () => {
  let profile = {};
  let patterns = {
    1234560: {
      name: 'SSN'
    },
    1234561: {
      name: 'Passport - US'
    },
    1234562: {
      name: 'Bank - Statements'
    },
    1234563: {
      name: 'CCN'
    },

  };
  let datasets = {
    '1234561': {
      name: 'dataset A'
    },
    '1234564': {
      name: 'dataset B'
    }
  };

  it('returns empty profile details', () => {
    const formatted = profileDetails(profile, patterns, datasets);
    expect(Object.keys(formatted)).toEqual(['id', 'name', 'profile_type', 'updated_at', 'updated_by', 'schema_version', 'type', 'data_patterns', 'multiProfile', 'secondary_rule_eligible']);
    expect(formatted.name).toEqual('Not Found');
    expect(formatted.id).toEqual(undefined);
  });

  it('trims name to max of 32 characters when cloning', () => {
    profile.name = 'a'.repeat(32);
    const formatted = profileDetails(profile, patterns, datasets, true);
    expect(formatted.name.length).toEqual(32);
    expect(formatted.name).toEqual('Copy - aaaaaaaaaaaaaaaaaaaaaaaaa');
  });

  describe('basic profile', () => {
    it('formats basic profile', () => {
      profile.data_patterns_rule_1 = {
        operator_type: 'and',
        data_pattern_rules: [
            {
              "id": "1234560",
              "name": "SSN",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","low"],
              "version": 1,
              "match_type": "include"
            },
            {
              "id": "1234563",
              "name": "Pannier",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","low"],
              "version": 1,
              "match_type": "include"
            },
            {
              "id": "1234561",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "name": "Passport - US",
              "supported_confidence_levels": ["high","medium","low"],
              "version": 1,
              "match_type": "include"
            },
            {
              "id": "1234562",
              "name": "Bank - Statements",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","medium","low"],
              "version": 1,
              "match_type": "include"
            }
        ]
      };
      const formatted = profileDetails(profile, patterns, datasets);
      expect(formatted.advance_data_patterns_rules).toEqual([
        {
          "operators": [],
          "conditions": [
            {
              "operators": [
                "and", "and", "and"
              ],
              "rule_items": [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "match_type": "include",
                  "version": 1
                },
                {
                  "id": "1234563",
                  "name": "CCN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "match_type": "include",
                  "version": 1
                },
                {
                  "id": "1234561",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "name": "Passport - US",
                  "supported_confidence_levels": ["high","medium","low"],
                  "match_type": "include",
                  "version": 1
                },
                {
                  "id": "1234562",
                  "name": "Bank - Statements",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","medium","low"],
                  "match_type": "include",
                  "version": 1
                }
              ]
            }
          ]
        }, {}
      ]);
    });

    it('formats basic profile with only one pattern', () => {
      profile.data_patterns_rule_1 = {
        operator_type: 'and',
        data_pattern_rules: [
            {
              "id": "1234560",
              "name": "SSN",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","low"],
              "version": 1,
              "match_type": "include"
            }
        ]
      };
      const formatted = profileDetails(profile, patterns, datasets);
      expect(formatted.advance_data_patterns_rules).toEqual([
        {
          "operators": [],
          "conditions": [
            {
              "operators": [
                "and"
              ],
              "rule_items": [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "match_type": "include",
                  "version": 1
                }
              ]
            }
          ]
        }, {}
      ]);
    });

    it('formats basic profile with only one exclude pattern', () => {
      profile.data_patterns_rule_1 = {
        operator_type: 'and',
        data_pattern_rules: [
            {
              "id": "1234560",
              "name": "SSN",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","low"],
              "version": 1,
              "match_type": "include"
            }
        ]
      };
      profile.data_patterns_rule_2 = {
        operator_type: 'and_not',
        data_pattern_rules: [
            {
              "id": "1234561",
              "name": "CCN",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","low"],
              "version": 1,
              "match_type": "exclude"
            }
        ]
      };
      const formatted = profileDetails(profile, patterns, datasets);
      expect(formatted.advance_data_patterns_rules).toEqual([
        {
          "operators": [],
          "conditions": [
            {
              "operators": [
                "and"
              ],
              "rule_items": [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "match_type": "include",
                  "version": 1
                }
              ]
            }
          ]
        },
        {
          "operators": [],
          "conditions": [
            {
              "operators": [
                "and_not"
              ],
              "rule_items": [
                {
                  "id": "1234561",
                  "name": "Passport - US",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "match_type": "exclude",
                  "version": 1
                }
              ]
            }
          ]
        }
      ]);
      profile.data_patterns_rule_2 = null;
    });

    it('flips excludes when not is first', () => {
      profile.data_patterns_rule_1 = {
        operator_type: 'and',
        data_pattern_rules: [
          {
            "id": "1234560",
            "name": "SSN",
            "occurrence_operator_type": "any",
            "occurrence_count": null,
            "confidence_level": "high",
            "supported_confidence_levels": ["high","low"],
            "version": 1,
            "match_type": "exclude"
          },
          {
            "id": "1234563",
            "name": "Pannier",
            "occurrence_operator_type": "any",
            "occurrence_count": null,
            "confidence_level": "high",
            "supported_confidence_levels": ["high","low"],
            "version": 1,
            "match_type": "include"
          }
        ]
      };
      const formatted = profileDetails(profile, patterns, datasets);
      expect(formatted.advance_data_patterns_rules).toEqual([
        {
          "operators": ["and_not"],
          "conditions": [
            {
              "operators": ["and"],
              "rule_items": [
                {
                  "id": "1234563",
                  "name": "CCN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                }
              ]
            },
            {
              "operators": [],
              "rule_items": [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "exclude"
                }
              ]
            }
          ]
        }, {}
      ]);
    });

    it('formats basic profile with not operators and rule 2', () => {
      profile.data_patterns_rule_1 = {
        operator_type: 'and',
        data_pattern_rules: [
            {
              "id": "1234560",
              "name": "SSN",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","low"],
              "version": 1,
              "match_type": "include"
            },
            {
              "id": "1234563",
              "name": "Pannier",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","low"],
              "version": 1,
              "match_type": "include"
            },
            {
              "id": "1234561",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "name": "Passport - US",
              "supported_confidence_levels": ["high","medium","low"],
              "version": 1,
              "match_type": "exclude"
            },
            {
              "id": "1234562",
              "name": "Bank - Statements",
              "occurrence_operator_type": "any",
              "occurrence_count": null,
              "confidence_level": "high",
              "supported_confidence_levels": ["high","medium","low"],
              "version": 1,
              "match_type": "exclude"
            }
        ]
      };
      profile.data_patterns_rule_2 = {
        operator_type: 'and',
        data_pattern_rules: [
          {
            "id": "1234560",
            "name": "SSN",
            "occurrence_operator_type": "any",
            "occurrence_count": null,
            "confidence_level": "high",
            "supported_confidence_levels": ["high","low"],
            "version": 1,
            "match_type": "include"
          }
        ]
      };
      const formatted = profileDetails(profile, patterns, datasets);
      expect(formatted.advance_data_patterns_rules).toEqual([
        {
          "operators": ["and_not"],
          "conditions": [
            {
              "operators": [
                "and"
              ],
              "rule_items": [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "match_type": "include",
                  "version": 1
                },
                {
                  "id": "1234563",
                  "name": "CCN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "match_type": "include",
                  "version": 1
                }
              ]
            },
            {
              "operators": [
                "and"
              ],
              "rule_items": [
                {
                  "id": "1234561",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "name": "Passport - US",
                  "supported_confidence_levels": ["high","medium","low"],
                  "match_type": "exclude",
                  "version": 1
                },
                {
                  "id": "1234562",
                  "name": "Bank - Statements",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","medium","low"],
                  "match_type": "exclude",
                  "version": 1
                }
              ]
            }
          ]
        },
        {
          "operators": [],
          "conditions": [
            {
              "operators": ["and"],
              "rule_items": [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "match_type": "include",
                  "version": 1
                }
              ]
            }
          ]
        }
      ]);
    });
  });

  describe('hybrid profile', () => {
    it('sets primaryExpTree and secondaryExpTree', () => {
      profile.data_patterns_rule_1 = undefined;
      profile.data_patterns_rule_2 = undefined;
      profile.detection_rules = [
        {
          rule_type: 'expression_tree',
          expression_tree: {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "between",
                  "occurrence_count": undefined,
                  "occurrence_low": 4,
                  "occurrence_high": 24,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "detection_technique": "ml",
                  "match_type": "include",
                  "version": 1
                },
                sub_expressions: []
              },
              {
                operator_type: null,
                rule_item: {
                  "edm_dataset_id": "1234564",
                  "occurrence_operator_type": "between",
                  "occurrence_low": 5,
                  "occurrence_high": 28,
                  "primary_fields": ['col-1', 'col-2'],
                  "primary_match_criteria": "any",
                  "primary_match_any_count": 1,
                  "confidence_level": "high",
                  "detection_technique": "edm"
                },
                sub_expressions: []
              }
            ]
          }
        },
        {
          "rule_type": "expression_tree",
          "expression_tree": {
            operator_type: null,
            "rule_item": {
              "edm_dataset_id": "1234561",
              "name": "DLPAT001-SM-Test",
              "detection_technique": "edm",
              "match_type": "include",
              "occurrence_operator_type": "any",
              "confidence_level": "high",
              "supported_confidence_levels": ["high", "low"],
              "version": 2
            },
            "sub_expressions": null
          }
        }
      ];
      const formatted = profileDetails(profile, patterns, datasets);
      expect(formatted.primaryExpTree).toEqual(
        {
          operator_type: 'or',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                "id": "1234560",
                "name": "SSN",
                "occurrence_operator_type": "between",
                "occurrence_count": undefined,
                "occurrence_low": 4,
                "occurrence_high": 24,
                "confidence_level": "high",
                "supported_confidence_levels": ["high","low"],
                "detection_technique": "ml",
                "match_type": "include",
                "version": 1
              },
              sub_expressions: []
            },
            {
              operator_type: null,
              rule_item: {
                "edm_dataset_id": "1234564",
                "name": "dataset B",
                "occurrence_operator_type": "between",
                "occurrence_low": 5,
                "occurrence_high": 28,
                "primary_fields": ['col-1', 'col-2'],
                "primary_match_criteria": "any",
                "primary_match_any_count": 1,
                "confidence_level": "high",
                "detection_technique": "edm"
              },
              sub_expressions: []
            }
          ]
        }
      );
      expect(formatted.secondaryExpTree).toEqual({
        "operator_type": 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            "rule_item": {
              "edm_dataset_id": "1234561",
              "name": "dataset A",
              "detection_technique": "edm",
              "match_type": "include",
              "occurrence_operator_type": "any",
              "confidence_level": "high",
              "supported_confidence_levels": ["high", "low"],
              "version": 2
            },
            "sub_expressions": []
          }
        ]
      });
    });
  });
});