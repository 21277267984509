import React, {useEffect, useState} from 'react';
import { Button } from '@panwds/react-ui';
import _uniq from 'lodash.uniq';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
  } from 'reactstrap';

import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`incidents.index.${scope}`, options);
}

const NotesForm = ({notes, closePopup, deleteNotes, saveNotes}) => {
  const [input, setInput] = useState('');
  const [overwriteMultiple, setOverwriteMultiple] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const uniqNotes = _uniq(notes);
    setOverwriteMultiple(uniqNotes.length > 1);
    if (uniqNotes.length === 1) {
      setInput(uniqNotes[0]);
    }
  }, [notes]);

  const inputChanged = (event) => {
    setInput(event.target.value);
  }

  const processDelete = async() => {
    const success = await deleteNotes();
    if(success) {
      closePopup();
    }
  }

  const deleteClicked = async () => {
    if (overwriteMultiple) {
      setShowDeleteModal(true);
    } else {
      processDelete();
    }
  }

  const processSave = async () => {
    const success = await saveNotes(input);
    if(success) {
      closePopup();
    }
  }

  const saveClicked = () => {
    if (overwriteMultiple) {
      setShowUpdateModal(true);
    } else {
      processSave();
    }
  }

  return (
    <div className='notesForm'>
      <div className='notesFormInnter tw-mt-2'>
        <div className='prompt tw-m-2'>{overwriteMultiple? t('overwrite_notes'): t('new_notes')}</div>
        <textarea className='notes form-control' value={input || ''} onChange={inputChanged}/>
      </div>
      <div className='actionsPane tw-mt-4'>
        <Button appearance='clear' addClassName='delete' onClick={deleteClicked}>
          {translate('actions.delete')}
        </Button>
        <Button appearance='clear' addClassName='save' onClick={saveClicked}>
          {translate('actions.save')}
        </Button>
      </div>

      <Modal
        centered
        isOpen={showUpdateModal}>

        <ModalHeader toggle={() => setShowUpdateModal(false)}>
          {t('warning')}
        </ModalHeader>
        <ModalBody>
          <div>{t('update_notes_warning')}</div>
        </ModalBody>
        <ModalFooter>
          <Button addClassName='canel' appearance='secondary' onClick={() => setShowUpdateModal(false)}>
            {translate('actions.cancel')}
          </Button>
          <Button addClassName='save' appearance='primary-destructive' onClick={processSave}>
            {translate('actions.save')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        isOpen={showDeleteModal}>

        <ModalHeader toggle={() => setShowDeleteModal(false)}>
          {t('warning')}
        </ModalHeader>
        <ModalBody>
          <div>{t('delete_notes_warning')}</div>
        </ModalBody>
        <ModalFooter>
          <Button addClassName='canel' appearance='secondary' onClick={() => setShowDeleteModal(false)}>
            {translate('actions.cancel')}
          </Button>
          <Button addClassName='save' appearance='primary-destructive' onClick={processDelete}>
            {translate('actions.delete')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>

  );
}

export default NotesForm;