import React from 'react';
import PropTypes from 'prop-types';
import CheckCircle from '../../../assets/images/check-circle.svg';
import MinusCircle from '../../../assets/images/minus-circle.svg';

class ToggleSwitch extends React.Component {
  state = {
    sliderDisabled: this.props.sliderDisabled,
    checked: this.props.checked,
    changeAction: this.props.changeAction,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      sliderDisabled: nextProps.sliderDisabled,
      checked: nextProps.checked,
      changeAction: nextProps.changeAction,
    };
   }

  render() {
    const { checked, sliderDisabled, changeAction } = this.state;

    const changeValue = () => {
      changeAction();
    };
    if (sliderDisabled) {
      return (
        <span className='switch toggle-element'>
          <input className='change-status-slider' checked={checked} disabled type='checkbox' />
          <span className='slider round disabled'>
            { checked ?
              <img alt="logo" className="toggle-icon checked" src={CheckCircle} />
              : <img alt="logo" className="toggle-icon unchecked" src={MinusCircle} />
            }
          </span>
        </span>
      );
    }
    return (
      <span className='switch toggle-element'>
        <input className='change-status-slider' onChange={() => changeValue} checked={checked} type='checkbox' />
        <span className='slider round' onClick={changeValue}>
          { checked ?
            <img alt="logo" className="toggle-icon checked" src={CheckCircle} />
            : <img alt="logo" className="toggle-icon unchecked" src={MinusCircle} />
          }
        </span>
      </span>
    );
  }
}

ToggleSwitch.propTypes = {
  sliderDisabled: PropTypes.bool,
  checked: PropTypes.bool,
  changeAction: PropTypes.func,
};

ToggleSwitch.defaultProps = {
  sliderDisabled: false,
  checked: false,
  changeAction: undefined,
};

export default ToggleSwitch;
