import React from "react";
import SpinnerIcon from '../../../assets/images/spinner.gif';
import translate from "../../helpers/translate";

const LoadingIndicator = () => {
  return (
    <div className='empty text-center'>
      <img id='loader' src={SpinnerIcon} alt='loading' />
      <br /><br />
      <p>{translate('bulk_table.loading')}</p>
    </div>
  )
};

export default LoadingIndicator;