import React from "react";
//import '@testing-library/react/dont-cleanup-after-each';
import {render, waitFor, fireEvent} from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import AzureConfigureModal from "./AzureConfigureModal";

const axiosMock = new MockAdapter(axios);
axiosMock.onPost().reply(200, {});
axiosMock.onPut().reply(200, {});

describe('<AzureConfigureModal/>', () => {
  const props = {
    initialState: 1,
    edit: true,
    showModal: true,
    initialTenantId: '',
    initialEndpoint: '',
    modalClosed: () => {}
  };

  let testUtils;
  beforeEach(() => {
    testUtils = render(<AzureConfigureModal {...props}/> );
  });

  test('should render the title', async() => {
    expect(await testUtils.findByTestId('testRoot')).toBeInTheDocument();
    expect(testUtils.getAllByText('Instructions - Azure')).toHaveLength(2);
  });

  test('should go to the 2nd screen', () => {
    let nextBtn = testUtils.getByText('Next');
    fireEvent.click(nextBtn);
    expect(testUtils.getByText('Input Bucket Details - Azure')).toBeInTheDocument();
  });

  test('should call validate API', () => {
    let nextBtn = testUtils.getByText('Next');
    fireEvent.click(nextBtn);
    let tenantInput = testUtils.getByTestId('tenantId-input');
    let endpointInput = testUtils.getByTestId('endpoint-input');

    fireEvent.focus(tenantInput);
    fireEvent.input(tenantInput, { target: { value: 'test-tenant' } });
    fireEvent.focus(endpointInput);
    fireEvent.input(endpointInput, { target: { value: 'https://test.windows.net' } });

    nextBtn = testUtils.getByText('Connect');
    fireEvent.click(nextBtn);

    expect(JSON.parse(axiosMock.history.post[0].data)).toEqual(expect.objectContaining({
      connection_data: {
        aws_bucket_name: null,
        aws_role_arn: null,
        azure_end_point: 'https://test.windows.net',
        azure_tenant_id: 'test-tenant',
        region: null
      },
      connection_type: 'azure'
    }));
  });

});