import translate from '../../helpers/translate';

export default [
  {
    value: 'past_60_minutes',
    label: translate('incidents.index.scan_dates.hour'),
  },
  {
    value: 'past_24_hours',
    label: translate('incidents.index.scan_dates.day'),
  },
  {
    value: 'past_7_days',
    label: translate('incidents.index.scan_dates.week'),
  },
  {
    value: 'past_30_days',
    label: translate('incidents.index.scan_dates.month'),
  },
  {
    value: 'past_90_days',
    label: translate('incidents.index.scan_dates.three_months'),
  },
];