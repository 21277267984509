import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import Item from './Item/Item';
import translate from '../../helpers/translate';
import SpinnerIcon from '../../../assets/images/spinner.gif';

function t(scope, options) {
  return translate(`dashboards.index.${scope}`, options);
}

class ServiceStatusTable extends React.Component {
  state = {
    data: [],
    loading: true
  };

  telemetryDetails = (telemetry) => {
    if (!telemetry.telemetry) { return []; }

    return telemetry.telemetry.map( item => {
      return(
        {
          name: this.props.portalType === 'casb' ? translate('dlp') : translate(item.service_name, {platform: true}),
          status: item.status,
          lastUpdatedAt: item.last_updated_at
        }
      )
    });
  }

  componentDidMount() {
    if (!this.props.lookupURL) { return undefined; }
    return axios(
      this.props.lookupURL
    ).then(
      result => { this.setState({ data: this.telemetryDetails(result.data), loading: false }); }
    ).catch(
      () => { this.setState({ data: [], loading: false }); }
    );
  }

  render () {
    const { data, loading } = this.state;
    return (
      <>
        <div className="title card-title">
          {t('table_title')}
        </div>
        <div className="body">
          {loading ?
            <div className='empty'>
              <img id='loader' src={SpinnerIcon} alt='loading' />
              <br /><br />
              <p>{t('loading')}</p>
            </div>
            :
            data.map(item => <Item key={item.name} {...item} />)
          }
        </div>
      </>
    );
  }
}

ServiceStatusTable.propTypes = {
  lookupURL: PropTypes.string,
  portalType: PropTypes.string,
};

ServiceStatusTable.defaultProps = {
  lookupURL: undefined,
  portalType: 'dlp',
}
export default ServiceStatusTable
