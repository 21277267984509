import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {IntlProvider} from 'react-intl';
import PropTypes from 'prop-types';
import { Multiselect } from 'multiselect-react-dropdown';
import { useTable } from 'react-table';
import { Button, DateRangePicker, Tooltip } from '@panwds/react-ui';
import $ from 'jquery';

import {
  FaPen,
  FaCopy,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDown,
  FaTimes,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaEllipsisV,
  FaTerminal,
  FaTrash
} from 'react-icons/fa';

import translate from '../../helpers/translate';
import DropDownList from '../DropDown/DropDownList';
import perPageOptions from './perPageOptions';

import EmptyListIcon from '../../../assets/images/empty-list.svg';
import FilterIcon from '../../../assets/images/filter.svg';
import SpinnerIcon from '../../../assets/images/spinner.gif';

import {
  ASC,
  DSC,
  DATA_PROFILE_COL_NAME,
  EDIT,
  TEST,
  DELETE
} from '../constants';

const moment = require('moment-timezone');

function t(scope, options) {
  return translate(`bulk_table.${scope}`, options);
}

const BulkTable = ({
  defaultSortBy,
  defaultSortOrder,
  actionable,
  actionHandlers,
  bulkActionsComponent,
  columns,
  data,
  filters,
  loading,
  pageData,
  requestResults,
  selectable,
  selectedItems,
  sortable,
  sortExclusionList,
  showDateRangePicker,
  updateFilters,
  updateSortControls,
  updateState,
  useRouter,
  customCellRender
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  useEffect(() => {
    return () => {
      // Clean up event handlers before component unmount
      $(document).off('click.bulkTable.filter');
      $(document).off('click.bulkTable.action');
    }
  }, []);

  if (pageData.currentPage >= pageData.totalPages) {
    pageData.currentPage = 0
  }

  if (filters.filterable) {
    $(document).on('click.bulkTable.filter', function checkFilterDropDowns(event) {
      const target = $(event.target);
      if ($('.optionListContainer').is(':visible') && target.closest('.optionListContainer').length === 0) {
        $('.optionListContainer').hide();
        // updateState({showDateRangePicker: false})
      }
    });
  }

  if (actionable || actionHandlers) {
    $(document).on('click.bulkTable.action', function checkActionDropDowns(event) {
      const target = $(event.target);
      if ($('.actionMenu').is(':visible') && target.closest('.actionBtn').length === 0) {
        $('.actionMenu').hide();
        $('.actionBtn').removeClass('active');
      }
    })
  }

  const tableInstance = useTable({ columns, data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const pageOptions = (max) => {
    const list = [];
    for (let i=0; i<max; i+=1) {
      list.push({value: i, label: i+1});
    }
    return list;
  }

  const checkAll = () => {
    if ($("th input[type='checkbox']").prop('checked')) {
      $('td input[type="checkbox"]').prop('checked', 'checked');
      const ids = [];
      $('td input[type="checkbox"]').each((index, elem) => {
        ids.push(elem.id);
     });
      updateState({ selectedItems: ids });
    }
    else {
      $('td input[type="checkbox"]').prop('checked', false);
      updateState({ selectedItems: [] });
    }
  }

  const selectItem = (id) => {
    const ids = selectedItems;
    if ($(`td input#${id}`).prop('checked')) {
      ids.push(id);
      updateState({ selectedItems: ids });
    }
    else {
      ids.splice( $.inArray(id, ids), 1 );
      updateState({ selectedItems: ids });
    }
    if ($('td input:checked').length === ($('td input').length)) {
      $("th input[type='checkbox']").prop('checked', 'checked');
    }
    else {
      $("th input[type='checkbox']").prop('checked', false);
    }
  }

  const toggleActionMenu = (id) => {
    if ($(`.actionMenu${id}:visible`).length > 0) {
      $(`.actionMenu${id}`).hide();
      $('.actionBtn').removeClass('active');
    }
    else {
      $('.actionMenu').hide();
      $('.actionBtn').removeClass('active');
      $(`.actionMenu${id}`).show();
      $(`.actionBtn${id}`).addClass('active');
    }
  }

  const renderSortIcon = colName => {
    if (sortExclusionList.includes(colName)) {
      return undefined;
    }

    if (sortBy === colName) {
      if (sortOrder === ASC) {
        return <FaSortDown className='sortIcon active' />
      }

      return <FaSortUp className='sortIcon active' />
    }

    return <FaSort className='sortIcon' />
  }

  const renderActionMenuItem = (type, item, onActionFunc, isEnabledFunc) => {
    let classname;
    let localizationKey;
    let componentIcon;

    if (!onActionFunc) {
      return undefined;
    }

    switch (type) {
      case EDIT: {
        classname = 'edit';
        localizationKey = 'edit';
        componentIcon = <FaPen />;
        break;
      }

      case TEST: {
        classname = 'test';
        localizationKey = 'test';
        componentIcon = <FaTerminal />;
        break;
      }

      case DELETE: {
        classname = 'delete';
        localizationKey = 'delete';
        componentIcon = <FaTrash />;
        break;
      }

      default:
        return undefined;
    }

    if (isEnabledFunc && isEnabledFunc(item)) {
      return (
        <div data-testid={`row-action-${type}-${item.id}`} className={classname} onClick={() => onActionFunc(item)}>
          {componentIcon}
          {t(localizationKey)}
        </div>
      );
    }

    return (
      <div className={`${classname} disabled`}>
        {componentIcon}
        {t(localizationKey)}
      </div>
    );
  };

  const updateSort = (colName) => {
    if (sortable && updateSortControls) {
      let newSortOrder = ASC;

      if (sortBy === colName) {
        if (sortOrder === ASC) {
          newSortOrder = DSC;
        }
      }

      setSortBy(colName);
      setSortOrder(newSortOrder);

      updateSortControls({
        sortBy: colName,
        sortOrder: newSortOrder
      });
    }
  }

  const tableData = () => {
    if (data.length > 0) {
      return(
        <table className='table' {...getTableProps()}>
          <thead>
            {
            headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                { selectable &&
                  <th className='checkboxCol' aria-label='check-all' role='columnheader'><input type='checkbox' onClick={() => checkAll()} /></th>
                }
                {
                headerGroup.headers.map(column => (
                  <th className={column.id} colid={column.id} {...column.getHeaderProps()}>
                    {column.render('header')}
                    { sortable &&
                      <span onClick={() => updateSort(column.id)}>{renderSortIcon(column.id)}</span>
                    }
                  </th>
                ))}
                { (actionable || actionHandlers) &&
                  <th className='actions'>{t('actions')}</th>
                }
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
            rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  { selectable &&
                    <td><input type='checkbox' checked={selectedItems.includes(row.original.id)} id={row.original.id} onClick={ () => selectItem(row.original.id) } onChange={() => {}} /></td>
                  }
                  {
                    row.cells.map(cell => {
                      if (cell.column.link) {
                        if (useRouter) {
                          return (
                            <td {...cell.getCellProps()}>
                              <Link to={row.original.link}>{cell.render('Cell')}</Link>
                            </td>
                          )
                        }
                          return (
                            <td {...cell.getCellProps()}>
                              <a href={row.original.link}>{cell.render('Cell')}</a>
                            </td>
                          )
                      }
                      if (cell.column.viewable) {
                        if (row.original.viewableOverride) {
                          return(
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )
                        }

                        if (actionHandlers?.onRowClick) {
                          if (actionHandlers?.isRowClickEnabled(row.original)) {
                            return (
                              <td {...cell.getCellProps()}>
                                <span data-testid={`row-clickable-${row.original.id}`} className='itemName' onClick={() => actionHandlers.onRowClick(row.original)}>{cell.render('Cell')}</span>
                                {row.original.outdated && <span className='oldLabel'>Old</span>}
                              </td>
                            );
                          }

                          return (
                            <td {...cell.getCellProps()}>
                              <span>{cell.render('Cell')}</span>
                              {row.original.outdated && <span className='oldLabel'>Old</span>}
                            </td>
                          );
                        }

                        return (
                          <td {...cell.getCellProps()}>
                            <span className='itemName' onClick={() => updateState({ viewing: row.original.id })}>{cell.render('Cell')}</span>
                            {row.original.outdated && <span className='oldLabel'>Old</span>}
                          </td>
                        )
                      }
                      if (cell.column.searchable) {
                        return (
                          <td {...cell.getCellProps()}>
                            <span className='itemName' onClick={() => updateState({ searching: row.original.userId })}>{cell.render('Cell')}</span>
                          </td>
                        )
                      }
                      return (
                        <td {...cell.getCellProps()} colid={cell.column.id}>
                          { ['created_at', 'updated_at'].includes(cell.column.id) && cell.value ?
                            moment.utc(cell.value).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z')
                            :
                            customCellRender(cell.render('Cell'), cell.column.id, cell.value)
                          }
                        </td>
                      )
                    })
                  }
                  { (actionable || actionHandlers) &&
                    <td className='actions'>
                      <FaEllipsisV data-testid={`row-action-menu-${row.id}`} className={`actionBtn actionBtn${row.id}`} onClick={() => toggleActionMenu(row.id)} />
                      <div className={`actionMenu${row.id} actionMenu`}>
                        { actionable?.editable && actionable.editable(row) &&
                          <div className='edit' onClick={() => updateState({editing: row.original.id})}>
                            <FaPen />
                            {t('edit')}
                          </div>
                        }
                        { actionable?.editable && !actionable.editable(row) &&
                          <div className='edit disabled'>
                            <FaPen />
                            {t('edit')}
                          </div>
                        }
                        { actionable?.cloneable && actionable.cloneable(row) &&
                          <div className='clone' onClick={() => updateState({cloning: row.original.id})}>
                            <FaCopy />
                            {t('clone')}
                          </div>
                        }
                        { actionable?.cloneable && !actionable.cloneable(row) &&
                          <div className='clone disabled'>
                            <FaCopy />
                            {t('clone')}
                          </div>
                        }
                        {renderActionMenuItem(EDIT, row?.original, actionHandlers?.onEdit, actionHandlers?.isEditEnabled)}
                        {renderActionMenuItem(TEST, row?.original, actionHandlers?.onTest, actionHandlers?.isTestEnabled)}
                        {renderActionMenuItem(DELETE, row?.original, actionHandlers?.onDelete, actionHandlers?.isDeleteEnabled)}
                      </div>
                    </td>
                  }
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    }
    return(
      <div className='empty text-center'>
        <img src={EmptyListIcon} alt='empty' />
        <br /><br />
        <p>{t('no_results_available')}</p>
      </div>
    )
  }

  const addFilter = (selectedFilters) => {
    const newFilterSelections = {}
    selectedFilters.forEach(filter => {
      newFilterSelections[filter.id] = filters.currentFilterSelections[filter.id] || []
    })
    updateFilters(selectedFilters, newFilterSelections);
  }

  const updateFilterSelection = (filter, selections) => {
    const updatedSelection = { }
    updatedSelection[filter] = selections
    const newFilterSelections = { ...filters.currentFilterSelections, ...updatedSelection }
    updateFilters(filters.currentFilters, newFilterSelections, true);
  }

  const filterDateRange = (range) => {
    return `${new Date(range.start).toDateString()} - ${new Date(range.end).toDateString()}`
  }

  const removeFilter = (selectedFilters) => {
    const stickyFilters = filters.currentFilters.filter(f => f.sticky);
    const newFilterSelections = {}
    const newFilters = [...selectedFilters, ...stickyFilters];
    newFilters.forEach(filter => {
      newFilterSelections[filter.id] = filters.currentFilterSelections[filter.id]
    })
    updateFilters(newFilters, newFilterSelections);
  }

  const removeFilterFromSelection = (filterToRemove) => {
    const newFilterSelections = filters.currentFilterSelections;
    delete newFilterSelections[filterToRemove];
    const newFilters = [];
    filters.currentFilters.forEach(filter => {
      if (filter.id !== filterToRemove) {
        newFilters.push(filter)
      }
    })
    updateFilters(newFilters, newFilterSelections, true);
  }

  const resetFilters = () => {
    const stickyFilters = filters.currentFilters.filter(f => f.sticky);
    if (stickyFilters.length === 0) {
      updateFilters([], {}, true, true);
    }
    else {
      const stickyFilterSelections = stickyFilters.map(f => f.id);
      Object.keys(filters.currentFilterSelections).forEach(f => {
        if (!stickyFilterSelections.includes(f)) { delete filters.currentFilterSelections[f] }
        else if (f === 'time') {
          filters.currentFilterSelections[f] = [{id: 'past_30_days', name: 'Past 30 Days'}]
        }
      })
      updateFilters(stickyFilters, filters.currentFilterSelections, true, true);
    }
  }

  const selectionDisplay = (filter) => {
    if (filters.currentFilterSelections[filter.id] && filters.currentFilterSelections[filter.id].length > 0) {
      if (filter.multiple) {
        return filters.currentFilterSelections[filter.id].map(selection => selection.name).join(', ')
      }
      return filters.currentFilterSelections[filter.id].map(selection => selection.name).join(', ')
    }
    if (filter.multiple) {
      return <span>{`Select ${filter.name}`} <FaAngleDown /></span>;
    }
    return `Enter ${filter.name}`
  }

  const toggleOptions = (filter) => {
    setTimeout(() => {
      $(`.${filter.id} .optionListContainer`).toggle();
    if ($(`.${filter.id} .optionListContainer input.search`).length === 0) {
      $(`.selectedFilters .${filter.id} ul.optionContainer`).prepend(`<input class='form-control search' id=${filter.id} placeholder='Search...' />`);
    }
    $('input.search').on('keyup', function filterFilterOptions() {
      const id = $(this).attr('id');
      const searchText = $(this).val().toLowerCase()
      $(`.${id} .optionContainer li`).each(function checkLi() {
        if ($(this).text().toLowerCase().includes(searchText)) {
          $(this).removeClass('hidden');
        }
        else {
          $(this).addClass('hidden');
        }
      })
    });
    });
  }

  const toggleAddFiltersMenu = () => {
    setTimeout(() => {
      $('.filtersToSelect .optionListContainer').toggle()
    });

  }

  return (
    <div className='bulkTable'>
      { filters.filterable &&
        <div className='filters'>
          <div className='filtersToSelect'>
            <span className='filtersTrigger' onClick={toggleAddFiltersMenu}>
              <Tooltip label={t('add_filter')} hasTriangle>
                <img src={FilterIcon} alt='filter' className='mr5' />
              </Tooltip>
              {filters.currentFilters.length === 0 && <span className='btn-link'>{t('add_new_filter')}</span>}
            </span>
            <Multiselect
              selectedValues={filters.currentFilters}
              options={filters.filterOptions}
              id='filterOptionsDropdown'
              displayValue='name'
              avoidHighlightFirstOption
              hidePlaceholder
              disablePreSelectedValues={filters.currentFilters.filter(f => f.sticky).length > 0}
              showCheckbox
              closeOnSelect={false}
              onSelect={(option) => addFilter(option)}
              onRemove={(option) => removeFilter(option)}
              style={
                {
                  chips: { background: '#EAEBEB', color: '#333', borderRadius: '4px' }
                }
              }
            />
          </div>
          <div className='selectedFilters'>
            { filters.currentFilters.map(filter =>
              <span key={filter.id} className={`filter ${filter.id}`}>
                <span className='filterName'>{!filter.sticky && <FaTimes className='muteText mr5 small' onClick={() => removeFilterFromSelection(filter.id)} />} {filter.name}</span>
                <span className='filterValue'>
                  <span className='filterValueDisplay btn-link' onClick={() => toggleOptions(filter)}>{selectionDisplay(filter)}</span>
                    { filters[filter.id] &&
                      <Multiselect
                        selectedValues={filters.currentFilterSelections[filter.id]}
                        options={filters[filter.id]}
                        id={`${filter.id}-filter-options`}
                        displayValue='name'
                        avoidHighlightFirstOption
                        hidePlaceholder
                        showCheckbox
                        singleSelect={filters.filterOptions?.find(fo => fo.id === filter.id)?.multiple === false}
                        closeOnSelect={false}
                        onSelect={(selections) => updateFilterSelection(filter.id, selections)}
                        onRemove={(selections) => updateFilterSelection(filter.id, selections)}
                        style={
                          {
                            optionContainer: { minWidth: '150px', background: '#FFF', width: 'max-content' },
                            chips: { background: '#EAEBEB', color: '#333', borderRadius: '4px' }
                          }
                        }
                      />
                    }
                    { filter.id === 'time' && showDateRangePicker &&
                      <div className='dateRangePicker'>
                        <IntlProvider locale='en'>
                          <DateRangePicker
                            onApply={(range) => updateFilterSelection('time', [{id:  range, name: filterDateRange(range)}])}
                            onCancel={() => updateState({showDateRangePicker: false})}
                          />
                        </IntlProvider>
                      </div>
                    }
                </span>
              </span>
            )}
          </div>
          <span onClick={() => resetFilters()} className='tw-float-right reset btn-link'>{t('reset')}</span>
        </div>
      }
      {
        bulkActionsComponent
      }
      { loading ?
        <div className='empty text-center'>
          <img id='loader' src={SpinnerIcon} alt='loading' />
          <br /><br />
          <p>{t('loading')}</p>
        </div>
        :
        tableData()
      }
      { !loading &&
        <div className='footer'>
          { selectable && <span>{`${selectedItems.length} selected | `}</span> }
          <span>{`Displaying ${data.length} results of ${pageData.totalCount}`}</span>
          { (data.length>0) && pageData.pageCount &&
            <span className='tw-float-right'>
              Rows
              <DropDownList
                dropClass='rows mx-2'
                upwards
                onSelect={newRowCount => requestResults(pageData.currentPage, newRowCount)}
                value={pageData.pageCount}
                items={perPageOptions}
                size='sm'
              />
              Page
              { pageData.totalPages > 0 ?
                <span className='pageDrop'>
                  <DropDownList
                    dropClass='pages tw-mx-2'
                    upwards
                    onSelect={newPageNum => requestResults(newPageNum, pageData.pageCount)}
                    value={pageData.currentPage}
                    items={pageOptions(pageData.totalPages)}
                    buttonInput
                    size='sm'
                  />
                </span>
                :
                <span className='tw-mr5 tw-ml5'>0</span>
              }
              of {pageData.totalPages}
              <span className='prevNext tw-inline-flex tw-space-x-1'>
                <Button disabled={pageData.currentPage === 0} icon={<FaAngleLeft />} onClick={() => requestResults(pageData.currentPage - 1, pageData.pageCount)} aria-label='previous'/>
                <Button disabled={pageData.currentPage === pageData.totalPages - 1} icon={<FaAngleRight />} onClick={() => requestResults(pageData.currentPage + 1, pageData.pageCount)} aria-label='next'/>
              </span>
            </span>
          }
        </div>
      }
    </div>
  )
}

BulkTable.propTypes = {
  data: PropTypes.array,
  defaultSortBy: PropTypes.string,
  defaultSortOrder: PropTypes.string,
  columns: PropTypes.array,
  selectedItems: PropTypes.array,
  pageData: PropTypes.shape({
    pageCount: PropTypes.number,
    currentPage: PropTypes.number,
    totalCount: PropTypes.number,
    totalPages: PropTypes.number
  }),
  requestResults: PropTypes.func,
  updateState: PropTypes.func,
  updateSortControls: PropTypes.func,
  updateFilters: PropTypes.func,
  actionable: PropTypes.shape({
    deletable: PropTypes.func,
    editable: PropTypes.func,
    cloneable: PropTypes.func
  }),
  actionHandlers: PropTypes.shape({
    isRowClickEnabled: PropTypes.func,
    isEditEnabled: PropTypes.func,
    isTestEnabled: PropTypes.func,
    isDeleteEnabled: PropTypes.func,
    onRowClick: PropTypes.func,
    onEdit: PropTypes.func,
    onTest: PropTypes.func,
    onDelete: PropTypes.func
  }),
  selectable: PropTypes.bool,
  sortable: PropTypes.bool,
  sortExclusionList: PropTypes.array,
  loading: PropTypes.bool,
  filters: PropTypes.shape({
    filterable: PropTypes.bool,
    filterOptions: PropTypes.array,
    currentFilters: PropTypes.array,
    currentFilterSelections: PropTypes.shape({})
  }),
  useRouter: PropTypes.bool,
  bulkActionsComponent: PropTypes.element,
  showDateRangePicker: PropTypes.bool,
  customCellRender: PropTypes.func
};

BulkTable.defaultProps = {
  data: [],
  defaultSortBy: ASC,
  defaultSortOrder: DATA_PROFILE_COL_NAME,
  columns: [],
  selectable: false,
  selectedItems: [],
  sortable: false,
  sortExclusionList: [],
  pageData: {
    pageCount: undefined,
    currentPage: 0,
    totalCount: 0,
    totalPages: 1
  },
  requestResults: null,
  updateState: null,
  updateSortControls: null,
  updateFilters: null,
  actionable: undefined,
  actionHandlers: undefined,
  loading: false,
  filters: {
    filterable: false,
    filterOptions: [],
    currentFilters: [],
    currentFilterSelections: {},
  },
  useRouter: false,
  bulkActionsComponent: undefined,
  showDateRangePicker: false,
  customCellRender: (func) => (func)
}

export default BulkTable;