/* eslint-disable func-names */

import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import Header from './Header';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<Header>', () => {
  let wrapper;

  describe('Component to have basic classes', () => {
    it('renders', () => {
      wrapper = shallow(<Header />);
      expect(wrapper.find('.header')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });
  });
});
