// Usage:
// Use wild char * for specific value only. Do Not use for ranges
// '10.2.4' -> Show if panosVersion greater than equal to '10.2.4'
// '10.2.* -> Show if panosVersion is between '10.2.0' to '10.2.999'
// '10.*.* -> Show if panosVersion is between '10.0.0' to '10.999.999'
// ['10.2.4', '10.999.999'] -> Show if panosVersion is between '10.2.4' to '10.999.999' (includes both)
// [['10.2.4', '10.999.999'], '11.0.2'] -> Show if panosVersion is
//                                       between '10.2.4' to '10.999.999' (includes both) or
//                                       greater than equal to 11.0.2

export const DOWNLOAD_SUPPORT = [
  [ '10.2.4', '10.999.999' ],
  '11.0.2'
];

export const NEBULA_FLAG = [ '10.2.*' ];