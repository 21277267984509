import React from 'react';
import PropTypes from 'prop-types';
import { FaSync, FaTimes } from 'react-icons/fa';
import translate from '../../helpers/translate';

const moment = require('moment-timezone');

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const dateClasses = ['createdDate', 'expiryDate'];

const t = (scope, options) => translate(`api_tokens.index.${scope}`, options);

const Table = ({columns, data, updateState, userRole}) => {
  return (
    <div className='bulkTable'>
      <table className='table'>
        <thead className='table-bordered'>
          <tr>
            {(userRole === 'READ_WRITE' ? columns : columns.filter(c => c.class !== 'action_item')).map(column =>
              <th key={column.title} className={`${column.class} table-bordered`}>
                {t(column.title)}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map(item =>
            <tr key={item.id}>
              { columns.filter(function filterDataColumns(column) {
                  if (column.class === 'action_item') { return false; } return true;
                }).map(column =>
                <td key={column.title} className={column.class}>
                  {
                    dateClasses.includes(column.class) ?
                    moment(item[column.class]).tz(timezone).format('MMMM D YYYY') :
                    item[column.class]
                  }
                </td>
              )}
              {userRole === 'READ_WRITE' &&
                <td>
                  <div className='text-center action_item' onClick={() => {
                      updateState('renewRevokeModal', 'revoke', item);
                    }}
                  >
                    <FaTimes />
                  </div>
                </td>
              }
              {userRole === 'READ_WRITE' &&
                <td>
                  <div className='text-center action_item' onClick={() => {
                      updateState('renewRevokeModal', 'renew', item)
                    }}
                  >
                    <FaSync />
                  </div>
                </td>
              }
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};


Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateState: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default Table;