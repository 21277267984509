import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import DataProfileDefinition from './DataProfileDefinition';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<DataProfileDefinition>', () => {
  let wrapper;
  const datasetA = {
    id: '1234564',
    name: 'Dataset A',
    index_version: 1,
    index_status: 'success',
    all_fields: [],
    active_fields: [
      {id: 'col-1', name: 'SSN', data_type_name: 'Social Security Number'},
      {id: 'col-2', name: 'CCN', data_type_name: 'Credit Card Number'},
      {id: 'col-3', name: 'Subscriber ID', data_type_name: 'Subscriber ID'},
      {id: 'col-4', name: 'Member ID', data_type_name: 'Member ID'}
    ]
  };
  const datasetB = {
    id: '1234565',
    name: 'Dataset B',
    index_version: 1,
    index_status: 'success',
    active_fields: [
      {id: 'col-1', name: 'SSN', data_type_name: 'Social Security Number'},
      {id: 'col-2', name: 'CCN', data_type_name: 'Credit Card Number'},
      {id: 'col-3', name: 'BankNum', data_type_name: 'Bank Account Numbers'}
    ]
  };
  const primaryExpTree = {
    operator_type: 'and',
    rule_item: null,
    sub_expressions: [
      {
        operator_type: null,
        rule_item: {
          id: '1234560',
          occurrence_operator_type: 'any',
          occurrence_count: null,
          confidence_level: 'high',
          name: 'SSN',
          supported_confidence_levels: [
            'high',
            'low'
          ],
          detection_technique: 'regex',
          version: 1
        },
        sub_expressions: [],
      },
      {
        operator_type: 'or',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  id: '1234561',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  confidence_level: 'high',
                  name: 'CCN',
                  supported_confidence_levels: [
                    'high',
                    'low'
                  ],
                  detection_technique: 'regex',
                  version: 1
                },
                sub_expressions: []
              },
              {
                operator_type: null,
                rule_item: {
                  edm_dataset_id: '1234565',
                  name: 'Dataset B',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  occurrence_low: null,
                  occurrence_high: null,
                  primary_fields: [
                    'col-1',
                    'col-2'
                  ],
                  primary_match_criteria: 'all',
                  primary_match_any_count: null,
                  secondary_fields: [],
                  secondary_match_criteria: 'all',
                  secondary_match_any_count: null,
                  detection_technique: 'edm'
              },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      {
        operator_type: 'not',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
                edm_dataset_id: '1234564',
                name: 'Dataset A',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                occurrence_low: null,
                occurrence_high: null,
                primary_fields: [
                  'col-1',
                  'col-3'
                ],
                primary_match_criteria: 'all',
                primary_match_any_count: null,
                secondary_fields: [],
                secondary_match_criteria: 'all',
                secondary_match_any_count: null,
                detection_technique: 'edm'
            },
            sub_expressions: []
          }
        ]
      }
    ]
  };
  const secondaryExpTree = {
    operator_type: 'or',
    rule_item: null,
    sub_expressions: [
      {
        operator_type: null,
        rule_item: {
          id: '1234560',
          occurrence_operator_type: 'any',
          occurrence_count: null,
          confidence_level: 'high',
          name: 'SSN',
          supported_confidence_levels: [
            'high',
            'low'
          ],
          detection_technique: 'regex',
          version: 1
        },
        sub_expressions: [],
      },
      {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  id: '1234561',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  confidence_level: 'high',
                  name: 'CCN',
                  supported_confidence_levels: [
                    'high',
                    'low'
                  ],
                  detection_technique: 'regex',
                  version: 1
                },
                sub_expressions: []
              },
              {
                operator_type: null,
                rule_item: {
                  edm_dataset_id: '1234565',
                  name: 'Dataset B',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  occurrence_low: null,
                  occurrence_high: null,
                  primary_fields: [
                    'col-1',
                    'col-2'
                  ],
                  primary_match_criteria: 'all',
                  primary_match_any_count: null,
                  secondary_fields: [],
                  secondary_match_criteria: 'all',
                  secondary_match_any_count: null,
                  detection_technique: 'edm'
              },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      {
        operator_type: 'and',
        rule_item: {
          edm_dataset_id: '1234564',
          name: 'Dataset A',
          occurrence_operator_type: 'any',
          occurrence_count: null,
          occurrence_low: null,
          occurrence_high: null,
          primary_fields: [
            'col-1',
            'col-3'
          ],
          primary_match_criteria: 'all',
          primary_match_any_count: null,
          secondary_fields: [],
          secondary_match_criteria: 'all',
          secondary_match_any_count: null,
          detection_technique: 'edm'
        },
        sub_expressions: []
      }
    ]
  };
  const dataPatternRule = {
    operators: ['and'],
    conditions: [
      {
        operators: ['or'],
        rule_items: [
          { "id": "1234560", "name": "SSN", "occurrence_operator_type": "between", "occurrence_low": 4, "occurrence_high": 24, "confidence_level": "high", "supported_confidence_levels": ["high","low"], "version": 1 },
          { "id": "1234561", "name": "CCN", "occurrence_operator_type": "less_than_equal_to", "occurrence_count": 9, "confidence_level": "medium", "supported_confidence_levels": ["high","medium","low"], "version": 1 }
        ]
      },
      {
        operators: ['or_not'],
        rule_items: [
          { "id": "1234562", "name": "Bank", "occurrence_operator_type": "between", "occurrence_low": 42, "occurrence_high": 241, "confidence_level": "high", "supported_confidence_levels": ["high","low"], "version": 1 },
          { "id": "1234563", "name": "Passport", "occurrence_operator_type": "any", "confidence_level": "low", "supported_confidence_levels": ["high","low"], "version": 1 }
        ]
      },
    ]
  };
  const v1DataPatternRule = {
    operators: ['and', 'or'],
    conditions: [
      {
        operators: ['or', 'and_not'],
        rule_items: [
          { "id": "1234560", "name": "SSN", "occurrence_operator_type": "between", "occurrence_low": 4, "occurrence_high": 24, "confidence_level": "high", "supported_confidence_levels": ["high","low"], "version": 1 },
          { "id": "1234561", "name": "CCN", "occurrence_operator_type": null, "occurrence_count": null, "confidence_level": "medium", "supported_confidence_levels": ["high","medium","low"], "version": 1 },
          { "id": "1234566", "name": "ABC", "occurrence_operator_type": null, "occurrence_count": null, "confidence_level": "medium", "supported_confidence_levels": ["high","medium","low"], "version": 1 }
        ]
      },
      {
        operators: ['or_not'],
        rule_items: [
          { "id": "1234562", "name": "Bank", "occurrence_operator_type": "between", "occurrence_low": 42, "occurrence_high": 241, "confidence_level": "high", "supported_confidence_levels": ["high","low"], "version": 1 },
          { "id": "1234563", "name": "Passport", "occurrence_operator_type": "any", "confidence_level": "low", "supported_confidence_levels": ["high","low"], "version": 1 }
        ]
      },
      {
        operators: [],
        rule_items: [
          { "id": "1234565", "name": "DEF", "occurrence_operator_type": "between", "occurrence_low": 42, "occurrence_high": 241, "confidence_level": "high", "supported_confidence_levels": ["high","low"], "version": 1 }
        ]
      },
    ]
  };

  it('renders hybrid preview', () => {
    wrapper = shallow(<DataProfileDefinition datasets={[datasetA, datasetB]} primaryExpTree={primaryExpTree} secondaryExpTree={secondaryExpTree} />);
    expect(wrapper).toMatchSnapshot();
  });

  it('renders pattern only preview', () => {
    wrapper = shallow(<DataProfileDefinition dataPatternsRules={[dataPatternRule, {}]} />);
    expect(wrapper).toMatchSnapshot();
  });

  it('renders v1 multiop pattern only preview', () => {
    wrapper = shallow(<DataProfileDefinition dataPatternsRules={[v1DataPatternRule, {}]} />);
    expect(wrapper).toMatchSnapshot();
  });
});
