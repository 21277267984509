import React from 'react';
import '@testing-library/jest-dom';
import renderWithRouter from '../../helpers/setupTests';

import FilteringProfileDetailsInfo from './FilteringProfileDetailsInfo';

const profile =
  {
    id: 1,
    name: 'Test profile 1',
    profile_type: 'custom',
    description: 'abc',
    updated_at: 1663873109869,
    profile_id: 11995103,
    action: 'upload',
    log_severity: 'low',
    fileBased: 'yes',
    nonFileBased: 'no',
    version: 2,
    file_type: ['any']
  };

test('renders the fields', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDetailsInfo profile={profile} />);
  expect(getByText('Name')).toBeInTheDocument();
  expect(getByText('Mode')).toBeInTheDocument();
  expect(getByText('Description')).toBeInTheDocument();
  expect(getByText('Last Modified')).toBeInTheDocument();
  expect(getByText('Data Profile')).toBeInTheDocument();
  expect(getByText('Direction')).toBeInTheDocument();
  expect(getByText('File Type')).toBeInTheDocument();
  expect(getByText('Action')).toBeInTheDocument();
  expect(getByText('Log Severity')).toBeInTheDocument();
  expect(getByText('File Based Match Criteria')).toBeInTheDocument();
  expect(getByText('Non-File Based Match Criteria')).toBeInTheDocument();
});
