import React from 'react';
import { SvgIcon } from '@panwds/icons';

const IncidentsIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox='0 0 13 16'>
      <path d='M12.8511 3.8125C12.8511 3.625 12.7573 3.4375 12.6323 3.28125L9.56982 0.21875C9.41357 0.09375 9.22607 0 9.03857 0H8.85107V4H12.8511V3.8125ZM12.8511 5H8.60107C8.16357 5 7.85107 4.6875 7.85107 4.25V0H1.60107C1.16357 0 0.851074 0.34375 0.851074 0.75V15.25C0.851074 15.6875 1.16357 16 1.60107 16H12.1011C12.5073 16 12.8511 15.6875 12.8511 15.25V5ZM5.60107 6.90625C5.60107 6.6875 5.75732 6.5 5.97607 6.5H7.69482C7.91357 6.5 8.06982 6.6875 8.06982 6.90625L7.85107 10.4062C7.81982 10.625 7.66357 10.75 7.47607 10.75H6.22607C6.00732 10.75 5.85107 10.625 5.85107 10.4062L5.60107 6.90625ZM8.10107 12.5C8.10107 13.2188 7.53857 13.75 6.85107 13.75C6.13232 13.75 5.60107 13.2188 5.60107 12.5C5.60107 11.8125 6.13232 11.25 6.85107 11.25C7.53857 11.25 8.10107 11.8125 8.10107 12.5Z' />
    </SvgIcon>
  );
};

export default IncidentsIcon;