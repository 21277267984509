import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import CloseIcon from '../../../Icons/CloseIcon';

import './styles.scss';

const Modal = ({
  addClassName,
  children,
  isOpen,
  onClose,
  showMask,
  title
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <main className='dlp-modal'>
      { showMask &&
        <section className='modal-backdrop'
          onClick={onClose}
        />
      }

      <div className={ClassNames('modal-main', addClassName)}>
        <section className='modal-header'>
          <span className='modal-title'>{title}</span>
          <span className='close-icon' onClick={onClose}><CloseIcon size='xs' /></span>
        </section>

        {children}
      </div>
    </main>
  );
}

Modal.propTypes = {
  addClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showMask: PropTypes.bool,
  title: PropTypes.string.isRequired
};

Modal.defaultProps = {
  addClassName: '',
  isOpen: false,
  onClose: () => {},
  showMask: true
};

export default Modal;