// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<FingerprintDrawer /> should match snapshot when closed 1`] = `
<IntlProvider
  defaultFormats={Object {}}
  defaultLocale="en"
  fallbackOnEmptyString={true}
  formats={Object {}}
  locale="en"
  messages={Object {}}
  onError={[Function]}
  onWarn={[Function]}
  textComponent={Symbol(react.fragment)}
>
  <FingerprintDrawer
    apiEndpoint="/apiEndpoint"
    apiPostfix=""
    isOpen={false}
    mode="new"
    onClose={[Function]}
    onDelete={[Function]}
    onRefresh={[Function]}
    savedFingerprintsArr={Array []}
  >
    <SidePanel
      addClassName="drawer-container"
      isOpen={false}
      onClose={[Function]}
      showMask={true}
      title="New Document Type"
    />
  </FingerprintDrawer>
</IntlProvider>
`;

exports[`<FingerprintDrawer /> should render when open EDIT 1`] = `
<IntlProvider
  defaultFormats={Object {}}
  defaultLocale="en"
  fallbackOnEmptyString={true}
  formats={Object {}}
  locale="en"
  messages={Object {}}
  onError={[Function]}
  onWarn={[Function]}
  textComponent={Symbol(react.fragment)}
>
  <FingerprintDrawer
    apiEndpoint="/apiEndpoint"
    apiPostfix=""
    category="category"
    description="description"
    fileObj={
      Object {
        "name": "saved file name",
        "size": 666,
      }
    }
    id={666}
    isOpen={true}
    mode="edit"
    onClose={[Function]}
    onDelete={[Function]}
    onRefresh={[Function]}
    savedFingerprintsArr={
      Array [
        Object {
          "name": "duplicate name",
        },
        Object {
          "name": "saved file name",
        },
      ]
    }
  >
    <SidePanel
      addClassName="drawer-container"
      isOpen={true}
      onClose={[Function]}
      showMask={true}
      title="Edit Fingerprint"
    >
      <main
        className="dlp-side-panel"
      >
        <section
          className="panel-backdrop"
          onClick={[Function]}
        />
        <div
          className="panel-main drawer-container"
        >
          <section
            className="panel-header"
          >
            <span
              className="panel-title"
            >
              Edit Fingerprint
            </span>
            <span
              className="close-icon"
              onClick={[Function]}
            >
              <CloseIcon
                size="xs"
              >
                <SvgIcon
                  size="xs"
                  viewBox="0 0 12 12"
                >
                  <svg
                    aria-hidden={true}
                    className="tw-fill-current"
                    focusable={false}
                    height="12px"
                    role="img"
                    viewBox="0 0 12 12"
                    width="12px"
                  >
                    <path
                      d="M11.7338 0.275313C11.3788 -0.0796357 10.8055 -0.0796357 10.4505 0.275313L6 4.71672L1.54949 0.266212C1.19454 -0.0887372 0.62116 -0.0887372 0.266212 0.266212C-0.0887372 0.62116 -0.0887372 1.19454 0.266212 1.54949L4.71672 6L0.266212 10.4505C-0.0887372 10.8055 -0.0887372 11.3788 0.266212 11.7338C0.62116 12.0887 1.19454 12.0887 1.54949 11.7338L6 7.28328L10.4505 11.7338C10.8055 12.0887 11.3788 12.0887 11.7338 11.7338C12.0887 11.3788 12.0887 10.8055 11.7338 10.4505L7.28328 6L11.7338 1.54949C12.0796 1.20364 12.0796 0.621161 11.7338 0.275313Z"
                    />
                  </svg>
                </SvgIcon>
              </CloseIcon>
            </span>
          </section>
          <main
            className="drawer-main"
          >
            <section
              className="title-area"
            >
              Information
            </section>
            <EditFingerprintForm
              category="category"
              description="description"
              filesArr={
                Array [
                  Object {
                    "name": "saved file name",
                    "size": 666,
                  },
                ]
              }
              hasError={false}
              id={666}
              name=""
              onInputChange={[Function]}
            >
              <section
                className="content-area"
              >
                <p
                  className="input-label"
                >
                  Technique
                </p>
                <Select
                  disabled={true}
                  items={
                    Array [
                      Object {
                        "value": "Indexed Document Match (IDM)",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={
                    Array [
                      Object {
                        "value": "Indexed Document Match (IDM)",
                      },
                    ]
                  }
                >
                  <SelectWithoutSearch
                    disabled={true}
                    items={
                      Array [
                        Object {
                          "value": "Indexed Document Match (IDM)",
                        },
                      ]
                    }
                    onChange={[Function]}
                    selectedItem={
                      Array [
                        Object {
                          "value": "Indexed Document Match (IDM)",
                        },
                      ]
                    }
                  >
                    <div
                      className=""
                    >
                      <Button
                        appearance="secondary"
                        aria-expanded={false}
                        aria-haspopup="listbox"
                        aria-labelledby="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-label field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-toggle-button"
                        buttonType="default"
                        data-testid="select-trigger"
                        disabled={true}
                        fullWidth={false}
                        id="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-toggle-button"
                        isMenu={true}
                        isOpen={false}
                        size="md"
                      >
                        <button
                          aria-expanded={false}
                          aria-haspopup="listbox"
                          aria-labelledby="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-label field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-toggle-button"
                          className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-cursor-not-allowed tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 tw-opacity-40"
                          data-testid="select-trigger"
                          disabled={true}
                          id="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-toggle-button"
                          type="button"
                        >
                          <TruncateText
                            addClassName="tw-flex-auto"
                          >
                            <span
                              className="tw-font-lato tw-truncate tw-flex-auto"
                              data-testid="truncate-indexed-document-match-(idm)"
                            >
                              <span>
                                Indexed Document Match (IDM)
                              </span>
                            </span>
                          </TruncateText>
                          <MenuIcon
                            buttonType="default"
                            isBtnGroup={false}
                            isOpen={false}
                            isSplit={false}
                            size="md"
                          >
                            <span
                              className="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <FaIcon
                                addClassName="tw-transform tw-transition-transform tw-duration-200"
                                color="currentColor"
                                faIconDef={
                                  Object {
                                    "icon": Array [
                                      256,
                                      512,
                                      Array [],
                                      "f107",
                                      "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                                    ],
                                    "iconName": "angle-down",
                                    "prefix": "fal",
                                  }
                                }
                                iconProps={Object {}}
                                secondaryColor="currentColor"
                                size="md"
                              >
                                <svg
                                  aria-hidden={true}
                                  className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable={false}
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                    key="0"
                                  />
                                </svg>
                              </FaIcon>
                            </span>
                          </MenuIcon>
                        </button>
                      </Button>
                      <Portal>
                        <Portal
                          containerInfo={
                            <div
                              data-testid="portal"
                            >
                              <div
                                class="tw-z-30"
                                style="position: fixed; left: 0px; top: 0px; margin: 0px;"
                              >
                                <ul
                                  aria-labelledby="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-label"
                                  class="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-menu"
                                  role="listbox"
                                  style="min-width: 11rem; max-height: 17rem;"
                                  tabindex="-1"
                                />
                              </div>
                            </div>
                          }
                        >
                          <div
                            className="tw-z-30"
                          >
                            <SelectList
                              enableMultiSelect={false}
                              fitToItemWidth={false}
                              getItemProps={[Function]}
                              getMenuProps={[Function]}
                              highlightedIndex={-1}
                              isLoading={false}
                              isOpen={false}
                              items={
                                Array [
                                  Object {
                                    "value": "Indexed Document Match (IDM)",
                                  },
                                ]
                              }
                              selectAllItem={Object {}}
                              selectedItems={
                                Array [
                                  Object {
                                    "value": "Indexed Document Match (IDM)",
                                  },
                                ]
                              }
                              selectedValues={
                                Array [
                                  "Indexed Document Match (IDM)",
                                ]
                              }
                              showSelectAll={false}
                            >
                              <List
                                aria-labelledby="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-label"
                                id="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-menu"
                                isOpen={false}
                                onBlur={[Function]}
                                onKeyDown={[Function]}
                                onMouseLeave={[Function]}
                                role="listbox"
                                style={
                                  Object {
                                    "margin": "0.1rem 0",
                                    "maxHeight": "",
                                  }
                                }
                                tabIndex={-1}
                              >
                                <ul
                                  aria-labelledby="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-label"
                                  className="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-3145f447-3972-4eb1-9d49-0a68044cf72f-label-menu"
                                  onBlur={[Function]}
                                  onKeyDown={[Function]}
                                  onMouseLeave={[Function]}
                                  role="listbox"
                                  style={
                                    Object {
                                      "maxHeight": "17rem",
                                      "minWidth": "11rem",
                                    }
                                  }
                                  tabIndex={-1}
                                />
                              </List>
                            </SelectList>
                          </div>
                        </Portal>
                      </Portal>
                    </div>
                  </SelectWithoutSearch>
                </Select>
                <p
                  className="input-label"
                >
                  Name
                  <span
                    className="required-indicator"
                  />
                </p>
                <input
                  className="form-control name"
                  data-testid="name-input"
                  disabled={true}
                  name="name"
                  onChange={[Function]}
                  placeholder="Document type name"
                  value=""
                />
                <p
                  className="input-label"
                >
                  Category
                  <span
                    className="required-indicator"
                  />
                </p>
                <Select
                  disabled={false}
                  items={
                    Array [
                      Object {
                        "value": "Academic",
                      },
                      Object {
                        "value": "Confidential",
                      },
                      Object {
                        "value": "Employment",
                      },
                      Object {
                        "value": "Financial",
                      },
                      Object {
                        "value": "Government",
                      },
                      Object {
                        "value": "Legal",
                      },
                      Object {
                        "value": "Marketing",
                      },
                      Object {
                        "value": "Source Code",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={
                    Array [
                      Object {
                        "value": "category",
                      },
                    ]
                  }
                >
                  <SelectWithoutSearch
                    disabled={false}
                    items={
                      Array [
                        Object {
                          "value": "Academic",
                        },
                        Object {
                          "value": "Confidential",
                        },
                        Object {
                          "value": "Employment",
                        },
                        Object {
                          "value": "Financial",
                        },
                        Object {
                          "value": "Government",
                        },
                        Object {
                          "value": "Legal",
                        },
                        Object {
                          "value": "Marketing",
                        },
                        Object {
                          "value": "Source Code",
                        },
                      ]
                    }
                    onChange={[Function]}
                    selectedItem={
                      Array [
                        Object {
                          "value": "category",
                        },
                      ]
                    }
                  >
                    <div
                      className=""
                    >
                      <Button
                        appearance="secondary"
                        aria-expanded={false}
                        aria-haspopup="listbox"
                        aria-labelledby="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-label field-bd94544e-79fa-45f9-9683-c769c62f5644-label-toggle-button"
                        buttonType="default"
                        data-testid="select-trigger"
                        disabled={false}
                        fullWidth={false}
                        id="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-toggle-button"
                        isMenu={true}
                        isOpen={false}
                        onClick={[Function]}
                        onKeyDown={[Function]}
                        size="md"
                      >
                        <button
                          aria-expanded={false}
                          aria-haspopup="listbox"
                          aria-labelledby="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-label field-bd94544e-79fa-45f9-9683-c769c62f5644-label-toggle-button"
                          className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
                          data-testid="select-trigger"
                          id="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-toggle-button"
                          onClick={[Function]}
                          onKeyDown={[Function]}
                          type="button"
                        >
                          <TruncateText
                            addClassName="tw-flex-auto"
                          >
                            <span
                              className="tw-font-lato tw-truncate tw-flex-auto"
                              data-testid="truncate-category"
                            >
                              <span>
                                category
                              </span>
                            </span>
                          </TruncateText>
                          <MenuIcon
                            buttonType="default"
                            isBtnGroup={false}
                            isOpen={false}
                            isSplit={false}
                            size="md"
                          >
                            <span
                              className="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <FaIcon
                                addClassName="tw-transform tw-transition-transform tw-duration-200"
                                color="currentColor"
                                faIconDef={
                                  Object {
                                    "icon": Array [
                                      256,
                                      512,
                                      Array [],
                                      "f107",
                                      "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                                    ],
                                    "iconName": "angle-down",
                                    "prefix": "fal",
                                  }
                                }
                                iconProps={Object {}}
                                secondaryColor="currentColor"
                                size="md"
                              >
                                <svg
                                  aria-hidden={true}
                                  className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable={false}
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                    key="0"
                                  />
                                </svg>
                              </FaIcon>
                            </span>
                          </MenuIcon>
                        </button>
                      </Button>
                      <Portal>
                        <Portal
                          containerInfo={
                            <div
                              data-testid="portal"
                            >
                              <div
                                class="tw-z-30"
                                style="position: fixed; left: 0px; top: 0px; margin: 0px;"
                              >
                                <ul
                                  aria-labelledby="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-label"
                                  class="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-menu"
                                  role="listbox"
                                  style="min-width: 11rem; max-height: 17rem;"
                                  tabindex="-1"
                                />
                              </div>
                            </div>
                          }
                        >
                          <div
                            className="tw-z-30"
                          >
                            <SelectList
                              enableMultiSelect={false}
                              fitToItemWidth={false}
                              getItemProps={[Function]}
                              getMenuProps={[Function]}
                              highlightedIndex={-1}
                              isLoading={false}
                              isOpen={false}
                              items={
                                Array [
                                  Object {
                                    "value": "Academic",
                                  },
                                  Object {
                                    "value": "Confidential",
                                  },
                                  Object {
                                    "value": "Employment",
                                  },
                                  Object {
                                    "value": "Financial",
                                  },
                                  Object {
                                    "value": "Government",
                                  },
                                  Object {
                                    "value": "Legal",
                                  },
                                  Object {
                                    "value": "Marketing",
                                  },
                                  Object {
                                    "value": "Source Code",
                                  },
                                ]
                              }
                              selectAllItem={Object {}}
                              selectedItems={
                                Array [
                                  Object {
                                    "value": "category",
                                  },
                                ]
                              }
                              selectedValues={
                                Array [
                                  "category",
                                ]
                              }
                              showSelectAll={false}
                            >
                              <List
                                aria-labelledby="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-label"
                                id="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-menu"
                                isOpen={false}
                                onBlur={[Function]}
                                onKeyDown={[Function]}
                                onMouseLeave={[Function]}
                                role="listbox"
                                style={
                                  Object {
                                    "margin": "0.1rem 0",
                                    "maxHeight": "",
                                  }
                                }
                                tabIndex={-1}
                              >
                                <ul
                                  aria-labelledby="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-label"
                                  className="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-bd94544e-79fa-45f9-9683-c769c62f5644-label-menu"
                                  onBlur={[Function]}
                                  onKeyDown={[Function]}
                                  onMouseLeave={[Function]}
                                  role="listbox"
                                  style={
                                    Object {
                                      "maxHeight": "17rem",
                                      "minWidth": "11rem",
                                    }
                                  }
                                  tabIndex={-1}
                                />
                              </List>
                            </SelectList>
                          </div>
                        </Portal>
                      </Portal>
                    </div>
                  </SelectWithoutSearch>
                </Select>
                <p
                  className="input-label"
                >
                  Description
                </p>
                <textarea
                  className="form-control description"
                  data-testid="description-input"
                  disabled={false}
                  name="description"
                  onChange={[Function]}
                  placeholder="Describe the document type."
                  value="description"
                />
                <section
                  className="files-section"
                >
                  <article
                    className="title"
                  >
                    Source File
                    <span
                      className="required-indicator"
                    />
                  </article>
                  <article
                    className="file-details disabled"
                    key="file_0"
                  >
                    <section
                      className="file-info"
                    >
                      <i
                        className="file-status"
                      >
                        <SuccessIcon
                          size="sm"
                        >
                          <FaIcon
                            addClassName="tw-text-risk-2"
                            aria-label="Success"
                            color="currentColor"
                            faIconDef={
                              Object {
                                "icon": Array [
                                  512,
                                  512,
                                  Array [],
                                  "f058",
                                  "M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z",
                                ],
                                "iconName": "check-circle",
                                "prefix": "fas",
                              }
                            }
                            iconProps={Object {}}
                            secondaryColor="currentColor"
                            size="sm"
                          >
                            <svg
                              aria-label="Success"
                              className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-3.5 tw-w-3.5 tw-text-risk-2"
                              role="img"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                                fill="currentColor"
                                key="0"
                              />
                            </svg>
                          </FaIcon>
                        </SuccessIcon>
                      </i>
                      <span
                        className="name"
                      >
                        saved file name
                      </span>
                      <span
                        className="size"
                      >
                        666 B
                      </span>
                    </section>
                    <div
                      className="progress-bar"
                    />
                    <i
                      className="delete-icon disabled"
                    >
                      <FaTrash>
                        <IconBase
                          attr={
                            Object {
                              "viewBox": "0 0 448 512",
                            }
                          }
                        >
                          <svg
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            strokeWidth="0"
                            style={
                              Object {
                                "color": undefined,
                              }
                            }
                            viewBox="0 0 448 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                              key="0"
                            />
                          </svg>
                        </IconBase>
                      </FaTrash>
                    </i>
                  </article>
                </section>
              </section>
            </EditFingerprintForm>
          </main>
          <div
            className="error-message"
          />
          <section
            className="drawer-footer tw-flex tw-justify-end tw-space-x-1"
          >
            <Button
              addClassName="cancel"
              appearance="secondary"
              buttonType="default"
              disabled={false}
              isMenu={false}
              onClick={[Function]}
              size="md"
            >
              <button
                className="cancel tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
                data-testid="button-cancel"
                onClick={[Function]}
                type="button"
              >
                Cancel
              </button>
            </Button>
            <LoadingButton
              appearance="primary"
              dataMetrics="loading-primary-button"
              dataResult={
                Object {
                  "loading": false,
                }
              }
              disabled={true}
              onClickCallback={[Function]}
            >
              <div
                className="tw-flex tw-space-x-2"
              >
                <Button
                  appearance="primary"
                  buttonType="default"
                  disabled={true}
                  isLoading={false}
                  isMenu={false}
                  onClick={[Function]}
                  size="md"
                >
                  <button
                    className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-cursor-not-allowed tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500 tw-opacity-40"
                    data-testid="button-false"
                    disabled={true}
                    onClick={[Function]}
                  >
                    <LoadingIconFull
                      addClassName="tw-absolute tw-opacity-0 "
                      size="md"
                    >
                      <FaIcon
                        addClassName="tw-absolute tw-opacity-0  tw-animate-spin"
                        aria-label="Loading"
                        color="currentColor"
                        faIconDef={
                          Object {
                            "icon": Array [
                              512,
                              512,
                              Array [],
                              "f1ce",
                              "M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z",
                            ],
                            "iconName": "circle-notch",
                            "prefix": "fas",
                          }
                        }
                        iconProps={Object {}}
                        secondaryColor="currentColor"
                        size="md"
                      >
                        <svg
                          aria-label="Loading"
                          className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-absolute tw-opacity-0  tw-animate-spin"
                          role="img"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
                            fill="currentColor"
                            key="0"
                          />
                        </svg>
                      </FaIcon>
                    </LoadingIconFull>
                    <span
                      className="tw-opacity-100"
                    >
                      Update
                    </span>
                  </button>
                </Button>
              </div>
            </LoadingButton>
          </section>
        </div>
      </main>
    </SidePanel>
  </FingerprintDrawer>
</IntlProvider>
`;

exports[`<FingerprintDrawer /> should render when open EDIT and does delete 1`] = `
<IntlProvider
  defaultFormats={Object {}}
  defaultLocale="en"
  fallbackOnEmptyString={true}
  formats={Object {}}
  locale="en"
  messages={Object {}}
  onError={[Function]}
  onWarn={[Function]}
  textComponent={Symbol(react.fragment)}
>
  <FingerprintDrawer
    apiEndpoint="/apiEndpoint"
    apiPostfix=""
    category="category"
    description="description"
    error="error"
    fileObj={
      Object {
        "name": "saved file name",
        "size": 666,
      }
    }
    id={666}
    isOpen={true}
    mode="edit"
    name="name"
    onClose={[Function]}
    onDelete={[Function]}
    onRefresh={[Function]}
    savedFingerprintsArr={
      Array [
        Object {
          "name": "duplicate name",
        },
        Object {
          "name": "saved file name",
        },
      ]
    }
  >
    <SidePanel
      addClassName="drawer-container"
      isOpen={true}
      onClose={[Function]}
      showMask={true}
      title="Edit Fingerprint"
    >
      <main
        className="dlp-side-panel"
      >
        <section
          className="panel-backdrop"
          onClick={[Function]}
        />
        <div
          className="panel-main drawer-container"
        >
          <section
            className="panel-header"
          >
            <span
              className="panel-title"
            >
              Edit Fingerprint
            </span>
            <span
              className="close-icon"
              onClick={[Function]}
            >
              <CloseIcon
                size="xs"
              >
                <SvgIcon
                  size="xs"
                  viewBox="0 0 12 12"
                >
                  <svg
                    aria-hidden={true}
                    className="tw-fill-current"
                    focusable={false}
                    height="12px"
                    role="img"
                    viewBox="0 0 12 12"
                    width="12px"
                  >
                    <path
                      d="M11.7338 0.275313C11.3788 -0.0796357 10.8055 -0.0796357 10.4505 0.275313L6 4.71672L1.54949 0.266212C1.19454 -0.0887372 0.62116 -0.0887372 0.266212 0.266212C-0.0887372 0.62116 -0.0887372 1.19454 0.266212 1.54949L4.71672 6L0.266212 10.4505C-0.0887372 10.8055 -0.0887372 11.3788 0.266212 11.7338C0.62116 12.0887 1.19454 12.0887 1.54949 11.7338L6 7.28328L10.4505 11.7338C10.8055 12.0887 11.3788 12.0887 11.7338 11.7338C12.0887 11.3788 12.0887 10.8055 11.7338 10.4505L7.28328 6L11.7338 1.54949C12.0796 1.20364 12.0796 0.621161 11.7338 0.275313Z"
                    />
                  </svg>
                </SvgIcon>
              </CloseIcon>
            </span>
          </section>
          <main
            className="drawer-main"
          >
            <section
              className="title-area"
            >
              Information
            </section>
            <EditFingerprintForm
              category="category"
              description="description"
              filesArr={
                Array [
                  Object {
                    "name": "saved file name",
                    "size": 666,
                  },
                ]
              }
              hasError={true}
              id={666}
              name="name"
              onInputChange={[Function]}
            >
              <section
                className="content-area"
              >
                <p
                  className="input-label"
                >
                  Technique
                </p>
                <Select
                  disabled={true}
                  items={
                    Array [
                      Object {
                        "value": "Indexed Document Match (IDM)",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={
                    Array [
                      Object {
                        "value": "Indexed Document Match (IDM)",
                      },
                    ]
                  }
                >
                  <SelectWithoutSearch
                    disabled={true}
                    items={
                      Array [
                        Object {
                          "value": "Indexed Document Match (IDM)",
                        },
                      ]
                    }
                    onChange={[Function]}
                    selectedItem={
                      Array [
                        Object {
                          "value": "Indexed Document Match (IDM)",
                        },
                      ]
                    }
                  >
                    <div
                      className=""
                    >
                      <Button
                        appearance="secondary"
                        aria-expanded={false}
                        aria-haspopup="listbox"
                        aria-labelledby="field-9f2decce-603c-44c9-9861-600c14f502dc-label-label field-9f2decce-603c-44c9-9861-600c14f502dc-label-toggle-button"
                        buttonType="default"
                        data-testid="select-trigger"
                        disabled={true}
                        fullWidth={false}
                        id="field-9f2decce-603c-44c9-9861-600c14f502dc-label-toggle-button"
                        isMenu={true}
                        isOpen={false}
                        size="md"
                      >
                        <button
                          aria-expanded={false}
                          aria-haspopup="listbox"
                          aria-labelledby="field-9f2decce-603c-44c9-9861-600c14f502dc-label-label field-9f2decce-603c-44c9-9861-600c14f502dc-label-toggle-button"
                          className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-cursor-not-allowed tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 tw-opacity-40"
                          data-testid="select-trigger"
                          disabled={true}
                          id="field-9f2decce-603c-44c9-9861-600c14f502dc-label-toggle-button"
                          type="button"
                        >
                          <TruncateText
                            addClassName="tw-flex-auto"
                          >
                            <span
                              className="tw-font-lato tw-truncate tw-flex-auto"
                              data-testid="truncate-indexed-document-match-(idm)"
                            >
                              <span>
                                Indexed Document Match (IDM)
                              </span>
                            </span>
                          </TruncateText>
                          <MenuIcon
                            buttonType="default"
                            isBtnGroup={false}
                            isOpen={false}
                            isSplit={false}
                            size="md"
                          >
                            <span
                              className="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <FaIcon
                                addClassName="tw-transform tw-transition-transform tw-duration-200"
                                color="currentColor"
                                faIconDef={
                                  Object {
                                    "icon": Array [
                                      256,
                                      512,
                                      Array [],
                                      "f107",
                                      "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                                    ],
                                    "iconName": "angle-down",
                                    "prefix": "fal",
                                  }
                                }
                                iconProps={Object {}}
                                secondaryColor="currentColor"
                                size="md"
                              >
                                <svg
                                  aria-hidden={true}
                                  className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable={false}
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                    key="0"
                                  />
                                </svg>
                              </FaIcon>
                            </span>
                          </MenuIcon>
                        </button>
                      </Button>
                      <Portal>
                        <Portal
                          containerInfo={
                            <div
                              data-testid="portal"
                            >
                              <div
                                class="tw-z-30"
                                style="position: fixed; left: 0px; top: 0px; margin: 0px;"
                              >
                                <ul
                                  aria-labelledby="field-9f2decce-603c-44c9-9861-600c14f502dc-label-label"
                                  class="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-9f2decce-603c-44c9-9861-600c14f502dc-label-menu"
                                  role="listbox"
                                  style="min-width: 11rem; max-height: 17rem;"
                                  tabindex="-1"
                                />
                              </div>
                            </div>
                          }
                        >
                          <div
                            className="tw-z-30"
                          >
                            <SelectList
                              enableMultiSelect={false}
                              fitToItemWidth={false}
                              getItemProps={[Function]}
                              getMenuProps={[Function]}
                              highlightedIndex={-1}
                              isLoading={false}
                              isOpen={false}
                              items={
                                Array [
                                  Object {
                                    "value": "Indexed Document Match (IDM)",
                                  },
                                ]
                              }
                              selectAllItem={Object {}}
                              selectedItems={
                                Array [
                                  Object {
                                    "value": "Indexed Document Match (IDM)",
                                  },
                                ]
                              }
                              selectedValues={
                                Array [
                                  "Indexed Document Match (IDM)",
                                ]
                              }
                              showSelectAll={false}
                            >
                              <List
                                aria-labelledby="field-9f2decce-603c-44c9-9861-600c14f502dc-label-label"
                                id="field-9f2decce-603c-44c9-9861-600c14f502dc-label-menu"
                                isOpen={false}
                                onBlur={[Function]}
                                onKeyDown={[Function]}
                                onMouseLeave={[Function]}
                                role="listbox"
                                style={
                                  Object {
                                    "margin": "0.1rem 0",
                                    "maxHeight": "",
                                  }
                                }
                                tabIndex={-1}
                              >
                                <ul
                                  aria-labelledby="field-9f2decce-603c-44c9-9861-600c14f502dc-label-label"
                                  className="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-9f2decce-603c-44c9-9861-600c14f502dc-label-menu"
                                  onBlur={[Function]}
                                  onKeyDown={[Function]}
                                  onMouseLeave={[Function]}
                                  role="listbox"
                                  style={
                                    Object {
                                      "maxHeight": "17rem",
                                      "minWidth": "11rem",
                                    }
                                  }
                                  tabIndex={-1}
                                />
                              </List>
                            </SelectList>
                          </div>
                        </Portal>
                      </Portal>
                    </div>
                  </SelectWithoutSearch>
                </Select>
                <p
                  className="input-label"
                >
                  Name
                  <span
                    className="required-indicator"
                  />
                </p>
                <input
                  className="form-control name"
                  data-testid="name-input"
                  disabled={true}
                  name="name"
                  onChange={[Function]}
                  placeholder="Document type name"
                  value="name"
                />
                <p
                  className="input-label"
                >
                  Category
                  <span
                    className="required-indicator"
                  />
                </p>
                <Select
                  disabled={false}
                  items={
                    Array [
                      Object {
                        "value": "Academic",
                      },
                      Object {
                        "value": "Confidential",
                      },
                      Object {
                        "value": "Employment",
                      },
                      Object {
                        "value": "Financial",
                      },
                      Object {
                        "value": "Government",
                      },
                      Object {
                        "value": "Legal",
                      },
                      Object {
                        "value": "Marketing",
                      },
                      Object {
                        "value": "Source Code",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={
                    Array [
                      Object {
                        "value": "category",
                      },
                    ]
                  }
                >
                  <SelectWithoutSearch
                    disabled={false}
                    items={
                      Array [
                        Object {
                          "value": "Academic",
                        },
                        Object {
                          "value": "Confidential",
                        },
                        Object {
                          "value": "Employment",
                        },
                        Object {
                          "value": "Financial",
                        },
                        Object {
                          "value": "Government",
                        },
                        Object {
                          "value": "Legal",
                        },
                        Object {
                          "value": "Marketing",
                        },
                        Object {
                          "value": "Source Code",
                        },
                      ]
                    }
                    onChange={[Function]}
                    selectedItem={
                      Array [
                        Object {
                          "value": "category",
                        },
                      ]
                    }
                  >
                    <div
                      className=""
                    >
                      <Button
                        appearance="secondary"
                        aria-expanded={false}
                        aria-haspopup="listbox"
                        aria-labelledby="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-label field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-toggle-button"
                        buttonType="default"
                        data-testid="select-trigger"
                        disabled={false}
                        fullWidth={false}
                        id="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-toggle-button"
                        isMenu={true}
                        isOpen={false}
                        onClick={[Function]}
                        onKeyDown={[Function]}
                        size="md"
                      >
                        <button
                          aria-expanded={false}
                          aria-haspopup="listbox"
                          aria-labelledby="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-label field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-toggle-button"
                          className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
                          data-testid="select-trigger"
                          id="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-toggle-button"
                          onClick={[Function]}
                          onKeyDown={[Function]}
                          type="button"
                        >
                          <TruncateText
                            addClassName="tw-flex-auto"
                          >
                            <span
                              className="tw-font-lato tw-truncate tw-flex-auto"
                              data-testid="truncate-category"
                            >
                              <span>
                                category
                              </span>
                            </span>
                          </TruncateText>
                          <MenuIcon
                            buttonType="default"
                            isBtnGroup={false}
                            isOpen={false}
                            isSplit={false}
                            size="md"
                          >
                            <span
                              className="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <FaIcon
                                addClassName="tw-transform tw-transition-transform tw-duration-200"
                                color="currentColor"
                                faIconDef={
                                  Object {
                                    "icon": Array [
                                      256,
                                      512,
                                      Array [],
                                      "f107",
                                      "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                                    ],
                                    "iconName": "angle-down",
                                    "prefix": "fal",
                                  }
                                }
                                iconProps={Object {}}
                                secondaryColor="currentColor"
                                size="md"
                              >
                                <svg
                                  aria-hidden={true}
                                  className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable={false}
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                    key="0"
                                  />
                                </svg>
                              </FaIcon>
                            </span>
                          </MenuIcon>
                        </button>
                      </Button>
                      <Portal>
                        <Portal
                          containerInfo={
                            <div
                              data-testid="portal"
                            >
                              <div
                                class="tw-z-30"
                                style="position: fixed; left: 0px; top: 0px; margin: 0px;"
                              >
                                <ul
                                  aria-labelledby="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-label"
                                  class="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-menu"
                                  role="listbox"
                                  style="min-width: 11rem; max-height: 17rem;"
                                  tabindex="-1"
                                />
                              </div>
                            </div>
                          }
                        >
                          <div
                            className="tw-z-30"
                          >
                            <SelectList
                              enableMultiSelect={false}
                              fitToItemWidth={false}
                              getItemProps={[Function]}
                              getMenuProps={[Function]}
                              highlightedIndex={-1}
                              isLoading={false}
                              isOpen={false}
                              items={
                                Array [
                                  Object {
                                    "value": "Academic",
                                  },
                                  Object {
                                    "value": "Confidential",
                                  },
                                  Object {
                                    "value": "Employment",
                                  },
                                  Object {
                                    "value": "Financial",
                                  },
                                  Object {
                                    "value": "Government",
                                  },
                                  Object {
                                    "value": "Legal",
                                  },
                                  Object {
                                    "value": "Marketing",
                                  },
                                  Object {
                                    "value": "Source Code",
                                  },
                                ]
                              }
                              selectAllItem={Object {}}
                              selectedItems={
                                Array [
                                  Object {
                                    "value": "category",
                                  },
                                ]
                              }
                              selectedValues={
                                Array [
                                  "category",
                                ]
                              }
                              showSelectAll={false}
                            >
                              <List
                                aria-labelledby="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-label"
                                id="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-menu"
                                isOpen={false}
                                onBlur={[Function]}
                                onKeyDown={[Function]}
                                onMouseLeave={[Function]}
                                role="listbox"
                                style={
                                  Object {
                                    "margin": "0.1rem 0",
                                    "maxHeight": "",
                                  }
                                }
                                tabIndex={-1}
                              >
                                <ul
                                  aria-labelledby="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-label"
                                  className="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-f37077dc-57f3-4bfa-89f2-85b2af33da77-label-menu"
                                  onBlur={[Function]}
                                  onKeyDown={[Function]}
                                  onMouseLeave={[Function]}
                                  role="listbox"
                                  style={
                                    Object {
                                      "maxHeight": "17rem",
                                      "minWidth": "11rem",
                                    }
                                  }
                                  tabIndex={-1}
                                />
                              </List>
                            </SelectList>
                          </div>
                        </Portal>
                      </Portal>
                    </div>
                  </SelectWithoutSearch>
                </Select>
                <p
                  className="input-label"
                >
                  Description
                </p>
                <textarea
                  className="form-control description"
                  data-testid="description-input"
                  disabled={true}
                  name="description"
                  onChange={[Function]}
                  placeholder="Describe the document type."
                  value="description"
                />
                <section
                  className="files-section"
                >
                  <article
                    className="title"
                  >
                    Source File
                    <span
                      className="required-indicator"
                    />
                  </article>
                  <article
                    className="file-details disabled"
                    key="file_0"
                  >
                    <section
                      className="file-info"
                    >
                      <i
                        className="file-status"
                      >
                        <SuccessIcon
                          size="sm"
                        >
                          <FaIcon
                            addClassName="tw-text-risk-2"
                            aria-label="Success"
                            color="currentColor"
                            faIconDef={
                              Object {
                                "icon": Array [
                                  512,
                                  512,
                                  Array [],
                                  "f058",
                                  "M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z",
                                ],
                                "iconName": "check-circle",
                                "prefix": "fas",
                              }
                            }
                            iconProps={Object {}}
                            secondaryColor="currentColor"
                            size="sm"
                          >
                            <svg
                              aria-label="Success"
                              className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-3.5 tw-w-3.5 tw-text-risk-2"
                              role="img"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                                fill="currentColor"
                                key="0"
                              />
                            </svg>
                          </FaIcon>
                        </SuccessIcon>
                      </i>
                      <span
                        className="name"
                      >
                        saved file name
                      </span>
                      <span
                        className="size"
                      >
                        666 B
                      </span>
                    </section>
                    <div
                      className="progress-bar"
                    />
                    <i
                      className="delete-icon disabled"
                    >
                      <FaTrash>
                        <IconBase
                          attr={
                            Object {
                              "viewBox": "0 0 448 512",
                            }
                          }
                        >
                          <svg
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            strokeWidth="0"
                            style={
                              Object {
                                "color": undefined,
                              }
                            }
                            viewBox="0 0 448 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                              key="0"
                            />
                          </svg>
                        </IconBase>
                      </FaTrash>
                    </i>
                  </article>
                </section>
              </section>
            </EditFingerprintForm>
          </main>
          <div
            className="error-message"
          />
          <div
            className="error-message"
          >
            error
             
            Please delete and re-create.
          </div>
          <section
            className="drawer-footer tw-flex tw-justify-end tw-space-x-1"
          >
            <Button
              addClassName="delete"
              appearance="primary-destructive"
              buttonType="default"
              disabled={false}
              isMenu={false}
              onClick={[Function]}
              size="md"
            >
              <button
                className="delete tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-text-dark-bg dark:tw-text-red-100 tw-bg-red-500 dark:tw-bg-red-600 active:tw-bg-red-700 dark:active:tw-bg-red-400 focus-visible:tw-bg-red-600 dark:focus-visible:tw-bg-red-500  hover:tw-bg-red-600 dark:hover:tw-bg-red-500"
                data-testid="button-delete"
                onClick={[Function]}
                type="button"
              >
                Delete
              </button>
            </Button>
            <Button
              addClassName="cancel"
              appearance="secondary"
              buttonType="default"
              disabled={false}
              isMenu={false}
              onClick={[Function]}
              size="md"
            >
              <button
                className="cancel tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
                data-testid="button-cancel"
                onClick={[Function]}
                type="button"
              >
                Cancel
              </button>
            </Button>
            <LoadingButton
              appearance="primary"
              dataMetrics="loading-primary-button"
              dataResult={
                Object {
                  "loading": false,
                }
              }
              disabled={true}
              onClickCallback={[Function]}
            >
              <div
                className="tw-flex tw-space-x-2"
              >
                <Button
                  appearance="primary"
                  buttonType="default"
                  disabled={true}
                  isLoading={false}
                  isMenu={false}
                  onClick={[Function]}
                  size="md"
                >
                  <button
                    className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-cursor-not-allowed tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500 tw-opacity-40"
                    data-testid="button-false"
                    disabled={true}
                    onClick={[Function]}
                  >
                    <LoadingIconFull
                      addClassName="tw-absolute tw-opacity-0 "
                      size="md"
                    >
                      <FaIcon
                        addClassName="tw-absolute tw-opacity-0  tw-animate-spin"
                        aria-label="Loading"
                        color="currentColor"
                        faIconDef={
                          Object {
                            "icon": Array [
                              512,
                              512,
                              Array [],
                              "f1ce",
                              "M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z",
                            ],
                            "iconName": "circle-notch",
                            "prefix": "fas",
                          }
                        }
                        iconProps={Object {}}
                        secondaryColor="currentColor"
                        size="md"
                      >
                        <svg
                          aria-label="Loading"
                          className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-absolute tw-opacity-0  tw-animate-spin"
                          role="img"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
                            fill="currentColor"
                            key="0"
                          />
                        </svg>
                      </FaIcon>
                    </LoadingIconFull>
                    <span
                      className="tw-opacity-100"
                    >
                      Update
                    </span>
                  </button>
                </Button>
              </div>
            </LoadingButton>
          </section>
        </div>
      </main>
    </SidePanel>
  </FingerprintDrawer>
</IntlProvider>
`;

exports[`<FingerprintDrawer /> should render when open NEW 1`] = `
<IntlProvider
  defaultFormats={Object {}}
  defaultLocale="en"
  fallbackOnEmptyString={true}
  formats={Object {}}
  locale="en"
  messages={Object {}}
  onError={[Function]}
  onWarn={[Function]}
  textComponent={Symbol(react.fragment)}
>
  <FingerprintDrawer
    apiEndpoint="/apiEndpoint"
    apiPostfix=""
    isOpen={true}
    mode="new"
    onClose={[Function]}
    onDelete={[Function]}
    onRefresh={[Function]}
    savedFingerprintsArr={
      Array [
        Object {
          "name": "duplicate name",
        },
      ]
    }
  >
    <SidePanel
      addClassName="drawer-container"
      isOpen={true}
      onClose={[Function]}
      showMask={true}
      title="New Document Type"
    >
      <main
        className="dlp-side-panel"
      >
        <section
          className="panel-backdrop"
          onClick={[Function]}
        />
        <div
          className="panel-main drawer-container"
        >
          <section
            className="panel-header"
          >
            <span
              className="panel-title"
            >
              New Document Type
            </span>
            <span
              className="close-icon"
              onClick={[Function]}
            >
              <CloseIcon
                size="xs"
              >
                <SvgIcon
                  size="xs"
                  viewBox="0 0 12 12"
                >
                  <svg
                    aria-hidden={true}
                    className="tw-fill-current"
                    focusable={false}
                    height="12px"
                    role="img"
                    viewBox="0 0 12 12"
                    width="12px"
                  >
                    <path
                      d="M11.7338 0.275313C11.3788 -0.0796357 10.8055 -0.0796357 10.4505 0.275313L6 4.71672L1.54949 0.266212C1.19454 -0.0887372 0.62116 -0.0887372 0.266212 0.266212C-0.0887372 0.62116 -0.0887372 1.19454 0.266212 1.54949L4.71672 6L0.266212 10.4505C-0.0887372 10.8055 -0.0887372 11.3788 0.266212 11.7338C0.62116 12.0887 1.19454 12.0887 1.54949 11.7338L6 7.28328L10.4505 11.7338C10.8055 12.0887 11.3788 12.0887 11.7338 11.7338C12.0887 11.3788 12.0887 10.8055 11.7338 10.4505L7.28328 6L11.7338 1.54949C12.0796 1.20364 12.0796 0.621161 11.7338 0.275313Z"
                    />
                  </svg>
                </SvgIcon>
              </CloseIcon>
            </span>
          </section>
          <main
            className="drawer-main"
          >
            <section
              className="title-area"
            >
              Information
            </section>
            <NewFingerprintForm
              category=""
              description=""
              filesArr={Array []}
              name=""
              onFileDelete={[Function]}
              onFilesUpload={[Function]}
              onInputChange={[Function]}
            >
              <section
                className="content-area"
              >
                <p
                  className="input-label"
                >
                  Technique
                </p>
                <Select
                  disabled={true}
                  items={
                    Array [
                      Object {
                        "value": "Indexed Document Match (IDM)",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={
                    Array [
                      Object {
                        "value": "Indexed Document Match (IDM)",
                      },
                    ]
                  }
                >
                  <SelectWithoutSearch
                    disabled={true}
                    items={
                      Array [
                        Object {
                          "value": "Indexed Document Match (IDM)",
                        },
                      ]
                    }
                    onChange={[Function]}
                    selectedItem={
                      Array [
                        Object {
                          "value": "Indexed Document Match (IDM)",
                        },
                      ]
                    }
                  >
                    <div
                      className=""
                    >
                      <Button
                        appearance="secondary"
                        aria-expanded={false}
                        aria-haspopup="listbox"
                        aria-labelledby="field-45145e5d-2137-49e9-995a-814ac67c7086-label-label field-45145e5d-2137-49e9-995a-814ac67c7086-label-toggle-button"
                        buttonType="default"
                        data-testid="select-trigger"
                        disabled={true}
                        fullWidth={false}
                        id="field-45145e5d-2137-49e9-995a-814ac67c7086-label-toggle-button"
                        isMenu={true}
                        isOpen={false}
                        size="md"
                      >
                        <button
                          aria-expanded={false}
                          aria-haspopup="listbox"
                          aria-labelledby="field-45145e5d-2137-49e9-995a-814ac67c7086-label-label field-45145e5d-2137-49e9-995a-814ac67c7086-label-toggle-button"
                          className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-cursor-not-allowed tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 tw-opacity-40"
                          data-testid="select-trigger"
                          disabled={true}
                          id="field-45145e5d-2137-49e9-995a-814ac67c7086-label-toggle-button"
                          type="button"
                        >
                          <TruncateText
                            addClassName="tw-flex-auto"
                          >
                            <span
                              className="tw-font-lato tw-truncate tw-flex-auto"
                              data-testid="truncate-indexed-document-match-(idm)"
                            >
                              <span>
                                Indexed Document Match (IDM)
                              </span>
                            </span>
                          </TruncateText>
                          <MenuIcon
                            buttonType="default"
                            isBtnGroup={false}
                            isOpen={false}
                            isSplit={false}
                            size="md"
                          >
                            <span
                              className="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <FaIcon
                                addClassName="tw-transform tw-transition-transform tw-duration-200"
                                color="currentColor"
                                faIconDef={
                                  Object {
                                    "icon": Array [
                                      256,
                                      512,
                                      Array [],
                                      "f107",
                                      "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                                    ],
                                    "iconName": "angle-down",
                                    "prefix": "fal",
                                  }
                                }
                                iconProps={Object {}}
                                secondaryColor="currentColor"
                                size="md"
                              >
                                <svg
                                  aria-hidden={true}
                                  className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable={false}
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                    key="0"
                                  />
                                </svg>
                              </FaIcon>
                            </span>
                          </MenuIcon>
                        </button>
                      </Button>
                      <Portal>
                        <Portal
                          containerInfo={
                            <div
                              data-testid="portal"
                            >
                              <div
                                class="tw-z-30"
                                style="position: fixed; left: 0px; top: 0px; margin: 0px;"
                              >
                                <ul
                                  aria-labelledby="field-45145e5d-2137-49e9-995a-814ac67c7086-label-label"
                                  class="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-45145e5d-2137-49e9-995a-814ac67c7086-label-menu"
                                  role="listbox"
                                  style="min-width: 11rem; max-height: 17rem;"
                                  tabindex="-1"
                                />
                              </div>
                            </div>
                          }
                        >
                          <div
                            className="tw-z-30"
                          >
                            <SelectList
                              enableMultiSelect={false}
                              fitToItemWidth={false}
                              getItemProps={[Function]}
                              getMenuProps={[Function]}
                              highlightedIndex={-1}
                              isLoading={false}
                              isOpen={false}
                              items={
                                Array [
                                  Object {
                                    "value": "Indexed Document Match (IDM)",
                                  },
                                ]
                              }
                              selectAllItem={Object {}}
                              selectedItems={
                                Array [
                                  Object {
                                    "value": "Indexed Document Match (IDM)",
                                  },
                                ]
                              }
                              selectedValues={
                                Array [
                                  "Indexed Document Match (IDM)",
                                ]
                              }
                              showSelectAll={false}
                            >
                              <List
                                aria-labelledby="field-45145e5d-2137-49e9-995a-814ac67c7086-label-label"
                                id="field-45145e5d-2137-49e9-995a-814ac67c7086-label-menu"
                                isOpen={false}
                                onBlur={[Function]}
                                onKeyDown={[Function]}
                                onMouseLeave={[Function]}
                                role="listbox"
                                style={
                                  Object {
                                    "margin": "0.1rem 0",
                                    "maxHeight": "",
                                  }
                                }
                                tabIndex={-1}
                              >
                                <ul
                                  aria-labelledby="field-45145e5d-2137-49e9-995a-814ac67c7086-label-label"
                                  className="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-45145e5d-2137-49e9-995a-814ac67c7086-label-menu"
                                  onBlur={[Function]}
                                  onKeyDown={[Function]}
                                  onMouseLeave={[Function]}
                                  role="listbox"
                                  style={
                                    Object {
                                      "maxHeight": "17rem",
                                      "minWidth": "11rem",
                                    }
                                  }
                                  tabIndex={-1}
                                />
                              </List>
                            </SelectList>
                          </div>
                        </Portal>
                      </Portal>
                    </div>
                  </SelectWithoutSearch>
                </Select>
                <p
                  className="input-label"
                >
                  Name
                  <span
                    className="required-indicator"
                  />
                </p>
                <input
                  className="form-control name"
                  data-testid="name-input"
                  name="name"
                  onChange={[Function]}
                  placeholder="Document type name"
                  value=""
                />
                <p
                  className="input-label"
                >
                  Category
                  <span
                    className="required-indicator"
                  />
                </p>
                <Select
                  disabled={false}
                  items={
                    Array [
                      Object {
                        "value": "Academic",
                      },
                      Object {
                        "value": "Confidential",
                      },
                      Object {
                        "value": "Employment",
                      },
                      Object {
                        "value": "Financial",
                      },
                      Object {
                        "value": "Government",
                      },
                      Object {
                        "value": "Legal",
                      },
                      Object {
                        "value": "Marketing",
                      },
                      Object {
                        "value": "Source Code",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={
                    Array [
                      Object {
                        "value": "",
                      },
                    ]
                  }
                >
                  <SelectWithoutSearch
                    disabled={false}
                    items={
                      Array [
                        Object {
                          "value": "Academic",
                        },
                        Object {
                          "value": "Confidential",
                        },
                        Object {
                          "value": "Employment",
                        },
                        Object {
                          "value": "Financial",
                        },
                        Object {
                          "value": "Government",
                        },
                        Object {
                          "value": "Legal",
                        },
                        Object {
                          "value": "Marketing",
                        },
                        Object {
                          "value": "Source Code",
                        },
                      ]
                    }
                    onChange={[Function]}
                    selectedItem={
                      Array [
                        Object {
                          "value": "",
                        },
                      ]
                    }
                  >
                    <div
                      className=""
                    >
                      <Button
                        appearance="secondary"
                        aria-expanded={false}
                        aria-haspopup="listbox"
                        aria-labelledby="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-label field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-toggle-button"
                        buttonType="default"
                        data-testid="select-trigger"
                        disabled={false}
                        fullWidth={false}
                        id="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-toggle-button"
                        isMenu={true}
                        isOpen={false}
                        onClick={[Function]}
                        onKeyDown={[Function]}
                        size="md"
                      >
                        <button
                          aria-expanded={false}
                          aria-haspopup="listbox"
                          aria-labelledby="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-label field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-toggle-button"
                          className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
                          data-testid="select-trigger"
                          id="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-toggle-button"
                          onClick={[Function]}
                          onKeyDown={[Function]}
                          type="button"
                        >
                          <TruncateText
                            addClassName="tw-flex-auto"
                          >
                            <span
                              className="tw-font-lato tw-truncate tw-flex-auto"
                              data-testid="truncate-truncate"
                            >
                              <span />
                            </span>
                          </TruncateText>
                          <MenuIcon
                            buttonType="default"
                            isBtnGroup={false}
                            isOpen={false}
                            isSplit={false}
                            size="md"
                          >
                            <span
                              className="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <FaIcon
                                addClassName="tw-transform tw-transition-transform tw-duration-200"
                                color="currentColor"
                                faIconDef={
                                  Object {
                                    "icon": Array [
                                      256,
                                      512,
                                      Array [],
                                      "f107",
                                      "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                                    ],
                                    "iconName": "angle-down",
                                    "prefix": "fal",
                                  }
                                }
                                iconProps={Object {}}
                                secondaryColor="currentColor"
                                size="md"
                              >
                                <svg
                                  aria-hidden={true}
                                  className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable={false}
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                    key="0"
                                  />
                                </svg>
                              </FaIcon>
                            </span>
                          </MenuIcon>
                        </button>
                      </Button>
                      <Portal>
                        <Portal
                          containerInfo={
                            <div
                              data-testid="portal"
                            >
                              <div
                                class="tw-z-30"
                                style="position: fixed; left: 0px; top: 0px; margin: 0px;"
                              >
                                <ul
                                  aria-labelledby="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-label"
                                  class="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-menu"
                                  role="listbox"
                                  style="min-width: 11rem; max-height: 17rem;"
                                  tabindex="-1"
                                />
                              </div>
                            </div>
                          }
                        >
                          <div
                            className="tw-z-30"
                          >
                            <SelectList
                              enableMultiSelect={false}
                              fitToItemWidth={false}
                              getItemProps={[Function]}
                              getMenuProps={[Function]}
                              highlightedIndex={-1}
                              isLoading={false}
                              isOpen={false}
                              items={
                                Array [
                                  Object {
                                    "value": "Academic",
                                  },
                                  Object {
                                    "value": "Confidential",
                                  },
                                  Object {
                                    "value": "Employment",
                                  },
                                  Object {
                                    "value": "Financial",
                                  },
                                  Object {
                                    "value": "Government",
                                  },
                                  Object {
                                    "value": "Legal",
                                  },
                                  Object {
                                    "value": "Marketing",
                                  },
                                  Object {
                                    "value": "Source Code",
                                  },
                                ]
                              }
                              selectAllItem={Object {}}
                              selectedItems={
                                Array [
                                  Object {
                                    "value": "",
                                  },
                                ]
                              }
                              selectedValues={
                                Array [
                                  "",
                                ]
                              }
                              showSelectAll={false}
                            >
                              <List
                                aria-labelledby="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-label"
                                id="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-menu"
                                isOpen={false}
                                onBlur={[Function]}
                                onKeyDown={[Function]}
                                onMouseLeave={[Function]}
                                role="listbox"
                                style={
                                  Object {
                                    "margin": "0.1rem 0",
                                    "maxHeight": "",
                                  }
                                }
                                tabIndex={-1}
                              >
                                <ul
                                  aria-labelledby="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-label"
                                  className="tw-border-gray-200 tw-box-border tw-m-0 tw-p-0 tw-font-lato tw-rounded tw-overflow-auto focus:tw-outline-none tw-shadow tw-transition-opacity tw-duration-200 tw-text-default dark:tw-text-dark-bg tw-opacity-0"
                                  data-testid="unordered-list"
                                  id="field-270bcc3e-1cda-414b-9224-8c6daacccfda-label-menu"
                                  onBlur={[Function]}
                                  onKeyDown={[Function]}
                                  onMouseLeave={[Function]}
                                  role="listbox"
                                  style={
                                    Object {
                                      "maxHeight": "17rem",
                                      "minWidth": "11rem",
                                    }
                                  }
                                  tabIndex={-1}
                                />
                              </List>
                            </SelectList>
                          </div>
                        </Portal>
                      </Portal>
                    </div>
                  </SelectWithoutSearch>
                </Select>
                <p
                  className="input-label"
                >
                  Description
                </p>
                <textarea
                  className="form-control description"
                  data-testid="description-input"
                  name="description"
                  onChange={[Function]}
                  placeholder="Describe the document type."
                  value=""
                />
                <p
                  className="input-label"
                >
                  Source File
                  <span
                    className="required-indicator"
                  />
                </p>
                <FileUpload
                  buttonLabel="Browse Files"
                  files={Array []}
                  maxFilesAtOnce={1}
                  onUpload={[Function]}
                  textLabel="Drag and drop one file here"
                >
                  <div
                    className="dlp-file-upload"
                  >
                    <section
                      className="container"
                    >
                      <div
                        className="dropzone"
                        data-testid="file-drop-input"
                        onBlur={[Function]}
                        onClick={[Function]}
                        onDragEnter={[Function]}
                        onDragLeave={[Function]}
                        onDragOver={[Function]}
                        onDrop={[Function]}
                        onFocus={[Function]}
                        onKeyDown={[Function]}
                        role="presentation"
                        tabIndex={0}
                      >
                        <input
                          multiple={true}
                          onChange={[Function]}
                          onClick={[Function]}
                          style={
                            Object {
                              "display": "none",
                            }
                          }
                          tabIndex={-1}
                          type="file"
                        />
                        <p
                          className="info-text"
                        >
                          Drag and drop one file here
                        </p>
                        <p
                          className="info-text"
                        >
                          or
                        </p>
                        <div
                          className="file-upload-button"
                          data-testid="file-upload-button"
                          onClick={[Function]}
                        >
                          <i>
                            <FaArrowAltCircleUp>
                              <IconBase
                                attr={
                                  Object {
                                    "viewBox": "0 0 512 512",
                                  }
                                }
                              >
                                <svg
                                  fill="currentColor"
                                  height="1em"
                                  stroke="currentColor"
                                  strokeWidth="0"
                                  style={
                                    Object {
                                      "color": undefined,
                                    }
                                  }
                                  viewBox="0 0 512 512"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm292 116V256h70.9c10.7 0 16.1-13 8.5-20.5L264.5 121.2c-4.7-4.7-12.2-4.7-16.9 0l-115 114.3c-7.6 7.6-2.2 20.5 8.5 20.5H212v116c0 6.6 5.4 12 12 12h64c6.6 0 12-5.4 12-12z"
                                    key="0"
                                  />
                                </svg>
                              </IconBase>
                            </FaArrowAltCircleUp>
                          </i>
                          Browse Files
                        </div>
                      </div>
                    </section>
                  </div>
                </FileUpload>
                <span
                  className="upload-info"
                >
                  A file up to 1 MB is supported..
                </span>
              </section>
            </NewFingerprintForm>
          </main>
          <div
            className="error-message"
          />
          <section
            className="drawer-footer tw-flex tw-justify-end tw-space-x-1"
          >
            <Button
              addClassName="cancel"
              appearance="secondary"
              buttonType="default"
              disabled={false}
              isMenu={false}
              onClick={[Function]}
              size="md"
            >
              <button
                className="cancel tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
                data-testid="button-cancel"
                onClick={[Function]}
                type="button"
              >
                Cancel
              </button>
            </Button>
            <LoadingButton
              appearance="primary"
              dataMetrics="loading-primary-button"
              dataResult={
                Object {
                  "loading": false,
                }
              }
              disabled={true}
              onClickCallback={[Function]}
            >
              <div
                className="tw-flex tw-space-x-2"
              >
                <Button
                  appearance="primary"
                  buttonType="default"
                  disabled={true}
                  isLoading={false}
                  isMenu={false}
                  onClick={[Function]}
                  size="md"
                >
                  <button
                    className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-cursor-not-allowed tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500 tw-opacity-40"
                    data-testid="button-false"
                    disabled={true}
                    onClick={[Function]}
                  >
                    <LoadingIconFull
                      addClassName="tw-absolute tw-opacity-0 "
                      size="md"
                    >
                      <FaIcon
                        addClassName="tw-absolute tw-opacity-0  tw-animate-spin"
                        aria-label="Loading"
                        color="currentColor"
                        faIconDef={
                          Object {
                            "icon": Array [
                              512,
                              512,
                              Array [],
                              "f1ce",
                              "M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z",
                            ],
                            "iconName": "circle-notch",
                            "prefix": "fas",
                          }
                        }
                        iconProps={Object {}}
                        secondaryColor="currentColor"
                        size="md"
                      >
                        <svg
                          aria-label="Loading"
                          className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-absolute tw-opacity-0  tw-animate-spin"
                          role="img"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
                            fill="currentColor"
                            key="0"
                          />
                        </svg>
                      </FaIcon>
                    </LoadingIconFull>
                    <span
                      className="tw-opacity-100"
                    >
                      Generate
                    </span>
                  </button>
                </Button>
              </div>
            </LoadingButton>
          </section>
        </div>
      </main>
    </SidePanel>
  </FingerprintDrawer>
</IntlProvider>
`;
