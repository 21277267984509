/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import dateTranslator from "../../helpers/dateTranslator";
import translate from "../../helpers/translate";

function t(scope, options) {
  return translate(`audit_logs.columns.${scope}`, options);
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const columns = [
  {
    "link": false,
    "header": t('time'),
    "accessor": "createdAt",
    Cell: ({ row }) => (
      <span>
        { moment(new Date(dateTranslator(row.values.createdAt))).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z') }
      </span>
    )
  },
  {
    "searchable": true,
    "header": t("user"),
    "accessor": "userId"
  },
  {
    "link": false,
    "header": t("platform"),
    "accessor": "clientName",
    Cell: ({ row }) => (
      <span>
        {translate(row.values.clientName, {platform: true})}
      </span>
    )
  },
  {
    "link": false,
    "header": t("event"),
    "accessor": "eventLabel"
  }
];
export default columns;