import React from 'react';
import { render } from '@testing-library/react';
import '@testing-library/jest-dom';
import Settings  from './Settings';
import * as constants from '../constants';

describe('<Settings />', () => {
  const props = {
    userInfo: {
      tenantIdDLP: '1234567890',
      email: 'test@test.com',
      role: 'READ_WRITE'
    },
    settingsPermissions: {
      sensitive_data: 'READ_WRITE',
      data_filtering: 'READ_WRITE',
      data_transfer: 'READ_WRITE',
      api_tokens: 'READ_WRITE',
      alert: 'READ_WRITE'
    },
    apiParams: {
      showApiTokens: true
    },
    lookupURL: '/settings',
    environment: 'staging'
  };
  constants.isPanoramaSettingsEnabled = true;

  let testUtils;

  test('should render the component', async() => {
    testUtils = render(<Settings {...props}/> );
    expect(testUtils.getByText('Sensitive Data')).toBeInTheDocument();
    expect(testUtils.getByText('Data Filtering')).toBeInTheDocument();
    expect(testUtils.getByText('Data Transfer')).toBeInTheDocument();
    expect(testUtils.getByText('API Tokens')).toBeInTheDocument();
    expect(testUtils.getByText('Alerts')).toBeInTheDocument();
    expect(testUtils.getByText('User Information')).toBeInTheDocument();
  });

  test('should render the component for isPanoramaSettingsEnabled false', async() => {
    constants.isPanoramaSettingsEnabled = false;
    testUtils = render(<Settings {...props}/> );
    expect(testUtils.getByText('Sensitive Data')).toBeInTheDocument();
    expect(testUtils.getByText('Data Filtering')).toBeInTheDocument();
    expect(testUtils.queryByText('Data Transfer') === null);
    expect(testUtils.getByText('API Tokens')).toBeInTheDocument();
    expect(testUtils.getByText('Alerts')).toBeInTheDocument();
    expect(testUtils.getByText('User Information')).toBeInTheDocument();
    constants.isPanoramaSettingsEnabled = true;
  });

  test('should render the components for saasEnterpriseOnly', async() => {
    const saasEnterpriseOnlyProps = {
      ...props,
      saasEnterpriseOnly: true,
    }
    testUtils = render(<Settings {...saasEnterpriseOnlyProps}/> );
    expect(testUtils.getByText('Sensitive Data')).toBeInTheDocument();
    expect(testUtils.getByText('Data Filtering')).toBeInTheDocument();
    expect(testUtils.queryAllByText('API Tokens').length).toBeGreaterThan(0);
    expect(testUtils.getByText('Alerts')).toBeInTheDocument();
    expect(testUtils.getByText('User Information')).toBeInTheDocument();
  });

  test('should not render for no access', async() => {
    const noAccessProps = {
      ...props,
      settingsPermissions: {
        sensitive_data: 'NO_ACCESS',
        data_filtering: 'NO_ACCESS',
        data_transfer: 'NO_ACCESS',
        api_tokens: 'NO_ACCESS',
        alert: 'NO_ACCESS'
      }
    }
    testUtils = render(<Settings {...noAccessProps}/> );
    expect(testUtils.queryByText('Sensitive Data') === null);
    expect(testUtils.queryByText('Data Filtering') === null);
    expect(testUtils.queryByText('API Tokens') === null);
    expect(testUtils.queryByText('Alerts') === null);
    expect(testUtils.queryByText('User Information') === null);
  });

  test('should not render for no access and isPanoramaSettingsEnabled false', async() => {
    const noAccessProps = {
      ...props,
      isPanoramaSettingsEnabled: false,
      settingsPermissions: {
        sensitive_data: 'NO_ACCESS',
        data_filtering: 'NO_ACCESS',
        data_transfer: 'NO_ACCESS',
        api_tokens: 'NO_ACCESS',
        alert: 'NO_ACCESS'
      }
    }
    testUtils = render(<Settings {...noAccessProps}/> );
    expect(testUtils.queryByText('Sensitive Data') === null);
    expect(testUtils.queryByText('Data Filtering') === null);
    expect(testUtils.queryByText('API Tokens') === null);
    expect(testUtils.queryByText('Alerts') === null);
    expect(testUtils.queryByText('User Information') === null);
  });

  test('should render for casb', async() => {
    const casbProps = {
      ...props,
      portalType: 'casb'
    }
    testUtils = render(<Settings {...casbProps}/> );
    expect(testUtils.queryByText('Sensitive Data') === null);
    expect(testUtils.queryByText('Data Filtering') === null);
    expect(testUtils.queryByText('Data Transfer') === null);
    expect(testUtils.queryByText('API Tokens') === null);
    expect(testUtils.queryByText('Alerts') === null);
    expect(testUtils.queryByText('User Information')).toBeInTheDocument();
  });

  test('should render for casb with email dlp enabled', async() => {
    const casbEmailProps = {
      ...props,
      portalType: 'casb',
      emailDlpEnabled: true,
      initialPage: 'sensitiveData'
    }
    testUtils = render(<Settings {...casbEmailProps}/> );
    expect(testUtils.queryByText('Sensitive Data')).toBeInTheDocument();
    expect(testUtils.queryByText('Evidence Storage')).toBeInTheDocument();
    expect(testUtils.queryByText('Snippet Viewing and Masking') === null);
    expect(testUtils.queryByText('Data Filtering') === null);
    expect(testUtils.queryByText('Data Transfer') === null);
    expect(testUtils.queryByText('API Tokens') === null);
    expect(testUtils.queryByText('Alerts') === null);
    expect(testUtils.queryByText('User Information')).toBeInTheDocument();
  });

  test('should render for casb isPanoramaSettingsEnabled false', async() => {
    const casbProps = {
      ...props,
      portalType: 'casb',
      isPanoramaSettingsEnabled: false
    }
    testUtils = render(<Settings {...casbProps}/> );
    expect(testUtils.queryByText('Sensitive Data') === null);
    expect(testUtils.queryByText('Data Filtering') === null);
    expect(testUtils.queryByText('Data Transfer') === null);
    expect(testUtils.queryByText('API Tokens') === null);
    expect(testUtils.queryByText('Alerts') === null);
    expect(testUtils.queryByText('User Information')).toBeInTheDocument();
  });

  test('should render for casb saasEnterpriseOnly', async() => {
    const casbProps = {
      ...props,
      portalType: 'casb',
      saasEnterpriseOnly: true,
      apiParams: {
        showApiTokens: false
      },
      initialPage: 'userInfo'
    }
    testUtils = render(<Settings {...casbProps}/> );
    expect(testUtils.queryByText('Sensitive Data') === null);
    expect(testUtils.queryByText('Data Filtering') === null);
    expect(testUtils.queryByText('Data Transfer') === null);
    expect(testUtils.queryByText('API Tokens') === null);
    expect(testUtils.queryByText('Alerts') === null);
    expect(testUtils.queryAllByText('User Information').length).toBeGreaterThan(0);
  });

  test('should render for panorama casb', async() => {
    const casbProps = {
      ...props,
      portalType: 'panorama-casb'
    }
    testUtils = render(<Settings {...casbProps}/> );
    expect(testUtils.queryByText('Sensitive Data')).toBeInTheDocument();
    expect(testUtils.queryByText('Data Filtering') === null);
    expect(testUtils.queryByText('Data Transfer') === null);
    expect(testUtils.queryByText('API Tokens')).toBeInTheDocument();
    expect(testUtils.queryByText('Alerts')).toBeInTheDocument();
    expect(testUtils.queryByText('User Information')).toBeInTheDocument();
  });

  test('should render for panorama casb saasEnterpriseOnly', async() => {
    const casbProps = {
      ...props,
      portalType: 'panorama-casb',
      saasEnterpriseOnly: true,
      apiParams: {
        showApiTokens: false
      },
      initialPage: 'userInfo'
    }
    testUtils = render(<Settings {...casbProps}/> );
    expect(testUtils.queryByText('Sensitive Data')).toBeInTheDocument();
    expect(testUtils.queryByText('Data Filtering') === null);
    expect(testUtils.queryByText('Data Transfer') === null);
    expect(testUtils.queryByText('API Tokens') === null);
    expect(testUtils.queryByText('Alerts')).toBeInTheDocument();
    expect(testUtils.queryAllByText('User Information').length).toBeGreaterThan(0);
  });
});