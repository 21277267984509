const checkEdmRuleItem = (ruleItem) => {
  if (!ruleItem.edm_dataset_id || !ruleItem.occurrence_operator_type || ruleItem.edm_dataset_id === '') {
    return false;
  }
  if (ruleItem.occurrence_high && ruleItem.occurrence_high > 500) {
    return false;
  }
  if (ruleItem.occurrence_count && ruleItem.occurrence_count > 500) {
    return false;
  }
  if (ruleItem.occurrence_low && ruleItem.occurrence_high && ruleItem.occurrence_low >= ruleItem.occurrence_high) {
    return false;
  }
  if (ruleItem.occurrence_operator_type === 'between') {
    if (!ruleItem.occurrence_low || !ruleItem.occurrence_high) {
      return false;
    }
  }
  else if (ruleItem.occurrence_operator_type !== 'any' && !ruleItem.occurrence_count) {
    return false;
  }
  if (ruleItem.primary_fields.length === 0) {
    return false;
  }
  if (!ruleItem.primary_match_criteria) {
    return false;
  }
  if (ruleItem.primary_match_criteria === 'any' && !ruleItem.primary_match_any_count) {
    return false;
  }
  if (ruleItem.secondary_match_criteria === 'any' && !ruleItem.secondary_match_any_count) {
    return false;
  }
  if (ruleItem.primary_match_criteria === 'any' && ruleItem.primary_match_any_count >= ruleItem.primary_fields.length) {
    return false;
  }
  if (ruleItem.secondary_match_criteria === 'any' && (ruleItem.secondary_fields || []).length > 0 && ruleItem.secondary_match_any_count >= (ruleItem.secondary_fields || []).length) {
    return false;
  }
  return true;
}

const checkPatternItem = (ruleItem) => {
  if (!ruleItem.id || !ruleItem.occurrence_operator_type || !ruleItem.confidence_level || ruleItem.id === '' || ruleItem.occurrence_operator_type === '' || ruleItem.confidence_level === '') {
    return false;
  }
  if (ruleItem.occurrence_high && ruleItem.occurrence_high > 500) {
    return false;
  }
  if (ruleItem.occurrence_count && ruleItem.occurrence_count > 500) {
    return false;
  }
  if (ruleItem.occurrence_low && ruleItem.occurrence_high && ruleItem.occurrence_low >= ruleItem.occurrence_high) {
    return false;
  }
  if (ruleItem.occurrence_operator_type === 'between') {
    if (!ruleItem.occurrence_low || !ruleItem.occurrence_high) {
      return false;
    }
  }
  else if (ruleItem.occurrence_operator_type !== 'any' && !ruleItem.occurrence_count) {
    return false;
  }
  return true;
}

let valid;

const checkRuleItems = (subExpressions) => {
  subExpressions.forEach(subExp => {
    if (valid) {
      if (subExp.rule_item) {
        if (subExp.rule_item.detection_technique === 'edm') {
          valid = checkEdmRuleItem(subExp.rule_item);
        }
        else {
          valid = checkPatternItem(subExp.rule_item);
        }
      }
      else if (subExp.sub_expressions.length === 0) {
        valid = false;
      }
      else {
        checkRuleItems(subExp.sub_expressions);
      }
    }
  })
  return valid;
}

export const isProfileValid = profile => {
  if (!profile || Object.keys(profile).length === 0) {
    return false;
  }
  if (!profile.name) {
    return false;
  }

  if (profile.multi) {
    if (profile.selectedPrimaryProfiles.length === 0) {
      return false;
    }
    if (profile.selectedPrimaryProfiles.length > 0 && profile.selectedPrimaryProfiles.filter(p => !p.key).length > 0) {
      return false;
    }
    if (profile.selectedSecondaryProfiles.length > 0 && profile.selectedSecondaryProfiles.filter(p => !p.key).length > 0) {
      return false;
    }
    return true;
  }

  if (!profile.primaryExpTree || (!profile.primaryExpTree.rule_item && profile.primaryExpTree.sub_expressions.length === 0)) {
    return false;
  }

  valid = true;
  checkRuleItems(profile.primaryExpTree.sub_expressions);
  checkRuleItems(profile.secondaryExpTree.sub_expressions);

  return valid;
};

export default profile => isProfileValid(profile);