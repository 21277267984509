/* eslint-disable func-names */

import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import OcrSettings from './OcrSettings';
import Header from '../DetectionMethods/Header';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

const axiosMock = new MockAdapter(axios);
const flushPromises = () => new Promise(setImmediate);

describe('<OcrSettings>', () => {
  let wrapper;

  describe('Component to have basic classes', () => {
    it('renders', () => {
      wrapper = shallow(<OcrSettings userRole='READ_WRITE' services={['access', 'saas']} lookupURL={'/ocr.json'} />);
      wrapper.setState({
        ocrConfig: {
          saas: {
            ocr_enablement: 'enabled',
            ocr_enablement_banner: false
          },
          access: {
            ocr_enablement: 'ENABLED'
          }
        },
        loading: false
      });
      expect(wrapper.find('.ocr.settings')).toHaveLength(1);
      expect(wrapper.find('.ocrTable')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });

    it('renders read only', () => {
      wrapper = shallow(<OcrSettings userRole='READ_ONLY' services={['access', 'saas']} lookupURL={'/ocr.json'} />);
      wrapper.setState({
        ocrConfig: {
          saas: {
            ocr_enablement: 'ENABLED',
            ocr_enablement_banner: false
          },
          access: {
            ocr_enablement: 'enabled'
          }
        },
        loading: false
      });
      expect(wrapper.find('.ocr.settings')).toHaveLength(1);
      expect(wrapper.find('.ocrTable')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });

    it('renders saas', () => {
      wrapper = shallow(<OcrSettings lookupURL={'/ocr.json'} services={['access', 'saas']} />);
      wrapper.setState({ocrConfig: {
        saas: {
          ocr_enablement: 'enabled',
          ocr_enablement_banner: true
        }
      }});
      expect(wrapper.find('.ocr.settings')).toHaveLength(1);
      expect(wrapper.find('.ocrTable')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });
  });

  it('renders saas banner requested', () => {
    wrapper = shallow(<OcrSettings lookupURL={'/ocr.json'} services={['access', 'saas']} />);
    wrapper.setState({ocrConfig: {
      saas: {
        ocr_enablement: 'REQUESTED',
        ocr_enablement_banner: true
      }
    }});
    expect(wrapper.find('.ocr.settings')).toHaveLength(1);
    expect(wrapper.find('.ocrTable')).toHaveLength(1);
    expect(wrapper).toMatchSnapshot();
  });

  describe('shows correct header', () => {
    it('renders', () => {
      wrapper = shallow(<OcrSettings lookupURL={'/ocr.json'} />);
      expect(wrapper.containsMatchingElement(<Header activePage='ocr.index.title' basePage='' basePageLink='' />)).toEqual(true);
      expect(wrapper).toMatchSnapshot();
    });
  });

  describe('componentDidMount', () => {
    it('should call API', async() => {
      wrapper = shallow(<OcrSettings lookupURL={'/ocr.json'} services={['saas']} />);

      axiosMock.onGet('/ocr.json').reply(200, {ocr_enablement: 'enabled'});
      await wrapper.instance().componentDidMount();
      expect(wrapper.state('ocrConfig')).toEqual({});
    });

    it('should call API for saas', async() => {
      wrapper = shallow(<OcrSettings lookupURL={'/ocr.json'} services={['saas']} />);

      axiosMock.onGet('/ocr.json').reply(200, {ocr_enablement: 'enabled'});
      await wrapper.instance().componentDidMount();
      expect(wrapper.state('ocrConfig')).toEqual({});
    });

    it('should call API for access/ngfw', async() => {
      wrapper = shallow(<OcrSettings userRole='READ_WRITE' lookupURL={'/ocr.json'} services={['access, ngfw']} />);

      axiosMock.onGet('/ocr.json').reply(200, {ocr_enablement: 'enabled'});
      await wrapper.instance().componentDidMount();
      expect(wrapper.state('ocrConfig')).toEqual({});
      expect(wrapper).toMatchSnapshot();
    });
  });

  describe('handleChange', () => {
    it('should show modal', async() => {

      wrapper = shallow(<OcrSettings lookupURL={'/ocr.json'}/>);
      wrapper.setState({ocrConfig: {
        ocr_enablement: 'disabled'
      }});
      wrapper.instance().updateOCRStatus = jest.fn(() => Promise.resolve({ data: {} }));
      await wrapper.instance().handleChange();
      expect(wrapper.state('modalOpen')).toEqual(true);
    });
  });

  describe('updateOCRStatus', () => {
    it('should call API', () => {
      wrapper = shallow(<OcrSettings lookupURL={'/ocr.json'}/>);

      const spy = jest.spyOn(axios, 'put');
      wrapper.instance().updateOCRStatus();
      expect(spy).toHaveBeenCalled();

    });
  });

  describe('closeBanner', () => {
    it('should call API', () => {
      document.body.innerHTML = `<div>
        <div id="ocrModal"/>
        <div id="banner-message"/>
      </div>`;

      wrapper = shallow(<OcrSettings lookupURL={'/ocr.json'} services={['saas']} />);
      const spy = jest.spyOn(axios, 'post');
      wrapper.instance().closeBanner('saas');
      expect(wrapper.state('ocrConfig')['saas']['ocr_enablement_banner']).toBe(false);
      expect(spy).toHaveBeenCalled();
    });
  });
});
