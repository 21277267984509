import React from 'react';
import PropTypes from 'prop-types';

import { FaExclamationTriangle } from "react-icons/fa";
import {
  Button, Tooltip,
  Modal, ModalBody, ModalFooter, ModalHeader
} from '@panwds/react-ui';
import { IntlProvider } from 'react-intl';
import $ from 'jquery';

import translate from '../../../helpers/translate';
import { isProfileValid } from './profileValidatorV1';
import { returnToIndex, saveProfile, updateProfile } from './profileActions';
import DataProfileDefinition from '../DataProfileDefinition';
import DataProfileDetailPreview from '../DataProfileDetailPreview';
import PreviewToggle from './PreviewToggle';
import { formatRequest } from './requestFormatter';
import { profileDetails } from '../profileDataTransformer';
import DataPatternRules from './DataPatternRules';

import BuilderIcon from '../../../../assets/images/builder.svg';
import BreadcrumbHeader from './BreadcrumbHeader';
import ProfileName from './ProfileName';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}

class DataProfileFormV1 extends React.Component {
  state = {
    validatePresence: false,
    nameError: undefined,
    name: '',
    previewType: 'detail',
    advance_data_patterns_rules: [{}, {}],
    clone: window.location.pathname.includes('clone'),
    showAdvConvertWarning: false,
    readOnly: false,
   };

   dataProfile;

  componentDidMount() {
    const {clone} = this.state;
    const originalProfile = this.props.dataProfile;

    if (originalProfile && originalProfile.profile_type === 'predefined' && !clone) {
      this.setState({readOnly: true})
    }

    if (originalProfile) {
      const  formattedProfile = profileDetails(originalProfile, this.props.dataPatterns, [], clone);
      this.dataProfile = formattedProfile;
      this.setState({
        name: formattedProfile.name,
        advance_data_patterns_rules: formattedProfile.advance_data_patterns_rules || [{}, {}]
      });
    }
  }

  handleNameChange = (event) => {
    this.setState({
      name: event.target.value
    });
  };

  isAdvancedProfile = (advRules = this.state.advance_data_patterns_rules) => {
    if (this.dataProfile && this.dataProfile.type === 'Advanced') {
      return true;
    }
    if (advRules.length === 0 || Object.keys(advRules[0]).length === 0) {
      return false;
    }
    if (advRules.length > 1 && Object.keys(advRules[1]).length > 0) {
      return true;
    }
    if (!this.dataProfile) {
      return true;
    }

    if (!advRules[0].operators.every( (val, i, arr) => val.split('_')[0] === arr[0].split('_')[0])) {
      return false;
    }
    const rule1op = (advRules[0].operators[0] || '').split('_')[0];
    let adv = false;
    advRules[0].conditions.forEach(condition => {
      if(condition.operators.length > 0) {
        if (!condition.operators.every( (val, i, arr) => val.split('_')[0] === arr[0].split('_')[0])) {
          adv = true;
        }
        if (rule1op) {
          if ((condition.operators[0] || '').split('_')[0] !== rule1op) {
            adv = true;
          }
        }
      }
    })

    return adv;
  }

  save = async(warningConfirmed) => {
    $('button:contains("Save")').prop('disabled', true);
    const params = {
      name: this.state.name,
      advance_data_patterns_rules: this.state.advance_data_patterns_rules,
      schema_version: 1,
      create: this.state.clone || !this.dataProfile,
      advanced: this.isAdvancedProfile()
    }
    const valid = isProfileValid(params);
    if (valid) {
      const dataProfile = formatRequest(params);
      let error = '';
      if (!this.state.clone && (this.props.dataProfile || this.props.dataProfileId)) {
        if (this.dataProfile.type === 'Basic' && !warningConfirmed && params.advanced) {
          this.setState({showAdvConvertWarning: true});
          $('button:contains("Save")').prop('disabled', false);
        }
        else {
          error = await updateProfile(dataProfile, this.props);
        }
      }
      else {
        error = await saveProfile(dataProfile, this.props);
      }
      if (error) {
        this.setState({ nameError: error });
      }
    }
    else {
      $('button:contains("Save")').prop('disabled', false);
      this.setState({ validatePresence: true });
    }
  }

  closeModal = () => {
    this.setState({
      showAdvConvertWarning: false
    });
  }

  addRule = (level = 'primary') => {
    const rule = {
      operators: [],
      conditions: [
        {
          operators: ['and'],
          rule_items: [
            {
              id: null,
              name: null,
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              supported_confidence_levels: ['high', 'low']
            }
          ]
        }
      ]
    };
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newRules = this.state.advance_data_patterns_rules;
    if (level === 'primary') {
      newRules[0] = rule;
    }
    else {
      newRules[1] = rule;
    }
    this.setState({advance_data_patterns_rules: newRules});
  }

  updateRule = (newRule, ruleIndex) => {
    if (ruleIndex === 0) {
      this.setState(prevState => (
        {advance_data_patterns_rules: [newRule, prevState.advance_data_patterns_rules[1]] }
      ));
    }
    else {
      this.setState(prevState => (
        {advance_data_patterns_rules: [prevState.advance_data_patterns_rules[0], newRule] }
      ));
    }
  }

  handleChange = (newState) => {
    this.setState(newState);
  }

  primaryRuleDropClass = () => {
    if (Object.keys(this.state.advance_data_patterns_rules[0]).length === 0) {
      return this.state.validatePresence ? 'ruleSelection has-error' : 'ruleSelection';
    }
    return 'hidden';
  }

  render () {
    const canSave = this.props.dataProfileId? this.dataProfile && this.dataProfile.profile_type.toLowerCase() !== 'predefined' : true;
    const secondaryRuleEligible = this.props.dataProfileId && !this.state.clone ? this.dataProfile && this.dataProfile.secondary_rule_eligible : true;
    return (
      <IntlProvider locale='en'>
      <div>
        <BreadcrumbHeader dataProfile={!!this.dataProfile} clone={this.state.clone} />

        <div className='formArea'>
          <div className='dataProfileForm'>
            <div className='tw-float-right form-section preview'>
              <h4 className="mt-2">
                {t('preview')}
                <span className='tw-float-right previewToggle'>
                  <PreviewToggle previewType={this.state.previewType} updateState={this.handleChange} />
                </span>
              </h4>
              {this.state.previewType === 'bracket' && (Object.keys(this.state.advance_data_patterns_rules[0]).length > 0 || Object.keys(this.state.advance_data_patterns_rules[1]).length > 0) &&
                <DataProfileDefinition dataPatternsRules={this.state.advance_data_patterns_rules} advanced={this.isAdvancedProfile()} schema={1} />
              }
              {this.state.previewType === 'detail' && (Object.keys(this.state.advance_data_patterns_rules[0]).length > 0 || Object.keys(this.state.advance_data_patterns_rules[1]).length > 0) &&
                <DataProfileDetailPreview dataPatternRules={this.state.advance_data_patterns_rules} schema={1} />
              }
              {Object.keys(this.state.advance_data_patterns_rules[0]).length === 0 && Object.keys(this.state.advance_data_patterns_rules[1]).length === 0 &&
                <div id='nothingToPreview'>
                  <img src={BuilderIcon} alt='Preview' className='text-center' />
                  <p className='text-center'>
                    <b>{t('no_definition')}</b>
                    <br />
                    <span className='helpText'>{t('add_a_rule')}</span>
                  </p>
                </div>
              }
            </div>

            <ProfileName validatePresence={this.state.validatePresence} name={this.state.name} handleNameChange={this.handleNameChange} nameError={this.state.nameError} readOnly={this.state.readOnly} />

            <div className='form-section'>
              <h4>{t('primary_rule')}</h4>
              <span className={this.primaryRuleDropClass().includes('has-error') ? 'error' : 'muteText'} style={{lineHeight: '20px'}}>Define the match criteria for a primary rule by adding data patterns</span>
              <hr />
              {Object.keys(this.state.advance_data_patterns_rules[0]).length > 0 &&
                <DataPatternRules rule={this.state.advance_data_patterns_rules[0]} validatePresence={this.state.validatePresence} dataPatterns={this.props.dataPatterns} ruleIndex={0} updateRule={this.updateRule} readOnly={this.state.readOnly} />
              }
              { Object.keys(this.state.advance_data_patterns_rules[0]).length === 0 &&
                <Button appearance='tertiary' disabled={this.state.readOnly} addClassName='addDatasetBtn' onClick={() => this.addRule('primary')} >
                  {t('add_data_pattern_group')}
                </Button>
              }
            </div>

            <div className='form-section'>
              <h4>{t('secondary_rule')} <span className='helpText'>{t('optional')}</span></h4>
              <span className='muteText' style={{lineHeight: '20px'}}>Define the match criteria for a secondary rule by adding data patterns</span>
              <hr />
              {Object.keys(this.state.advance_data_patterns_rules[1]).length > 1 &&
                <DataPatternRules rule={this.state.advance_data_patterns_rules[1]} validatePresence={this.state.validatePresence} dataPatterns={this.props.dataPatterns} ruleIndex={1} updateRule={this.updateRule} readOnly={this.state.readOnly} />
              }
              { Object.keys(this.state.advance_data_patterns_rules[1]).length === 0 && secondaryRuleEligible &&
                <Button appearance='tertiary' disabled={this.state.readOnly} addClassName='addDatasetBtn' onClick={() => this.addRule('secondary')} >
                  {t('add_data_pattern_group')}
                </Button>
              }
              { Object.keys(this.state.advance_data_patterns_rules[1]).length === 0 && !secondaryRuleEligible &&
                <Tooltip label={t('secondary_rule_ineligible')} appearance='dark' hasTriangle>
                  <Button appearance='tertiary' disabled addClassName='addDatasetBtn'>
                    {t('add_data_pattern_group')}
                  </Button>
                </Tooltip>
              }
            </div>
          </div>
        </div>
        <div className='formFooter'>
          <div className='tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1'>
            <Button onClick={() => returnToIndex(this.props.history, this.props.dataProfileId)} >
              {translate('actions.cancel')}
            </Button>
            {canSave && <Button appearance='primary' onClick={ () => this.save(false) } >
              {translate('actions.save')}
            </Button>}
          </div>
        </div>
        { this.state.showAdvConvertWarning &&
          <IntlProvider locale='en'>
            <Modal addClassName='dlp-root warningModal' size="md" confirmClose={false} onClose={this.closeModal} isOpen={this.state.showAdvConvertWarning}>
              <ModalHeader title={t('title_edit')} enableClose />
              <ModalBody addClassName="modal-dialog newModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
                <div className='modal-content'>
                  <FaExclamationTriangle className='warning mr5'/>
                  <p>{t('basic_adv_caution_message')}</p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button appearance='primary' onClick={ () => this.save(true) }>
                    {translate('actions.continue')}
                  </Button>
              </ModalFooter>
            </Modal>
          </IntlProvider>
        }
      </div>
      </IntlProvider>
    );
  }
}

DataProfileFormV1.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  createURL: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  updateURL: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  apiPostfix: PropTypes.string,
  dataProfile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    profile_type: PropTypes.string,
    type: PropTypes.string,
    schema_version: PropTypes.number,
    advance_data_patterns_rules: PropTypes.array,
    data_patterns_rule_1: PropTypes.shape({
      operator_type: PropTypes.string,
      conditions: PropTypes.shape({})
    }),
    data_patterns_rule_2: PropTypes.shape({}),
  }),
  dataProfileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataPatterns: PropTypes.shape({}),
  // history will be provided by react-router withRouter HOC
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

DataProfileFormV1.defaultProps = {
  dataProfileId: undefined,
  createURL: '/data_profiles',
  updateURL: '/data_profiles',
  apiPostfix: '.json',
  dataProfile: undefined,
  dataPatterns: {},
  history: undefined
}
export default DataProfileFormV1
