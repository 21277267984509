const fileTypes = [
  { value: 'asm', label: 'asm' },
  { value: 'c_cpp-hdr', label: 'c_cpp-hdr' },
  { value: 'c_cpp-src', label: 'c_cpp-src' },
  { value: 'cpp-hdr', label: 'cpp-hdr' },
  { value: 'cpp-src', label: 'cpp-src' },
  { value: 'csharp', label: 'csharp' },
  { value: 'csv', label: 'csv' },
  { value: 'doc', label: 'doc' },
  { value: 'docx', label: 'docx' },
  { value: 'gzip', label: 'gzip' },
  { value: 'iwork-numbers', label: 'iwork-numbers' },
  { value: 'iwork-keynote', label: 'iwork-keynote' },
  { value: 'iwork-pages', label: 'iwork-pages' },
  { value: 'java-src', label: 'java-src' },
  { value: 'jpeg-upload', label: 'jpeg-upload' },
  { value: 'js', label: 'js' },
  { value: 'matlab/obj-c', label: 'matlab/obj-c' },
  { value: 'pdf', label: 'pdf' },
  { value: 'pl', label: 'pl' },
  { value: 'powershell', label: 'powershell' },
  { value: 'png-upload', label: 'png-upload' },
  { value: 'ppt', label: 'ppt' },
  { value: 'pptx', label: 'pptx' },
  { value: 'py', label: 'py' },
  { value: 'r', label: 'r' },
  { value: 'rtf', label: 'rtf' },
  { value: 'ruby', label: 'ruby' },
  { value: 'tif', label: 'tif' },
  { value: 'txt-upload', label: 'txt-upload' },
  { value: 'vbs', label: 'vbs' },
  { value: 'verilog', label: 'verilog' },
  { value: 'vhdl', label: 'vhdl' },
  { value: 'vsd', label: 'vsd' },
  { value: 'vsdx', label: 'vsdx' },
  { value: 'vsdm', label: 'vsdm' },
  { value: 'xls', label: 'xls' },
  { value: 'xlsx', label: 'xlsx' },
  { value: '7z', label: '7z' }
];
export default fileTypes;