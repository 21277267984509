import React from "react";
import {render, waitFor, fireEvent} from '@testing-library/react';
import '@testing-library/jest-dom';
import { IntlProvider } from "react-intl";
import { MemoryRouter } from "react-router-dom";

import AlertSettings from "./AlertSettings";

const props = {
  fawkes: true,
  userRole: 'READ_WRITE'
};

const readOnlyProps = {
  fawkes: true,
  userRole: 'READ_ONLY'
};


describe('<AlertSettings/>', () => {
  let container, getByText, getByLabelText, findByTestId;

  beforeEach(() => {
    ({container, getByText, getByLabelText, findByTestId} = render(
      <MemoryRouter initialEntries={['/home/settings']}>
        <IntlProvider locale="en">
          <AlertSettings {...props}/>
        </IntlProvider>
      </MemoryRouter>
    ));
  });

  test('should render the title', async() => {
    expect(await findByTestId('testRoot')).toBeInTheDocument();
    expect(getByText('End User Alerts')).toBeInTheDocument();
  });

});

describe('<AlertSettings readOnly/>', () => {
  let container, getByText, getByLabelText, findByTestId;

  beforeEach(() => {
    ({container, getByText, getByLabelText, findByTestId} = render(
      <MemoryRouter initialEntries={['/home/settings']}>
        <IntlProvider locale="en">
          <AlertSettings {...readOnlyProps}/>
        </IntlProvider>
      </MemoryRouter>
    ));
  });

  test('should render the title', async() => {
    expect(await findByTestId('testRoot')).toBeInTheDocument();
    expect(getByText('End User Alerts')).toBeInTheDocument();
  });

});