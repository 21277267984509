import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import DataProfileDetails from './DataProfileDetails';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<DataProfileDetails>', () => {
  let wrapper;
  const profile = {
    name: 'test',
    id: '12345',
    advance_data_patterns_rules: [{
      operators: [],
      conditions: [
        {
          operators: ['or'],
          rule_items: [{
            "id": "1234560",
            "name": "SSN",
            "occurrence_operator_type": "between",
            "occurrence_low": 4,
            "occurrence_high": 24,
            "confidence_level": "high",
            "supported_confidence_levels": ["high","low"],
            "version": 1
          }]
        }
      ]
    }, {}],
    schema_version: 1
  };
  const hybridProfile = {
    name: 'test',
    id: '12345',
    primaryExpTree: {
      operator_type: 'and',
      rule_item: null,
      sub_expressions: [
        {
          operator_type: null,
          rule_item: {
            id: '1234560',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            name: 'SSN',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: [],
        },
        {
          operator_type: 'or',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: 'or',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                    id: '1234561',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    confidence_level: 'high',
                    name: 'CCN',
                    supported_confidence_levels: [
                      'high',
                      'low'
                    ],
                    detection_technique: 'regex',
                    version: 1
                  },
                  sub_expressions: []
                },
                {
                  operator_type: null,
                  rule_item: {
                    edm_dataset_id: '1234565',
                    name: 'Dataset B',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      'col-1',
                      'col-2'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        {
          operator_type: 'not',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                  edm_dataset_id: '1234564',
                  name: 'Dataset A',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  occurrence_low: null,
                  occurrence_high: null,
                  primary_fields: [
                    'col-1',
                    'col-3'
                  ],
                  primary_match_criteria: 'all',
                  primary_match_any_count: null,
                  secondary_fields: [],
                  secondary_match_criteria: 'all',
                  secondary_match_any_count: null,
                  detection_technique: 'edm'
              },
              sub_expressions: []
            }
          ]
        }
      ]
    },
    secondaryExpTree: {
      sub_expressions: []
    },
    schema_version: 2
  };

  it('stays empty without profile', () => {
    wrapper = shallow(<DataProfileDetails profile={{}} updateState={(input) => this.setState(input)} />);
    expect(wrapper).toMatchSnapshot();
  });

  describe('v1', () => {
    it('shows details when given profile', () => {
      wrapper = shallow(<DataProfileDetails profile={profile} updateState={(input) => this.setState(input)} />);
      expect(wrapper).toMatchSnapshot();
    });

    it('shows preview when preview state and given profile', () => {
      wrapper = shallow(<DataProfileDetails profile={profile} actionable={{editable: true, cloneable: true}} updateState={(input) => this.setState(input)} />);
      wrapper.setState({currentView: 'preview'});
      expect(wrapper).toMatchSnapshot();
    });
  })

  describe('v2', () => {
    it('shows details when given profile', () => {
      wrapper = shallow(<DataProfileDetails profile={hybridProfile} updateState={(input) => this.setState(input)} />);
      expect(wrapper).toMatchSnapshot();
    });

    it('shows preview when preview state and given profile', () => {
      wrapper = shallow(<DataProfileDetails profile={hybridProfile} actionable={{editable: true, cloneable: true}} updateState={(input) => this.setState(input)} />);
      wrapper.setState({currentView: 'preview'});
      expect(wrapper).toMatchSnapshot();
    });
  })
});
