// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<EdmSetup> when expanded renders 1`] = `
<div
  className="reactTable setupGuide"
>
  <div
    className="title card-title"
  >
    <span>
      edm_datasets.setup_guide.title
      <FaAngleUp
        className="toggleBtn"
        onClick={[Function]}
      />
    </span>
    <span
      className="actions tw-float-right"
    >
      <Button
        addClassName="cliBtn"
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        edm_datasets.setup_guide.download_cli
      </Button>
    </span>
  </div>
  <div
    className="row instructions"
  >
    <div
      className="col-sm-4"
    >
      <div
        className="row"
      >
        <div
          className="col-sm-1"
        >
          <FaDownload
            className="instructionIcon"
          />
        </div>
        <div
          className="instructionStep col-sm-11"
        >
          <div
            className="stepTitle"
          >
            edm_datasets.setup_guide.step1
          </div>
          <div
            className="helpText"
          >
            edm_datasets.setup_guide.step1_desc
          </div>
        </div>
      </div>
    </div>
    <div
      className="col-sm-4"
    >
      <div
        className="row"
      >
        <div
          className="col-sm-1"
        >
          <FaWrench
            className="instructionIcon"
          />
        </div>
        <div
          className="instructionStep col-sm-11"
        >
          <div
            className="stepTitle"
          >
            edm_datasets.setup_guide.step2
          </div>
          <div
            className="helpText"
          >
            edm_datasets.setup_guide.step2_desc
          </div>
        </div>
      </div>
    </div>
    <div
      className="col-sm-4"
    >
      <div
        className="row"
      >
        <div
          className="col-sm-1"
        >
          <FaTable
            className="instructionIcon"
          />
        </div>
        <div
          className="instructionStep col-sm-11"
        >
          <div
            className="stepTitle"
          >
            edm_datasets.setup_guide.step3
          </div>
          <div
            className="helpText"
          >
            edm_datasets.setup_guide.step3_desc
          </div>
        </div>
      </div>
    </div>
    <IntlProvider
      defaultFormats={Object {}}
      defaultLocale="en"
      fallbackOnEmptyString={true}
      formats={Object {}}
      locale="en"
      messages={Object {}}
      onError={[Function]}
      onWarn={[Function]}
      textComponent={Symbol(react.fragment)}
    >
      <Modal
        confirmClose={false}
        isOpen={false}
        onClose={[Function]}
        size="lg"
        style={
          Object {
            "maxWidth": "600px",
            "width": "100%",
          }
        }
      >
        <ModalHeader
          enableClose={true}
          title="edm_datasets.setup_guide.download_cli"
        />
        <CardBody
          addClassName="modal-dialog cliModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
        >
          <div
            className="modal-content"
          >
            <p
              className="helpText"
            >
              edm_datasets.setup_guide.cli_modal.description
            </p>
            <br />
            <div
              className="options"
            >
              <div
                className="icon-area text-center"
              >
                <img
                  alt="tool"
                />
              </div>
              <div>
                <div
                  className="stepTitle"
                >
                  edm_datasets.setup_guide.cli_modal.options
                </div>
                <br />
                <div
                  className="form-label"
                >
                  edm_datasets.setup_guide.cli_modal.platform
                </div>
                <img
                  alt="loading"
                  className="loader"
                />
                <br />
                <br />
                <div
                  className="form-label"
                >
                  edm_datasets.setup_guide.cli_modal.version
                </div>
                <img
                  alt="loading"
                  className="loader"
                />
                <span>
                  <br />
                  <br />
                </span>
                <br />
              </div>
            </div>
          </div>
        </CardBody>
        <ModalFooter>
          <ModalCloseButton>
            actions.cancel
          </ModalCloseButton>
          <Button
            addClassName="downloadBtn"
            appearance="primary"
            buttonType="default"
            disabled={false}
            icon={
              <FaDownload
                className="mr5"
              />
            }
            isMenu={false}
            onClick={[Function]}
            size="md"
          >
            edm_datasets.setup_guide.cli_modal.download
          </Button>
        </ModalFooter>
      </Modal>
    </IntlProvider>
  </div>
</div>
`;
