import React from 'react';
import { IntlProvider } from 'react-intl';
import '@testing-library/jest-dom/extend-expect';
import { shallow } from 'enzyme';
import { act } from 'react-dom/test-utils';
import { render, fireEvent, screen } from '@testing-library/react';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import sinon from 'sinon';
import { expect as chaiExpect } from 'chai';

const axiosMock = new MockAdapter(axios);

import DeleteModal from './DeleteModal';

describe('<DeleteModal />', () => {
  let sandbox, wrapper;
  const baseProps = {
    apiEndpoint: '/apiEndpoint',
    id: 666,
    isOpen: false,
    name: 'DeleteModal'
  };
  const mockView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return shallow(<DeleteModal { ...props } />);
  };
  const renderView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    render(
      <IntlProvider locale="en">
        <DeleteModal
          {...props}
        />
      </IntlProvider>
    );
  };

  beforeEach(() => {
    axiosMock.reset();
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    sandbox.restore();
  });

  it('should match snapshot 1', () => {
    wrapper = mockView();

    expect(wrapper).toMatchSnapshot();
  });

  it('should match snapshot 2', () => {
    wrapper = mockView({ isOpen: true });

    expect(wrapper).toMatchSnapshot();
  });

  it('should handle close and delete', async () => {
    const handleCloseModalStub = sandbox.stub();

    axiosMock.onDelete().reply(config => {
      expect(config.url).toBe('/apiEndpoint/666');

      return [200, {}];
    });

    renderView({
      isOpen: true,
      onClose: handleCloseModalStub
    });

    await act(async () => {
      fireEvent.click(screen.getByText('Delete Fingerprint'));
    });

    chaiExpect(handleCloseModalStub.calledOnce).to.be.true;
  });
});