import translate from "../../helpers/translate";

function t(scope, options) {
  return translate(`edm_datasets.index.columns.${scope}`, options);
}

const columns = [{
  "link": false,
  "header": t('name'),
  "accessor": "name",
  "viewable": true,
  minWidth: '300px'
},
{
  "link": false,
  "header": t("updated_at"),
  "accessor": "updated_at",
  width: '180px'
},
{
  "link": false,
  "header": t("updated_by"),
  "accessor": "updated_by",
  width: '180px'
},
{
  "link": false,
  "header": t("index_status"),
  "accessor": "index_status",
  width: '180px'
}
];
export default columns;