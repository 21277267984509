import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import ColumnTable from './ColumnTable';

configure({ adapter: new Adapter() });

describe('<ColumnTable />', () => {
  let wrapper;
  const props = {
    columns: [
      {
        name: 'Social Security Number',
        data_type_name: 'SSN',
      },
      {
        name: 'Credit Card Number',
        data_type_name: 'CCN',
      }
    ]
  };

  describe('columm table', () => {
    beforeEach(() => {
      wrapper = shallow(<ColumnTable {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.find('.displayTable')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(3);
    });

    it('matches snapshot', () => {
      expect(wrapper).toMatchSnapshot();
    });
  });
});