/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FaRegCheckCircle, FaSync, FaTimesCircle, FaExclamationTriangle } from 'react-icons/fa';
import $ from 'jquery';
import BulkTable from '../BulkTable/BulkTable';
import translate from '../../helpers/translate';
import EdmDatasetDetails from './EdmDatasetDetails';
import EdmProfileSetup from './EdmSetup';
import Header from '../DetectionMethods/Header';
import EdmConfig from './EdmConfig';

import columns from './columns';

function t(scope, options) {
  return translate(`edm_datasets.index.${scope}`, options);
}

class EdmDatasets extends React.Component {
  state = {
    data: [],
    pageData: {},
    viewing: '',
    selectedItems: [],
    edmStatus: 'disabled',
    showEnabledBanner: false
  };

  componentDidMount = async() => {
    try {
      const result = await axios.get(this.props.statusURL);
      this.setState({edmStatus: result.data.edm_enablement});
      this.setState({showEnabledBanner: result.data.edm_enablement_banner});
    } catch (error) {
      this.setState({edmStatus: 'disabled'});
    }
    if (this.state.edmStatus === 'enabled') {
      if (this.state.showEnabledBanner) {
        $('.banner-area').html(
          `<div class='infoBanner'>
            <span class="fa fa-info-circle info flash-icon"></span>
            <span class="infoMessage">${t('edm_enabled')}</span>
            <span class="tw-float-right close"></span>
          </div>`);
        $('.banner-area span.close').click(this.closeBanner);
      }
      if (!this.props.lookupURL) { return undefined; }
      return this.requestResults(0, 15);
    }
    return undefined;
  };

  closeBanner = async () => {
    this.setState({showEnabledBanner: false});
    $('.banner-area').html('');
    await axios.post('/edm_datasets/turn_off_edm_banner.json', {});
  }

  statusDisplayValue = (edmDataset) => {
    const {index_status, current_index_version, required_index_version} = edmDataset;
    if (index_status === 'success') {
      return 'complete';
    } if (index_status !== 'failed') {
      return 'progress';
    } if (current_index_version === required_index_version) {
      return 'failed';
    }
    return 'update_failed';
  }

  statusIcon = (status) => {
    if (status === 'complete') {
      return <FaRegCheckCircle className='mr5 success-icon' />
    }
    if (status === 'progress') {
      return <FaSync className='mr5 helpText' />
    }
    if (status === 'failed') {
      return <FaTimesCircle className='mr5 error' />
    }
    return <FaExclamationTriangle className='mr5 warning' />
  }

  requestResults = (pageNum, numPerPage) => {
    this.setState({ loading: true });
    return axios.get(`${this.props.lookupURL}?page=${pageNum}&limit=${numPerPage}`).then(
      result => {
        const data = result.data.data.map((col) => {
          const statusDiaplay = this.statusDisplayValue(col);
          return {...col, index_status: <span>{this.statusIcon(statusDiaplay)} {t(`indexing_statuses.${statusDiaplay}`)}</span> }
        })
        this.setState({
          data,
          pageData: result.data.pageData || {},
          loading: false,
        });
      }
    ).catch(
      () => { this.setState({ data: [], loading: false }); }
    );
  }

  updateState = (newState) => {
    this.setState(newState);
  }

  enableEdm = async() => {
    this.setState({edmStatus: 'requested'});
    try {
      await axios.post(`${this.props.enablementURL}`, {edm_status: 'requested'});
    } catch (error) {
      this.setState({edmStatus: 'disabled'});
    }
  }

  render () {
    const { data, pageData, edmStatus, loading } = this.state;

    return (
      <div className='edm_datasets'>
        <Header activePage={t('title')} />
        { edmStatus !== 'enabled' &&
          <EdmConfig currentStatus={edmStatus} enableEdm={this.enableEdm} userRole={this.props.userRole}/>
        }
        { edmStatus === 'enabled' &&
          <div>
            { this.props.userRole === 'READ_WRITE' &&
              <EdmProfileSetup cliOptionsURL={this.props.cliOptionsURL} cliDownloadURL={this.props.cliDownloadURL} />
            }
            <div className='datasetTable reactTable'>
              <div className="title card-title">
                <span>
                  {t('table_title')}
                  <span className='count'>{`(${pageData.total_effective_elements || data.length})`}</span>
                </span>
              </div>
              <BulkTable
                data={data}
                columns={columns}
                pageData={
                  {
                    pageCount: pageData.size,
                    currentPage: pageData.number,
                    totalCount: pageData.total_effective_elements || data.length,
                    totalPages: pageData.total_pages
                  }
                }
                requestResults={this.requestResults}
                updateState={this.updateState}
                selectedItems={this.state.selectedItems}
                selectable={false}
                sortable={false}
                loading={loading}
              />
              {this.state.viewing && <EdmDatasetDetails dataset={data.find(d => d.id === this.state.viewing)} updateState={this.updateState} />}
            </div>
          </div>
        }
      </div>
    );
  }
}

EdmDatasets.propTypes = {
  lookupURL: PropTypes.string.isRequired,
  statusURL: PropTypes.string.isRequired,
  enablementURL: PropTypes.string,
  cliOptionsURL: PropTypes.string,
  cliDownloadURL: PropTypes.string,
  userRole: PropTypes.string,
};

EdmDatasets.defaultProps = {
  userRole: 'READ_ONLY',
  cliOptionsURL: '/edm_datasets/cli_options.json',
  cliDownloadURL: '/edm_datasets/cli_download.json',
  enablementURL: '/edm_datasets/enable.json'
}
export default EdmDatasets