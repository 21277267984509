import React from 'react';
import { SvgIcon } from '@panwds/icons';

const AuditLogsIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox='0 0 16 12'>
      <path d='M10.375 8H11.5938C11.7812 8 12 7.8125 12 7.625V3.40625C12 3.21875 11.7812 3 11.5938 3H10.375C10.1875 3 10 3.21875 10 3.40625V7.625C10 7.8125 10.1875 8 10.375 8ZM13.375 8H14.5938C14.7812 8 15 7.8125 15 7.625V0.40625C15 0.21875 14.7812 0 14.5938 0H13.375C13.1875 0 13 0.21875 13 0.40625V7.625C13 7.8125 13.1875 8 13.375 8ZM4.375 8H5.59375C5.78125 8 6 7.8125 6 7.625V5.40625C6 5.21875 5.78125 5 5.59375 5H4.375C4.1875 5 4 5.21875 4 5.40625V7.625C4 7.8125 4.1875 8 4.375 8ZM7.375 8H8.59375C8.78125 8 9 7.8125 9 7.625V1.40625C9 1.21875 8.78125 1 8.59375 1H7.375C7.1875 1 7 1.21875 7 1.40625V7.625C7 7.8125 7.1875 8 7.375 8ZM15.5 10H2V0.5C2 0.25 1.75 0 1.5 0H0.5C0.21875 0 0 0.25 0 0.5V11C0 11.5625 0.4375 12 1 12H15.5C15.75 12 16 11.7812 16 11.5V10.5C16 10.25 15.75 10 15.5 10Z' />
    </SvgIcon>
  );
};

export default AuditLogsIcon;