// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataProfileFormV2> create form Component has expected classes 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={false}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <div
          id="nothingToPreview"
        >
          <img
            alt="Preview"
            className="text-center"
          />
          <p
            className="text-center"
          >
            <b>
              No Definition Yet
            </b>
            <br />
            <span
              className="helpText"
            >
              Add a rule to start
            </span>
          </p>
        </div>
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name=""
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a primary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="0"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="and"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          />
        </div>
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a secondary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormV2> create form add buttons adds data pattern rule item 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div>
        <div
          class="header main"
        >
          <div
            class="breadcrumb"
          >
            <a
              class="btn-link"
              href="/"
            >
              DLP
            </a>
            <span
              class="fa fa-chevron-right separator"
            />
            <a
              class="btn-link"
              href="/data_profiles"
            >
              Data Profiles
            </a>
            <span
              class="fa fa-chevron-right separator"
            />
            Add Data Profile
          </div>
          <div
            class="title"
          >
            Add Data Profile
          </div>
        </div>
        <div
          class="formArea"
        >
          <div
            class="dataProfileForm"
          >
            <div
              class="tw-float-right form-section preview"
            >
              <h4
                class="mt-2"
              >
                Data Profile Preview
                <span
                  class="tw-float-right previewToggle"
                >
                  <div>
                    <div
                      class="detail selected"
                      data-reach-tooltip-trigger=""
                      data-state="tooltip-hidden"
                    >
                      <span>
                        <svg
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                          />
                        </svg>
                      </span>
                    </div>
                    <div
                      class="bracket unselected"
                      data-reach-tooltip-trigger=""
                      data-state="tooltip-hidden"
                    >
                      <span>
                        <img
                          alt="brackets"
                        />
                      </span>
                    </div>
                  </div>
                </span>
              </h4>
              <div
                style="padding: 15px;"
              >
                <div
                  class="rule"
                >
                  <p
                    class="form-label conditionLabel"
                  >
                    Primary Rule
                  </p>
                  <div
                    class="sectionGroup"
                  >
                    <div
                      class=""
                    >
                      <div
                        class="pattern"
                        style="margin-left: 17px;"
                      >
                        <svg
                          class="mr10 helpText"
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM240 320h-48v48c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h96c52.9 0 96 43.1 96 96s-43.1 96-96 96zm0-128h-48v64h48c17.6 0 32-14.4 32-32s-14.4-32-32-32z"
                          />
                        </svg>
                        Undefined
                        <br />
                        <span
                          class="helpText "
                          style="margin-left: 22px;"
                        >
                          Any occurence - high
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div
                  class="rule"
                >
                  <p
                    class="form-label conditionLabel"
                  >
                    Secondary Rule
                  </p>
                  <span
                    class="muteText"
                    style="line-height: 20px;"
                  >
                    No criteria defined
                  </span>
                </div>
              </div>
            </div>
            <div
              class="form-section"
            >
              <h4>
                Details
              </h4>
              <br />
              <div
                class="form-label undefined"
              >
                Data Profile Name
              </div>
              <input
                class="form-control profile_name undefined"
                maxlength="32"
                placeholder="Enter a Data Profile name"
                value=""
              />
            </div>
            <div
              class="form-section"
            >
              <h4>
                Primary Rule
              </h4>
              <span
                class="muteText"
                style="line-height: 20px;"
              >
                Define the match criteria for a primary rule by adding data patterns and EDM datasets.
              </span>
              <hr />
              <div>
                <div
                  class="headGroup"
                >
                  <div
                    class="btnGroup "
                  >
                    <div
                      class="dd-menu dd-menu-left"
                    >
                      <div>
                        <button
                          aria-expanded="false"
                          class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                          data-testid="button-false"
                          type="button"
                        >
                          <span>
                            AND
                          </span>
                          <span
                            class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                          >
                            <svg
                              aria-hidden="true"
                              class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                              focusable="false"
                              role="img"
                              viewBox="0 0 256 512"
                            >
                              <path
                                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        class="dd-menu-items"
                      />
                    </div>
                    <span
                      class="tw-float-right ruleBtns"
                    >
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-data-pattern"
                        id="addPattern0"
                        type="button"
                      >
                        Add Data Pattern
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-edm-dataset"
                        id="addDataset0"
                        type="button"
                      >
                        Add EDM Dataset
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-group"
                        id="addGroup0"
                        type="button"
                      >
                        Add Group
                      </button>
                    </span>
                  </div>
                  <div
                    class="conditionGroup"
                  >
                    <div
                      class="dp ruleItem"
                    >
                      <div>
                        <div
                          class="tw-float-right"
                        >
                          <svg
                            class="muteText removeBtn"
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            stroke-width="0"
                            viewBox="0 0 352 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                            />
                          </svg>
                        </div>
                        <table
                          class="occurrenceFields-0-0"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  class="identifier-dropdown false css-2b097c-container"
                                >
                                  <span
                                    aria-atomic="false"
                                    aria-live="polite"
                                    aria-relevant="additions text"
                                    class="css-1f43avz-a11yText-A11yText"
                                  />
                                  <div
                                    class=" css-1y0yxj9-control"
                                  >
                                    <div
                                      class=" css-l1xr2i-Component"
                                    >
                                      <div
                                        class=" css-z0s8u7-placeholder"
                                      >
                                        Data Pattern
                                      </div>
                                      <div
                                        class="css-xry0bu-Component"
                                      >
                                        <div
                                          class=""
                                          style="display: inline-block;"
                                        >
                                          <input
                                            aria-autocomplete="list"
                                            autocapitalize="none"
                                            autocomplete="off"
                                            autocorrect="off"
                                            id="react-select-2-input"
                                            spellcheck="false"
                                            style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                            tabindex="0"
                                            type="text"
                                            value=""
                                          />
                                          <div
                                            style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class=" css-ny0e4k-Component"
                                    >
                                      <span
                                        class=" css-ds71as-Component"
                                      />
                                      <div
                                        aria-hidden="true"
                                        class=" css-tlfecz-indicatorContainer"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          class="css-tj5bde-Svg"
                                          focusable="false"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          width="20"
                                        >
                                          <path
                                            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  class="dd-menu dd-menu-left"
                                >
                                  <div>
                                    <button
                                      aria-expanded="false"
                                      class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                      data-testid="button-false"
                                      type="button"
                                    >
                                      <span>
                                        Any
                                      </span>
                                      <span
                                        class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 256 512"
                                        >
                                          <path
                                            d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                  <div
                                    class="dd-menu-items"
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  class="dd-menu dd-menu-left"
                                >
                                  <div>
                                    <button
                                      aria-expanded="false"
                                      class="basic-dropdown false confidence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                      data-testid="button-false"
                                      type="button"
                                    >
                                      <span>
                                        High
                                      </span>
                                      <span
                                        class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 256 512"
                                        >
                                          <path
                                            d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                  <div
                                    class="dd-menu-items"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-section"
            >
              <h4>
                Secondary Rule
              </h4>
              <span
                class="muteText"
                style="line-height: 20px;"
              >
                Define the match criteria for a secondary rule by adding data patterns and EDM datasets.
              </span>
              <hr />
              <div>
                <div
                  class="headGroup"
                >
                  <div
                    class="btnGroup "
                  >
                    <div
                      class="dd-menu dd-menu-left"
                    >
                      <div>
                        <button
                          aria-expanded="false"
                          class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                          data-testid="button-false"
                          type="button"
                        >
                          <span>
                            AND
                          </span>
                          <span
                            class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                          >
                            <svg
                              aria-hidden="true"
                              class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                              focusable="false"
                              role="img"
                              viewBox="0 0 256 512"
                            >
                              <path
                                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        class="dd-menu-items"
                      />
                    </div>
                    <span
                      class="tw-float-right ruleBtns"
                    >
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-data-pattern"
                        id="addPattern1"
                        type="button"
                      >
                        Add Data Pattern
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-edm-dataset"
                        id="addDataset1"
                        type="button"
                      >
                        Add EDM Dataset
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-group"
                        id="addGroup1"
                        type="button"
                      >
                        Add Group
                      </button>
                    </span>
                  </div>
                  <div
                    class="conditionGroup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="formFooter"
        >
          <div
            class="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
          >
            <button
              class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
              data-testid="button-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500  hover:tw-bg-blue-700 dark:hover:tw-bg-blue-600"
              data-testid="button-save"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </body>,
  "container": <div>
    <div>
      <div
        class="header main"
      >
        <div
          class="breadcrumb"
        >
          <a
            class="btn-link"
            href="/"
          >
            DLP
          </a>
          <span
            class="fa fa-chevron-right separator"
          />
          <a
            class="btn-link"
            href="/data_profiles"
          >
            Data Profiles
          </a>
          <span
            class="fa fa-chevron-right separator"
          />
          Add Data Profile
        </div>
        <div
          class="title"
        >
          Add Data Profile
        </div>
      </div>
      <div
        class="formArea"
      >
        <div
          class="dataProfileForm"
        >
          <div
            class="tw-float-right form-section preview"
          >
            <h4
              class="mt-2"
            >
              Data Profile Preview
              <span
                class="tw-float-right previewToggle"
              >
                <div>
                  <div
                    class="detail selected"
                    data-reach-tooltip-trigger=""
                    data-state="tooltip-hidden"
                  >
                    <span>
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div
                    class="bracket unselected"
                    data-reach-tooltip-trigger=""
                    data-state="tooltip-hidden"
                  >
                    <span>
                      <img
                        alt="brackets"
                      />
                    </span>
                  </div>
                </div>
              </span>
            </h4>
            <div
              style="padding: 15px;"
            >
              <div
                class="rule"
              >
                <p
                  class="form-label conditionLabel"
                >
                  Primary Rule
                </p>
                <div
                  class="sectionGroup"
                >
                  <div
                    class=""
                  >
                    <div
                      class="pattern"
                      style="margin-left: 17px;"
                    >
                      <svg
                        class="mr10 helpText"
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 448 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM240 320h-48v48c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h96c52.9 0 96 43.1 96 96s-43.1 96-96 96zm0-128h-48v64h48c17.6 0 32-14.4 32-32s-14.4-32-32-32z"
                        />
                      </svg>
                      Undefined
                      <br />
                      <span
                        class="helpText "
                        style="margin-left: 22px;"
                      >
                        Any occurence - high
                      </span>
                      <br />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div
                class="rule"
              >
                <p
                  class="form-label conditionLabel"
                >
                  Secondary Rule
                </p>
                <span
                  class="muteText"
                  style="line-height: 20px;"
                >
                  No criteria defined
                </span>
              </div>
            </div>
          </div>
          <div
            class="form-section"
          >
            <h4>
              Details
            </h4>
            <br />
            <div
              class="form-label undefined"
            >
              Data Profile Name
            </div>
            <input
              class="form-control profile_name undefined"
              maxlength="32"
              placeholder="Enter a Data Profile name"
              value=""
            />
          </div>
          <div
            class="form-section"
          >
            <h4>
              Primary Rule
            </h4>
            <span
              class="muteText"
              style="line-height: 20px;"
            >
              Define the match criteria for a primary rule by adding data patterns and EDM datasets.
            </span>
            <hr />
            <div>
              <div
                class="headGroup"
              >
                <div
                  class="btnGroup "
                >
                  <div
                    class="dd-menu dd-menu-left"
                  >
                    <div>
                      <button
                        aria-expanded="false"
                        class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                        data-testid="button-false"
                        type="button"
                      >
                        <span>
                          AND
                        </span>
                        <span
                          class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                        >
                          <svg
                            aria-hidden="true"
                            class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                            focusable="false"
                            role="img"
                            viewBox="0 0 256 512"
                          >
                            <path
                              d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div
                      class="dd-menu-items"
                    />
                  </div>
                  <span
                    class="tw-float-right ruleBtns"
                  >
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-data-pattern"
                      id="addPattern0"
                      type="button"
                    >
                      Add Data Pattern
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-edm-dataset"
                      id="addDataset0"
                      type="button"
                    >
                      Add EDM Dataset
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-group"
                      id="addGroup0"
                      type="button"
                    >
                      Add Group
                    </button>
                  </span>
                </div>
                <div
                  class="conditionGroup"
                >
                  <div
                    class="dp ruleItem"
                  >
                    <div>
                      <div
                        class="tw-float-right"
                      >
                        <svg
                          class="muteText removeBtn"
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 352 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                          />
                        </svg>
                      </div>
                      <table
                        class="occurrenceFields-0-0"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <div
                                class="identifier-dropdown false css-2b097c-container"
                              >
                                <span
                                  aria-atomic="false"
                                  aria-live="polite"
                                  aria-relevant="additions text"
                                  class="css-1f43avz-a11yText-A11yText"
                                />
                                <div
                                  class=" css-1y0yxj9-control"
                                >
                                  <div
                                    class=" css-l1xr2i-Component"
                                  >
                                    <div
                                      class=" css-z0s8u7-placeholder"
                                    >
                                      Data Pattern
                                    </div>
                                    <div
                                      class="css-xry0bu-Component"
                                    >
                                      <div
                                        class=""
                                        style="display: inline-block;"
                                      >
                                        <input
                                          aria-autocomplete="list"
                                          autocapitalize="none"
                                          autocomplete="off"
                                          autocorrect="off"
                                          id="react-select-2-input"
                                          spellcheck="false"
                                          style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                          tabindex="0"
                                          type="text"
                                          value=""
                                        />
                                        <div
                                          style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class=" css-ny0e4k-Component"
                                  >
                                    <span
                                      class=" css-ds71as-Component"
                                    />
                                    <div
                                      aria-hidden="true"
                                      class=" css-tlfecz-indicatorContainer"
                                    >
                                      <svg
                                        aria-hidden="true"
                                        class="css-tj5bde-Svg"
                                        focusable="false"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        width="20"
                                      >
                                        <path
                                          d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                class="dd-menu dd-menu-left"
                              >
                                <div>
                                  <button
                                    aria-expanded="false"
                                    class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                    data-testid="button-false"
                                    type="button"
                                  >
                                    <span>
                                      Any
                                    </span>
                                    <span
                                      class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                    >
                                      <svg
                                        aria-hidden="true"
                                        class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                        focusable="false"
                                        role="img"
                                        viewBox="0 0 256 512"
                                      >
                                        <path
                                          d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                                <div
                                  class="dd-menu-items"
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                class="dd-menu dd-menu-left"
                              >
                                <div>
                                  <button
                                    aria-expanded="false"
                                    class="basic-dropdown false confidence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                    data-testid="button-false"
                                    type="button"
                                  >
                                    <span>
                                      High
                                    </span>
                                    <span
                                      class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                    >
                                      <svg
                                        aria-hidden="true"
                                        class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                        focusable="false"
                                        role="img"
                                        viewBox="0 0 256 512"
                                      >
                                        <path
                                          d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                                <div
                                  class="dd-menu-items"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-section"
          >
            <h4>
              Secondary Rule
            </h4>
            <span
              class="muteText"
              style="line-height: 20px;"
            >
              Define the match criteria for a secondary rule by adding data patterns and EDM datasets.
            </span>
            <hr />
            <div>
              <div
                class="headGroup"
              >
                <div
                  class="btnGroup "
                >
                  <div
                    class="dd-menu dd-menu-left"
                  >
                    <div>
                      <button
                        aria-expanded="false"
                        class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                        data-testid="button-false"
                        type="button"
                      >
                        <span>
                          AND
                        </span>
                        <span
                          class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                        >
                          <svg
                            aria-hidden="true"
                            class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                            focusable="false"
                            role="img"
                            viewBox="0 0 256 512"
                          >
                            <path
                              d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div
                      class="dd-menu-items"
                    />
                  </div>
                  <span
                    class="tw-float-right ruleBtns"
                  >
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-data-pattern"
                      id="addPattern1"
                      type="button"
                    >
                      Add Data Pattern
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-edm-dataset"
                      id="addDataset1"
                      type="button"
                    >
                      Add EDM Dataset
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-group"
                      id="addGroup1"
                      type="button"
                    >
                      Add Group
                    </button>
                  </span>
                </div>
                <div
                  class="conditionGroup"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="formFooter"
      >
        <div
          class="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
        >
          <button
            class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
            data-testid="button-cancel"
            type="button"
          >
            Cancel
          </button>
          <button
            class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500  hover:tw-bg-blue-700 dark:hover:tw-bg-blue-600"
            data-testid="button-save"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;

exports[`<DataProfileFormV2> create form add buttons adds edm dataset rule item 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div>
        <div
          class="header main"
        >
          <div
            class="breadcrumb"
          >
            <a
              class="btn-link"
              href="/"
            >
              DLP
            </a>
            <span
              class="fa fa-chevron-right separator"
            />
            <a
              class="btn-link"
              href="/data_profiles"
            >
              Data Profiles
            </a>
            <span
              class="fa fa-chevron-right separator"
            />
            Add Data Profile
          </div>
          <div
            class="title"
          >
            Add Data Profile
          </div>
        </div>
        <div
          class="formArea"
        >
          <div
            class="dataProfileForm"
          >
            <div
              class="tw-float-right form-section preview"
            >
              <h4
                class="mt-2"
              >
                Data Profile Preview
                <span
                  class="tw-float-right previewToggle"
                >
                  <div>
                    <div
                      class="detail selected"
                      data-reach-tooltip-trigger=""
                      data-state="tooltip-hidden"
                    >
                      <span>
                        <svg
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                          />
                        </svg>
                      </span>
                    </div>
                    <div
                      class="bracket unselected"
                      data-reach-tooltip-trigger=""
                      data-state="tooltip-hidden"
                    >
                      <span>
                        <img
                          alt="brackets"
                        />
                      </span>
                    </div>
                  </div>
                </span>
              </h4>
              <div
                style="padding: 15px;"
              >
                <div
                  class="rule"
                >
                  <p
                    class="form-label conditionLabel"
                  >
                    Primary Rule
                  </p>
                  <div
                    class="sectionGroup"
                  >
                    <div
                      class=""
                    >
                      <div
                        class="dataset"
                        style="margin-left: 17px;"
                      >
                        <svg
                          class="mr10 helpText"
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                          />
                        </svg>
                        Undefined
                        <br />
                        <div
                          class="helpText "
                          style="margin-left: 23px;"
                        >
                          Any occurence
                        </div>
                        <div
                          class="matchDef helpText"
                          style="margin-left: 24px;"
                        >
                          <span
                            class="mr5"
                          >
                            Match
                          </span>
                          <span
                            class=""
                          >
                            <span
                              class="mr5 codeRed"
                            >
                              all
                            </span>
                          </span>
                          <span
                            class="mr5"
                          >
                            in
                          </span>
                          <span
                            class="mr5"
                          >
                            <span
                              class=""
                            >
                              (
                              )
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div
                  class="rule"
                >
                  <p
                    class="form-label conditionLabel"
                  >
                    Secondary Rule
                  </p>
                  <span
                    class="muteText"
                    style="line-height: 20px;"
                  >
                    No criteria defined
                  </span>
                </div>
              </div>
            </div>
            <div
              class="form-section"
            >
              <h4>
                Details
              </h4>
              <br />
              <div
                class="form-label undefined"
              >
                Data Profile Name
              </div>
              <input
                class="form-control profile_name undefined"
                maxlength="32"
                placeholder="Enter a Data Profile name"
                value=""
              />
            </div>
            <div
              class="form-section"
            >
              <h4>
                Primary Rule
              </h4>
              <span
                class="muteText"
                style="line-height: 20px;"
              >
                Define the match criteria for a primary rule by adding data patterns and EDM datasets.
              </span>
              <hr />
              <div>
                <div
                  class="headGroup"
                >
                  <div
                    class="btnGroup "
                  >
                    <div
                      class="dd-menu dd-menu-left"
                    >
                      <div>
                        <button
                          aria-expanded="false"
                          class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                          data-testid="button-false"
                          type="button"
                        >
                          <span>
                            AND
                          </span>
                          <span
                            class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                          >
                            <svg
                              aria-hidden="true"
                              class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                              focusable="false"
                              role="img"
                              viewBox="0 0 256 512"
                            >
                              <path
                                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        class="dd-menu-items"
                      />
                    </div>
                    <span
                      class="tw-float-right ruleBtns"
                    >
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-data-pattern"
                        id="addPattern0"
                        type="button"
                      >
                        Add Data Pattern
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-edm-dataset"
                        id="addDataset0"
                        type="button"
                      >
                        Add EDM Dataset
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-group"
                        id="addGroup0"
                        type="button"
                      >
                        Add Group
                      </button>
                    </span>
                  </div>
                  <div
                    class="conditionGroup"
                  >
                    <div
                      class="edm ruleItem"
                    >
                      <div
                        id="ruleItem0-0"
                      >
                        <div
                          class="tw-float-right"
                        >
                          <svg
                            class="muteText removeBtn"
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            stroke-width="0"
                            viewBox="0 0 352 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                            />
                          </svg>
                        </div>
                        <table
                          class="occurrenceFields"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  class="identifier-dropdown false css-2b097c-container"
                                >
                                  <span
                                    aria-atomic="false"
                                    aria-live="polite"
                                    aria-relevant="additions text"
                                    class="css-1f43avz-a11yText-A11yText"
                                  />
                                  <div
                                    class=" css-1y0yxj9-control"
                                  >
                                    <div
                                      class=" css-l1xr2i-Component"
                                    >
                                      <div
                                        class=" css-z0s8u7-placeholder"
                                      >
                                        EDM Dataset
                                      </div>
                                      <div
                                        class="css-xry0bu-Component"
                                      >
                                        <div
                                          class=""
                                          style="display: inline-block;"
                                        >
                                          <input
                                            aria-autocomplete="list"
                                            autocapitalize="none"
                                            autocomplete="off"
                                            autocorrect="off"
                                            id="react-select-3-input"
                                            spellcheck="false"
                                            style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                            tabindex="0"
                                            type="text"
                                            value=""
                                          />
                                          <div
                                            style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class=" css-ny0e4k-Component"
                                    >
                                      <span
                                        class=" css-ds71as-Component"
                                      />
                                      <div
                                        aria-hidden="true"
                                        class=" css-tlfecz-indicatorContainer"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          class="css-tj5bde-Svg"
                                          focusable="false"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          width="20"
                                        >
                                          <path
                                            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  class="dd-menu dd-menu-left"
                                >
                                  <div>
                                    <button
                                      aria-expanded="false"
                                      class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                      data-testid="button-false"
                                      type="button"
                                    >
                                      <span>
                                        Any
                                      </span>
                                      <span
                                        class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 256 512"
                                        >
                                          <path
                                            d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                  <div
                                    class="dd-menu-items"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          class="fields"
                        >
                          <span
                            class="fieldsMatch"
                          >
                            <div
                              class="helpText"
                            >
                              Match
                            </div>
                            <div
                              class="dd-menu dd-menu-left"
                            >
                              <div>
                                <button
                                  aria-expanded="false"
                                  class="basic-dropdown false match-criteria tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                  data-testid="button-false"
                                  type="button"
                                >
                                  <span>
                                    All (AND)
                                  </span>
                                  <span
                                    class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                      focusable="false"
                                      role="img"
                                      viewBox="0 0 256 512"
                                    >
                                      <path
                                        d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </button>
                              </div>
                              <div
                                class="dd-menu-items"
                              />
                            </div>
                          </span>
                          <div
                            class=" fieldDropdown"
                          >
                            <div
                              class="multiselect-container _3vt7_Mh4hRCFbp__dFqBCI "
                              id="primarySelect0-0"
                            >
                              <div
                                class="search-wrapper _2iA8p44d0WZ-WqRBGcAuEV "
                                style="width: 340px; background: rgb(255, 255, 255);"
                              >
                                <input
                                  autocomplete="off"
                                  class="searchBox"
                                  id="primarySelect0-0_input"
                                  placeholder="Primary Fields"
                                  style="width: 200px;"
                                  type="text"
                                  value=""
                                />
                                <i
                                  class="icon_cancel _1cgu0CqbPSvU9G_sHmk1wv"
                                />
                              </div>
                              <div
                                class="optionListContainer _3vLmCG3bB3CM2hhAiQX1tN Dv7FLAST-3bZceA5TIxEN"
                              >
                                <ul
                                  class="optionContainer"
                                  style="width: 340px; background: rgb(255, 255, 255);"
                                >
                                  <span
                                    class="notFound _1e56tWp4cEJMn-cD-PZArd"
                                  >
                                    No Options Available
                                  </span>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="fields"
                        >
                          <span
                            class="fieldsMatch"
                          >
                            <div
                              class="helpText italic"
                            >
                              AND
                            </div>
                            <div
                              class="dd-menu dd-menu-left"
                            >
                              <div>
                                <button
                                  aria-expanded="false"
                                  class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                  data-testid="button-false"
                                  type="button"
                                >
                                  <span>
                                    All (AND)
                                  </span>
                                  <span
                                    class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                      focusable="false"
                                      role="img"
                                      viewBox="0 0 256 512"
                                    >
                                      <path
                                        d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </button>
                              </div>
                              <div
                                class="dd-menu-items"
                              />
                            </div>
                          </span>
                          <div
                            class=" fieldDropdown"
                          >
                            <div
                              class="multiselect-container _3vt7_Mh4hRCFbp__dFqBCI "
                              id="secondarySelect0-0"
                            >
                              <div
                                class="search-wrapper _2iA8p44d0WZ-WqRBGcAuEV "
                                style="width: 340px; background: rgb(255, 255, 255);"
                              >
                                <input
                                  autocomplete="off"
                                  class="searchBox"
                                  id="secondarySelect0-0_input"
                                  placeholder="Secondary Field (optional)"
                                  style="width: 200px;"
                                  type="text"
                                  value=""
                                />
                                <i
                                  class="icon_cancel _1cgu0CqbPSvU9G_sHmk1wv"
                                />
                              </div>
                              <div
                                class="optionListContainer _3vLmCG3bB3CM2hhAiQX1tN Dv7FLAST-3bZceA5TIxEN"
                              >
                                <ul
                                  class="optionContainer"
                                  style="width: 340px; background: rgb(255, 255, 255);"
                                >
                                  <span
                                    class="notFound _1e56tWp4cEJMn-cD-PZArd"
                                  >
                                    No Options Available
                                  </span>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-section"
            >
              <h4>
                Secondary Rule
              </h4>
              <span
                class="muteText"
                style="line-height: 20px;"
              >
                Define the match criteria for a secondary rule by adding data patterns and EDM datasets.
              </span>
              <hr />
              <div>
                <div
                  class="headGroup"
                >
                  <div
                    class="btnGroup "
                  >
                    <div
                      class="dd-menu dd-menu-left"
                    >
                      <div>
                        <button
                          aria-expanded="false"
                          class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                          data-testid="button-false"
                          type="button"
                        >
                          <span>
                            AND
                          </span>
                          <span
                            class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                          >
                            <svg
                              aria-hidden="true"
                              class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                              focusable="false"
                              role="img"
                              viewBox="0 0 256 512"
                            >
                              <path
                                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        class="dd-menu-items"
                      />
                    </div>
                    <span
                      class="tw-float-right ruleBtns"
                    >
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-data-pattern"
                        id="addPattern1"
                        type="button"
                      >
                        Add Data Pattern
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-edm-dataset"
                        id="addDataset1"
                        type="button"
                      >
                        Add EDM Dataset
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-group"
                        id="addGroup1"
                        type="button"
                      >
                        Add Group
                      </button>
                    </span>
                  </div>
                  <div
                    class="conditionGroup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="formFooter"
        >
          <div
            class="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
          >
            <button
              class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
              data-testid="button-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500  hover:tw-bg-blue-700 dark:hover:tw-bg-blue-600"
              data-testid="button-save"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </body>,
  "container": <div>
    <div>
      <div
        class="header main"
      >
        <div
          class="breadcrumb"
        >
          <a
            class="btn-link"
            href="/"
          >
            DLP
          </a>
          <span
            class="fa fa-chevron-right separator"
          />
          <a
            class="btn-link"
            href="/data_profiles"
          >
            Data Profiles
          </a>
          <span
            class="fa fa-chevron-right separator"
          />
          Add Data Profile
        </div>
        <div
          class="title"
        >
          Add Data Profile
        </div>
      </div>
      <div
        class="formArea"
      >
        <div
          class="dataProfileForm"
        >
          <div
            class="tw-float-right form-section preview"
          >
            <h4
              class="mt-2"
            >
              Data Profile Preview
              <span
                class="tw-float-right previewToggle"
              >
                <div>
                  <div
                    class="detail selected"
                    data-reach-tooltip-trigger=""
                    data-state="tooltip-hidden"
                  >
                    <span>
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div
                    class="bracket unselected"
                    data-reach-tooltip-trigger=""
                    data-state="tooltip-hidden"
                  >
                    <span>
                      <img
                        alt="brackets"
                      />
                    </span>
                  </div>
                </div>
              </span>
            </h4>
            <div
              style="padding: 15px;"
            >
              <div
                class="rule"
              >
                <p
                  class="form-label conditionLabel"
                >
                  Primary Rule
                </p>
                <div
                  class="sectionGroup"
                >
                  <div
                    class=""
                  >
                    <div
                      class="dataset"
                      style="margin-left: 17px;"
                    >
                      <svg
                        class="mr10 helpText"
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                        />
                      </svg>
                      Undefined
                      <br />
                      <div
                        class="helpText "
                        style="margin-left: 23px;"
                      >
                        Any occurence
                      </div>
                      <div
                        class="matchDef helpText"
                        style="margin-left: 24px;"
                      >
                        <span
                          class="mr5"
                        >
                          Match
                        </span>
                        <span
                          class=""
                        >
                          <span
                            class="mr5 codeRed"
                          >
                            all
                          </span>
                        </span>
                        <span
                          class="mr5"
                        >
                          in
                        </span>
                        <span
                          class="mr5"
                        >
                          <span
                            class=""
                          >
                            (
                            )
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div
                class="rule"
              >
                <p
                  class="form-label conditionLabel"
                >
                  Secondary Rule
                </p>
                <span
                  class="muteText"
                  style="line-height: 20px;"
                >
                  No criteria defined
                </span>
              </div>
            </div>
          </div>
          <div
            class="form-section"
          >
            <h4>
              Details
            </h4>
            <br />
            <div
              class="form-label undefined"
            >
              Data Profile Name
            </div>
            <input
              class="form-control profile_name undefined"
              maxlength="32"
              placeholder="Enter a Data Profile name"
              value=""
            />
          </div>
          <div
            class="form-section"
          >
            <h4>
              Primary Rule
            </h4>
            <span
              class="muteText"
              style="line-height: 20px;"
            >
              Define the match criteria for a primary rule by adding data patterns and EDM datasets.
            </span>
            <hr />
            <div>
              <div
                class="headGroup"
              >
                <div
                  class="btnGroup "
                >
                  <div
                    class="dd-menu dd-menu-left"
                  >
                    <div>
                      <button
                        aria-expanded="false"
                        class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                        data-testid="button-false"
                        type="button"
                      >
                        <span>
                          AND
                        </span>
                        <span
                          class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                        >
                          <svg
                            aria-hidden="true"
                            class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                            focusable="false"
                            role="img"
                            viewBox="0 0 256 512"
                          >
                            <path
                              d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div
                      class="dd-menu-items"
                    />
                  </div>
                  <span
                    class="tw-float-right ruleBtns"
                  >
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-data-pattern"
                      id="addPattern0"
                      type="button"
                    >
                      Add Data Pattern
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-edm-dataset"
                      id="addDataset0"
                      type="button"
                    >
                      Add EDM Dataset
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-group"
                      id="addGroup0"
                      type="button"
                    >
                      Add Group
                    </button>
                  </span>
                </div>
                <div
                  class="conditionGroup"
                >
                  <div
                    class="edm ruleItem"
                  >
                    <div
                      id="ruleItem0-0"
                    >
                      <div
                        class="tw-float-right"
                      >
                        <svg
                          class="muteText removeBtn"
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 352 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                          />
                        </svg>
                      </div>
                      <table
                        class="occurrenceFields"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <div
                                class="identifier-dropdown false css-2b097c-container"
                              >
                                <span
                                  aria-atomic="false"
                                  aria-live="polite"
                                  aria-relevant="additions text"
                                  class="css-1f43avz-a11yText-A11yText"
                                />
                                <div
                                  class=" css-1y0yxj9-control"
                                >
                                  <div
                                    class=" css-l1xr2i-Component"
                                  >
                                    <div
                                      class=" css-z0s8u7-placeholder"
                                    >
                                      EDM Dataset
                                    </div>
                                    <div
                                      class="css-xry0bu-Component"
                                    >
                                      <div
                                        class=""
                                        style="display: inline-block;"
                                      >
                                        <input
                                          aria-autocomplete="list"
                                          autocapitalize="none"
                                          autocomplete="off"
                                          autocorrect="off"
                                          id="react-select-3-input"
                                          spellcheck="false"
                                          style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                          tabindex="0"
                                          type="text"
                                          value=""
                                        />
                                        <div
                                          style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class=" css-ny0e4k-Component"
                                  >
                                    <span
                                      class=" css-ds71as-Component"
                                    />
                                    <div
                                      aria-hidden="true"
                                      class=" css-tlfecz-indicatorContainer"
                                    >
                                      <svg
                                        aria-hidden="true"
                                        class="css-tj5bde-Svg"
                                        focusable="false"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        width="20"
                                      >
                                        <path
                                          d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                class="dd-menu dd-menu-left"
                              >
                                <div>
                                  <button
                                    aria-expanded="false"
                                    class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                    data-testid="button-false"
                                    type="button"
                                  >
                                    <span>
                                      Any
                                    </span>
                                    <span
                                      class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                    >
                                      <svg
                                        aria-hidden="true"
                                        class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                        focusable="false"
                                        role="img"
                                        viewBox="0 0 256 512"
                                      >
                                        <path
                                          d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                                <div
                                  class="dd-menu-items"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        class="fields"
                      >
                        <span
                          class="fieldsMatch"
                        >
                          <div
                            class="helpText"
                          >
                            Match
                          </div>
                          <div
                            class="dd-menu dd-menu-left"
                          >
                            <div>
                              <button
                                aria-expanded="false"
                                class="basic-dropdown false match-criteria tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                data-testid="button-false"
                                type="button"
                              >
                                <span>
                                  All (AND)
                                </span>
                                <span
                                  class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                >
                                  <svg
                                    aria-hidden="true"
                                    class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                    focusable="false"
                                    role="img"
                                    viewBox="0 0 256 512"
                                  >
                                    <path
                                      d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </button>
                            </div>
                            <div
                              class="dd-menu-items"
                            />
                          </div>
                        </span>
                        <div
                          class=" fieldDropdown"
                        >
                          <div
                            class="multiselect-container _3vt7_Mh4hRCFbp__dFqBCI "
                            id="primarySelect0-0"
                          >
                            <div
                              class="search-wrapper _2iA8p44d0WZ-WqRBGcAuEV "
                              style="width: 340px; background: rgb(255, 255, 255);"
                            >
                              <input
                                autocomplete="off"
                                class="searchBox"
                                id="primarySelect0-0_input"
                                placeholder="Primary Fields"
                                style="width: 200px;"
                                type="text"
                                value=""
                              />
                              <i
                                class="icon_cancel _1cgu0CqbPSvU9G_sHmk1wv"
                              />
                            </div>
                            <div
                              class="optionListContainer _3vLmCG3bB3CM2hhAiQX1tN Dv7FLAST-3bZceA5TIxEN"
                            >
                              <ul
                                class="optionContainer"
                                style="width: 340px; background: rgb(255, 255, 255);"
                              >
                                <span
                                  class="notFound _1e56tWp4cEJMn-cD-PZArd"
                                >
                                  No Options Available
                                </span>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="fields"
                      >
                        <span
                          class="fieldsMatch"
                        >
                          <div
                            class="helpText italic"
                          >
                            AND
                          </div>
                          <div
                            class="dd-menu dd-menu-left"
                          >
                            <div>
                              <button
                                aria-expanded="false"
                                class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                                data-testid="button-false"
                                type="button"
                              >
                                <span>
                                  All (AND)
                                </span>
                                <span
                                  class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                                >
                                  <svg
                                    aria-hidden="true"
                                    class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                    focusable="false"
                                    role="img"
                                    viewBox="0 0 256 512"
                                  >
                                    <path
                                      d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </button>
                            </div>
                            <div
                              class="dd-menu-items"
                            />
                          </div>
                        </span>
                        <div
                          class=" fieldDropdown"
                        >
                          <div
                            class="multiselect-container _3vt7_Mh4hRCFbp__dFqBCI "
                            id="secondarySelect0-0"
                          >
                            <div
                              class="search-wrapper _2iA8p44d0WZ-WqRBGcAuEV "
                              style="width: 340px; background: rgb(255, 255, 255);"
                            >
                              <input
                                autocomplete="off"
                                class="searchBox"
                                id="secondarySelect0-0_input"
                                placeholder="Secondary Field (optional)"
                                style="width: 200px;"
                                type="text"
                                value=""
                              />
                              <i
                                class="icon_cancel _1cgu0CqbPSvU9G_sHmk1wv"
                              />
                            </div>
                            <div
                              class="optionListContainer _3vLmCG3bB3CM2hhAiQX1tN Dv7FLAST-3bZceA5TIxEN"
                            >
                              <ul
                                class="optionContainer"
                                style="width: 340px; background: rgb(255, 255, 255);"
                              >
                                <span
                                  class="notFound _1e56tWp4cEJMn-cD-PZArd"
                                >
                                  No Options Available
                                </span>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-section"
          >
            <h4>
              Secondary Rule
            </h4>
            <span
              class="muteText"
              style="line-height: 20px;"
            >
              Define the match criteria for a secondary rule by adding data patterns and EDM datasets.
            </span>
            <hr />
            <div>
              <div
                class="headGroup"
              >
                <div
                  class="btnGroup "
                >
                  <div
                    class="dd-menu dd-menu-left"
                  >
                    <div>
                      <button
                        aria-expanded="false"
                        class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                        data-testid="button-false"
                        type="button"
                      >
                        <span>
                          AND
                        </span>
                        <span
                          class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                        >
                          <svg
                            aria-hidden="true"
                            class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                            focusable="false"
                            role="img"
                            viewBox="0 0 256 512"
                          >
                            <path
                              d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div
                      class="dd-menu-items"
                    />
                  </div>
                  <span
                    class="tw-float-right ruleBtns"
                  >
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-data-pattern"
                      id="addPattern1"
                      type="button"
                    >
                      Add Data Pattern
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-edm-dataset"
                      id="addDataset1"
                      type="button"
                    >
                      Add EDM Dataset
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-group"
                      id="addGroup1"
                      type="button"
                    >
                      Add Group
                    </button>
                  </span>
                </div>
                <div
                  class="conditionGroup"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="formFooter"
      >
        <div
          class="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
        >
          <button
            class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
            data-testid="button-cancel"
            type="button"
          >
            Cancel
          </button>
          <button
            class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500  hover:tw-bg-blue-700 dark:hover:tw-bg-blue-600"
            data-testid="button-save"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;

exports[`<DataProfileFormV2> create form add buttons adds group 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div>
        <div
          class="header main"
        >
          <div
            class="breadcrumb"
          >
            <a
              class="btn-link"
              href="/"
            >
              DLP
            </a>
            <span
              class="fa fa-chevron-right separator"
            />
            <a
              class="btn-link"
              href="/data_profiles"
            >
              Data Profiles
            </a>
            <span
              class="fa fa-chevron-right separator"
            />
            Add Data Profile
          </div>
          <div
            class="title"
          >
            Add Data Profile
          </div>
        </div>
        <div
          class="formArea"
        >
          <div
            class="dataProfileForm"
          >
            <div
              class="tw-float-right form-section preview"
            >
              <h4
                class="mt-2"
              >
                Data Profile Preview
                <span
                  class="tw-float-right previewToggle"
                >
                  <div>
                    <div
                      class="detail selected"
                      data-reach-tooltip-trigger=""
                      data-state="tooltip-hidden"
                    >
                      <span>
                        <svg
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                          />
                        </svg>
                      </span>
                    </div>
                    <div
                      class="bracket unselected"
                      data-reach-tooltip-trigger=""
                      data-state="tooltip-hidden"
                    >
                      <span>
                        <img
                          alt="brackets"
                        />
                      </span>
                    </div>
                  </div>
                </span>
              </h4>
              <div
                style="padding: 15px;"
              >
                <div
                  class="rule"
                >
                  <p
                    class="form-label conditionLabel"
                  >
                    Primary Rule
                  </p>
                  <div
                    class="sectionGroup"
                  >
                    <div
                      class="nestedSection"
                    >
                      <div
                        class="nestedSectionHead"
                      >
                        <span
                          class="angleToggle"
                        >
                          <svg
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            stroke-width="0"
                            viewBox="0 0 320 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
                            />
                          </svg>
                        </span>
                        <svg
                          class="mr10 ml10 muteText"
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                          />
                        </svg>
                        <span
                          class="upperBold mr5  "
                        >
                          and
                        </span>
                      </div>
                      <div
                        class="sectionGroup"
                      />
                    </div>
                  </div>
                  <hr />
                </div>
                <div
                  class="rule"
                >
                  <p
                    class="form-label conditionLabel"
                  >
                    Secondary Rule
                  </p>
                  <span
                    class="muteText"
                    style="line-height: 20px;"
                  >
                    No criteria defined
                  </span>
                </div>
              </div>
            </div>
            <div
              class="form-section"
            >
              <h4>
                Details
              </h4>
              <br />
              <div
                class="form-label undefined"
              >
                Data Profile Name
              </div>
              <input
                class="form-control profile_name undefined"
                maxlength="32"
                placeholder="Enter a Data Profile name"
                value=""
              />
            </div>
            <div
              class="form-section"
            >
              <h4>
                Primary Rule
              </h4>
              <span
                class="muteText"
                style="line-height: 20px;"
              >
                Define the match criteria for a primary rule by adding data patterns and EDM datasets.
              </span>
              <hr />
              <div>
                <div
                  class="headGroup"
                >
                  <div
                    class="btnGroup "
                  >
                    <div
                      class="dd-menu dd-menu-left"
                    >
                      <div>
                        <button
                          aria-expanded="false"
                          class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                          data-testid="button-false"
                          type="button"
                        >
                          <span>
                            AND
                          </span>
                          <span
                            class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                          >
                            <svg
                              aria-hidden="true"
                              class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                              focusable="false"
                              role="img"
                              viewBox="0 0 256 512"
                            >
                              <path
                                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        class="dd-menu-items"
                      />
                    </div>
                    <span
                      class="tw-float-right ruleBtns"
                    >
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-data-pattern"
                        id="addPattern0"
                        type="button"
                      >
                        Add Data Pattern
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-edm-dataset"
                        id="addDataset0"
                        type="button"
                      >
                        Add EDM Dataset
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-group"
                        id="addGroup0"
                        type="button"
                      >
                        Add Group
                      </button>
                    </span>
                  </div>
                  <div
                    class="conditionGroup"
                  >
                    <div
                      class="nest"
                    >
                      <div
                        class="btnGroup "
                      >
                        <div
                          class="dd-menu dd-menu-left"
                        >
                          <div>
                            <button
                              aria-expanded="false"
                              class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                              data-testid="button-false"
                              type="button"
                            >
                              <span>
                                AND
                              </span>
                              <span
                                class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable="false"
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div
                            class="dd-menu-items"
                          />
                        </div>
                        <span
                          class="tw-float-right ruleBtns"
                        >
                          <button
                            class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                            data-testid="button-add-data-pattern"
                            id="addPattern0-0"
                            type="button"
                          >
                            Add Data Pattern
                          </button>
                          <button
                            class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                            data-testid="button-add-edm-dataset"
                            id="addDataset0-0"
                            type="button"
                          >
                            Add EDM Dataset
                          </button>
                          <button
                            class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                            data-testid="button-add-group"
                            id="addGroup0-0"
                            type="button"
                          >
                            Add Group
                          </button>
                          <svg
                            class="muteText removeBtn ml5"
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            stroke-width="0"
                            viewBox="0 0 352 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div
                        class="conditionGroup"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-section"
            >
              <h4>
                Secondary Rule
              </h4>
              <span
                class="muteText"
                style="line-height: 20px;"
              >
                Define the match criteria for a secondary rule by adding data patterns and EDM datasets.
              </span>
              <hr />
              <div>
                <div
                  class="headGroup"
                >
                  <div
                    class="btnGroup "
                  >
                    <div
                      class="dd-menu dd-menu-left"
                    >
                      <div>
                        <button
                          aria-expanded="false"
                          class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                          data-testid="button-false"
                          type="button"
                        >
                          <span>
                            AND
                          </span>
                          <span
                            class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                          >
                            <svg
                              aria-hidden="true"
                              class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                              focusable="false"
                              role="img"
                              viewBox="0 0 256 512"
                            >
                              <path
                                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        class="dd-menu-items"
                      />
                    </div>
                    <span
                      class="tw-float-right ruleBtns"
                    >
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-data-pattern"
                        id="addPattern1"
                        type="button"
                      >
                        Add Data Pattern
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-edm-dataset"
                        id="addDataset1"
                        type="button"
                      >
                        Add EDM Dataset
                      </button>
                      <button
                        class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                        data-testid="button-add-group"
                        id="addGroup1"
                        type="button"
                      >
                        Add Group
                      </button>
                    </span>
                  </div>
                  <div
                    class="conditionGroup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="formFooter"
        >
          <div
            class="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
          >
            <button
              class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
              data-testid="button-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500  hover:tw-bg-blue-700 dark:hover:tw-bg-blue-600"
              data-testid="button-save"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </body>,
  "container": <div>
    <div>
      <div
        class="header main"
      >
        <div
          class="breadcrumb"
        >
          <a
            class="btn-link"
            href="/"
          >
            DLP
          </a>
          <span
            class="fa fa-chevron-right separator"
          />
          <a
            class="btn-link"
            href="/data_profiles"
          >
            Data Profiles
          </a>
          <span
            class="fa fa-chevron-right separator"
          />
          Add Data Profile
        </div>
        <div
          class="title"
        >
          Add Data Profile
        </div>
      </div>
      <div
        class="formArea"
      >
        <div
          class="dataProfileForm"
        >
          <div
            class="tw-float-right form-section preview"
          >
            <h4
              class="mt-2"
            >
              Data Profile Preview
              <span
                class="tw-float-right previewToggle"
              >
                <div>
                  <div
                    class="detail selected"
                    data-reach-tooltip-trigger=""
                    data-state="tooltip-hidden"
                  >
                    <span>
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div
                    class="bracket unselected"
                    data-reach-tooltip-trigger=""
                    data-state="tooltip-hidden"
                  >
                    <span>
                      <img
                        alt="brackets"
                      />
                    </span>
                  </div>
                </div>
              </span>
            </h4>
            <div
              style="padding: 15px;"
            >
              <div
                class="rule"
              >
                <p
                  class="form-label conditionLabel"
                >
                  Primary Rule
                </p>
                <div
                  class="sectionGroup"
                >
                  <div
                    class="nestedSection"
                  >
                    <div
                      class="nestedSectionHead"
                    >
                      <span
                        class="angleToggle"
                      >
                        <svg
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 320 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
                          />
                        </svg>
                      </span>
                      <svg
                        class="mr10 ml10 muteText"
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                        />
                      </svg>
                      <span
                        class="upperBold mr5  "
                      >
                        and
                      </span>
                    </div>
                    <div
                      class="sectionGroup"
                    />
                  </div>
                </div>
                <hr />
              </div>
              <div
                class="rule"
              >
                <p
                  class="form-label conditionLabel"
                >
                  Secondary Rule
                </p>
                <span
                  class="muteText"
                  style="line-height: 20px;"
                >
                  No criteria defined
                </span>
              </div>
            </div>
          </div>
          <div
            class="form-section"
          >
            <h4>
              Details
            </h4>
            <br />
            <div
              class="form-label undefined"
            >
              Data Profile Name
            </div>
            <input
              class="form-control profile_name undefined"
              maxlength="32"
              placeholder="Enter a Data Profile name"
              value=""
            />
          </div>
          <div
            class="form-section"
          >
            <h4>
              Primary Rule
            </h4>
            <span
              class="muteText"
              style="line-height: 20px;"
            >
              Define the match criteria for a primary rule by adding data patterns and EDM datasets.
            </span>
            <hr />
            <div>
              <div
                class="headGroup"
              >
                <div
                  class="btnGroup "
                >
                  <div
                    class="dd-menu dd-menu-left"
                  >
                    <div>
                      <button
                        aria-expanded="false"
                        class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                        data-testid="button-false"
                        type="button"
                      >
                        <span>
                          AND
                        </span>
                        <span
                          class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                        >
                          <svg
                            aria-hidden="true"
                            class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                            focusable="false"
                            role="img"
                            viewBox="0 0 256 512"
                          >
                            <path
                              d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div
                      class="dd-menu-items"
                    />
                  </div>
                  <span
                    class="tw-float-right ruleBtns"
                  >
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-data-pattern"
                      id="addPattern0"
                      type="button"
                    >
                      Add Data Pattern
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-edm-dataset"
                      id="addDataset0"
                      type="button"
                    >
                      Add EDM Dataset
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-group"
                      id="addGroup0"
                      type="button"
                    >
                      Add Group
                    </button>
                  </span>
                </div>
                <div
                  class="conditionGroup"
                >
                  <div
                    class="nest"
                  >
                    <div
                      class="btnGroup "
                    >
                      <div
                        class="dd-menu dd-menu-left"
                      >
                        <div>
                          <button
                            aria-expanded="false"
                            class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                            data-testid="button-false"
                            type="button"
                          >
                            <span>
                              AND
                            </span>
                            <span
                              class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <svg
                                aria-hidden="true"
                                class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                focusable="false"
                                role="img"
                                viewBox="0 0 256 512"
                              >
                                <path
                                  d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div
                          class="dd-menu-items"
                        />
                      </div>
                      <span
                        class="tw-float-right ruleBtns"
                      >
                        <button
                          class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                          data-testid="button-add-data-pattern"
                          id="addPattern0-0"
                          type="button"
                        >
                          Add Data Pattern
                        </button>
                        <button
                          class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                          data-testid="button-add-edm-dataset"
                          id="addDataset0-0"
                          type="button"
                        >
                          Add EDM Dataset
                        </button>
                        <button
                          class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                          data-testid="button-add-group"
                          id="addGroup0-0"
                          type="button"
                        >
                          Add Group
                        </button>
                        <svg
                          class="muteText removeBtn ml5"
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 352 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                          />
                        </svg>
                      </span>
                    </div>
                    <div
                      class="conditionGroup"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-section"
          >
            <h4>
              Secondary Rule
            </h4>
            <span
              class="muteText"
              style="line-height: 20px;"
            >
              Define the match criteria for a secondary rule by adding data patterns and EDM datasets.
            </span>
            <hr />
            <div>
              <div
                class="headGroup"
              >
                <div
                  class="btnGroup "
                >
                  <div
                    class="dd-menu dd-menu-left"
                  >
                    <div>
                      <button
                        aria-expanded="false"
                        class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                        data-testid="button-false"
                        type="button"
                      >
                        <span>
                          AND
                        </span>
                        <span
                          class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                        >
                          <svg
                            aria-hidden="true"
                            class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                            focusable="false"
                            role="img"
                            viewBox="0 0 256 512"
                          >
                            <path
                              d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div
                      class="dd-menu-items"
                    />
                  </div>
                  <span
                    class="tw-float-right ruleBtns"
                  >
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-data-pattern"
                      id="addPattern1"
                      type="button"
                    >
                      Add Data Pattern
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-edm-dataset"
                      id="addDataset1"
                      type="button"
                    >
                      Add EDM Dataset
                    </button>
                    <button
                      class="addBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-bg-transparent tw-text-link dark:tw-text-blue-400 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 dark:hover:tw-bg-blue-700"
                      data-testid="button-add-group"
                      id="addGroup1"
                      type="button"
                    >
                      Add Group
                    </button>
                  </span>
                </div>
                <div
                  class="conditionGroup"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="formFooter"
      >
        <div
          class="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
        >
          <button
            class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
            data-testid="button-cancel"
            type="button"
          >
            Cancel
          </button>
          <button
            class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border-0 tw-group tw-text-dark-bg dark:tw-text-blue-100 tw-bg-blue-600 dark:tw-bg-blue-700 focus-visible:tw-bg-blue-700 dark:focus-visible:tw-bg-blue-600 active:tw-bg-blue-800 dark:active:tw-bg-blue-500  hover:tw-bg-blue-700 dark:hover:tw-bg-blue-600"
            data-testid="button-save"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;

exports[`<DataProfileFormV2> create form componentDidMount() matches snapshot 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={false}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <div
          id="nothingToPreview"
        >
          <img
            alt="Preview"
            className="text-center"
          />
          <p
            className="text-center"
          >
            <b>
              No Definition Yet
            </b>
            <br />
            <span
              className="helpText"
            >
              Add a rule to start
            </span>
          </p>
        </div>
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name=""
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a primary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="0"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="and"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          />
        </div>
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a secondary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormV2> create form renders bracket preview 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={false}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="bracket"
              updateState={[Function]}
            />
          </span>
        </h4>
        <DataProfileDefinition
          advanced={true}
          dataPatternsRules={Array []}
          datasets={
            Array [
              Object {
                "active_fields": Array [
                  Object {
                    "data_type_name": "Social Security Number",
                    "id": "col-1",
                    "name": "SSN",
                  },
                  Object {
                    "data_type_name": "Credit Card Number",
                    "id": "col-2",
                    "name": "CCN",
                  },
                  Object {
                    "data_type_name": "Subscriber ID",
                    "id": "col-3",
                    "name": "Subscriber ID",
                  },
                  Object {
                    "data_type_name": "Member ID",
                    "id": "col-4",
                    "name": "Member ID",
                  },
                ],
                "all_fields": Array [],
                "id": "1234564",
                "index_status": "success",
                "index_version": 1,
                "name": "Dataset A",
              },
              Object {
                "active_fields": Array [
                  Object {
                    "data_type_name": "Social Security Number",
                    "id": "col-1",
                    "name": "SSN",
                  },
                  Object {
                    "data_type_name": "Credit Card Number",
                    "id": "col-2",
                    "name": "CCN",
                  },
                  Object {
                    "data_type_name": "Bank Account Numbers",
                    "id": "col-3",
                    "name": "BankNum",
                  },
                ],
                "id": "1234565",
                "index_status": "success",
                "index_version": 1,
                "name": "Dataset B",
              },
            ]
          }
          multi={false}
          primaryExpTree={
            Object {
              "operator_type": "and",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  },
                  "sub_expressions": Array [],
                },
                Object {
                  "operator_type": "or",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": "or",
                      "rule_item": null,
                      "sub_expressions": Array [
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          },
                          "sub_expressions": Array [],
                        },
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          },
                          "sub_expressions": Array [],
                        },
                      ],
                    },
                  ],
                },
                Object {
                  "operator_type": "not",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": null,
                      "rule_item": Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234564",
                        "name": "Dataset A",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-3",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      },
                      "sub_expressions": Array [],
                    },
                  ],
                },
              ],
            }
          }
          primaryProfiles={Array []}
          schema={2}
          secondaryExpTree={
            Object {
              "operator_type": "or",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  },
                  "sub_expressions": Array [],
                },
                Object {
                  "operator_type": "and",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": "and",
                      "rule_item": null,
                      "sub_expressions": Array [
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          },
                          "sub_expressions": Array [],
                        },
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          },
                          "sub_expressions": Array [],
                        },
                      ],
                    },
                  ],
                },
                Object {
                  "operator_type": "and",
                  "rule_item": Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  },
                  "sub_expressions": Array [],
                },
              ],
            }
          }
          secondaryProfiles={Array []}
        />
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name="Test Profile"
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a primary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="0"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="and"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          >
            <div
              className="dp ruleItem"
              key="dp-undefined-0-0"
            >
              <DataPatternRuleItem
                dataPatterns={Object {}}
                deleteRuleItem={[Function]}
                dp={
                  Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  }
                }
                position="0-0"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
            <div
              className="nest"
              key="0-1"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="or"
                />
                <span
                  className="tw-float-right ruleBtns"
                  key="addBtns"
                >
                  <div
                    style={
                      Object {
                        "display": "inline-block",
                      }
                    }
                  >
                    <SingleSelect
                      button={
                        <Button
                          appearance="secondary"
                          append={true}
                          buttonType="default"
                          disabled={false}
                          isMenu={true}
                          size="md"
                        >
                          Add
                        </Button>
                      }
                      disabled={false}
                      enableSearch={false}
                      items={
                        Array [
                          Object {
                            "value": "Data Pattern",
                          },
                          Object {
                            "value": "Group",
                          },
                        ]
                      }
                      onChange={[Function]}
                      selectedItem={Array []}
                      style={Object {}}
                    />
                  </div>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="nest"
                  key="0-1-0"
                >
                  <div
                    className="btnGroup "
                  >
                    <BasicDropdown
                      disabled={false}
                      dropClass=""
                      error={false}
                      items={
                        Array [
                          Object {
                            "label": "AND",
                            "value": "and",
                          },
                          Object {
                            "label": "OR",
                            "value": "or",
                          },
                          Object {
                            "label": "NOT",
                            "value": "not",
                          },
                        ]
                      }
                      onSelect={[Function]}
                      placeholder="Select an Item"
                      value="or"
                    />
                    <span
                      className="tw-float-right ruleBtns"
                      key="addBtns"
                    >
                      <div
                        style={
                          Object {
                            "display": "inline-block",
                          }
                        }
                      >
                        <SingleSelect
                          button={
                            <Button
                              appearance="secondary"
                              append={true}
                              buttonType="default"
                              disabled={false}
                              isMenu={true}
                              size="md"
                            >
                              Add
                            </Button>
                          }
                          disabled={false}
                          enableSearch={false}
                          items={
                            Array [
                              Object {
                                "value": "Data Pattern",
                              },
                              Object {
                                "value": "Group",
                              },
                            ]
                          }
                          onChange={[Function]}
                          selectedItem={Array []}
                          style={Object {}}
                        />
                      </div>
                      <FaTimes
                        className="muteText removeBtn ml5"
                        onClick={[Function]}
                      />
                    </span>
                  </div>
                  <div
                    className="conditionGroup"
                  >
                    <div
                      className="dp ruleItem"
                      key="dp-undefined-0-1-0-0"
                    >
                      <DataPatternRuleItem
                        dataPatterns={Object {}}
                        deleteRuleItem={[Function]}
                        dp={
                          Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          }
                        }
                        position="0-1-0-0"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                    <div
                      className="edm ruleItem"
                      key="edm-Dataset B-0-1-0-1"
                    >
                      <EdmDatasetRuleItem
                        datasets={
                          Array [
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Subscriber ID",
                                  "id": "col-3",
                                  "name": "Subscriber ID",
                                },
                                Object {
                                  "data_type_name": "Member ID",
                                  "id": "col-4",
                                  "name": "Member ID",
                                },
                              ],
                              "all_fields": Array [],
                              "id": "1234564",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset A",
                            },
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Bank Account Numbers",
                                  "id": "col-3",
                                  "name": "BankNum",
                                },
                              ],
                              "id": "1234565",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset B",
                            },
                          ]
                        }
                        def={
                          Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          }
                        }
                        deleteRuleItem={[Function]}
                        duplicateDatasets={Array []}
                        position="0-1-0-1"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="nest"
              key="0-2"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="not"
                />
                <span
                  className="tw-float-right ruleBtns dlp-flex-center"
                  key="addBtns"
                >
                  <Tooltip
                    hasTriangle={true}
                    label="Only one item or group can be added beneath a not operator."
                  >
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={true}
                      isMenu={true}
                      isSplit={true}
                      size="md"
                    >
                      Add
                    </Button>
                  </Tooltip>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="edm ruleItem"
                  key="edm-Dataset A-0-2-0"
                >
                  <EdmDatasetRuleItem
                    datasets={
                      Array [
                        Object {
                          "active_fields": Array [
                            Object {
                              "data_type_name": "Social Security Number",
                              "id": "col-1",
                              "name": "SSN",
                            },
                            Object {
                              "data_type_name": "Credit Card Number",
                              "id": "col-2",
                              "name": "CCN",
                            },
                            Object {
                              "data_type_name": "Subscriber ID",
                              "id": "col-3",
                              "name": "Subscriber ID",
                            },
                            Object {
                              "data_type_name": "Member ID",
                              "id": "col-4",
                              "name": "Member ID",
                            },
                          ],
                          "all_fields": Array [],
                          "id": "1234564",
                          "index_status": "success",
                          "index_version": 1,
                          "name": "Dataset A",
                        },
                        Object {
                          "active_fields": Array [
                            Object {
                              "data_type_name": "Social Security Number",
                              "id": "col-1",
                              "name": "SSN",
                            },
                            Object {
                              "data_type_name": "Credit Card Number",
                              "id": "col-2",
                              "name": "CCN",
                            },
                            Object {
                              "data_type_name": "Bank Account Numbers",
                              "id": "col-3",
                              "name": "BankNum",
                            },
                          ],
                          "id": "1234565",
                          "index_status": "success",
                          "index_version": 1,
                          "name": "Dataset B",
                        },
                      ]
                    }
                    def={
                      Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234564",
                        "name": "Dataset A",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-3",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      }
                    }
                    deleteRuleItem={[Function]}
                    duplicateDatasets={Array []}
                    position="0-2-0"
                    updateRuleItem={[Function]}
                    validatePresence={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a secondary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="1"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="or"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          >
            <div
              className="dp ruleItem"
              key="dp-undefined-1-0"
            >
              <DataPatternRuleItem
                dataPatterns={Object {}}
                deleteRuleItem={[Function]}
                dp={
                  Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  }
                }
                position="1-0"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
            <div
              className="nest"
              key="1-1"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="and"
                />
                <span
                  className="tw-float-right ruleBtns"
                  key="addBtns"
                >
                  <div
                    style={
                      Object {
                        "display": "inline-block",
                      }
                    }
                  >
                    <SingleSelect
                      button={
                        <Button
                          appearance="secondary"
                          append={true}
                          buttonType="default"
                          disabled={false}
                          isMenu={true}
                          size="md"
                        >
                          Add
                        </Button>
                      }
                      disabled={false}
                      enableSearch={false}
                      items={
                        Array [
                          Object {
                            "value": "Data Pattern",
                          },
                          Object {
                            "value": "Group",
                          },
                        ]
                      }
                      onChange={[Function]}
                      selectedItem={Array []}
                      style={Object {}}
                    />
                  </div>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="nest"
                  key="1-1-0"
                >
                  <div
                    className="btnGroup "
                  >
                    <BasicDropdown
                      disabled={false}
                      dropClass=""
                      error={false}
                      items={
                        Array [
                          Object {
                            "label": "AND",
                            "value": "and",
                          },
                          Object {
                            "label": "OR",
                            "value": "or",
                          },
                          Object {
                            "label": "NOT",
                            "value": "not",
                          },
                        ]
                      }
                      onSelect={[Function]}
                      placeholder="Select an Item"
                      value="and"
                    />
                    <span
                      className="tw-float-right ruleBtns"
                      key="addBtns"
                    >
                      <div
                        style={
                          Object {
                            "display": "inline-block",
                          }
                        }
                      >
                        <SingleSelect
                          button={
                            <Button
                              appearance="secondary"
                              append={true}
                              buttonType="default"
                              disabled={false}
                              isMenu={true}
                              size="md"
                            >
                              Add
                            </Button>
                          }
                          disabled={false}
                          enableSearch={false}
                          items={
                            Array [
                              Object {
                                "value": "Data Pattern",
                              },
                              Object {
                                "value": "Group",
                              },
                            ]
                          }
                          onChange={[Function]}
                          selectedItem={Array []}
                          style={Object {}}
                        />
                      </div>
                      <FaTimes
                        className="muteText removeBtn ml5"
                        onClick={[Function]}
                      />
                    </span>
                  </div>
                  <div
                    className="conditionGroup"
                  >
                    <div
                      className="dp ruleItem"
                      key="dp-undefined-1-1-0-0"
                    >
                      <DataPatternRuleItem
                        dataPatterns={Object {}}
                        deleteRuleItem={[Function]}
                        dp={
                          Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          }
                        }
                        position="1-1-0-0"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                    <div
                      className="edm ruleItem"
                      key="edm-Dataset B-1-1-0-1"
                    >
                      <EdmDatasetRuleItem
                        datasets={
                          Array [
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Subscriber ID",
                                  "id": "col-3",
                                  "name": "Subscriber ID",
                                },
                                Object {
                                  "data_type_name": "Member ID",
                                  "id": "col-4",
                                  "name": "Member ID",
                                },
                              ],
                              "all_fields": Array [],
                              "id": "1234564",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset A",
                            },
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Bank Account Numbers",
                                  "id": "col-3",
                                  "name": "BankNum",
                                },
                              ],
                              "id": "1234565",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset B",
                            },
                          ]
                        }
                        def={
                          Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          }
                        }
                        deleteRuleItem={[Function]}
                        duplicateDatasets={Array []}
                        position="1-1-0-1"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="edm ruleItem"
              key="edm-Dataset A-1-2"
            >
              <EdmDatasetRuleItem
                datasets={
                  Array [
                    Object {
                      "active_fields": Array [
                        Object {
                          "data_type_name": "Social Security Number",
                          "id": "col-1",
                          "name": "SSN",
                        },
                        Object {
                          "data_type_name": "Credit Card Number",
                          "id": "col-2",
                          "name": "CCN",
                        },
                        Object {
                          "data_type_name": "Subscriber ID",
                          "id": "col-3",
                          "name": "Subscriber ID",
                        },
                        Object {
                          "data_type_name": "Member ID",
                          "id": "col-4",
                          "name": "Member ID",
                        },
                      ],
                      "all_fields": Array [],
                      "id": "1234564",
                      "index_status": "success",
                      "index_version": 1,
                      "name": "Dataset A",
                    },
                    Object {
                      "active_fields": Array [
                        Object {
                          "data_type_name": "Social Security Number",
                          "id": "col-1",
                          "name": "SSN",
                        },
                        Object {
                          "data_type_name": "Credit Card Number",
                          "id": "col-2",
                          "name": "CCN",
                        },
                        Object {
                          "data_type_name": "Bank Account Numbers",
                          "id": "col-3",
                          "name": "BankNum",
                        },
                      ],
                      "id": "1234565",
                      "index_status": "success",
                      "index_version": 1,
                      "name": "Dataset B",
                    },
                  ]
                }
                def={
                  Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  }
                }
                deleteRuleItem={[Function]}
                duplicateDatasets={Array []}
                position="1-2"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormV2> create form renders definition preview 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={false}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <DataProfileDetailPreview
          changedPrimaryExpTree={Object {}}
          changedPrimaryProfiles={Array []}
          changedSecondaryExpTree={Object {}}
          changedSecondaryProfiles={Array []}
          dataPatternRules={
            Array [
              Object {},
              Object {},
            ]
          }
          datasets={
            Array [
              Object {
                "active_fields": Array [
                  Object {
                    "data_type_name": "Social Security Number",
                    "id": "col-1",
                    "name": "SSN",
                  },
                  Object {
                    "data_type_name": "Credit Card Number",
                    "id": "col-2",
                    "name": "CCN",
                  },
                  Object {
                    "data_type_name": "Subscriber ID",
                    "id": "col-3",
                    "name": "Subscriber ID",
                  },
                  Object {
                    "data_type_name": "Member ID",
                    "id": "col-4",
                    "name": "Member ID",
                  },
                ],
                "all_fields": Array [],
                "id": "1234564",
                "index_status": "success",
                "index_version": 1,
                "name": "Dataset A",
              },
              Object {
                "active_fields": Array [
                  Object {
                    "data_type_name": "Social Security Number",
                    "id": "col-1",
                    "name": "SSN",
                  },
                  Object {
                    "data_type_name": "Credit Card Number",
                    "id": "col-2",
                    "name": "CCN",
                  },
                  Object {
                    "data_type_name": "Bank Account Numbers",
                    "id": "col-3",
                    "name": "BankNum",
                  },
                ],
                "id": "1234565",
                "index_status": "success",
                "index_version": 1,
                "name": "Dataset B",
              },
            ]
          }
          multi={false}
          primaryExpTree={
            Object {
              "operator_type": "and",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  },
                  "sub_expressions": Array [],
                },
                Object {
                  "operator_type": "or",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": "or",
                      "rule_item": null,
                      "sub_expressions": Array [
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          },
                          "sub_expressions": Array [],
                        },
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          },
                          "sub_expressions": Array [],
                        },
                      ],
                    },
                  ],
                },
                Object {
                  "operator_type": "not",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": null,
                      "rule_item": Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234564",
                        "name": "Dataset A",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-3",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      },
                      "sub_expressions": Array [],
                    },
                  ],
                },
              ],
            }
          }
          primaryProfiles={Array []}
          schema={2}
          secondaryExpTree={
            Object {
              "operator_type": "or",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  },
                  "sub_expressions": Array [],
                },
                Object {
                  "operator_type": "and",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": "and",
                      "rule_item": null,
                      "sub_expressions": Array [
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          },
                          "sub_expressions": Array [],
                        },
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          },
                          "sub_expressions": Array [],
                        },
                      ],
                    },
                  ],
                },
                Object {
                  "operator_type": "and",
                  "rule_item": Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  },
                  "sub_expressions": Array [],
                },
              ],
            }
          }
          secondaryProfiles={Array []}
        />
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name="Test Profile"
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a primary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="0"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="and"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          >
            <div
              className="dp ruleItem"
              key="dp-undefined-0-0"
            >
              <DataPatternRuleItem
                dataPatterns={Object {}}
                deleteRuleItem={[Function]}
                dp={
                  Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  }
                }
                position="0-0"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
            <div
              className="nest"
              key="0-1"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="or"
                />
                <span
                  className="tw-float-right ruleBtns"
                  key="addBtns"
                >
                  <div
                    style={
                      Object {
                        "display": "inline-block",
                      }
                    }
                  >
                    <SingleSelect
                      button={
                        <Button
                          appearance="secondary"
                          append={true}
                          buttonType="default"
                          disabled={false}
                          isMenu={true}
                          size="md"
                        >
                          Add
                        </Button>
                      }
                      disabled={false}
                      enableSearch={false}
                      items={
                        Array [
                          Object {
                            "value": "Data Pattern",
                          },
                          Object {
                            "value": "Group",
                          },
                        ]
                      }
                      onChange={[Function]}
                      selectedItem={Array []}
                      style={Object {}}
                    />
                  </div>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="nest"
                  key="0-1-0"
                >
                  <div
                    className="btnGroup "
                  >
                    <BasicDropdown
                      disabled={false}
                      dropClass=""
                      error={false}
                      items={
                        Array [
                          Object {
                            "label": "AND",
                            "value": "and",
                          },
                          Object {
                            "label": "OR",
                            "value": "or",
                          },
                          Object {
                            "label": "NOT",
                            "value": "not",
                          },
                        ]
                      }
                      onSelect={[Function]}
                      placeholder="Select an Item"
                      value="or"
                    />
                    <span
                      className="tw-float-right ruleBtns"
                      key="addBtns"
                    >
                      <div
                        style={
                          Object {
                            "display": "inline-block",
                          }
                        }
                      >
                        <SingleSelect
                          button={
                            <Button
                              appearance="secondary"
                              append={true}
                              buttonType="default"
                              disabled={false}
                              isMenu={true}
                              size="md"
                            >
                              Add
                            </Button>
                          }
                          disabled={false}
                          enableSearch={false}
                          items={
                            Array [
                              Object {
                                "value": "Data Pattern",
                              },
                              Object {
                                "value": "Group",
                              },
                            ]
                          }
                          onChange={[Function]}
                          selectedItem={Array []}
                          style={Object {}}
                        />
                      </div>
                      <FaTimes
                        className="muteText removeBtn ml5"
                        onClick={[Function]}
                      />
                    </span>
                  </div>
                  <div
                    className="conditionGroup"
                  >
                    <div
                      className="dp ruleItem"
                      key="dp-undefined-0-1-0-0"
                    >
                      <DataPatternRuleItem
                        dataPatterns={Object {}}
                        deleteRuleItem={[Function]}
                        dp={
                          Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          }
                        }
                        position="0-1-0-0"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                    <div
                      className="edm ruleItem"
                      key="edm-Dataset B-0-1-0-1"
                    >
                      <EdmDatasetRuleItem
                        datasets={
                          Array [
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Subscriber ID",
                                  "id": "col-3",
                                  "name": "Subscriber ID",
                                },
                                Object {
                                  "data_type_name": "Member ID",
                                  "id": "col-4",
                                  "name": "Member ID",
                                },
                              ],
                              "all_fields": Array [],
                              "id": "1234564",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset A",
                            },
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Bank Account Numbers",
                                  "id": "col-3",
                                  "name": "BankNum",
                                },
                              ],
                              "id": "1234565",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset B",
                            },
                          ]
                        }
                        def={
                          Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          }
                        }
                        deleteRuleItem={[Function]}
                        duplicateDatasets={Array []}
                        position="0-1-0-1"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="nest"
              key="0-2"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="not"
                />
                <span
                  className="tw-float-right ruleBtns dlp-flex-center"
                  key="addBtns"
                >
                  <Tooltip
                    hasTriangle={true}
                    label="Only one item or group can be added beneath a not operator."
                  >
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={true}
                      isMenu={true}
                      isSplit={true}
                      size="md"
                    >
                      Add
                    </Button>
                  </Tooltip>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="edm ruleItem"
                  key="edm-Dataset A-0-2-0"
                >
                  <EdmDatasetRuleItem
                    datasets={
                      Array [
                        Object {
                          "active_fields": Array [
                            Object {
                              "data_type_name": "Social Security Number",
                              "id": "col-1",
                              "name": "SSN",
                            },
                            Object {
                              "data_type_name": "Credit Card Number",
                              "id": "col-2",
                              "name": "CCN",
                            },
                            Object {
                              "data_type_name": "Subscriber ID",
                              "id": "col-3",
                              "name": "Subscriber ID",
                            },
                            Object {
                              "data_type_name": "Member ID",
                              "id": "col-4",
                              "name": "Member ID",
                            },
                          ],
                          "all_fields": Array [],
                          "id": "1234564",
                          "index_status": "success",
                          "index_version": 1,
                          "name": "Dataset A",
                        },
                        Object {
                          "active_fields": Array [
                            Object {
                              "data_type_name": "Social Security Number",
                              "id": "col-1",
                              "name": "SSN",
                            },
                            Object {
                              "data_type_name": "Credit Card Number",
                              "id": "col-2",
                              "name": "CCN",
                            },
                            Object {
                              "data_type_name": "Bank Account Numbers",
                              "id": "col-3",
                              "name": "BankNum",
                            },
                          ],
                          "id": "1234565",
                          "index_status": "success",
                          "index_version": 1,
                          "name": "Dataset B",
                        },
                      ]
                    }
                    def={
                      Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234564",
                        "name": "Dataset A",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-3",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      }
                    }
                    deleteRuleItem={[Function]}
                    duplicateDatasets={Array []}
                    position="0-2-0"
                    updateRuleItem={[Function]}
                    validatePresence={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a secondary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="1"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="or"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          >
            <div
              className="dp ruleItem"
              key="dp-undefined-1-0"
            >
              <DataPatternRuleItem
                dataPatterns={Object {}}
                deleteRuleItem={[Function]}
                dp={
                  Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  }
                }
                position="1-0"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
            <div
              className="nest"
              key="1-1"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="and"
                />
                <span
                  className="tw-float-right ruleBtns"
                  key="addBtns"
                >
                  <div
                    style={
                      Object {
                        "display": "inline-block",
                      }
                    }
                  >
                    <SingleSelect
                      button={
                        <Button
                          appearance="secondary"
                          append={true}
                          buttonType="default"
                          disabled={false}
                          isMenu={true}
                          size="md"
                        >
                          Add
                        </Button>
                      }
                      disabled={false}
                      enableSearch={false}
                      items={
                        Array [
                          Object {
                            "value": "Data Pattern",
                          },
                          Object {
                            "value": "Group",
                          },
                        ]
                      }
                      onChange={[Function]}
                      selectedItem={Array []}
                      style={Object {}}
                    />
                  </div>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="nest"
                  key="1-1-0"
                >
                  <div
                    className="btnGroup "
                  >
                    <BasicDropdown
                      disabled={false}
                      dropClass=""
                      error={false}
                      items={
                        Array [
                          Object {
                            "label": "AND",
                            "value": "and",
                          },
                          Object {
                            "label": "OR",
                            "value": "or",
                          },
                          Object {
                            "label": "NOT",
                            "value": "not",
                          },
                        ]
                      }
                      onSelect={[Function]}
                      placeholder="Select an Item"
                      value="and"
                    />
                    <span
                      className="tw-float-right ruleBtns"
                      key="addBtns"
                    >
                      <div
                        style={
                          Object {
                            "display": "inline-block",
                          }
                        }
                      >
                        <SingleSelect
                          button={
                            <Button
                              appearance="secondary"
                              append={true}
                              buttonType="default"
                              disabled={false}
                              isMenu={true}
                              size="md"
                            >
                              Add
                            </Button>
                          }
                          disabled={false}
                          enableSearch={false}
                          items={
                            Array [
                              Object {
                                "value": "Data Pattern",
                              },
                              Object {
                                "value": "Group",
                              },
                            ]
                          }
                          onChange={[Function]}
                          selectedItem={Array []}
                          style={Object {}}
                        />
                      </div>
                      <FaTimes
                        className="muteText removeBtn ml5"
                        onClick={[Function]}
                      />
                    </span>
                  </div>
                  <div
                    className="conditionGroup"
                  >
                    <div
                      className="dp ruleItem"
                      key="dp-undefined-1-1-0-0"
                    >
                      <DataPatternRuleItem
                        dataPatterns={Object {}}
                        deleteRuleItem={[Function]}
                        dp={
                          Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          }
                        }
                        position="1-1-0-0"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                    <div
                      className="edm ruleItem"
                      key="edm-Dataset B-1-1-0-1"
                    >
                      <EdmDatasetRuleItem
                        datasets={
                          Array [
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Subscriber ID",
                                  "id": "col-3",
                                  "name": "Subscriber ID",
                                },
                                Object {
                                  "data_type_name": "Member ID",
                                  "id": "col-4",
                                  "name": "Member ID",
                                },
                              ],
                              "all_fields": Array [],
                              "id": "1234564",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset A",
                            },
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Bank Account Numbers",
                                  "id": "col-3",
                                  "name": "BankNum",
                                },
                              ],
                              "id": "1234565",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset B",
                            },
                          ]
                        }
                        def={
                          Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          }
                        }
                        deleteRuleItem={[Function]}
                        duplicateDatasets={Array []}
                        position="1-1-0-1"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="edm ruleItem"
              key="edm-Dataset A-1-2"
            >
              <EdmDatasetRuleItem
                datasets={
                  Array [
                    Object {
                      "active_fields": Array [
                        Object {
                          "data_type_name": "Social Security Number",
                          "id": "col-1",
                          "name": "SSN",
                        },
                        Object {
                          "data_type_name": "Credit Card Number",
                          "id": "col-2",
                          "name": "CCN",
                        },
                        Object {
                          "data_type_name": "Subscriber ID",
                          "id": "col-3",
                          "name": "Subscriber ID",
                        },
                        Object {
                          "data_type_name": "Member ID",
                          "id": "col-4",
                          "name": "Member ID",
                        },
                      ],
                      "all_fields": Array [],
                      "id": "1234564",
                      "index_status": "success",
                      "index_version": 1,
                      "name": "Dataset A",
                    },
                    Object {
                      "active_fields": Array [
                        Object {
                          "data_type_name": "Social Security Number",
                          "id": "col-1",
                          "name": "SSN",
                        },
                        Object {
                          "data_type_name": "Credit Card Number",
                          "id": "col-2",
                          "name": "CCN",
                        },
                        Object {
                          "data_type_name": "Bank Account Numbers",
                          "id": "col-3",
                          "name": "BankNum",
                        },
                      ],
                      "id": "1234565",
                      "index_status": "success",
                      "index_version": 1,
                      "name": "Dataset B",
                    },
                  ]
                }
                def={
                  Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  }
                }
                deleteRuleItem={[Function]}
                duplicateDatasets={Array []}
                position="1-2"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormV2> update form componentDidMount() matches snapshot 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={true}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <DataProfileDetailPreview
          changedPrimaryExpTree={Object {}}
          changedPrimaryProfiles={Array []}
          changedSecondaryExpTree={Object {}}
          changedSecondaryProfiles={Array []}
          dataPatternRules={
            Array [
              Object {},
              Object {},
            ]
          }
          datasets={
            Array [
              Object {
                "active_fields": Array [
                  Object {
                    "data_type_name": "Social Security Number",
                    "id": "col-1",
                    "name": "SSN",
                  },
                  Object {
                    "data_type_name": "Credit Card Number",
                    "id": "col-2",
                    "name": "CCN",
                  },
                  Object {
                    "data_type_name": "Subscriber ID",
                    "id": "col-3",
                    "name": "Subscriber ID",
                  },
                  Object {
                    "data_type_name": "Member ID",
                    "id": "col-4",
                    "name": "Member ID",
                  },
                ],
                "all_fields": Array [],
                "id": "1234564",
                "index_status": "success",
                "index_version": 1,
                "name": "Dataset A",
              },
              Object {
                "active_fields": Array [
                  Object {
                    "data_type_name": "Social Security Number",
                    "id": "col-1",
                    "name": "SSN",
                  },
                  Object {
                    "data_type_name": "Credit Card Number",
                    "id": "col-2",
                    "name": "CCN",
                  },
                  Object {
                    "data_type_name": "Bank Account Numbers",
                    "id": "col-3",
                    "name": "BankNum",
                  },
                ],
                "id": "1234565",
                "index_status": "success",
                "index_version": 1,
                "name": "Dataset B",
              },
            ]
          }
          multi={false}
          primaryExpTree={
            Object {
              "operator_type": "and",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  },
                  "sub_expressions": Array [],
                },
                Object {
                  "operator_type": "or",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": "or",
                      "rule_item": null,
                      "sub_expressions": Array [
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          },
                          "sub_expressions": Array [],
                        },
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          },
                          "sub_expressions": Array [],
                        },
                      ],
                    },
                  ],
                },
                Object {
                  "operator_type": "not",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": null,
                      "rule_item": Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234564",
                        "name": "Dataset A",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-3",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      },
                      "sub_expressions": Array [],
                    },
                  ],
                },
              ],
            }
          }
          primaryProfiles={Array []}
          schema={2}
          secondaryExpTree={
            Object {
              "operator_type": "or",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  },
                  "sub_expressions": Array [],
                },
                Object {
                  "operator_type": "and",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": "and",
                      "rule_item": null,
                      "sub_expressions": Array [
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          },
                          "sub_expressions": Array [],
                        },
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          },
                          "sub_expressions": Array [],
                        },
                      ],
                    },
                  ],
                },
                Object {
                  "operator_type": "and",
                  "rule_item": Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  },
                  "sub_expressions": Array [],
                },
              ],
            }
          }
          secondaryProfiles={Array []}
        />
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name="existing profile"
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a primary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="0"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="and"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          >
            <div
              className="dp ruleItem"
              key="dp-undefined-0-0"
            >
              <DataPatternRuleItem
                dataPatterns={Object {}}
                deleteRuleItem={[Function]}
                dp={
                  Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  }
                }
                position="0-0"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
            <div
              className="nest"
              key="0-1"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="or"
                />
                <span
                  className="tw-float-right ruleBtns"
                  key="addBtns"
                >
                  <div
                    style={
                      Object {
                        "display": "inline-block",
                      }
                    }
                  >
                    <SingleSelect
                      button={
                        <Button
                          appearance="secondary"
                          append={true}
                          buttonType="default"
                          disabled={false}
                          isMenu={true}
                          size="md"
                        >
                          Add
                        </Button>
                      }
                      disabled={false}
                      enableSearch={false}
                      items={
                        Array [
                          Object {
                            "value": "Data Pattern",
                          },
                          Object {
                            "value": "Group",
                          },
                        ]
                      }
                      onChange={[Function]}
                      selectedItem={Array []}
                      style={Object {}}
                    />
                  </div>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="nest"
                  key="0-1-0"
                >
                  <div
                    className="btnGroup "
                  >
                    <BasicDropdown
                      disabled={false}
                      dropClass=""
                      error={false}
                      items={
                        Array [
                          Object {
                            "label": "AND",
                            "value": "and",
                          },
                          Object {
                            "label": "OR",
                            "value": "or",
                          },
                          Object {
                            "label": "NOT",
                            "value": "not",
                          },
                        ]
                      }
                      onSelect={[Function]}
                      placeholder="Select an Item"
                      value="or"
                    />
                    <span
                      className="tw-float-right ruleBtns"
                      key="addBtns"
                    >
                      <div
                        style={
                          Object {
                            "display": "inline-block",
                          }
                        }
                      >
                        <SingleSelect
                          button={
                            <Button
                              appearance="secondary"
                              append={true}
                              buttonType="default"
                              disabled={false}
                              isMenu={true}
                              size="md"
                            >
                              Add
                            </Button>
                          }
                          disabled={false}
                          enableSearch={false}
                          items={
                            Array [
                              Object {
                                "value": "Data Pattern",
                              },
                              Object {
                                "value": "Group",
                              },
                            ]
                          }
                          onChange={[Function]}
                          selectedItem={Array []}
                          style={Object {}}
                        />
                      </div>
                      <FaTimes
                        className="muteText removeBtn ml5"
                        onClick={[Function]}
                      />
                    </span>
                  </div>
                  <div
                    className="conditionGroup"
                  >
                    <div
                      className="dp ruleItem"
                      key="dp-undefined-0-1-0-0"
                    >
                      <DataPatternRuleItem
                        dataPatterns={Object {}}
                        deleteRuleItem={[Function]}
                        dp={
                          Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          }
                        }
                        position="0-1-0-0"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                    <div
                      className="edm ruleItem"
                      key="edm-Dataset B-0-1-0-1"
                    >
                      <EdmDatasetRuleItem
                        datasets={
                          Array [
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Subscriber ID",
                                  "id": "col-3",
                                  "name": "Subscriber ID",
                                },
                                Object {
                                  "data_type_name": "Member ID",
                                  "id": "col-4",
                                  "name": "Member ID",
                                },
                              ],
                              "all_fields": Array [],
                              "id": "1234564",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset A",
                            },
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Bank Account Numbers",
                                  "id": "col-3",
                                  "name": "BankNum",
                                },
                              ],
                              "id": "1234565",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset B",
                            },
                          ]
                        }
                        def={
                          Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          }
                        }
                        deleteRuleItem={[Function]}
                        duplicateDatasets={Array []}
                        position="0-1-0-1"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="nest"
              key="0-2"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="not"
                />
                <span
                  className="tw-float-right ruleBtns dlp-flex-center"
                  key="addBtns"
                >
                  <Tooltip
                    hasTriangle={true}
                    label="Only one item or group can be added beneath a not operator."
                  >
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={true}
                      isMenu={true}
                      isSplit={true}
                      size="md"
                    >
                      Add
                    </Button>
                  </Tooltip>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="edm ruleItem"
                  key="edm-Dataset A-0-2-0"
                >
                  <EdmDatasetRuleItem
                    datasets={
                      Array [
                        Object {
                          "active_fields": Array [
                            Object {
                              "data_type_name": "Social Security Number",
                              "id": "col-1",
                              "name": "SSN",
                            },
                            Object {
                              "data_type_name": "Credit Card Number",
                              "id": "col-2",
                              "name": "CCN",
                            },
                            Object {
                              "data_type_name": "Subscriber ID",
                              "id": "col-3",
                              "name": "Subscriber ID",
                            },
                            Object {
                              "data_type_name": "Member ID",
                              "id": "col-4",
                              "name": "Member ID",
                            },
                          ],
                          "all_fields": Array [],
                          "id": "1234564",
                          "index_status": "success",
                          "index_version": 1,
                          "name": "Dataset A",
                        },
                        Object {
                          "active_fields": Array [
                            Object {
                              "data_type_name": "Social Security Number",
                              "id": "col-1",
                              "name": "SSN",
                            },
                            Object {
                              "data_type_name": "Credit Card Number",
                              "id": "col-2",
                              "name": "CCN",
                            },
                            Object {
                              "data_type_name": "Bank Account Numbers",
                              "id": "col-3",
                              "name": "BankNum",
                            },
                          ],
                          "id": "1234565",
                          "index_status": "success",
                          "index_version": 1,
                          "name": "Dataset B",
                        },
                      ]
                    }
                    def={
                      Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234564",
                        "name": "Dataset A",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-3",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      }
                    }
                    deleteRuleItem={[Function]}
                    duplicateDatasets={Array []}
                    position="0-2-0"
                    updateRuleItem={[Function]}
                    validatePresence={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a secondary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="1"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="or"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          >
            <div
              className="dp ruleItem"
              key="dp-undefined-1-0"
            >
              <DataPatternRuleItem
                dataPatterns={Object {}}
                deleteRuleItem={[Function]}
                dp={
                  Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  }
                }
                position="1-0"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
            <div
              className="nest"
              key="1-1"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="and"
                />
                <span
                  className="tw-float-right ruleBtns"
                  key="addBtns"
                >
                  <div
                    style={
                      Object {
                        "display": "inline-block",
                      }
                    }
                  >
                    <SingleSelect
                      button={
                        <Button
                          appearance="secondary"
                          append={true}
                          buttonType="default"
                          disabled={false}
                          isMenu={true}
                          size="md"
                        >
                          Add
                        </Button>
                      }
                      disabled={false}
                      enableSearch={false}
                      items={
                        Array [
                          Object {
                            "value": "Data Pattern",
                          },
                          Object {
                            "value": "Group",
                          },
                        ]
                      }
                      onChange={[Function]}
                      selectedItem={Array []}
                      style={Object {}}
                    />
                  </div>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="nest"
                  key="1-1-0"
                >
                  <div
                    className="btnGroup "
                  >
                    <BasicDropdown
                      disabled={false}
                      dropClass=""
                      error={false}
                      items={
                        Array [
                          Object {
                            "label": "AND",
                            "value": "and",
                          },
                          Object {
                            "label": "OR",
                            "value": "or",
                          },
                          Object {
                            "label": "NOT",
                            "value": "not",
                          },
                        ]
                      }
                      onSelect={[Function]}
                      placeholder="Select an Item"
                      value="and"
                    />
                    <span
                      className="tw-float-right ruleBtns"
                      key="addBtns"
                    >
                      <div
                        style={
                          Object {
                            "display": "inline-block",
                          }
                        }
                      >
                        <SingleSelect
                          button={
                            <Button
                              appearance="secondary"
                              append={true}
                              buttonType="default"
                              disabled={false}
                              isMenu={true}
                              size="md"
                            >
                              Add
                            </Button>
                          }
                          disabled={false}
                          enableSearch={false}
                          items={
                            Array [
                              Object {
                                "value": "Data Pattern",
                              },
                              Object {
                                "value": "Group",
                              },
                            ]
                          }
                          onChange={[Function]}
                          selectedItem={Array []}
                          style={Object {}}
                        />
                      </div>
                      <FaTimes
                        className="muteText removeBtn ml5"
                        onClick={[Function]}
                      />
                    </span>
                  </div>
                  <div
                    className="conditionGroup"
                  >
                    <div
                      className="dp ruleItem"
                      key="dp-undefined-1-1-0-0"
                    >
                      <DataPatternRuleItem
                        dataPatterns={Object {}}
                        deleteRuleItem={[Function]}
                        dp={
                          Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          }
                        }
                        position="1-1-0-0"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                    <div
                      className="edm ruleItem"
                      key="edm-Dataset B-1-1-0-1"
                    >
                      <EdmDatasetRuleItem
                        datasets={
                          Array [
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Subscriber ID",
                                  "id": "col-3",
                                  "name": "Subscriber ID",
                                },
                                Object {
                                  "data_type_name": "Member ID",
                                  "id": "col-4",
                                  "name": "Member ID",
                                },
                              ],
                              "all_fields": Array [],
                              "id": "1234564",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset A",
                            },
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Bank Account Numbers",
                                  "id": "col-3",
                                  "name": "BankNum",
                                },
                              ],
                              "id": "1234565",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset B",
                            },
                          ]
                        }
                        def={
                          Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          }
                        }
                        deleteRuleItem={[Function]}
                        duplicateDatasets={Array []}
                        position="1-1-0-1"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="edm ruleItem"
              key="edm-Dataset A-1-2"
            >
              <EdmDatasetRuleItem
                datasets={
                  Array [
                    Object {
                      "active_fields": Array [
                        Object {
                          "data_type_name": "Social Security Number",
                          "id": "col-1",
                          "name": "SSN",
                        },
                        Object {
                          "data_type_name": "Credit Card Number",
                          "id": "col-2",
                          "name": "CCN",
                        },
                        Object {
                          "data_type_name": "Subscriber ID",
                          "id": "col-3",
                          "name": "Subscriber ID",
                        },
                        Object {
                          "data_type_name": "Member ID",
                          "id": "col-4",
                          "name": "Member ID",
                        },
                      ],
                      "all_fields": Array [],
                      "id": "1234564",
                      "index_status": "success",
                      "index_version": 1,
                      "name": "Dataset A",
                    },
                    Object {
                      "active_fields": Array [
                        Object {
                          "data_type_name": "Social Security Number",
                          "id": "col-1",
                          "name": "SSN",
                        },
                        Object {
                          "data_type_name": "Credit Card Number",
                          "id": "col-2",
                          "name": "CCN",
                        },
                        Object {
                          "data_type_name": "Bank Account Numbers",
                          "id": "col-3",
                          "name": "BankNum",
                        },
                      ],
                      "id": "1234565",
                      "index_status": "success",
                      "index_version": 1,
                      "name": "Dataset B",
                    },
                  ]
                }
                def={
                  Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  }
                }
                deleteRuleItem={[Function]}
                duplicateDatasets={Array []}
                position="1-2"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormV2> update form not secondary rule eligible componentDidMount() matches snapshot 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={true}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <DataProfileDetailPreview
          changedPrimaryExpTree={Object {}}
          changedPrimaryProfiles={Array []}
          changedSecondaryExpTree={Object {}}
          changedSecondaryProfiles={Array []}
          dataPatternRules={
            Array [
              Object {},
              Object {},
            ]
          }
          datasets={
            Array [
              Object {
                "active_fields": Array [
                  Object {
                    "data_type_name": "Social Security Number",
                    "id": "col-1",
                    "name": "SSN",
                  },
                  Object {
                    "data_type_name": "Credit Card Number",
                    "id": "col-2",
                    "name": "CCN",
                  },
                  Object {
                    "data_type_name": "Subscriber ID",
                    "id": "col-3",
                    "name": "Subscriber ID",
                  },
                  Object {
                    "data_type_name": "Member ID",
                    "id": "col-4",
                    "name": "Member ID",
                  },
                ],
                "all_fields": Array [],
                "id": "1234564",
                "index_status": "success",
                "index_version": 1,
                "name": "Dataset A",
              },
              Object {
                "active_fields": Array [
                  Object {
                    "data_type_name": "Social Security Number",
                    "id": "col-1",
                    "name": "SSN",
                  },
                  Object {
                    "data_type_name": "Credit Card Number",
                    "id": "col-2",
                    "name": "CCN",
                  },
                  Object {
                    "data_type_name": "Bank Account Numbers",
                    "id": "col-3",
                    "name": "BankNum",
                  },
                ],
                "id": "1234565",
                "index_status": "success",
                "index_version": 1,
                "name": "Dataset B",
              },
            ]
          }
          multi={false}
          primaryExpTree={
            Object {
              "operator_type": "and",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "confidence_level": "low",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  },
                  "sub_expressions": Array [],
                },
                Object {
                  "operator_type": "or",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": "or",
                      "rule_item": null,
                      "sub_expressions": Array [
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          },
                          "sub_expressions": Array [],
                        },
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          },
                          "sub_expressions": Array [],
                        },
                      ],
                    },
                  ],
                },
                Object {
                  "operator_type": "not",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": null,
                      "rule_item": Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234564",
                        "name": "Dataset A",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-3",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      },
                      "sub_expressions": Array [],
                    },
                  ],
                },
              ],
            }
          }
          primaryProfiles={Array []}
          schema={2}
          secondaryExpTree={
            Object {
              "operator_type": "or",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  },
                  "sub_expressions": Array [],
                },
                Object {
                  "operator_type": "and",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": "and",
                      "rule_item": null,
                      "sub_expressions": Array [
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          },
                          "sub_expressions": Array [],
                        },
                        Object {
                          "operator_type": null,
                          "rule_item": Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          },
                          "sub_expressions": Array [],
                        },
                      ],
                    },
                  ],
                },
                Object {
                  "operator_type": "and",
                  "rule_item": Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  },
                  "sub_expressions": Array [],
                },
              ],
            }
          }
          secondaryProfiles={Array []}
        />
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name="existing profile"
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a primary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="0"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="and"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          >
            <div
              className="dp ruleItem"
              key="dp-undefined-0-0"
            >
              <DataPatternRuleItem
                dataPatterns={Object {}}
                deleteRuleItem={[Function]}
                dp={
                  Object {
                    "confidence_level": "low",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  }
                }
                position="0-0"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
            <div
              className="nest"
              key="0-1"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="or"
                />
                <span
                  className="tw-float-right ruleBtns"
                  key="addBtns"
                >
                  <div
                    style={
                      Object {
                        "display": "inline-block",
                      }
                    }
                  >
                    <SingleSelect
                      button={
                        <Button
                          appearance="secondary"
                          append={true}
                          buttonType="default"
                          disabled={false}
                          isMenu={true}
                          size="md"
                        >
                          Add
                        </Button>
                      }
                      disabled={false}
                      enableSearch={false}
                      items={
                        Array [
                          Object {
                            "value": "Data Pattern",
                          },
                          Object {
                            "value": "Group",
                          },
                        ]
                      }
                      onChange={[Function]}
                      selectedItem={Array []}
                      style={Object {}}
                    />
                  </div>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="nest"
                  key="0-1-0"
                >
                  <div
                    className="btnGroup "
                  >
                    <BasicDropdown
                      disabled={false}
                      dropClass=""
                      error={false}
                      items={
                        Array [
                          Object {
                            "label": "AND",
                            "value": "and",
                          },
                          Object {
                            "label": "OR",
                            "value": "or",
                          },
                          Object {
                            "label": "NOT",
                            "value": "not",
                          },
                        ]
                      }
                      onSelect={[Function]}
                      placeholder="Select an Item"
                      value="or"
                    />
                    <span
                      className="tw-float-right ruleBtns"
                      key="addBtns"
                    >
                      <div
                        style={
                          Object {
                            "display": "inline-block",
                          }
                        }
                      >
                        <SingleSelect
                          button={
                            <Button
                              appearance="secondary"
                              append={true}
                              buttonType="default"
                              disabled={false}
                              isMenu={true}
                              size="md"
                            >
                              Add
                            </Button>
                          }
                          disabled={false}
                          enableSearch={false}
                          items={
                            Array [
                              Object {
                                "value": "Data Pattern",
                              },
                              Object {
                                "value": "Group",
                              },
                            ]
                          }
                          onChange={[Function]}
                          selectedItem={Array []}
                          style={Object {}}
                        />
                      </div>
                      <FaTimes
                        className="muteText removeBtn ml5"
                        onClick={[Function]}
                      />
                    </span>
                  </div>
                  <div
                    className="conditionGroup"
                  >
                    <div
                      className="dp ruleItem"
                      key="dp-undefined-0-1-0-0"
                    >
                      <DataPatternRuleItem
                        dataPatterns={Object {}}
                        deleteRuleItem={[Function]}
                        dp={
                          Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          }
                        }
                        position="0-1-0-0"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                    <div
                      className="edm ruleItem"
                      key="edm-Dataset B-0-1-0-1"
                    >
                      <EdmDatasetRuleItem
                        datasets={
                          Array [
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Subscriber ID",
                                  "id": "col-3",
                                  "name": "Subscriber ID",
                                },
                                Object {
                                  "data_type_name": "Member ID",
                                  "id": "col-4",
                                  "name": "Member ID",
                                },
                              ],
                              "all_fields": Array [],
                              "id": "1234564",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset A",
                            },
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Bank Account Numbers",
                                  "id": "col-3",
                                  "name": "BankNum",
                                },
                              ],
                              "id": "1234565",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset B",
                            },
                          ]
                        }
                        def={
                          Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          }
                        }
                        deleteRuleItem={[Function]}
                        duplicateDatasets={Array []}
                        position="0-1-0-1"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="nest"
              key="0-2"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="not"
                />
                <span
                  className="tw-float-right ruleBtns dlp-flex-center"
                  key="addBtns"
                >
                  <Tooltip
                    hasTriangle={true}
                    label="Only one item or group can be added beneath a not operator."
                  >
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={true}
                      isMenu={true}
                      isSplit={true}
                      size="md"
                    >
                      Add
                    </Button>
                  </Tooltip>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="edm ruleItem"
                  key="edm-Dataset A-0-2-0"
                >
                  <EdmDatasetRuleItem
                    datasets={
                      Array [
                        Object {
                          "active_fields": Array [
                            Object {
                              "data_type_name": "Social Security Number",
                              "id": "col-1",
                              "name": "SSN",
                            },
                            Object {
                              "data_type_name": "Credit Card Number",
                              "id": "col-2",
                              "name": "CCN",
                            },
                            Object {
                              "data_type_name": "Subscriber ID",
                              "id": "col-3",
                              "name": "Subscriber ID",
                            },
                            Object {
                              "data_type_name": "Member ID",
                              "id": "col-4",
                              "name": "Member ID",
                            },
                          ],
                          "all_fields": Array [],
                          "id": "1234564",
                          "index_status": "success",
                          "index_version": 1,
                          "name": "Dataset A",
                        },
                        Object {
                          "active_fields": Array [
                            Object {
                              "data_type_name": "Social Security Number",
                              "id": "col-1",
                              "name": "SSN",
                            },
                            Object {
                              "data_type_name": "Credit Card Number",
                              "id": "col-2",
                              "name": "CCN",
                            },
                            Object {
                              "data_type_name": "Bank Account Numbers",
                              "id": "col-3",
                              "name": "BankNum",
                            },
                          ],
                          "id": "1234565",
                          "index_status": "success",
                          "index_version": 1,
                          "name": "Dataset B",
                        },
                      ]
                    }
                    def={
                      Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234564",
                        "name": "Dataset A",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-3",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      }
                    }
                    deleteRuleItem={[Function]}
                    duplicateDatasets={Array []}
                    position="0-2-0"
                    updateRuleItem={[Function]}
                    validatePresence={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Create a secondary rule for the data profile containing multiple data profiles.
        </span>
        <hr />
        <div
          className="headGroup"
          key="1"
        >
          <div
            className="btnGroup "
          >
            <BasicDropdown
              disabled={false}
              dropClass=""
              error={false}
              items={
                Array [
                  Object {
                    "label": "AND",
                    "value": "and",
                  },
                  Object {
                    "label": "OR",
                    "value": "or",
                  },
                ]
              }
              onSelect={[Function]}
              placeholder="Select an Item"
              value="or"
            />
            <span
              className="tw-float-right ruleBtns"
              key="addBtns"
            >
              <div
                style={
                  Object {
                    "display": "inline-block",
                  }
                }
              >
                <SingleSelect
                  button={
                    <Button
                      appearance="secondary"
                      append={true}
                      buttonType="default"
                      disabled={false}
                      isMenu={true}
                      size="md"
                    >
                      Add
                    </Button>
                  }
                  disabled={false}
                  enableSearch={false}
                  items={
                    Array [
                      Object {
                        "value": "Data Pattern",
                      },
                      Object {
                        "value": "Group",
                      },
                    ]
                  }
                  onChange={[Function]}
                  selectedItem={Array []}
                  style={Object {}}
                />
              </div>
            </span>
          </div>
          <div
            className="conditionGroup"
          >
            <div
              className="dp ruleItem"
              key="dp-undefined-1-0"
            >
              <DataPatternRuleItem
                dataPatterns={Object {}}
                deleteRuleItem={[Function]}
                dp={
                  Object {
                    "confidence_level": "high",
                    "detection_technique": "regex",
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_count": null,
                    "occurrence_operator_type": "any",
                    "supported_confidence_levels": Array [
                      "high",
                      "low",
                    ],
                    "version": 1,
                  }
                }
                position="1-0"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
            <div
              className="nest"
              key="1-1"
            >
              <div
                className="btnGroup "
              >
                <BasicDropdown
                  disabled={false}
                  dropClass=""
                  error={false}
                  items={
                    Array [
                      Object {
                        "label": "AND",
                        "value": "and",
                      },
                      Object {
                        "label": "OR",
                        "value": "or",
                      },
                      Object {
                        "label": "NOT",
                        "value": "not",
                      },
                    ]
                  }
                  onSelect={[Function]}
                  placeholder="Select an Item"
                  value="and"
                />
                <span
                  className="tw-float-right ruleBtns"
                  key="addBtns"
                >
                  <div
                    style={
                      Object {
                        "display": "inline-block",
                      }
                    }
                  >
                    <SingleSelect
                      button={
                        <Button
                          appearance="secondary"
                          append={true}
                          buttonType="default"
                          disabled={false}
                          isMenu={true}
                          size="md"
                        >
                          Add
                        </Button>
                      }
                      disabled={false}
                      enableSearch={false}
                      items={
                        Array [
                          Object {
                            "value": "Data Pattern",
                          },
                          Object {
                            "value": "Group",
                          },
                        ]
                      }
                      onChange={[Function]}
                      selectedItem={Array []}
                      style={Object {}}
                    />
                  </div>
                  <FaTimes
                    className="muteText removeBtn ml5"
                    onClick={[Function]}
                  />
                </span>
              </div>
              <div
                className="conditionGroup"
              >
                <div
                  className="nest"
                  key="1-1-0"
                >
                  <div
                    className="btnGroup "
                  >
                    <BasicDropdown
                      disabled={false}
                      dropClass=""
                      error={false}
                      items={
                        Array [
                          Object {
                            "label": "AND",
                            "value": "and",
                          },
                          Object {
                            "label": "OR",
                            "value": "or",
                          },
                          Object {
                            "label": "NOT",
                            "value": "not",
                          },
                        ]
                      }
                      onSelect={[Function]}
                      placeholder="Select an Item"
                      value="and"
                    />
                    <span
                      className="tw-float-right ruleBtns"
                      key="addBtns"
                    >
                      <div
                        style={
                          Object {
                            "display": "inline-block",
                          }
                        }
                      >
                        <SingleSelect
                          button={
                            <Button
                              appearance="secondary"
                              append={true}
                              buttonType="default"
                              disabled={false}
                              isMenu={true}
                              size="md"
                            >
                              Add
                            </Button>
                          }
                          disabled={false}
                          enableSearch={false}
                          items={
                            Array [
                              Object {
                                "value": "Data Pattern",
                              },
                              Object {
                                "value": "Group",
                              },
                            ]
                          }
                          onChange={[Function]}
                          selectedItem={Array []}
                          style={Object {}}
                        />
                      </div>
                      <FaTimes
                        className="muteText removeBtn ml5"
                        onClick={[Function]}
                      />
                    </span>
                  </div>
                  <div
                    className="conditionGroup"
                  >
                    <div
                      className="dp ruleItem"
                      key="dp-undefined-1-1-0-0"
                    >
                      <DataPatternRuleItem
                        dataPatterns={Object {}}
                        deleteRuleItem={[Function]}
                        dp={
                          Object {
                            "confidence_level": "high",
                            "detection_technique": "regex",
                            "id": "1234561",
                            "name": "CCN",
                            "occurrence_count": null,
                            "occurrence_operator_type": "any",
                            "supported_confidence_levels": Array [
                              "high",
                              "low",
                            ],
                            "version": 1,
                          }
                        }
                        position="1-1-0-0"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                    <div
                      className="edm ruleItem"
                      key="edm-Dataset B-1-1-0-1"
                    >
                      <EdmDatasetRuleItem
                        datasets={
                          Array [
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Subscriber ID",
                                  "id": "col-3",
                                  "name": "Subscriber ID",
                                },
                                Object {
                                  "data_type_name": "Member ID",
                                  "id": "col-4",
                                  "name": "Member ID",
                                },
                              ],
                              "all_fields": Array [],
                              "id": "1234564",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset A",
                            },
                            Object {
                              "active_fields": Array [
                                Object {
                                  "data_type_name": "Social Security Number",
                                  "id": "col-1",
                                  "name": "SSN",
                                },
                                Object {
                                  "data_type_name": "Credit Card Number",
                                  "id": "col-2",
                                  "name": "CCN",
                                },
                                Object {
                                  "data_type_name": "Bank Account Numbers",
                                  "id": "col-3",
                                  "name": "BankNum",
                                },
                              ],
                              "id": "1234565",
                              "index_status": "success",
                              "index_version": 1,
                              "name": "Dataset B",
                            },
                          ]
                        }
                        def={
                          Object {
                            "detection_technique": "edm",
                            "edm_dataset_id": "1234565",
                            "name": "Dataset B",
                            "occurrence_count": null,
                            "occurrence_high": null,
                            "occurrence_low": null,
                            "occurrence_operator_type": "any",
                            "primary_fields": Array [
                              "col-1",
                              "col-2",
                            ],
                            "primary_match_any_count": null,
                            "primary_match_criteria": "all",
                            "secondary_fields": Array [],
                            "secondary_match_any_count": null,
                            "secondary_match_criteria": "all",
                          }
                        }
                        deleteRuleItem={[Function]}
                        duplicateDatasets={Array []}
                        position="1-1-0-1"
                        updateRuleItem={[Function]}
                        validatePresence={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="edm ruleItem"
              key="edm-Dataset A-1-2"
            >
              <EdmDatasetRuleItem
                datasets={
                  Array [
                    Object {
                      "active_fields": Array [
                        Object {
                          "data_type_name": "Social Security Number",
                          "id": "col-1",
                          "name": "SSN",
                        },
                        Object {
                          "data_type_name": "Credit Card Number",
                          "id": "col-2",
                          "name": "CCN",
                        },
                        Object {
                          "data_type_name": "Subscriber ID",
                          "id": "col-3",
                          "name": "Subscriber ID",
                        },
                        Object {
                          "data_type_name": "Member ID",
                          "id": "col-4",
                          "name": "Member ID",
                        },
                      ],
                      "all_fields": Array [],
                      "id": "1234564",
                      "index_status": "success",
                      "index_version": 1,
                      "name": "Dataset A",
                    },
                    Object {
                      "active_fields": Array [
                        Object {
                          "data_type_name": "Social Security Number",
                          "id": "col-1",
                          "name": "SSN",
                        },
                        Object {
                          "data_type_name": "Credit Card Number",
                          "id": "col-2",
                          "name": "CCN",
                        },
                        Object {
                          "data_type_name": "Bank Account Numbers",
                          "id": "col-3",
                          "name": "BankNum",
                        },
                      ],
                      "id": "1234565",
                      "index_status": "success",
                      "index_version": 1,
                      "name": "Dataset B",
                    },
                  ]
                }
                def={
                  Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  }
                }
                deleteRuleItem={[Function]}
                duplicateDatasets={Array []}
                position="1-2"
                updateRuleItem={[Function]}
                validatePresence={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;
