import React from 'react';
import PropTypes from 'prop-types';
import dateTranslator from '../../../helpers/dateTranslator';
import translate from '../../../helpers/translate';
import NormalIcon from '../../../../assets/images/normal.svg';
import DegradedIcon from '../../../../assets/images/degraded.svg';
import ErrorIcon from '../../../../assets/images/error.svg';

const moment = require('moment-timezone');

function imageIcon(status) {
  if (status === 'operational') {
    return NormalIcon;
  }
  if (status === 'degraded-performance') {
    return DegradedIcon;
  }
  return ErrorIcon;
}

export default function Item({ name, status, lastUpdatedAt }) {
  const t = (scope, options) => translate(`dashboards.index.${scope}`, options);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div className="telemetryItem">
      <div className="name">{name}</div>
      <img src={imageIcon(status)} alt={t(status)} />
      <div className="status">{t(status)}</div>
      <div className="label">{t('last_updated')}</div>
      <div className="value">{moment(dateTranslator(lastUpdatedAt)).tz(timezone).format('MMMM D YYYY [at] h:mm z')}</div>
    </div>
  );
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  lastUpdatedAt: PropTypes.string.isRequired,
};

Item.defaultProps = {
}
