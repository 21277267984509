import React from 'react';

import { configure, shallow, mount } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import DropDownList from './DropDownList';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<DropDownList/>', () => {
  const setState = jest.fn();
  const useStateSpy = jest.spyOn(React, 'useState');
  useStateSpy.mockImplementation(init => [init, setState]);

  let wrapper;
  const props = {
    onSelect: jest.fn(),
    dropClass: 'test-class',
    items: [
      {
        label: 'item1',
        value: 'item1'
      },
      {
        label: 'item2',
        value: 'item2'
      }
    ]
  };

  afterEach(() => {
    jest.clearAllMocks();
  });

  test('toggle', () => {
    const wrapper = mount(<DropDownList {...props} />);
    wrapper
      .find('svg')
      .at(0)
      .simulate('click');
    expect(wrapper).toMatchSnapshot();
    wrapper
      .find('svg')
      .at(0)
      .simulate('click');
    expect(wrapper).toMatchSnapshot();
  });

  it('should render', () => {
    wrapper = shallow(<DropDownList {...props}/>);
    expect(wrapper).toMatchSnapshot();
  });

  describe('with button input', () => {
    it('should render', () => {
      const buttonInputprops = { ...props, buttonInput: true };
      wrapper = shallow(<DropDownList {...buttonInputprops}/>);
      expect(wrapper).toMatchSnapshot();
    });
  })
});
