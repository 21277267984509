exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".R3lMLIogRCIhwy4F_z2eZ{background-color:#f5f5f5;border-radius:4px;font-family:Menlo,Monaco,Consolas,\"Courier New\",monospace;padding:10px;text-transform:capitalize;max-height:450px;overflow-y:scroll}.R3lMLIogRCIhwy4F_z2eZ ._1ulU8OG57OO9Kb-hrjH75n{text-transform:uppercase}.TSe7_pMj5HtDXw5Betf9y{color:#006fcc;text-transform:uppercase}", "", {"version":3,"sources":["/home/panuser/app/javascript/components/DataProfiles/DataProfileBracketPreview.module.scss"],"names":[],"mappings":"AAAA,uBACE,yBACA,kBACA,0DACA,aACA,0BACA,iBACA,iBAAA,CACA,gDACE,wBAAA,CAKJ,uBACE,cACA,wBAAA,CAAA","file":"DataProfileBracketPreview.module.scss","sourcesContent":[".previewArea {\n  background-color: #f5f5f5;\n  border-radius: 4px;\n  font-family: Menlo, Monaco, Consolas, \"Courier New\", monospace;\n  padding: 10px;\n  text-transform: capitalize;\n  max-height: 450px;\n  overflow-y: scroll;\n  .previewLabel {\n    text-transform: uppercase;\n    // color: #707070;\n  }\n}\n\n.primaryBlue {\n  color: #006FCC;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"previewArea": "R3lMLIogRCIhwy4F_z2eZ",
	"previewLabel": "_1ulU8OG57OO9Kb-hrjH75n",
	"primaryBlue": "TSe7_pMj5HtDXw5Betf9y"
};