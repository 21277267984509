import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PrettyBytes from 'pretty-bytes';
import { Select, SuccessIcon } from '@panwds/react-ui';
import { FaTrash } from 'react-icons/fa';

import { documentCategories } from './MiscData';
import FileUpload from './FileUpload';
import translate from '../../../helpers/translate';

function localizeString(scope, options) {
  return translate(`document_types.fingerprint_drawer.${scope}`, options);
}

const NewFingerprintForm = ({
  name,
  category: savedCategory,
  description,
  filesArr,
  onInputChange,
  onFilesUpload,
  onFileDelete
 }) => {
  const [ category, setCategory ] = useState([{ value: '' }]);

  useEffect(() => {
    if (savedCategory) {
      setCategory([{ value: savedCategory }]);
    };
  }, [ savedCategory ]);

  const handleChangeCategory = ({ selectedItem }) => {
    setCategory([ selectedItem ]);

    onInputChange({
      target: {
        name: 'category',
        value: selectedItem?.value
      }
    });
  };

  return (
    <section className='content-area'>
      <p className='input-label'>
        {localizeString('technique')}
      </p>
      <Select
        disabled
        items={[{ value: localizeString('technique_value') }]}
        selectedItem={[{ value: localizeString('technique_value') }]}
        onChange={()=>{}}
      />

      <p className='input-label'>
        {localizeString('name')}<span className='required-indicator' />
      </p>
      <input
        className='form-control name'
        placeholder={localizeString('name_placeholder')}
        onChange={onInputChange}
        value={name}
        name='name'
        data-testid='name-input'
      />

      <p className='input-label'>
        {localizeString('category')}<span className='required-indicator' />
      </p>
      <Select
        disabled={false}
        items={documentCategories}
        selectedItem={category}
        onChange={handleChangeCategory}
      />

      <p className='input-label'>
        {localizeString('description')}
      </p>
      <textarea
        className='form-control description'
        placeholder={localizeString('description_placeholder')}
        onChange={onInputChange}
        value={description}
        name='description'
        data-testid='description-input'
      />

      <p className='input-label'>
        {localizeString('source_file')}<span className='required-indicator' />
      </p>
      <FileUpload
        files={filesArr}
        onUpload={onFilesUpload}
        maxFilesAtOnce={1}
      />

      <span className='upload-info'>{localizeString('upload_info')}</span>

      { filesArr.length > 0 &&
        <section className='files-section'>
          <article className='title'>
            {localizeString('source_file')}
            <span className='required-indicator' />
          </article>

          { filesArr.map((file, idx) => (
            <article className='file-details' key={`file_${idx}`}>
              <section className='file-info'>
                <i className='file-status'><SuccessIcon size='sm' /></i>
                <span className='name'>{file.name}</span>
                <span className='size'>{PrettyBytes(file.size)}</span>
              </section>
              <div className='progress-bar' />
              <i className='delete-icon'>
                <FaTrash
                  onClick={() => onFileDelete(idx)}
                  data-testid='delete-file'
                />
              </i>
            </article>
          ))}
        </section>
      }
    </section>
  );
}

NewFingerprintForm.propTypes = {
  name: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  filesArr: PropTypes.array,
  onInputChange: PropTypes.func,
  onFilesUpload: PropTypes.func,
  onFileDelete: PropTypes.func
};

NewFingerprintForm.defaultProps = {
  name: '',
  category: '',
  description: '',
  filesArr: [],
  onInputChange: () => {},
  onFilesUpload: () => {},
  onFileDelete: () => {}
};

export default NewFingerprintForm;