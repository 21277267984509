import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../helpers/translate';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}

const BreadcrumbHeader = ({ dataProfile, clone }) => {
  return (
    <div className='header main'>
      <div className='breadcrumb'>
        <a className='btn-link' href='/'>{t('dlp')}</a>
        <span className='fa fa-chevron-right separator' />
        <a className='btn-link' href='/data_profiles'>{t('data_profiles')}</a>
        <span className='fa fa-chevron-right separator' />
        {dataProfile && !clone && t('title_edit')}
        {dataProfile && clone && t('title_clone')}
        {!dataProfile && t('title_new')}
      </div>
      <div className='title'>
        {dataProfile && !clone && t('title_edit')}
        {dataProfile && clone && t('title_clone')}
        {!dataProfile && t('title_new')}
      </div>
    </div>
  );
}

BreadcrumbHeader.propTypes = {
  dataProfile: PropTypes.bool,
  clone: PropTypes.bool,
};

BreadcrumbHeader.defaultProps = {
  dataProfile: false,
  clone: false,
}

export default BreadcrumbHeader;



