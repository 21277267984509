import React from 'react';

import { render, within, fireEvent } from '@testing-library/react';
import '@testing-library/jest-dom';
import selectEvent from 'react-select-event';
import EdmDatasetRuleItem from './EdmDatasetRuleItem';

describe('<EdmDatasetRuleItem />', () => {
  let wrapper;
  const datasets = [
    {
      id: '12345',
      name: 'CA Details',
      supported_confidence_levels: ['high', 'low']
    },
    {
      id: '12346',
      name: 'EDM Dataset 2',
      supported_confidence_levels: ['high', 'low']
    }
  ];
  const updateRuleItem = jest.fn();
  const deleteRuleItem = jest.fn();
  let props = {
    def: {
      edm_dataset_id: datasets[0].id,
      occurrence_operator_type: 'any',
      primary_match_criteria: 'any',
      secondary_match_criteria: 'any',
      primary_match_any_count: 2,
      secondary_match_any_count: 4,
      primary_fields: [],
      secondary_fields: [],
    },
    position: '0-0',
    datasets,
    updateRuleItem,
    deleteRuleItem
  };

  describe('primary rule', () => {
    beforeEach(() => {
      wrapper = render(<EdmDatasetRuleItem {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.container.querySelectorAll('#ruleItem0-0')).toHaveLength(1);
      expect(wrapper.container.querySelectorAll('.fieldsMatch')).toHaveLength(2);
    });

    it('matches snapshot', () => {
      expect(wrapper.asFragment()).toMatchSnapshot();
    });

    it('should change primary match any count', () => {
      const input = wrapper.container.querySelector('.criteraInput');
      expect(input).toBeInTheDocument();
      fireEvent.focus(input);
      fireEvent.input(input, { target: { value: '20' } });
      expect(updateRuleItem.mock.calls[0][1].primary_match_any_count).toEqual(20);
    });

    it('should change dataset', async() => {
      wrapper = render(<EdmDatasetRuleItem {...props}/>);
      const dropdown = wrapper.container.querySelector('.identifier-dropdown svg');
      await selectEvent.select(dropdown, ['EDM Dataset 2'])

      expect(updateRuleItem.mock.calls[0][1].edm_dataset_id).toEqual('12346');
    });

    it('should clear primary match_any counts when criteria is switched to all', () => {
      wrapper = render(<EdmDatasetRuleItem {...props}/>);
      const primaryMatchSelection = wrapper.container.querySelector('.match-criteria');
      fireEvent.click(primaryMatchSelection);
      const menuItem = wrapper.getByText('All (AND)');
      fireEvent.click(menuItem);

      expect(updateRuleItem.mock.calls[0][1].primary_match_criteria).toEqual('all');
      expect(updateRuleItem.mock.calls[0][1].primary_match_any_count).toEqual(null);
      expect(updateRuleItem.mock.calls[0][1].secondary_match_criteria).toEqual('any');
      expect(updateRuleItem.mock.calls[0][1].secondary_match_any_count).toEqual(4);
    });

    it('should delete definition', () => {
      let deleteIcons = wrapper.container.querySelectorAll('.removeBtn');
      fireEvent.click(deleteIcons[0]);
      expect(deleteRuleItem).toHaveBeenCalledWith('0-0');
    });

    describe('validations', () => {
      it('shows errors for incorrect data', () => {
        const def = {
          edm_dataset_id: datasets[0].id,
          occurrence_operator_type: 'any',
          primary_match_criteria: 'any',
          primary_match_any_count: 4,
          secondary_match_criteria: 'any',
          secondary_match_any_count: 3,
          primary_fields: [],
          secondary_fields: [],
        }
        props = { ...props, def, validatePresence: true }
        wrapper = render(<EdmDatasetRuleItem {...props}/>);
        expect(wrapper.container.querySelectorAll('.has-error')).toHaveLength(2);
        expect(wrapper.container.querySelectorAll('.error')).toHaveLength(3);
        expect(wrapper.asFragment()).toMatchSnapshot();
      });
      it('shows errors for missing dataset id', () => {
        const def = {
          edm_dataset_id: null,
          occurrence_operator_type: 'any',
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        }
        props = { ...props, def, validatePresence: true }
        wrapper = render(<EdmDatasetRuleItem {...props}/>);
        expect(wrapper.container.querySelectorAll('.has-error')).toHaveLength(3);
        expect(wrapper.container.querySelectorAll('.error')).toHaveLength(2);
        expect(wrapper.asFragment()).toMatchSnapshot();
      });
      it('shows errors for non-numeric occurrence low', () => {
        const def = {
          edm_dataset_id: datasets[0].id,
          occurrence_operator_type: 'between',
          occurrence_low: 'abc',
          occurrence_high: 5,
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        }
        props = { ...props, def, validatePresence: true }
        wrapper = render(<EdmDatasetRuleItem {...props}/>);
        expect(wrapper.container.querySelectorAll('.has-error')).toHaveLength(2);
        expect(wrapper.container.querySelectorAll('.error')).toHaveLength(1);
        expect(wrapper.asFragment()).toMatchSnapshot();
      });
      it('shows errors for non-numeric occurrence high', () => {
        const def = {
          edm_dataset_id: datasets[0].id,
          occurrence_operator_type: 'between',
          occurrence_low: 5,
          occurrence_high: 'abc',
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        }
        props = { ...props, def, validatePresence: true }
        wrapper = render(<EdmDatasetRuleItem {...props}/>);
        expect(wrapper.container.querySelectorAll('.has-error')).toHaveLength(2);
        expect(wrapper.container.querySelectorAll('.error')).toHaveLength(1);
        expect(wrapper.asFragment()).toMatchSnapshot();
      });
      it('shows errors for occurrence low > occurrence high', () => {
        const def = {
          edm_dataset_id: datasets[0].id,
          occurrence_operator_type: 'between',
          occurrence_low: 5,
          occurrence_high: 2,
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        }
        props = { ...props, def, validatePresence: true }
        wrapper = render(<EdmDatasetRuleItem {...props}/>);
        expect(wrapper.container.querySelectorAll('.has-error')).toHaveLength(3);
        expect(wrapper.container.querySelectorAll('.error')).toHaveLength(2);
        expect(wrapper.asFragment()).toMatchSnapshot();
      });
      it('shows errors for occurrence high > 500', () => {
        const def = {
          edm_dataset_id: datasets[0].id,
          occurrence_operator_type: 'between',
          occurrence_low: 5,
          occurrence_high: 501,
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        }
        props = { ...props, def, validatePresence: true }
        wrapper = render(<EdmDatasetRuleItem {...props}/>);
        expect(wrapper.container.querySelectorAll('.has-error')).toHaveLength(3);
        expect(wrapper.container.querySelectorAll('.error')).toHaveLength(2);
        expect(wrapper.asFragment()).toMatchSnapshot();
      });
      it('shows errors for occurrence count > 500', () => {
        const def = {
          edm_dataset_id: datasets[0].id,
          occurrence_operator_type: 'more_than_equal_to',
          occurrence_count: 501,
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        }
        props = { ...props, def, validatePresence: true }
        wrapper = render(<EdmDatasetRuleItem {...props}/>);
        expect(wrapper.container.querySelectorAll('.has-error')).toHaveLength(3);
        expect(wrapper.container.querySelectorAll('.error')).toHaveLength(2);
        expect(wrapper.asFragment()).toMatchSnapshot();
      });
    });
  });

  describe('secondary rule', () => {
    beforeEach(() => {
      props = {
        def: {
          edm_dataset_id: datasets[0].id,
          occurrence_operator_type: 'any',
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        },
        position: '1-0',
        datasets,
        updateRuleItem,
        deleteRuleItem,
        datasets
      };
      wrapper = render(<EdmDatasetRuleItem {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.container.querySelectorAll('#ruleItem1-0')).toHaveLength(1);
      expect(wrapper.container.querySelectorAll('.fieldsMatch')).toHaveLength(2);
    });

    it('matches snapshot', () => {
      expect(wrapper.asFragment()).toMatchSnapshot();
    });

    it('should delete definition', () => {
      let deleteIcons = wrapper.container.querySelectorAll('.removeBtn');
      fireEvent.click(deleteIcons[0]);
      expect(deleteRuleItem).toHaveBeenCalledWith('1-0');
    });
  });

  describe('operators and occurrences', () => {
    beforeEach(() => {
      props = {
        def: {
          edm_dataset_id: datasets[0].id,
          occurrence_operator_type: 'less_than_equal_to',
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        },
        position: '0-0',
        datasets,
        updateRuleItem,
        deleteRuleItem
      };
      wrapper = render(<EdmDatasetRuleItem {...props}/>);
    });

    it('should change operator to more_than_equal_to', () => {
      const dropdown = wrapper.container.querySelector('.basic-dropdown.occurrence');
      fireEvent.click(dropdown);
      const operator = wrapper.getByText('More than or equal to');
      expect(operator).toBeInTheDocument();
      fireEvent.click(operator);
      expect(updateRuleItem.mock.calls[0][1].occurrence_operator_type).toEqual("more_than_equal_to");
    });

    it('should change occurrence', () => {
      const input = wrapper.container.querySelector('input.occurrenceInput');
      fireEvent.focus(input);
      fireEvent.input(input, { target: { value: '5' } });
      expect(updateRuleItem.mock.calls[0][1].occurrence_count).toEqual(5);
    });

    it('should change operator to any', () => {
      const dropdown = wrapper.container.querySelector('.basic-dropdown.occurrence');
      fireEvent.click(dropdown);
      const operator = wrapper.getByText('Any');
      expect(operator).toBeInTheDocument();
      fireEvent.click(operator);
      expect(updateRuleItem.mock.calls[0][1].occurrence_operator_type).toEqual("any");
    });

    it('should change match criteria', () => {
      const dropdown = wrapper.container.querySelector('.basic-dropdown.match-criteria');
      fireEvent.click(dropdown);
      const menu = wrapper.container.querySelector('.dd-items-left');
      const operator = within(menu).getByText('All (AND)');
      expect(operator).toBeInTheDocument();
      fireEvent.click(operator);
      expect(updateRuleItem.mock.calls[0][1].primary_match_criteria).toEqual("all");
    });

    it('should toggle multi-select', () => {
      jest.spyOn($.fn, 'show');
      jest.spyOn($.fn, 'hide');
      const input = wrapper.container.querySelector('input.searchBox');
      fireEvent.click(input);
      expect($.fn.show).toHaveBeenCalled();
      fireEvent.click(input);
      expect($.fn.hide).toHaveBeenCalled();
    });
  });

  it('should set occurrence high and low', () => {
    props = {
      def: {
        edm_dataset_id: datasets[0].id,
        occurrence_operator_type: 'between',
        primary_match_criteria: 'any',
        secondary_match_criteria: 'any',
        primary_fields: [],
        secondary_fields: [],
      },
      position: '0-0',
      datasets,
      updateRuleItem,
      deleteRuleItem
    };
    wrapper = render(<EdmDatasetRuleItem {...props}/>);

    const inputs = wrapper.container.querySelectorAll('input.occurrenceInput');
    expect(inputs.length).toBe(2);
    fireEvent.focus(inputs[0]);
    fireEvent.input(inputs[0], { target: { value: '2' } });
    fireEvent.focus(inputs[1]);
    fireEvent.input(inputs[1], { target: { value: '4' } });
    expect(updateRuleItem.mock.calls[0][1].occurrence_low).toEqual(2);
    expect(updateRuleItem.mock.calls[0][1].occurrence_high).toEqual(4);
  });
});