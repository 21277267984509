import React from 'react';
import {render, fireEvent, waitFor, screen, waitForElementToBeRemoved} from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import renderWithRouter from '../../helpers/setupTests';

import FilteringProfileTable from './FilteringProfileTable';

const mockAdapter = new MockAdapter(axios);
const profiles = [
  {
    id: '1',
    name: 'Test profile 1',
    rule1: {
      action: 'alert'
    },
    file_type: ['any']
  },
  {
    id: '2',
    name: 'Test profile 2',
    rule1: {
      action: 'block'
    },
    file_type: ['any']
  },
  {
    id: '3',
    name: 'Dummy profile',
    rule1: {
      action: 'alert'
    },
    rule2: {
      action: 'block'
    },
    file_type: ['any']
  }
];
mockAdapter.onGet().reply(200, {
  resources: profiles
});

test('renders the title', () => {
  const {container, getByText} = render(<FilteringProfileTable lookupURL={'/dlp_rules'} userRole={'READ_WRITE'}/>);
  expect(getByText('Data Filtering Profiles')).toBeInTheDocument();
});

test('renders the row', async() => {
  const {container, findByText} = render(<FilteringProfileTable lookupURL={'/dlp_rules'} userRole={'READ_WRITE'}/>);
  const elem = await findByText('Test profile 1');
  expect(elem).toBeInTheDocument();
});

test('search by name works', async() => {
  mockAdapter.resetHistory();
  const {container, getByText} = render(<FilteringProfileTable lookupURL={'/dlp_rules'} userRole={'READ_WRITE'}/>);
  await waitForElementToBeRemoved(() => container.querySelector('img[id="loader"]'));
  const input = container.querySelector('input.form-control.search');
  fireEvent.focus(input);
  fireEvent.input(input, { target: { value: 'Test' } });
  fireEvent.keyPress(input, {key: 'Enter', charCode: 13});
  expect(mockAdapter.history.get[1].url).toMatch(/name=Test/);
});

test('view details works', async() => {
  const {container, getByText} = renderWithRouter(<FilteringProfileTable lookupURL={'/dlp_rules'} userRole={'READ_WRITE'}/>);
  await waitForElementToBeRemoved(() => container.querySelector('img[id="loader"]'));
  const nameCell = container.querySelector('tr > td > span.itemName');
  fireEvent.click(nameCell);
  expect(container.querySelector('.detailContent')).toBeInTheDocument();
});
