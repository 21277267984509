import React from 'react';
import PropTypes from 'prop-types';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Button } from '@panwds/react-ui';

import translate from '../../helpers/translate';
import ColumnTable from './ColumnTable';
import SpinnerIcon from '../../../assets/images/spinner.gif';

const moment = require('moment-timezone');

function t(scope, options) {
  return translate(`edm_datasets.view.${scope}`, options);
}

const EdmDatasetDetails = ({ dataset, updateState, actionable }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div className='tableDetailsView'>
      <div className='detailHeader'>
        <span className='detailTitle'>
          {dataset.name ? dataset.name : <img id='loader' src={SpinnerIcon} alt='loading' />}
        </span>
        <Button appearance='tertiary-clear' addClassName='text-lg tw-float-right' data-dismiss='modal' aria-hidden='true' onClick={ () => updateState({viewing: ''}) } />
      </div>
      {
        dataset.id &&
        <div>
          <div style={{ padding: '15px' }}>
            { actionable &&
              <div className='actionBtns'>
                <Button aria-label='edit' icon={<FaPen className='muteText' />}/>
                <Button aria-label='delete' icon={<FaTrash className='muteText' />}/>
              </div>
            }
            <br />

            <p className='detailSubtitle'>{t('details')}</p>
            <table className='table table-borderless table-condensed'>
              <tbody>
                <tr>
                  <td className='form-label'>{t('name')}</td>
                  <td>{dataset.name}</td>
                </tr>
                <tr>
                  <td className='form-label'>{t('updated_by')}</td>
                  <td>{dataset.updated_by}</td>
                </tr>
                <tr>
                  <td className='form-label'>{t('updated_at')}</td>
                  <td>{dataset.updated_at && moment(dataset.updated_at).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z')}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            { dataset.active_fields.length > 0 && <ColumnTable columns={dataset.active_fields} /> }
          </div>
        </div>
      }
    </div>
  )
}

EdmDatasetDetails.propTypes = {
  dataset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updated_by: PropTypes.string,
    updated_at: PropTypes.string,
    active_fields: PropTypes.array,
  }),
  updateState: PropTypes.func.isRequired,
  actionable: PropTypes.bool,
};

EdmDatasetDetails.defaultProps = {
  dataset: {},
  actionable: false,
}

export default EdmDatasetDetails;
