/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';

import translate from '../../helpers/translate';
import withActions from "./withActions";
import { CUSTOM } from "./DataPatternTable";
import {regexArrayToString, regexStringToArray} from './regexTransform';
import isValidName from "../../helpers/nameValidator";

function t(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}
const CustomPatternForm =({pattern, updateState, doValidation}) => {
  const {detection_technique, regexes, proximity_keywords, delimiter} = pattern;
  const keywords = proximity_keywords? proximity_keywords.join(', ') : '';
  const regex = regexArrayToString(regexes, detection_technique, delimiter);
  const [regexInput, setRegexInput] = useState(regex);
  const [keywordsInput, setKeywordsInput] = useState(keywords);
  const [regexValid, setRegexValid] = useState(true);

  const regexTypeChanged = (event) => {
    const newType = event.target.value;
    updateState({
      pattern: {
        ...pattern,
        detection_technique: newType
      }
    });
  }

  const regexChanged = (event) => {
    setRegexInput(event.target.value);
  }

  const processRegex = () => {
    if (!regexInput) {
      setRegexValid(false);
      return;
    }
    try {
      const regexes = regexStringToArray(regexInput, detection_technique, delimiter);
      updateState({
        pattern: {
          ...pattern,
          regexes
        }
      });
      setRegexValid(true);
    } catch (error) {
      if (error instanceof SyntaxError) {
        setRegexValid(false);
      } else {
        throw error;
      }
    }
  }

  const delimiterChanged = (event) => {
    const {value} = event.target;
    updateState({
      pattern: {
        ...pattern,
        delimiter: value
      }
    });
  }

  const keyWordsChanged = (event) => {
    setKeywordsInput(event.target.value);
  }

  const processKeywords = () => {
    const newKeywords = keywordsInput?keywordsInput.split(',').map(k => k.trim()):[];
    updateState({
      pattern: {
        ...pattern,
        proximity_keywords: newKeywords
      }
    });
  }


  const validatePattern = () => {
    if (!pattern.name || !pattern.name.trim() || !isValidName(pattern.name)) {
      return false;
    }
    if (pattern.root_type === 'predefined') {
      return true;
    }
    processRegex();

    return !!regexInput && !!delimiter && regexValid;

  }

  useEffect(() => {
    if (doValidation) {
      const valid = validatePattern();
      updateState({
        valid
      });
    } else {
      updateState({
        valid: true
      });
    }
  }, [doValidation]);
  return (
    <div className='definition-section regex'>
      <div className='data-pattern-form'>

        { pattern.root_type !== 'predefined' &&
          <>
            <div className='form-label tw-mb-3'>{t('regular_expression')}</div>
            <div className='regex-radio-group'>
              <input className='tw-mr-1' type="radio" id="basic-regex" checked={detection_technique === 'regex'}
              name="regex-type" value='regex' onChange={regexTypeChanged}/>
              <label className='tw-mr-3' htmlFor="basic-regex">{t('basic')}</label>

              <input className='tw-mr-1' type="radio" id="weighted-regex" checked={detection_technique !== 'regex'}
              name="regex-type" value='weighted_regex' onChange={regexTypeChanged}/>
              <label htmlFor="weighted-regex">{t('weighted')}</label>
            </div>

            <textarea className={`regex-input form-control ${doValidation && !regexValid? 'has-error' : ''}`}
              value={regexInput}
              onChange={regexChanged}
              onBlur={processRegex}
              placeholder={detection_technique === 'regex'? t('regex_placeholder') : t('weighted_regex_placeholder')}/>

            <div className={`helpText tw-mb-3 ${doValidation && !regexValid? 'error' : ''}`}>{t('regex_hint')}</div>
          </>
        }

        { detection_technique !== 'regex' &&
          <>
            <div className='form-label'>{t('customize_your_delimiter')}</div>
            <input maxLength="32" className={`form-control delimiter ${doValidation && !delimiter? 'has-error' : ''}`}
              onChange={delimiterChanged}
              value={delimiter}/>
            <div className={`helpText tw-mb-3 ${doValidation && !delimiter? 'error' : ''}`}>{t('delimiter_hint')}</div>
          </>
        }

        <div className='form-label tw-mb-1'>{t('proximity_keywords')} {t('optional')}</div>
        <textarea className='key-words-input form-control'
          value={keywordsInput}
          onChange={keyWordsChanged}
          onBlur={processKeywords}/>
        <div className='helpText tw-max-w-2xl'>{t('keyword_hint')}</div>
      </div>
    </div>
  );
}

CustomPatternForm.propTypes = {
  pattern: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    root_type: PropTypes.string,
    detection_technique: PropTypes.string,
    description: PropTypes.string,
    regexes: PropTypes.array,
    proximity_keywords: PropTypes.arrayOf(PropTypes.string),
    delimiter: PropTypes.string
  }),
  updateState: PropTypes.func.isRequired,
  doValidation: PropTypes.bool.isRequired,
};

CustomPatternForm.defaultProps = {
  pattern: PropTypes.shape({
    id: null,
    name: '',
    root_type: null,
    detection_technique: 'regex',
    description: '',
    regexes: [],
    proximity_keywords: [],
    delimiter: ''
  }),
}

export default withActions(CustomPatternForm, CUSTOM);