import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrettyBytes from 'pretty-bytes';
import ClassNames from 'classnames';
import axios from 'axios';
import { LoadingIcon, Button } from '@panwds/react-ui';
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import SidePanel from './components/SlidingPanel';
import FileUpload from './components/FileUpload';
import {
  isFileSizeAllowed
} from './components/helpers';
import translate from '../../helpers/translate';

import {
  DOCUMENT_STATUS_SUCCESS,
  DOCUMENT_STATUS_FAILED,
  DOCUMENT_STATUS_IN_PROGRESS,
  NUMBER_SIMULTANEOUS_FINGERPRINT_TEST
} from '../constants';

import './components/drawer.scss';

function localizeString(scope, options) {
  return translate(`document_types.test_drawer.${scope}`, options);
}

const TestDrawer = ({
  apiEndpoint,
  apiPostfix,
  category,
  documentObjectPath,
  id,
  isOpen,
  name,
  onClose
}) => {
  const [filesArr, setFilesArr] = useState([]);
  const [resultArr, setResultArr] = useState([]);
  const [trackTestCalls, setTrackTestCalls] = useState({});
  const [error, setError] = useState('');

  const keyFromFileAttributes = file => (
    `${file.name.toString().replace(' ', '')}_${file.size}`
  );

  const removeProcessedFiles = (uniqueKey) => {
    setFilesArr(prevFilesArr => {
      const updatedFilesArr = [ ...prevFilesArr ];

      for (let idx=0; idx<updatedFilesArr.length; idx+=1) {
        if (uniqueKey === keyFromFileAttributes(updatedFilesArr[idx])) {
          updatedFilesArr.splice(idx, 1);
          break;
        }
      }

      return updatedFilesArr;
    });
  };

  const updateTrackTestCalls = (uniqueKey, flag) => {
    setTrackTestCalls(prevTrackTestCalls => {
      const updatedTrackTestCalls = { ...prevTrackTestCalls };

      updatedTrackTestCalls[uniqueKey] = flag;

      return updatedTrackTestCalls;
    });
  };

  const fetchResultForFile = (file, uniqueKey) => {
    const formData = new FormData();

    formData.append('file', file);

    axios.post(`${apiEndpoint}/${id}${apiPostfix}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      updateTrackTestCalls(uniqueKey, DOCUMENT_STATUS_SUCCESS);
      removeProcessedFiles(uniqueKey);

      setResultArr(prevResultArr => (
        [ ...prevResultArr, { name: file.name, result: Math.trunc(res?.data?.score) } ]
      ));
    })
    .catch(() => {
      updateTrackTestCalls(uniqueKey, DOCUMENT_STATUS_FAILED);
      removeProcessedFiles(uniqueKey);

      setResultArr(prevResultArr => (
        [ ...prevResultArr, { name: file.name, result: 'Failure!', error: true } ]
      ));
    });

    setError('');
  };

  const handleUpload = (arr) => {
    if ((arr.length + filesArr.length) > NUMBER_SIMULTANEOUS_FINGERPRINT_TEST) {
      setError(localizeString('upload_error_text_number'));
    } else {
      const updatedFilesArr = [...filesArr];

      arr.forEach(file => {
        const uniqueKey = keyFromFileAttributes(file);

        if (
          isFileSizeAllowed(file) &&
          (!trackTestCalls[uniqueKey] || trackTestCalls[uniqueKey] === DOCUMENT_STATUS_FAILED)
        ) {
          updateTrackTestCalls(uniqueKey, DOCUMENT_STATUS_IN_PROGRESS);
          fetchResultForFile(file, uniqueKey);

          updatedFilesArr.push(file);
        }
      });

      if (filesArr.length === updatedFilesArr.length) {
        setError(localizeString('upload_error_text_size'));
      } else {
        setError('');
      }

      setFilesArr(updatedFilesArr);
    }
  };

  return (
    <SidePanel
      addClassName='drawer-container'
      isOpen={isOpen}
      onClose={onClose}
      title={name}
    >
      <main className='test-drawer-main'>
        <div className='form-label'>{localizeString('label_technique')}</div>
        <div className='name'>Indexed Document Match (IDM)</div>
        <div className='form-label'>{localizeString('label_category')}</div>
        <div className='category'>{category}</div>

        <section className='title-area'>
          {localizeString('upload_test_files')}
        </section>

        <FileUpload
          files={filesArr}
          onUpload={handleUpload}
          maxFilesAtOnce={NUMBER_SIMULTANEOUS_FINGERPRINT_TEST}
          textLabel={localizeString('upload_file_label')}
        />

        <div className='upload-info'>{localizeString('upload_info')}</div>

        <section className='files-queue'>
          <div className='error-message'>{error}</div>

          {filesArr.map((file, idx) => (
            <article className='file' key={`file-${idx}`}>
              <LoadingIcon size='sm' addClassName='file-status' />
              <div className='file-name text-ellipsis'>{file.name}</div>
              <div className='file-size text-ellipsis'>{PrettyBytes(file.size)}</div>
              <div className='progress-bar' />
            </article>
          ))}
        </section>

        <section className={ClassNames('result-section', { 'in-active': resultArr?.length === 0 })}>
          <section className='result-body'>
            {resultArr.map((file, idx) => (
              <section className='result-row' key={`r-row-${idx}`}>
                <div className='column-1 text-ellipsis'>{file.name}</div>
                <div data-testid={`result-score-${idx}`} className={ClassNames('column-2', 'text-ellipsis', { error: file.error })}>
                  {file.result}
                </div>
              </section>
            ))}
          </section>


          <section className='result-header'>
            <div className='column-1'>{localizeString('test_file_name')}</div>
            <div className='column-2'>
              {localizeString('overlapping_score')}
              <FaInfoCircle
                className='score-tooltip-icon'
                data-tip={localizeString('tooltip_score')}
                data-class='place-top score-tooltip'
              />
              <ReactTooltip />
            </div>
          </section>
        </section>
      </main>
      <section className='drawer-footer tw-flex tw-justify-end tw-space-x-1'>
        <Button addClassName='cancel' onClick={onClose} >
          {localizeString('close')}
        </Button>
      </section>
    </SidePanel>
  );
}

TestDrawer.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  apiPostfix: PropTypes.string,
  category: PropTypes.string.isRequired,
  documentObjectPath: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

TestDrawer.defaultProps = {
  apiPostfix: '',
  isOpen: false,
  onClose: () => {}
};

export default TestDrawer;