import React from 'react';

import { configure, shallow, mount } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import ToggleSwitch from './ToggleSwitch';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<ToggleSwitch/>', () => {
  let wrapper;
  let props;

  describe('render()', () => {
    it('should render enabled input tag', () => {
      props = {
        sliderDisabled: false,
        checked: true
      };
      wrapper = mount(<ToggleSwitch {...props}/>);
      const inputNode = wrapper.find('input.change-status-slider');      
      expect(inputNode.getDOMNode().getAttribute('disabled')).toBeFalsy();
    });

    it('should render disabled input tag', () => {
      props = {
        sliderDisabled: true,
        checked: true
      };
      wrapper = mount(<ToggleSwitch {...props}/>);
      const inputNode = wrapper.find('input.change-status-slider');      
      expect(inputNode.getDOMNode()).toHaveProperty('disabled');
    });
  });
  
});
