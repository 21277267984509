import React from 'react';
import PropTypes from 'prop-types';
import styles from './DataProfileBracketPreview.module.scss'
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}

const DataProfileBracketPreview = (props) => {
  const { dataPatternRule, datasets, expTree, advanced, schema, profiles, multi } = props;

  const operator = (field, dp = true) => {
    if (field.occurrence_operator_type === null) {
      return ` ?${dp ? '; ' : ''}`;
    }
    if (field.occurrence_operator_type === 'between') {
      return(
        <span>
          <span className={styles.previewLabel}> between </span>
          {`${field.occurrence_low || '?'}, ${field.occurrence_high || '?'}${dp ? '; ' : ''}`}
        </span>
      )
    }
    if (field.occurrence_operator_type === 'more_than_equal_to') {
      return ` >= ${field.occurrence_count || '?'}${dp ? '; ' : ''}`
    }
    if (field.occurrence_operator_type === 'less_than_equal_to') {
      return ` <= ${field.occurrence_count || '?'}${dp ? '; ' : ''}`
    }
    return ` > 0${dp ? '; ' : ''}`;
  }

  const conditionStr = (condition, index, rule, v1MultiOp = false) => {
    const fieldPreview = ['('];
    if(index === 0 && condition.operators.length === 1 && v1MultiOp && condition.operators[0].includes('not') && condition.rule_items.length === 1) {
      fieldPreview.unshift(<span className={`${styles.previewLabel} codeRed`} key='not00'>not </span>);
    }
    condition.rule_items.forEach((field, i) => {
      if (field.id) {
        fieldPreview.push(
          <span key={field.id}>
            {field.name}
            {operator(field)}
            {field.confidence_level || '?'}
          </span>
        );
      }
      if (condition.rule_items.length > 1 && i < condition.rule_items.length -1) {
        fieldPreview.push(<br key={`br${i}`} />);
        if (v1MultiOp) {
          fieldPreview.push(<span className={`${styles.previewLabel}`} key={`op${i}`}> {(condition.operators[i] || '').split('_')[0]} </span>);
          if ((condition.operators[i] || '').split('_').length > 1) {
            fieldPreview.push(<span className={`${styles.previewLabel} codeRed`} key={`not${i}`}>not </span>);
          }
        }
        else {
          fieldPreview.push(<span className={`${styles.previewLabel}`} key={`op${i}`}> {(condition.operators[0] || '').split('_')[0]} </span>);
        }
      }
    });
    fieldPreview.push(')');
    if (rule.conditions.length > 1 && index < rule.conditions.length -1) {
      fieldPreview.push(<br key={`cbr${index}`} />);
    }
    return fieldPreview;
  }

  const v1MultiOp = (condition, index) => {
    return(
      <span key={`condition-${index}`}>
        {dataPatternRule.conditions.length > 1 && index > 0 &&
          <span className={`${styles.previewLabel} mr5`}>
            {(dataPatternRule.operators[index - 1] || dataPatternRule.operators[0] || '').split('_')[0]}
            {(dataPatternRule.operators[index - 1] || '').split('_').length > 1 && <span className='codeRed ml5'>not</span>}
            {condition.rule_items.length === 1 && (condition.operators[index - 1] || '').includes('not') && <span className='codeRed ml5'>not</span>}
          </span>
        }
        {conditionStr(condition, index, dataPatternRule, true)}
      </span>
    );
  }

  const v2SameOp = (condition, index) => {
    return(
      <span key={`condition-${index}`}>
        {dataPatternRule.conditions.length > 1 && index > 0 &&
          <span className={`${styles.previewLabel} mr5`}>
            {(dataPatternRule.operators[index - 1] || '').split('_')[0]}
            {dataPatternRule.operators[index - 1] && dataPatternRule.operators[index - 1].includes('not') && <span className='codeRed ml5'>not</span>}
            {condition.operators[0] && condition.operators[0].includes('not') && <span className='codeRed ml5'>not</span>}
          </span>
        }
        {index === 0 && (condition.operators[0] || '').split('_').length > 1 &&
          <span className={`codeRed ${styles.previewLabel} mr5`}>not</span>
        }
        {conditionStr(condition, index, dataPatternRule)}
      </span>
    );
  }

  const patternRule = () => {
    if (Object.keys(dataPatternRule).length === 0) {
      return '';
    }
    return(
      dataPatternRule.conditions.map((condition, index) =>
        (schema !== 1 || (condition.operators.every( (val, i, arr) => val === arr[0]) && (!advanced || (condition.operators.length > 0 && !condition.operators[0].includes('not'))))) ?
          v2SameOp(condition, index) : v1MultiOp(condition, index)
      )
    )
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return `${str.slice(0, num)}...`
  }

  const v2BracketDisplay = (subExpressions, operatorType, level) => {
    const bracketDisplay = ['('];

    subExpressions.forEach((subExp, index) => {
      if (index > 0) {
        bracketDisplay.push(<br key={index} />)
      }
      if (subExp.rule_item !== null) {
        const edm = subExp.rule_item.detection_technique === 'edm';
        let {name} = subExp.rule_item;
        if (edm && !name) {
          name = datasets.find((d) => d.id === subExp.rule_item.edm_dataset_id)?.name;
        }
        bracketDisplay.push(
          <span key={`dataset-${index}`} className='bracketRuleItem' style={index > 0 ? {marginLeft: `${level*10}px`} : {}}>

            {index > 0 &&
              <span className={`${styles.previewLabel} ${operatorType === 'not' ? 'codeRed' : ''} mr5`}>
                {operatorType}
              </span>
            }
            {truncateString(name || '?', 20)}
            {operator(subExp.rule_item, !edm)}
            {!edm && (subExp.rule_item.confidence_level || '?')}
          </span>
        );
      }
      else {
        bracketDisplay.push(
          <span key={`group-${index}`} className='bracketRuleItem'>
            <span className='subExpression'>
              {index > 0 &&
                <span className={`${styles.previewLabel} ml5 mr5`} style={{marginLeft: `${level*10}px`}}>
                  {operatorType}{subExp.operator_type === 'not' &&
                    <span className={`${styles.previewLabel} codeRed`}> not</span>
                  }
                </span>
              }
              {v2BracketDisplay(subExp.sub_expressions, subExp.operator_type === 'not' ? 'and' : subExp.operator_type, level + 1)}
            </span>
          </span>
        )
      }
    });
    bracketDisplay.push([')']);
    return (
      <span className={`level${level}`}>{bracketDisplay}</span>
    );
  }

  const profileBracketDisplay = () => {
    const bracketDisplay = ['('];
    profiles.forEach((profile, index) => {
      bracketDisplay.push(profile.value || 'Undefined');
      if (index < profiles.length - 1) {
        bracketDisplay.push(' OR ');
      }
    });
    bracketDisplay.push(')');
    return bracketDisplay;
  }

  const criteriaDefined = () => {
    if (multi) {
      return profiles.length > 0 && profiles[0].key
    }
    if (schema === 2) {
      return expTree.rule_item || expTree.sub_expressions.length > 0
    }
    return Object.keys(dataPatternRule).length > 0;
  }

  return (
    <div>
      {!criteriaDefined() && <span className='muteText' style={{lineHeight: '20px'}}>{t('no_criteria_defined')}</span>}
      {criteriaDefined() &&
        <div className={styles.previewArea}>
          {schema === 1 && patternRule()}
          {multi && profileBracketDisplay()}
          {schema === 2 && !multi && v2BracketDisplay(expTree.sub_expressions, expTree.operator_type, 0)}
        </div>
      }
    </div>
  )
}

DataProfileBracketPreview.propTypes = {
  dataPatternRule: PropTypes.shape({
    operators: PropTypes.array,
    conditions: PropTypes.array,
  }),
  expTree: PropTypes.shape({
    operator_type: PropTypes.string,
    rule_item: PropTypes.shape({}),
    sub_expressions: PropTypes.array,
  }),
  datasets: PropTypes.array,
  advanced: PropTypes.bool,
  schema: PropTypes.number,
  profiles: PropTypes.array,
  multi: PropTypes.bool,
};

DataProfileBracketPreview.defaultProps = {
  dataPatternRule: {},
  expTree: {},
  datasets: [],
  advanced: true,
  schema: 2,
  profiles: [],
  multi: false,
}
export default DataProfileBracketPreview
