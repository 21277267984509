import _findIndex from 'lodash.findindex';

const nameRegex = /^[A-Z0-9][ .\w-]*\w$/i;

const isValidName = (name, existingNames = []) => {
  if (existingNames) {
    const index = _findIndex(existingNames, name);

    if (index > -1) {
      return false;
    }
  }

  return name.match(nameRegex);
};

export default isValidName;