import translate from '../../../helpers/translate';

export default [
  {
    value: 'and',
    label: translate('data_profiles.form.match_type.and'),
  },
  {
    value: 'or',
    label: translate('data_profiles.form.match_type.or'),
  },
];
