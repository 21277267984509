import isProfileValid  from './profileValidatorV1';

describe('profileValidatorV1', () => {
  let profile;

  it('should return false for empty profile', () => {
    profile = {};
    expect(isProfileValid(profile)).toEqual(false);
  });

  it('should return false for missing name', () => {
    profile = {
      name: '',
      advance_data_patterns_rules: [
        {
          "operators": [
            "or_not",
            "or"
          ],
          "conditions": [
            {
              "operators": [
                "and_not",
                "or"
              ],
              "rule_items": [
                {
                  "id": "1234561",
                  "name": "API Credentials Client ID - Amazon Web Services AWS",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234562",
                  "name": "Application credentials",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234563",
                  "name": "Api access token",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            },
            {
              "operators": [
                "and",
                "and_not"
              ],
              "rule_items": [
                {
                  "id": "1234564",
                  "name": "Bank - Bankruptcy Filings",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234565",
                  "name": "Bank - Canada Routing Number THD",
                  "detection_technique": "weighted_regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234566",
                  "name": "Bank - Committee on Uniform Securities Identification Procedures number",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            },
            {
              "operators": [
                "and_not"
              ],
              "rule_items": [
                {
                  "id": "1234567",
                  "name": "Bank - Canada Acct near Routing THD",
                  "detection_technique": "weighted_regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234568",
                  "name": "Bank - Canada Routing Number THD",
                  "detection_technique": "weighted_regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            }
          ]
        },
        {
          "operators": [
            "and",
            "and"
          ],
          "conditions": [
            {
              "operators": [],
              "rule_items": [
                {
                  "id": "1234563",
                  "name": "API Credentials Client ID - Amazon Web Services AWS",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            },
            {
              "operators": [],
              "rule_items": [
                {
                  "id": "1234564",
                  "name": "API Credentials Client ID - Amazon Web Services AWS",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            },
            {
              "operators": [
                "or"
              ],
              "rule_items": [
                {
                  "id": "1234565",
                  "name": "Api access token",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234566",
                  "name": "Application credentials",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            }
          ]
        }
      ]
    };
    expect(isProfileValid(profile)).toEqual(false);
  });

  it('should return false for missing data pattern rules', () => {
    profile = {
      name: 'abc',
      advance_data_patterns_rules: [{}, {}]
    };
    expect(isProfileValid(profile)).toEqual(false);
  });

  it('should return true for valid profile', () => {
    profile = {
      name: 'Profile Test',
      advanced: true,
      advance_data_patterns_rules: [
        {
          "operators": [
            "or_not",
            "or"
          ],
          "conditions": [
            {
              "operators": [
                "and_not",
                "or"
              ],
              "rule_items": [
                {
                  "id": "1234561",
                  "name": "API Credentials Client ID - Amazon Web Services AWS",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234562",
                  "name": "Application credentials",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234563",
                  "name": "Api access token",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            },
            {
              "operators": [
                "and",
                "and_not"
              ],
              "rule_items": [
                {
                  "id": "1234564",
                  "name": "Bank - Bankruptcy Filings",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234565",
                  "name": "Bank - Canada Routing Number THD",
                  "detection_technique": "weighted_regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234566",
                  "name": "Bank - Committee on Uniform Securities Identification Procedures number",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            },
            {
              "operators": [
                "and_not"
              ],
              "rule_items": [
                {
                  "id": "1234567",
                  "name": "Bank - Canada Acct near Routing THD",
                  "detection_technique": "weighted_regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234568",
                  "name": "Bank - Canada Routing Number THD",
                  "detection_technique": "weighted_regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            }
          ]
        },
        {
          "operators": [
            "and",
            "and"
          ],
          "conditions": [
            {
              "operators": [],
              "rule_items": [
                {
                  "id": "1234563",
                  "name": "API Credentials Client ID - Amazon Web Services AWS",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            },
            {
              "operators": [],
              "rule_items": [
                {
                  "id": "1234564",
                  "name": "API Credentials Client ID - Amazon Web Services AWS",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            },
            {
              "operators": [
                "or"
              ],
              "rule_items": [
                {
                  "id": "1234565",
                  "name": "Api access token",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                },
                {
                  "id": "1234566",
                  "name": "Application credentials",
                  "detection_technique": "ml",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": [
                    "high",
                    "low"
                  ],
                  "version": 1
                }
              ]
            }
          ]
        }
      ],
      schema_version: 1
    };
    expect(isProfileValid(profile)).toEqual(true);
  });

  describe('data pattern validations', () => {
    it ('should return false for missing occurrence', () => {
      profile = {
        name: 'test',
        advanced: true,
        advance_data_patterns_rules: [
          {
            "operators": [],
            "conditions": [
              {
                "operators": [
                  "and_not",
                  "or"
                ],
                "rule_items": [
                  {
                    "id": "1234561",
                    "name": "API Credentials Client ID - Amazon Web Services AWS",
                    "detection_technique": "regex",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234562",
                    "name": "Application credentials",
                    "detection_technique": "ml",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234563",
                    "name": "Api access token",
                    "detection_technique": "ml",
                    "occurrence_operator_type": "",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  }
                ]
              }
            ]
          },
          {}
        ],
        schema_version: 1
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it ('should return false for occurrence_high > 500', () => {
      profile = {
        name: 'test',
        advanced: true,
        advance_data_patterns_rules: [
          {
            "operators": [],
            "conditions": [
              {
                "operators": [
                  "and_not",
                  "or"
                ],
                "rule_items": [
                  {
                    "id": "1234561",
                    "name": "API Credentials Client ID - Amazon Web Services AWS",
                    "detection_technique": "regex",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234562",
                    "name": "Application credentials",
                    "detection_technique": "ml",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234563",
                    "name": "Api access token",
                    "detection_technique": "regex",
                    "occurrence_operator_type": "between",
                    "occurrence_low": 25,
                    "occurrence_high": 501,
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  }
                ]
              }
            ]
          },
          {}
        ],
        schema_version: 1
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it ('should return false for occurrence_count > 500', () => {
      profile = {
        name: 'test',
        advanced: true,
        advance_data_patterns_rules: [
          {
            "operators": [],
            "conditions": [
              {
                "operators": [
                  "and_not",
                  "or"
                ],
                "rule_items": [
                  {
                    "id": "1234561",
                    "name": "API Credentials Client ID - Amazon Web Services AWS",
                    "detection_technique": "regex",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234562",
                    "name": "Application credentials",
                    "detection_technique": "ml",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234563",
                    "name": "Api access token",
                    "detection_technique": "regex",
                    "occurrence_operator_type": "more_than_equal_to",
                    "occurrence_count": 600,
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  }
                ]
              }
            ]
          },
          {}
        ],
        schema_version: 1
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it ('should return false for missing confidence', () => {
      profile = {
        name: 'test',
        advanced: true,
        advance_data_patterns_rules: [
          {
            "operators": [],
            "conditions": [
              {
                "operators": [
                  "and_not",
                  "or"
                ],
                "rule_items": [
                  {
                    "id": "1234561",
                    "name": "API Credentials Client ID - Amazon Web Services AWS",
                    "detection_technique": "regex",
                    "occurrence_operator_type": "any",
                    "confidence_level": "",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234562",
                    "name": "Application credentials",
                    "detection_technique": "ml",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234563",
                    "name": "Api access token",
                    "detection_technique": "ml",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  }
                ]
              }
            ]
          },
          {}
        ],
        schema_version: 1
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it ('should return false for missing pattern name', () => {
      profile = {
        name: '',
        advance_data_patterns_rules: [
          {
            "operators": [],
            "conditions": [
              {
                "operators": [
                  "and_not",
                  "or"
                ],
                "rule_items": [
                  {
                    "id": "1234561",
                    "name": "API Credentials Client ID - Amazon Web Services AWS",
                    "detection_technique": "regex",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234562",
                    "name": "Application credentials",
                    "detection_technique": "ml",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  },
                  {
                    "id": "1234563",
                    "name": "Api access token",
                    "detection_technique": "ml",
                    "occurrence_operator_type": "any",
                    "confidence_level": "high",
                    "supported_confidence_levels": [
                      "high",
                      "low"
                    ],
                    "version": 1
                  }
                ]
              }
            ]
          },
          {}
        ],
        schema_version: 1
      };
      expect(isProfileValid(profile)).toEqual(false);
    });
  })
});