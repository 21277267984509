import React from 'react';

import { fireEvent, render, screen, waitFor, within } from '@testing-library/react';
import '@testing-library/jest-dom';
import DataPatternRules from './DataPatternRules';

describe('<DataPatternRules />', () => {
  let wrapper;
  const v1MixedOpRule = {
    conditions: [
      {
        operators: ['and', 'or_not', 'or'],
        rule_items: [
          {
            id: '12345',
            name: 'CCN',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            supported_confidence_levels: ['high', 'low']
          },
          {
            id: '12344',
            name: 'SSN',
            occurrence_operator_type: 'between',
            occurrence_low: 5,
            occurrence_high: 25,
            confidence_level: 'high',
            supported_confidence_levels: ['high', 'low']
          },
          {
            id: '12343',
            name: 'ABC',
            occurrence_operator_type: 'less_than_equal_to',
            occurrence_count: 12,
            confidence_level: 'low',
            supported_confidence_levels: ['high', 'low']
          },
          {
            id: '12342',
            name: 'Bank',
            occurrence_operator_type: 'more_than_equal_to',
            occurrence_count: 10,
            confidence_level: 'medium',
            supported_confidence_levels: ['high', 'medium', 'low']
          }
        ]
      },
      {
        operators: ['and'],
        rule_items: [
          {
            id: '12342',
            name: 'DEF',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            supported_confidence_levels: ['high', 'low']
          }
        ]
      }
    ],
    operators: ['and']
  };
  const updateRule = jest.fn();
  const props = {
    rule: v1MixedOpRule,
    validatePresence: false,
    ruleIndex: 0,
    updateRule: updateRule,
    dataPatterns: {
      '12345': {
        id: '12345',
        name: 'CCN',
        supported_confidence_levels: ['high', 'low']
      },
      '12344': {
        id: '12344',
        name: 'SSN',
        supported_confidence_levels: ['high', 'low']
      }
    }
  };

  describe('edit v1 setup', () => {
    beforeEach(() => {
      wrapper = render(<DataPatternRules {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.container.querySelectorAll('.dataPatternConditions')).toHaveLength(1);
      expect(wrapper.container.querySelectorAll('.v1Form')).toHaveLength(2);
      expect(wrapper.container.querySelectorAll('.v1OperatorGroup')).toHaveLength(3);
      expect(wrapper.container.querySelectorAll('.condition')).toHaveLength(5);
    });

    it('matches snapshot', () => {
      expect(wrapper.asFragment()).toMatchSnapshot();
    });
  });
});