// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<SftpConfigureModal /> should match snapshot 1`] = `
<IntlProvider
  defaultFormats={Object {}}
  defaultLocale="en"
  fallbackOnEmptyString={true}
  formats={Object {}}
  locale="en"
  messages={Object {}}
  onError={[Function]}
  onWarn={[Function]}
  textComponent={Symbol(react.fragment)}
>
  <Modal
    confirmClose={false}
    isOpen={false}
    onClose={[Function]}
    size="xl"
    style={
      Object {
        "maxWidth": "900px",
        "width": "100%",
      }
    }
  >
    <ModalHeader
      enableClose={true}
      title="Configure Bucket for Evidence Storage"
    />
    <CardBody
      addClassName="dlp-root sftpConfigureModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
    >
      <div
        className="tw-flex modal-body"
        data-testid="testRoot"
      >
        <div
          className="step-list"
        >
          <div
            className="step-item btn-link"
            onClick={[Function]}
          >
            Instructions - SFTP
            <FaCheckCircle
              className="tw-float-right"
            />
          </div>
          <div
            className="step-item null active"
          >
            Input Bucket Details
          </div>
          <div
            className="step-item btn-link"
          >
            Connection Status
          </div>
        </div>
        <div
          className="configBody"
        >
          <div
            className="bucketDetails sftp"
          >
            <h3
              className="modal-section-title"
            >
              Input Bucket Details - SFTP
            </h3>
            <div
              className="helpText"
            >
              Instructions and description of cloud storage bucket configuration process for evidence storage.
            </div>
            <div
              className="helpText"
            >
              <br />
              <FaExclamationTriangle
                className="warning mr5"
                style={
                  Object {
                    "height": "15px",
                    "width": "15px",
                  }
                }
              />
              Please note editing your bucket configuration may cause you to lose access to previously stored files in evidence storage.
            </div>
            <div
              className="errorMessage"
              style={
                Object {
                  "display": "none",
                }
              }
            >
              <FaExclamationCircle
                className="error mr5"
              />
            </div>
            <div
              className="bucketForm"
            >
              <p
                className="inputLabel false"
              >
                Username
                <span
                  className="required-indicator"
                />
              </p>
              <input
                className="form-control username false"
                data-testid="username-input"
                name="username"
                onChange={[Function]}
                placeholder="Example: system"
                value="system"
              />
              <p
                className="inputLabel"
              >
                Private Key
                <span
                  className="required-indicator"
                />
              </p>
              <textarea
                className="form-control privateKey false"
                data-testid="privateKey-input"
                name="privateKey"
                onChange={[Function]}
                placeholder="Example:
-----BEGIN RSA PRIVATE KEY-----
AAAA BBBB CCCC
DDDD EEEE FFFF
XXXX YYYY ZZZZ
-----END RSA PRIVATE KEY-----"
                rows={6}
                value="-----BEGIN RSA PRIVATE KEY-----
*
-----END RSA PRIVATE KEY-----"
              />
              <p
                className="inputLabel"
              >
                PGP Key
              </p>
              <textarea
                className="form-control pgpKey false"
                data-testid="pgpKey-input"
                name="pgpKey"
                onChange={[Function]}
                placeholder="Example:
-----BEGIN PGP PUBLIC KEY BLOCK-----
AAAA BBBB CCCC
DDDD EEEE FFFF
XXXX YYYY ZZZZ
-----END PGP PUBLIC KEY BLOCK-----"
                rows={6}
                value="-----BEGIN PGP PUBLIC KEY BLOCK-----
*
-----END PGP PUBLIC KEY BLOCK-----"
              />
              <p
                className="inputLabel false"
              >
                Hostname
                <span
                  className="required-indicator"
                />
              </p>
              <input
                className="form-control hostname false"
                data-testid="hostname-input"
                name="hostname"
                onChange={[Function]}
                placeholder="Example: 10.0.0.4"
                value="127.0.0.1"
              />
              <p
                className="inputLabel"
              >
                Folder Path
              </p>
              <input
                className="form-control folderPath"
                data-testid="folderPath-input"
                name="folderPath"
                onChange={[Function]}
                placeholder="Example: tmp/storage/evidence"
                value="home/folder"
              />
              <p
                className="inputLabel false"
              >
                Port Number
                <span
                  className="required-indicator"
                />
              </p>
              <input
                className="form-control portNumber false"
                data-testid="portNumber-input"
                name="portNumber"
                onChange={[Function]}
                placeholder="Example: 22"
                value={22}
              />
            </div>
            <ConnectWarning
              service="Sftp"
            />
          </div>
        </div>
      </div>
    </CardBody>
    <ModalFooter>
      <span
        className="tw-flex tw-justify-end tw-space-x-1"
      >
        <ModalCloseButton>
          Cancel
        </ModalCloseButton>
        <Button
          addClassName="prevBtn"
          appearance="secondary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          Previous
        </Button>
        <Button
          addClassName="nextBtn"
          appearance="primary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          Connect
        </Button>
      </span>
    </ModalFooter>
  </Modal>
</IntlProvider>
`;
