/* eslint-disable func-names */

import React from 'react';

import { configure, shallow, mount } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { act } from 'react-dom/test-utils';

import EdmSetup from './EdmSetup';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

const flushPromises = () => new Promise(setImmediate);

const axiosMock = new MockAdapter(axios);

const response = {
  platforms: [
    {
      value: 'linux-64',
      label: 'Linux - 64',
    },
    {
      value: 'linux-64',
      label: 'Linux - 64'
    },
    {
      value: 'macosx-64',
      label: 'Mac OSX - 64'
    },
    {
      value: 'windows-64',
      label: 'Windows - 64'
    },
    {
      value: 'linux-64',
      label: 'Linux - 64'
    }
  ],
  versions: {
    'linux-64': [
      {
        label: '1.0',
        value: '1.0'
      },
      {
        label: '2.0',
        value: '2.0'
      },
      {
        label: '3.0',
        value: '3.0'
      }
    ],
    'windows-64': [
      {
        label: '2.0',
        value: '2.0'
      }
    ],
    'macosx-64': [
      {
        label: '2.0',
        value: '2.0'
      }
    ]
  },
  checksums: {
    'linux-64': {
      '1.0': '',
      '2.0': '2abc123',
      '3.0': '3abc123'
    },
    'macosx-64': {
      '2.0': ''
    },
    'windows-64': {
      '2.0': '222123'
    }
  }
};
axiosMock.onGet('/edm_datasets/cli_options.json').reply(200, response);

describe('<EdmSetup>', () => {
  let wrapper;

  describe('when expanded', () => {
    it('renders', () => {
      wrapper = shallow(<EdmSetup cliOptionsURL='/edm_datasets/cli_options.json' cliDownloadURL='/edm_datasets/cli_download.json'/>);
      expect(wrapper.find('.setupGuide')).toHaveLength(1);
      expect(wrapper.find('.instructions')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });

    it('should be collapsible', () => {
      wrapper = shallow(<EdmSetup cliOptionsURL='/edm_datasets/cli_options.json' cliDownloadURL='/edm_datasets/cli_download.json'/>);
      expect(wrapper.find('.instructions')).toHaveLength(1);
      const upBtn = wrapper.find('.toggleBtn');
      upBtn.simulate('click');
      expect(wrapper.find('.instructions')).toHaveLength(0);
    });
  });

  describe('when expanded and download button clicked', () => {
    it('should call API', async() => {
      delete window.location;
      window.location = {
        assign: jest.fn()
      };
      axiosMock.onGet(/\/edm_datasets\/*/).reply(200, {});
      act(() => {
        wrapper = mount(<EdmSetup cliOptionsURL='/edm_datasets/cli_options.json' cliDownloadURL='/edm_datasets/cli_download.json'/>);
      });

      const cliBtn = wrapper.find('.cliBtn');
      cliBtn.simulate('click');
      const downloadBtn = wrapper.find('.downloadBtn');
      downloadBtn.simulate('click');
      await flushPromises();
      expect(window.location.assign).toHaveBeenCalled();
    });
  });
});
