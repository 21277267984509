import React from 'react';

import {render, fireEvent} from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import AwsConfigureModal from './AwsConfigureModal';

jest.mock('../../helpers/translate');

const axiosMock = new MockAdapter(axios);
axiosMock.onPost().reply(200, {});
axiosMock.onPut().reply(200, {});

describe('<AwsConfigureModal>', () => {
  const props = { environment: 'staging' };

  let testUtils;


  describe('commercial env', () => {
    beforeEach(() => {
      testUtils = render(<AwsConfigureModal {...props}/> );
    });

    test('should render the title and commercial arn', async() => {
      expect(testUtils.getAllByText('settings.configure_modal.instructions')).toHaveLength(2);
      expect(testUtils.getAllByText('settings.configure_modal.instruction_list.step_one')).toHaveLength(1);
      const iamjson = document.getElementById('iamJSON');
      expect(iamjson.value.includes('arn:aws-us-gov:')).toBeFalsy();
      expect(iamjson.value.includes('arn:aws:')).toBeTruthy();
    });

    test('enable kms', async() => {
      const policyjson = document.getElementById('policyJSON');
      expect(policyjson.value.includes('arn:aws:kms:')).toBeFalsy();
      let kmsToggle = document.getElementsByClassName('slider')[0];
      fireEvent.click(kmsToggle);
      expect(policyjson.value.includes('arn:aws:kms:')).toBeTruthy();
    });

    test('should have functioning prev/next buttons', () => {
      expect(testUtils.getAllByText('settings.configure_modal.instructions')).toHaveLength(2);

      let nextBtn = testUtils.getByText('settings.configure_modal.next');
      fireEvent.click(nextBtn);
      expect(testUtils.getByText('settings.configure_modal.bucket_details.bucket_name')).toBeInTheDocument();

      nextBtn = testUtils.getByText('actions.connect');
      fireEvent.click(nextBtn);
      expect(testUtils.getByText('settings.configure_modal.bucket_details.field_required + {"field":"settings.configure_modal.bucket_details.bucket_name"}')).toBeInTheDocument();
      expect(testUtils.getByText('settings.configure_modal.bucket_details.field_required + {"field":"settings.configure_modal.bucket_details.role_arn"}')).toBeInTheDocument();

      let prevBtn = testUtils.getByText('settings.configure_modal.previous');
      fireEvent.click(prevBtn);
      expect(testUtils.getAllByText('settings.configure_modal.instructions')).toHaveLength(2);

      nextBtn = testUtils.getByText('settings.configure_modal.next');
      fireEvent.click(nextBtn);
      expect(testUtils.getByText('settings.configure_modal.bucket_details.bucket_name')).toBeInTheDocument();
    });
  });

  describe('fedramp', () => {
    beforeEach(() => {
      const govProps = {...props, environment: 'gov_production'}
      testUtils = render(<AwsConfigureModal {...govProps}/> );
    });

    test('should render the title and gov arn', async() => {
      expect(testUtils.getAllByText('settings.configure_modal.instructions')).toHaveLength(2);
      const iamjson = document.getElementById('iamJSON');
      expect(iamjson.value.includes('arn:aws-us-gov:')).toBeTruthy();
      expect(iamjson.value.includes('arn:aws:')).toBeFalsy();
    });
  })
});

describe('<AwsConfigureModal> edit', () => {
  const props = {
    edit: true,
    connectionData: {
      bucketName: 'my-bucket',
      roleArn: '123abc',
      region: 'us-east-2',
    },
    initialState: 2,
    environment: 'production'
  };

  let testUtils;
  beforeEach(() => {
    testUtils = render(<AwsConfigureModal {...props}/> );
  });

  it('skips instructions step', () => {
    expect(testUtils.getByText('settings.configure_modal.bucket_details.bucket_name')).toBeInTheDocument();
  });
});
