import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { Redirect } from 'react-router-dom';
import translate from '../../helpers/translate';
import BulkTable from '../BulkTable/BulkTable';
import columns from './columns';
import FilteringProfileDetails from './FilteringProfileDetails';

import { preprocessProfile } from './helper';

import {
  ASC,
  FILTERING_PROFILE_COL_CREATED_AT
} from '../constants';

function t(scope, options) {
  return translate(`data_filtering_profiles.${scope}`, options);
}

class FilteringProfileTable extends React.Component {
  state = {
    data: [],
    pageData: {},
    filters: {
      currentFilters: [],
      currentFilterSelections: {}
    },
    sortBy: FILTERING_PROFILE_COL_CREATED_AT,
    sortOrder: ASC,
    profileNameSearch: '',
  };

  componentDidMount() {
    return this.requestResults(0, 10);
  }

  requestResults = (pageNum, numPerPage) => {
    this.setState({ loading: true });

    const { profileNameSearch, sortBy, sortOrder } = this.state;
    const nameSearch = profileNameSearch || '';
    const sortCriteria = `${sortOrder === ASC ? '' : '-'}${sortBy}`;

    return axios.get(`${this.props.lookupURL}?sort=${sortCriteria}&page=${pageNum}&limit=${numPerPage}&name=${nameSearch}`).then(
      result => {
        const {data} = result;
        const filteringProfiles = data.resources.map(preprocessProfile);
        this.setState(prevState => ({
          data: filteringProfiles,
          filters: {...result.data.filters, currentFilters: prevState.filters.currentFilters, currentFilterSelections: prevState.filters.currentFilterSelections},
          pageData: data.page || {},
          loading: false
        }));
      }
    ).catch(
      () => {
        this.setState({ data: [], loading: false });
      }
    );
  }

  updateState = (newState, requestNewResults = false) => {
    if (newState.viewing) {
      this.setState(prevState => {
        const row = prevState.data.find(i => i.id === newState.viewing);
        return {
          viewing: row
        }
      });
    }
    else {
      this.setState(newState);
    }
    if (requestNewResults) {
      this.requestResults(0, this.state.pageData.size);
    }
  }

  updateFilters = (newFiltersList, newFilterSelections, requestNewResults = false) => {
    const updatedFilters = { currentFilters: newFiltersList, currentFilterSelections: newFilterSelections };
    this.setState(prevState => ({
      filters: { ...prevState.filters, ...updatedFilters }
    }));
    if (requestNewResults) {
      this.requestResults(0, this.state.pageData.size)
    }
  }

  actionable = (profile) => {
    return({
      editable: this.props.userRole === 'READ_WRITE' && profile.profile_type === 'custom',
      cloneable: false,
      deletable: false,
    });
  }

  handleSubmit = (event) => {
    if (event.key === 'Enter') {
      this.requestResults(0, this.state.pageData.size);
    }
  };

  handleUpdateSortControls = ({ sortBy=FILTERING_PROFILE_COL_CREATED_AT, sortOrder=ASC }) => {
    this.setState({
      sortBy,
      sortOrder
    }, () => {
      this.requestResults(0, this.state.pageData.size);
    });
  }

  render() {
    const { data, pageData, loading, filters, editing, sortBy, sortOrder } = this.state;
    const actionable = {
      deletable: () => false,
      editable: (row) => this.props.userRole === 'READ_WRITE' && row.values.profile_type === 'custom'
    };

    if (editing) {
      // editing state contains the profile idß
      return <Redirect to={`./dlp-rules/${editing}`}/>
    }
    return (
      <div className='data-filtering-profiles-root'>
        <div className='reactTable filteringProfileTable'>
          <div className="title card-title">
            <span>
              {t('title')}
              <span className='count'>{` (${pageData.total_elements || 0})`}</span>
            </span>
            <span className='tw-float-right rightInnerAddOn'>
              <input value={this.state.profileNameSearch} onKeyPress={this.handleSubmit} onChange={(event) => this.updateState({ profileNameSearch: event.target.value })}  placeholder={t('search')} className='form-control search' />
              <i><FaSearch /></i>
            </span>
          </div>
          <BulkTable
            data={data}
            defaultSortBy={sortBy}
            defaultSortOrder={sortOrder}
            columns={columns}
            pageData={
              {
                pageCount: pageData.size,
                currentPage: pageData.number,
                totalCount: pageData.total_elements || data.length,
                totalPages: pageData.total_pages
              }
            }
            requestResults={this.requestResults}
            updateState={this.updateState}
            updateFilters={this.updateFilters}
            updateSortControls={this.handleUpdateSortControls}
            selectable={false}
            sortable
            loading={loading}
            filters={filters}
            actionable={actionable}
          />
          {this.state.viewing && <FilteringProfileDetails profile={this.state.viewing} updateState={this.updateState} actionable={this.actionable(this.state.viewing)} />}
        </div>
      </div>
    );
  }
}

FilteringProfileTable.propTypes = {
  lookupURL: PropTypes.string,
  userRole: PropTypes.string
};

FilteringProfileTable.defaultProps = {
  lookupURL: undefined,
  userRole: 'READ_WRITE'
}

export default FilteringProfileTable;
