/* eslint-disable func-names */

import React from 'react';

import { configure, shallow } from 'enzyme';
import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';
import Adapter from 'enzyme-adapter-react-16';
import ServiceStatusTable from './ServiceStatusTable';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<ServiceStatusTable>', () => {
  let wrapper;
  const lookupURL = 'http://localhost/dashboards.json';
  const props = {
    lookupURL,
  };

  describe('with a lookupURL', () => {
    let promise;
    const mock = new MockAdapter(axios);

    describe('with a success response', () => {
      const responseData = {
        telemetry: [
          {
            id: 'fix_san',
            service_name: 'prisma-saas',
            status: 'Operational',
            last_updated_at: '2020-07-09T20:56:14.620Z'
          }
        ]
      };

      it('renders', () => {
        wrapper = shallow(<ServiceStatusTable {...props} />);
        const details = wrapper.instance().telemetryDetails(responseData);
        expect(details[0].name).toEqual('prisma-saas + {\"platform\":true}');
      });
    });

    describe('with a success response for casb portal', () => {
      const responseData = {
        telemetry: [
          {
            id: 'fix_san',
            service_name: 'prisma-saas',
            status: 'Operational',
            last_updated_at: '2020-07-09T20:56:14.620Z'
          }
        ]
      };

      it('renders', () => {
        const casbProps = {...props, portalType: 'casb'};
        wrapper = shallow(<ServiceStatusTable {...casbProps} />);
        const details = wrapper.instance().telemetryDetails(responseData);
        expect(details[0].name).toEqual('dlp');
        expect(wrapper).toMatchSnapshot();
      });
    });

    describe('with an error response', () => {
      beforeEach(() => {
        promise = new Promise(function(resolve, reject) {
          reject(new Error('Request failed with status code 500'));
        });
        mock.onGet(lookupURL).reply(function() {
          return promise;
        });
      });

      it('renders', () => {
        wrapper = shallow(<ServiceStatusTable {...props} />);
        expect(wrapper).toMatchSnapshot();
      });
    });
  });

  it('renders without a fetchUrl', () => {
    wrapper = shallow(<ServiceStatusTable/>);
    expect(wrapper).toMatchSnapshot();
  });
});
