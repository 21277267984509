// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<IncidentsTable> commercial production environment does display region dropdown 1`] = `
<div
  id="incidentsPage"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <span
        className="btn-link"
      >
        <a
          href="/"
        >
          application.dlp
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      incidents.index.title
    </div>
    <div
      className="title"
    >
      incidents.index.title
    </div>
  </div>
  <div
    className="header"
  >
    <DropDownList
      disabled={false}
      dropClass="scanDateSelection"
      items={
        Array [
          Object {
            "label": "incidents.index.scan_dates.hour",
            "value": "past_60_minutes",
          },
          Object {
            "label": "incidents.index.scan_dates.day",
            "value": "past_24_hours",
          },
          Object {
            "label": "incidents.index.scan_dates.week",
            "value": "past_7_days",
          },
          Object {
            "label": "incidents.index.scan_dates.month",
            "value": "past_30_days",
          },
          Object {
            "label": "incidents.index.scan_dates.three_months",
            "value": "past_90_days",
          },
        ]
      }
      labelFreeze={false}
      onSelect={[Function]}
      placeholder="Select an Item"
      preLabel={
        <span
          className="globalFilterLabel"
        >
          <FaCalendar
            className="mr5"
          />
          incidents.index.scan_date
        </span>
      }
      upwards={false}
      value="past_90_days"
    />
    <DropDownList
      disabled={false}
      dropClass="regionSelection"
      items={
        Array [
          Object {
            "label": "incidents.index.dlp_regions.us",
            "value": "us",
          },
          Object {
            "label": "incidents.index.dlp_regions.ap",
            "value": "ap",
          },
          Object {
            "label": "incidents.index.dlp_regions.eu",
            "value": "eu",
          },
          Object {
            "label": "incidents.index.dlp_regions.eu-west-2",
            "value": "eu-west-2",
          },
          Object {
            "label": "incidents.index.dlp_regions.ap-south-1",
            "value": "ap-south-1",
          },
          Object {
            "label": "incidents.index.dlp_regions.ap-southeast-2",
            "value": "ap-southeast-2",
          },
        ]
      }
      labelFreeze={false}
      onSelect={[Function]}
      placeholder="Select an Item"
      preLabel={
        <span
          className="globalFilterLabel"
        >
          <FaGlobeAmericas
            className="mr5"
          />
          incidents.index.region
        </span>
      }
      upwards={false}
      value="us"
    />
  </div>
  <div
    className="aggregation"
  >
    <AggregationCard
      columnLabel="data_profile"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_data_profiles"
      type="dataProfile"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="source"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_sources"
      type="source"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="action"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="files_by_action"
      type="action"
      updateFilters={[Function]}
    />
  </div>
  <div
    className="reactTable incidentsTable"
  >
    <div
      className="title card-title"
    >
      <span>
        incidents.index.title
        <span
          className="count"
        >
          (0)
        </span>
        <span
          className="subText"
        >
          incidents.index.updated_real_time
        </span>
      </span>
      <span
        className="tw-float-right actionItems"
      >
        <DropDownList
          disabled={false}
          dropClass="fileReportFilter"
          items={
            Array [
              Object {
                "label": "incidents.index.columns.file_name",
                "value": "filename",
              },
              Object {
                "label": "incidents.index.columns.report_id",
                "value": "report_id",
              },
              Object {
                "label": "incidents.index.columns.user_id",
                "value": "user_id",
              },
            ]
          }
          labelFreeze={false}
          onSelect={[Function]}
          placeholder="incidents.index.filter_by"
          upwards={false}
          value="filename"
        />
        <span
          className="rightInnerAddOn inputSearch"
        >
          <input
            className="form-control"
            onChange={[Function]}
            onKeyPress={[Function]}
            placeholder="incidents.index.search_by.filename"
          />
          <i>
            <FaSearch />
          </i>
        </span>
      </span>
    </div>
    <BulkTable
      bulkActionsComponent={
        <BulkActions
          assignees={Array []}
          deleteAssignee={[Function]}
          globalStatusLookupURL="/global-statuses?region=us"
          incidentUpdateURL="/incident-update?region=us"
          incidentsUpdated={[Function]}
          saveAssignee={[Function]}
          selectedItems={Array []}
          tenantStatusLookupURL="/tenant-statuses?region=us"
        />
      }
      columns={
        Array [
          Object {
            "Cell": [Function],
            "accessor": "createdAt",
            "header": "incidents.index.columns.created_at",
            "link": false,
          },
          Object {
            "accessor": [Function],
            "disableSortBy": true,
            "header": "incidents.index.columns.assigned_to",
            "id": "assignee",
            "link": false,
          },
          Object {
            "accessor": "filename",
            "header": "incidents.index.columns.file_name",
            "link": true,
          },
          Object {
            "accessor": "dataProfileId",
            "disableSortBy": true,
            "header": "incidents.index.columns.data_profile",
            "link": false,
            "viewable": true,
          },
          Object {
            "accessor": "channel",
            "disableSortBy": true,
            "header": "incidents.index.columns.channel",
            "link": false,
          },
          Object {
            "accessor": "action",
            "disableSortBy": true,
            "header": "incidents.index.columns.action",
            "link": false,
          },
          Object {
            "accessor": "source",
            "disableSortBy": true,
            "header": "incidents.index.columns.source",
            "link": false,
          },
          Object {
            "accessor": "userId",
            "header": "incidents.index.columns.user_id",
            "link": false,
          },
          Object {
            "accessor": "reportId",
            "disableSortBy": true,
            "header": "incidents.index.columns.report_id",
            "link": false,
          },
          Object {
            "accessor": "incidentFeedbackStatus",
            "disableSortBy": true,
            "header": [Function],
            "link": false,
          },
          Object {
            "accessor": "resolutionStatus",
            "disableSortBy": true,
            "header": "incidents.index.columns.resolution",
            "link": false,
          },
          Object {
            "accessor": "incidentNotes",
            "disableSortBy": true,
            "header": "incidents.index.columns.notes",
            "link": false,
          },
        ]
      }
      customCellRender={[Function]}
      data={Array []}
      defaultSortBy="createdAt"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": undefined,
          "pageCount": undefined,
          "totalCount": 0,
          "totalPages": undefined,
        }
      }
      requestResults={[Function]}
      selectable={true}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={
        Array [
          "reportId",
          "incidentFeedbackStatus",
        ]
      }
      sortable={true}
      updateFilters={[Function]}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
</div>
`;

exports[`<IncidentsTable> gov_production environment does not display region dropdown 1`] = `
<div
  id="incidentsPage"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <span
        className="btn-link"
      >
        <a
          href="/"
        >
          application.dlp
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      incidents.index.title
    </div>
    <div
      className="title"
    >
      incidents.index.title
    </div>
  </div>
  <div
    className="header"
  >
    <DropDownList
      disabled={false}
      dropClass="scanDateSelection"
      items={
        Array [
          Object {
            "label": "incidents.index.scan_dates.hour",
            "value": "past_60_minutes",
          },
          Object {
            "label": "incidents.index.scan_dates.day",
            "value": "past_24_hours",
          },
          Object {
            "label": "incidents.index.scan_dates.week",
            "value": "past_7_days",
          },
          Object {
            "label": "incidents.index.scan_dates.month",
            "value": "past_30_days",
          },
          Object {
            "label": "incidents.index.scan_dates.three_months",
            "value": "past_90_days",
          },
        ]
      }
      labelFreeze={false}
      onSelect={[Function]}
      placeholder="Select an Item"
      preLabel={
        <span
          className="globalFilterLabel"
        >
          <FaCalendar
            className="mr5"
          />
          incidents.index.scan_date
        </span>
      }
      upwards={false}
      value="past_90_days"
    />
  </div>
  <div
    className="aggregation"
  >
    <AggregationCard
      columnLabel="data_profile"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_data_profiles"
      type="dataProfile"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="source"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_sources"
      type="source"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="action"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="files_by_action"
      type="action"
      updateFilters={[Function]}
    />
  </div>
  <div
    className="reactTable incidentsTable"
  >
    <div
      className="title card-title"
    >
      <span>
        incidents.index.title
        <span
          className="count"
        >
          (0)
        </span>
        <span
          className="subText"
        >
          incidents.index.updated_real_time
        </span>
      </span>
      <span
        className="tw-float-right actionItems"
      >
        <DropDownList
          disabled={false}
          dropClass="fileReportFilter"
          items={
            Array [
              Object {
                "label": "incidents.index.columns.file_name",
                "value": "filename",
              },
              Object {
                "label": "incidents.index.columns.report_id",
                "value": "report_id",
              },
              Object {
                "label": "incidents.index.columns.user_id",
                "value": "user_id",
              },
            ]
          }
          labelFreeze={false}
          onSelect={[Function]}
          placeholder="incidents.index.filter_by"
          upwards={false}
          value="filename"
        />
        <span
          className="rightInnerAddOn inputSearch"
        >
          <input
            className="form-control"
            onChange={[Function]}
            onKeyPress={[Function]}
            placeholder="incidents.index.search_by.filename"
          />
          <i>
            <FaSearch />
          </i>
        </span>
      </span>
    </div>
    <BulkTable
      bulkActionsComponent={
        <BulkActions
          assignees={Array []}
          deleteAssignee={[Function]}
          globalStatusLookupURL="/global-statuses?region=us"
          incidentUpdateURL="/incident-update?region=us"
          incidentsUpdated={[Function]}
          saveAssignee={[Function]}
          selectedItems={Array []}
          tenantStatusLookupURL="/tenant-statuses?region=us"
        />
      }
      columns={
        Array [
          Object {
            "Cell": [Function],
            "accessor": "createdAt",
            "header": "incidents.index.columns.created_at",
            "link": false,
          },
          Object {
            "accessor": [Function],
            "disableSortBy": true,
            "header": "incidents.index.columns.assigned_to",
            "id": "assignee",
            "link": false,
          },
          Object {
            "accessor": "filename",
            "header": "incidents.index.columns.file_name",
            "link": true,
          },
          Object {
            "accessor": "dataProfileId",
            "disableSortBy": true,
            "header": "incidents.index.columns.data_profile",
            "link": false,
            "viewable": true,
          },
          Object {
            "accessor": "channel",
            "disableSortBy": true,
            "header": "incidents.index.columns.channel",
            "link": false,
          },
          Object {
            "accessor": "action",
            "disableSortBy": true,
            "header": "incidents.index.columns.action",
            "link": false,
          },
          Object {
            "accessor": "source",
            "disableSortBy": true,
            "header": "incidents.index.columns.source",
            "link": false,
          },
          Object {
            "accessor": "userId",
            "header": "incidents.index.columns.user_id",
            "link": false,
          },
          Object {
            "accessor": "reportId",
            "disableSortBy": true,
            "header": "incidents.index.columns.report_id",
            "link": false,
          },
          Object {
            "accessor": "incidentFeedbackStatus",
            "disableSortBy": true,
            "header": [Function],
            "link": false,
          },
          Object {
            "accessor": "resolutionStatus",
            "disableSortBy": true,
            "header": "incidents.index.columns.resolution",
            "link": false,
          },
          Object {
            "accessor": "incidentNotes",
            "disableSortBy": true,
            "header": "incidents.index.columns.notes",
            "link": false,
          },
        ]
      }
      customCellRender={[Function]}
      data={Array []}
      defaultSortBy="createdAt"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": undefined,
          "pageCount": undefined,
          "totalCount": 0,
          "totalPages": undefined,
        }
      }
      requestResults={[Function]}
      selectable={true}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={
        Array [
          "reportId",
          "incidentFeedbackStatus",
        ]
      }
      sortable={true}
      updateFilters={[Function]}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
</div>
`;

exports[`<IncidentsTable> renders bulk table 1`] = `
<div
  id="incidentsPage"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <span
        className="btn-link"
      >
        <a
          href="/"
        >
          application.dlp
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      incidents.index.title
    </div>
    <div
      className="title"
    >
      incidents.index.title
    </div>
  </div>
  <div
    className="header"
  >
    <img
      alt="loading"
      id="loader"
    />
  </div>
  <div
    className="aggregation"
  >
    <AggregationCard
      columnLabel="data_profile"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_data_profiles"
      type="dataProfile"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="source"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_sources"
      type="source"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="action"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="files_by_action"
      type="action"
      updateFilters={[Function]}
    />
  </div>
  <div
    className="reactTable incidentsTable"
  >
    <div
      className="title card-title"
    >
      <span>
        incidents.index.title
        <span
          className="count"
        >
          (5)
        </span>
        <span
          className="subText"
        >
          incidents.index.updated_real_time
        </span>
      </span>
      <span
        className="tw-float-right actionItems"
      >
        <DropDownList
          disabled={false}
          dropClass="fileReportFilter"
          items={
            Array [
              Object {
                "label": "incidents.index.columns.file_name",
                "value": "filename",
              },
              Object {
                "label": "incidents.index.columns.report_id",
                "value": "report_id",
              },
              Object {
                "label": "incidents.index.columns.user_id",
                "value": "user_id",
              },
            ]
          }
          labelFreeze={false}
          onSelect={[Function]}
          placeholder="incidents.index.filter_by"
          upwards={false}
          value="filename"
        />
        <span
          className="rightInnerAddOn inputSearch"
        >
          <input
            className="form-control"
            onChange={[Function]}
            onKeyPress={[Function]}
            placeholder="incidents.index.search_by.filename"
          />
          <i>
            <FaSearch />
          </i>
        </span>
      </span>
    </div>
    <BulkTable
      bulkActionsComponent={
        <BulkActions
          assignees={Array []}
          deleteAssignee={[Function]}
          globalStatusLookupURL="/global-statuses?region=us"
          incidentUpdateURL="/incident-update?region=us"
          incidentsUpdated={[Function]}
          saveAssignee={[Function]}
          selectedItems={Array []}
          tenantStatusLookupURL="/tenant-statuses?region=us"
        />
      }
      columns={
        Array [
          Object {
            "Cell": [Function],
            "accessor": "createdAt",
            "header": "incidents.index.columns.created_at",
            "link": false,
          },
          Object {
            "accessor": [Function],
            "disableSortBy": true,
            "header": "incidents.index.columns.assigned_to",
            "id": "assignee",
            "link": false,
          },
          Object {
            "accessor": "filename",
            "header": "incidents.index.columns.file_name",
            "link": true,
          },
          Object {
            "accessor": "dataProfileId",
            "disableSortBy": true,
            "header": "incidents.index.columns.data_profile",
            "link": false,
            "viewable": true,
          },
          Object {
            "accessor": "channel",
            "disableSortBy": true,
            "header": "incidents.index.columns.channel",
            "link": false,
          },
          Object {
            "accessor": "action",
            "disableSortBy": true,
            "header": "incidents.index.columns.action",
            "link": false,
          },
          Object {
            "accessor": "source",
            "disableSortBy": true,
            "header": "incidents.index.columns.source",
            "link": false,
          },
          Object {
            "accessor": "userId",
            "header": "incidents.index.columns.user_id",
            "link": false,
          },
          Object {
            "accessor": "reportId",
            "disableSortBy": true,
            "header": "incidents.index.columns.report_id",
            "link": false,
          },
          Object {
            "accessor": "incidentFeedbackStatus",
            "disableSortBy": true,
            "header": [Function],
            "link": false,
          },
          Object {
            "accessor": "resolutionStatus",
            "disableSortBy": true,
            "header": "incidents.index.columns.resolution",
            "link": false,
          },
          Object {
            "accessor": "incidentNotes",
            "disableSortBy": true,
            "header": "incidents.index.columns.notes",
            "link": false,
          },
        ]
      }
      customCellRender={[Function]}
      data={
        Array [
          Object {
            "action": "quarantine",
            "channel": "Prisma Access",
            "data_profile": "Profile A",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset A",
            "id": "111",
            "link": "/incidents/111",
            "report_id": "12345",
            "source": "Enterprise DLP App",
          },
          Object {
            "action": "block",
            "channel": "SaaS Security",
            "data_profile": "Profile B",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset B",
            "id": "112",
            "link": "/incidents/112",
            "report_id": "12346",
            "source": "Prisma Access",
          },
          Object {
            "action": "quarantine",
            "channel": "Prisma Access",
            "data_profile": "Profile C",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset C",
            "id": "113",
            "link": "/incidents/113",
            "report_id": "12347",
            "source": "Panorama",
          },
          Object {
            "action": "alert",
            "channel": "SaaS Security",
            "data_profile": "Profile D",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset D",
            "id": "114",
            "link": "/incidents/114",
            "report_id": "12348",
            "source": "SaaS Security",
          },
          Object {
            "action": "block",
            "channel": "Panorama",
            "data_profile": "Profile E",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset E",
            "id": "115",
            "link": "/incidents/115",
            "report_id": "12349",
            "source": "Enterprise DLP App",
          },
        ]
      }
      defaultSortBy="createdAt"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": 1,
          "pageCount": 10,
          "totalCount": 5,
          "totalPages": 1,
        }
      }
      requestResults={[Function]}
      selectable={true}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={
        Array [
          "reportId",
          "incidentFeedbackStatus",
        ]
      }
      sortable={true}
      updateFilters={[Function]}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
</div>
`;

exports[`<IncidentsTable> renders bulk table for read only user 1`] = `
<div
  id="incidentsPage"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <span
        className="btn-link"
      >
        <a
          href="/"
        >
          application.dlp
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      incidents.index.title
    </div>
    <div
      className="title"
    >
      incidents.index.title
    </div>
  </div>
  <div
    className="header"
  >
    <img
      alt="loading"
      id="loader"
    />
  </div>
  <div
    className="aggregation"
  >
    <AggregationCard
      columnLabel="data_profile"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_data_profiles"
      type="dataProfile"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="source"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_sources"
      type="source"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="action"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="files_by_action"
      type="action"
      updateFilters={[Function]}
    />
  </div>
  <div
    className="reactTable incidentsTable"
  >
    <div
      className="title card-title"
    >
      <span>
        incidents.index.title
        <span
          className="count"
        >
          (5)
        </span>
        <span
          className="subText"
        >
          incidents.index.updated_real_time
        </span>
      </span>
      <span
        className="tw-float-right actionItems"
      >
        <DropDownList
          disabled={false}
          dropClass="fileReportFilter"
          items={
            Array [
              Object {
                "label": "incidents.index.columns.file_name",
                "value": "filename",
              },
              Object {
                "label": "incidents.index.columns.report_id",
                "value": "report_id",
              },
              Object {
                "label": "incidents.index.columns.user_id",
                "value": "user_id",
              },
            ]
          }
          labelFreeze={false}
          onSelect={[Function]}
          placeholder="incidents.index.filter_by"
          upwards={false}
          value="filename"
        />
        <span
          className="rightInnerAddOn inputSearch"
        >
          <input
            className="form-control"
            onChange={[Function]}
            onKeyPress={[Function]}
            placeholder="incidents.index.search_by.filename"
          />
          <i>
            <FaSearch />
          </i>
        </span>
      </span>
    </div>
    <BulkTable
      bulkActionsComponent={<React.Fragment />}
      columns={
        Array [
          Object {
            "Cell": [Function],
            "accessor": "createdAt",
            "header": "incidents.index.columns.created_at",
            "link": false,
          },
          Object {
            "accessor": [Function],
            "disableSortBy": true,
            "header": "incidents.index.columns.assigned_to",
            "id": "assignee",
            "link": false,
          },
          Object {
            "accessor": "filename",
            "header": "incidents.index.columns.file_name",
            "link": true,
          },
          Object {
            "accessor": "dataProfileId",
            "disableSortBy": true,
            "header": "incidents.index.columns.data_profile",
            "link": false,
            "viewable": true,
          },
          Object {
            "accessor": "channel",
            "disableSortBy": true,
            "header": "incidents.index.columns.channel",
            "link": false,
          },
          Object {
            "accessor": "action",
            "disableSortBy": true,
            "header": "incidents.index.columns.action",
            "link": false,
          },
          Object {
            "accessor": "source",
            "disableSortBy": true,
            "header": "incidents.index.columns.source",
            "link": false,
          },
          Object {
            "accessor": "userId",
            "header": "incidents.index.columns.user_id",
            "link": false,
          },
          Object {
            "accessor": "reportId",
            "disableSortBy": true,
            "header": "incidents.index.columns.report_id",
            "link": false,
          },
          Object {
            "accessor": "incidentFeedbackStatus",
            "disableSortBy": true,
            "header": [Function],
            "link": false,
          },
          Object {
            "accessor": "resolutionStatus",
            "disableSortBy": true,
            "header": "incidents.index.columns.resolution",
            "link": false,
          },
          Object {
            "accessor": "incidentNotes",
            "disableSortBy": true,
            "header": "incidents.index.columns.notes",
            "link": false,
          },
        ]
      }
      customCellRender={[Function]}
      data={
        Array [
          Object {
            "action": "quarantine",
            "channel": "Prisma Access",
            "data_profile": "Profile A",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset A",
            "id": "111",
            "link": "/incidents/111",
            "report_id": "12345",
            "source": "Enterprise DLP App",
          },
          Object {
            "action": "block",
            "channel": "SaaS Security",
            "data_profile": "Profile B",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset B",
            "id": "112",
            "link": "/incidents/112",
            "report_id": "12346",
            "source": "Prisma Access",
          },
          Object {
            "action": "quarantine",
            "channel": "Prisma Access",
            "data_profile": "Profile C",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset C",
            "id": "113",
            "link": "/incidents/113",
            "report_id": "12347",
            "source": "Panorama",
          },
          Object {
            "action": "alert",
            "channel": "SaaS Security",
            "data_profile": "Profile D",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset D",
            "id": "114",
            "link": "/incidents/114",
            "report_id": "12348",
            "source": "SaaS Security",
          },
          Object {
            "action": "block",
            "channel": "Panorama",
            "data_profile": "Profile E",
            "detection_time": "Mar 6 2021 at 1:00",
            "file_name": "Asset E",
            "id": "115",
            "link": "/incidents/115",
            "report_id": "12349",
            "source": "Enterprise DLP App",
          },
        ]
      }
      defaultSortBy="createdAt"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": 1,
          "pageCount": 10,
          "totalCount": 5,
          "totalPages": 1,
        }
      }
      requestResults={[Function]}
      selectable={true}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={
        Array [
          "reportId",
          "incidentFeedbackStatus",
        ]
      }
      sortable={true}
      updateFilters={[Function]}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
</div>
`;

exports[`<IncidentsTable> renders bulk table with no data 1`] = `
<div
  id="incidentsPage"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <span
        className="btn-link"
      >
        <a
          href="/"
        >
          application.dlp
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      incidents.index.title
    </div>
    <div
      className="title"
    >
      incidents.index.title
    </div>
  </div>
  <div
    className="header"
  >
    <img
      alt="loading"
      id="loader"
    />
  </div>
  <div
    className="aggregation"
  >
    <AggregationCard
      columnLabel="data_profile"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_data_profiles"
      type="dataProfile"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="source"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_sources"
      type="source"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="action"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="files_by_action"
      type="action"
      updateFilters={[Function]}
    />
  </div>
  <div
    className="reactTable incidentsTable"
  >
    <div
      className="title card-title"
    >
      <span>
        incidents.index.title
        <span
          className="count"
        >
          (0)
        </span>
        <span
          className="subText"
        >
          incidents.index.updated_real_time
        </span>
      </span>
      <span
        className="tw-float-right actionItems"
      >
        <DropDownList
          disabled={false}
          dropClass="fileReportFilter"
          items={
            Array [
              Object {
                "label": "incidents.index.columns.file_name",
                "value": "filename",
              },
              Object {
                "label": "incidents.index.columns.report_id",
                "value": "report_id",
              },
              Object {
                "label": "incidents.index.columns.user_id",
                "value": "user_id",
              },
            ]
          }
          labelFreeze={false}
          onSelect={[Function]}
          placeholder="incidents.index.filter_by"
          upwards={false}
          value="filename"
        />
        <span
          className="rightInnerAddOn inputSearch"
        >
          <input
            className="form-control"
            onChange={[Function]}
            onKeyPress={[Function]}
            placeholder="incidents.index.search_by.filename"
          />
          <i>
            <FaSearch />
          </i>
        </span>
      </span>
    </div>
    <BulkTable
      bulkActionsComponent={
        <BulkActions
          assignees={Array []}
          deleteAssignee={[Function]}
          globalStatusLookupURL="/global-statuses?region=us"
          incidentUpdateURL="/incident-update?region=us"
          incidentsUpdated={[Function]}
          saveAssignee={[Function]}
          selectedItems={Array []}
          tenantStatusLookupURL="/tenant-statuses?region=us"
        />
      }
      columns={
        Array [
          Object {
            "Cell": [Function],
            "accessor": "createdAt",
            "header": "incidents.index.columns.created_at",
            "link": false,
          },
          Object {
            "accessor": [Function],
            "disableSortBy": true,
            "header": "incidents.index.columns.assigned_to",
            "id": "assignee",
            "link": false,
          },
          Object {
            "accessor": "filename",
            "header": "incidents.index.columns.file_name",
            "link": true,
          },
          Object {
            "accessor": "dataProfileId",
            "disableSortBy": true,
            "header": "incidents.index.columns.data_profile",
            "link": false,
            "viewable": true,
          },
          Object {
            "accessor": "channel",
            "disableSortBy": true,
            "header": "incidents.index.columns.channel",
            "link": false,
          },
          Object {
            "accessor": "action",
            "disableSortBy": true,
            "header": "incidents.index.columns.action",
            "link": false,
          },
          Object {
            "accessor": "source",
            "disableSortBy": true,
            "header": "incidents.index.columns.source",
            "link": false,
          },
          Object {
            "accessor": "userId",
            "header": "incidents.index.columns.user_id",
            "link": false,
          },
          Object {
            "accessor": "reportId",
            "disableSortBy": true,
            "header": "incidents.index.columns.report_id",
            "link": false,
          },
          Object {
            "accessor": "incidentFeedbackStatus",
            "disableSortBy": true,
            "header": [Function],
            "link": false,
          },
          Object {
            "accessor": "resolutionStatus",
            "disableSortBy": true,
            "header": "incidents.index.columns.resolution",
            "link": false,
          },
          Object {
            "accessor": "incidentNotes",
            "disableSortBy": true,
            "header": "incidents.index.columns.notes",
            "link": false,
          },
        ]
      }
      customCellRender={[Function]}
      data={Array []}
      defaultSortBy="createdAt"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": undefined,
          "pageCount": undefined,
          "totalCount": 0,
          "totalPages": undefined,
        }
      }
      requestResults={[Function]}
      selectable={true}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={
        Array [
          "reportId",
          "incidentFeedbackStatus",
        ]
      }
      sortable={true}
      updateFilters={[Function]}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
</div>
`;

exports[`<IncidentsTable> updateState() should open view portal for data profiles 1`] = `
<div
  id="incidentsPage"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <span
        className="btn-link"
      >
        <a
          href="/"
        >
          application.dlp
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      incidents.index.title
    </div>
    <div
      className="title"
    >
      incidents.index.title
    </div>
  </div>
  <div
    className="header"
  >
    <img
      alt="loading"
      id="loader"
    />
  </div>
  <div
    className="aggregation"
  >
    <AggregationCard
      columnLabel="data_profile"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_data_profiles"
      type="dataProfile"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="source"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="top_sources"
      type="source"
      updateFilters={[Function]}
    />
    <AggregationCard
      columnLabel="action"
      data={Array []}
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      timestamp=""
      title="files_by_action"
      type="action"
      updateFilters={[Function]}
    />
  </div>
  <div
    className="reactTable incidentsTable"
  >
    <div
      className="title card-title"
    >
      <span>
        incidents.index.title
        <span
          className="count"
        >
          (0)
        </span>
        <span
          className="subText"
        >
          incidents.index.updated_real_time
        </span>
      </span>
      <span
        className="tw-float-right actionItems"
      >
        <DropDownList
          disabled={false}
          dropClass="fileReportFilter"
          items={
            Array [
              Object {
                "label": "incidents.index.columns.file_name",
                "value": "filename",
              },
              Object {
                "label": "incidents.index.columns.report_id",
                "value": "report_id",
              },
              Object {
                "label": "incidents.index.columns.user_id",
                "value": "user_id",
              },
            ]
          }
          labelFreeze={false}
          onSelect={[Function]}
          placeholder="incidents.index.filter_by"
          upwards={false}
          value="filename"
        />
        <span
          className="rightInnerAddOn inputSearch"
        >
          <input
            className="form-control"
            onChange={[Function]}
            onKeyPress={[Function]}
            placeholder="incidents.index.search_by.filename"
          />
          <i>
            <FaSearch />
          </i>
        </span>
      </span>
    </div>
    <BulkTable
      bulkActionsComponent={
        <BulkActions
          assignees={Array []}
          deleteAssignee={[Function]}
          globalStatusLookupURL="/global-statuses?region=us"
          incidentUpdateURL="/incident-update?region=us"
          incidentsUpdated={[Function]}
          saveAssignee={[Function]}
          selectedItems={Array []}
          tenantStatusLookupURL="/tenant-statuses?region=us"
        />
      }
      columns={
        Array [
          Object {
            "Cell": [Function],
            "accessor": "createdAt",
            "header": "incidents.index.columns.created_at",
            "link": false,
          },
          Object {
            "accessor": [Function],
            "disableSortBy": true,
            "header": "incidents.index.columns.assigned_to",
            "id": "assignee",
            "link": false,
          },
          Object {
            "accessor": "filename",
            "header": "incidents.index.columns.file_name",
            "link": true,
          },
          Object {
            "accessor": "dataProfileId",
            "disableSortBy": true,
            "header": "incidents.index.columns.data_profile",
            "link": false,
            "viewable": true,
          },
          Object {
            "accessor": "channel",
            "disableSortBy": true,
            "header": "incidents.index.columns.channel",
            "link": false,
          },
          Object {
            "accessor": "action",
            "disableSortBy": true,
            "header": "incidents.index.columns.action",
            "link": false,
          },
          Object {
            "accessor": "source",
            "disableSortBy": true,
            "header": "incidents.index.columns.source",
            "link": false,
          },
          Object {
            "accessor": "userId",
            "header": "incidents.index.columns.user_id",
            "link": false,
          },
          Object {
            "accessor": "reportId",
            "disableSortBy": true,
            "header": "incidents.index.columns.report_id",
            "link": false,
          },
          Object {
            "accessor": "incidentFeedbackStatus",
            "disableSortBy": true,
            "header": [Function],
            "link": false,
          },
          Object {
            "accessor": "resolutionStatus",
            "disableSortBy": true,
            "header": "incidents.index.columns.resolution",
            "link": false,
          },
          Object {
            "accessor": "incidentNotes",
            "disableSortBy": true,
            "header": "incidents.index.columns.notes",
            "link": false,
          },
        ]
      }
      customCellRender={[Function]}
      data={
        Array [
          Object {
            "dataProfile": Object {
              "id": "1111",
              "name": "Profile A",
            },
            "filename": "Incident A",
            "id": "12345",
          },
        ]
      }
      defaultSortBy="createdAt"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": undefined,
          "pageCount": undefined,
          "totalCount": 1,
          "totalPages": undefined,
        }
      }
      requestResults={[Function]}
      selectable={true}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={
        Array [
          "reportId",
          "incidentFeedbackStatus",
        ]
      }
      sortable={true}
      updateFilters={[Function]}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
    <div
      className="data_profiles"
    >
      <DataProfileDetails
        history={null}
        profile={
          Object {
            "id": "1111",
            "name": "Profile A",
          }
        }
        updateState={[Function]}
      />
    </div>
  </div>
</div>
`;
