import _get from 'lodash.get';
import axios from 'axios';
import $ from 'jquery';
import toastr from '../../../helpers/toastr';
import translate from '../../../helpers/translate';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}


export const returnToIndex = (history, dataProfileId) => {
  if (history) {
    const path = dataProfileId ? '../../data-profiles' : '../data-profiles';
    history.push(path);
  } else {
    window.location.assign('/data_profiles/');
  }
}

async function submitRequest(url, method, dataProfile) {
  try {
    await axios({
      url,
      method,
      data: { dataProfile }
    });
    return '200';
  } catch (error) {
    const errorMessage = _get(error, 'response.data.message') || _get(error, 'response.data.body.message');
    return errorMessage;
  }
}

function processResult(result, props, dataProfile, method) {
  if (result === '200') {
    if (method === 'create') {
      toastr.success(translate('data_profiles.alerts.create_success', {name: dataProfile.name}));
    }
    else {
      toastr.success(translate('data_profiles.alerts.update_success', {name: dataProfile.name}));
    }
    return returnToIndex(props.history, method !== 'create');
  }
  if (result && (result.includes('Duplicate data profile name') || result.includes('duplicate key error'))) {
    $('button:contains("Save")').prop('disabled', false);
    return t('duplicate_name_error');
  }
  if (result && (result.includes('name must start with'))) {
    $('button:contains("Save")').prop('disabled', false);
    return result;
  }
  toastr.error(result || translate('errors.server_error'));
  return returnToIndex(props.history, props.dataProfileId);
}

export const saveProfile = async(dataProfile, props) => {
  $('button:contains("Save")').prop('disabled', true);
  const url = `${props.createURL}${props.apiPostfix}`;
  const result = await submitRequest(url, 'POST', dataProfile);
  return processResult(result, props, dataProfile, 'create');
};

export const updateProfile = async(dataProfile, props) => {
  $('button:contains("Save")').prop('disabled', true);
  const url = `${props.updateURL}/${props.dataProfile.id}${props.apiPostfix}`;
  const result = await submitRequest(url, 'PUT', dataProfile);
  return processResult(result, props, dataProfile, 'update');
};
