import translate from '../../../helpers/translate';

export default [
  {
    value: 'data_pattern',
    label: translate('data_profiles.form.rule_type.data_pattern'),
  },
  {
    value: 'edm_dataset',
    label: translate('data_profiles.form.rule_type.edm_dataset'),
  },
];
