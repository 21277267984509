import translate from '../../../helpers/translate';

export default [
  {
    value: 'and',
    label: translate('data_profiles.form.rule_operators.and'),
  },
  {
    value: 'or',
    label: translate('data_profiles.form.rule_operators.or'),
  },
  {
    value: 'and_not',
    label: translate('data_profiles.form.rule_operators.and_not'),
  },
  {
    value: 'or_not',
    label: translate('data_profiles.form.rule_operators.or_not'),
  },
];
