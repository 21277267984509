export const isProfileValid = profile => {
  if (!profile || Object.keys(profile).length === 0) {
    return false;
  }
  if (!profile.name || (!profile.advance_data_patterns_rules || Object.keys(profile.advance_data_patterns_rules[0]).length === 0)) {
    return false;
  }

  // check data patterns valid
  let dataPatternsValid = true;
  if (profile.advance_data_patterns_rules) {
    profile.advance_data_patterns_rules.forEach(rule => {
      (rule.conditions || []).forEach(condition => {
        condition.rule_items.forEach(ruleItem => {
          if (!ruleItem.id || !ruleItem.occurrence_operator_type || !ruleItem.confidence_level) {
            dataPatternsValid = false;
          }
          if (ruleItem.occurrence_high && ruleItem.occurrence_high > 500) {
            dataPatternsValid = false;
          }
          if (ruleItem.occurrence_count && ruleItem.occurrence_count > 500) {
            dataPatternsValid = false;
          }
          if (ruleItem.occurrence_low && ruleItem.occurrence_high && ruleItem.occurrence_low >= ruleItem.occurrence_high) {
            dataPatternsValid = false;
          }
          if (ruleItem.occurrence_operator_type === 'between') {
            if (!ruleItem.occurrence_low || !ruleItem.occurrence_high) {
              dataPatternsValid = false;
            }
          }
          else if (ruleItem.occurrence_operator_type !== 'any' && !ruleItem.occurrence_count) {
            dataPatternsValid = false;
          }
        })
      })
    })
  }

  return dataPatternsValid;
};

export default profile => isProfileValid(profile);