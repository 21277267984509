import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import { FaArrowDown, FaTimes, FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`incidents.index.aggregations.${scope}`, options);
}

const moment = require('moment-timezone');

const AggregationCard = ({ type, title, columnLabel, data, updateFilters, filters, timestamp }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const addFilter = (name, id) => {
    let newFilterSelection = filters.currentFilterSelections[type];
    if (newFilterSelection && newFilterSelection.filter(s => s.id === id).length === 0) {
      newFilterSelection.push({ name, id });
    }
    else {
      newFilterSelection = [{ name, id }];
    }
    const updatedSelection = {};
    updatedSelection[type] = newFilterSelection;
    const newFilters = filters.currentFilters;
    if (newFilters.filter(c => c.id === type).length === 0) {
      newFilters.push({ name: t(columnLabel), id: type, multiple: true});
    }
    const newFilterSelections = { ...filters.currentFilterSelections, ...updatedSelection };

    updateFilters(newFilters, newFilterSelections, true);
  }

  const removeFilter = (id) => {
    const newFilterSelection = filters.currentFilterSelections[type];
    const item = newFilterSelection.filter(s => s.id === id)[0];
    newFilterSelection.splice(newFilterSelection.indexOf(item), 1);

    const newFilters = filters.currentFilters;
    if (newFilterSelection.length === 0) {
      newFilters.splice(newFilters.indexOf({ name: t(columnLabel), id: type, multiple: true}), 1);
    }

    const updatedSelection = {};
    updatedSelection[type] = newFilterSelection;
    const newFilterSelections = { ...filters.currentFilterSelections, ...updatedSelection };
    updateFilters(newFilters, newFilterSelections, true);
  }

  const isRowActive = (row) => {
    return filters.currentFilterSelections[type] && filters.currentFilterSelections[type].filter(s => s.id === row.itemId).length > 0
  }

  return (
    <div className='aggregationCard'>
      <div className='reactTable'>
        <div className="title card-title">
          <span>
            {t(title)}
          </span>
          <span className='info'>
            { data && data.length > 0  &&
              <span>
                <FaInfoCircle className='muteText small'
                  data-tip={t('info', { date: moment.utc(timestamp).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z') })}
                  data-class='aggregation-tooltip' data-effect='solid'/>
                <ReactTooltip/>
              </span>
            }
          </span>
        </div>
        <table className='table table-borderless table-hover'>
          <thead>
            <tr>
              <th>{t(columnLabel)}</th>
              <th>
                {t('sensitive_files')}
                <FaArrowDown />
              </th>
              <th>{t('incidents')}</th>
            </tr>
          </thead>
          <tbody>
            { data && data.map(row =>
              <tr className={isRowActive(row) ? 'selected' : ''} key={row.item}>
                <td>{row.item}</td>
                <td>{row.sensitiveFileCount}</td>
                <td>
                  <>
                    { row.itemId !== undefined ?
                      <Popup trigger={<span className='btn-link' onClick={() => addFilter(row.item, row.itemId)}>{row.incidentCount}</span>} position='top center' on='hover'>
                        {t('filter_by', {name: row.item})}
                      </Popup>
                      :
                      row.incidentCount
                    }
                    { isRowActive(row) && <FaTimes className='removeAggFilter' onClick={() => removeFilter(row.itemId) } /> }
                  </>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

AggregationCard.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  columnLabel: PropTypes.string.isRequired,
  data: PropTypes.array,
  updateFilters: PropTypes.func,
  filters: PropTypes.shape({
    filterable: PropTypes.bool,
    filterOptions: PropTypes.array,
    currentFilters: PropTypes.array,
    currentFilterSelections: PropTypes.shape({})
  }),
  timestamp: PropTypes.string
};

AggregationCard.defaultProps = {
  data: [],
  updateFilters: null,
  filters: {
    filterable: false,
    filterOptions: [],
    currentFilters: [],
    currentFilterSelections: {},
  },
  timestamp: ''
}

export default AggregationCard;




