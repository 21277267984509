/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  LoadingButton,
  Input,
  Radio,
  RadioGroup,
  Checkbox
} from '@panwds/react-ui';

import translate from '../../../helpers/translate';
import toastWrapper from '../../../helpers/toastr';
import SpinnerIcon from '../../../../assets/images/spinner.gif';
import {
  ALLOW,
  BLOCK,
  FILE_SETTINGS_TYPE
} from '../../constants';

const t = (scope, options) => translate(`data_transfer.file_based.${scope}`, options);

const FileBasedView = (props) => {
  const [loading, setLoading] = useState(props.loading);
  const [maxLatency, setMaxLatency] = useState(props.maxLatency);
  const [actionMaxLatency, setActionMaxLatency] = useState(props.actionMaxLatency);
  const [maxSize, setMaxSize] = useState(props.maxSize);
  const [actionMaxSize, setActionMaxSize] = useState(props.actionMaxSize);
  const [allowLogFiles, setAllowLogFiles] = useState(props.allowLogFiles);
  const [saveButtonStatus, setSaveButtonStatus] = useState({ loading: false });

  useEffect(() => {
    setLoading(props.loading);
    setMaxLatency(props.maxLatency);
    setActionMaxLatency(props.actionMaxLatency);
    setMaxSize(props.maxSize);
    setActionMaxSize(props.actionMaxSize);
    setAllowLogFiles(props.allowLogFiles);
  }, [props]);

  const saveData = async () => {
    setSaveButtonStatus({ loading: true });

    try{
      const { data } = await axios.put('/v1/dlp-ui/data-filtering-profile/setting', {
        type: FILE_SETTINGS_TYPE,
        file_max_latency: maxLatency,
        file_action_on_max_latency: actionMaxLatency,
        file_max_size: maxSize,
        file_action_on_max_size: actionMaxSize,
        file_log_files_not_scanned: allowLogFiles,
      });

      if (data) {
        props.updateSettings({
          maxLatency: data.file_max_latency,
          actionMaxLatency: data.file_action_on_max_latency,
          maxSize: data.file_max_size,
          actionMaxSize: data.file_action_on_max_size,
          allowLogFiles: data.file_log_files_not_scanned
        });
      }

      toastWrapper.success(t('save_success'));
    } catch (error) {
      toastWrapper.error(t('server_error'));
    } finally {
      setSaveButtonStatus({ loading: false });
    }
  };

  const isFormDataValid = () => (
    Number.isInteger(Number(maxLatency)) &&
    Number.isInteger(Number(maxSize)) &&
    (maxLatency > 0 && maxLatency < 241) &&
    (maxSize > 0 && maxSize < 101)
  );

  const isFormUpdated = () => (
    (Number(maxLatency) !== props.maxLatency) ||
    (actionMaxLatency !== props.actionMaxLatency) ||
    (Number(maxSize) !== props.maxSize) ||
    (actionMaxSize !== props.actionMaxSize) ||
    (allowLogFiles !== props.allowLogFiles)
  );

  const isSaveEnabled = () => (
    isFormDataValid() && isFormUpdated()
  );

  return (
    <div className='settings-container file-settings'>
      { loading &&
        <div className='empty text-center'>
          <img id='loader' src={SpinnerIcon} alt='loading' />
          <br /><br />
          <p>{t('loading')}</p>
        </div>
      }

      { !loading &&
        <>
          <div className='header-section'>
            <h5>{t('title')}</h5>
            <div className='help-text'>{t('description')}</div>
          </div>

          <div className='data-section'>
            <div className='left-col'>
              <div className='text-label'>{t('max_latency')}</div>
              <div className='text-label action-latency'>{t('max_latency_action')}</div>
              <div className='text-label max-file'>{t('max_file_size')}</div>
              <div className='text-label action-max'>{t('max_file_size_action')}</div>
            </div>

            <div className='right-col'>
              <Input
                value={maxLatency}
                disabled={props.userRole !== 'READ_WRITE'}
                addClassName='max-latency-input'
                onChange={(e) => {
                  setMaxLatency(e.currentTarget.value);
                }}
              />
              <div className='text-data max-latency-placeholder'>{t('max_latency_desc')}</div>

              <RadioGroup
                name='actionMaxLatency'
                value={actionMaxLatency}
                horizontal
                className='radio-horizontal'
                onChange={(event) => {
                  setActionMaxLatency(event.target.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              >
                <Radio value={ALLOW}>{t('allow')}</Radio>
                <Radio value={BLOCK}>{t('block')}</Radio>
              </RadioGroup>

              <Input
                value={maxSize}
                addClassName='max-size-input'
                onChange={(e) => {
                  setMaxSize(e.currentTarget.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              />
              <div className='text-data max-size-placeholder'>{t('max_file_size_desc')}</div>

              <RadioGroup
                name='actionMaxSize'
                value={actionMaxSize}
                horizontal
                className='radio-horizontal'
                onChange={(event) => {
                  setActionMaxSize(event.target.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              >
                <Radio value={ALLOW}>{t('allow')}</Radio>
                <Radio value={BLOCK}>{t('block')}</Radio>
              </RadioGroup>

              <div className='checkbox-wrapper'>
                <Checkbox
                  checked={allowLogFiles}
                  onChange={() => setAllowLogFiles(!allowLogFiles)}
                  disabled={props.userRole !== 'READ_WRITE'}
                >
                  {t('log_files_not_scanned')}
                </Checkbox>
              </div>
            </div>
          </div>

          <div className='footer-section'>
            {props.userRole === 'READ_WRITE' &&
              <LoadingButton
                dataMetrics='loading-primary-button'
                onClickCallback={saveData}
                dataResult={saveButtonStatus}
                appearance='primary'
                disabled={!isSaveEnabled()}
              >
                {t('save')}
              </LoadingButton>
            }
          </div>
        </>
      }
    </div>
  );
}

FileBasedView.propTypes = {
  loading: PropTypes.bool.isRequired,
  maxLatency: PropTypes.number.isRequired,
  actionMaxLatency: PropTypes.string.isRequired,
  maxSize: PropTypes.number.isRequired,
  actionMaxSize: PropTypes.string.isRequired,
  allowLogFiles: PropTypes.bool.isRequired,
  updateSettings: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired
}

export default FileBasedView;