import {regexArrayToString, regexStringToArray} from './regexTransform';

describe('regexArrayToString', () => {
  it('should return correct string', () => {
    const str = regexArrayToString([
      {
        regex: 'abcd',
        weight: 1
      }
    ], 'regex');
    expect(str).toEqual('abcd');
  });

  it('should return correct string with weight and delimiter', () => {
    const str = regexArrayToString([
      {
        regex: 'abcd',
        weight: 3
      }
    ], 'weighted-regex', ';');
    expect(str).toEqual('abcd;3');
  });

  it('should return correct string with negative weight and delimiter', () => {
    const str = regexArrayToString([
      {
        regex: 'abcd',
        weight: -3
      }
    ], 'weighted-regex', ';');
    expect(str).toEqual('abcd;-3');
  });
});

describe('regexStringToArray', () => {
  it('should return correct array', () => {
    const array = regexStringToArray('password', 'regex');
    expect(array).toEqual([
      {
        regex: 'password',
        weight: 1
      }
    ]);
  });

  it('should return array with correct weight', () => {
    const array = regexStringToArray('password;3', 'weighted-regex', ';');
    expect(array).toEqual([
      {
        regex: 'password',
        weight: 3
      }
    ]);
  });
});