import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { FaFileDownload } from 'react-icons/fa';
import { fileSize } from 'humanize-plus';
import { Button } from '@panwds/react-ui';
import $ from 'jquery';
import translate from '../../helpers/translate';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const moment = require('moment-timezone');

function t(scope, options) {
  return translate(`reports.index.${scope}`, options);
}

export function download(report) {
  $('.downloadIcon').removeClass('btn-link');
  $('.downloadIcon').addClass('disabled');
  axios.get(`/file/${report.report_id}.json?originalFileName=${report.file_name}&service-name=${report.channel}`)
    .then(
      (response) => {
        $('.downloadIcon').addClass('btn-link');
        $('.downloadIcon').removeClass('disabled');
        if (response.status === 200 && !response.data.hasError) {
          window.location.assign(response.data.additionalDetails.URL);
          $('#flash-messages').html(
            `<div class="alert alert-floating alert-dismissible fade show" role="alert">
              <span class="fa fa-check-circle success-icon mr5"></span>
              <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
              ${t('download_success')}
            </div>`)
          setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
        }
        else {
          $('#flash-messages').html(
            `<div class="alert alert-floating alert-dismissible fade show" role="alert">
              <span class="fa fa-times error mr5"></span>
              <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
              ${response.data.errorMessage || t('download_failure')}
            </div>`)
          setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
        }
      }
    )
}

const ReportCard = ({ report, showDetails, userRole }) => {
  return (
    <div className='reportDetails tw-float-left'>
      <div className='card-title reportHeader'>
        { showDetails ? `${t('report')} ${report.report_id}` : t('general')}
        { showDetails && <Button onClick={ () => showDetails(report) } addClassName='tw-float-right showDetailsBtn'>{t('show_details')}</Button> }
      </div>
      <div className='details'>
        <table className='table table-borderless table-condensed'>
          <tbody>
            <tr>
              <td className='detailCategory'>{t('report_id')}</td>
              <td>{report.report_id}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('scan_date')}</td>
              <td>{moment.utc(report.detection_time).tz(timezone).format('MMMM D YYYY [at] h:mm z')}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('data_profile')}</td>
              <td>{report.data_profile_name}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('asset')}</td>
              <td>
                <span>{report.file_name}</span>
                { userRole === 'READ_WRITE' && (report.data_pattern_rule_1_verdict.toLowerCase() === 'matched' || report.data_pattern_rule_2_verdict.toLowerCase() === 'matched') &&
                  <FaFileDownload onClick={() => download(report)} className='btn-link downloadIcon' />
                }
              </td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('asset_type')}</td>
              <td>{report.file_type}</td>
            </tr>
            <tr>
              <td className='detailCategory'>{t('asset_size')}</td>
              <td>{fileSize(report.file_size_in_bytes)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

ReportCard.propTypes = {
  report: PropTypes.shape({
    report_id: PropTypes.string,
    file_name: PropTypes.string,
    asset_id: PropTypes.string,
    data_profile_name: PropTypes.string,
    data_pattern_rule_1_verdict: PropTypes.string,
    data_pattern_rule_2_verdict: PropTypes.string,
    channel: PropTypes.string,
    detection_time: PropTypes.string,
    file_type: PropTypes.string,
    file_size_in_bytes: PropTypes.number,
  }),
  showDetails: PropTypes.func,
  userRole: PropTypes.string,
};

ReportCard.defaultProps = {
  report: {},
  showDetails: undefined,
  userRole: 'READ_ONLY'
};

export default ReportCard;