import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import PreviewToggle from './PreviewToggle';

configure({ adapter: new Adapter() });

describe('<PreviewToggle />', () => {
  let wrapper;

  describe('bracket preview', () => {
    beforeEach(() => {
      const props = {
        previewType: 'bracket',
        updateState: () => {}
      };
      wrapper = shallow(<PreviewToggle {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.find('.bracket.selected')).toHaveLength(1);
      expect(wrapper.find('.detail.unselected')).toHaveLength(1);
    });

    it('matches snapshot', () => {
      expect(wrapper).toMatchSnapshot();
    });
  });

  describe('detail preview', () => {
    beforeEach(() => {
      const props = {
        previewType: 'detail',
        updateState: () => {}
      };
      wrapper = shallow(<PreviewToggle {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.find('.bracket.unselected')).toHaveLength(1);
      expect(wrapper.find('.detail.selected')).toHaveLength(1);
    });

    it('matches snapshot', () => {
      expect(wrapper).toMatchSnapshot();
    });
  });
});