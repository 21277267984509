import React from 'react';

import { configure, shallow } from 'enzyme';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import Adapter from 'enzyme-adapter-react-16';
import DataProfileFormV1 from './DataProfileFormV1';

configure({ adapter: new Adapter() });

const flushPromises = () => new Promise(setImmediate);

describe('<DataProfileFormV1>', () => {
  let wrapper;
  const mock = new MockAdapter(axios);
  const existingV1AdvProfile = {
    name: 'existing profile',
    type: 'advanced',
    id: '12345',
    profile_type: 'custom',
    advance_data_patterns_rules: [
      {
        "operators": [
          "and"
        ],
        "conditions": [
          {
            "operators": [
              "and"
            ],
            "rule_items": [
              {
                "id": "1234560",
                "name": "SSN",
                "occurrence_operator_type": "any",
                "occurrence_count": null,
                "confidence_level": "high",
                "supported_confidence_levels": ["high","low"],
                "version": 1
              },
              {
                "id": "1234563",
                "name": "Pannier",
                "occurrence_operator_type": "any",
                "occurrence_count": null,
                "confidence_level": "high",
                "supported_confidence_levels": ["high","low"],
                "version": 1
              }
            ]
          },
          {
            "operators": [
              "or"
            ],
            "rule_items": [
              {
                "id": "1234561",
                "occurrence_operator_type": "any",
                "occurrence_count": null,
                "confidence_level": "high",
                "name": "Passport - US",
                "supported_confidence_levels": ["high","medium","low"],
                "version": 1
              },
              {
                "id": "1234562",
                "name": "Bank - Statements",
                "occurrence_operator_type": "any",
                "occurrence_count": null,
                "confidence_level": "high",
                "supported_confidence_levels": ["high","medium","low"],
                "version": 1
              }
            ]
          }
        ]
      }, {}
    ],
    schema_version: 1
  };
  const history = {
    push: jest.fn()
  };
  const dataPatterns = {
    1234560: { name: 'SSN' },
    1234561: { name: 'Passport - US' },
    1234562: { name: 'Bank - Statements' },
    1234563: { name: 'CCN' }
  };
  const props = {
    history,
    dataPatterns
  };
  const updateProps = {
    dataProfile: existingV1AdvProfile,
    dataPatterns
  };

  describe('create form', () => {
    beforeEach(() => {
      wrapper = shallow(<DataProfileFormV1 {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.find('.dataProfileForm')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });

    it('renders definition preview', () => {
      wrapper.setState({ name: 'Test Profile', advance_data_patterns_rules: [
        {
          "operators": [
            "and"
          ],
          "conditions": [
            {
              "operators": [
                "and"
              ],
              "rule_items": [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1
                },
                {
                  "id": "1234563",
                  "name": "Pannier",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1
                }
              ]
            },
            {
              "operators": [
                "or"
              ],
              "rule_items": [
                {
                  "id": "1234561",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "name": "Passport - US",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1
                },
                {
                  "id": "1234562",
                  "name": "Bank - Statements",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1
                }
              ]
            }
          ]
        }, {}
      ]});
      expect(wrapper).toMatchSnapshot();
    });

    describe('save()', () => {
      it('should call API with valid profile', async() => {
        wrapper.setState({
          name: 'test-profile',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ]
        });
        mock.onPost().reply(200, {});
        wrapper.instance().save();

        await flushPromises();
      });

      it('should set nameError', async() => {
        wrapper.setState({
          name: 'test-profile',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ]
        });
        mock.onPost().reply(500, {
          message: 'Duplicate data profile name'
        });
        wrapper.instance().save();

        await flushPromises();
        expect(wrapper.instance().state['nameError']).toBeDefined();
      });

      it('should set correct state if profile is invalid', () => {
        jest.mock('./profileValidatorV1', () => ({
          isProfileValid: jest.fn(() => false)
        }));

        wrapper.instance().save();
        expect(wrapper.state('validatePresence')).toEqual(true);
      });
    });

    describe('componentDidMount()', () => {
      it('leaves default profile data', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper.state('name')).toEqual('');
        expect(wrapper.state('advance_data_patetrns_rules')).toEqual(undefined);
      });

      it ('matches snapshot', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper).toMatchSnapshot();
      });
    });

    describe('isAdvancedProfile()', () => {
      it('should return true', () => {
        wrapper.setState({
          advance_data_patterns_rules: [
            {
              operators: ['and', 'or']
            },
            {
              operators: ['and', 'or']
            }
          ]
        });
        expect(wrapper.instance().isAdvancedProfile()).toBe(true);
      });

      it('should return true when creating a data profile', () => {
        wrapper.setState({
          advance_data_patterns_rules: [
            {
              operators: ['and']
            },
            {
              operators: ['and']
            }
          ]
        });
        const comp = wrapper.instance();
        comp.dataProfile = null;
        expect(comp.isAdvancedProfile()).toBe(true);
      });

      it('should return true', () => {
        wrapper.setState({
          advance_data_patterns_rules: [
            {
              operators: ['and'],
              conditions: [
                {
                  operators: ['or']
                },
                {
                  operators: ['or']
                }
              ]
            },
            {

            }
          ]
        });
        const comp = wrapper.instance();
        comp.dataProfile = {};
        expect(comp.isAdvancedProfile()).toBe(true);
      });

      it('should return true', () => {
        wrapper.setState({
          advance_data_patterns_rules: [
            {
              operators: ['and'],
              conditions: [
                {
                  operators: ['or']
                },
                {
                  operators: ['or']
                }
              ]
            },
            {
              operators: ['and'],
              conditions: [
                {
                  operators: ['or']
                },
                {
                  operators: ['or']
                }
              ]
            }
          ]
        });
        const comp = wrapper.instance();
        comp.dataProfile = {};
        expect(comp.isAdvancedProfile()).toBe(true);
      });

      it('should return false for basic profile with matching operators', () => {
        wrapper.setState({
          advance_data_patterns_rules: [
            {
              operators: ['or'],
              conditions: [
                {
                  operators: ['or']
                }
              ]
            },
            {}
          ]
        });
        const comp = wrapper.instance();
        comp.dataProfile = {};
        expect(comp.isAdvancedProfile()).toBe(false);
      });

      it('should return false for basic profile with not operators', () => {
        wrapper.setState({
          advance_data_patterns_rules: [
            {
              "operators": [
                  "or_not"
              ],
              "conditions": [
                {
                  "operators": [
                      "or",
                      "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "name": "123 pattern name",
                      "detection_technique": "regex",
                      "match_type": "include",
                      "occurrence_operator_type": "any",
                      "confidence_level": "high",
                      "supported_confidence_levels": [
                          "high",
                          "low"
                      ],
                      "version": 1
                    },
                    {
                      "id": "1234560",
                      "name": "Bank - American Bankers Association Routing Number - ABA",
                      "detection_technique": "regex",
                      "match_type": "include",
                      "occurrence_operator_type": "any",
                      "confidence_level": "high",
                      "supported_confidence_levels": [
                          "high",
                          "low"
                      ],
                      "version": 3
                    }
                  ]
                },
                {
                  "operators": [],
                  "rule_items": [
                    {
                        "id": "abcdef",
                        "name": "15_digit",
                        "detection_technique": "regex",
                        "match_type": "exclude",
                        "occurrence_operator_type": "any",
                        "confidence_level": "high",
                        "supported_confidence_levels": [
                            "high",
                            "low"
                        ],
                        "version": 1
                    }
                  ]
                }
              ]
            },
            {}
          ]
        });
        const comp = wrapper.instance();
        comp.dataProfile = {};
        expect(comp.isAdvancedProfile()).toBe(false);
      });
    });
  })

  describe('update form', () => {
    beforeEach(() => {
      wrapper = shallow(<DataProfileFormV1 {...updateProps}/>);
    });

    describe('componentDidMount()', () => {
      it('sets profile data', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper.state('name')).toEqual('existing profile');
        expect(wrapper.state('advance_data_patetrns_rules')).toEqual(undefined);
      });

      it ('matches snapshot', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper).toMatchSnapshot();
      });

    });

    describe('save()', () => {
      it('should call update API with valid profile', async() => {
        wrapper.setState({
          name: 'test-profile',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ]
        });
        mock.onPut().reply(200, {});

        wrapper.instance().save();

        await flushPromises();
      });

      it('should show warning for converting basic profile', () => {
        jest.mock('./profileValidatorV1', () => ({
          isProfileValid: () => true
        }));
        const comp = wrapper.instance();
        jest.spyOn(comp, 'isAdvancedProfile').mockReturnValue(true);
        comp.dataProfile = {
          type: 'Basic'
        };
        comp.save(false);
        expect(wrapper.state('showAdvConvertWarning')).toEqual(true);
      });

      it('should set nameError', async() => {
        wrapper.setState({
          name: 'test-profile',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ]
        });
        mock.onPut().reply(500, {
          message: 'Duplicate data profile name'
        });
        window.scrollTo = jest.fn();
        wrapper.instance().save();

        await flushPromises();
        expect(wrapper.instance().state['nameError']).toBeDefined();
      });

      it('should call update API with valid profile and persist advanced type', async() => {
        mock.reset();
        wrapper = shallow(<DataProfileFormV1 {...updateProps} />);
        wrapper.setState({
          name: 'test-profile',
          advance_data_patterns_rules: [{
            operators: ['and'],
            conditions: [
              {
                operators: ['and'],
                rule_items: [{
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "between",
                  "detection_technique": "regex",
                  "match_type": "include",
                  "occurrence_count": undefined,
                  "occurrence_low": 4,
                  "occurrence_high": 24,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1
                }]
              }
            ]
          }, {}]
        });
        mock.onPut().reply(200, {});

        wrapper.instance().save();
        expect(JSON.parse(mock.history.put[0].data)).toEqual(expect.objectContaining({
          dataProfile: expect.objectContaining({
            advance_data_patterns_rule_request: ["(SSN high between 4 24)"]
          })
        }));
      });

      it('should set correct state if profile is invalid', () => {
        wrapper.setState({name: ''});

        wrapper.instance().save();
        expect(wrapper.state('validatePresence')).toEqual(true);
      });
    });

    describe('addRule()', () => {
      it('should add data pattern', () => {
        const comp = wrapper.instance();
        expect(comp.state['advance_data_patterns_rules'][1]).toEqual({});
        comp.addRule('data_pattern', 'secondary');
        expect(comp.state['advance_data_patterns_rules'][1].conditions).toBeDefined();
      });
    });

    it('updateRule should update advance_data_patterns_rules', () => {
      const comp = wrapper.instance();
      comp.updateRule({
        operators: ['or']
      }, 0);
      expect(comp.state['advance_data_patterns_rules'][0].operators).toEqual(['or']);
    });

    it('handleNameChange() should update name', () => {
      const comp = wrapper.instance();
      comp.handleNameChange({
        target: {
          value: 'abc'
        }
      });
      expect(comp.state.name).toEqual('abc');
    });

    it('handleChange() should update state', () => {
      const comp = wrapper.instance();
      comp.handleChange({
        hybridPrimaryMatchType: 'all'
      });
      expect(comp.state.hybridPrimaryMatchType).toEqual('all');
    });
  });
});