import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from '@panwds/react-ui';
import translate from '../../helpers/translate';
import DataProfileDetailPreview from '../DataProfiles/DataProfileDetailPreview';
import { profileDetails } from '../DataProfiles/profileDataTransformer';
import DataPatternInfo from '../DataPatterns/DataPatternInfo';
import FilteringProfileDetailsInfo from '../DataFilteringProfiles/FilteringProfileDetailsInfo';
import { preprocessProfile } from '../DataFilteringProfiles/helper';

function t(scope, options) {
  return translate(`audit_logs.${scope}`, options);
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AuditLogDetails = (props) => {
  const { auditLog, updateState, datasets, patterns, fawkes } = props;
  let beforeObj = null;
  let afterObj = null;
  if (auditLog.changes.old || auditLog.changes.new) {
    if (auditLog.type?.toLowerCase() === 'dataprofile') {
      beforeObj = profileDetails(JSON.parse(auditLog.changes.old), patterns, datasets, false);
      afterObj = profileDetails(JSON.parse(auditLog.changes.new), patterns, datasets, false);
    }
    else if (auditLog.type?.toLowerCase() === 'datapattern') {
      beforeObj = JSON.parse(auditLog.changes.old);
      afterObj = JSON.parse(auditLog.changes.new);
    }
    else if (auditLog.type?.toLowerCase() === 'datafilteringprofile') {
      beforeObj = preprocessProfile(JSON.parse(auditLog.changes.old));
      afterObj = preprocessProfile(JSON.parse(auditLog.changes.new));
    }
  }

  const objDiff = (change, date=false) => {
    if (!change) { return 'None'; }
    const details = change.match(/\(([^)]+)\)/);
    if (!details || details.length === 0) {
      if (date) {
        return moment(new Date(change)).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z');
      }
      return change;
    }
    const list = [];
    const displayFields = ['name', 'occurrenceCount', 'occurrenceHigh', 'occurrenceLow', 'occurrenceType', 'confidenceLevel'];
    details[0].split(', ').forEach(field => {
      const f = field.split('=');
      if (displayFields.includes(f[0]) && f[1] !== 'null') {
        if (list.length > 0) {
          list.push(<br />);
        }
        list.push(<b>{`${f[0].replace(/([A-Z])/g, ' $1').replace(/^./, (str) => { return str.toUpperCase(); })}`}</b>);
        list.push(`: ${f[1]}`);
      }
    });
    return list;
  }

  const diffFieldName = (field = '') => {
   return field.split('.')[field.split('.').length - 1].replace('/', ' ').replace(/([A-Z])/g, ' $1').replace(/^./, (str) => { return str.toUpperCase(); });
  }

  return (
    <div className='tableDetailsView auditLogDetails'>
      <div className='detailHeader'>
        <span className='detailTitle'>{t('log_details')}</span>
        <Button addClassName='close tw-float-right' data-dismiss='modal' aria-hidden='true' onClick={ () => updateState({viewing: null}) } />
        <br/><br/>
        <span>{t(`columns.${auditLog.type?.toLowerCase()}`)} {t('columns.update')}</span>
        {Array.isArray(auditLog.changes) && <div className='helpText'>{t('diff_too_large')}</div>}
      </div>


      <div className='changeDetails'>
        {Array.isArray(auditLog.changes) &&
          <table className='table table-condensed diffTable'>
            <thead>
              <tr>
                <th className='fieldDiff'>{t('field')}</th>
                <th className='oldDiff'>{t('before')}</th>
                <th className='newDiff'>{t('after')}</th>
              </tr>
            </thead>
            <tbody>
              {auditLog.changes.filter(c => { return !c.field.includes('id')}).map( (change, index) => {
                return(
                  <tr key={index}>
                    <td>{diffFieldName(change.field)}</td>
                    <td>{objDiff(change.old, change.field.includes('updatedAt'))}</td>
                    <td>{objDiff(change.new, change.field.includes('updatedAt'))}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        }
        {!Array.isArray(auditLog.changes) &&
          <table className='table table-borderless table-condensed changeTable'>
            <thead>
              <tr>
                <th className='old'>{t('before')}</th>
                <th className='new'>{t('after')}</th>
              </tr>
            </thead>
            <tbody>
              {auditLog.type?.toLowerCase() === 'dataprofile' && (beforeObj || afterObj) &&
                <tr>
                  <td>
                    <span className={beforeObj?.name === afterObj?.name ? '' : 'before'}>{beforeObj?.name}</span>
                  </td>
                  <td>
                    <span className={beforeObj?.name === afterObj?.name ? '' : 'after'}>{afterObj?.name}</span>
                  </td>
                </tr>
              }
              {auditLog.type?.toLowerCase() === 'dataprofile' && (beforeObj || afterObj) &&
                <tr className='data_profiles'>
                  <td className='preview'>
                    <DataProfileDetailPreview
                      dataPatternRules={beforeObj.advance_data_patterns_rules}
                      schema={beforeObj.schema_version}
                      primaryExpTree={beforeObj.primaryExpTree}
                      secondaryExpTree={beforeObj.secondaryExpTree}
                      change='before'
                      changedDataPatternRules={afterObj.advance_data_patterns_rules}
                      datasets={Object.values(datasets)}
                      changedPrimaryExpTree={afterObj.primaryExpTree}
                      changedSecondaryExpTree={afterObj.secondaryExpTree}
                      primaryProfiles={beforeObj.selectedPrimaryProfiles}
                      secondaryProfiles={beforeObj.selectedSecondaryProfiles}
                      changedPrimaryProfiles={(afterObj.selectedPrimaryProfiles || []).map(p => p.value)}
                      changedSecondaryProfiles={(afterObj.selectedSecondaryProfiles || []).map(p => p.value)}
                      multi={beforeObj.multiProfile}
                    />
                  </td>
                  <td className='preview'>
                    <DataProfileDetailPreview
                      dataPatternRules={afterObj.advance_data_patterns_rules}
                      schema={afterObj.schema_version}
                      primaryExpTree={afterObj.primaryExpTree}
                      secondaryExpTree={afterObj.secondaryExpTree}
                      change='after'
                      changedDataPatternRules={beforeObj.advance_data_patterns_rules}
                      datasets={Object.values(datasets)}
                      changedPrimaryExpTree={beforeObj.primaryExpTree}
                      changedSecondaryExpTree={beforeObj.secondaryExpTree}
                      primaryProfiles={afterObj.selectedPrimaryProfiles}
                      secondaryProfiles={afterObj.selectedSecondaryProfiles}
                      changedPrimaryProfiles={(beforeObj.selectedPrimaryProfiles || []).map(p => p.value)}
                      changedSecondaryProfiles={(beforeObj.selectedSecondaryProfiles || []).map(p => p.value)}
                      multi={afterObj.multiProfile}
                    />
                  </td>
                </tr>
              }
              {auditLog.type?.toLowerCase() === 'datapattern' && (beforeObj || afterObj) &&
                <tr className='data-patterns-root'>
                  <td className='dataPatternTable'>
                    <DataPatternInfo pattern={beforeObj} changedObj={afterObj} change='before' />
                  </td>
                  <td className='dataPatternTable'>
                    <DataPatternInfo pattern={afterObj} changedObj={beforeObj} change='after' />
                  </td>
                </tr>
              }
              {auditLog.type?.toLowerCase() === 'datafilteringprofile' && (beforeObj || afterObj) &&
                <tr className='filteringProfiles'>
                  <td>
                    <FilteringProfileDetailsInfo profile={beforeObj} fawkes={fawkes} changedObj={afterObj} change='before' />
                  </td>
                  <td>
                    <FilteringProfileDetailsInfo profile={afterObj} fawkes={fawkes} changedObj={beforeObj} change='after' />
                  </td>
                </tr>
              }
              { (beforeObj || afterObj) &&
                <tr style={{borderTop: '1px solid #DADBDB'}}>
                  <td>
                    {t('updated_by')}
                    <span className={beforeObj.updated_by === afterObj.updated_by ? '' : 'before'}>{beforeObj.updated_by}</span>
                  </td>
                  <td>
                    {t('updated_by')}
                    <span className={beforeObj.updated_by === afterObj.updated_by ? '' : 'after'}>{afterObj.updated_by}</span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
    </div>
  )
}

AuditLogDetails.propTypes = {
  auditLog: PropTypes.shape({
    auditId: PropTypes.string,
    createdAt: PropTypes.string,
    userId: PropTypes.string,
    clientName: PropTypes.string,
    dataId: PropTypes.string,
    changes: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.shape({
        old: PropTypes.string,
        new: PropTypes.string
      }
    )]),
    name: PropTypes.string,
    type: PropTypes.string
  }),
  updateState: PropTypes.func.isRequired,
  datasets: PropTypes.shape({}),
  patterns: PropTypes.shape({}),
  fawkes: PropTypes.bool,
};

AuditLogDetails.defaultProps = {
  auditLog: {},
  datasets: {},
  patterns: {},
  fawkes: false,
}

export default AuditLogDetails;
