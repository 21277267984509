import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import { flatten } from 'flat';
import { i18nextPlugin } from 'translation-check'

const defaultLng = "en";

const translations = require('./en.json');
const flattened = flatten(translations);
const i18nextOptions = {
  fallbackLng: defaultLng,  
  resources: {
    en: {
      translation: flattened
    }
  }
};
const newInstance = i18next.createInstance();
newInstance.use(LanguageDetector).use(i18nextPlugin).init(i18nextOptions);

export default newInstance;