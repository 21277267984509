// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataTransfer /> should match snapshot 1`] = `
<div
  className="data-transfer"
  data-testid="testRoot"
>
  <FileBasedView
    actionMaxLatency=""
    actionMaxSize=""
    allowLogFiles={false}
    loading={true}
    maxLatency={0}
    maxSize={0}
    updateSettings={[Function]}
    userRole="READ_WRITE"
  />
  <NonFileBasedView
    actionMaxLatencyNonFile=""
    actionMaxSizeNonFile=""
    allowLogData={false}
    enableNonFile={false}
    loading={true}
    maxLatency={0}
    maxSize={0}
    minSize={0}
    updateSettings={[Function]}
    userRole="READ_WRITE"
  />
  <DlpView
    actionOnError=""
    loading={true}
    updateSettings={[Function]}
    userRole="READ_WRITE"
  />
</div>
`;
