import React from 'react';
import { IntlProvider } from 'react-intl';
import '@testing-library/jest-dom/extend-expect';
import { act } from 'react-dom/test-utils';
import { mount } from 'enzyme';
import sinon from 'sinon';
import { expect as chaiExpect } from 'chai';

import RuleItemDocumentType from './RuleItemDocumentType';
import { FaTimes } from 'react-icons/fa';
import IdentifierDropdown from './IdentifierDropdown';

describe('<RuleItemDocumentType />', () => {
  let sandbox, wrapper;
  const baseProps = {
    documentsList: [],
    onDeleteRuleItem: undefined,
    onUpdateRuleItem: undefined,
    position: '0',
    ruleItem: {},
    shouldValidatePresence: false
  };
  const mockView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return mount(
      <IntlProvider locale="en">
        <RuleItemDocumentType {...props} />
      </IntlProvider>
    );
  };

  beforeEach(() => {
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    sandbox.restore();
    jest.resetAllMocks();
  });

  it('should match snapshot', () => {
    wrapper = mockView();

    expect(wrapper).toMatchSnapshot();
  });

  it('should render', async () => {
    const stubOnDeleteRuleItem = sinon.stub();
    const stubOnUpdateRuleItem = sinon.stub();

    wrapper = mockView({
      onDeleteRuleItem: stubOnDeleteRuleItem,
      onUpdateRuleItem: stubOnUpdateRuleItem,
      position: '666',
    });

    await act(async () => {
      wrapper.find(FaTimes).at(0).simulate('click');
    });

    chaiExpect(stubOnDeleteRuleItem.calledWith('666')).to.be.true;

    await act(async () => {
      wrapper.find(IdentifierDropdown).invoke('onSelect')({
        occurrence_operator_type: 'more_than_equal_to'
      });
    });

    chaiExpect(stubOnUpdateRuleItem.calledWith('666', {
      occurrence_count: 50,
      occurrence_operator_type: 'more_than_equal_to'
    })).to.be.true;

    await act(async () => {
      wrapper.find(IdentifierDropdown).invoke('onSelect')({
        occurrence_operator_type: 'between'
      });
    });

    chaiExpect(stubOnUpdateRuleItem.calledWith('666', {
      occurrence_low: 50,
      occurrence_high: 100,
      occurrence_operator_type: 'between'
    })).to.be.true;

    await act(async () => {
      wrapper.find(IdentifierDropdown).invoke('onSelect')({
        occurrence_operator_type: 'default'
      });
    });

    chaiExpect(stubOnUpdateRuleItem.calledWith('666', {
      occurrence_low: 50,
      occurrence_high: 100,
      occurrence_operator_type: 'between'
    })).to.be.true;
  });
});