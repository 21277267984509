import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import IncidentDetails from './IncidentDetails';
import ReportCard from './ReportCard';
import MatchingDataPatterns from './MatchingDataPatterns';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

const axiosMock = new MockAdapter(axios);
const flushPromises = () => new Promise(setImmediate);

describe('<IncidentDetails> with data patterns', () => {
  let wrapper;
  const incident = {
    incidentId: '12345',
    filename: 'Test Asset',
    userRole: 'READ_WRITE',
    asset_id: '987654',
    dataProfile: 'Bank Account Number North American',
    channel: 'Prisma Access',
    scanDate: '2020-07-09T20:56:14.620Z',
    data_pattern_results: [
      { 'data_pattern_id': 'pattern1', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
      { 'data_pattern_id': 'pattern2', 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 },
      { 'data_pattern_id': 'pattern3', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
    ]
  };
  const expHighMatches = {
    'pattern2': { 'data_pattern_id': 'pattern2', 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 }
  };
  const expMedMatches = {
    'pattern1': { 'data_pattern_id': 'pattern1', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
    'pattern3': { 'data_pattern_id': 'pattern3', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
  };
  const expLowMatches = {
    'pattern1': { 'data_pattern_id': 'pattern1', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
    'pattern2': { 'data_pattern_id': 'pattern2', 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 },
    'pattern3': { 'data_pattern_id': 'pattern3', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
  };
  const patternProps = {
    incidentId: '12345',
    highMatches: expHighMatches,
    medMatches: expMedMatches,
    lowMatches: expLowMatches,
    confidence: 'hcf',
  };
  const feedbackHistory = [
    {
        "incidentId": '12345',
        "incidentFeedbackStatus": "Confirmed sensitive",
        "incidentFeedbackDetails": "Response status changed to \"Confirmed Sensitive\"",
        "createdAt": "2022-Feb-14 21:51:25 UTC"
    },
    {
        "incidentId": '12345',
        "incidentFeedbackStatus": "Pending response",
        "incidentFeedbackDetails": "Notification has been sent to user.",
        "createdAt": "2022-Feb-14 21:51:20 UTC"
    }
  ];

  it('renders report details with data pattern widget when present', () => {
    axiosMock.onGet(/\/incident-feedback\/12345.+/).reply(200, [{}]);
    wrapper = shallow(<IncidentDetails lookupURL='incidents/12345.json' feedbackHistoryURL='/incident-feedback/12345.json' fawkes={false} userId='test@example.com' incidentId='12345' />);
    wrapper.setState({ incident, patternResults: incident.data_pattern_results, highMatches: expHighMatches, medMatches: expMedMatches, lowMatches: expLowMatches, currentMatches: expHighMatches, feedbackHistory })
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.containsMatchingElement(<ReportCard incident={incident} />)).toEqual(true);
    expect(wrapper.containsMatchingElement(<MatchingDataPatterns {...patternProps} />)).toEqual(true);
  });

  it('componentDidMount() should call API', async() => {
    delete window.location;
    window.location = { search: '?region=us', assign: null };
    wrapper = shallow(<IncidentDetails lookupURL='incidents/12345.json' feedbackHistoryURL='/incident-feedback/12345.json' fawkes={false} userId='test@example.com' incidentId='12345' />);
    const response = {
      data_pattern_results: {
        p1: {
          lcf: 1,
          mcf: 2,
          hcf: 3
        },
        p2: {
          lcf: 1,
          mcf: 2,
          hcf: 3
        }
      }
    };
    const feedbackHistory = [
      {
          "incidentId": '12345',
          "incidentFeedbackStatus": "Pending response",
          "incidentFeedbackDetails": "Notification has been sent to user.",
          "createdAt": "2022-Feb-14 21:51:20 UTC"
      }
    ];
    axiosMock.onGet('/incidents/12345.json?region=us').reply(200, response);
    axiosMock.onGet('/incident-feedback/12345.json?region=us').reply(200, feedbackHistory);
    wrapper.instance().componentDidMount();
    await flushPromises();
    expect(wrapper.state('highMatches')).toEqual({p1: {
      lcf: 1,
      mcf: 2,
      hcf: 3
    },
    p2: {
      lcf: 1,
      mcf: 2,
      hcf: 3
    }});
    expect(wrapper.state('feedbackHistory').length).toEqual(1);
  });

  it('should call API with "ap" region', async() => {
    delete window.location;
    window.location = { search: '?region=ap', assign: null };

    wrapper = shallow(<IncidentDetails lookupURL='incidents/12345.json' feedbackHistoryURL='/incident-feedback/12345.json' userId='test@example.com' fawkes={false} incidentId='12345' />);
    const response = {
      data_pattern_results: {
        p1: {
          lcf: 1,
          mcf: 2,
          hcf: 3
        },
        p2: {
          lcf: 1,
          mcf: 2,
          hcf: 3
        }
      }
    };
    axiosMock.resetHistory();
    axiosMock.onGet().reply(200, response);

    wrapper.instance().componentDidMount();
    await flushPromises();
    expect(axiosMock.history.get[0].url).toMatch(/\?region=ap/);
  })
});

describe('<IncidentDetails> without data patterns', () => {
  let wrapper;
  const incident = {
    incident_id: '12345',
    file_name: 'Test Asset',
    asset_id: '987654',
    dataProfileName: 'Bank Account Number North American',
    channel: 'Prisma Access',
    detection_time: '2020-07-09T20:56:14.620Z',
    file_size_in_bytes: 45986,
    data_pattern_results: []
  };

  it('does not render data pattern widget without data pattern results', () => {
    axiosMock.onGet().reply(200, []);
    wrapper = shallow(<IncidentDetails lookupURL='incidents/12345.json' userId='test@example.com' fawkes={false} incidentId='12345' />);
    wrapper.setState({ incident })
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.containsMatchingElement(<ReportCard incident={incident} />)).toEqual(true);
    expect(wrapper.containsMatchingElement(<MatchingDataPatterns />)).toEqual(false);
  });
});
