import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@panwds/react-ui';
import { FaTimes, FaQuestionCircle } from 'react-icons/fa';
import axios from 'axios';
import { IntlProvider } from 'react-intl';

import translate from '../../../helpers/translate';
import { returnToIndex, saveProfile, updateProfile } from './profileActions';
import PreviewToggle from './PreviewToggle';
import { formatRequest } from './requestFormatter';
import { profileDetails } from '../profileDataTransformer';
import DataProfileDefinition from '../DataProfileDefinition';
import DataProfileDetailPreview from '../DataProfileDetailPreview';
import BuilderIcon from '../../../../assets/images/builder.svg';
import BreadcrumbHeader from './BreadcrumbHeader';
import ProfileName from './ProfileName';
import SingleSelect from '../../SingleSelect';
import { isProfileValid } from './profileValidatorV2';
import SpinnerIcon from '../../../../assets/images/spinner.gif';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}

const DataProfileFormMulti = ({ history, dataProfileId, lookupUrl, dataProfile, apiPostfix, createURL, updateURL }) => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(undefined);
  const [validatePresence, setValidatePresence] = useState(false);
  const [previewType, setPreviewType] = useState('detail');
  const [profileList, setProfileList] = useState([]);
  const [selectedPrimaryProfiles, setSelectedPrimaryProfiles] = useState([{}]);
  const [selectedSecondaryProfiles, setSelectedSecondaryProfiles] = useState([]);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const clone = window.location.pathname.includes('clone');

  const profileDisplayName = (profile) => {
    if (profile.profile_available_for_multi_profile) {
      return profile.name;
    }
    return(
      <div style={{minWidth: '253px'}}>
        <div className='tw-float-right'>
          <Tooltip label={t('multi_profile_ineligible')} appearance='dark' hasTriangle>
            <Button
              aria-label="info"
              icon={<FaQuestionCircle className="tw-absolute" />}
              appearance='tertiary-clear'
              addClassName='noHoverBtn'
            />
          </Tooltip>
        </div>
        {/* TODO: truncate longer names */}
        <div style={{marginTop: '9px', display: 'inline-block'}}>{profile.name}</div>
      </div>
    );
  }

  useEffect(() => {
    async function fetchData() {
      axios.get(lookupUrl)
        .then(
          result => {
            const formattedProfiles = result.data.resources.map(profile => {
              return {
                value: profileDisplayName(profile),
                key: profile.id,
                disabled: !profile.profile_available_for_multi_profile
              }}).sort((a) => a.disabled ? 1 : -1
            );
            setProfileList(formattedProfiles);

            if (dataProfile) {
              const formattedProfile = profileDetails(dataProfile, [], [], clone, formattedProfiles);
              setName(formattedProfile.name);
              setSelectedPrimaryProfiles(formattedProfile.selectedPrimaryProfiles);
              setSelectedSecondaryProfiles(formattedProfile.selectedSecondaryProfiles);
            }
            setLoading(false);
          }
        ).catch( () => {
          setLoading(false);
        })
    }
    fetchData();
  }, []);

  const updatePreviewType = (type) => {
    setPreviewType(type);
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  };

  const profileDataPresent = () => {
    return (selectedPrimaryProfiles.length > 0 && selectedPrimaryProfiles[0].value) || selectedPrimaryProfiles.length > 1 || selectedSecondaryProfiles.length > 0;
  }

  const handleSelectionChange = (newProfile, index, level) => {
    if (level === 'primary') {
      const newProfileList = [...selectedPrimaryProfiles];
      newProfileList.splice(index, 1, newProfile.selectedItem);
      setSelectedPrimaryProfiles(newProfileList);
    }
    else {
      const newProfileList = [...selectedSecondaryProfiles];
      newProfileList.splice(index, 1, newProfile.selectedItem);
      setSelectedSecondaryProfiles(newProfileList);
    }
  }

  const handleAddProfile = (level) => {
    if (level === 'primary') {
      setSelectedPrimaryProfiles([...selectedPrimaryProfiles, {}])
    }
    else {
      setSelectedSecondaryProfiles([...selectedSecondaryProfiles, {}])
    }
  }

  const removeProfile = (level, index) => {
    if (level === 'primary') {
      const newProfileList = [...selectedPrimaryProfiles];
      newProfileList.splice(index, 1);
      setSelectedPrimaryProfiles(newProfileList);
    }
    else {
      const newProfileList = [...selectedSecondaryProfiles];
      newProfileList.splice(index, 1);
      setSelectedSecondaryProfiles(newProfileList);
    }
  }

  const save = async() => {
    setSaveInProgress(true);
    const params = {
      name,
      selectedPrimaryProfiles,
      selectedSecondaryProfiles,
      schema_version: 2,
      create: clone || !dataProfile,
      advanced: true,
      multi: true
    }
    const valid = isProfileValid(params);
    if (valid) {
      const formattedProfile = formatRequest(params);
      let error = '';
      if (!clone && (dataProfile || dataProfileId)) {
        error = await updateProfile(formattedProfile, {apiPostfix, dataProfile, updateURL, history, dataProfileId});
      }
      else {
        error = await saveProfile(formattedProfile, {apiPostfix, dataProfile, createURL, history, dataProfileId});
      }
      if (error) {
        setNameError(error);
      }
    }
    else {
      setSaveInProgress(false);
      setValidatePresence(true);
    }
  }

  const canSave = dataProfileId? dataProfile && dataProfile.profile_type.toLowerCase() !== 'predefined' : true;

  return (
    <IntlProvider locale='en'>
    <div>
      <BreadcrumbHeader dataProfile={!!dataProfileId} clone={clone} />

      {loading ?
        <div className='empty text-center'>
          <img id='loader' src={SpinnerIcon} alt='loading' style={{width: '75px', marginTop: '100px'}} />
          <br /><br />
          <p>{translate('actions.loading')}</p>
        </div>
        :
        <div className='formArea'>
          <div className='dataProfileForm'>
            <div className='tw-float-right form-section preview'>
              <h4 className="mt-2">
                {t('preview')}
                <span className='tw-float-right previewToggle'>
                  <PreviewToggle previewType={previewType} updateState={(type) => updatePreviewType(type?.previewType)} />
                </span>
              </h4>
              {previewType === 'detail' && profileDataPresent() &&
                <DataProfileDetailPreview primaryProfiles={selectedPrimaryProfiles} secondaryProfiles={selectedSecondaryProfiles} multi />
              }
              {previewType === 'bracket' && profileDataPresent() &&
                <DataProfileDefinition primaryProfiles={selectedPrimaryProfiles} secondaryProfiles={selectedSecondaryProfiles} multi />
              }
              {!profileDataPresent() &&
                <div id='nothingToPreview'>
                  <img src={BuilderIcon} alt='Preview' className='text-center' />
                  <p className='text-center'>
                    <b>{t('no_definition')}</b>
                    <br />
                    <span className='helpText'>{t('add_a_profile')}</span>
                  </p>
                </div>
              }
            </div>

            <ProfileName validatePresence={validatePresence} name={name} handleNameChange={handleNameChange} nameError={nameError} />

            <div className='form-section'>
              <h4>{t('primary_rule')}</h4>
              <span className='muteText' style={{lineHeight: '20px'}}>{t('multi_primary_rule_desc')}</span>
              <hr />
              <div>

                <div className='btnGroup'>
                  <Button addClassName='addBtn'>
                    {t('match_type.or')}
                  </Button>
                  <span key='addBtns' className='tw-float-right ruleBtns'>
                    <Button appearance='clear' id='addProfilePrimary' addClassName='addBtn' onClick={() => handleAddProfile('primary')}>
                      {t('add_data_profile')}
                    </Button>
                  </span>
                </div>

                <div className='conditionGroup'>
                  {selectedPrimaryProfiles.map((profile, index) => {
                    return(
                      <div className='ruleItem dp' key={`${profile.key}-${index}`}>
                        {index > 0 &&
                          <div className='tw-float-right'>
                            <FaTimes onClick={() => removeProfile('primary', index)} className='muteText removeBtn' />
                          </div>
                        }

                        <SingleSelect
                          onChange={newProfileId => handleSelectionChange(newProfileId, index, 'primary')}
                          selectedKey={profile.key}
                          items={profileList}
                          style={{minWidth: '275px'}}
                          dataTestId='profileSelect'
                          enableSearch
                          button={
                            <Button isMenu appearance='tertiary' addClassName={`profileName ${validatePresence && !profile.key ? 'has-error' : ''}`}>
                              {profile.value || t('add_data_profile')}
                            </Button>
                          }
                        />
                      </div>
                    )
                  })}
                </div>

              </div>
            </div>

            <div className='form-section'>
              <h4>{t('secondary_rule')}</h4>
              <span className='muteText' style={{lineHeight: '20px'}}>{t('multi_secondary_rule_desc')}</span>
              <hr />
              <div>
                <div className='btnGroup'>
                  <Button addClassName='addBtn'>
                    {t('match_type.or')}
                  </Button>
                  <span key='addBtns' className='tw-float-right ruleBtns'>
                    <Button appearance='clear' id='addProfileSecondary' addClassName='addBtn' onClick={() => handleAddProfile('secondary')}>
                      {t('add_data_profile')}
                    </Button>
                  </span>
                </div>

                <div className='conditionGroup'>
                  {selectedSecondaryProfiles.map((profile, index) => {
                    return(
                      <div className='ruleItem dp' key={`${profile.key}-${index}`}>
                        <div className='tw-float-right'>
                          <FaTimes onClick={() => removeProfile('secondary', index)} className='muteText removeBtn' />
                        </div>

                        <SingleSelect
                          onChange={newProfileId => handleSelectionChange(newProfileId, index, 'secondary')}
                          selectedKey={profile.key}
                          items={profileList}
                          style={{minWidth: '275px'}}
                          dataTestId='profileSelect'
                          enableSearch
                          button={
                            <Button isMenu appearance='tertiary' addClassName={`profileName ${validatePresence && !profile.key ? 'has-error' : ''}`}>
                              {profile.value || t('add_data_profile')}
                            </Button>
                          }
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <div className='formFooter'>
        <div className='tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1'>
          <Button onClick={() => returnToIndex(history, dataProfileId)} >
            {translate('actions.cancel')}
          </Button>
          <Button disabled={!canSave || saveInProgress} appearance='primary' onClick={ () => save(false) } >
            {translate('actions.save')}
          </Button>
        </div>
      </div>
    </div>
    </IntlProvider>
  );
}

DataProfileFormMulti.propTypes = {
  createURL: PropTypes.string,
  updateURL: PropTypes.string,
  apiPostfix: PropTypes.string,
  dataProfile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    profile_type: PropTypes.string,
    detection_rules: PropTypes.array,
    schema_version: PropTypes.number,
    multiProfile: PropTypes.bool,
  }),
  dataProfileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // history will be provided by react-router withRouter HOC
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  lookupUrl: PropTypes.string,
};

DataProfileFormMulti.defaultProps = {
  dataProfileId: undefined,
  createURL: '/data_profiles',
  updateURL: '/data_profiles',
  apiPostfix: '.json',
  dataProfile: undefined,
  history: undefined,
  lookupUrl: '/data_profiles.json'
}
export default DataProfileFormMulti
