import React, {useState, useEffect} from 'react';
import { Button } from '@panwds/react-ui';
import { MoreIcon } from '@panwds/icons';
import Popup from 'reactjs-popup';
import _partition from 'lodash.partition';
import _sortBy from 'lodash.sortby';
import translate from '../../helpers/translate';
import StatusForm from './StatusForm';

function t(scope, options) {
  return translate(`incidents.index.${scope}`, options);
}

const ResolveMenu = ({statusList, closePopup, updateStatus, statusSelected}) => {
  const [search, setSearch] = useState('');
  const [filteredStatusList, setFilteredStatusList] = useState(statusList);

  const filterStatuses = (input = search) => {
    const filtered = statusList.filter(status => {
      return status.resolutionStatus.toLowerCase().includes(input.toLowerCase());
    });
    setFilteredStatusList(filtered);
  }
  const processSearch = (event) => {
    const input = event.target.value;
    setSearch(input);
    filterStatuses(input);
  }

  useEffect(() => {
    filterStatuses();
  }, [statusList]);


  const statusClicked = async(event, status) => {
    if (!(event.target instanceof (SVGElement))) {
      await statusSelected(status);
      closePopup();
    }
  }

  const saveStatus = async(status) => {
    return await updateStatus(status);
  }

  const statusForm = (status) => {
    return (closePopup) =>
      (<StatusForm status={status} closePopup={closePopup} saveStatus={saveStatus}/>);
  }

  const newStatusForm = () => {
    const status = {
      resolutionStatus: search
    }
    return statusForm(status);
  }

  const renderStatus = (status, editable) => {
    return (
      <li className='listItem resolution' key={status.id} onClick={() => statusClicked(event, status)}>
        <div className='itemDetails'>
          <div className='name'>{status.resolutionStatus}</div>
        </div>
        {
          editable &&
          <Popup
            trigger={<span><MoreIcon className='actionBtn tw-mt-1'/></span>}
            position='right top'
            on='click'
            arrow={false}
            closeOnEscape
            >
              {statusForm(status)}
          </Popup>
        }
      </li>
    );
  }

  const [globalStatusList, tenantStatusList] = _partition(filteredStatusList, {global: true}).map(list => _sortBy(list, ['resolutionStatus']));


  return (
    <div className='resolveMenu'>
      <input placeholder={t('search_or_create_resolution')} className='searchBox form-control tw-mb-2' value={search} onChange={processSearch}/>
      {filteredStatusList.length > 0 &&
        <ul className='listHolder'>
          {
            globalStatusList.map(status => renderStatus(status))
          }
          { globalStatusList.length > 0 && tenantStatusList.length > 0 && <hr className='tw-m-1'/>}
          {
            tenantStatusList.map(status => renderStatus(status, true))
          }
        </ul>
      }
      {
        filteredStatusList.length === 0 &&
        <div className='createPane tw-flex'>
          <span className='statusToAdd tw-mr-auto tw-ml-2'>{search}</span>
          <Popup
            trigger={<Button appearance='clear'>{t('create_tag')}</Button>}
            position='right top'
            on='click'
            arrow={false}
            closeOnEscape
            contentStype='margin-left:16px;'
            >
              {newStatusForm()}
          </Popup>
        </div>
      }
    </div>
  );
}

export default ResolveMenu;