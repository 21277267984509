import i18next from '../i18n/i18n';

export default (scope, options = {}) => {
  if (options.platform) {
    switch (scope) {
      case 'prisma-saas':
        return 'SaaS Security';
      case 'prisma-access':
        return 'Prisma Access';
      case 'ng-casb':
        return 'NG CASB';
      case 'ngfw':
        return 'NGFW';
      case 'panorama':
        return 'Panorama';
      case 'prisma-cloud':
        return 'Prisma Cloud';
      // TODO remove the following workaround after Database fix is in
      case 'Fawkes':
        return 'Prisma Access Cloud Management';
      case 'dlp-ui':
        return 'Prisma Access Cloud Management';
      case 'dlp':
        return 'Enterprise DLP';
      default:
        return scope;
    }
  }
  return (i18next.t(scope, options));
}
