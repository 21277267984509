import React from 'react';

import { configure, shallow } from 'enzyme';
import { render, fireEvent } from '@testing-library/react';
import Adapter from 'enzyme-adapter-react-16';
import ProfileName from './ProfileName';

jest.mock('../../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<ProfileName>', () => {
  let wrapper;
  const handleNameChange = jest.fn();

  it('renders profile name input', () => {
    wrapper = shallow(<ProfileName name='abc' handleNameChange={handleNameChange} />);
    expect(wrapper.find('.form-section').length).toEqual(1);
    expect(wrapper.find('input.profile_name').length).toEqual(1);
    expect(wrapper).toMatchSnapshot();
  });

  it('renders profile name errors', () => {
    wrapper = shallow(<ProfileName name='abc' handleNameChange={handleNameChange} validatePresence={true} nameError='Duplicate name' />);
    expect(wrapper.find('.form-section').length).toEqual(1);
    expect(wrapper.find('input.profile_name.has-error').length).toEqual(1);
    expect(wrapper.find('.errorDetail').text()).toEqual('Duplicate name');
    expect(wrapper).toMatchSnapshot();
  });

  it('renders missing name errors', () => {
    wrapper = shallow(<ProfileName name='' handleNameChange={handleNameChange} validatePresence={true} />);
    expect(wrapper.find('.form-section').length).toEqual(1);
    expect(wrapper.find('input.profile_name.has-error').length).toEqual(1);
    expect(wrapper.find('.errorDetail').text()).toEqual('data_profiles.form.name_placeholder');
    expect(wrapper).toMatchSnapshot();
  });

  it('should change name', () => {
    wrapper = render(<ProfileName name='' handleNameChange={handleNameChange} />);
    const input = wrapper.container.querySelector('.profile_name');
    fireEvent.focus(input);
    fireEvent.input(input, { target: { value: 'abc' } });
    expect(handleNameChange).toHaveBeenCalled();
  });
});
