exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1lt2Q4gWVQ_s7ST8qpGMyS{margin-bottom:16px}._1lt2Q4gWVQ_s7ST8qpGMyS ._3Hv9cd0uMFRka1kkIAlfou{padding:14px;border-bottom:1px solid #d4d4d4}._1lt2Q4gWVQ_s7ST8qpGMyS ._1t9_SNJgrLqNN8LjX9zFgB{display:flex;padding:14px}._1lt2Q4gWVQ_s7ST8qpGMyS ._1t9_SNJgrLqNN8LjX9zFgB:first-child{border-bottom:1px solid #d4d4d4}._1lt2Q4gWVQ_s7ST8qpGMyS .Hu1JVY2H4GxVy1nb8CTgh{flex-basis:380px}._1lt2Q4gWVQ_s7ST8qpGMyS ._1fEeGA0FWUY608WC9HNFFZ{margin-bottom:0}", "", {"version":3,"sources":["/home/panuser/app/javascript/components/SnippetsConfig/SnippetsConfig.module.scss"],"names":[],"mappings":"AAEA,yBACE,kBAAA,CAEA,kDACE,aACA,+BAAA,CAGF,kDACE,aACA,YAAA,CAEA,8DACE,+BAAA,CAIJ,gDACE,gBAAA,CAGF,kDACE,eAAA,CAAA","file":"SnippetsConfig.module.scss","sourcesContent":["@import '../../../assets/stylesheets/custom_variables.scss';\n\n.snippetsMain {\n  margin-bottom: 16px;\n\n  .headerSection {\n    padding: 14px;\n    border-bottom: 1px solid $form-border;\n  }\n\n  .formRow {\n    display: flex;\n    padding: 14px;\n\n    &:first-child {\n      border-bottom: 1px solid $form-border;\n    }\n  }\n\n  .formLabel {\n    flex-basis: 380px;\n  }\n\n  .inputLabel {\n    margin-bottom: 0;\n  }\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"snippetsMain": "_1lt2Q4gWVQ_s7ST8qpGMyS",
	"headerSection": "_3Hv9cd0uMFRka1kkIAlfou",
	"formRow": "_1t9_SNJgrLqNN8LjX9zFgB",
	"formLabel": "Hu1JVY2H4GxVy1nb8CTgh",
	"inputLabel": "_1fEeGA0FWUY608WC9HNFFZ"
};