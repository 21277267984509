import React from 'react';

import {render} from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import ApiTokenTable from './ApiTokenTable';

const axiosMock = new MockAdapter(axios);
axiosMock.onPost().reply(200, {});
axiosMock.onPut().reply(200, {});

describe('<ApiTokenTable>', () => {
  let testUtils;
  const props = {
    lookupURL: '/api_tokens',
    fawkes: false
  };

  const tokens = [
    { appName: 'Token A', createdDate: '2020-07-08 23:44:28 UTC', expiryDate: '2021-01-08 23:44:28 UTC', userId: 'user@example.com', id: '111' },
    { appName: 'Token B', createdDate: '2020-06-08 23:44:28 UTC', expiryDate: '2020-12-08 23:44:28 UTC', userId: 'user2@example.com', id: '112' }
  ];

  it('renders no tokens label', () => {
    axiosMock.onGet().reply(200, { data: [] })
    testUtils = render(<ApiTokenTable {...props} /> );
    expect(testUtils.container.querySelector('.no_tokens')).toBeInTheDocument();
  });
});
