import React from 'react';
import PropTypes from 'prop-types';
import { FaPen, FaCopy } from 'react-icons/fa';
import { Button } from '@panwds/react-ui';
import translate from '../../helpers/translate';
import styles from './DataProfileDetails.module.scss'
import DataProfileBracketPreview from './DataProfileBracketPreview';

const moment = require('moment-timezone');

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function t(scope, options) {
  return translate(`data_profiles.view.${scope}`, options);
}

class DataProfileDetails extends React.Component {
  state = {
    currentView: 'detail'
  };

  render() {
    const dataPatterns = this.props.profile.data_patterns || [];
    const edmDatasets = [];

    const findPatternsDatasets = (subExpressions) => {
      subExpressions.forEach(subExp => {
        if (subExp.rule_item) {
          const edm = subExp.rule_item.detection_technique === 'edm';
          const {name} = subExp.rule_item;
          if (edm) { edmDatasets.push(name) }
          else { dataPatterns.push(name) };
        }
        else {
          findPatternsDatasets(subExp.sub_expressions);
        }
      })
    }

    if (this.props.profile.schema_version === 2 && !this.props.profile.multiProfile) {
      findPatternsDatasets(this.props.profile.primaryExpTree.sub_expressions);
      findPatternsDatasets(this.props.profile.secondaryExpTree.sub_expressions);
    }

    return(
      <div className={styles.tableDetailsView} data-test-id="tableDetailsView">
        <div className={`${styles.detailHeader} detailHeader`}>
          <span className={styles.detailTitle}>
            {this.props.profile.name}
          </span>
          <Button addClassName='close tw-float-right' data-dismiss='modal' aria-hidden='true' onClick={ () => this.props.updateState({viewing: ''}) } />
        </div>
        {
          this.props.profile.id &&
          <div>
            <div style={{ padding: '15px' }}>
              { this.props.actionable &&
                <div className={styles.actionBtns}>
                  <Button disabled={!this.props.actionable.editable} addClassName='edit' onClick={() => this.props.updateState({editing: this.props.profile.id})} aria-label='edit' icon={<FaPen className='defaultText' />}/>
                  <Button disabled={!this.props.actionable.cloneable} addClassName='clone' onClick={() => this.props.updateState({cloning: this.props.profile.id})} aria-label='clone' icon={<FaCopy className='defaultText' />}/>
                </div>
              }
              <br />

              <div className={`${styles.detailSubtitle} ${this.state.currentView === 'detail' && styles.active} tab-details`} onClick={() => this.setState({currentView: 'detail'})}>
                {t('details')}
              </div>
              <div className={`${styles.detailSubtitle} ${this.state.currentView === 'preview' && styles.active} ml5 tab-preview`} onClick={() => this.setState({currentView: 'preview'})}>
                {t('preview')}
              </div>
              <hr className={styles.divider} />

              {this.state.currentView === 'detail' &&
                <table className='table table-borderless table-condensed'>
                  <tbody>
                    <tr>
                      <td className='form-label'>{t('name')}</td>
                      <td>{this.props.profile.name}</td>
                    </tr>
                    <tr>
                      <td className='form-label'>{t('type')}</td>
                      <td>{this.props.profile.type}</td>
                    </tr>
                    <tr>
                      <td className='form-label'>{t('mode')}</td>
                      <td>{this.props.profile.profile_type}</td>
                    </tr>
                    <tr>
                      <td className='form-label'>{t('updated_by')}</td>
                      <td>{translate(this.props.profile.updated_by, {platform: true})}</td>
                    </tr>
                    <tr>
                      <td className='form-label'>{t('updated_at')}</td>
                      <td>{this.props.profile.updated_at && moment(this.props.profile.updated_at).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z')}</td>
                    </tr>
                    {dataPatterns.length > 0 &&
                      <tr>
                        <td className='form-label'>{t('patterns')}</td>
                        <td>{[...new Set(this.props.profile.data_patterns)].join(', ')}</td>
                      </tr>
                    }
                    { edmDatasets.length > 0 &&
                      <tr>
                        <td className='form-label'>{t('datasets')}</td>
                        <td>{[...new Set(edmDatasets)].join(', ')}</td>
                      </tr>
                    }
                    { this.props.profile.multiProfile && this.props.profile.selectedPrimaryProfiles.length > 0 &&
                      <tr>
                        <td className='form-label'>{t('profiles')}</td>
                        <td>{[...this.props.profile.selectedPrimaryProfiles, ...this.props.profile.selectedSecondaryProfiles].map(p => p.value).join(', ')}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              }

              {this.state.currentView === 'preview' &&
                <div className='preview'>
                  <div className={styles.headerLabel}>{t('primary_rule')}</div>
                  <div className={`${styles.bracketPreview} bracketPreview`}>
                    <DataProfileBracketPreview
                      dataPatternRule={this.props.profile.advance_data_patterns_rules ? this.props.profile.advance_data_patterns_rules[0] : {}}
                      advanced={this.props.profile.advanced}
                      expTree={this.props.profile.primaryExpTree}
                      schema={this.props.profile.schema_version || 1}
                      profiles={this.props.profile.selectedPrimaryProfiles}
                      multi={this.props.profile.multiProfile}
                    />
                  </div>

                  <div className={styles.headerLabel}>{t('secondary_rule')}</div>
                  <div className={styles.bracketPreview}>
                    <DataProfileBracketPreview
                      dataPatternRule={this.props.profile.advance_data_patterns_rules ? this.props.profile.advance_data_patterns_rules[1] : {}}
                      advanced={this.props.profile.advanced}
                      expTree={this.props.profile.secondaryExpTree}
                      schema={this.props.profile.schema_version || 1}
                      profiles={this.props.profile.selectedSecondaryProfiles}
                      multi={this.props.profile.multiProfile}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

DataProfileDetails.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    advance_data_patterns_rules: PropTypes.array,
    data_patterns: PropTypes.array,
    type: PropTypes.string,
    profile_type: PropTypes.string,
    updated_by: PropTypes.string,
    updated_at: PropTypes.string,
    primaryExpTree: PropTypes.shape({
      sub_expressions: PropTypes.array
    }),
    secondaryExpTree: PropTypes.shape({
      sub_expressions: PropTypes.array
    }),
    selectedPrimaryProfiles: PropTypes.array,
    selectedSecondaryProfiles: PropTypes.array,
    multiProfile: PropTypes.bool,
    advanced: PropTypes.bool,
    schema_version: PropTypes.number,
  }),
  updateState: PropTypes.func.isRequired,
  actionable: PropTypes.shape({
    editable: PropTypes.bool,
    cloneable: PropTypes.bool,
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

DataProfileDetails.defaultProps = {
  profile: {},
  actionable: undefined,
  history: null,
}

export default DataProfileDetails;
