// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataProfileTable> renders bulk table 1`] = `
<div
  className="data_profiles"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <a
        className="btn-link"
        href="/"
      >
        application.dlp
      </a>
      <span
        className="fa fa-chevron-right separator"
      />
      data_profiles.index.title
    </div>
    <div
      className="title"
    >
      data_profiles.index.title
    </div>
  </div>
  <div
    className="dataProfileTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        data_profiles.index.title
        <span
          className="count"
        >
          (5)
        </span>
      </span>
      <span
        className="actions tw-float-right"
      >
        <Button
          appearance="primary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          data_profiles.index.add
        </Button>
      </span>
      <span
        className="tw-float-right rightInnerAddOn searchBar"
      >
        <input
          className="form-control"
          onChange={[Function]}
          placeholder="data_profiles.index.search"
          value=""
        />
        <i>
          <FaSearch />
        </i>
      </span>
    </div>
    <BulkTable
      actionable={
        Object {
          "cloneable": [Function],
          "deletable": [Function],
          "editable": [Function],
        }
      }
      columns={
        Array [
          Object {
            "accessor": "name",
            "header": "Data Profile",
          },
          Object {
            "accessor": "profile_type",
            "header": "Mode",
          },
          Object {
            "accessor": "type",
            "header": "Type",
          },
          Object {
            "accessor": "updated_at",
            "header": "Last Modified",
          },
          Object {
            "accessor": "updated_by",
            "header": "Last Updated By",
          },
        ]
      }
      customCellRender={[Function]}
      data={
        Array [
          Object {
            "id": "111",
            "name": "Profile A",
            "profile_type": "Custom",
            "type": "Basic",
            "updated_at": "Apr 7 2021 at 15:23",
            "updated_by": "Enterprise DLP App",
          },
          Object {
            "id": "112",
            "name": "Profile B",
            "profile_type": "Predefined",
            "type": "Advanced",
            "updated_at": "Apr 1 2021 at 8:00",
            "updated_by": "Prisma Access",
          },
          Object {
            "id": "113",
            "name": "Profile C",
            "profile_type": "Custom - EDM",
            "type": "Advanced",
            "updated_at": "Apr 8 2021 at 2:00",
            "updated_by": "Panorama",
          },
          Object {
            "id": "114",
            "name": "Profile D",
            "profile_type": "Custom",
            "type": "Basic",
            "updated_at": "Apr 6 2021 at 9:42",
            "updated_by": "SaaS Security",
          },
          Object {
            "id": "115",
            "name": "Profile E",
            "profile_type": "Predefined",
            "type": "Basic",
            "updated_at": "Apr 3 2021 at 16:53",
            "updated_by": "Enterprise DLP App",
          },
        ]
      }
      defaultSortBy="updated_at"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
          "filterOptions": Array [],
          "filterable": false,
        }
      }
      loading={false}
      pageData={
        Object {
          "currentPage": 1,
          "pageCount": 10,
          "totalCount": 5,
          "totalPages": 1,
        }
      }
      requestResults={[Function]}
      selectable={false}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={Array []}
      sortable={true}
      updateFilters={null}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      addClassName="dlp-root modal"
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      size="md"
    >
      <ModalHeader
        enableClose={true}
        title="Add a Data Profile"
      />
      <CardBody
        addClassName="newModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div>
          <p
            className="helpTitle"
          >
            Select the type of Data Profile to create:
          </p>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Pattern Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Classic Data Profile
              </h5>
              <p>
                Create a classic data profile that is editable on the Panorama management server. Cannot include advanced detection methods such as EDM. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="EDM/Hybrid Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Advanced Data Profile
              </h5>
              <p>
                Create an advanced data profile with data patterns and advanced detection methods. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Multi Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                With Nested Data Profiles 
              </h5>
              <p>
                Create a data profile containing multiple nested data profiles. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <br />
        </div>
      </CardBody>
      <ModalFooter>
        <ModalCloseButton>
          actions.cancel
        </ModalCloseButton>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<DataProfileTable> renders bulk table and view modal if state contains viewing profile 1`] = `
<div
  className="data_profiles"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <a
        className="btn-link"
        href="/"
      >
        application.dlp
      </a>
      <span
        className="fa fa-chevron-right separator"
      />
      data_profiles.index.title
    </div>
    <div
      className="title"
    >
      data_profiles.index.title
    </div>
  </div>
  <div
    className="dataProfileTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        data_profiles.index.title
        <span
          className="count"
        >
          (5)
        </span>
      </span>
      <span
        className="actions tw-float-right"
      >
        <Button
          appearance="primary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          data_profiles.index.add
        </Button>
      </span>
      <span
        className="tw-float-right rightInnerAddOn searchBar"
      >
        <input
          className="form-control"
          onChange={[Function]}
          placeholder="data_profiles.index.search"
          value=""
        />
        <i>
          <FaSearch />
        </i>
      </span>
    </div>
    <BulkTable
      actionable={
        Object {
          "cloneable": [Function],
          "deletable": [Function],
          "editable": [Function],
        }
      }
      columns={
        Array [
          Object {
            "accessor": "name",
            "header": "Data Profile",
          },
          Object {
            "accessor": "profile_type",
            "header": "Mode",
          },
          Object {
            "accessor": "type",
            "header": "Type",
          },
          Object {
            "accessor": "updated_at",
            "header": "Last Modified",
          },
          Object {
            "accessor": "updated_by",
            "header": "Last Updated By",
          },
        ]
      }
      customCellRender={[Function]}
      data={
        Array [
          Object {
            "id": "111",
            "name": "Profile A",
            "profile_type": "Custom",
            "type": "Basic",
            "updated_at": "Apr 7 2021 at 15:23",
            "updated_by": "Enterprise DLP App",
          },
          Object {
            "id": "112",
            "name": "Profile B",
            "profile_type": "Predefined",
            "type": "Advanced",
            "updated_at": "Apr 1 2021 at 8:00",
            "updated_by": "Prisma Access",
          },
          Object {
            "id": "113",
            "name": "Profile C",
            "profile_type": "Custom - EDM",
            "type": "Advanced",
            "updated_at": "Apr 8 2021 at 2:00",
            "updated_by": "Panorama",
          },
          Object {
            "id": "114",
            "name": "Profile D",
            "profile_type": "Custom",
            "type": "Basic",
            "updated_at": "Apr 6 2021 at 9:42",
            "updated_by": "SaaS Security",
          },
          Object {
            "id": "115",
            "name": "Profile E",
            "profile_type": "Predefined",
            "type": "Basic",
            "updated_at": "Apr 3 2021 at 16:53",
            "updated_by": "Enterprise DLP App",
          },
        ]
      }
      defaultSortBy="updated_at"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
          "filterOptions": Array [],
          "filterable": false,
        }
      }
      loading={false}
      pageData={
        Object {
          "currentPage": 1,
          "pageCount": 10,
          "totalCount": 5,
          "totalPages": 1,
        }
      }
      requestResults={[Function]}
      selectable={false}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={Array []}
      sortable={true}
      updateFilters={null}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
    <DataProfileDetails
      actionable={
        Object {
          "cloneable": true,
          "deletable": false,
          "editable": false,
        }
      }
      history={
        Object {
          "push": [MockFunction],
        }
      }
      profile={
        Object {
          "id": "113",
          "name": "Profile C",
          "profile_type": "Custom - EDM",
          "type": "Advanced",
          "updated_at": "Apr 8 2021 at 2:00",
          "updated_by": "Panorama",
        }
      }
      updateState={[Function]}
    />
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      addClassName="dlp-root modal"
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      size="md"
    >
      <ModalHeader
        enableClose={true}
        title="Add a Data Profile"
      />
      <CardBody
        addClassName="newModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div>
          <p
            className="helpTitle"
          >
            Select the type of Data Profile to create:
          </p>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Pattern Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Classic Data Profile
              </h5>
              <p>
                Create a classic data profile that is editable on the Panorama management server. Cannot include advanced detection methods such as EDM. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="EDM/Hybrid Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Advanced Data Profile
              </h5>
              <p>
                Create an advanced data profile with data patterns and advanced detection methods. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Multi Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                With Nested Data Profiles 
              </h5>
              <p>
                Create a data profile containing multiple nested data profiles. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <br />
        </div>
      </CardBody>
      <ModalFooter>
        <ModalCloseButton>
          actions.cancel
        </ModalCloseButton>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<DataProfileTable> request results includes name search 1`] = `
<div
  className="data_profiles"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <a
        className="btn-link"
        href="/"
      >
        application.dlp
      </a>
      <span
        className="fa fa-chevron-right separator"
      />
      data_profiles.index.title
    </div>
    <div
      className="title"
    >
      data_profiles.index.title
    </div>
  </div>
  <div
    className="dataProfileTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        data_profiles.index.title
        <span
          className="count"
        >
          (0)
        </span>
      </span>
      <span
        className="actions tw-float-right"
      >
        <Button
          appearance="primary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          data_profiles.index.add
        </Button>
      </span>
      <span
        className="tw-float-right rightInnerAddOn searchBar"
      >
        <input
          className="form-control"
          onChange={[Function]}
          placeholder="data_profiles.index.search"
          value="example"
        />
        <i>
          <FaSearch />
        </i>
      </span>
    </div>
    <BulkTable
      actionable={
        Object {
          "cloneable": [Function],
          "deletable": [Function],
          "editable": [Function],
        }
      }
      columns={
        Array [
          Object {
            "accessor": "name",
            "header": "Data Profile",
          },
          Object {
            "accessor": "profile_type",
            "header": "Mode",
          },
          Object {
            "accessor": "type",
            "header": "Type",
          },
          Object {
            "accessor": "updated_at",
            "header": "Last Modified",
          },
          Object {
            "accessor": "updated_by",
            "header": "Last Updated By",
          },
        ]
      }
      customCellRender={[Function]}
      data={Array []}
      defaultSortBy="updated_at"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
          "filterOptions": Array [],
          "filterable": false,
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": undefined,
          "pageCount": undefined,
          "totalCount": 0,
          "totalPages": undefined,
        }
      }
      requestResults={[Function]}
      selectable={false}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={Array []}
      sortable={true}
      updateFilters={null}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      addClassName="dlp-root modal"
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      size="md"
    >
      <ModalHeader
        enableClose={true}
        title="Add a Data Profile"
      />
      <CardBody
        addClassName="newModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div>
          <p
            className="helpTitle"
          >
            Select the type of Data Profile to create:
          </p>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Pattern Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Classic Data Profile
              </h5>
              <p>
                Create a classic data profile that is editable on the Panorama management server. Cannot include advanced detection methods such as EDM. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="EDM/Hybrid Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Advanced Data Profile
              </h5>
              <p>
                Create an advanced data profile with data patterns and advanced detection methods. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Multi Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                With Nested Data Profiles 
              </h5>
              <p>
                Create a data profile containing multiple nested data profiles. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <br />
        </div>
      </CardBody>
      <ModalFooter>
        <ModalCloseButton>
          actions.cancel
        </ModalCloseButton>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<DataProfileTable> request results matches snapshot 1`] = `
<div
  className="data_profiles"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <a
        className="btn-link"
        href="/"
      >
        application.dlp
      </a>
      <span
        className="fa fa-chevron-right separator"
      />
      data_profiles.index.title
    </div>
    <div
      className="title"
    >
      data_profiles.index.title
    </div>
  </div>
  <div
    className="dataProfileTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        data_profiles.index.title
        <span
          className="count"
        >
          (0)
        </span>
      </span>
      <span
        className="actions tw-float-right"
      >
        <Button
          appearance="primary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          data_profiles.index.add
        </Button>
      </span>
      <span
        className="tw-float-right rightInnerAddOn searchBar"
      >
        <input
          className="form-control"
          onChange={[Function]}
          placeholder="data_profiles.index.search"
          value=""
        />
        <i>
          <FaSearch />
        </i>
      </span>
    </div>
    <BulkTable
      actionable={
        Object {
          "cloneable": [Function],
          "deletable": [Function],
          "editable": [Function],
        }
      }
      columns={
        Array [
          Object {
            "accessor": "name",
            "header": "Data Profile",
          },
          Object {
            "accessor": "profile_type",
            "header": "Mode",
          },
          Object {
            "accessor": "type",
            "header": "Type",
          },
          Object {
            "accessor": "updated_at",
            "header": "Last Modified",
          },
          Object {
            "accessor": "updated_by",
            "header": "Last Updated By",
          },
        ]
      }
      customCellRender={[Function]}
      data={Array []}
      defaultSortBy="updated_at"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
          "filterOptions": Array [],
          "filterable": false,
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": undefined,
          "pageCount": undefined,
          "totalCount": 0,
          "totalPages": undefined,
        }
      }
      requestResults={[Function]}
      selectable={false}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={Array []}
      sortable={true}
      updateFilters={null}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      addClassName="dlp-root modal"
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      size="md"
    >
      <ModalHeader
        enableClose={true}
        title="Add a Data Profile"
      />
      <CardBody
        addClassName="newModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div>
          <p
            className="helpTitle"
          >
            Select the type of Data Profile to create:
          </p>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Pattern Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Classic Data Profile
              </h5>
              <p>
                Create a classic data profile that is editable on the Panorama management server. Cannot include advanced detection methods such as EDM. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="EDM/Hybrid Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Advanced Data Profile
              </h5>
              <p>
                Create an advanced data profile with data patterns and advanced detection methods. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Multi Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                With Nested Data Profiles 
              </h5>
              <p>
                Create a data profile containing multiple nested data profiles. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <br />
        </div>
      </CardBody>
      <ModalFooter>
        <ModalCloseButton>
          actions.cancel
        </ModalCloseButton>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<DataProfileTable> shows correct header renders 1`] = `
<div
  className="data_profiles"
>
  <div
    className="header main"
  >
    <div
      className="breadcrumb"
    >
      <a
        className="btn-link"
        href="/"
      >
        application.dlp
      </a>
      <span
        className="fa fa-chevron-right separator"
      />
      data_profiles.index.title
    </div>
    <div
      className="title"
    >
      data_profiles.index.title
    </div>
  </div>
  <div
    className="dataProfileTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        data_profiles.index.title
        <span
          className="count"
        >
          (0)
        </span>
      </span>
      <span
        className="actions tw-float-right"
      >
        <Button
          appearance="primary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          data_profiles.index.add
        </Button>
      </span>
      <span
        className="tw-float-right rightInnerAddOn searchBar"
      >
        <input
          className="form-control"
          onChange={[Function]}
          placeholder="data_profiles.index.search"
          value=""
        />
        <i>
          <FaSearch />
        </i>
      </span>
    </div>
    <BulkTable
      actionable={
        Object {
          "cloneable": [Function],
          "deletable": [Function],
          "editable": [Function],
        }
      }
      columns={
        Array [
          Object {
            "accessor": "name",
            "header": "Data Profile",
          },
          Object {
            "accessor": "profile_type",
            "header": "Mode",
          },
          Object {
            "accessor": "type",
            "header": "Type",
          },
          Object {
            "accessor": "updated_at",
            "header": "Last Modified",
          },
          Object {
            "accessor": "updated_by",
            "header": "Last Updated By",
          },
        ]
      }
      customCellRender={[Function]}
      data={Array []}
      defaultSortBy="updated_at"
      defaultSortOrder="asc"
      filters={
        Object {
          "currentFilterSelections": Object {},
          "currentFilters": Array [],
          "filterOptions": Array [],
          "filterable": false,
        }
      }
      loading={true}
      pageData={
        Object {
          "currentPage": undefined,
          "pageCount": undefined,
          "totalCount": 0,
          "totalPages": undefined,
        }
      }
      requestResults={[Function]}
      selectable={false}
      selectedItems={Array []}
      showDateRangePicker={false}
      sortExclusionList={Array []}
      sortable={true}
      updateFilters={null}
      updateSortControls={[Function]}
      updateState={[Function]}
      useRouter={false}
    />
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      addClassName="dlp-root modal"
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      size="md"
    >
      <ModalHeader
        enableClose={true}
        title="Add a Data Profile"
      />
      <CardBody
        addClassName="newModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div>
          <p
            className="helpTitle"
          >
            Select the type of Data Profile to create:
          </p>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Pattern Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Classic Data Profile
              </h5>
              <p>
                Create a classic data profile that is editable on the Panorama management server. Cannot include advanced detection methods such as EDM. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="EDM/Hybrid Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                Advanced Data Profile
              </h5>
              <p>
                Create an advanced data profile with data patterns and advanced detection methods. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <div
            className="formCommon"
            onClick={[Function]}
          >
            <img
              alt="Multi Profile"
              className="text-center profileImg"
            />
            <span
              className="tw-float-right profileDesc"
            >
              <h5>
                With Nested Data Profiles 
              </h5>
              <p>
                Create a data profile containing multiple nested data profiles. This data profile cannot be edited on the Panorama management server. 
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  Learn More
                </span>
              </p>
            </span>
          </div>
          <br />
        </div>
      </CardBody>
      <ModalFooter>
        <ModalCloseButton>
          actions.cancel
        </ModalCloseButton>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;
