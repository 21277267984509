// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<ColumnTable /> columm table matches snapshot 1`] = `
<table
  className="table table-borderless table-hover displayTable"
>
  <thead
    className="table-bordered"
  >
    <tr>
      <th
        className="name table-bordered"
      >
        Field Name
      </th>
      <th
        className="type table-bordered"
      >
        Data Type
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      key="Social Security Number"
    >
      <td
        style={
          Object {
            "textAlign": "left",
          }
        }
      >
        Social Security Number
      </td>
      <td
        style={
          Object {
            "textAlign": "left",
          }
        }
      >
        SSN
      </td>
    </tr>
    <tr
      key="Credit Card Number"
    >
      <td
        style={
          Object {
            "textAlign": "left",
          }
        }
      >
        Credit Card Number
      </td>
      <td
        style={
          Object {
            "textAlign": "left",
          }
        }
      >
        CCN
      </td>
    </tr>
  </tbody>
</table>
`;
