// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<Table> renders 1`] = `
<div
  className="bulkTable"
>
  <table
    className="table"
  >
    <thead
      className="table-bordered"
    >
      <tr>
        <th
          className="name table-bordered"
          key="token_name"
        >
          api_tokens.index.token_name
        </th>
        <th
          className="createdDate table-bordered"
          key="create"
        >
          api_tokens.index.create
        </th>
        <th
          className="expiryDate table-bordered"
          key="expiry"
        >
          api_tokens.index.expiry
        </th>
        <th
          className="action_item table-bordered"
          key="revoke"
        >
          api_tokens.index.revoke
        </th>
        <th
          className="action_item table-bordered"
          key="renew"
        >
          api_tokens.index.renew
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        key="123"
      >
        <td
          className="name"
          key="token_name"
        />
        <td
          className="createdDate"
          key="create"
        >
          July 8 2020
        </td>
        <td
          className="expiryDate"
          key="expiry"
        >
          July 8 2021
        </td>
        <td>
          <div
            className="text-center action_item"
            onClick={[Function]}
          >
            <FaTimes />
          </div>
        </td>
        <td>
          <div
            className="text-center action_item"
            onClick={[Function]}
          >
            <FaSync />
          </div>
        </td>
      </tr>
      <tr
        key="456"
      >
        <td
          className="name"
          key="token_name"
        />
        <td
          className="createdDate"
          key="create"
        >
          July 8 2020
        </td>
        <td
          className="expiryDate"
          key="expiry"
        >
          July 8 2021
        </td>
        <td>
          <div
            className="text-center action_item"
            onClick={[Function]}
          >
            <FaTimes />
          </div>
        </td>
        <td>
          <div
            className="text-center action_item"
            onClick={[Function]}
          >
            <FaSync />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
`;

exports[`<Table> renders without action column for read only user 1`] = `
<div
  className="bulkTable"
>
  <table
    className="table"
  >
    <thead
      className="table-bordered"
    >
      <tr>
        <th
          className="name table-bordered"
          key="token_name"
        >
          api_tokens.index.token_name
        </th>
        <th
          className="createdDate table-bordered"
          key="create"
        >
          api_tokens.index.create
        </th>
        <th
          className="expiryDate table-bordered"
          key="expiry"
        >
          api_tokens.index.expiry
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        key="123"
      >
        <td
          className="name"
          key="token_name"
        />
        <td
          className="createdDate"
          key="create"
        >
          July 8 2020
        </td>
        <td
          className="expiryDate"
          key="expiry"
        >
          July 8 2021
        </td>
      </tr>
      <tr
        key="456"
      >
        <td
          className="name"
          key="token_name"
        />
        <td
          className="createdDate"
          key="create"
        >
          July 8 2020
        </td>
        <td
          className="expiryDate"
          key="expiry"
        >
          July 8 2021
        </td>
      </tr>
    </tbody>
  </table>
</div>
`;
