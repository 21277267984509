// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<FileUpload /> should match snapshot 1`] = `
<div
  className="dlp-file-upload"
>
  <section
    className="container"
  >
    <div
      className="disable-mask"
    />
    <div
      className="dropzone"
      data-testid="file-drop-input"
      onBlur={[Function]}
      onClick={[Function]}
      onDragEnter={[Function]}
      onDragLeave={[Function]}
      onDragOver={[Function]}
      onDrop={[Function]}
      onFocus={[Function]}
      onKeyDown={[Function]}
      role="presentation"
      tabIndex={0}
    >
      <input
        multiple={true}
        onChange={[Function]}
        onClick={[Function]}
        style={
          Object {
            "display": "none",
          }
        }
        tabIndex={-1}
        type="file"
      />
      <p
        className="info-text"
      >
        Drag and drop one file here
      </p>
      <p
        className="info-text"
      >
        or
      </p>
      <div
        className="file-upload-button"
        data-testid="file-upload-button"
        onClick={[Function]}
      >
        <i>
          <FaArrowAltCircleUp />
        </i>
        Browse Files
      </div>
    </div>
  </section>
</div>
`;
