import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaAngleDown, FaAngleRight, FaLayerGroup, FaParking, FaTable } from 'react-icons/fa';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}

const DataProfileDetailPreview = (props) => {
  const [collapsed, setCollapsed] = useState({});
  const { dataPatternRules, schema, primaryExpTree, secondaryExpTree, datasets, change, changedPrimaryExpTree, changedSecondaryExpTree, changedDataPatternRules, multi, primaryProfiles, secondaryProfiles, changedPrimaryProfiles, changedSecondaryProfiles } = props;

  const occurrenceDetails = (rule, includeConfidence) => {
    let str = ''

    if (!rule) { return str; }

    if (rule.occurrence_operator_type === 'any') {
      str = 'Any occurence'
    }
    else if (rule.occurrence_operator_type === 'between') {
      str = `Between (inclusive) ${rule.occurrence_low || '?'} and ${rule.occurrence_high || '?'}`
    }
    else if (rule.occurrence_operator_type === 'more_than_equal_to') {
      str = `More than or equal to ${rule.occurrence_count}`
    }
    else if (rule.occurrence_operator_type === 'less_than_equal_to') {
      str = `Less than or equal to ${rule.occurrence_count}`
    }
    else {
      str = '?'
    }
    if (rule.detection_technique !== 'document_fingerprint' && includeConfidence) {
      str += ` - ${rule.confidence_level || '?'}`
    }

    if (rule.by_unique_count) {
      str += ' unique occurrences'
    }

    return str;
  }

  const fieldsPreview = (fields, matchType) => {
    const fieldPreview = ['('];
    fields.forEach((field, i) => {
      fieldPreview.push(<span key={field?.name}>{field?.name}</span>);
      if(i < (fields.length - 1)) {
        fieldPreview.push(<span key={i} className='primaryBlue'> {t(`match_preview.${matchType}`).toUpperCase()} </span>)
      }
    });
    fieldPreview.push(')')
    return fieldPreview
  }

  const levelEmpty = (level) => {
    if (multi) {
      return level === 'primary' ? primaryProfiles.length === 0 : secondaryProfiles.length === 0
    }
    if (schema === 2) {
      if (level === 'primary') {
        return Object.keys(primaryExpTree).length === 0 || primaryExpTree.sub_expressions.length === 0
      }
      return Object.keys(secondaryExpTree).length === 0 || secondaryExpTree.sub_expressions.length === 0
    }
    if (level === 'primary') {
      return Object.keys(dataPatternRules[0]).length === 0
    }
    return Object.keys(dataPatternRules[1]).length === 0
  }

  const patternGroup = (condition, conditionIndex, ruleIndex, changedCondition) => {
    const ruleItems = [];
    condition.rule_items.forEach((item, index) => {
      const changedRuleItem = (changedCondition?.rule_items || []).find((rule) => rule.id === item.id);
      const changedRuleItemIndex = changedRuleItem ? changedCondition.rule_items.indexOf(changedRuleItem) : 0;
      ruleItems.push(
        <div key={index} className={`${change && (!(changedCondition?.rule_items || []).some((rule) => rule.id === item.id)) ? change : ''} pattern`} style={{marginLeft: '17px'}}>
          <FaParking className='mr10 helpText' />
          {condition.operators[index - 1] &&
            <span className={changedCondition && condition.operators[index - 1] !== changedCondition.operators[changedRuleItemIndex - 1] ? change : ''}>
              {<span className='upperBold mr5'>{(condition.operators[index - 1] || '').split('_')[0]}</span>}
              {(condition.operators[index - 1] || '').includes('not') && <span className='upperBold mr5 codeRed'>not</span>}
            </span>
          }
          {item.name || 'Undefined'}
          <br />
          <span className={`${change && occurrenceDetails(changedRuleItem, true) !== occurrenceDetails(item, true) ? change : '' } helpText`} style={{marginLeft: '22px'}}>{occurrenceDetails(item, true)}</span>
          <br />
        </div>
      )
    })

    return(
      <div className={collapsed[`patternGroup${ruleIndex}_${conditionIndex}`] || collapsed[`patterns${ruleIndex}`] ? 'hidden' : 'sectionGroup'}>
        {ruleItems}
      </div>
    )
  }

  const v1MultiOp = (conditionIndex, rule, changedRule = null) => {
    if (conditionIndex > 0) {
      return(
        <span className={changedRule && changedRule.operators && changedRule.operators[conditionIndex - 1] !== rule.operators[conditionIndex - 1] ? change : ''}>
          <span className='upperBold mr5'>{(rule.operators[conditionIndex - 1] || '').split('_')[0]}</span>
          {(rule.operators[conditionIndex - 1] || '').includes('not') &&
            <span className='upperBold mr5 codeRed'>not</span>
          }
          {(rule.conditions[conditionIndex] && rule.conditions[conditionIndex].rule_items.length === 1 && rule.conditions[conditionIndex].operators[conditionIndex - 1] || '').includes('not') &&
            <span className='upperBold mr5 codeRed'>not</span>
          }
        </span>
      )
    }
    if(conditionIndex === 0 && (rule.conditions[0]?.operators[0] || '').includes('not') && rule.conditions[0].rule_items.length === 1) {
      return(
        <span>
          <span className='upperBold mr5 codeRed'>not</span>
          {(rule.conditions[0]?.operators[0] || '').split('_')[0] === 'and' ? 'All' : 'Any'}
        </span>
      )
    }
    return '';
  }

  const nestedConditions = (rule, ruleIndex, changedRule) => {
    return (
      rule.conditions.map((condition, conditionIndex) =>
        <div className='nestedSection' key={conditionIndex}>
          <div className='nestedSectionHead'>
            { collapsed[`patternGroup${ruleIndex}_${conditionIndex}`] ?
              <span className='angleToggle' onClick={() => setCollapsed({...collapsed, [`patternGroup${ruleIndex}_${conditionIndex}`]: false})}><FaAngleRight /></span>
              :
              <span className='angleToggle' onClick={() => setCollapsed({...collapsed, [`patternGroup${ruleIndex}_${conditionIndex}`]: true})}><FaAngleDown /></span>
            }
            <FaLayerGroup className='mr10 ml10 muteText' />
            {v1MultiOp(conditionIndex, rule, changedRule)}
          </div>
          {patternGroup(condition, conditionIndex, ruleIndex, changedRule?.conditions ? changedRule.conditions[conditionIndex] : null)}
        </div>
      )
    )
  }

  const patternList = (level) => {
    const ruleIndex = level === 'primary' ? 0 : 1;
    if (Object.keys(dataPatternRules[ruleIndex]).length > 0) {
      const {conditions} = dataPatternRules[ruleIndex];
      return (
        <div>
          <div className='sectionHead'>
            { collapsed[`patterns${ruleIndex}`] ?
              <span className='angleToggle' onClick={() => setCollapsed({...collapsed, [`patterns${ruleIndex}`]: false})}><FaAngleRight /></span>
              :
              <span className='angleToggle' onClick={() => setCollapsed({...collapsed, [`patterns${ruleIndex}`]: true})}><FaAngleDown /></span>
            }
            <FaLayerGroup className='mr10 ml10 muteText' />
            <span>{t('match_data_pattern_criteria')}</span>
          </div>
          {conditions && conditions.length > 1 &&
            <div className={collapsed[`patterns${ruleIndex}`] ? 'hidden' : 'sectionGroup'}>
              {nestedConditions(dataPatternRules[ruleIndex], ruleIndex, change ? changedDataPatternRules[ruleIndex] : null)}
            </div>
          }
          {conditions && conditions.length === 1 &&
            patternGroup(conditions[0], 0, ruleIndex, change ? changedDataPatternRules[ruleIndex].conditions[0] : null)
          }
        </div>
      )
    }
    return '';
  }

  const v2PatternItem = (ruleItem, index, operatorType, changedOpType = null, changedRuleItem = null) => {
    const detailChange = change && changedRuleItem?.id === ruleItem.id;
    return (
      <div key={index} className='pattern' style={{marginLeft: '17px'}}>
        <FaParking className='mr10 helpText' />
        {index > 0 && <span className={`upperBold mr5 ${change && changedOpType !== operatorType ? change : ''}`}>{operatorType}</span>}
        {ruleItem.name || 'Undefined'}
        <br />
        <span className={`helpText ${detailChange && occurrenceDetails(ruleItem, true) !== occurrenceDetails(changedRuleItem, true) ? change : ''}`}
          style={{marginLeft: '22px'}}
        >
          {occurrenceDetails(ruleItem, true)}
        </span>
        <br />
      </div>
    )
  }

  const edmItem = (ruleItem, index, operatorType, changedOpType = null, changedRuleItem = null) => {
    const detailChange = change && changedRuleItem?.id === ruleItem.id;
    const dataset = datasets.find((d) => d.id === ruleItem.edm_dataset_id) || {};
    const primaryFields = (ruleItem.primary_fields || []).map(pf => dataset.active_fields?.find(af => af.id === pf)).filter(x => x !== undefined) || [];
    const secondaryFields = (ruleItem.secondary_fields || []).map(pf => dataset.active_fields?.find(af => af.id === pf)).filter(x => x !== undefined) || [];
    const changedPrimaryFields = detailChange ? (changedRuleItem?.primary_fields || []).map(pf => dataset.active_fields?.find(af => af.id === pf)).filter(x => x !== undefined) : [];
    const changedSecondaryFields = detailChange ? (changedRuleItem?.secondary_fields || []).map(pf => dataset.active_fields?.find(af => af.id === pf)).filter(x => x !== undefined) : [];
    return (
      <div key={index} className='dataset' style={{marginLeft: '17px'}}>
        <FaTable className='mr10 helpText' />
        {index > 0 && <span className={`upperBold mr5 ${change && changedOpType !== operatorType ? change : ''}`}>{operatorType}</span>}
        {dataset.name || 'Undefined'}
        <br />
        <div className={`helpText ${detailChange && occurrenceDetails(changedRuleItem, false) !== occurrenceDetails(ruleItem, false) ? change : ''}`} style={{marginLeft: '23px'}}>{occurrenceDetails(ruleItem, false)}</div>
        <div className='matchDef helpText' style={{marginLeft: '24px'}}>
          <span className='mr5'>{t('match')}</span>
          <span className={detailChange && (ruleItem.primary_match_criteria !== changedRuleItem.primary_match_criteria || ruleItem.primary_match_any_count !== changedRuleItem.primary_match_any_count) ? change : ''}>
            <span className='mr5 codeRed'>{ruleItem.primary_match_criteria || t('match_type.all')}</span>
            {ruleItem.primary_match_criteria === 'any' && <span className='mr5 codeRed'>{ruleItem.primary_match_any_count || '?'}</span> }
          </span>
          <span className='mr5'>{t('in')}</span>
          <span className='mr5'>
            <span className={detailChange && primaryFields.join() !== changedPrimaryFields.join() ? change : ''}>
              {fieldsPreview(primaryFields, ruleItem.primary_match_criteria)}
            </span>
          </span>
          {ruleItem.secondary_fields && ruleItem.secondary_fields.length > 0 &&
            <span className={detailChange && changedSecondaryFields.length === 0 ? change : ''}>
              <span className='mr5 primaryBlue'>{t('dataset_form.and').toUpperCase()}</span>
              <span className='mr5'>{t('match')}</span>
              <span className={detailChange && (ruleItem.secondary_match_criteria !== changedRuleItem.secondary_match_criteria || ruleItem.secondary_match_any_count !== changedRuleItem.secondary_match_any_count) ? change : ''}>
                <span className='mr5 codeRed'>{ruleItem.secondary_match_criteria || t('match_type.all')}</span>
                {ruleItem.secondary_match_criteria === 'any' && <span className='mr5 codeRed'>{ruleItem.secondary_match_any_count || '?'}</span> }
              </span>
              <span className='mr5'>{t('in')}</span>
              <span className={detailChange && secondaryFields.join() !== changedSecondaryFields.join() ? change : ''}>
                {fieldsPreview(secondaryFields, ruleItem.secondary_match_criteria)}
              </span>
            </span>
          }
        </div>
      </div>
    );
  }

  const v2ItemList = (subExpressions, position, operatorType, changedSubExps = [], changedOpType = null) => {
    const list = [];
    const changedMap = (changedSubExps || []).map( se => `${se.operator_type}-${se.rule_item?.id}-${se.sub_expressions.length}-${(se.sub_expressions?.length || 0) > 0 && se.sub_expressions[0].rule_item?.id}` );
    const map = (subExpressions || []).map( se => `${se.operator_type}-${se.rule_item?.id}-${se.sub_expressions.length}-${(se.sub_expressions?.length || 0) > 0 && se.sub_expressions[0].rule_item?.id}` );
    const mapDiff = map.filter(x => !changedMap.includes(x));

    subExpressions.forEach((subExp, index) => {
      const key = `${subExp.operator_type}-${subExp.rule_item?.id}-${subExp.sub_expressions.length}-${(subExp.sub_expressions?.length || 0) > 0 && subExp.sub_expressions[0].rule_item?.id}`;
      const compareIndex = changedMap.indexOf(key) >= 0 ? changedMap.indexOf(key) : index;
      const compareGrp = changedSubExps[compareIndex];

      if (subExp.rule_item !== null) {
        const changeDetected = change && mapDiff.includes(key);
        if (subExp.rule_item.detection_technique === 'edm') {
          list.push(<div key={index} className={changeDetected ? change : ''}>{edmItem(subExp.rule_item, index, operatorType, changedOpType, compareGrp?.rule_item)}</div>)
        }
        else {
          list.push(<div key={index} className={changeDetected ? change : ''}>{v2PatternItem(subExp.rule_item, index, operatorType, changedOpType, compareGrp?.rule_item)}</div>)
        }
      }
      else {
        list.push(
          <div className='nestedSection' key={`${position}-${index}`}>
            <div className='nestedSectionHead'>
              { collapsed[`sectionGroup${position}-${index}`] ?
                <span className='angleToggle' onClick={() => setCollapsed({...collapsed, [`sectionGroup${position}-${index}`]: false})}><FaAngleRight /></span>
                :
                <span className='angleToggle' onClick={() => setCollapsed({...collapsed, [`sectionGroup${position}-${index}`]: true})}><FaAngleDown /></span>
              }
              <FaLayerGroup className='mr10 ml10 muteText' />
              <span className={`upperBold mr5 ${subExp.operator_type === 'not' ? 'codeRed' : ''} ${change && subExp.operator_type !== compareGrp?.operator_type ? change : ''}`}>{subExp.operator_type}</span>
            </div>
            <div className={collapsed[`sectionGroup${position}-${index}`] ? 'hidden' : 'sectionGroup'}>
              {v2ItemList(subExp.sub_expressions, `${position}-${index}`, subExp.operator_type, compareGrp?.sub_expressions, compareGrp?.operator_type)}
            </div>
          </div>
        )
      }
    });
    return list;
  }

  const profileList = (profiles, changedProfiles = []) => {
    const list = [];
    profiles.forEach((profile, index) => {
      list.push(
        <div key={`${profile.value}-${index}`} className='pattern' style={{marginLeft: '17px'}}>
          <FaParking className='mr10 helpText' />
          {index > 0 && <span className='upperBold mr5'>or</span>}
          <span className={change && !changedProfiles.includes(profile.value) ? change : ''}>{profile.value || 'Undefined'}</span>
          <br />
        </div>
      )
    });
    return list;
  }

  return (
    <div style={{padding: change ? '0px' : '15px'}}>
      { ['primary', 'secondary'].map(level =>
        <div className='rule' key={level}>
          <p className='form-label conditionLabel'>{t(`${level}_rule`)}</p>
          {levelEmpty(level) && <span className='muteText' style={{lineHeight: '20px'}}>{t('no_criteria_defined')}</span>}
          {!levelEmpty(level) && schema === 1 &&
            <div>
              {patternList(level)}
            </div>
          }
          {!levelEmpty(level) && schema === 2 && multi &&
            <div className='sectionGroup'>
              {level === 'primary' && profileList(primaryProfiles, changedPrimaryProfiles)}
              {level === 'secondary' && profileList(secondaryProfiles, changedSecondaryProfiles)}
            </div>
          }
          {!levelEmpty(level) && schema === 2 && !multi &&
            <div className='sectionGroup'>
              {level === 'primary' && v2ItemList(primaryExpTree.sub_expressions, '0', primaryExpTree.operator_type, changedPrimaryExpTree.sub_expressions, changedPrimaryExpTree.operator_type)}
              {level === 'secondary' && v2ItemList(secondaryExpTree.sub_expressions, '1', secondaryExpTree.operator_type, changedSecondaryExpTree.sub_expressions, changedSecondaryExpTree.operator_type)}
            </div>
          }
          {level === 'primary' && <hr /> }
        </div>
      )}
    </div>
  )
}

DataProfileDetailPreview.propTypes = {
  dataPatternRules: PropTypes.array,
  primaryExpTree: PropTypes.shape({
    operator_type: PropTypes.string,
    rule_item: PropTypes.shape({}),
    sub_expressions: PropTypes.array,
  }),
  secondaryExpTree: PropTypes.shape({
    operator_type: PropTypes.string,
    rule_item: PropTypes.shape({}),
    sub_expressions: PropTypes.array,
  }),
  datasets: PropTypes.array,
  schema: PropTypes.number,
  change: PropTypes.string,
  changedDataPatternRules: PropTypes.array,
  changedPrimaryExpTree: PropTypes.shape({
    operator_type: PropTypes.string,
    rule_item: PropTypes.shape({}),
    sub_expressions: PropTypes.array,
  }),
  changedSecondaryExpTree: PropTypes.shape({
    operator_type: PropTypes.string,
    rule_item: PropTypes.shape({}),
    sub_expressions: PropTypes.array,
  }),
  primaryProfiles: PropTypes.array,
  secondaryProfiles: PropTypes.array,
  changedPrimaryProfiles: PropTypes.array,
  changedSecondaryProfiles: PropTypes.array,
  multi: PropTypes.bool,
};

DataProfileDetailPreview.defaultProps = {
  dataPatternRules: [{}, {}],
  primaryExpTree: {},
  secondaryExpTree: {},
  datasets: [],
  schema: 2,
  change: undefined,
  changedDataPatternRules: undefined,
  changedPrimaryExpTree: {},
  changedSecondaryExpTree: {},
  primaryProfiles: [],
  secondaryProfiles: [],
  changedPrimaryProfiles: [],
  changedSecondaryProfiles: [],
  multi: false,
}
export default DataProfileDetailPreview
