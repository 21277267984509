// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<MatchingDataPatterns> minSnippetView should handle null snippets 1`] = `
Array [
  <span
    className="snippet"
  >
    <span>
      
       
    </span>
    <span
      className="highlight"
    >
      12345678
    </span>
    <span>
       
      abcdef
       
    </span>
  </span>,
]
`;

exports[`<MatchingDataPatterns> minSnippetView should prioritize detection with long left field 1`] = `
Array [
  <span
    className="snippet"
  >
    <span>
      really long string really long string really long string really long string really long string really long string really long string really long string really long string really long string really long string really long string really long st ...
       
    </span>
    <span>
       
      
       
    </span>
  </span>,
]
`;

exports[`<MatchingDataPatterns> renders 1`] = `
<div
  className="dataPatterns"
>
  <div
    className="card-title patternTitle"
  >
    incidents.show.matches_within_profile
  </div>
  <div
    className="subHeading"
  >
    <span
      className="subHeaderTab muteText"
    >
      incidents.show.confidence_level
      :
    </span>
    <span
      className="subHeaderTab confSelector active"
      onClick={[Function]}
    >
      incidents.show.high
      <span
        className="helpText"
      >
          (
        2
         Patterns)
      </span>
    </span>
    <span
      className="subHeaderTab confSelector false"
      onClick={[Function]}
    >
      incidents.show.medium
      <span
        className="helpText"
      >
          (
        2
         Patterns)
      </span>
    </span>
    <span
      className="subHeaderTab confSelector false"
      onClick={[Function]}
    >
      incidents.show.low
      <span
        className="helpText"
      >
          (
        3
         Patterns)
      </span>
    </span>
  </div>
  <table
    className="table table-condensed matchTable"
  >
    <tbody>
      <tr
        className="visible"
        key="pattern2"
      >
        <td
          className="col-md-4"
        >
          <div
            className="patternName"
          >
            Data Pattern - Pattern2
          </div>
          <span
            className="occurrenceCount"
          >
            2
             
            incidents.show.occurrences
          </span>
        </td>
        <td
          className="col-md-8 snippetResults"
        >
          <div
            className="snippetsCenter"
          >
            <div>
              <img
                alt="loading"
                id="loader"
              />
            </div>
          </div>
        </td>
      </tr>
      <tr
        className="visible"
        key="dataset1"
      >
        <td
          className="col-md-4"
        >
          <div
            className="patternName"
          >
            Data Pattern - Dataset1
          </div>
          <span
            className="occurrenceCount"
          >
            1
             
            incidents.show.occurrences
          </span>
        </td>
        <td
          className="col-md-8 snippetResults"
        >
          <div
            className="snippetsCenter"
          >
            <div>
              <img
                alt="loading"
                id="loader"
              />
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
`;

exports[`<MatchingDataPatterns> with snippets requested renders loading state 1`] = `
<div
  className="dataPatterns"
>
  <div
    className="card-title patternTitle"
  >
    incidents.show.matches_within_profile
  </div>
  <div
    className="subHeading"
  >
    <span
      className="subHeaderTab muteText"
    >
      incidents.show.confidence_level
      :
    </span>
    <span
      className="subHeaderTab confSelector active"
      onClick={[Function]}
    >
      incidents.show.high
      <span
        className="helpText"
      >
          (
        1
         Patterns)
      </span>
    </span>
    <span
      className="subHeaderTab confSelector false"
      onClick={[Function]}
    >
      incidents.show.medium
      <span
        className="helpText"
      >
          (
        2
         Patterns)
      </span>
    </span>
    <span
      className="subHeaderTab confSelector false"
      onClick={[Function]}
    >
      incidents.show.low
      <span
        className="helpText"
      >
          (
        3
         Patterns)
      </span>
    </span>
  </div>
  <table
    className="table table-condensed matchTable"
  >
    <tbody>
      <tr
        className="visible"
        key="pattern2"
      >
        <td
          className="col-md-4"
        >
          <div
            className="patternName"
          >
            Data Pattern - Pattern2
          </div>
          <span
            className="occurrenceCount"
          >
            2
             
            incidents.show.occurrences
          </span>
        </td>
        <td
          className="col-md-8 snippetResults"
        >
          <div
            className="snippetsCenter"
          >
            <div>
              <img
                alt="loading"
                id="loader"
              />
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
`;
