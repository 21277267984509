import React from 'react';
import PropTypes from 'prop-types';
import ReportCard from './ReportCard'
import MatchingDataPatterns from './MatchingDataPatterns'

class ReportDetails extends React.Component {
  state = {
    report: this.props.report,
    patternResults: [],
    highMatches: {},
    medMatches: {},
    lowMatches: {},
    currentMatches: {},
    confidence: 'high_confidence_frequency',
  };

  componentDidMount() {
    const high = {};
    const med = {};
    const low = {};
    this.props.report.data_pattern_results.forEach(match => {
      if (match.high_confidence_frequency > 0) { high[match.data_pattern_id] = match; }
      if (match.medium_confidence_frequency > 0) { med[match.data_pattern_id] = match; }
      if (match.low_confidence_frequency > 0) { low[match.data_pattern_id] = match; }
    });
    this.setState({ patternResults: this.props.report.data_pattern_results, highMatches: high, medMatches: med, lowMatches: low, currentMatches: high });
  }

  render() {
    const { report, patternResults, highMatches, medMatches, lowMatches, confidence, currentMatches } = this.state;
    return (
      <div className='detailsView'>
        <ReportCard report={report} userRole={this.props.userRole} />
        { patternResults.length > 0 &&
          <MatchingDataPatterns
            highMatches={highMatches}
            medMatches={medMatches}
            lowMatches={lowMatches}
            currentMatches={currentMatches}
            confidence={confidence}
            report_id={report.report_id}
          />
        }
      </div>
    );
  }
}

ReportDetails.propTypes = {
  report: PropTypes.shape({
    report_id: PropTypes.string,
    asset_name: PropTypes.string,
    asset_id: PropTypes.string,
    data_profile_name: PropTypes.string,
    channel: PropTypes.string,
    scan_date: PropTypes.string,
    asset_type: PropTypes.string,
    asset_size: PropTypes.string,
    data_pattern_results: PropTypes.array,
  }),
  userRole: PropTypes.string,
};

ReportDetails.defaultProps = {
  report: {},
  userRole: 'READ_ONLY'
};

export default ReportDetails;