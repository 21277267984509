import React from 'react';
import '@testing-library/jest-dom/extend-expect';
import { shallow } from 'enzyme';
import { act } from 'react-dom/test-utils';
import { render, fireEvent, screen } from '@testing-library/react';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import sinon from 'sinon';
import { expect as chaiExpect } from 'chai';

import ReportCard from './ReportCard';
import { Popover } from '@panwds/react-ui';

const axiosMock = new MockAdapter(axios);

describe('<ReportCard />', () => {
  let sandbox, wrapper;
  const originalLocAssign = window.location.assign;
  const locationAssignStub = sinon.stub();
  const baseProps = {
    incident: {
      reportId: '12345',
      filename: 'Test Asset',
      bucketConfigured: true,
      dataProfile: 'Bank Account Number North American',
      channel: 'prisma-access',
      scanDate: '2020-07-09T20:56:14.620Z'
    },
    downloadURL: '/download/doge',
    userRole: 'READ_WRITE'
  };
  const mockView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return shallow(<ReportCard {...props} />);
  };
  const renderView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    render(
      <ReportCard
        {...props}
      />
    );
  };

  beforeEach(() => {
    delete window.location;
    window.location = { assign: locationAssignStub };

    axiosMock.reset();
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    delete window.location;
    window.location = { assign: originalLocAssign };

    sandbox.restore();
  });

  it('should match snapshot', () => {
    wrapper = mockView();

    expect(wrapper).toMatchSnapshot();
  });

  it('should render when bucket configured', () => {
    wrapper = mockView({ userRole: 'READ_ONLY' });

    chaiExpect(wrapper.getElement()).not.to.be.undefined;
  });

  it('should render when bucket NOT configured', () => {
    wrapper = mockView({
      incident: {
        reportId: '12345',
        filename: 'Test Asset',
        bucketConfigured: false,
        dataProfile: 'Bank Account Number North American',
        channel: 'prisma-access',
        scanDate: '2020-07-09T20:56:14.620Z'
      }
    });

    chaiExpect(wrapper.getElement()).not.to.be.undefined;
  });

  it('should render when downloadURL is missing', () => {
    wrapper = mockView({
      downloadURL: null
    });

    chaiExpect(wrapper.getElement()).not.to.be.undefined;
  });

  it('should render when bucket type is sftp', async () => {
    axiosMock.onGet().reply((config) => {
      expect(config.url).toBe('/download/doge');

      return [200, {
        hasError: false,
        additionalDetails: {
          connectionType: 'sftp',
          fileLocationInfo: 'sftp bucket file location path'
        }
      }];
    });

    renderView({});

    await act(async () => {
      fireEvent.click(screen.getByTestId('trigger-download'));
    });

    chaiExpect(screen.getByTestId('popover')).not.to.be.undefined;
  });

  it('should render when bucket type is NOT sftp', async () => {
    axiosMock.onGet().reply((config) => {
      expect(config.url).toBe('/download/doge');

      return [200, {
        hasError: false,
        additionalDetails: {
          URL: '/download/cabinet'
        }
      }];
    });

    renderView({});

    await act(async () => {
      fireEvent.click(screen.getByTestId('trigger-download'));
    });

    chaiExpect(locationAssignStub.calledWith('/download/cabinet')).to.be.true;
    chaiExpect(locationAssignStub.calledOnce).to.be.true;
  });

  it('should handle case where downloadUrl output has error', async () => {
    axiosMock.onGet().reply((config) => {
      expect(config.url).toBe('/download/doge');

      return [200, {
        hasError: true
      }];
    });

    renderView({});

    await act(async () => {
      fireEvent.click(screen.getByTestId('trigger-download'));
    });
  });

  it('should handle case where downloadUrl throws error', async () => {
    axiosMock.onGet().reply((config) => {
      expect(config.url).toBe('/download/doge');

      return [500, {}];
    });

    renderView({});

    await act(async () => {
      fireEvent.click(screen.getByTestId('trigger-download'));
    });
  });
});