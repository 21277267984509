import React from 'react';
import { Button } from '@panwds/react-ui';
import PropTypes from 'prop-types';
import BasicDropdown from './BasicDropdown';
import DropDownList from '../../DropDown/DropDownList';
import operatorTypes from './operatorTypes';
import matchTypes from './matchTypes';

import translate from '../../../helpers/translate';
import DataPatternCondition from './DataPatternCondition';

function t(scope, options) {
  return translate(`data_profiles.form.data_pattern_form.${scope}`, options);
}

const DataPatternRules = ({rule, ruleIndex, dataPatterns, validatePresence, updateRule, readOnly}) => {
  const handleV1OperatorTypeChange = (operatorType, operatorIndex, conditionIndex) => {
    const newRule = rule;
    newRule.conditions[conditionIndex].operators.splice(operatorIndex, 1, operatorType);
    updateRule(newRule, ruleIndex);
  }

  const handleMatchTypeChange = (matchType, conditionIndex) => {
    const newRule = rule;
    newRule.operators.splice(conditionIndex, 1, matchType);
    updateRule(newRule, ruleIndex);
  }

  const addDataPattern = (conditionIndex, v1=false) => {
    const newRule = rule;
    newRule.conditions[conditionIndex].rule_items.push({
      id: null,
      occurrence_operator_type: 'any',
      occurrence_count: null,
      confidence_level: 'high'
    });
    if (v1 && newRule.conditions[conditionIndex].operators.length === newRule.conditions[conditionIndex].rule_items.length - 2) {
      newRule.conditions[conditionIndex].operators.push('and');
    }
    updateRule(newRule, ruleIndex);
  }

  const addDataPatternGroup = () => {
    const newRule = rule;
    newRule.conditions.push({
      operators: ['and'],
      rule_items: [
        {
          id: null,
          occurrence_operator_type: 'any',
          occurrence_count: null,
          confidence_level: 'high'
        }
      ]
    });
    if (newRule.operators.length > 0) {
      newRule.operators.push(newRule.operators[0]);
    }
    else {
      newRule.operators.push('and');
    }
    updateRule(newRule, ruleIndex);
  }

  const updateRuleItems = (newRuleItems, conditionIndex, v1Index) => {
    const newRule = rule;
    if (v1Index === undefined) {
      newRule.conditions[conditionIndex].rule_items = newRuleItems;
    }
    else {
      newRule.conditions[conditionIndex].rule_items.splice(v1Index, 1, newRuleItems[0]);
    }
    updateRule(newRule, ruleIndex);
  }

  const deleteRuleItem = (conditionIndex, ruleItemIndex, v1Index) => {
    let newRule = rule;
    if (v1Index === undefined) {
      newRule.conditions[conditionIndex].rule_items.splice(ruleItemIndex, 1);
    }
    else {
      newRule.conditions[conditionIndex].rule_items.splice(v1Index, 1);
      if (newRule.conditions[conditionIndex].operators.length > 1) {
        const opIndex = v1Index === 0 ? 0 : v1Index - 1;
        newRule.conditions[conditionIndex].operators.splice(opIndex, 1);
      }
      if (newRule.conditions[conditionIndex].rule_items.length === 1 && newRule.conditions[conditionIndex].operators.length === 1 && newRule.conditions[conditionIndex].operators[0].includes('not')) {
        newRule.conditions[conditionIndex].operators[0] = newRule.conditions[conditionIndex].operators[0].split('_')[0] === 'and' ? 'and' : 'or'
      }
    }
    if (newRule.conditions[conditionIndex].rule_items.length === 0) {
      newRule.conditions.splice(conditionIndex, 1);
      newRule.operators.splice(conditionIndex, 1);
    }
    if (newRule.conditions.length === 0) {
      newRule = {};
    }
    updateRule(newRule, ruleIndex);
  }

  const v1AdvForm = (condition, conditionIndex) => {
    return(
      <div className='formCommon v1Form'>
        <div className='condition' >
          <DataPatternCondition dataPatterns={dataPatterns} ruleItems={[condition.rule_items[0]]} conditionIndex={conditionIndex} v1Index={0} updateRuleItems={updateRuleItems} deleteRuleItem={deleteRuleItem} validatePresence={validatePresence} readOnly={readOnly} />
        </div>
        {condition.rule_items.length > 1 && condition.operators.map((operator, operatorIndex) =>
          <div key={operatorIndex} className='v1OperatorGroup'>
            <BasicDropdown
              onSelect={newOperatorType => handleV1OperatorTypeChange(newOperatorType, operatorIndex, conditionIndex)}
              value={operator || 'and'}
              items={operatorTypes}
            />
            <div className='condition' >
              <DataPatternCondition dataPatterns={dataPatterns} ruleItems={[condition.rule_items[operatorIndex + 1]]} conditionIndex={conditionIndex} v1Index={operatorIndex+1} updateRuleItems={updateRuleItems} deleteRuleItem={deleteRuleItem} validatePresence={validatePresence} readOnly={readOnly} />
            </div>
          </div>
        )}
        <Button disabled={readOnly} appearance='tertiary' addClassName='addDataPatternBtn' onClick={() => addDataPattern(conditionIndex, true)} >
          {t('add_data_pattern')}
        </Button>
      </div>
    )
  }

  return(
    <div className='formCommon dataPatternConditions'>
      <p className='form-label conditionLabel'>{t('data_pattern_conditions')}</p>
      {rule.conditions && rule.conditions.map((condition, conditionIndex) =>
        <div key={`${ruleIndex}-${conditionIndex}`}>
          {v1AdvForm(condition, conditionIndex)}
          {rule.conditions.length > 1 && conditionIndex !== rule.conditions.length - 1 &&
            <DropDownList
              onSelect={newMatchType => handleMatchTypeChange(newMatchType, conditionIndex)}
              dropClass='operatorBox'
              value={rule.operators[conditionIndex] || rule.operators[0]}
              items={matchTypes.concat([{value: 'and_not', label: 'AND NOT'}, {value: 'or_not', label: 'OR NOT'}])}
              size='sm'
            />
          }
        </div>
      )}
      <Button appearance='tertiary' disabled={readOnly} onClick={() => addDataPatternGroup()} >
        {t('add_data_pattern_group')}
      </Button>
    </div>
  )
}

DataPatternRules.propTypes = {
  rule: PropTypes.shape({
    conditions: PropTypes.array,
    operators: PropTypes.array,
  }).isRequired,
  validatePresence: PropTypes.bool,
  dataPatterns: PropTypes.shape({}),
  ruleIndex: PropTypes.number,
  updateRule: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

DataPatternRules.defaultProps = {
  validatePresence: false,
  dataPatterns: {},
  ruleIndex: 0,
  readOnly: false,
}

export default DataPatternRules;