import React from "react";
import '@testing-library/react/dont-cleanup-after-each';
import { fireEvent } from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import renderWithRouter from "../../helpers/setupTests";
import FilePropertyPatternForm from "./FilePropertyPatternForm";

const axiosMock = new MockAdapter(axios);
axiosMock.onPost().reply(200, {});
describe('<FilePropertyPatternForm/>', () => {
  let testUtils;
  const pattern = {
    detection_technique: 'titus_tag',
    status: 'active',
    type: 'file_property',
    name: '',
    description: '',
    metadataCriteria: [{
    }]
  };


  test('Render the form', () => {
    testUtils = renderWithRouter(
      <FilePropertyPatternForm dataPattern={pattern} lookupURL='/data-patterns/'/>);
    expect(testUtils.getByText('Add File Property Data Pattern')).toBeInTheDocument();
  });

  test('Basic validation works', () => {
    const saveButton = testUtils.container.querySelector('button.save');
    fireEvent.click(saveButton);
    const errorElements = testUtils.container.querySelectorAll('.error');
    expect(errorElements.length).toBeGreaterThan(0);
  });

  test('adding and deleting property works', () => {
    const addButton = testUtils.container.querySelector('.action-bar button');
    fireEvent.click(addButton);
    let rows = testUtils.container.querySelectorAll('.fp-row');
    expect(rows.length).toBe(3);
    const deleteButton = rows[2].querySelector('.delete-row');
    fireEvent.click(deleteButton);
    rows = testUtils.container.querySelectorAll('.fp-row');
    expect(rows.length).toBe(2);
  });

  test('"Save" calls API', () => {
    const nameInput = testUtils.container.querySelector('input.data-pattern-name');
    fireEvent.focus(nameInput);
    fireEvent.input(nameInput, { target: { value: 'test-data-pattern' } });
    const propNameInput = testUtils.container.querySelector('input.file-property-name');
    fireEvent.focus(propNameInput);
    fireEvent.input(propNameInput, { target: { value: 'prop1' } });
    const propValueInput = testUtils.container.querySelector('input.file-property-value');
    fireEvent.focus(propValueInput);
    fireEvent.input(propValueInput, { target: { value: 'value1' } });

    const saveButton = testUtils.container.querySelector('button.save');
    saveButton.focus();
    fireEvent.click(saveButton);
    expect(axiosMock.history.post.length).toBe(1);
  });
});