// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataProfileDefinition> renders hybrid preview 1`] = `
<div
  style={
    Object {
      "padding": "15px",
    }
  }
>
  <div
    className="rule"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.primary_rule
    </p>
    <DataProfileBracketPreview
      advanced={true}
      dataPatternRule={Object {}}
      datasets={
        Array [
          Object {
            "active_fields": Array [
              Object {
                "data_type_name": "Social Security Number",
                "id": "col-1",
                "name": "SSN",
              },
              Object {
                "data_type_name": "Credit Card Number",
                "id": "col-2",
                "name": "CCN",
              },
              Object {
                "data_type_name": "Subscriber ID",
                "id": "col-3",
                "name": "Subscriber ID",
              },
              Object {
                "data_type_name": "Member ID",
                "id": "col-4",
                "name": "Member ID",
              },
            ],
            "all_fields": Array [],
            "id": "1234564",
            "index_status": "success",
            "index_version": 1,
            "name": "Dataset A",
          },
          Object {
            "active_fields": Array [
              Object {
                "data_type_name": "Social Security Number",
                "id": "col-1",
                "name": "SSN",
              },
              Object {
                "data_type_name": "Credit Card Number",
                "id": "col-2",
                "name": "CCN",
              },
              Object {
                "data_type_name": "Bank Account Numbers",
                "id": "col-3",
                "name": "BankNum",
              },
            ],
            "id": "1234565",
            "index_status": "success",
            "index_version": 1,
            "name": "Dataset B",
          },
        ]
      }
      expTree={
        Object {
          "operator_type": "and",
          "rule_item": null,
          "sub_expressions": Array [
            Object {
              "operator_type": null,
              "rule_item": Object {
                "confidence_level": "high",
                "detection_technique": "regex",
                "id": "1234560",
                "name": "SSN",
                "occurrence_count": null,
                "occurrence_operator_type": "any",
                "supported_confidence_levels": Array [
                  "high",
                  "low",
                ],
                "version": 1,
              },
              "sub_expressions": Array [],
            },
            Object {
              "operator_type": "or",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": "or",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": null,
                      "rule_item": Object {
                        "confidence_level": "high",
                        "detection_technique": "regex",
                        "id": "1234561",
                        "name": "CCN",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "low",
                        ],
                        "version": 1,
                      },
                      "sub_expressions": Array [],
                    },
                    Object {
                      "operator_type": null,
                      "rule_item": Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234565",
                        "name": "Dataset B",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-2",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      },
                      "sub_expressions": Array [],
                    },
                  ],
                },
              ],
            },
            Object {
              "operator_type": "not",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": null,
                  "rule_item": Object {
                    "detection_technique": "edm",
                    "edm_dataset_id": "1234564",
                    "name": "Dataset A",
                    "occurrence_count": null,
                    "occurrence_high": null,
                    "occurrence_low": null,
                    "occurrence_operator_type": "any",
                    "primary_fields": Array [
                      "col-1",
                      "col-3",
                    ],
                    "primary_match_any_count": null,
                    "primary_match_criteria": "all",
                    "secondary_fields": Array [],
                    "secondary_match_any_count": null,
                    "secondary_match_criteria": "all",
                  },
                  "sub_expressions": Array [],
                },
              ],
            },
          ],
        }
      }
      multi={false}
      profiles={Array []}
      schema={2}
    />
  </div>
  <hr />
  <div
    className="rule"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.secondary_rule
    </p>
    <DataProfileBracketPreview
      advanced={true}
      dataPatternRule={Object {}}
      datasets={
        Array [
          Object {
            "active_fields": Array [
              Object {
                "data_type_name": "Social Security Number",
                "id": "col-1",
                "name": "SSN",
              },
              Object {
                "data_type_name": "Credit Card Number",
                "id": "col-2",
                "name": "CCN",
              },
              Object {
                "data_type_name": "Subscriber ID",
                "id": "col-3",
                "name": "Subscriber ID",
              },
              Object {
                "data_type_name": "Member ID",
                "id": "col-4",
                "name": "Member ID",
              },
            ],
            "all_fields": Array [],
            "id": "1234564",
            "index_status": "success",
            "index_version": 1,
            "name": "Dataset A",
          },
          Object {
            "active_fields": Array [
              Object {
                "data_type_name": "Social Security Number",
                "id": "col-1",
                "name": "SSN",
              },
              Object {
                "data_type_name": "Credit Card Number",
                "id": "col-2",
                "name": "CCN",
              },
              Object {
                "data_type_name": "Bank Account Numbers",
                "id": "col-3",
                "name": "BankNum",
              },
            ],
            "id": "1234565",
            "index_status": "success",
            "index_version": 1,
            "name": "Dataset B",
          },
        ]
      }
      expTree={
        Object {
          "operator_type": "or",
          "rule_item": null,
          "sub_expressions": Array [
            Object {
              "operator_type": null,
              "rule_item": Object {
                "confidence_level": "high",
                "detection_technique": "regex",
                "id": "1234560",
                "name": "SSN",
                "occurrence_count": null,
                "occurrence_operator_type": "any",
                "supported_confidence_levels": Array [
                  "high",
                  "low",
                ],
                "version": 1,
              },
              "sub_expressions": Array [],
            },
            Object {
              "operator_type": "and",
              "rule_item": null,
              "sub_expressions": Array [
                Object {
                  "operator_type": "and",
                  "rule_item": null,
                  "sub_expressions": Array [
                    Object {
                      "operator_type": null,
                      "rule_item": Object {
                        "confidence_level": "high",
                        "detection_technique": "regex",
                        "id": "1234561",
                        "name": "CCN",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "low",
                        ],
                        "version": 1,
                      },
                      "sub_expressions": Array [],
                    },
                    Object {
                      "operator_type": null,
                      "rule_item": Object {
                        "detection_technique": "edm",
                        "edm_dataset_id": "1234565",
                        "name": "Dataset B",
                        "occurrence_count": null,
                        "occurrence_high": null,
                        "occurrence_low": null,
                        "occurrence_operator_type": "any",
                        "primary_fields": Array [
                          "col-1",
                          "col-2",
                        ],
                        "primary_match_any_count": null,
                        "primary_match_criteria": "all",
                        "secondary_fields": Array [],
                        "secondary_match_any_count": null,
                        "secondary_match_criteria": "all",
                      },
                      "sub_expressions": Array [],
                    },
                  ],
                },
              ],
            },
            Object {
              "operator_type": "and",
              "rule_item": Object {
                "detection_technique": "edm",
                "edm_dataset_id": "1234564",
                "name": "Dataset A",
                "occurrence_count": null,
                "occurrence_high": null,
                "occurrence_low": null,
                "occurrence_operator_type": "any",
                "primary_fields": Array [
                  "col-1",
                  "col-3",
                ],
                "primary_match_any_count": null,
                "primary_match_criteria": "all",
                "secondary_fields": Array [],
                "secondary_match_any_count": null,
                "secondary_match_criteria": "all",
              },
              "sub_expressions": Array [],
            },
          ],
        }
      }
      multi={false}
      profiles={Array []}
      schema={2}
    />
  </div>
</div>
`;

exports[`<DataProfileDefinition> renders pattern only preview 1`] = `
<div
  style={
    Object {
      "padding": "15px",
    }
  }
>
  <div
    className="rule"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.primary_rule
    </p>
    <DataProfileBracketPreview
      advanced={true}
      dataPatternRule={
        Object {
          "conditions": Array [
            Object {
              "operators": Array [
                "or",
              ],
              "rule_items": Array [
                Object {
                  "confidence_level": "high",
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_high": 24,
                  "occurrence_low": 4,
                  "occurrence_operator_type": "between",
                  "supported_confidence_levels": Array [
                    "high",
                    "low",
                  ],
                  "version": 1,
                },
                Object {
                  "confidence_level": "medium",
                  "id": "1234561",
                  "name": "CCN",
                  "occurrence_count": 9,
                  "occurrence_operator_type": "less_than_equal_to",
                  "supported_confidence_levels": Array [
                    "high",
                    "medium",
                    "low",
                  ],
                  "version": 1,
                },
              ],
            },
            Object {
              "operators": Array [
                "or_not",
              ],
              "rule_items": Array [
                Object {
                  "confidence_level": "high",
                  "id": "1234562",
                  "name": "Bank",
                  "occurrence_high": 241,
                  "occurrence_low": 42,
                  "occurrence_operator_type": "between",
                  "supported_confidence_levels": Array [
                    "high",
                    "low",
                  ],
                  "version": 1,
                },
                Object {
                  "confidence_level": "low",
                  "id": "1234563",
                  "name": "Passport",
                  "occurrence_operator_type": "any",
                  "supported_confidence_levels": Array [
                    "high",
                    "low",
                  ],
                  "version": 1,
                },
              ],
            },
          ],
          "operators": Array [
            "and",
          ],
        }
      }
      datasets={Array []}
      expTree={Object {}}
      multi={false}
      profiles={Array []}
      schema={2}
    />
  </div>
  <hr />
  <div
    className="rule"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.secondary_rule
    </p>
    <DataProfileBracketPreview
      advanced={true}
      dataPatternRule={Object {}}
      datasets={Array []}
      expTree={Object {}}
      multi={false}
      profiles={Array []}
      schema={2}
    />
  </div>
</div>
`;

exports[`<DataProfileDefinition> renders v1 multiop pattern only preview 1`] = `
<div
  style={
    Object {
      "padding": "15px",
    }
  }
>
  <div
    className="rule"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.primary_rule
    </p>
    <DataProfileBracketPreview
      advanced={true}
      dataPatternRule={
        Object {
          "conditions": Array [
            Object {
              "operators": Array [
                "or",
                "and_not",
              ],
              "rule_items": Array [
                Object {
                  "confidence_level": "high",
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_high": 24,
                  "occurrence_low": 4,
                  "occurrence_operator_type": "between",
                  "supported_confidence_levels": Array [
                    "high",
                    "low",
                  ],
                  "version": 1,
                },
                Object {
                  "confidence_level": "medium",
                  "id": "1234561",
                  "name": "CCN",
                  "occurrence_count": null,
                  "occurrence_operator_type": null,
                  "supported_confidence_levels": Array [
                    "high",
                    "medium",
                    "low",
                  ],
                  "version": 1,
                },
                Object {
                  "confidence_level": "medium",
                  "id": "1234566",
                  "name": "ABC",
                  "occurrence_count": null,
                  "occurrence_operator_type": null,
                  "supported_confidence_levels": Array [
                    "high",
                    "medium",
                    "low",
                  ],
                  "version": 1,
                },
              ],
            },
            Object {
              "operators": Array [
                "or_not",
              ],
              "rule_items": Array [
                Object {
                  "confidence_level": "high",
                  "id": "1234562",
                  "name": "Bank",
                  "occurrence_high": 241,
                  "occurrence_low": 42,
                  "occurrence_operator_type": "between",
                  "supported_confidence_levels": Array [
                    "high",
                    "low",
                  ],
                  "version": 1,
                },
                Object {
                  "confidence_level": "low",
                  "id": "1234563",
                  "name": "Passport",
                  "occurrence_operator_type": "any",
                  "supported_confidence_levels": Array [
                    "high",
                    "low",
                  ],
                  "version": 1,
                },
              ],
            },
            Object {
              "operators": Array [],
              "rule_items": Array [
                Object {
                  "confidence_level": "high",
                  "id": "1234565",
                  "name": "DEF",
                  "occurrence_high": 241,
                  "occurrence_low": 42,
                  "occurrence_operator_type": "between",
                  "supported_confidence_levels": Array [
                    "high",
                    "low",
                  ],
                  "version": 1,
                },
              ],
            },
          ],
          "operators": Array [
            "and",
            "or",
          ],
        }
      }
      datasets={Array []}
      expTree={Object {}}
      multi={false}
      profiles={Array []}
      schema={2}
    />
  </div>
  <hr />
  <div
    className="rule"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.secondary_rule
    </p>
    <DataProfileBracketPreview
      advanced={true}
      dataPatternRule={Object {}}
      datasets={Array []}
      expTree={Object {}}
      multi={false}
      profiles={Array []}
      schema={2}
    />
  </div>
</div>
`;
