import React, { useState, useEffect } from 'react';
import { FaAngleUp, FaAngleDown, FaDownload, FaCopy, FaWrench, FaTable } from 'react-icons/fa';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton } from '@panwds/react-ui';
import { IntlProvider } from 'react-intl';
import $ from 'jquery';
import PropTypes from 'prop-types';

import translate from '../../helpers/translate';
import DropDownList from '../DropDown/DropDownList';
import ToolIcon from '../../../assets/images/tool.svg';
import SpinnerIcon from '../../../assets/images/spinner.gif';

function t(scope, options) {
  return translate(`edm_datasets.setup_guide.${scope}`, options);
}

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return `${str.slice(0, num)}...`
}

const EdmSetup = ({cliOptionsURL, cliDownloadURL}) => {
  const [expanded, setExpanded] = useState(true);
  const [version, setVersion] = useState(null);
  const [allVersions, setAllVersions] = useState(null);
  const [versionOptions, setVersionOptions] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [platformOptions, setPlatformOptions] = useState(null);
  const [checksum, setChecksum] = useState(null);
  const [checksumOptions, setChecksumOptions] = useState(null);
  const [copied, setCopied] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    axios.get(cliOptionsURL)
      .then(
        result => {
          if (result.data.platforms.length > 0) {
            const initialPlatform = result.data.platforms[0].value;
            const initialVersion = result.data.versions[initialPlatform][0].value;
            setPlatformOptions(result.data.platforms);
            setPlatform(initialPlatform);
            setAllVersions(result.data.versions);
            setVersionOptions(result.data.versions[initialPlatform]);
            setVersion(initialVersion);
            setChecksum(result.data.checksums[initialPlatform][initialVersion]);
            setChecksumOptions(result.data.checksums);
          }
        }
      )
  }, []);

  const copy = (id) => {
    setCopied(id);
    const copyText = document.getElementById(id);
    copyText.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  }

  $('.rightInnerAddOn i').mouseleave(() => {
    setCopied('');
  })

  const selectionChange = (newSelection) => {
    let newPlatform = platform;
    let newVersion = version;
    if (newSelection.platform) {
      newPlatform = newSelection.platform;
      setPlatform(newPlatform);
      setVersionOptions(allVersions[newPlatform]);
      newVersion = allVersions[newPlatform][0].value;
      setVersion(newVersion);
    }
    else if (newSelection.version) {
      newVersion = newSelection.version;
      setVersion(newVersion);
    }
    setChecksum(checksumOptions[newPlatform][newVersion])
  }

  const downloadCli = () => {
    $('.downloadBtn').addClass('disabled');
    axios.get(`${cliDownloadURL}?platform=${platform}&version=${version}`)
      .then(
        response => {
          $('.downloadBtn').removeClass('disabled');
          if (response.status === 200) {
            window.location.assign(response.data);
            $('#flash-messages').html(
              `<div class="alert alert-floating alert-dismissible fade show" role="alert">
                <span class="fa fa-check-circle success-icon mr5"></span>
                <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
                ${t('download_success')}
              </div>`)
            setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
          }
          else {
            $('#flash-messages').html(
              `<div class="alert alert-floating alert-dismissible fade show" role="alert">
                <span class="fa fa-times error mr5"></span>
                <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
                ${response.data.errorMessage || t('download_failure')}
              </div>`)
            setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
          }
        }
      )
  }

  const closeModal= () => {
    setModalOpen(false);
  }

  return (
    <div className='reactTable setupGuide'>
      <div className="title card-title">
        <span>
          {t('title')}
          { expanded ?
            <FaAngleUp className='toggleBtn' onClick={()=> setExpanded(false)} />
            :
            <FaAngleDown className='toggleBtn' onClick={()=> setExpanded(true)} />
          }
        </span>
        <span className="actions tw-float-right">
          <Button appearance='secondary' onClick={() => setModalOpen(true)} addClassName='cliBtn'>{t('download_cli')}</Button>
        </span>
      </div>
      { expanded &&
        <div className='row instructions'>
          <div className='col-sm-4'>
            <div className='row'>
              <div className='col-sm-1'>
                <FaDownload className='instructionIcon' />
              </div>
              <div className='instructionStep col-sm-11'>
                <div className='stepTitle'>{t('step1')}</div>
                <div className='helpText'>{t('step1_desc')}</div>
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='row'>
              <div className='col-sm-1'>
                <FaWrench className='instructionIcon' />
              </div>
              <div className='instructionStep col-sm-11'>
                <div className='stepTitle'>{t('step2')}</div>
                <div className='helpText'>{t('step2_desc')}</div>
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='row'>
              <div className='col-sm-1'>
                <FaTable className='instructionIcon' />
              </div>
              <div className='instructionStep col-sm-11'>
                <div className='stepTitle'>{t('step3')}</div>
                <div className='helpText'>{t('step3_desc')}</div>
              </div>
            </div>
          </div>
          <IntlProvider locale='en'>
            <Modal style={{maxWidth: '600px', width: '100%'}} size="lg" confirmClose={false} onClose={closeModal} isOpen={modalOpen}>
              <ModalHeader title={t('download_cli')} enableClose />
              <ModalBody addClassName="cliModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
                <div>
                  <p className='helpText'>
                    {t('cli_modal.description')}
                  </p>
                  <br />
                  <div className='options'>
                    <div className='icon-area text-center'>
                      <img alt='tool' src={ToolIcon} />
                    </div>
                    <div>
                      <div className='stepTitle'>
                        {t('cli_modal.options')}
                      </div>
                      <br />
                      <div className='form-label'>{t('cli_modal.platform')}</div>
                      { platform ?
                        <DropDownList
                          dropClass='cliPlatform'
                          onSelect={newPlatform => selectionChange({ platform: newPlatform })}
                          value={platform}
                          items={platformOptions}
                        />
                        :
                        <img alt='loading' className='loader' src={SpinnerIcon} />
                      }
                      <br /><br />
                      <div className='form-label'>{t('cli_modal.version')}</div>
                      { version ?
                        <DropDownList
                          dropClass='cliVersion'
                          onSelect={newVersion => selectionChange({ version: newVersion })}
                          value={version}
                          items={versionOptions}
                        />
                        :
                        <img alt='loading' className='loader' src={SpinnerIcon} />
                      }
                      { checksum ?
                        <div className='checksum'>
                          <br />
                          <div className='form-label'>{t('cli_modal.checksum')}</div>
                          <div className={`rightInnerAddOn ${copied==='checksumValue' ? 'max' : 'min'}`}>
                            <Popup trigger={ <i onClick={ () => copy('checksumValue') }><FaCopy /></i> } position='top center' on='hover' mouseLeaveDelay={0}>
                              <div className='popup-box'>{copied==='checksumValue' ? t('cli_modal.copied') : t('cli_modal.copy')}</div>
                            </Popup>
                            <textarea readOnly className='checksumDisplay' id='checksum' value={truncateString(checksum, 35)} />
                            <textarea readOnly id='checksumValue' value={checksum} />
                          </div>
                        </div>
                        :
                        <span><br /><br /></span>
                      }
                      <br />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <ModalCloseButton>{translate('actions.cancel')}</ModalCloseButton>
                <Button appearance='primary' addClassName='downloadBtn' onClick={downloadCli} icon={<FaDownload className='mr5'/>}>
                  {t('cli_modal.download')}
                </Button>
              </ModalFooter>
            </Modal>
          </IntlProvider>
        </div>
      }
    </div>
  );
};

EdmSetup.propTypes = {
  cliOptionsURL: PropTypes.string.isRequired,
  cliDownloadURL: PropTypes.string.isRequired
}

export default EdmSetup;