// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DropDownList/> should render 1`] = `
<DropdownMenu
  align="left"
  animAlign={null}
  animate={false}
  className={null}
  close={[Function]}
  closeOnInsideClick={true}
  closeOnOutsideClick={true}
  enterTimeout={10}
  inverse={false}
  isOpen={false}
  leaveTimeout={10}
  menuAlign={null}
  size={null}
  textAlign={null}
  toggle={
    <div>
      <Button
        addClassName="test-class"
        appearance="tertiary"
        aria-expanded={false}
        buttonType="default"
        disabled={false}
        isMenu={true}
        onClick={[Function]}
        size="md"
      >
        <span
          className="placeholder"
        >
          Select an Item
        </span>
      </Button>
    </div>
  }
  upwards={false}
>
  <li
    key="item1"
  >
    <Button
      appearance="secondary"
      buttonType="default"
      disabled={false}
      isMenu={false}
      key="item1"
      onClick={[Function]}
      size="md"
      type="button"
    >
      item1
    </Button>
  </li>
  <li
    key="item2"
  >
    <Button
      appearance="secondary"
      buttonType="default"
      disabled={false}
      isMenu={false}
      key="item2"
      onClick={[Function]}
      size="md"
      type="button"
    >
      item2
    </Button>
  </li>
</DropdownMenu>
`;

exports[`<DropDownList/> toggle 1`] = `
<DropDownList
  disabled={false}
  dropClass="test-class"
  items={
    Array [
      Object {
        "label": "item1",
        "value": "item1",
      },
      Object {
        "label": "item2",
        "value": "item2",
      },
    ]
  }
  labelFreeze={false}
  onSelect={[MockFunction]}
  placeholder="Select an Item"
  upwards={false}
  value={null}
>
  <DropdownMenu
    align="left"
    animAlign={null}
    animate={false}
    className={null}
    close={[Function]}
    closeOnInsideClick={true}
    closeOnOutsideClick={true}
    enterTimeout={10}
    inverse={false}
    isOpen={true}
    leaveTimeout={10}
    menuAlign={null}
    size={null}
    textAlign={null}
    toggle={
      <div>
        <Button
          addClassName="test-class"
          appearance="tertiary"
          aria-expanded={true}
          buttonType="default"
          disabled={false}
          isMenu={true}
          onClick={[Function]}
          size="md"
        >
          <span
            className="placeholder"
          >
            Select an Item
          </span>
        </Button>
      </div>
    }
    upwards={false}
  >
    <div
      className="dd-menu dd-menu-left"
    >
      <div>
        <Button
          addClassName="test-class"
          appearance="tertiary"
          aria-expanded={true}
          buttonType="default"
          disabled={false}
          isMenu={true}
          key="test-class"
          onClick={[Function]}
          size="md"
        >
          <button
            aria-expanded={true}
            className="test-class tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
            data-testid="button-false"
            onClick={[Function]}
            type="button"
          >
            <span
              className="placeholder"
            >
              Select an Item
            </span>
            <MenuIcon
              buttonType="default"
              isBtnGroup={false}
              isOpen={false}
              isSplit={false}
              size="md"
            >
              <span
                className="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
              >
                <FaIcon
                  addClassName="tw-transform tw-transition-transform tw-duration-200"
                  color="currentColor"
                  faIconDef={
                    Object {
                      "icon": Array [
                        256,
                        512,
                        Array [],
                        "f107",
                        "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                      ],
                      "iconName": "angle-down",
                      "prefix": "fal",
                    }
                  }
                  iconProps={Object {}}
                  secondaryColor="currentColor"
                  size="md"
                >
                  <svg
                    aria-hidden={true}
                    className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                    focusable={false}
                    role="img"
                    viewBox="0 0 256 512"
                  >
                    <path
                      d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                      fill="currentColor"
                      key="0"
                    />
                  </svg>
                </FaIcon>
              </span>
            </MenuIcon>
          </button>
        </Button>
      </div>
      <CSSTransitionGroup
        className="dd-menu-items"
        component="div"
        onKeyDown={[Function]}
        transitionAppear={false}
        transitionEnter={false}
        transitionEnterTimeout={10}
        transitionLeave={false}
        transitionLeaveTimeout={10}
        transitionName="grow-from-left"
      >
        <TransitionGroup
          childFactory={[Function]}
          className="dd-menu-items"
          component="div"
          onKeyDown={[Function]}
          transitionAppear={false}
          transitionEnter={false}
          transitionEnterTimeout={10}
          transitionLeave={false}
          transitionLeaveTimeout={10}
          transitionName="grow-from-left"
        >
          <div
            className="dd-menu-items"
            onKeyDown={[Function]}
          >
            <CSSTransitionGroupChild
              appear={false}
              enter={false}
              enterTimeout={10}
              key=".$items"
              leave={false}
              leaveTimeout={10}
              name="grow-from-left"
            >
              <ul
                className="dd-items-left"
                key=".$items"
              >
                <li
                  key="item1"
                >
                  <Button
                    appearance="secondary"
                    buttonType="default"
                    disabled={false}
                    isMenu={false}
                    key="item1"
                    onClick={[Function]}
                    size="md"
                    type="button"
                  >
                    <button
                      className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
                      data-testid="button-item1"
                      onClick={[Function]}
                      type="button"
                    >
                      item1
                    </button>
                  </Button>
                </li>
                <li
                  key="item2"
                >
                  <Button
                    appearance="secondary"
                    buttonType="default"
                    disabled={false}
                    isMenu={false}
                    key="item2"
                    onClick={[Function]}
                    size="md"
                    type="button"
                  >
                    <button
                      className="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-text-link dark:tw-text-blue-400 tw-border-gray-300 dark:tw-border-blue-700 active:tw-text-blue-800 dark:active:tw-text-blue-200 active:tw-bg-blue-200 dark:active:tw-bg-blue-600 active:tw-border-blue-800 dark:active:tw-border-blue-400 focus-visible:tw-bg-blue-100 dark:focus-visible:tw-bg-blue-700 focus-visible:tw-border-blue-700 dark:focus-visible:tw-border-blue-500 focus-visible:tw-text-blue-700 dark:focus-visible:tw-text-blue-300 hover:tw-text-blue-700 dark:hover:tw-text-blue-300 hover:tw-bg-blue-100 hover:tw-border-blue-700 dark:hover:tw-border-blue-500 dark:hover:tw-bg-blue-700"
                      data-testid="button-item2"
                      onClick={[Function]}
                      type="button"
                    >
                      item2
                    </button>
                  </Button>
                </li>
              </ul>
            </CSSTransitionGroupChild>
          </div>
        </TransitionGroup>
      </CSSTransitionGroup>
    </div>
  </DropdownMenu>
</DropDownList>
`;

exports[`<DropDownList/> toggle 2`] = `
<DropDownList
  disabled={false}
  dropClass="test-class"
  items={
    Array [
      Object {
        "label": "item1",
        "value": "item1",
      },
      Object {
        "label": "item2",
        "value": "item2",
      },
    ]
  }
  labelFreeze={false}
  onSelect={[MockFunction]}
  placeholder="Select an Item"
  upwards={false}
  value={null}
>
  <DropdownMenu
    align="left"
    animAlign={null}
    animate={false}
    className={null}
    close={[Function]}
    closeOnInsideClick={true}
    closeOnOutsideClick={true}
    enterTimeout={10}
    inverse={false}
    isOpen={false}
    leaveTimeout={10}
    menuAlign={null}
    size={null}
    textAlign={null}
    toggle={
      <div>
        <Button
          addClassName="test-class"
          appearance="tertiary"
          aria-expanded={false}
          buttonType="default"
          disabled={false}
          isMenu={true}
          onClick={[Function]}
          size="md"
        >
          <span
            className="placeholder"
          >
            Select an Item
          </span>
        </Button>
      </div>
    }
    upwards={false}
  >
    <div
      className="dd-menu dd-menu-left"
    >
      <div>
        <Button
          addClassName="test-class"
          appearance="tertiary"
          aria-expanded={false}
          buttonType="default"
          disabled={false}
          isMenu={true}
          key="test-class"
          onClick={[Function]}
          size="md"
        >
          <button
            aria-expanded={false}
            className="test-class tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
            data-testid="button-false"
            onClick={[Function]}
            type="button"
          >
            <span
              className="placeholder"
            >
              Select an Item
            </span>
            <MenuIcon
              buttonType="default"
              isBtnGroup={false}
              isOpen={false}
              isSplit={false}
              size="md"
            >
              <span
                className="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
              >
                <FaIcon
                  addClassName="tw-transform tw-transition-transform tw-duration-200"
                  color="currentColor"
                  faIconDef={
                    Object {
                      "icon": Array [
                        256,
                        512,
                        Array [],
                        "f107",
                        "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                      ],
                      "iconName": "angle-down",
                      "prefix": "fal",
                    }
                  }
                  iconProps={Object {}}
                  secondaryColor="currentColor"
                  size="md"
                >
                  <svg
                    aria-hidden={true}
                    className="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                    focusable={false}
                    role="img"
                    viewBox="0 0 256 512"
                  >
                    <path
                      d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                      fill="currentColor"
                      key="0"
                    />
                  </svg>
                </FaIcon>
              </span>
            </MenuIcon>
          </button>
        </Button>
      </div>
      <CSSTransitionGroup
        className="dd-menu-items"
        component="div"
        onKeyDown={[Function]}
        transitionAppear={false}
        transitionEnter={false}
        transitionEnterTimeout={10}
        transitionLeave={false}
        transitionLeaveTimeout={10}
        transitionName="grow-from-left"
      >
        <TransitionGroup
          childFactory={[Function]}
          className="dd-menu-items"
          component="div"
          onKeyDown={[Function]}
          transitionAppear={false}
          transitionEnter={false}
          transitionEnterTimeout={10}
          transitionLeave={false}
          transitionLeaveTimeout={10}
          transitionName="grow-from-left"
        >
          <div
            className="dd-menu-items"
            onKeyDown={[Function]}
          />
        </TransitionGroup>
      </CSSTransitionGroup>
    </div>
  </DropdownMenu>
</DropDownList>
`;

exports[`<DropDownList/> with button input should render 1`] = `
<DropdownMenu
  align="left"
  animAlign={null}
  animate={false}
  className={null}
  close={[Function]}
  closeOnInsideClick={true}
  closeOnOutsideClick={true}
  enterTimeout={10}
  inverse={false}
  isOpen={false}
  leaveTimeout={10}
  menuAlign={null}
  size={null}
  textAlign={null}
  toggle={
    <div>
      <Button
        addClassName="test-class"
        appearance="tertiary"
        aria-expanded={false}
        buttonType="default"
        disabled={false}
        isMenu={true}
        onClick={[Function]}
        size="md"
      >
        <input
          onChange={[Function]}
          onKeyPress={[Function]}
          style={
            Object {
              "border": "none",
              "top": "0",
              "width": "53px",
            }
          }
          value=""
        />
      </Button>
    </div>
  }
  upwards={false}
>
  <li
    key="item1"
  >
    <Button
      appearance="secondary"
      buttonType="default"
      disabled={false}
      isMenu={false}
      key="item1"
      onClick={[Function]}
      size="md"
      type="button"
    >
      item1
    </Button>
  </li>
  <li
    key="item2"
  >
    <Button
      appearance="secondary"
      buttonType="default"
      disabled={false}
      isMenu={false}
      key="item2"
      onClick={[Function]}
      size="md"
      type="button"
    >
      item2
    </Button>
  </li>
</DropdownMenu>
`;
