import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import translate from '../../../helpers/translate';

class IdentifierDropdown extends React.Component {
  render() {
    const { value, onSelect, items, error, placeholder, controlWidth } = this.props;
    const options = items.map(i => { return( {...i, value: i.id, label: i.name })})
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: state.isFocused ? '#D4ECFF' : '#fff',
        color: '#333',
        minWidth: 150
      }),
      control: (provided) => ({
        ...provided,
        width: controlWidth,
        height: 32,
        minHeight: 32,
        cursor: 'pointer'
      }),
      indicatorSeparator: () => ({
        display: 'none'
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#aaa'
      }),
      menuPlacer: (provided) => ({
        ...provided,
        minWidth: 200
      }),
      menu: (provided) => ({
        ...provided,
        minWidth: 200
      }),
      menuPortal: (provided) => ({
        ...provided,
        minWidth: 200
      })
    }
    return (
      <Select
        options={options}
        isSearchable
        className={`identifier-dropdown ${error && 'has-error'}`}
        onChange={onSelect}
        defaultValue={Object.keys(value).length > 0 ? {...value, value: value.id, label: value.name } : ''}
        styles={customStyles}
        placeholder={placeholder || translate('data_profiles.form.dataset_form.identifier_placeholder')}
      />
    );
  }
}

IdentifierDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.object,
  items: PropTypes.array,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  controlWidth: PropTypes.number,
};

IdentifierDropdown.defaultProps = {
  items: [],
  value: {},
  error: false,
  placeholder: '',
  controlWidth: 140,
};

export default IdentifierDropdown;
