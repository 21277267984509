import React from 'react';

import { configure, shallow, mount} from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import AggregationCard from './AggregationCard';
import Popup from 'reactjs-popup';
import { FaTimes } from 'react-icons/fa';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<AggregationCard/>', () => {
  let wrapper;
  let deepWrapper;
  const props = {
    type: 'type1',
    title: 'test-title',
    columnLabel: 'test-column',
    data: [
      {
        itemId: '111',
        item: 'item1',
        sensitiveFileCount: 'file1, file2',
        incidentCount: 3,
        date: '2021-06-17 10:07:25'
      },
      {
        itemId: '222',
        item: 'item2',
        sensitiveFileCount: 'file3, file4',
        incidentCount: 4,
        date: '2021-06-17 10:07:26'
      }
    ],
    updateFilters: jest.fn(),
    filters: {
      filterable: true,
      currentFilters: [],
      filterOptions: [],
      currentFilterSelections: {
        type1: [{id: '111'}]
      }
    },
    timestamp: '2021-06-27 10:07:26'
  };
  beforeEach(() => {
    wrapper = shallow(<AggregationCard {...props}/>);
    deepWrapper = mount(<AggregationCard {...props}/>);
  });

  it('should render', () => {
    expect(wrapper).toMatchSnapshot();
  });

  it('should add filter', () => {
    const popup = deepWrapper.find('span.btn-link');

    popup.at(0).simulate('click');
    expect(props.updateFilters).toHaveBeenCalled();
  });

  it('should remove filter', () => {
    const icon = wrapper.find(FaTimes);

    icon.at(0).simulate('click');
    expect(props.updateFilters).toHaveBeenCalled();
  });
});