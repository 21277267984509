import React from 'react'
import { Router } from 'react-router-dom'
import { render } from '@testing-library/react'
import { createMemoryHistory } from 'history'
import {IntlProvider} from 'react-intl';
import '@testing-library/jest-dom/extend-expect';
import { Provider } from "react-redux";
import store from '../../../micro-app/src/store';

const path = '/';
const mockHistory = {
  ...createMemoryHistory({ initialEntries: [path] }),
  push: jest.fn(),
  replace: jest.fn()
};
export {mockHistory as history};

function renderWithRouter(
  ui,
  {
    route = path,
    history = mockHistory,
  } = {}
) {
  return {
    ...render(
      <IntlProvider locale='en'>
        <Provider store={store}>
          <Router history={history}>{ui}</Router>)
        </Provider>
      </IntlProvider>)
    ,
    history,
  }
}

export default renderWithRouter;