import React from 'react';

import { Route } from 'react-router-dom';
import {
  NavItem,
  SubNavListItem
} from '@panwds/react-nav';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import Nav from './index';

jest.mock('../../helpers/translate');
configure({ adapter: new Adapter() });

describe('<Nav>', () => {
  const props = {
    menu: {
      incidents: {
        accessible: true,
      },
      profile: {
        accessible: true,
      },
      detection_methods: {
        data_pattern: {
          accessible: true,
        },
        edm: {
          accessible: true,
        },
        ocr: {
          accessible: true,
        }
      },
      document_types: {
        accessible: true
      },
      telemetry: {
        accessible: true
      },
      reports: {
        accessible: true,
      },
      audit_log: {
        accessible: true
      },
      settings: {
        accessible: true
      }
    },
    currentUser: {
      name: 'Test User',
      tenantId: '1234',
      url: '/logout'
    },
    hub: {
      url: '/hub',
      accessible: true
    }
  };

  it('renders', () => {
    const wrapper = shallow(<Nav {...props} />);

    expect(wrapper.find(NavItem)).toHaveLength(9);
    expect(wrapper.find(SubNavListItem)).toHaveLength(3);
    expect(wrapper.find(Route)).toHaveLength(12);
    expect(wrapper).toMatchSnapshot();
  });

  it('hides menu items based on props', () => {
    const newProps = Object.assign({}, props, {
      menu: {
        incidents: {
          accessible: false,
        },
        profile: {
          accessible: false,
        },
        detection_methods: {
          data_pattern: {
            accessible: false,
          },
          edm: {
            accessible: false,
          },
          ocr: {
            accessible: false,
          }
        },
        document_types: {
          accessible: false
        },
        telemetry: {
          accessible: false
        },
        reports: {
          accessible: false,
        },
        audit_log: {
          accessible: false
        },
        settings: {
          accessible: false
        }
      },
      currentUser: {
        name: undefined,
        tenantId: undefined,
        url: '/logout'
      },
      hub: {
        url: '/hub',
        accessible: false
      }
    });

    const wrapper = shallow(<Nav {...newProps} />);

    expect(wrapper.find(NavItem)).toHaveLength(1);
    expect(wrapper.find(SubNavListItem)).toHaveLength(0);
    expect(wrapper.find(Route)).toHaveLength(2);
    expect(wrapper).toMatchSnapshot();
  });
});