/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@panwds/react-ui';
import $ from 'jquery';

import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import SingleSelect from '../SingleSelect';
import translate from "../../helpers/translate";
import toastrWrapper from '../../helpers/toastr';

import XsoarIcon from '../../../assets/images/xsoar.png';
import AnchorIcon from '../../../assets/images/anchor.svg';

import styles from './AlertSettings.module.scss';

const t = (scope, options) => translate(`alert_settings.${scope}`, options);

const SLACK_INTEGRATION_DOC = 'https://xsoar.pan.dev/docs/reference/integrations/slack-v3';
const MAIL_SENDER_DOC = 'https://xsoar.pan.dev/docs/reference/integrations/mail-sender-new';
const DLP_DOC = 'https://docs.paloaltonetworks.com/enterprise-dlp/enterprise-dlp-admin/configure-enterprise-dlp/enterprise-dlp-end-user-alerting-with-cortex-xsoar.html';

const FAWKES_LOOKUP_URL = '/v1/dlp-ui/ocr';
const LOOKUP_URL = '/notification_settings.json';

const MAX_DURATION = 90 * 24 * 60; // Max duration is 3 months
const TIME_UNITS = [
  {
    value: 'Minutes',
    key: 'minutes'
  },
  {
    value: 'Hours',
    key: 'hours'
  }
];

const AlertSettings = ({fawkes, userRole}) => {
  const [aboutSectionOpen, setAboutSectionOpen] = useState(true);
  const [setupSectionOpen, setSetupSectionOpen] = useState(false);
  const [configSectionOpen, setConfigSectionOpen] = useState(false);
  const [includeSnippets, setIncludeSnippets] = useState(false);
  const [duration, setDuration] = useState(12);
  const [unit, setUnit] = useState('hours');
  const [enableIntegration, setEnableIntegration] = useState(false);
  const [tenantConfig, setTenantConfig] = useState({});
  const [durationValid, setDurationValid] = useState(true);

  const getLookupUrl = () => fawkes? FAWKES_LOOKUP_URL : LOOKUP_URL;

  const fetchData = () => {
    const lookupURL = getLookupUrl();
    return axios(
      lookupURL
    ).then( result => {
      setTenantConfig(result.data);
      const {
        incident_notification_enablement,
        incident_notification_snippets_enablement,
        detection_exemption_period_minutes
      } = result.data;

      setEnableIntegration(incident_notification_enablement);
      setIncludeSnippets(incident_notification_snippets_enablement);
      if (detection_exemption_period_minutes) {
        if (detection_exemption_period_minutes > 60) {
          setDuration(detection_exemption_period_minutes/60);
          setUnit('hours');
        } else {
          setDuration(detection_exemption_period_minutes);
          setUnit('minutes');
        }
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const timeUnitChanged = (event) => {
    setUnit(event.selectedItem.key);
  }

  const durationChanged = (event) => {
    const value = parseInt(event.target.value, 10);
    setDuration(value);
    const durationMinutes = unit === 'hours'? value * 60 : value;
    setDurationValid(durationMinutes < MAX_DURATION && durationMinutes > 0);
  }

  const showFlashMessage = (message, iconType) => {
    $('#flash-messages').html(
      `<div class="alert alert-floating alert-dismissible fade show" role="alert">
        <span class="fa fa-check-circle ${iconType} flash-icon"></span>
        <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
        ${message}
      </div>`)
    setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
  }

  const save = async () => {
    const lookupURL = getLookupUrl();
    tenantConfig.incident_notification_enablement = enableIntegration;
    tenantConfig.incident_notification_snippets_enablement = includeSnippets;
    const durationMinutes = unit === 'hours'? duration * 60 : duration;
    tenantConfig.detection_exemption_period_minutes = durationMinutes;
    try{
      await axios.post(lookupURL, tenantConfig);
      toastrWrapper.success(t('save_success'));
      showFlashMessage(t('save_success'), 'success');
    } catch (error) {
      toastrWrapper.error(t('server_error'));
      showFlashMessage(t('server_error'));
    }
  }

  return (
    <section className={`alertSettingsRoot ${styles.alertSettings} settings`} data-testid='testRoot'>
      <div className={`${styles.headerSection} tw-mb-3`}>
        <h5>{t('alerts')}</h5>
        <span>{t('alerts_intro')}</span>
      </div>

      <div className={`${styles.mainBody} formCommon`}>
        <div className={`${styles.headerSection} tw-flex tw-justify-between tw-items-center`}>
          <h5>{t('end_user_alerts')}</h5>
          <span className='tw-inline-flex tw-items-center'>
            <img alt="Cortex XSOAR" src={XsoarIcon}/>
            <span className={`${styles.xsoarStatus} tw-ml-2`}>{t('xsoar_integration')}</span>
            {/* <span className={`statusCircle ${integrationStatus} ml-2`} /> */}
          </span>
        </div>

        <div className={`${styles.section}`}>

          <div className={`${styles.sectionHeader} tw-flex tw-items-center tw-justify-items-stretch`}>
            <span className='tw-mr-2'>{t('about')}</span>
            {
              aboutSectionOpen &&
              <>
                <FaAngleDown onClick={() => setAboutSectionOpen(false)}/>
              </>
            }
            {
              !aboutSectionOpen &&  <FaAngleRight onClick={() => setAboutSectionOpen(true)}/>
            }

          </div>
          <div className={`${styles.sectionContent} tw-flex ${aboutSectionOpen? 'open' : 'tw-hidden'}`}>
            <div className={`${styles.leftColumn}`}>
              {t('alert_benefits')}
            </div>
            <div className={`${styles.benefitDetails} tw-ml-40`}>
              <div className={`${styles.list}`} dangerouslySetInnerHTML={{__html: t('alert_benefits_details', { 'interpolation': {'escapeValue': false} })}}/>
              <a className='tw-flex tw-items-start tw-mt-4' href={DLP_DOC} target="_blank" rel="noreferrer">
                <span className='tw-mr-2'>{t('view_documentation')}</span>
                <img alt='anchor icon' src={AnchorIcon}/>
              </a>
            </div>
          </div>
        </div>

        <div className={`${styles.section} setup`}>
          <div className={`${styles.sectionHeader} tw-flex tw-items-center tw-justify-items-stretch`}>
            <span className='tw-mr-2'>{t('setup_instructions')}</span>
            {
              setupSectionOpen &&
              <>
                <FaAngleDown onClick={() => setSetupSectionOpen(false)}/>
              </>
            }
            {
              !setupSectionOpen &&  <FaAngleRight onClick={() => setSetupSectionOpen(true)}/>
            }

          </div>
          <div className={`${styles.sectionContent} ${setupSectionOpen? 'open' : 'tw-hidden'}`}>
            <ol className='tw-pr-20'>
              <li>
                <div className="tw-flex tw-items-center">
                  <span className={styles.instructionMain}>{t('enable_slack_integration')}</span>
                  <a className={`${styles.instructionRow}`} href={SLACK_INTEGRATION_DOC} target='_blank' rel="noreferrer">
                    {t('view_documentation')}
                    <img alt='anchor icon' className='tw-ml-2' src={AnchorIcon}/>
                  </a>
                </div>
              </li>
              <li>
                <div className="tw-flex tw-items-center">
                  <span className={styles.instructionMain}>{t('enable_mail_sender_integration')}</span>
                  <a className={`${styles.instructionRow}`} href={MAIL_SENDER_DOC} target='_blank' rel="noreferrer">
                    {t('view_documentation')}<img alt='anchor icon' className='tw-ml-2' src={AnchorIcon}/>
                  </a>
                </div>
              </li>
              <li>
                <div className="tw-flex tw-mt-8">
                  <span className={styles.instructionMain}>{t('enable_dlp_integration')}</span>

                  <ol>
                    <li>
                      <span>
                        {fawkes && <Link to='./settings'>{t('go_to_tokens')}</Link>}
                        {!fawkes && <a href='/api_tokens'>{t('go_to_tokens')}</a>}
                        <span className='ml-1'>{t('generate_tokens')}</span>
                      </span>
                    </li>
                    <li>
                      <span>{t('enable_dlp_integration_details')}</span>
                      <ul className='tw-pl-8'>
                        <li>Select Data Loss Prevention as Incident Type</li>
                        <li>Select Data Loss Prevention as the Mapper</li>
                        <li>Provide access and refresh tokens</li>
                        <li>Check the &quot;Long Running Instance&quot; checkbox</li>
                        <li>Specify the regions to pull incidents from</li>
                        <li>Specify a list of Data Profiles that can allow exemption</li>
                        <li>Customize the Slack bot message</li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <div className="tw-flex">
                  <span className={styles.instructionMain}>{t('confirm_xsoar_integration')}</span>
                  <div>
                    <span>{t('turn_on_toggle_for_integration')}</span>
                    <div className={`${styles.toggleContainer} tw-flex tw-items-center`}>
                      <ToggleSwitch changeAction={() => setEnableIntegration(!enableIntegration)} checked={enableIntegration} sliderDisabled={userRole !== 'READ_WRITE'}/>
                      <span className='tw-ml-2'>{enableIntegration? t('on') : t('off')}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </div>

        <div className={`${styles.section} config`}>
          <div className={`${styles.sectionHeader} tw-flex tw-items-center tw-justify-items-stretch`}>
            <span className='tw-mr-2'>{t('configuration')}</span>
            {
              configSectionOpen &&
              <>
                <FaAngleDown onClick={() => setConfigSectionOpen(false)}/>
              </>
            }
            {
              !configSectionOpen &&  <FaAngleRight onClick={() => setConfigSectionOpen(true)}/>
            }

          </div>
          <div className={`${styles.sectionContent} ${ configSectionOpen? 'open' : 'tw-hidden'}`}>
            <div className='tw-flex tw-items-center'>
              <span className={`${styles.configLabel}`}>{t('exemption_duration')}</span>
              <span className='tw-inline-flex'>
                <input disabled={userRole !== 'READ_WRITE'} className={`${styles.durationInput} tw-mr-2 form-control ${durationValid? '': 'error'}`} type='number' maxLength={2}
                value={duration} onChange={durationChanged}/>
                <SingleSelect disabled={userRole !== 'READ_WRITE'} items={TIME_UNITS} onChange={(event) => timeUnitChanged(event)} selectedKey={unit}/>
              </span>
            </div>
            {!durationValid && <span className={`${styles.durationInvalidMsg} error`}>{t('duration_invalid')}</span>}
           {/*  <div className='tw-flex tw-items-center'>
              <span className={`${styles.configLabel}`}>{t('include_snippets')}</span>
              <span className='tw-inline-flex tw-items-center'>
                <ToggleSwitch changeAction={() => setIncludeSnippets(!includeSnippets)} checked={includeSnippets} sliderDisabled={userRole !== 'READ_WRITE'}/>
                <span className='tw-ml-2'>{includeSnippets? t('on') : t('off')}</span>
              </span>
            </div> */}
          </div>
        </div>

        <div className={`${styles.actions} tw-flex tw-justify-items-end tw-m-2`}>
          { userRole=== 'READ_WRITE' &&
            <Button addClassName='save' appearance='primary' size='md' onClick={save} aria-label='save' disabled={!durationValid}>
              {translate('actions.save')}
            </Button>
          }
        </div>
      </div>

    </section>
  );
};

AlertSettings.propTypes = {
  fawkes: PropTypes.bool,
  userRole: PropTypes.string,
};

AlertSettings.defaultProps = {
  fawkes: false,
  userRole: 'READ_ONLY'
}
export default AlertSettings;