import React, {useState, useEffect} from "react";
import _find from 'lodash.find';
import {IntlProvider} from 'react-intl';
import { Select } from "@panwds/react-ui";
import PropTypes from 'prop-types';

const SingleSelect = ({items, selectedKey, onChange, disabled, button, style, enableSearch}) => {

  const [selectedItem, setSelectedItem] = useState();

  const handleChange = (event) => {
    setSelectedItem(event.selectedItem);
    onChange(event);
  }

  useEffect(() => {
    const item = _find(items, {key: selectedKey});
    setSelectedItem(item);
  }, [selectedKey]);

  return (
    <IntlProvider locale='en'>
      <Select
        disabled={disabled}
        button={button}
        items={items}
        menuStyle={style}
        selectedItem={selectedItem}
        onChange={handleChange}
        enableSearch={enableSearch}
      />
    </IntlProvider>
  );
};

SingleSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  })).isRequired,
  selectedKey: PropTypes.string,
  style: PropTypes.shape({}),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  enableSearch: PropTypes.bool,
  button: PropTypes.object,
}

SingleSelect.defaultProps = {
  selectedKey: undefined,
  onChange: () => {},
  disabled: false,
  enableSearch: false,
  button: undefined,
  style: {},
}

export default SingleSelect;