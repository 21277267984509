import { RULE_TECHNIQUE_DOCUMENT_TYPE } from '../constants';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`data_profiles.index.${scope}`, options);
}

function patternList(formattedProfile, patterns) {
  const patternNames = [];
  (formattedProfile.advance_data_patterns_rules || []).forEach(rule => {
    (rule.conditions || []).forEach(condition => {
      (condition.rule_items || []).forEach(item => {
        if (patterns[item.id]) {
          const {name} = patterns[item.id];
          patternNames.push(name);
        }
        else {
          patternNames.push(item.name);
        }
      })
    })
  })

  return patternNames;
}

function basicRules(rules, patterns) {
  const includeRules = [];
  const excludeRules = [];
  rules.data_pattern_rules.forEach(pattern => {
    const name = patterns[pattern.id]?.name;
    if (name) {
      pattern.name = name;
    }
    else {
      pattern = { ...pattern, id: '', name: '' }
    }
    if (pattern.match_type === 'include') {
      includeRules.push(pattern);
    }
    else {
      excludeRules.push(pattern);
    }
  });
  const conditions = [];

  if (includeRules.length > 0) {
    conditions.push({
      operators: new Array(includeRules.length === 1 ? 1 : includeRules.length - 1).fill(rules.operator_type),
      rule_items: includeRules
    });
  }
  if (excludeRules.length > 0 && includeRules.length === 0) {
    return({
      operators: [],
      conditions: [
        {
          operators: new Array(excludeRules.length === 1 ? 1 : excludeRules.length - 1).fill(rules.operator_type),
          rule_items: excludeRules
        }
      ]
    });
  }
  if (excludeRules.length > 0) {
    conditions.push({
      operators: new Array(excludeRules.length - 1).fill(rules.operator_type),
      rule_items: excludeRules
    });
  }
  if (includeRules.length > 0 && excludeRules.length === 0) {
    return({
      operators: [],
      conditions
    });
  }
  return({
    operators: [`${rules.operator_type}_not`],
    conditions
  });
}

function transformBasicToAdvanced(profile, patterns) {
  const advRules = [];

  if (profile.data_patterns_rule_1 && Object.keys(profile.data_patterns_rule_1).length > 0) {
    advRules[0] = basicRules(profile.data_patterns_rule_1, patterns);
  }
  else {
    advRules[0] = {}
  }
  if (profile.data_patterns_rule_2 && Object.keys(profile.data_patterns_rule_2).length > 0) {
    advRules.push(basicRules(profile.data_patterns_rule_2, patterns));
  }
  else {
    advRules.push({});
  }

  return advRules;
}

function updatePatternNames(advRule, patterns) {
  if (!advRule || !advRule.conditions) {
    return {};
  }
  advRule.conditions.forEach( condition => {
    condition.rule_items = condition.rule_items.map(ruleItem => {
      const name = patterns[ruleItem.id]?.name;
      if (name) {
        return { ...ruleItem, name }
      }
      return { ...ruleItem, id: ruleItem.id }
    });
  });
  return advRule;
}

function updateItemNames(subExpressions, datasets, patterns) {
  subExpressions.forEach(subExp => {
    if (subExp.rule_item) {
      if (subExp.rule_item.detection_technique === 'edm') {
        const name = datasets[subExp.rule_item.edm_dataset_id]?.name;
        if (name) {
          subExp.rule_item.name = name;
        }
        else {
          subExp.rule_item = { ...subExp.rule_item, edm_dataset_id: '', name: '' }
        }
      }
      else {
        const name = patterns[subExp.rule_item.id]?.name;

        if (name) {
          subExp.rule_item.name = name;
        }
        else if (subExp.rule_item?.detection_technique !== RULE_TECHNIQUE_DOCUMENT_TYPE) {
          subExp.rule_item = { ...subExp.rule_item, id: '', name: '' };
        }
      }
    }
    else {
      updateItemNames(subExp.sub_expressions, datasets, patterns);
    }
  })
}

function expTreeBody() {
  return {
    operator_type: 'and',
    rule_item: null,
    sub_expressions: []
  }
}

function formatV2(expTree, datasets, patterns) {
  if (!expTree) {
    return expTreeBody();
  }
  if (expTree.rule_item && (!expTree.sub_expressions || expTree.sub_expressions.length === 0)) {
    if (expTree.rule_item.detection_technique === 'edm') {
      const name = datasets[expTree.rule_item.edm_dataset_id]?.name;
      if (name) {
        expTree.rule_item.name = name;
      }
      else {
        expTree.rule_item = { ...expTree.rule_item, edm_dataset_id: '', name: '' }
      }
    }
    return {
      operator_type: 'and',
      rule_item: null,
      sub_expressions: [
        {
          operator_type: null,
          rule_item: expTree.rule_item,
          sub_expressions: []
        }
      ]
    }
  }
  updateItemNames(expTree.sub_expressions, datasets, patterns)
  return expTree;
}

function formatMulti(multiProfile, profiles) {
  if (!multiProfile) {
    return [];
  }

  if (Array.isArray(multiProfile)) {
    return multiProfile.map(p => { return {value: p} });
  }

  let arrProfiles = [];
  const formattedList = [];

  if (!Array.isArray(profiles)) {
    arrProfiles = Object.values(profiles).map(obj => ({
      value: obj.name,
      key: obj.id,
      disabled: obj.datapattern_readonly
    }));
  } else {
    arrProfiles = [ ...profiles ];
  }

  multiProfile.multi_profile.data_profile_ids.forEach(id => {
    const profile = arrProfiles.find(p => p?.key?.toString() === id.toString());

    if (!profile || profile.profile_available_for_multi_profile) {
      formattedList.push({});
    }
    else {
      formattedList.push(profile);
    }
  });

  return formattedList;
}

export const profileDetails = (profile, patterns, datasets, clone=false, profiles=[]) => {
  let profileType = profile.profile_type;
  if (profileType === t('modes.multi')) {
    profileType = 'multi';
  }
  if (profile.detection_rules && profile.detection_rules.length > 0) {
    if (profile.detection_rules[0].rule_type === 'expression_tree') {
      profileType = 'edm';
    }
    else if (profile.detection_rules[0].rule_type === 'multi_profile' || Array.isArray(profile.detection_rules[0])) {
      profileType = 'multi';
    }
  }
  if (clone && profileType === 'predefined' && profile.schema_version !== 2) {
    profileType = 'custom';
  }
  const formattedProfile = {
    id: clone ? '' : profile.id,
    name: clone ? `Copy - ${profile.name}` : profile.name || 'Not Found',
    profile_type: t(`modes.${profileType}`, {default: 'Unknown'}),
    updated_at: clone ? '' : profile.updated_at,
    updated_by: clone ? '' : translate(profile.updated_by, {platform: true}),
    schema_version: profile.schema_version || 1,
    type: `${(profile.type || '').charAt(0).toUpperCase()}${(profile.type || '').slice(1)}`,
    data_patterns: [],
    multiProfile: profileType === 'multi',
    secondary_rule_eligible: profile.secondary_rule_eligible !== false
  };


  if (profile.profile_type === t('modes.multi')) {
    formattedProfile.selectedPrimaryProfiles = profile.selectedPrimaryProfiles;
    formattedProfile.selectedSecondaryProfiles = profile.selectedSecondaryProfiles;
  }

  if (formattedProfile.name.length > 32) {
    formattedProfile.name = formattedProfile.name.substring(0, 32);
  }

  if ((profile.data_patterns_rule_1 && Object.keys(profile.data_patterns_rule_1).length > 0) || profile.data_patterns_rule_2 && Object.keys(profile.data_patterns_rule_2).length > 0) {
    formattedProfile.advance_data_patterns_rules = transformBasicToAdvanced(profile, patterns);
  }

  if (profile.advance_data_patterns_rules && profile.advance_data_patterns_rules.length > 0 && (!profile.detection_rules || profile.detection_rules.length === 0)) {
    formattedProfile.advance_data_patterns_rules = [updatePatternNames(profile.advance_data_patterns_rules[0], patterns), updatePatternNames(profile.advance_data_patterns_rules[1], patterns)];
  }

  if (profile.detection_rules && profile.detection_rules.length > 0 && profileType === 'edm') {
    formattedProfile.primaryExpTree = formatV2(profile.detection_rules[0].expression_tree, datasets, patterns);
    formattedProfile.secondaryExpTree = formatV2(profile.detection_rules[1]?.expression_tree, datasets, patterns);
  }

  if (profile.detection_rules && profile.detection_rules.length > 0 && profileType === 'multi') {
    formattedProfile.selectedPrimaryProfiles = formatMulti(profile.detection_rules[0], profiles);
    formattedProfile.selectedSecondaryProfiles = formatMulti(profile.detection_rules[1], profiles);
  }

  if (profile.primaryExpTree) {
    formattedProfile.primaryExpTree = profile.primaryExpTree;
    formattedProfile.secondaryExpTree = profile.secondaryExpTree;
  }

  formattedProfile.data_patterns = patternList(formattedProfile, patterns);
  return formattedProfile;
}

export default (profile, patterns, datasets, clone=false, profiles=[]) => profileDetails(profile, patterns, datasets, clone, profiles);
