// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataProfileDetailPreview> v1 renders pattern only preview 1`] = `
<div
  style={
    Object {
      "padding": "15px",
    }
  }
>
  <div
    className="rule"
    key="primary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.primary_rule
    </p>
    <span
      className="muteText"
      style={
        Object {
          "lineHeight": "20px",
        }
      }
    >
      data_profiles.form.no_criteria_defined
    </span>
    <hr />
  </div>
  <div
    className="rule"
    key="secondary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.secondary_rule
    </p>
    <span
      className="muteText"
      style={
        Object {
          "lineHeight": "20px",
        }
      }
    >
      data_profiles.form.no_criteria_defined
    </span>
  </div>
</div>
`;

exports[`<DataProfileDetailPreview> v1 renders v1 multi-op a and_not b pattern only preview 1`] = `
<div
  style={
    Object {
      "padding": "15px",
    }
  }
>
  <div
    className="rule"
    key="primary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.primary_rule
    </p>
    <div>
      <div>
        <div
          className="sectionHead"
        >
          <span
            className="angleToggle"
            onClick={[Function]}
          >
            <FaAngleDown />
          </span>
          <FaLayerGroup
            className="mr10 ml10 muteText"
          />
          <span>
            data_profiles.form.match_data_pattern_criteria
          </span>
        </div>
        <div
          className="sectionGroup"
        >
          <div
            className=" pattern"
            key="0"
            style={
              Object {
                "marginLeft": "17px",
              }
            }
          >
            <FaParking
              className="mr10 helpText"
            />
            SSN
            <br />
            <span
              className=" helpText"
              style={
                Object {
                  "marginLeft": "22px",
                }
              }
            >
              Between (inclusive) 4 and 24 - high
            </span>
            <br />
          </div>
          <div
            className=" pattern"
            key="1"
            style={
              Object {
                "marginLeft": "17px",
              }
            }
          >
            <FaParking
              className="mr10 helpText"
            />
            <span
              className=""
            >
              <span
                className="upperBold mr5"
              >
                and
              </span>
              <span
                className="upperBold mr5 codeRed"
              >
                not
              </span>
            </span>
            CCN
            <br />
            <span
              className=" helpText"
              style={
                Object {
                  "marginLeft": "22px",
                }
              }
            >
              ? - medium
            </span>
            <br />
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
  <div
    className="rule"
    key="secondary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.secondary_rule
    </p>
    <span
      className="muteText"
      style={
        Object {
          "lineHeight": "20px",
        }
      }
    >
      data_profiles.form.no_criteria_defined
    </span>
  </div>
</div>
`;

exports[`<DataProfileDetailPreview> v1 renders v1 multi-op pattern only preview 1`] = `
<div
  style={
    Object {
      "padding": "15px",
    }
  }
>
  <div
    className="rule"
    key="primary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.primary_rule
    </p>
    <div>
      <div>
        <div
          className="sectionHead"
        >
          <span
            className="angleToggle"
            onClick={[Function]}
          >
            <FaAngleDown />
          </span>
          <FaLayerGroup
            className="mr10 ml10 muteText"
          />
          <span>
            data_profiles.form.match_data_pattern_criteria
          </span>
        </div>
        <div
          className="sectionGroup"
        >
          <div
            className="nestedSection"
            key="0"
          >
            <div
              className="nestedSectionHead"
            >
              <span
                className="angleToggle"
                onClick={[Function]}
              >
                <FaAngleDown />
              </span>
              <FaLayerGroup
                className="mr10 ml10 muteText"
              />
            </div>
            <div
              className="sectionGroup"
            >
              <div
                className=" pattern"
                key="0"
                style={
                  Object {
                    "marginLeft": "17px",
                  }
                }
              >
                <FaParking
                  className="mr10 helpText"
                />
                SSN
                <br />
                <span
                  className=" helpText"
                  style={
                    Object {
                      "marginLeft": "22px",
                    }
                  }
                >
                  Between (inclusive) 4 and 24 - high
                </span>
                <br />
              </div>
              <div
                className=" pattern"
                key="1"
                style={
                  Object {
                    "marginLeft": "17px",
                  }
                }
              >
                <FaParking
                  className="mr10 helpText"
                />
                <span
                  className=""
                >
                  <span
                    className="upperBold mr5"
                  >
                    or
                  </span>
                </span>
                CCN
                <br />
                <span
                  className=" helpText"
                  style={
                    Object {
                      "marginLeft": "22px",
                    }
                  }
                >
                  ? - medium
                </span>
                <br />
              </div>
              <div
                className=" pattern"
                key="2"
                style={
                  Object {
                    "marginLeft": "17px",
                  }
                }
              >
                <FaParking
                  className="mr10 helpText"
                />
                <span
                  className=""
                >
                  <span
                    className="upperBold mr5"
                  >
                    and
                  </span>
                  <span
                    className="upperBold mr5 codeRed"
                  >
                    not
                  </span>
                </span>
                ABC
                <br />
                <span
                  className=" helpText"
                  style={
                    Object {
                      "marginLeft": "22px",
                    }
                  }
                >
                  ? - medium
                </span>
                <br />
              </div>
            </div>
          </div>
          <div
            className="nestedSection"
            key="1"
          >
            <div
              className="nestedSectionHead"
            >
              <span
                className="angleToggle"
                onClick={[Function]}
              >
                <FaAngleDown />
              </span>
              <FaLayerGroup
                className="mr10 ml10 muteText"
              />
              <span
                className=""
              >
                <span
                  className="upperBold mr5"
                >
                  and
                </span>
              </span>
            </div>
            <div
              className="sectionGroup"
            >
              <div
                className=" pattern"
                key="0"
                style={
                  Object {
                    "marginLeft": "17px",
                  }
                }
              >
                <FaParking
                  className="mr10 helpText"
                />
                Bank
                <br />
                <span
                  className=" helpText"
                  style={
                    Object {
                      "marginLeft": "22px",
                    }
                  }
                >
                  Between (inclusive) 42 and 241 - high
                </span>
                <br />
              </div>
              <div
                className=" pattern"
                key="1"
                style={
                  Object {
                    "marginLeft": "17px",
                  }
                }
              >
                <FaParking
                  className="mr10 helpText"
                />
                <span
                  className=""
                >
                  <span
                    className="upperBold mr5"
                  >
                    or
                  </span>
                  <span
                    className="upperBold mr5 codeRed"
                  >
                    not
                  </span>
                </span>
                Passport
                <br />
                <span
                  className=" helpText"
                  style={
                    Object {
                      "marginLeft": "22px",
                    }
                  }
                >
                  Any occurence - low
                </span>
                <br />
              </div>
            </div>
          </div>
          <div
            className="nestedSection"
            key="2"
          >
            <div
              className="nestedSectionHead"
            >
              <span
                className="angleToggle"
                onClick={[Function]}
              >
                <FaAngleDown />
              </span>
              <FaLayerGroup
                className="mr10 ml10 muteText"
              />
              <span
                className=""
              >
                <span
                  className="upperBold mr5"
                >
                  or
                </span>
              </span>
            </div>
            <div
              className="sectionGroup"
            >
              <div
                className=" pattern"
                key="0"
                style={
                  Object {
                    "marginLeft": "17px",
                  }
                }
              >
                <FaParking
                  className="mr10 helpText"
                />
                DEF
                <br />
                <span
                  className=" helpText"
                  style={
                    Object {
                      "marginLeft": "22px",
                    }
                  }
                >
                  Between (inclusive) 42 and 241 - high
                </span>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
  <div
    className="rule"
    key="secondary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.secondary_rule
    </p>
    <span
      className="muteText"
      style={
        Object {
          "lineHeight": "20px",
        }
      }
    >
      data_profiles.form.no_criteria_defined
    </span>
  </div>
</div>
`;

exports[`<DataProfileDetailPreview> v2 changed version diff proper highlighting 1`] = `
<div
  style={
    Object {
      "padding": "15px",
    }
  }
>
  <div
    className="rule"
    key="primary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.primary_rule
    </p>
    <div
      className="sectionGroup"
    >
      <div
        className="nestedSection"
        key="0-0"
      >
        <div
          className="nestedSectionHead"
        >
          <span
            className="angleToggle"
            onClick={[Function]}
          >
            <FaAngleDown />
          </span>
          <FaLayerGroup
            className="mr10 ml10 muteText"
          />
          <span
            className="upperBold mr5  "
          >
            or
          </span>
        </div>
        <div
          className="sectionGroup"
        >
          <div
            className=""
            key="0"
          >
            <div
              className="pattern"
              key="0"
              style={
                Object {
                  "marginLeft": "17px",
                }
              }
            >
              <FaParking
                className="mr10 helpText"
              />
              Address  - Turkey
              <br />
              <span
                className="helpText "
                style={
                  Object {
                    "marginLeft": "22px",
                  }
                }
              >
                Any occurence - high
              </span>
              <br />
            </div>
          </div>
          <div
            className=""
            key="1"
          >
            <div
              className="dataset"
              key="1"
              style={
                Object {
                  "marginLeft": "17px",
                }
              }
            >
              <FaTable
                className="mr10 helpText"
              />
              <span
                className="upperBold mr5 "
              >
                or
              </span>
              Dataset A
              <br />
              <div
                className="helpText "
                style={
                  Object {
                    "marginLeft": "23px",
                  }
                }
              >
                Any occurence
              </div>
              <div
                className="matchDef helpText"
                style={
                  Object {
                    "marginLeft": "24px",
                  }
                }
              >
                <span
                  className="mr5"
                >
                  data_profiles.form.match
                </span>
                <span
                  className=""
                >
                  <span
                    className="mr5 codeRed"
                  >
                    any
                  </span>
                  <span
                    className="mr5 codeRed"
                  >
                    2
                  </span>
                </span>
                <span
                  className="mr5"
                >
                  data_profiles.form.in
                </span>
                <span
                  className="mr5"
                >
                  <span
                    className=""
                  >
                    (
                    <span
                      key="SSN"
                    >
                      SSN
                    </span>
                    <span
                      className="primaryBlue"
                      key="0"
                    >
                       
                      DATA_PROFILES.FORM.MATCH_PREVIEW.ANY
                       
                    </span>
                    <span
                      key="CCN"
                    >
                      CCN
                    </span>
                    )
                  </span>
                </span>
                <span
                  className=""
                >
                  <span
                    className="mr5 primaryBlue"
                  >
                    DATA_PROFILES.FORM.DATASET_FORM.AND
                  </span>
                  <span
                    className="mr5"
                  >
                    data_profiles.form.match
                  </span>
                  <span
                    className=""
                  >
                    <span
                      className="mr5 codeRed"
                    >
                      any
                    </span>
                    <span
                      className="mr5 codeRed"
                    >
                      1
                    </span>
                  </span>
                  <span
                    className="mr5"
                  >
                    data_profiles.form.in
                  </span>
                  <span
                    className=""
                  >
                    (
                    <span
                      key="Subscriber ID"
                    >
                      Subscriber ID
                    </span>
                    )
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div
            className="nestedSection"
            key="0-0-2"
          >
            <div
              className="nestedSectionHead"
            >
              <span
                className="angleToggle"
                onClick={[Function]}
              >
                <FaAngleDown />
              </span>
              <FaLayerGroup
                className="mr10 ml10 muteText"
              />
              <span
                className="upperBold mr5  "
              >
                and
              </span>
            </div>
            <div
              className="sectionGroup"
            >
              <div
                className=""
                key="0"
              >
                <div
                  className="pattern"
                  key="0"
                  style={
                    Object {
                      "marginLeft": "17px",
                    }
                  }
                >
                  <FaParking
                    className="mr10 helpText"
                  />
                  Address - Australia
                  <br />
                  <span
                    className="helpText "
                    style={
                      Object {
                        "marginLeft": "22px",
                      }
                    }
                  >
                    Any occurence - high
                  </span>
                  <br />
                </div>
              </div>
              <div
                className=""
                key="1"
              >
                <div
                  className="pattern"
                  key="1"
                  style={
                    Object {
                      "marginLeft": "17px",
                    }
                  }
                >
                  <FaParking
                    className="mr10 helpText"
                  />
                  <span
                    className="upperBold mr5 "
                  >
                    and
                  </span>
                  Credit Card CVV
                  <br />
                  <span
                    className="helpText "
                    style={
                      Object {
                        "marginLeft": "22px",
                      }
                    }
                  >
                    Any occurence - high
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className=""
        key="1"
      >
        <div
          className="pattern"
          key="1"
          style={
            Object {
              "marginLeft": "17px",
            }
          }
        >
          <FaParking
            className="mr10 helpText"
          />
          <span
            className="upperBold mr5 "
          >
            and
          </span>
          API Credentials Client ID - Foursquare
          <br />
          <span
            className="helpText "
            style={
              Object {
                "marginLeft": "22px",
              }
            }
          >
            Any occurence - high
          </span>
          <br />
        </div>
      </div>
    </div>
    <hr />
  </div>
  <div
    className="rule"
    key="secondary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.secondary_rule
    </p>
    <span
      className="muteText"
      style={
        Object {
          "lineHeight": "20px",
        }
      }
    >
      data_profiles.form.no_criteria_defined
    </span>
  </div>
</div>
`;

exports[`<DataProfileDetailPreview> v2 renders hybrid detail preview 1`] = `
<div
  style={
    Object {
      "padding": "15px",
    }
  }
>
  <div
    className="rule"
    key="primary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.primary_rule
    </p>
    <div
      className="sectionGroup"
    >
      <div
        className=""
        key="0"
      >
        <div
          className="pattern"
          key="0"
          style={
            Object {
              "marginLeft": "17px",
            }
          }
        >
          <FaParking
            className="mr10 helpText"
          />
          SSN
          <br />
          <span
            className="helpText "
            style={
              Object {
                "marginLeft": "22px",
              }
            }
          >
            Any occurence - high
          </span>
          <br />
        </div>
      </div>
      <div
        className="nestedSection"
        key="0-1"
      >
        <div
          className="nestedSectionHead"
        >
          <span
            className="angleToggle"
            onClick={[Function]}
          >
            <FaAngleDown />
          </span>
          <FaLayerGroup
            className="mr10 ml10 muteText"
          />
          <span
            className="upperBold mr5  "
          >
            or
          </span>
        </div>
        <div
          className="sectionGroup"
        >
          <div
            className="nestedSection"
            key="0-1-0"
          >
            <div
              className="nestedSectionHead"
            >
              <span
                className="angleToggle"
                onClick={[Function]}
              >
                <FaAngleDown />
              </span>
              <FaLayerGroup
                className="mr10 ml10 muteText"
              />
              <span
                className="upperBold mr5  "
              >
                or
              </span>
            </div>
            <div
              className="sectionGroup"
            >
              <div
                className=""
                key="0"
              >
                <div
                  className="pattern"
                  key="0"
                  style={
                    Object {
                      "marginLeft": "17px",
                    }
                  }
                >
                  <FaParking
                    className="mr10 helpText"
                  />
                  CCN
                  <br />
                  <span
                    className="helpText "
                    style={
                      Object {
                        "marginLeft": "22px",
                      }
                    }
                  >
                    Any occurence - high
                  </span>
                  <br />
                </div>
              </div>
              <div
                className=""
                key="1"
              >
                <div
                  className="dataset"
                  key="1"
                  style={
                    Object {
                      "marginLeft": "17px",
                    }
                  }
                >
                  <FaTable
                    className="mr10 helpText"
                  />
                  <span
                    className="upperBold mr5 "
                  >
                    or
                  </span>
                  Dataset B
                  <br />
                  <div
                    className="helpText "
                    style={
                      Object {
                        "marginLeft": "23px",
                      }
                    }
                  >
                    Any occurence
                  </div>
                  <div
                    className="matchDef helpText"
                    style={
                      Object {
                        "marginLeft": "24px",
                      }
                    }
                  >
                    <span
                      className="mr5"
                    >
                      data_profiles.form.match
                    </span>
                    <span
                      className=""
                    >
                      <span
                        className="mr5 codeRed"
                      >
                        all
                      </span>
                    </span>
                    <span
                      className="mr5"
                    >
                      data_profiles.form.in
                    </span>
                    <span
                      className="mr5"
                    >
                      <span
                        className=""
                      >
                        (
                        <span
                          key="SSN"
                        >
                          SSN
                        </span>
                        <span
                          className="primaryBlue"
                          key="0"
                        >
                           
                          DATA_PROFILES.FORM.MATCH_PREVIEW.ALL
                           
                        </span>
                        <span
                          key="CCN"
                        >
                          CCN
                        </span>
                        )
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="nestedSection"
        key="0-2"
      >
        <div
          className="nestedSectionHead"
        >
          <span
            className="angleToggle"
            onClick={[Function]}
          >
            <FaAngleDown />
          </span>
          <FaLayerGroup
            className="mr10 ml10 muteText"
          />
          <span
            className="upperBold mr5 codeRed "
          >
            not
          </span>
        </div>
        <div
          className="sectionGroup"
        >
          <div
            className=""
            key="0"
          >
            <div
              className="dataset"
              key="0"
              style={
                Object {
                  "marginLeft": "17px",
                }
              }
            >
              <FaTable
                className="mr10 helpText"
              />
              Dataset A
              <br />
              <div
                className="helpText "
                style={
                  Object {
                    "marginLeft": "23px",
                  }
                }
              >
                Any occurence
              </div>
              <div
                className="matchDef helpText"
                style={
                  Object {
                    "marginLeft": "24px",
                  }
                }
              >
                <span
                  className="mr5"
                >
                  data_profiles.form.match
                </span>
                <span
                  className=""
                >
                  <span
                    className="mr5 codeRed"
                  >
                    all
                  </span>
                </span>
                <span
                  className="mr5"
                >
                  data_profiles.form.in
                </span>
                <span
                  className="mr5"
                >
                  <span
                    className=""
                  >
                    (
                    <span
                      key="SSN"
                    >
                      SSN
                    </span>
                    <span
                      className="primaryBlue"
                      key="0"
                    >
                       
                      DATA_PROFILES.FORM.MATCH_PREVIEW.ALL
                       
                    </span>
                    <span
                      key="Subscriber ID"
                    >
                      Subscriber ID
                    </span>
                    )
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
  <div
    className="rule"
    key="secondary"
  >
    <p
      className="form-label conditionLabel"
    >
      data_profiles.form.secondary_rule
    </p>
    <div
      className="sectionGroup"
    >
      <div
        className=""
        key="0"
      >
        <div
          className="pattern"
          key="0"
          style={
            Object {
              "marginLeft": "17px",
            }
          }
        >
          <FaParking
            className="mr10 helpText"
          />
          SSN
          <br />
          <span
            className="helpText "
            style={
              Object {
                "marginLeft": "22px",
              }
            }
          >
            Any occurence - high
          </span>
          <br />
        </div>
      </div>
      <div
        className="nestedSection"
        key="1-1"
      >
        <div
          className="nestedSectionHead"
        >
          <span
            className="angleToggle"
            onClick={[Function]}
          >
            <FaAngleDown />
          </span>
          <FaLayerGroup
            className="mr10 ml10 muteText"
          />
          <span
            className="upperBold mr5  "
          >
            and
          </span>
        </div>
        <div
          className="sectionGroup"
        >
          <div
            className="nestedSection"
            key="1-1-0"
          >
            <div
              className="nestedSectionHead"
            >
              <span
                className="angleToggle"
                onClick={[Function]}
              >
                <FaAngleDown />
              </span>
              <FaLayerGroup
                className="mr10 ml10 muteText"
              />
              <span
                className="upperBold mr5  "
              >
                and
              </span>
            </div>
            <div
              className="sectionGroup"
            >
              <div
                className=""
                key="0"
              >
                <div
                  className="pattern"
                  key="0"
                  style={
                    Object {
                      "marginLeft": "17px",
                    }
                  }
                >
                  <FaParking
                    className="mr10 helpText"
                  />
                  CCN
                  <br />
                  <span
                    className="helpText "
                    style={
                      Object {
                        "marginLeft": "22px",
                      }
                    }
                  >
                    Any occurence - high
                  </span>
                  <br />
                </div>
              </div>
              <div
                className=""
                key="1"
              >
                <div
                  className="dataset"
                  key="1"
                  style={
                    Object {
                      "marginLeft": "17px",
                    }
                  }
                >
                  <FaTable
                    className="mr10 helpText"
                  />
                  <span
                    className="upperBold mr5 "
                  >
                    and
                  </span>
                  Dataset B
                  <br />
                  <div
                    className="helpText "
                    style={
                      Object {
                        "marginLeft": "23px",
                      }
                    }
                  >
                    Any occurence
                  </div>
                  <div
                    className="matchDef helpText"
                    style={
                      Object {
                        "marginLeft": "24px",
                      }
                    }
                  >
                    <span
                      className="mr5"
                    >
                      data_profiles.form.match
                    </span>
                    <span
                      className=""
                    >
                      <span
                        className="mr5 codeRed"
                      >
                        all
                      </span>
                    </span>
                    <span
                      className="mr5"
                    >
                      data_profiles.form.in
                    </span>
                    <span
                      className="mr5"
                    >
                      <span
                        className=""
                      >
                        (
                        <span
                          key="SSN"
                        >
                          SSN
                        </span>
                        <span
                          className="primaryBlue"
                          key="0"
                        >
                           
                          DATA_PROFILES.FORM.MATCH_PREVIEW.ALL
                           
                        </span>
                        <span
                          key="CCN"
                        >
                          CCN
                        </span>
                        )
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className=""
        key="2"
      >
        <div
          className="dataset"
          key="2"
          style={
            Object {
              "marginLeft": "17px",
            }
          }
        >
          <FaTable
            className="mr10 helpText"
          />
          <span
            className="upperBold mr5 "
          >
            or
          </span>
          Dataset A
          <br />
          <div
            className="helpText "
            style={
              Object {
                "marginLeft": "23px",
              }
            }
          >
            Any occurence
          </div>
          <div
            className="matchDef helpText"
            style={
              Object {
                "marginLeft": "24px",
              }
            }
          >
            <span
              className="mr5"
            >
              data_profiles.form.match
            </span>
            <span
              className=""
            >
              <span
                className="mr5 codeRed"
              >
                all
              </span>
            </span>
            <span
              className="mr5"
            >
              data_profiles.form.in
            </span>
            <span
              className="mr5"
            >
              <span
                className=""
              >
                (
                <span
                  key="SSN"
                >
                  SSN
                </span>
                <span
                  className="primaryBlue"
                  key="0"
                >
                   
                  DATA_PROFILES.FORM.MATCH_PREVIEW.ALL
                   
                </span>
                <span
                  key="Subscriber ID"
                >
                  Subscriber ID
                </span>
                )
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
