/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { FaPen, FaClone } from 'react-icons/fa';
import { Button } from '@panwds/react-ui';
import ReactTooltip from "react-tooltip";

import translate from '../../helpers/translate';
import SpinnerIcon from '../../../assets/images/spinner.gif';
import DataPatternInfo from './DataPatternInfo';

function t(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}

const DataPatternDetails = ({ pattern, updateState, actionable, handleClonePattern }) => {
  return(
    <div className='tableDetailsView'>
      <div className='detailHeader'>
        <span className='detailTitle'>
          {pattern.name ? pattern.name : <img id='loader' src={SpinnerIcon} alt='loading' />}
        </span>
        <Button addClassName='close tw-float-right' data-dismiss='modal' aria-hidden='true' onClick={ () => updateState({viewing: ''}) } />
      </div>

      <div>
        <div className="detailContent">

          <div className='action-btns tw-flex tw-space-x-1'>
          { actionable && actionable.editable &&
            <Button addClassName='edit' appearance='tertiary' size='lg' onClick={() => updateState({editing: pattern.id})} aria-label='edit' icon={<FaPen className='defaultText' />}>
              {translate('actions.edit')}
            </Button>
          }
          { actionable && actionable.cloneable &&
            <Button addClassName='clone' appearance='tertiary-clear' size='lg' onClick={() => handleClonePattern(pattern)}
              aria-label='clone' icon={<FaClone className='defaultText'/>} data-tip={translate('actions.clone')}/>
          }
          </div>


          <p className='detail-subtitle'>{t('details')}</p>
          <DataPatternInfo pattern={pattern} />

          <hr />
        </div>
      </div>
      <ReactTooltip />
    </div>
  )
}

DataPatternDetails.propTypes = {
  pattern: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updated_at: PropTypes.string,
    description: PropTypes.string,
    detection_technique: PropTypes.string,
    type: PropTypes.string,
    regexes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
    metadataCriteria: PropTypes.array,
    delimiter: PropTypes.string,
    proximity_keywords: PropTypes.arrayOf(PropTypes.string),
    supported_confidence_levels: PropTypes.arrayOf(PropTypes.string)
  }),
  updateState: PropTypes.func.isRequired,
  actionable: PropTypes.shape({
    editable: PropTypes.bool,
    deletable: PropTypes.bool,
    cloneable: PropTypes.bool
  }),
  handleClonePattern: PropTypes.func
};

DataPatternDetails.defaultProps = {
  pattern: {},
  actionable: {},
  handleClonePattern: undefined
}

export default DataPatternDetails;
