/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import translate from '../../helpers/translate';

const moment = require('moment-timezone');

function t(scope, options) {
  return translate(`data_filtering_profiles.${scope}`, options);
}

const FilteringProfileDetailsInfo = ({profile, changedObj, fawkes, change}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (typeof profile.file_type !== 'string') {
    profile.file_type = (profile.file_type || []).join(', ');
  }
  if (change && typeof changedObj.file_type !== 'string') {
    changedObj.file_type = (changedObj.file_type || []).join(', ');
  }
  return(
    <div className='tableContainer'>
      <table className={`table table-borderless table-condensed${change ? ' changeTable' : ''}`}>
        <tbody>
          <tr>
            <td className='form-label'>{t('name')}</td>
            <td className={change && profile.name !== changedObj.name ? change : ''}>{profile.name}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('mode')}</td>
            <td className={change && profile.profile_type !== changedObj.profile_type ? change : ''} colid='profile_type'>{profile.profile_type}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('description')}</td>
            <td className={change && profile.description !== changedObj.description ? change : ''}>{profile.description}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('last_modified')}</td>
            <td className={change && profile.updated_at !== changedObj.updated_at ? change : ''}>{profile.updated_at && moment(profile.updated_at).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z')}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('data_profile')}</td>
            <td>{fawkes ?
              <Link to={`./data-profiles/${profile.profile_id}/edit`}>{profile.name}</Link>
              :
              <a href={`/data_profiles/${profile.profile_id}/edit`}>{profile.name}</a>
              }
            </td>
          </tr>
          <tr>
            <td className='form-label'>{t('direction')}</td>
            <td>{t(profile.direction)}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('file_type')}</td>
            <td className={change && profile.file_type !== changedObj.file_type ? change : ''} colid='file_type'>{profile.file_type}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('action')}</td>
            <td className={change && profile.action !== changedObj.action ? change : ''} colid='action'>{profile.action}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('log_severity')}</td>
            <td className={change && profile.log_severity !== changedObj.log_severity ? change : ''} colid='log_severity'>{profile.log_severity}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('file_based')}</td>
            <td className={change && profile.fileBased !== changedObj.fileBased ? change : ''} colid='fileBased'>{profile.fileBased === 'yes' ? t('enabled') : t('disabled')}</td>
          </tr>
          <tr>
            <td className='form-label'>{t('non_file_based')}</td>
            <td className={change && profile.nonFileBased !== changedObj.nonFileBased ? change : ''} colid='nonFileBased'>{profile.nonFileBased === 'yes' ? t('enabled') : t('disabled')}</td>
          </tr>
          {change &&
            <tr>
              <td className='form-label'>{t('version')}</td>
              <td className={change && profile.version !== changedObj.version ? change : ''} colid='version'>{profile.version}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

FilteringProfileDetailsInfo.propTypes = {
  profile: PropTypes.shape({
    action: PropTypes.string,
    description: PropTypes.string,
    direction: PropTypes.string,
    file_type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    fileBased: PropTypes.string,
    log_severity: PropTypes.string,
    name: PropTypes.string,
    nonFileBased: PropTypes.string,
    profile_id: PropTypes.number,
    profile_type: PropTypes.string,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    version: PropTypes.number
  }),
  changedObj: PropTypes.shape({
    name: PropTypes.string,
    profile_type: PropTypes.string,
    description: PropTypes.string,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profile_id: PropTypes.number,
    file_type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    action: PropTypes.string,
    log_severity: PropTypes.string,
    fileBased: PropTypes.string,
    nonFileBased: PropTypes.string,
    version: PropTypes.number,
  }),
  fawkes: PropTypes.bool,
  change: PropTypes.string,
}

FilteringProfileDetailsInfo.defaultProps = {
  profile: {},
  changedObj: {},
  fawkes: false,
  change: undefined,
}

export default FilteringProfileDetailsInfo;