import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { FaArrowAltCircleUp } from 'react-icons/fa';

import translate from '../../../../helpers/translate';

import './styles.scss';

function localizeString(scope, options) {
  return translate(`document_types.misc.${scope}`, options);
}

const FileUpload = ({
  files,
  maxFilesAtOnce,
  onUpload,
  textLabel,
  buttonLabel
}) => {
  const onDrop = useCallback(acceptedFiles => {
    if (onUpload) {
      onUpload([...files, ...acceptedFiles]);
    }
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  const handleDisableClick = (event) => {
    event.stopPropagation();
  };

  const handleClickButton = (event) => {
    const { onClick } = getRootProps();

    onClick(event);
  };

  return (
    <div className='dlp-file-upload'>
      <section className="container">
        { files.length >= maxFilesAtOnce &&
          <div className='disable-mask' />
        }

        <div
          {...getRootProps({
            className: 'dropzone',
            onClick: handleDisableClick
          })}
          data-testid='file-drop-input'
        >
          <input {...getInputProps()} />
          <p className='info-text'>{textLabel}</p>
          <p className='info-text'>{localizeString('or')}</p>
          <div
            className='file-upload-button'
            data-testid='file-upload-button'
            onClick={handleClickButton}
          >
            <i><FaArrowAltCircleUp /></i>
            {buttonLabel}
          </div>
        </div>
      </section>
    </div>
  );
}

FileUpload.propTypes = {
  files: PropTypes.array,
  maxFilesAtOnce: PropTypes.number,
  onUpload: PropTypes.func.isRequired,
  textLabel: PropTypes.string,
  buttonLabel: PropTypes.string
};

FileUpload.defaultProps = {
  files: [],
  maxFilesAtOnce: 9000,
  textLabel: localizeString('drag_drop_file'),
  buttonLabel: localizeString('browse_files')
};

export default FileUpload;