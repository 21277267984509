import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from '../../DropDown/DropDownList';

const BasicDropdown = (props) => {
  const { value, onSelect, items, placeholder, error, size, dropClass, disabled } = props;
  return (
    <DropDownList
      onSelect={onSelect}
      dropClass={`basic-dropdown ${error && 'has-error'} ${dropClass}`}
      value={value}
      items={items}
      placeholder={placeholder}
      size={size}
      disabled={disabled}
    />
  );
}

BasicDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  items: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  size: PropTypes.string,
  dropClass: PropTypes.string,
  disabled: PropTypes.bool,
};

BasicDropdown.defaultProps = {
  value: '',
  placeholder: 'Select an Item',
  error: false,
  size: undefined,
  dropClass: '',
  disabled: false
}

export default BasicDropdown;
