/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore } from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';
import _findIndex from 'lodash.findindex';

export const READ_WRITE = 'READ_WRITE';
export const READ_ONLY = 'READ_ONLY';

const initialState = {};
const reducers = {
  toastr: toastrReducer,

  environment: (state = null, action) => {
    if (action.type === 'set-environment') {
      return action.payload;
    }
    return state;
  },

  portalType: (state = null, action) => {
    if (action.type === 'set-portal-type') {
      return action.payload;
    }
    return state;
  },

  apiCluster: (state = null, action) => {
    if (action.type === 'set-api-cluster') {
      return action.payload;
    }
    return state;
  },

  roleLabel: (state = null, action) => {
    if (action.type === 'set-role') {
      return action.role;
    }
    return state;
  },

  userPrivileges: (state = null, action) => {
    if (action.type === 'set-privileges') {
      return action.payload;
    }
    return state;
  },

  managementPlatform: (state = null, action) => {
    if (action.type === 'set-manager') {
      return action.payload;
    }
    return state;
  },

  services: (state = null, action) => {
    if (action.type === 'set-services') {
      return action.payload;
    }
    return state;
  },

  edmDatasets: (state = null, action) => {
    if (action.type === 'set-edm-datasets') {
      return action.payload;
    }
    return state;
  },

  dataPatterns: (state = null, action) => {
    let index;
    let arrayCopy;

    switch(action.type) {
      case 'set-data-patterns':
        return action.payload;

      case 'add-data-pattern':
        return [...state, action.payload];

      case 'update-data-pattern':
        index = _findIndex(state, {id: action.payload.id});
        arrayCopy = [...state];
        arrayCopy.splice(index, 1, action.payload);
        return arrayCopy;

      case 'delete-data-pattern':
        index = _findIndex(state, {id: action.payload.id});
        arrayCopy = [...state];
        arrayCopy.splice(index, 1);
        return arrayCopy;

      default:
        return state;
    }
  },

  emailFlag: (state = null, action) => {
    if (action.type === 'set-email-enabled-flag') {
      return action.flag;
    }
    return state;
  },

  fingerprintFlag: (state = null, action) => {
    if (action.type === 'set-fingerprinting-enabled-flag') {
      return action.flag;
    }
    return state;
  },

  caJpFlag: (state = null, action) => {
    if (action.type === 'set-caJpRegions-enabled-flag') {
      return action.flag;
    }
    return state;
  },

  userInfo: (state = null, action) => {
    if (action.type === 'set-user-info') {
      return action.payload;
    }

    return state;
  },

  panosVersion: (state = null, action) => {
    if (action.type === 'set-panos-version') {
      return action.payload;
    }

    return state;
  }
};

const store = createStore(
  combineReducers(reducers),
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store;