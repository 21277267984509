import React from 'react';
import { IntlProvider } from 'react-intl';
import '@testing-library/jest-dom/extend-expect';
import { act } from 'react-dom/test-utils';
import { mount } from 'enzyme';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import sinon from 'sinon';
import { expect as chaiExpect } from 'chai';

import {
  Button,
  LoadingButton
} from '@panwds/react-ui';

import FingerprintDrawer from './FingerprintDrawer';
import NewFingerprintForm from './components/NewFingerprintForm';
import EditFingerprintForm from './components/EditFingerprintForm';

import {
  NEW,
  EDIT,
  FILE_SIZE_FINGERPRINT
} from '../constants';

const axiosMock = new MockAdapter(axios);

describe('<FingerprintDrawer />', () => {
  let sandbox, wrapper;
  const baseProps = {
    apiEndpoint: '/apiEndpoint'
  };
  const mockView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return mount(
      <IntlProvider locale="en">
        <FingerprintDrawer {...props} />
      </IntlProvider>
    );
  };

  beforeEach(() => {
    axiosMock.reset();
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    sandbox.restore();
    jest.resetAllMocks();
  });

  it('should match snapshot when closed', () => {
    wrapper = mockView();

    expect(wrapper).toMatchSnapshot();
  });

  it('should render when open NEW', async () => {
    axiosMock
      .onPost()
      .networkErrorOnce()
      .onPost()
      .replyOnce(config => {
        chaiExpect(config.url).to.equal('/apiEndpoint');

        return [201, {}]
      })
      .onPost()
      .replyOnce(config => {
        chaiExpect(config.url).to.equal('/apiEndpoint');

        return [200, {}]
      });

    wrapper = mockView({
      isOpen: true,
      savedFingerprintsArr: [{ name: 'duplicate name' }]
    });

    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find(LoadingButton).prop('disabled')).toBeTruthy();

    wrapper.find(NewFingerprintForm).invoke('onInputChange')({
      target: {
        name: 'name',
        value: 'duplicate name'
      }
    });

    wrapper.find(NewFingerprintForm).invoke('onInputChange')({
      target: {
        name: 'name',
        value: 'name'
      }
    });

    wrapper.find(NewFingerprintForm).invoke('onInputChange')({
      target: {
        name: 'category',
        value: 'category'
      }
    });

    wrapper.find(NewFingerprintForm).invoke('onInputChange')({
      target: {
        name: 'description',
        value: 'description'
      }
    });

    wrapper.find(NewFingerprintForm).invoke('onFilesUpload')([
      {
        name: 'file 0',
        size: 1
      }, {
        name: 'file 1',
        size: 1
      }
    ]);

    expect(wrapper.find(LoadingButton).prop('disabled')).toBeTruthy();

    wrapper.find(NewFingerprintForm).invoke('onFilesUpload')([
      {
        name: 'file 0',
        size: 1
      }
    ]);

    expect(wrapper.find(LoadingButton).prop('disabled')).toBeFalsy();

    wrapper.find(NewFingerprintForm).invoke('onFileDelete')(0);

    wrapper.find(NewFingerprintForm).invoke('onFilesUpload')([
      {
        name: 'file 0',
        size: FILE_SIZE_FINGERPRINT + 1
      }
    ]);

    expect(wrapper.find(LoadingButton).prop('disabled')).toBeTruthy();

    wrapper.find(NewFingerprintForm).invoke('onFilesUpload')([
      {
        name: 'file 0',
        size: 1
      }
    ]);

    await act(async () => {
      wrapper.find(LoadingButton).invoke('onClickCallback')();
    });

    await act(async () => {
      wrapper.find(LoadingButton).invoke('onClickCallback')();
    });

    await act(async () => {
      wrapper.find(LoadingButton).invoke('onClickCallback')();
    });
  });

  it('should render when open EDIT', async () => {
    axiosMock
      .onPut()
      .networkErrorOnce()
      .onPut()
      .replyOnce(config => {
        chaiExpect(config.url).to.equal('/apiEndpoint/666');

        return [201, {}]
      })
      .onPut()
      .replyOnce(config => {
        chaiExpect(config.url).to.equal('/apiEndpoint/666');

        return [200, {}]
      });

    wrapper = mockView({
      isOpen: true,
      mode: EDIT,
      id: 666,
      category: 'category',
      description: 'description',
      error: undefined,
      fileObj: {
        name: 'saved file name',
        size: 666
      },
      savedFingerprintsArr: [{ name: 'duplicate name' }, { name: 'saved file name' }]
    });

    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find(LoadingButton).prop('disabled')).toBeTruthy();

    wrapper.find(EditFingerprintForm).invoke('onInputChange')({
      target: {
        name: 'category',
        value: 'new category'
      }
    });

    wrapper.find(EditFingerprintForm).invoke('onInputChange')({
      target: {
        name: 'description',
        value: 'new description'
      }
    });

    expect(wrapper.find(LoadingButton).prop('disabled')).toBeFalsy();

    await act(async () => {
      wrapper.find(LoadingButton).invoke('onClickCallback')();
    });

    await act(async () => {
      wrapper.find(LoadingButton).invoke('onClickCallback')();
    });

    await act(async () => {
      wrapper.find(LoadingButton).invoke('onClickCallback')();
    });
  });

  it('should render when open EDIT and does delete', async () => {
    const onDeleteStub = sinon.stub();

    wrapper = mockView({
      isOpen: true,
      mode: EDIT,
      id: 666,
      name: 'name',
      category: 'category',
      description: 'description',
      error: 'error',
      fileObj: {
        name: 'saved file name',
        size: 666
      },
      onDelete: onDeleteStub,
      savedFingerprintsArr: [{ name: 'duplicate name' }, { name: 'saved file name' }]
    });

    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find(LoadingButton).prop('disabled')).toBeTruthy();
    expect(wrapper.find(EditFingerprintForm).prop('hasError')).toBeTruthy();

    await act(async () => {
      wrapper.find(Button).at(0).simulate('click');
    });

    chaiExpect(onDeleteStub.calledWith({
      id: 666,
      name: 'name',
      category: 'category',
      description: 'description'
    })).to.be.true;
  });
});