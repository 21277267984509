import React, { useRef } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

import {
  Navigation,
  TopNav,
  BottomNav,
  NavItem,
  SubNavList,
  SubNavListItem
} from '@panwds/react-nav';
import {
  ThAppSwitcherIcon,
  ProfileNavigationIcon,
  ReportsIcon,
  SettingsIcon,
  FileCertificateIcon
} from '@panwds/icons';

import {
  FaClipboardList,
  FaHeartbeat
} from 'react-icons/fa';

import AppLogoImg from '../../../assets/images/DLP-Logo-Collapsed.png';
import AppNameImg from '../../../assets/images/DLP-Logo-Expanded.png';
import IncidentsIcon from '../Icons/IncidentsIcon';
import DetectionMethodsIcon from "../Icons/DetectionMethodsIcon";
import AuditLogsIcon from "../Icons/AuditLogsIcon";

import translate from '../../helpers/translate';

import './styles.scss';

const Nav = ({ menu, currentUser, hub }) => {
  const navigationRef = useRef(null);
  const refreshRailsContent = () => {
    if (sessionStorage.getItem('prevUrl') !== window.location.pathname) {
      sessionStorage.setItem('prevUrl', window.location.pathname);
      window.location.assign(window.location.pathname);
    }

    return null;
  };
  const showDetectionMethods = () => (
    menu && menu.detection_methods && (
      (menu.detection_methods.data_pattern && menu.detection_methods.data_pattern.accessible) ||
      (menu.detection_methods.edm && menu.detection_methods.edm.accessible) ||
      (menu.detection_methods.ocr && menu.detection_methods.ocr.accessible)
    )
  );
  const redirectOnClick = (url) => {
    window.location.assign(url);
  }

  return (
    <BrowserRouter>
      <IntlProvider locale='en'>
        <div className='dlp-nav-wrapper'>
          <Navigation
            logo={AppLogoImg}
            logoUrl='/'
            fullName={AppNameImg}
            isTwoLines
            hasCollapseController
            clickToExpand
            navRef={navigationRef}
          >
            <TopNav>
              { menu && menu.incidents && menu.incidents.accessible &&
                <NavItem
                  isSimpleNav
                  icon={<IncidentsIcon size='sm' />}
                  title={translate('left_nav.incidents')}
                  id='nav-incidents'
                  url='/incidents'
                />
              }

              { menu && menu.profile && menu.profile.accessible &&
                <NavItem
                  isSimpleNav
                  icon={<FaClipboardList />}
                  title={translate('left_nav.data_profiles')}
                  id='nav-data-profiles'
                  url='/data_profiles'
                />
              }

              { showDetectionMethods() &&
                <SubNavList
                  isSimpleNav
                  defaultOpen={window.location.pathname.includes('/detection_methods/')}
                  icon={<DetectionMethodsIcon />}
                  title={translate('left_nav.detection_methods')}
                  id='nav-detection-methods'
                  url='/detection_methods'
                >
                  { menu.detection_methods.data_pattern && menu.detection_methods.data_pattern.accessible &&
                    <SubNavListItem
                      isSimpleNav
                      dataTestId='DLP-nav-data_patterns'
                      title={translate('left_nav.data_patterns')}
                      url='/detection_methods/data_patterns'
                    />
                  }

                  { menu.detection_methods.edm && menu.detection_methods.edm.accessible &&
                    <SubNavListItem
                      isSimpleNav
                      dataTestId='DLP-nav-edm_datasets'
                      title={translate('left_nav.edm_datasets')}
                      url='/detection_methods/edm_datasets'
                    />
                  }

                  { menu.detection_methods.ocr && menu.detection_methods.ocr.accessible &&
                    <SubNavListItem
                      isSimpleNav
                      dataTestId='DLP-nav-ocr'
                      title={translate('left_nav.ocr')}
                      url='/detection_methods/ocr'
                    />
                  }

                </SubNavList>
              }

              { menu && menu.document_types && menu.document_types.accessible &&
                <NavItem
                  isSimpleNav
                  icon={<FileCertificateIcon size='sm' />}
                  title={translate('left_nav.document_types')}
                  id='nav-document-types'
                  url='/document_types'
                />
              }

              { menu && menu.telemetry && menu.telemetry.accessible &&
                <NavItem
                  isSimpleNav
                  icon={<FaHeartbeat />}
                  title={translate('left_nav.dashboards')}
                  id='nav-dashboards'
                  url='/dashboards'
                />
              }

              { menu && menu.reports && menu.reports.accessible &&
                <NavItem
                  isSimpleNav
                  icon={<ReportsIcon size='sm' />}
                  title={translate('left_nav.reports')}
                  id='nav-reports'
                  url='/reports'
                />
              }

              { menu && menu.audit_log && menu.audit_log.accessible &&
                <NavItem
                  isSimpleNav
                  icon={<AuditLogsIcon size='sm' />}
                  title={translate('left_nav.audit_logs')}
                  id='nav-audit_logs'
                  url='/audit_logs'
                />
              }

              { menu && menu.settings && menu.settings.accessible &&
                <NavItem
                  isSimpleNav
                  icon={<SettingsIcon size='sm' />}
                  title={translate('left_nav.settings')}
                  id='nav-settings'
                  url='/settings'
                />
              }
            </TopNav>

            <BottomNav>
              { currentUser && currentUser.tenantId &&
                <div className='nav-tenant-id-wrapper'>
                  <div className='nav-tenant-id'>
                    Tenant ID: {currentUser.tenantId}
                  </div>
                </div>
              }

              { currentUser && currentUser.name &&
                <div className='nav-sign-out-wrapper'>
                  <div className='custom-sign-out' onClick={() => redirectOnClick(currentUser.url)}>
                    <ProfileNavigationIcon size='sm' />
                    <div className='custom-sign-out-name'>{currentUser.name}</div>
                    <div className='custom-sign-out-pipe'>|</div>
                    <div className='custom-sign-out-label'>{translate('left_nav.sign_out')}</div>
                  </div>

                  <NavItem
                    displayActiveBorder
                    icon={<ProfileNavigationIcon size='sm' />}
                    title={translate('left_nav.sign_out')}
                    id='nav-sign_out'
                    url={currentUser.url}
                  />
                </div>
              }

              { hub &&
                <div onClick={() => redirectOnClick(hub.url)} >
                  <NavItem
                    icon={<ThAppSwitcherIcon size="sm" />}
                    title={translate('left_nav.hub')}
                    id="nav-hub"
                  />
                </div>
              }
            </BottomNav>
          </Navigation>

          <div className='dlp-nav-routing'>
            <Switch>
              <Route
                exact path='/'
                render={refreshRailsContent}
              />
              { menu && menu.incidents && menu.incidents.accessible &&
                <Route
                  exact path='/incidents'
                  render={refreshRailsContent}
                />
              }
              { menu && menu.profile && menu.profile.accessible &&
                <Route
                  exact path='/data_profiles'
                  render={refreshRailsContent}
                />
              }
              { showDetectionMethods() && menu.detection_methods.data_pattern && menu.detection_methods.data_pattern.accessible &&
                <Route
                  exact path='/detection_methods/data_patterns'
                  render={refreshRailsContent}
                />
              }
              { showDetectionMethods() && menu.detection_methods.edm && menu.detection_methods.edm.accessible &&
                <Route
                  exact path='/detection_methods/edm_datasets'
                  render={refreshRailsContent}
                />
              }
              { showDetectionMethods() && menu.detection_methods.ocr && menu.detection_methods.ocr.accessible &&
                <Route
                  exact path='/detection_methods/ocr'
                  render={refreshRailsContent}
                />
              }
              { menu && menu.document_types && menu.document_types.accessible &&
                <Route
                  exact path='/document_types'
                  render={refreshRailsContent}
                />
              }
              { menu && menu.telemetry && menu.telemetry.accessible &&
                <Route
                  exact path='/dashboards'
                  render={refreshRailsContent}
                />
              }
              { menu && menu.reports && menu.reports.accessible &&
                <Route
                  exact path='/reports'
                  render={refreshRailsContent}
                />
              }
              { menu && menu.audit_log && menu.audit_log.accessible &&
                <Route
                  exact path='/audit_logs'
                  render={refreshRailsContent}
                />
              }
              { menu && menu.settings && menu.settings.accessible &&
                <Route
                  exact path='/settings'
                  render={refreshRailsContent}
                />
              }
              <Route
                exact path={currentUser.url}
                render={refreshRailsContent}
              />
            </Switch>
          </div>
        </div>
      </IntlProvider>
    </BrowserRouter>
  );
}

Nav.propTypes = {
  menu: PropTypes.shape({
    incidents: PropTypes.shape({
      accessible: PropTypes.bool.isRequired,
    }),
    profile: PropTypes.shape({
      accessible: PropTypes.bool.isRequired
    }),
    detection_methods: PropTypes.shape({
      data_pattern: PropTypes.shape({
        accessible: PropTypes.bool.isRequired
      }),
      edm: PropTypes.shape({
        accessible: PropTypes.bool.isRequired
      }),
      ocr: PropTypes.shape({
        accessible: PropTypes.bool.isRequired
      })
    }),
    document_types: PropTypes.shape({
      accessible: PropTypes.bool.isRequired
    }),
    telemetry: PropTypes.shape({
      accessible: PropTypes.bool
    }),
    reports: PropTypes.shape({
      accessible: PropTypes.bool.isRequired
    }),
    audit_log: PropTypes.shape({
      accessible: PropTypes.bool.isRequired
    }),
    settings: PropTypes.shape({
      accessible: PropTypes.bool.isRequired
    })
  }).isRequired,
  currentUser: PropTypes.shape({
    name: PropTypes.string,
    tenantId: PropTypes.string,
    url: PropTypes.string.isRequired
  }),
  hub: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
};

Nav.defaultProps = {
  currentUser: {
    name: undefined,
    tenantId: undefined,
    url: '/d/users/sign_out'
  },
  hub: {
    url: 'https://apps-qa.qa.appsvc.paloaltonetworks.com/hub'
  }
}

export default Nav;