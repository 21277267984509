import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button,
  LoadingButton
  } from '@panwds/react-ui';

import Modal from './components/Modal/index';
import translate from '../../helpers/translate';

import './components/modal.scss';

function localizeString(scope, options) {
  return translate(`document_types.delete_modal.${scope}`, options);
}

const DeleteModal = ({
  apiEndpoint,
  apiPostfix,
  id,
  isOpen,
  name,
  onClose
}) => {
  const [ deleteButtonStatus, setDeleteButtonStatus ] = useState({ loading: false });
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(false);
  const [ errorMssg, setErrorMssg ] = useState('');

  const handleDelete = () => {
    setDeleteButtonStatus({ loading: true });

    axios.delete(`${apiEndpoint}/${id}${apiPostfix}`)
      .then(response => {
        if (response.status === 200) {
          onClose();
        }
      })
      .catch(({ response }) => {
        setDeleteButtonStatus({ loading: false });
        setIsButtonDisabled(true);
        setErrorMssg(response?.data?.message || localizeString('server_error'));
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={localizeString('delete_confirmation')}
      addClassName='delete-fingerprint-modal'
    >
      <section className='modal-body'>
        <p>{localizeString('info_line_1')} <span className='special'>{name}</span>?</p>
        <p>{localizeString('info_line_2')}</p>
        <p className='error-message'>{errorMssg}</p>
      </section>

      <section className='modal-footer tw-flex tw-justify-end tw-space-x-1'>
        <Button addClassName='cancel' appearance='tertiary' onClick={onClose} >
          {localizeString('cancel')}
        </Button>
        <LoadingButton
          appearance='primary-destructive'
          dataMetrics='loading-primary-button'
          dataResult={deleteButtonStatus}
          onClickCallback={handleDelete}
          disabled={isButtonDisabled}
        >
          {localizeString('delete_fingerprint')}
        </LoadingButton>
      </section>
    </Modal>
  );
}

DeleteModal.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  apiPostfix: PropTypes.string,
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

DeleteModal.defaultProps = {
  apiPostfix: '',
  isOpen: false,
  onClose: () => {}
};

export default DeleteModal;