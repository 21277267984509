// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<ReportDetails> with data patterns renders report details with data pattern widget when present 1`] = `
<div
  className="detailsView"
>
  <ReportCard
    report={
      Object {
        "asset_id": "987654",
        "channel": "Prisma Access",
        "data_pattern_results": Array [
          Object {
            "data_pattern_id": "pattern1",
            "high_confidence_frequency": 0,
            "low_confidence_frequency": 5,
            "medium_confidence_frequency": 2,
            "name": "Pattern1",
          },
          Object {
            "data_pattern_id": "pattern2",
            "high_confidence_frequency": 2,
            "low_confidence_frequency": 5,
            "medium_confidence_frequency": 0,
            "name": "Pattern2",
          },
          Object {
            "data_pattern_id": "pattern3",
            "high_confidence_frequency": 0,
            "low_confidence_frequency": 4,
            "medium_confidence_frequency": 1,
            "name": "Pattern1",
          },
        ],
        "data_profile_name": "Bank Account Number North American",
        "detection_time": "2020-07-09T20:56:14.620Z",
        "file_name": "Test Asset",
        "file_size_in_bytes": 45986,
        "report_id": "12345",
      }
    }
    userRole="READ_WRITE"
  />
  <MatchingDataPatterns
    confidence="high_confidence_frequency"
    currentMatches={
      Object {
        "pattern2": Object {
          "data_pattern_id": "pattern2",
          "high_confidence_frequency": 2,
          "low_confidence_frequency": 5,
          "medium_confidence_frequency": 0,
          "name": "Pattern2",
        },
      }
    }
    highMatches={
      Object {
        "pattern2": Object {
          "data_pattern_id": "pattern2",
          "high_confidence_frequency": 2,
          "low_confidence_frequency": 5,
          "medium_confidence_frequency": 0,
          "name": "Pattern2",
        },
      }
    }
    lowMatches={
      Object {
        "pattern1": Object {
          "data_pattern_id": "pattern1",
          "high_confidence_frequency": 0,
          "low_confidence_frequency": 5,
          "medium_confidence_frequency": 2,
          "name": "Pattern1",
        },
        "pattern2": Object {
          "data_pattern_id": "pattern2",
          "high_confidence_frequency": 2,
          "low_confidence_frequency": 5,
          "medium_confidence_frequency": 0,
          "name": "Pattern2",
        },
        "pattern3": Object {
          "data_pattern_id": "pattern3",
          "high_confidence_frequency": 0,
          "low_confidence_frequency": 4,
          "medium_confidence_frequency": 1,
          "name": "Pattern1",
        },
      }
    }
    medMatches={
      Object {
        "pattern1": Object {
          "data_pattern_id": "pattern1",
          "high_confidence_frequency": 0,
          "low_confidence_frequency": 5,
          "medium_confidence_frequency": 2,
          "name": "Pattern1",
        },
        "pattern3": Object {
          "data_pattern_id": "pattern3",
          "high_confidence_frequency": 0,
          "low_confidence_frequency": 4,
          "medium_confidence_frequency": 1,
          "name": "Pattern1",
        },
      }
    }
    report_id="12345"
  />
</div>
`;

exports[`<ReportDetails> without data patterns does not render data pattern widget without data pattern results 1`] = `
<div
  className="detailsView"
>
  <ReportCard
    report={
      Object {
        "asset_id": "987654",
        "channel": "Prisma Access",
        "data_pattern_results": Array [],
        "data_profile_name": "Bank Account Number North American",
        "detection_time": "2020-07-09T20:56:14.620Z",
        "file_name": "Test Asset",
        "file_size_in_bytes": 45986,
        "report_id": "12345",
      }
    }
    userRole="READ_WRITE"
  />
</div>
`;
