import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

import IdentifierDropdown from './IdentifierDropdown';
import BasicDropdown from './BasicDropdown';
import { occurrenceDocumentTypes, occurrenceNumbers } from './occurrences';

import translate from '../../../helpers/translate';

function localizeString(scope, options) {
  return translate(`data_profiles.form.document_type.${scope}`, options);
}

const RuleItemDocumentType = ({
  documentsList,
  onDeleteRuleItem,
  onUpdateRuleItem,
  position,
  ruleItem,
  shouldValidatePresence
}) => {
  const handleDeleteRuleItem = () => {
    onDeleteRuleItem(position)
  };

  const handleInputChange = (partialItem) => {
    const partialOccurrenceObj = {
      occurrence_count: 50,
      occurrence_low: 50,
      occurrence_high: 100
    };
    const updatedRuleItem = {
      ...partialOccurrenceObj,
      ...ruleItem,
      ...partialItem
    };

    switch (updatedRuleItem.occurrence_operator_type) {
      case 'more_than_equal_to': {
        delete updatedRuleItem.occurrence_low;
        delete updatedRuleItem.occurrence_high;
        break;
      }

      case 'between':
      default: {
        delete updatedRuleItem.occurrence_count;
        break;
      }
    }

    delete updatedRuleItem.label;
    delete updatedRuleItem.value;

    onUpdateRuleItem(position, updatedRuleItem);
  };

  return (
    <>
      <div className='tw-float-right'>
        <FaTimes onClick={handleDeleteRuleItem} className='muteText removeBtn' />
      </div>
      <table className={`occurrenceFields-${position}`}>
        <tbody>
          <tr>
            <td>
              <IdentifierDropdown
                error={shouldValidatePresence && !ruleItem.id}
                items={documentsList}
                onSelect={handleInputChange}
                placeholder={localizeString('identifier_placeholder')}
                value={ruleItem.id ? { id: ruleItem.id, name: ruleItem.name } : {}}
              />
            </td>

            <td>
              <span className='rule-item-text'>{localizeString('matching')}</span>
            </td>

            <td>
              <BasicDropdown
                dropClass='occurrence'
                items={occurrenceDocumentTypes}
                onSelect={newOccurrence => handleInputChange({
                  occurrence_operator_type: newOccurrence
                })}
                placeholder={localizeString('occurrences_placeholder')}
                value={ruleItem.occurrence_operator_type}
              />
            </td>

            { ruleItem.occurrence_operator_type === 'more_than_equal_to' &&
              <td>
                <BasicDropdown
                  dropClass='occurrence-number'
                  items={occurrenceNumbers}
                  onSelect={num => handleInputChange({
                    occurrence_count: num
                  })}
                  value={ruleItem.occurrence_count}
                />
              </td>
            }

            { ruleItem.occurrence_operator_type === 'between' &&
              <>
                <td>
                  <BasicDropdown
                    dropClass='occurrence-number-1'
                    items={occurrenceNumbers}
                    onSelect={num => handleInputChange({
                      occurrence_low: num,
                      occurrence_high: num > ruleItem.occurrence_high ?
                        num : ruleItem.occurrence_high
                    })}
                    value={ruleItem.occurrence_low}
                  />
                </td>

                <td>
                  <span className='rule-item-text'>{localizeString('and')}</span>
                </td>

                <td>
                  <BasicDropdown
                    dropClass='occurrence-number-2'
                    items={occurrenceNumbers}
                    onSelect={num => handleInputChange({
                      occurrence_high: num,
                      occurrence_low: ruleItem.occurrence_low > num ?
                        num : ruleItem.occurrence_low
                    })}
                    value={ruleItem.occurrence_high}
                  />
                </td>
              </>
            }
          </tr>
        </tbody>
      </table>
    </>
  );
}

RuleItemDocumentType.propTypes = {
  documentsList: PropTypes.array,
  onDeleteRuleItem: PropTypes.func,
  onUpdateRuleItem: PropTypes.func,
  position: PropTypes.string,
  ruleItem: PropTypes.object,
  shouldValidatePresence: PropTypes.bool
};

RuleItemDocumentType.defaultProps = {
  documentsList: [],
  onDeleteRuleItem: undefined,
  onUpdateRuleItem: undefined,
  position: '0',
  ruleItem: {},
  shouldValidatePresence: false
}

export default RuleItemDocumentType;