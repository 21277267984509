/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import FileBasedView from './viewFile';
import NonFileBasedView from './viewNonFile';
import DlpView from './viewDLP';

import { ALLOW } from '../../constants';

import './styles.scss';

const DataTransfer = ({ userRole, nebulaFlag, panosVersion }) => {
  const [loading, setLoading] = useState(true);
  const [settingsFileBased, setSettingsFileBased] = useState({
    maxLatency: 0,
    actionMaxLatency: '',
    maxSize: 0,
    actionMaxSize: '',
    allowLogFiles: false
  });
  const [settingsNonFileBased, setSettingsNonFileBased] = useState({
    enableNonFile: false,
    maxLatency: 0,
    actionMaxLatencyNonFile: '',
    minSize: 0,
    maxSize: 0,
    actionMaxSizeNonFile: '',
    allowLogData: false
  });
  const [settingsDLP, setSettingsDLP] = useState({
    actionOnError: ''
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    return axios(
      '/v1/dlp-ui/data-filtering-profile/setting'
    ).then( result => {
      const {
        file_max_latency=240,
        file_action_on_max_latency=ALLOW,
        file_max_size=100,
        file_action_on_max_size=ALLOW,
        file_log_files_not_scanned=true,
        nonfile_enabled=true,
        nonfile_max_latency=15,
        nonfile_action_on_max_latency=ALLOW,
        nonfile_min_size=250,
        nonfile_max_size=100,
        nonfile_action_on_max_size=ALLOW,
        nonfile_log_files_not_scanned=true,
        action_on_error=ALLOW
      } = result.data;

      setSettingsFileBased({
        maxLatency: file_max_latency,
        actionMaxLatency: file_action_on_max_latency,
        maxSize: file_max_size,
        actionMaxSize: file_action_on_max_size,
        allowLogFiles: file_log_files_not_scanned
      });

      setSettingsNonFileBased({
        enableNonFile: nonfile_enabled,
        maxLatency: nonfile_max_latency,
        actionMaxLatencyNonFile: nonfile_action_on_max_latency,
        minSize: nonfile_min_size,
        maxSize: nonfile_max_size,
        actionMaxSizeNonFile: nonfile_action_on_max_size,
        allowLogData: nonfile_log_files_not_scanned
      });

      setSettingsDLP({
        actionOnError: action_on_error
      });

      setLoading(false);
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className='data-transfer' data-testid='testRoot'>
      <FileBasedView
        loading={loading}
        maxLatency={settingsFileBased.maxLatency}
        actionMaxLatency={settingsFileBased.actionMaxLatency}
        maxSize={settingsFileBased.maxSize}
        actionMaxSize={settingsFileBased.actionMaxSize}
        allowLogFiles={settingsFileBased.allowLogFiles}
        updateSettings={setSettingsFileBased}
        userRole={userRole}
      />
      { nebulaFlag &&
        <NonFileBasedView
          loading={loading}
          enableNonFile={settingsNonFileBased.enableNonFile}
          maxLatency={settingsNonFileBased.maxLatency}
          actionMaxLatencyNonFile={settingsNonFileBased.actionMaxLatencyNonFile}
          minSize={settingsNonFileBased.minSize}
          maxSize={settingsNonFileBased.maxSize}
          actionMaxSizeNonFile={settingsNonFileBased.actionMaxSizeNonFile}
          allowLogData={settingsNonFileBased.allowLogData}
          updateSettings={setSettingsNonFileBased}
          userRole={userRole}
        />
      }
      <DlpView
        loading={loading}
        actionOnError={settingsDLP.actionOnError}
        updateSettings={setSettingsDLP}
        userRole={userRole}
      />
    </div>
  );
}

DataTransfer.propTypes = {
  userRole: PropTypes.string.isRequired,
  nebulaFlag: PropTypes.bool,
  panosVersion: PropTypes.string
}

DataTransfer.defaultProps = {
  nebulaFlag: false,
  panosVersion: undefined
}

export default DataTransfer;