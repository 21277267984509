import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@panwds/react-ui';

import translate from '../../helpers/translate';

class Modal extends React.Component {
  closeModal = () => {
    if (this.props.resetState) {
      this.props.resetState();
    }
  }

  render() {
    let linkElement = null;
    if (this.props.linkElement) {
      linkElement = (
        <a href={`#${this.props.compId}`} data-toggle="modal">
          {this.props.linkElement(this.props)}
        </a>
      );
    }
    return (
      <div className="modalComponent">
        {linkElement}
        <div
          id={this.props.compId}
          className="modal fade"
          role="dialog"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
          data-focus="true"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <div className='modal-content'>
              <div className="modal-header">
                <Button data-dismiss="modal" appearance='tertiary-clear' addClassName='close' onClick={this.closeModal} />
                <h4 className="modal-title">{this.props.modalHeader}</h4>
              </div>
              <div className="modal-body">{this.props.modalBody}</div>
              <div className="modal-footer tw-flex justify-end tw-space-x-1">
                { this.props.showCancel ?
                  <Button data-dismiss="modal" onClick={this.closeModal}>
                    {this.props.cancelText}
                  </Button>
                  : ''
                }
                {this.props.actionElement}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  compId: PropTypes.string,
  modalHeader: PropTypes.string,
  modalBody: PropTypes.object,
  linkElement: PropTypes.func,
  actionElement: PropTypes.object,
  cancelText: PropTypes.string,
  resetState: PropTypes.func,
  showCancel: PropTypes.bool,
};

Modal.defaultProps = {
  compId: 'dvModal',
  modalHeader: '',
  modalBody: <p/>,
  linkElement: undefined,
  actionElement: undefined,
  cancelText: translate('actions.cancel'),
  resetState: undefined,
  showCancel: true,
};

export default Modal;
