/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton } from '@panwds/react-ui';
import { importExposed, getState } from 'sparky-framework';
import _get from 'lodash.get';
import axios from 'axios';
import { saveAs } from 'file-saver';

import translate from '../../helpers/translate';
import FileUpload from './FileUpload';

const fawkesResponsePagePromise = importExposed('management-app', 'responsePage');

function t(scope, options) {
  return translate(`settings.block_response.${scope}`, options);
}

class BlockResponse extends React.Component {
  state = {
    selectedFile: null,
    location: 'predefined',
    modalOpen: false
  };

  apiResponse;

  getRequest;

  addPageConfigFunc;

  fileData;

  configLocation;

  componentDidMount() {
    const globalState = getState();
    fawkesResponsePagePromise.then((fawkesResponseFunctions) => {
      this.configLocation = _get(globalState, 'main.configLocation');
      this.getRequest = fawkesResponseFunctions.responsePageObservable({location: this.configLocation, method: 'GET', extraParams: { blockPage: 'data-filter-block-page'}});
      this.addPageConfigFunc = fawkesResponseFunctions.addResponsePageObservable
      this.requestData(this.getRequest);
    });
  }

  requestData = (requestConfig) => {
    axios(requestConfig).then((response) => {
      this.apiResponse = response.data;
      const pageInfo = _get(this.apiResponse, 'result.result.data-filter-block-page.info');
      // If the API response doesn't have location info, treat location as 'prisma access'
      const loc = _get(this.apiResponse, 'result.result.data-filter-block-page.@loc', 'prisma access');
      this.fileData = pageInfo;
      this.setState({location: loc});
    });
  }

  downloadFile = () => {
    const fileContent = atob(this.fileData);
    const blob = new Blob([fileContent], {type: "text/html"});
    saveAs(blob, "data-filter-block-page.html");
  }

  openModal = () => {
    this.setState({
      modalOpen: true
    });
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  }

  fileSelected = (file) => {
    this.setState({selectedFile: file});
  }

  handleSave = () => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64result = reader.result.split(',')[1];
      this.fileData = base64result;
      const postRequestConfig = this.addPageConfigFunc({record: {fileContent: base64result, blockPage: 'data-filter-block-page'}, location: this.configLocation});

      postRequestConfig.data = postRequestConfig.body;
      delete postRequestConfig.body;

      axios(postRequestConfig).then(() => {
        this.closeModal();
        this.setState({location: 'prisma access'});
      }).catch((error) => {
        console.error(error);
      });

    };
    reader.readAsDataURL(this.state.selectedFile);
  }

  revert = async () => {
    const delteRequest = {
      ...this.getRequest,
      method: 'DELETE'
    };
    await axios(delteRequest);
    this.requestData(this.getRequest);
  }

  render () {
    const {userRole} = this.props;
    const {location, modalOpen} = this.state;
    return (
      <div className="block-response-page-root">
        <div className="header-section">
          <h5>{t('title')}</h5>
          <div className="helpText">{t('description')}</div>
        </div>

        <div className="table-wrapper">
          <table className="table block-response">
            <thead>
                <tr>
                    <th>{t('response_page')}</th>
                    <th>{t('location')}</th>
                    <th>{translate('bulk_table.actions')}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='edit-page'>
                      { userRole === 'READ_WRITE' &&
                        <a className='btn-link' onClick={this.openModal}>{t('data_filtering_block_page')}</a>
                      }
                      {
                        userRole === 'READ_ONLY' &&
                        <>{t('data_filtering_block_page')}</>
                      }
                    </td>
                    <td className='location-cell'>{location}</td>
                    <td>
                      { userRole === 'READ_WRITE' &&
                        <a className='btn-link' onClick={this.downloadFile}>{t('export_html')}</a>
                      }
                      { userRole === 'READ_ONLY' &&
                        <span>{t('export_html')}</span>
                      }

                      {
                        location !== 'predefined' && this.props.userRole === 'READ_WRITE' &&
                        <div>
                          <a className='btn-link' onClick={this.revert}>{t('revert_to_inhertied_template')}</a>
                        </div>
                      }
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <Modal size='md' style={{width:'520px', fontSize: '12px'}} confirmClose={false} onClose={this.closeModal} isOpen={modalOpen}>
          <ModalHeader title={t('edit_page')} enableClose />
          <ModalBody addClassName="modal-dialog tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
            <div className='modal-content modal-body'>
              <FileUpload fileSelected={this.fileSelected} />
            </div>
          </ModalBody>
          <ModalFooter>
            <span className="tw-flex tw-justify-end tw-space-x-1">
              <ModalCloseButton>{translate('actions.cancel')}</ModalCloseButton>
              <Button addClassName='save' appearance='primary' onClick={ this.handleSave }>
                {t('save')}
              </Button>
            </span>

          </ModalFooter>
        </Modal>
      </div>
    );
  }
};

BlockResponse.propTypes = {
  userRole: PropTypes.string
};

BlockResponse.defaultProps = {
  userRole: 'READ_WRITE'
};

export default BlockResponse;