import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import CloseIcon from '../../../Icons/CloseIcon';

import './styles.scss';

const SidePanel = ({
  addClassName,
  children,
  isOpen,
  onClose,
  showMask,
  title
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <main className='dlp-side-panel'>
      { showMask &&
        <section className='panel-backdrop'
          onClick={onClose}
        />
      }

      <div className={ClassNames('panel-main', addClassName)}>
        <section className='panel-header'>
          <span className='panel-title'>{title}</span>
          <span className='close-icon' onClick={onClose}><CloseIcon size='xs' /></span>
        </section>

        {children}
      </div>
    </main>
  );
}

SidePanel.propTypes = {
  addClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showMask: PropTypes.bool,
  title: PropTypes.string.isRequired
};

SidePanel.defaultProps = {
  addClassName: '',
  isOpen: false,
  showMask: true
};

export default SidePanel;