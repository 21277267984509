jest.mock('../../i18n/i18n');

import transformIncidentData  from './incidentDataTransformer';

describe('incidentDataTransformer', () => {
  let incidents = {};
  let aggregation = {};
  let profiles = {};
  let datasets = {};
  let patterns = {};
  const region = 'us';

  it('sets up empty data for incidents table', () => {
    const formatted = transformIncidentData(incidents, aggregation, profiles, datasets, patterns, region);
    expect(Object.keys(formatted)).toEqual(['aggregationData', 'pageData', 'filters', 'incidents']);
    expect(formatted.pageData).toEqual({
      size: 0,
      number: 0,
      total_effective_elements: 0,
      total_pages: 0
    });
    expect(formatted.incidents).toEqual([]);
  });

  it('sets up transformed data for incidents table', () => {
    incidents = {
      status: 200,
      body: {
        size: 3,
        number: 0,
        totalElements: 50,
        totalPages: 5,
        sort: {
          unsorted: false,
          sorted: true,
          empty: false
        }
      },
      content: [
        { action: "alert", channel: "SaaS Security", checksum: "75bdc987a23abc8048923dfe834a6cb2", createdAt: "2021-06-07T21:45:18.000+00:00", scanDate: "2021-06-07T21:45:18.000+00:00", dataProfileId: "1234560", dataProfileVersion: 15, filename: "awesome_plastic_hat.zip", incidentDetails: "?", incidentId: "ca23ee113dbc183a66d44573127187a9", reportId: "1234560", source: "prisma-saas", tenantId: "1234567", match_info: {}, snippets: {}, report: null, data_profile: null},
        { action: "block", channel: "NGFW", checksum: "a653e698926ca983b89260002eb9549c", createdAt: "2021-06-07T21:45:18.000+00:00", scanDate: "2021-06-07T21:45:18.000+00:00", dataProfileId: "9999", dataProfileVersion: 15, filename: "fantastic_wooden_car.docx", incidentDetails: "?", incidentId: "a5bf0587e5d6df68f602cd82bf001812", reportId: "1234561", source: "10.64.218.44", tenantId: "1234567", match_info: {}, snippets: {}, report: null, data_profile: null},
        { action: "quarantine", channel: "prisma-access", checksum: "724bc84360668b4f684e14f5b57e5a96", createdAt: "2021-06-07T21:45:18.000+00:00", scanDate: "2021-06-07T21:45:18.000+00:00", dataProfileId: "9999", dataProfileVersion: 15, filename: "mediocre_wool_lamp.xlsx", incidentDetails: "?", incidentId: "d0d8b769f712bdfb9b3a0ee0b294f772", reportId: "1234569", source: "10.99.186.19", tenantId: "1234567", match_info: {}, snippets: {}, report: null, data_profile: null}
      ]
    };
    aggregation = {
      "actionAggregationList": [
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:45:18 UTC",
          "id": 0,
          "incidentCount": 19,
          "sensitiveFileCount": 40,
          "action": "alert",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:45:18 UTC",
          "id": 1,
          "incidentCount": 24,
          "sensitiveFileCount": 31,
          "action": "block",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:45:18 UTC",
          "id": 2,
          "incidentCount": 11,
          "sensitiveFileCount": 30,
          "action": "quarantine",
          "tenantId": "1234567"
        }
      ],
      "dataProfileAggregationList": [
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:35:18 UTC",
          "id": 0,
          "incidentCount": 9,
          "sensitiveFileCount": 39,
          "dataProfileId": "1234560",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:35:18 UTC",
          "id": 1,
          "incidentCount": 9,
          "sensitiveFileCount": 33,
          "dataProfileId": "1234561",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:35:18 UTC",
          "id": 2,
          "incidentCount": 24,
          "sensitiveFileCount": 26,
          "dataProfileId": "1234562",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:35:18 UTC",
          "id": 3,
          "incidentCount": 12,
          "sensitiveFileCount": 24,
          "dataProfileId": "1234563",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:35:18 UTC",
          "id": 4,
          "incidentCount": 22,
          "sensitiveFileCount": 20,
          "dataProfileId": "1234564",
          "tenantId": "1234567"
        }
      ],
      "sourceAggregationList": [
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:25:18 UTC",
          "id": 0,
          "incidentCount": 8,
          "sensitiveFileCount": 38,
          "source": "127.46.184.13",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:25:18 UTC",
          "id": 1,
          "incidentCount": 7,
          "sensitiveFileCount": 33,
          "source": "10.42.212.27",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:25:18 UTC",
          "id": 2,
          "incidentCount": 22,
          "sensitiveFileCount": 29,
          "source": "S3_bucket_name",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:25:18 UTC",
          "id": 3,
          "incidentCount": 20,
          "sensitiveFileCount": 22,
          "source": "Google Drive",
          "tenantId": "1234567"
        },
        {
          "aggregationUntilTimestamp": "2021-Jun-07 21:25:18 UTC",
          "id": 4,
          "incidentCount": 8,
          "sensitiveFileCount": 16,
          "source": "prisma-access",
          "tenantId": "1234567"
        }
      ],
      "lastAggregationTimestamp": "2021-Jul-01 21:25:18 UTC"
    };
    profiles = {
      '1234560': {
        "id": "1234560",
        "name": "EDM profile multi datasets 0",
        "tenant_id": "1234567",
        "version": 1,
        "created_at": "1970-01-01T00:00:00.001+00:00",
        "updated_at": "2021-10-05T00:00:00.000+00:00",
        "created_by": "Panorama",
        "updated_by": "prisma-saas",
        "type": "advanced",
        "thresholds": [],
        "data_patterns_rule_1": {},
        "data_patterns_rule_2": {},
        "advance_data_patterns_rules": [],
        "detection_rules": [
            {
                "rule_type": "expression_tree",
                "expression_tree": {
                    "operator_type": "or",
                    "rule_item": null,
                    "sub_expressions": [
                        {
                            "operator_type": null,
                            "rule_item": {
                                "id": "1",
                                "name": "updated_name2",
                                "detection_technique": "edm",
                                "occurrence_operator_type": "more_than_equal_to",
                                "occurrence_count": 1,
                                "confidence_level": "high",
                                "edm_dataset_id": "dataset-id-1",
                                "primary_match_criteria": "all",
                                "primary_fields": [
                                    "col-1",
                                    "col-3"
                                ],
                                "secondary_match_criteria": "any",
                                "secondary_match_any_count": 2,
                                "secondary_fields": [
                                    "col-2",
                                    "col-4",
                                    "col-5"
                                ]
                            },
                            "sub_expressions": []
                        },
                        {
                            "operator_type": null,
                            "rule_item": {
                                "id": "1234560",
                                "name": "outdated pattern name",
                                "detection_technique": "regex",
                                "occurrence_operator_type": "more_than_equal_to",
                                "occurrence_count": 1,
                                "confidence_level": "high"
                            },
                            "sub_expressions": []
                        },
                        {
                            "operator_type": null,
                            "rule_item": {
                                "id": "2",
                                "name": "updated_name2",
                                "detection_technique": "edm",
                                "occurrence_operator_type": "less_than_equal_to",
                                "occurrence_count": 4,
                                "confidence_level": "high",
                                "edm_dataset_id": "dataset-id-2",
                                "primary_match_criteria": "all",
                                "primary_match_any_count": 1,
                                "primary_fields": [
                                    "col-1"
                                ],
                                "secondary_match_criteria": "any",
                                "secondary_match_any_count": 1,
                                "secondary_fields": [
                                    "col-2",
                                    "col-3",
                                    "col-4",
                                    "col-5"
                                ]
                            },
                            "sub_expressions": []
                        },
                        {
                            "operator_type": null,
                            "rule_item": {
                                "id": "3",
                                "name": "updated_name2",
                                "detection_technique": "edm",
                                "occurrence_operator_type": "between",
                                "occurrence_low": 5,
                                "occurrence_high": 20,
                                "confidence_level": "high",
                                "edm_dataset_id": "dataset-id-3",
                                "primary_match_criteria": "any",
                                "primary_match_any_count": 1,
                                "primary_fields": [
                                    "col-1",
                                    "col-2"
                                ],
                                "secondary_match_criteria": "all",
                                "secondary_fields": [
                                    "col-3",
                                    "col-4"
                                ]
                            },
                            "sub_expressions": []
                        }
                    ]
                }
            }
        ],
        "profile_type": "custom",
        "schema_version": 2
      }
    };
    datasets = {
      "dataset-id-0": {
        "id": "dataset-id-0",
        "name": "Dataset A",
        "index_version": null,
        "index_status": "creating",
        "current_index_version": 1,
        "required_index_version": 1,
        "active_fields": [],
        "all_fields": [],
        "data": {},
        "created_at": "53192-11-26T17:57:48.000+00:00",
        "updated_at": "2021-03-22T11:45:43.068-07:00",
        "updated_by": "walker@rath.info"
      },
      "dataset-id-1": {
        "id": "dataset-id-1",
        "name": "Dataset B",
        "index_version": null,
        "index_status": "creating",
        "current_index_version": 1,
        "required_index_version": 1,
        "active_fields": [],
        "all_fields": [],
        "data": {},
        "created_at": "53192-11-26T17:57:48.000+00:00",
        "updated_at": "2021-03-22T11:45:43.068-07:00",
        "updated_by": "walker@rath.info"
      },
      "dataset-id-2": {
        "id": "dataset-id-2",
        "name": "Dataset C",
        "index_version": null,
        "index_status": "creating",
        "current_index_version": 1,
        "required_index_version": 1,
        "active_fields": [],
        "all_fields": [],
        "data": {},
        "created_at": "53192-11-26T17:57:48.000+00:00",
        "updated_at": "2021-03-22T11:45:43.068-07:00",
        "updated_by": "walker@rath.info"
      },
      "dataset-id-3": {
        "id": "dataset-id-3",
        "name": "Dataset D",
        "index_version": null,
        "index_status": "creating",
        "current_index_version": 1,
        "required_index_version": 1,
        "active_fields": [],
        "all_fields": [],
        "data": {},
        "created_at": "53192-11-26T17:57:48.000+00:00",
        "updated_at": "2021-03-22T11:45:43.068-07:00",
        "updated_by": "walker@rath.info"
      }
    };
    patterns = {
      '1234560': {
        "id": "1234560",
        "name": "SSN",
        "type": "predefined",
        "status": "active",
        "version": 1,
        "supported_confidence_levels": [
            "high",
            "low"
        ]
      }
    }
    const formatted = transformIncidentData(incidents, aggregation, profiles, datasets, patterns, region);
    expect(Object.keys(formatted)).toEqual(['aggregationData', 'pageData', 'filters', 'incidents']);
    expect(formatted.pageData).toEqual({
      size: 3,
      number: 0,
      total_effective_elements: 50,
      total_pages: 5
    });
    expect(formatted.incidents).toEqual([
      {
        action: "Alert",
        assigneeId: undefined,
        channel: "SaaS Security",
        createdAt: "2021-06-07T21:45:18.000+00:00",
        dataProfile: {
          "data_patterns": [],
          "id": "1234560",
          "multiProfile": false,
          "secondary_rule_eligible": true,
          "name": "EDM profile multi datasets 0",
          "profile_type": "data_profiles.index.modes.edm",
          primaryExpTree: {
            "operator_type": "or",
            "rule_item": null,
            "sub_expressions": [
                {
                    "operator_type": null,
                    "rule_item": {
                        "id": "1",
                        "name": "Dataset B",
                        "detection_technique": "edm",
                        "occurrence_operator_type": "more_than_equal_to",
                        "occurrence_count": 1,
                        "confidence_level": "high",
                        "edm_dataset_id": "dataset-id-1",
                        "primary_match_criteria": "all",
                        "primary_fields": [
                            "col-1",
                            "col-3"
                        ],
                        "secondary_match_criteria": "any",
                        "secondary_match_any_count": 2,
                        "secondary_fields": [
                            "col-2",
                            "col-4",
                            "col-5"
                        ]
                    },
                    "sub_expressions": []
                },
                {
                    "operator_type": null,
                    "rule_item": {
                        "id": "1234560",
                        "name": "SSN",
                        "detection_technique": "regex",
                        "occurrence_operator_type": "more_than_equal_to",
                        "occurrence_count": 1,
                        "confidence_level": "high"
                    },
                    "sub_expressions": []
                },
                {
                    "operator_type": null,
                    "rule_item": {
                        "id": "2",
                        "name": "Dataset C",
                        "detection_technique": "edm",
                        "occurrence_operator_type": "less_than_equal_to",
                        "occurrence_count": 4,
                        "confidence_level": "high",
                        "edm_dataset_id": "dataset-id-2",
                        "primary_match_criteria": "all",
                        "primary_match_any_count": 1,
                        "primary_fields": [
                            "col-1"
                        ],
                        "secondary_match_criteria": "any",
                        "secondary_match_any_count": 1,
                        "secondary_fields": [
                            "col-2",
                            "col-3",
                            "col-4",
                            "col-5"
                        ]
                    },
                    "sub_expressions": []
                },
                {
                    "operator_type": null,
                    "rule_item": {
                        "id": "3",
                        "name": "Dataset D",
                        "detection_technique": "edm",
                        "occurrence_operator_type": "between",
                        "occurrence_low": 5,
                        "occurrence_high": 20,
                        "confidence_level": "high",
                        "edm_dataset_id": "dataset-id-3",
                        "primary_match_criteria": "any",
                        "primary_match_any_count": 1,
                        "primary_fields": [
                            "col-1",
                            "col-2"
                        ],
                        "secondary_match_criteria": "all",
                        "secondary_fields": [
                            "col-3",
                            "col-4"
                        ]
                    },
                    "sub_expressions": []
                }
            ]
          },
          secondaryExpTree: {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: []
          },
          "schema_version": 2,
          "type": "Advanced",
          "updated_at": "2021-10-05T00:00:00.000+00:00",
          "updated_by": "SaaS Security",
        },
        dataProfileId: "EDM profile multi datasets 0",
        dataProfileVersion: 1,
        filename: "awesome_plastic_hat.zip",
        id: "ca23ee113dbc183a66d44573127187a9",
        incidentDataProfileVersion: 15,
        link: "./incidents/ca23ee113dbc183a66d44573127187a9?region=us",
        outdated: true,
        reportId: "1234560",
        source: "SaaS Security",
        viewableOverride: false,
        incidentNotes: undefined,
        resolutionStatus: undefined,
        userId: undefined,
        incidentFeedbackStatus: '-'
      },
      {
        action: "Block",
        assigneeId: undefined,
        channel: "NGFW",
        createdAt: "2021-06-07T21:45:18.000+00:00",
        dataProfile: {},
        dataProfileId: "errors.not_found",
        dataProfileVersion: undefined,
        filename: "fantastic_wooden_car.docx",
        id: "a5bf0587e5d6df68f602cd82bf001812",
        incidentDataProfileVersion: 15,
        link: "./incidents/a5bf0587e5d6df68f602cd82bf001812?region=us",
        outdated: true,
        reportId: "1234561",
        source: "10.64.218.44",
        viewableOverride: true,
        incidentNotes: undefined,
        resolutionStatus: undefined,
        userId: undefined,
        incidentFeedbackStatus: '-'
        },
        {
        action: "Quarantine",
        assigneeId: undefined,
        channel: "Prisma Access",
        createdAt: "2021-06-07T21:45:18.000+00:00",
        dataProfile: {},
        dataProfileId: "errors.not_found",
        dataProfileVersion: undefined,
        filename: "mediocre_wool_lamp.xlsx",
        id: "d0d8b769f712bdfb9b3a0ee0b294f772",
        incidentDataProfileVersion: 15,
        link: "./incidents/d0d8b769f712bdfb9b3a0ee0b294f772?region=us",
        outdated: true,
        reportId: "1234569",
        source: "10.99.186.19",
        viewableOverride: true,
        incidentNotes: undefined,
        resolutionStatus: undefined,
        userId: undefined,
        incidentFeedbackStatus: '-'
        }
    ]);
  });
});