import React from 'react';
import { render, fireEvent } from '@testing-library/react';
import '@testing-library/jest-dom';
import selectEvent from 'react-select-event';
import DataPatternRuleItem from './DataPatternRuleItem';

describe('<DataPatternRuleItem />', () => {
  let wrapper;
  const dataPatterns = {
    '12345': {
      id: '12345',
      name: 'CCN',
      supported_confidence_levels: ['high', 'low']
    },
    '12344': {
      id: '12344',
      name: 'SSN',
      supported_confidence_levels: ['high', 'low']
    }
  };
  const updateRuleItem = jest.fn();
  const deleteRuleItem = jest.fn();
  let props = {
    dp: {
      id: '12345',
      confidence_level: 'high',
      occurrence_operator_type: 'any',
      primary_match_criteria: 'any',
      secondary_match_criteria: 'any',
      primary_fields: [],
      secondary_fields: [],
    },
    position: '0-0',
    dataPatterns,
    updateRuleItem,
    deleteRuleItem
  };

  describe('rule item', () => {
    beforeEach(() => {
      wrapper = render(<DataPatternRuleItem {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.container.querySelectorAll('.occurrenceFields-0-0')).toHaveLength(1);
      expect(wrapper.container.querySelectorAll('.removeBtn')).toHaveLength(1);
    });

    it('matches snapshot', () => {
      expect(wrapper.asFragment()).toMatchSnapshot();
    });

    it('should change data pattern', async() => {
      const dropdown = wrapper.container.querySelector('.identifier-dropdown svg');
      await selectEvent.select(dropdown, ['SSN'])

      expect(updateRuleItem.mock.calls[0][1].id).toEqual('12344');
    });

    it('should delete definition', () => {
      let deleteIcons = wrapper.container.querySelectorAll('.removeBtn');
      fireEvent.click(deleteIcons[0]);
      expect(deleteRuleItem).toHaveBeenCalledWith('0-0');
    });
  });

  describe('operators and occurrences', () => {
    beforeEach(() => {
      props = {
        dp: {
          id: '12345',
          confidence_level: 'high',
          occurrence_operator_type: 'less_than_equal_to',
          primary_match_criteria: 'any',
          secondary_match_criteria: 'any',
          primary_fields: [],
          secondary_fields: [],
        },
        position: '0-0',
        dataPatterns,
        updateRuleItem,
        deleteRuleItem
      };
      wrapper = render(<DataPatternRuleItem {...props}/>);
    });

    it('should change operator to more_than_equal_to', () => {
      const dropdown = wrapper.container.querySelector('.basic-dropdown.occurrence');
      fireEvent.click(dropdown);
      const operator = wrapper.getByText('More than or equal to');
      expect(operator).toBeInTheDocument();
      fireEvent.click(operator);
      expect(updateRuleItem.mock.calls[0][1].occurrence_operator_type).toEqual("more_than_equal_to");
    });

    it('should change occurrence', () => {
      const input = wrapper.container.querySelector('input.occurrenceInput');
      fireEvent.focus(input);
      fireEvent.input(input, { target: { value: '5' } });
      expect(updateRuleItem.mock.calls[0][1].occurrence_count).toEqual(5);
    });

    it('should change operator to any', () => {
      const dropdown = wrapper.container.querySelector('.basic-dropdown.occurrence');
      fireEvent.click(dropdown);
      const operator = wrapper.getByText('Any');
      expect(operator).toBeInTheDocument();
      fireEvent.click(operator);
      expect(updateRuleItem.mock.calls[0][1].occurrence_operator_type).toEqual("any");
    });
  });

  it('should set occurrence high and low', () => {
    props = {
      dp: {
        id: '12345',
        confidence_level: 'high',
        occurrence_operator_type: 'between',
        primary_match_criteria: 'any',
        secondary_match_criteria: 'any',
        primary_fields: [],
        secondary_fields: [],
      },
      position: '0-0',
      dataPatterns,
      updateRuleItem,
      deleteRuleItem
    };
    wrapper = render(<DataPatternRuleItem {...props}/>);

    const inputs = wrapper.container.querySelectorAll('input.occurrenceInput');
    expect(inputs.length).toBe(2);
    fireEvent.focus(inputs[0]);
    fireEvent.input(inputs[0], { target: { value: '2' } });
    fireEvent.focus(inputs[1]);
    fireEvent.input(inputs[1], { target: { value: '4' } });
    expect(updateRuleItem.mock.calls[0][1].occurrence_low).toEqual(2);
    expect(updateRuleItem.mock.calls[1][1].occurrence_high).toEqual(4);
  });

  describe('validations', () => {
    it('should have error for missing dp id', () => {
      const noIdProps = {...props, dp: { id: null }, validatePresence: true};
      wrapper = render(<DataPatternRuleItem {...noIdProps} />);
      const dpError = wrapper.getByText('Select a data pattern');
      expect(dpError).toBeInTheDocument();
    });
    it('should have error for non-numeric occurrence low', () => {
      const invalidProps = {...props, dp: { occurrence_operator_type: 'between', occurrence_low: 'abc' }, validatePresence: true};
      wrapper = render(<DataPatternRuleItem {...invalidProps} />);
      const error = wrapper.getByText('Number only');
      expect(error).toBeInTheDocument();
    });
    it('should have error for non-numeric occurrence high', () => {
      const invalidProps = {...props, dp: { occurrence_operator_type: 'between', occurrence_low: 5, occurrence_high: 'abc' }, validatePresence: true};
      wrapper = render(<DataPatternRuleItem {...invalidProps} />);
      const error = wrapper.getByText('Number only');
      expect(error).toBeInTheDocument();
    });
    it('should have error for occurrence low > occurrence high', () => {
      const invalidProps = {...props, dp: { occurrence_operator_type: 'between', occurrence_low: 25, occurrence_high: 5 }, validatePresence: true};
      wrapper = render(<DataPatternRuleItem {...invalidProps} />);
      const error = wrapper.getByText('Low value must be less than high value');
      expect(error).toBeInTheDocument();
    });
    it('should have error for occurrence high > 500', () => {
      const invalidProps = {...props, dp: { occurrence_operator_type: 'between', occurrence_low: 25, occurrence_high: 501 }, validatePresence: true};
      wrapper = render(<DataPatternRuleItem {...invalidProps} />);
      const error = wrapper.getByText('Max detection limit is 500');
      expect(error).toBeInTheDocument();
    });
    it('should have error for occurrence count > 500', () => {
      const invalidProps = {...props, dp: { occurrence_operator_type: 'more_than_equal_to', occurrence_count: 501 }, validatePresence: true};
      wrapper = render(<DataPatternRuleItem {...invalidProps} />);
      const error = wrapper.getByText('Max detection limit is 500');
      expect(error).toBeInTheDocument();
    });
    it('should have error for occurrence count > 500 with less_than_equal_to operator', () => {
      const invalidProps = {...props, dp: { occurrence_operator_type: 'less_than_equal_to', occurrence_count: 501 }, validatePresence: true};
      wrapper = render(<DataPatternRuleItem {...invalidProps} />);
      const error = wrapper.getByText('Max detection limit is 500');
      expect(error).toBeInTheDocument();
    });
  });
});