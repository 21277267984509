import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../helpers/translate';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}

const ProfileName = ({ validatePresence, name, handleNameChange, nameError, readOnly }) => {
  return (
    <div className='form-section'>
      <h4>{t('details')}</h4>
      <br />
      <div className={`form-label ${((validatePresence && !name) || nameError) && 'error'}`}>{t('name')}</div>
      <input
        disabled={readOnly}
        maxLength='32'
        onChange={(event) => handleNameChange(event)}
        value={name}
        className={`form-control profile_name ${((validatePresence && !name) || nameError) && 'has-error'}`}
        placeholder={t('name_placeholder')}
      />
      { validatePresence && !name &&
        <div className='errorDetail error'>{t('name_placeholder')}</div>
      }
      { nameError &&
        <div className='errorDetail error'>{nameError}</div>
      }
    </div>
  );
}

ProfileName.propTypes = {
  validatePresence: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  handleNameChange: PropTypes.func.isRequired,
  nameError: PropTypes.string,
};

ProfileName.defaultProps = {
  validatePresence: false,
  readOnly: false,
  name: '',
  nameError: undefined,
}

export default ProfileName;









