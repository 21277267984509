import translate from "../../helpers/translate";

function t(scope, options) {
  return translate(`data_filtering_profiles.${scope}`, options);
}

const columns = [{
  "link": false,
  "header": t('data_filtering_profile'),
  "accessor": "name",
  "viewable": true
},
{
  "link": false,
  "header": t("action"),
  "accessor": "action"
},
{
  "link": false,
  "header": t("mode"),
  "accessor": "profile_type"
},
{
  "link": false,
  "header": t("last_modified"),
  "accessor": "updated_at"
}
];
export default columns;