import isProfileValid  from './profileValidatorV2';

describe('profileValidator', () => {
  let profile;

  it('should return true for valid profile', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [{
          operator_type: null,
          rule_item: {
            id: '5ee3c51d99025d000161ac53',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            name: 'Address - Austria',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: []
        }]
      }
    };
    expect(isProfileValid(profile)).toEqual(true);
  });

  it('should return false for edm occurrence_low >= occurrence_high', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'between',
                    occurrence_low: 500,
                    occurrence_high: 400,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [{
          operator_type: null,
          rule_item: {
            id: '5ee3c51d99025d000161ac53',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            name: 'Address - Austria',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: []
        }]
      }
    };
    expect(isProfileValid(profile)).toEqual(false);
  });

  it('should return false for pattern occurrence_low >= occurrence_high', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'between',
              occurrence_low: 500,
              occurrence_high: 400,
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'between',
                    occurrence_low: 300,
                    occurrence_high: 400,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [{
          operator_type: null,
          rule_item: {
            id: '5ee3c51d99025d000161ac53',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            name: 'Address - Austria',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: []
        }]
      }
    };
    expect(isProfileValid(profile)).toEqual(false);
  });

  it('should return false for occurrence_high > 500', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [{
          operator_type: null,
          rule_item: {
            id: '5ee3c51d99025d000161ac53',
            occurrence_operator_type: 'between',
            occurrence_low: 200,
            occurrence_high: 501,
            confidence_level: 'high',
            name: 'Address - Austria',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: []
        }]
      }
    };
    expect(isProfileValid(profile)).toEqual(false);
  });

  it('should return true for occurrence_high <= 500', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [{
          operator_type: null,
          rule_item: {
            id: '5ee3c51d99025d000161ac53',
            occurrence_operator_type: 'between',
            occurrence_low: 200,
            occurrence_high: 500,
            confidence_level: 'high',
            name: 'Address - Austria',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: []
        }]
      }
    };
    expect(isProfileValid(profile)).toEqual(true);
  });

  it('should return false for occurrence_count > 500', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'more_than_equal_to',
              occurrence_count: 501,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [{
          operator_type: null,
          rule_item: {
            id: '5ee3c51d99025d000161ac53',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            name: 'Address - Austria',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: []
        }]
      }
    };
    expect(isProfileValid(profile)).toEqual(false);
  });

  it('should return true for occurrence_count <= 500', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'more_than_equal_to',
              occurrence_count: 499,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [{
          operator_type: null,
          rule_item: {
            id: '5ee3c51d99025d000161ac53',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            name: 'Address - Austria',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: []
        }]
      }
    };
    expect(isProfileValid(profile)).toEqual(true);
  });

  it('should return false for missing name', () => {
    profile = {
      name: '',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: []
      }
    };
    expect(isProfileValid(profile)).toEqual(false);
  });

  it('should return true for missing edm as it is no longer required', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'not',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  id: '5ee3c51d99025d000161ac54',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  confidence_level: 'low',
                  name: 'Address - Australia',
                  supported_confidence_levels: [
                    'high',
                    'low'
                  ],
                  detection_technique: 'regex',
                  version: 1
                },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: []
      }
    };
    expect(isProfileValid(profile)).toEqual(true);
  });

  it('should return false for empty group', () => {
    profile = {
      name: 'abc',
      primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          },
          {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: []
          }
        ]
      },
      secondaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [{
          operator_type: null,
          rule_item: {
            id: '5ee3c51d99025d000161ac53',
            occurrence_operator_type: 'any',
            occurrence_count: null,
            confidence_level: 'high',
            name: 'Address - Austria',
            supported_confidence_levels: [
              'high',
              'low'
            ],
            detection_technique: 'regex',
            version: 1
          },
          sub_expressions: []
        }]
      }
    };
    expect(isProfileValid(profile)).toEqual(false);
  });

  describe('edm validations', () => {
    it('should return false for missing primary_match_any_count if primary match criteria is any', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                    id: '5ee3c51d99025d000161ac59',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    confidence_level: 'high',
                    name: 'Address - Denmark',
                    supported_confidence_levels: [
                      'high',
                      'low'
                    ],
                    detection_technique: 'regex',
                    version: 1
                  },
                  sub_expressions: []
                },
                {
                  operator_type: null,
                  rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'any',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'all',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return true for missing primary_match_any_count if primary match criteria is all', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(true);
    });

    it('should return false for missing secondary_match_any_count if secondary match criteria is any', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                    id: '5ee3c51d99025d000161ac59',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    confidence_level: 'high',
                    name: 'Address - Denmark',
                    supported_confidence_levels: [
                      'high',
                      'low'
                    ],
                    detection_technique: 'regex',
                    version: 1
                  },
                  sub_expressions: []
                },
                {
                  operator_type: null,
                  rule_item: {
                    edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                    occurrence_operator_type: 'any',
                    occurrence_count: null,
                    occurrence_low: null,
                    occurrence_high: null,
                    primary_fields: [
                      '60d63d6380824c3a1c1618b0',
                      '60d63d6380824c3a1c1618b1'
                    ],
                    primary_match_criteria: 'all',
                    primary_match_any_count: null,
                    secondary_fields: [],
                    secondary_match_criteria: 'any',
                    secondary_match_any_count: null,
                    detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing edm dataset id', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_operator_type', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: null,
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_count if operator type is more_than_equal_to', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'more_than_equal_to',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_count if operator type is less_than_equal_to', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'less_than_equal_to',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_low if operator type is between', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'between',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: 20,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_high if operator type is between', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'between',
                      occurrence_count: null,
                      occurrence_low: 5,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return true for occurrence_high and _low present if operator type is between', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'between',
                      occurrence_count: null,
                      occurrence_low: 5,
                      occurrence_high: 15,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(true);
    });

    it('should return false for missing primary_fields', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      id: '5ee3c51d99025d000161ac59',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      confidence_level: 'high',
                      name: 'Address - Denmark',
                      supported_confidence_levels: [
                        'high',
                        'low'
                      ],
                      detection_technique: 'regex',
                      version: 1
                  },
                  sub_expressions: []
                },
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });
  });

  describe('pattern validations', () => {
    it('should return false for missing id', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [{
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          }]
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_operator_type', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: null,
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [{
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          }]
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing confidence_level', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                confidence_level: null,
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [{
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          }]
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_count if occurrence is high', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'high',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [{
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          }]
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_count if occurrence is low', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'low',
                occurrence_count: null,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [{
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          }]
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_low if occurrence is between', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'between',
                occurrence_count: null,
                occurrence_high: 25,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [{
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          }]
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });

    it('should return false for missing occurrence_high if occurrence is between', () => {
      profile = {
        name: 'abc',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                id: '5ee3c51d99025d000161ac53',
                occurrence_operator_type: 'between',
                occurrence_count: null,
                occurrence_low: 5,
                confidence_level: 'high',
                name: 'Address - Austria',
                supported_confidence_levels: [
                  'high',
                  'low'
                ],
                detection_technique: 'regex',
                version: 1
              },
              sub_expressions: []
            },
            {
              operator_type: 'not',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                      edm_dataset_id: '60e8a5cd85c9c31cccd962a5',
                      occurrence_operator_type: 'any',
                      occurrence_count: null,
                      occurrence_low: null,
                      occurrence_high: null,
                      primary_fields: [
                        '60d63d6380824c3a1c1618b0',
                        '60d63d6380824c3a1c1618b1'
                      ],
                      primary_match_criteria: 'all',
                      primary_match_any_count: null,
                      secondary_fields: [],
                      secondary_match_criteria: 'all',
                      secondary_match_any_count: null,
                      detection_technique: 'edm'
                  },
                  sub_expressions: []
                }
              ]
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [{
            operator_type: null,
            rule_item: {
              id: '5ee3c51d99025d000161ac53',
              occurrence_operator_type: 'any',
              occurrence_count: null,
              confidence_level: 'high',
              name: 'Address - Austria',
              supported_confidence_levels: [
                'high',
                'low'
              ],
              detection_technique: 'regex',
              version: 1
            },
            sub_expressions: []
          }]
        }
      };
      expect(isProfileValid(profile)).toEqual(false);
    });
  });

  describe('multi profile validations', () => {
    it('should return true for valid profile', () => {
      profile = {
        name: 'abc',
        multi: true,
        selectedPrimaryProfiles: [
          { key: 'abc123', name: 'profile a' },
          { key: 'def456', name: 'profile b' }
        ],
        selectedSecondaryProfiles: [
          { key: 'ghi789', name: 'profile c' }
        ]
      };
      expect(isProfileValid(profile)).toEqual(true);
    });
    it('should return false for empty primary profiles', () => {
      profile = {
        name: 'abc',
        multi: true,
        selectedPrimaryProfiles: []
      };
      expect(isProfileValid(profile)).toEqual(false);
    });
    it('should return false for invalid primary profiles', () => {
      profile = {
        name: 'abc',
        multi: true,
        selectedPrimaryProfiles: ['abc']
      };
      expect(isProfileValid(profile)).toEqual(false);
    });
    it('should return false for invalid primary profiles', () => {
      profile = {
        name: 'abc',
        multi: true,
        selectedPrimaryProfiles: [
          { key: 'abc123', name: 'profile a' },
          { key: 'def456', name: 'profile b' }
        ],
        selectedSecondaryProfiles: ['abc']
      };
      expect(isProfileValid(profile)).toEqual(false);
    });
  });
});
