// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<ProfileName> renders missing name errors 1`] = `
<div
  className="form-section"
>
  <h4>
    data_profiles.form.details
  </h4>
  <br />
  <div
    className="form-label error"
  >
    data_profiles.form.name
  </div>
  <input
    className="form-control profile_name has-error"
    disabled={false}
    maxLength="32"
    onChange={[Function]}
    placeholder="data_profiles.form.name_placeholder"
    value=""
  />
  <div
    className="errorDetail error"
  >
    data_profiles.form.name_placeholder
  </div>
</div>
`;

exports[`<ProfileName> renders profile name errors 1`] = `
<div
  className="form-section"
>
  <h4>
    data_profiles.form.details
  </h4>
  <br />
  <div
    className="form-label error"
  >
    data_profiles.form.name
  </div>
  <input
    className="form-control profile_name has-error"
    disabled={false}
    maxLength="32"
    onChange={[Function]}
    placeholder="data_profiles.form.name_placeholder"
    value="abc"
  />
  <div
    className="errorDetail error"
  >
    Duplicate name
  </div>
</div>
`;

exports[`<ProfileName> renders profile name input 1`] = `
<div
  className="form-section"
>
  <h4>
    data_profiles.form.details
  </h4>
  <br />
  <div
    className="form-label undefined"
  >
    data_profiles.form.name
  </div>
  <input
    className="form-control profile_name undefined"
    disabled={false}
    maxLength="32"
    onChange={[Function]}
    placeholder="data_profiles.form.name_placeholder"
    value="abc"
  />
</div>
`;
