// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<Reports> renders search bar 1`] = `
<div>
  <div
    className="header"
  >
    <div
      className="breadcrumb"
    >
      <a
        className="btn-link"
        href="/"
      >
        application.dlp
      </a>
      <span
        className="fa fa-chevron-right separator"
      />
      reports.index.title
    </div>
    <div
      className="title"
    >
      reports.index.title
    </div>
    <div
      className="description"
    >
      reports.index.description
    </div>
  </div>
  <div
    className="reportView"
  >
    <div
      className="searchArea"
    >
      <div
        className="search rightInnerAddOn"
      >
        <input
          className="form-control"
          maxLength="72"
          onChange={[Function]}
          onKeyPress={[Function]}
          placeholder="reports.index.search_for_id"
          value=""
        />
        <i>
          <FaSearch />
        </i>
      </div>
      <Button
        addClassName="search-button"
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        reports.index.search
      </Button>
    </div>
    <div
      className="resultArea"
    >
      <div
        className="noSearch"
      >
        <img
          alt="Search"
        />
        <div
          className="subText"
        >
          reports.index.search_to_start
        </div>
      </div>
    </div>
  </div>
</div>
`;
