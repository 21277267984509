import translate from '../../helpers/translate';

export default [
  {
    value: 'c2s',
    label: translate('data_filtering_profiles.upload'),
  },
  {
    value: 's2c',
    label: translate('data_filtering_profiles.download'),
  },
  {
    value: 'both',
    label: translate('data_filtering_profiles.both'),
  }
];
