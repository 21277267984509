import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import moment from 'moment';
import Table from './Table';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<Table>', () => {
  let wrapper;
  const props = {
    type: 'api_tokens',
    columns: [
      { class: 'name', title: 'token_name' },
      { class: 'createdDate', title: 'create' },
      { class: 'expiryDate', title: 'expiry' },
      { class: 'action_item', title: 'revoke' },
      { class: 'action_item', title: 'renew' },
    ],
    data: [
      { appName: 'Token A', id: '123', createdDate: moment('2020-07-08', 'YYYY-MM-DD').toISOString(), expiryDate: moment('2021-07-08', 'YYYY-MM-DD').toISOString() },
      { appName: 'Token B', id: '456', createdDate: moment('2020-07-08', 'YYYY-MM-DD').toISOString(), expiryDate: moment('2021-07-08', 'YYYY-MM-DD').toISOString() },
    ],
    updateState: () => {},
    userRole: 'READ_WRITE'
  };

  it('renders', () => {
    wrapper = shallow(<Table {...props} />);
    expect(wrapper).toMatchSnapshot();
  });

  it('renders without action column for read only user', () => {
    const readOnlyProps = {...props, userRole: 'READ_ONLY'}
    wrapper = shallow(<Table {...readOnlyProps} />);
    expect(wrapper).toMatchSnapshot();
  });
});
