import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { Button } from '@panwds/react-ui';
import translate from '../../helpers/translate';
import ReportCard from './ReportCard'
import ReportDetails from './ReportDetails'
import SearchIcon from '../../../assets/images/report-search.svg';
import NotFoundIcon from '../../../assets/images/not-found.svg';

function t(scope, options) {
  return translate(`reports.index.${scope}`, options);
}

class Reports extends React.Component {
  state = {
    reportId: '',
    reports: [],
    searchStarted: false,
    found: null,
    detailReport: undefined
  };

  handleSearchChange = (event) => {
    this.setState({ reportId: event.target.value });
  };

  handleSubmit = (event) => {
    if (event.key === 'Enter') {
      this.search();
    }
  };

  search = () => {
    this.setState({ searchStarted: true });
    axios.get(`/reports/${this.state.reportId}.json`).then(
      result => {
        if (result.data && result.data.length > 0) {
          this.setState({ reports: result.data, found: true });
          if (result.data.length === 1) {
            this.setState({ detailReport: result.data[0] });
          }
        }
        else {
          this.setState({ found: false });
        }
      }
    )
  }

  showDetails = (report) => {
    this.setState({
      detailReport: report
    });
  }

  showAll = () => {
    this.setState({ detailReport: undefined })
  }

  reportHeader = () => {
    if (this.state.detailReport) {
      return(
        <div className='header'>
          <div className='breadcrumb'>
          <a className='btn-link' href='/'>{translate('application.dlp')}</a>
            <span className='fa fa-chevron-right separator' />
            <span className='btn-link' onClick={this.showAll}>{t('title')}</span>
            <span className='fa fa-chevron-right separator' />
            {t('details')}
          </div>
          <div className='title'>{t('report')} {this.state.detailReport.id}</div>
        </div>
      )
    }
    return (
      <div className='header'>
        <div className='breadcrumb'>
        <a className='btn-link' href='/'>{translate('application.dlp')}</a>
          <span className='fa fa-chevron-right separator' />
          {t('title')}
        </div>
        <div className='title'>{t('title')}</div>
        <div className='description'>{t('description')}</div>
      </div>
    )
  }

  render() {
    const { reportId, searchStarted, reports, found, detailReport } = this.state;
    return (
      <div>
        {this.reportHeader()}
        <div className='reportView'>
          { detailReport ? <ReportDetails report={detailReport} userRole={this.props.userRole} /> :
            <>
              <div className='searchArea'>
                <div className='search rightInnerAddOn'>
                  <input className='form-control' value={reportId} onKeyPress={this.handleSubmit} onChange={this.handleSearchChange} placeholder={t('search_for_id')} maxLength='72' />
                  <i><FaSearch /></i>
                </div>
                <Button appearance='primary' addClassName='search-button' onClick={this.search}>{t('search')}</Button>
              </div>
              { !searchStarted &&
                <div className='resultArea'>
                  <div className='noSearch'>
                    <img src={SearchIcon} alt='Search' />
                    <div className='subText'>
                      {t('search_to_start')}
                    </div>
                  </div>
                </div>
              }
              { searchStarted && found === false &&
                <div className='resultArea'>
                  <div className='notFound'>
                    <img src={NotFoundIcon} alt='Not Found' />
                    <div className='subText'>
                      {t('none_found')}
                    </div>
                    <div className='helpText'>
                      {t('try_adjusting_search')}
                    </div>
                  </div>
                </div>
              }
              { searchStarted && found &&
                <div className='results'>
                  <div className='resultTotal'>{t('total_results', { count: reports.length }) }</div>
                  {reports.map((report, idx) => <ReportCard report={report} key={`${report.report_id}-${idx}`} showDetails={this.showDetails} userRole={this.props.userRole} />)}
                </div>
              }
              </>
            }
          </div>
      </div>
    );
  }
}

Reports.propTypes = {
  userRole: PropTypes.string,
};

Reports.defaultProps = {
  userRole: 'READ_ONLY'
};

export default Reports;