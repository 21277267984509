// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<IncidentDetails> with data patterns renders report details with data pattern widget when present 1`] = `
<div
  className="detailsView"
>
  <div
    className="header"
  >
    <div
      className="breadcrumb"
    >
      <span
        className="btn-link"
      >
        <a
          href="/"
        >
          application.dlp
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      <span
        className="btn-link"
      >
        <a
          href="/incidents"
        >
          incidents.show.title
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      incidents.show.details
    </div>
    <div
      className="title"
    >
      Test Asset
    </div>
    <div
      className="description"
    >
      <span>
        <b>
          incidents.show.report_card.channel
        </b>
        : 
        Prisma Access | 
      </span>
      <span>
        <b>
          incidents.show.report_card.scan_date
        </b>
        :  
        July 9, 2020, 1:56 PM PDT
      </span>
    </div>
  </div>
  <div
    className="detailsContent tw-flex"
  >
    <div
      className="left-column tw-flex-col"
    >
      <ReportCard
        downloadURL={null}
        incident={
          Object {
            "asset_id": "987654",
            "channel": "Prisma Access",
            "dataProfile": "Bank Account Number North American",
            "data_pattern_results": Array [
              Object {
                "data_pattern_id": "pattern1",
                "high_confidence_frequency": 0,
                "low_confidence_frequency": 5,
                "medium_confidence_frequency": 2,
                "name": "Pattern1",
              },
              Object {
                "data_pattern_id": "pattern2",
                "high_confidence_frequency": 2,
                "low_confidence_frequency": 5,
                "medium_confidence_frequency": 0,
                "name": "Pattern2",
              },
              Object {
                "data_pattern_id": "pattern3",
                "high_confidence_frequency": 0,
                "low_confidence_frequency": 4,
                "medium_confidence_frequency": 1,
                "name": "Pattern1",
              },
            ],
            "filename": "Test Asset",
            "incidentId": "12345",
            "scanDate": "2020-07-09T20:56:14.620Z",
            "userRole": "READ_WRITE",
          }
        }
        userRole={null}
      />
      <section
        className="feedback-history reportDetails"
      >
        <div
          className="card-title reportHeader"
        >
          Response History
        </div>
        <div
          className="details"
        >
          <div
            className="history-item"
            key="history-0"
          >
            <div
              className="feedback-detail tw-mb-2 active"
            >
              Response status changed to "Confirmed Sensitive"
            </div>
            <div
              className="timestamp tw-mb-2"
            >
              February 14, 2022, 1:51 PM PST
            </div>
          </div>
          <div
            className="history-item"
            key="history-1"
          >
            <div
              className="feedback-detail tw-mb-2 "
            >
              Notification has been sent to user.
            </div>
            <div
              className="timestamp tw-mb-2"
            >
              February 14, 2022, 1:51 PM PST
            </div>
          </div>
        </div>
      </section>
    </div>
    <MatchingDataPatterns
      confidence="hcf"
      highMatches={
        Object {
          "pattern2": Object {
            "data_pattern_id": "pattern2",
            "high_confidence_frequency": 2,
            "low_confidence_frequency": 5,
            "medium_confidence_frequency": 0,
            "name": "Pattern2",
          },
        }
      }
      incidentId="12345"
      isMultiProfile={false}
      lowMatches={
        Object {
          "pattern1": Object {
            "data_pattern_id": "pattern1",
            "high_confidence_frequency": 0,
            "low_confidence_frequency": 5,
            "medium_confidence_frequency": 2,
            "name": "Pattern1",
          },
          "pattern2": Object {
            "data_pattern_id": "pattern2",
            "high_confidence_frequency": 2,
            "low_confidence_frequency": 5,
            "medium_confidence_frequency": 0,
            "name": "Pattern2",
          },
          "pattern3": Object {
            "data_pattern_id": "pattern3",
            "high_confidence_frequency": 0,
            "low_confidence_frequency": 4,
            "medium_confidence_frequency": 1,
            "name": "Pattern1",
          },
        }
      }
      medMatches={
        Object {
          "pattern1": Object {
            "data_pattern_id": "pattern1",
            "high_confidence_frequency": 0,
            "low_confidence_frequency": 5,
            "medium_confidence_frequency": 2,
            "name": "Pattern1",
          },
          "pattern3": Object {
            "data_pattern_id": "pattern3",
            "high_confidence_frequency": 0,
            "low_confidence_frequency": 4,
            "medium_confidence_frequency": 1,
            "name": "Pattern1",
          },
        }
      }
      multiProfileList={Array []}
      snippets={Object {}}
    />
  </div>
</div>
`;

exports[`<IncidentDetails> without data patterns does not render data pattern widget without data pattern results 1`] = `
<div
  className="detailsView"
>
  <div
    className="header"
  >
    <div
      className="breadcrumb"
    >
      <span
        className="btn-link"
      >
        <a
          href="/"
        >
          application.dlp
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      <span
        className="btn-link"
      >
        <a
          href="/incidents"
        >
          incidents.show.title
        </a>
      </span>
      <span
        className="fa fa-chevron-right separator"
      />
      incidents.show.details
    </div>
    <div
      className="title"
    />
    <div
      className="description"
    >
      <span>
        <b>
          incidents.show.report_card.channel
        </b>
        : 
        Prisma Access | 
      </span>
    </div>
  </div>
  <div
    className="detailsContent tw-flex"
  >
    <div
      className="left-column tw-flex-col"
    >
      <ReportCard
        downloadURL={null}
        incident={
          Object {
            "asset_id": "987654",
            "channel": "Prisma Access",
            "dataProfileName": "Bank Account Number North American",
            "data_pattern_results": Array [],
            "detection_time": "2020-07-09T20:56:14.620Z",
            "file_name": "Test Asset",
            "file_size_in_bytes": 45986,
            "incident_id": "12345",
          }
        }
        userRole={null}
      />
    </div>
  </div>
</div>
`;
