import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaCheckCircle, FaCopy, FaExclamationTriangle } from 'react-icons/fa';
import { Button } from '@panwds/react-ui';
import translate from '../../../helpers/translate';

const t = (scope, options) => translate(`api_tokens.index.${scope}`, options);

function copy(id) {
  const copyText = document.getElementById(id);
  copyText.select();
  document.execCommand('copy');
}

const createModalBody = (error, errorMessage, tokenName, handleNameChange) => {
  return(
    <div>
      <p id='nameInputLabel' className={error ? 'error' : ''}>{t('create_modal.name')}</p>
      <input id='createTokenInput' maxLength='72' className={`form-control${error ? ' error' : ''}`} value={tokenName} onChange={handleNameChange} />
      <span className={`infoText messageArea${error ? ' error' : ''}`}>
        {error ? errorMessage : t('create_modal.description')}
      </span>
      <span className='tw-float-right infoText'>
        {tokenName.length}/72
      </span>
    </div>
  );
}

const createModalFooter = (processing, tokenName, create) => {
  return(
    <Button disabled={processing || tokenName.length === 0} appearance='primary' onClick={() => create()} >
      {t('create')}
    </Button>
  );
}

const errorModalBody = (errorMessage) => {
  return(
    <div>
      <span className='error mr5'><FaTimes /></span>
      {errorMessage || t('errors.generic')}
    </div>
  );
}

const errorModalFooter = (processing, retry) => {
  return(
    <Button disabled={processing} appearance='primary' onClick={() => retry()}>
      {t('retry')}
    </Button>
  );
}

const createModalOutputBody = (status, accessToken, refreshToken) => {
  return(
    <div>
      <p><FaCheckCircle className='success-icon mr5'/>{status === 'renew' ? t('renew_modal.success') : t('create_modal.success')}</p>
      <p className='warningText'>{t('warning')}</p>
      <br/>
      <p>{t('access_token')}</p>
      <div className='rightInnerAddOn'>
        <i onClick={ () => copy('createAccessTokenVal') }><FaCopy /></i>
        <input id='createAccessTokenVal' className='form-control tokenDisplay' readOnly value={accessToken} />
      </div>
      <br/>
      <p>{t('refresh_token')}</p>
      <div className='rightInnerAddOn'>
        <i onClick={ () => copy('createRefreshTokenVal') }><FaCopy /></i>
        <input id='createRefreshTokenVal' className='form-control tokenDisplay' readOnly value={refreshToken} />
      </div>
    </div>
  );
}

const createModalOutputFooter = (closeModal) => {
  return(
    <Button appearance='tertiary' onClick={closeModal()}>
      Close
    </Button>
  );
}

const renewRevokeModalBody = (status) => {
  return(
    <div>
      {t(`${status}_modal.body`)}
    </div>
  );
}

const renewRevokeModalFooter = (processing, status, update) => {
  return(
    <Button disabled={processing} appearance={status === 'renew' ? 'primary' : 'primary-destructive'} onClick={() => update()}>
      {t(status)}
    </Button>
  );
}

const confirmModalBody = () => {
  return(
    <div>
      <FaExclamationTriangle className='warning mr5'/>
      <span>{t('caution_message')}</span>
    </div>
  );
}

const confirmModalFooter = (processing, confirm) => {
  return(
    <Button disabled={processing} appearance='primary' onClick={confirm}>
      {translate('actions.continue')}
    </Button>
  );
}

const TokenModals = ({type, area, error, errorMessage, tokenName, processing, status, accessToken, refreshToken, handleNameChange, confirm, create, retry, closeModal, update }) => {
  if (type === 'createModal') {
    return area === 'body' ? createModalBody(error, errorMessage, tokenName, handleNameChange) : createModalFooter(processing, tokenName, create);
  }
  if (type === 'errorModal') {
    return area === 'body' ? errorModalBody(errorMessage) : errorModalFooter(processing, retry);
  }
  if (type === 'createModalOutput') {
    return area === 'body' ? createModalOutputBody(status, accessToken, refreshToken) : createModalOutputFooter(closeModal);
  }
  if (type === 'renewRevokeModal') {
    return area === 'body' ? renewRevokeModalBody(status) : renewRevokeModalFooter(processing, status, update);
  }
  return area === 'body' ? confirmModalBody() : confirmModalFooter(processing, confirm);
}

TokenModals.propTypes = {
  type: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  tokenName: PropTypes.string,
  processing: PropTypes.bool,
  status: PropTypes.string,
  accessToken: PropTypes.string,
  refreshToken: PropTypes.string,
  handleNameChange: PropTypes.func,
  confirm: PropTypes.func,
  create: PropTypes.func,
  retry: PropTypes.func,
  closeModal: PropTypes.func,
  update: PropTypes.func,
};

TokenModals.defaultProps = {
  error: false,
  errorMessage: null,
  tokenName: '',
  processing: false,
  status: '',
  accessToken: '',
  refreshToken: '',
  handleNameChange: undefined,
  confirm: undefined,
  create: undefined,
  retry: undefined,
  closeModal: undefined,
  update: undefined,

}

export default TokenModals;
