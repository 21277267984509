import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalCloseButton } from '@panwds/react-ui';
import $ from 'jquery';
import { FaSearch } from 'react-icons/fa';
import { IntlProvider } from 'react-intl';
import translate from '../../helpers/translate';
import BulkTable from '../BulkTable/BulkTable';
import DataProfileDetails from './DataProfileDetails';
import { profileDetails } from './profileDataTransformer';
import PatternProfileIcon from '../../../assets/images/pattern-profile.svg';
import HybridProfileIcon from '../../../assets/images/hybrid-profile.svg';
import MultiProfileIcon from '../../../assets/images/multi-profile.svg';
import {
  ASC,
  DATA_PROFILE_COL_UPDATED_AT
} from '../constants';

import { sortArrObjects } from '../../helpers/utils';

function t(scope, options) {
  return translate(`data_profiles.index.${scope}`, options);
}

const FAWKES_HYBRID_LINK = 'https://docs.paloaltonetworks.com/enterprise-dlp/enterprise-dlp-admin/configure-enterprise-dlp/create-an-enterprise-dlp-data-filtering-profile/create-a-data-profile-with-data-patterns-and-edm-datasets-on-prisma-access-cloud-managed';
const FAWKES_PATTERN_LINK = 'https://docs.paloaltonetworks.com/enterprise-dlp/enterprise-dlp-admin/configure-enterprise-dlp/create-an-enterprise-dlp-data-filtering-profile/create-a-data-profile-on-prisma-access-cloud-managed';
const FAWKES_MULTI_LINK = 'https://docs.paloaltonetworks.com/enterprise-dlp/enterprise-dlp-admin/configure-enterprise-dlp/create-an-enterprise-dlp-data-filtering-profile/create-a-data-profile-with-nested-data-profiles-on-prisma-access-cloud-managed';
const DLP_HYBRID_LINK = 'https://docs.paloaltonetworks.com/enterprise-dlp/enterprise-dlp-admin/configure-enterprise-dlp/create-an-enterprise-dlp-data-filtering-profile/create-a-data-profile-with-data-patterns-and-edm-datasets-on-the-dlp-app';
const DLP_PATTERN_LINK = 'https://docs.paloaltonetworks.com/enterprise-dlp/enterprise-dlp-admin/configure-enterprise-dlp/create-an-enterprise-dlp-data-filtering-profile/create-a-data-profile-on-the-dlp-app';
const DLP_MULTI_LINK = 'https://docs.paloaltonetworks.com/enterprise-dlp/enterprise-dlp-admin/configure-enterprise-dlp/create-an-enterprise-dlp-data-filtering-profile/create-a-data-profile-with-nested-data-profiles-on-the-dlp-app';

class DataProfileTable extends React.Component {
  state = {
    data: [],
    pageData: {},
    viewing: '',
    selectedItems: [],
    profileNameSearch: '',
    showNewModal: false,
    loading: true,
    sortBy: DATA_PROFILE_COL_UPDATED_AT,
    sortOrder: ASC
  };

  dataProfilesList;

  componentDidMount() {
    $('.banner-area').html('');
    const axiosConfig = {
      url: `${this.props.lookupURL}?sort=-updated_at`,
      method: this.props.lookupMethod
    }
    axios(axiosConfig).then(
      result => {
        const profiles = result.data.resources;
        const multiProfileList = profiles.map(profile => {
          return {
            value: profile.name,
            key: profile.id,
            disabled: !profile.profile_available_for_multi_profile
          }});
        const formattedResults = profiles.map(profile => profileDetails(profile, this.props.dataPatterns, this.props.datasets, false, multiProfileList));
        this.dataProfilesList = sortArrObjects(formattedResults, this.state.sortBy, this.state.sortOrder, this.state.sortBy === DATA_PROFILE_COL_UPDATED_AT);
        this.requestResults(0, 15);
      }
    ).catch(
      (error) => {
        console.error(error);
        this.setState({ data: [], loading: false });
        this.dataProfilesList = [];
      }
    );
  };

  addNew = () => {
    this.setState({showNewModal: true});
  };

  navigateNew = (type = 'v1') => {
    if (this.props.history) {
      this.props.history.push(`./data-profiles/new`,
        {
          type
        }
      );
    }
    else {
      window.location.assign(`/data_profiles/new?type=${type}`);
    }
  }

  handleNameSearchChange = (event) => {
    this.setState({ profileNameSearch: event.target.value });
    this.requestResults(0, this.state.pageData.size, event.target.value);
  };

  closeModal = () => {
    this.setState({
      showNewModal: false
    });
  }

  combineAndRemoveDuplicates = (arrDataProfileLists) => {
    const resultSet = new Set();

    arrDataProfileLists.forEach(list => {
      list.forEach(dataProfile => {
        resultSet.add(dataProfile);
      });
    });

    return Array.from(resultSet);
  }

  requestResults = (pageNum, numPerPage, nameSearch = this.state.profileNameSearch) => {
    this.setState({ loading: true });
    const filteredProfilesByName = (this.dataProfilesList || []).filter(dp => dp.name ? dp.name.toLowerCase().includes(nameSearch.toLowerCase()) : null);
    const filteredProfilesByProfileType = (this.dataProfilesList || []).filter(dp => dp.profile_type ? dp.profile_type.toLowerCase().includes(nameSearch.toLowerCase()) : null);
    const filteredProfilesByType = (this.dataProfilesList || []).filter(dp => dp.type ? dp.type.toLowerCase().includes(nameSearch.toLowerCase()) : null);
    const filteredProfilesByUpdatedBy = (this.dataProfilesList || []).filter(dp => dp.updated_by ? dp.updated_by.toLowerCase().includes(nameSearch.toLowerCase()) : null);

    const filteredProfiles = this.combineAndRemoveDuplicates([
      filteredProfilesByName,
      filteredProfilesByProfileType,
      filteredProfilesByType,
      filteredProfilesByUpdatedBy
    ]);

    const totalPages = Math.ceil(filteredProfiles.length / numPerPage);
    const startIndex = pageNum * numPerPage;
    const endIndex = (pageNum + 1) * numPerPage;
    const pagedProfiles = filteredProfiles.slice(startIndex, endIndex);

    this.setState({
      data: pagedProfiles,
      pageData: {
        size: numPerPage,
        number: pageNum,
        total_elements: filteredProfiles.length,
        total_pages: totalPages,
        total_effective_elements: filteredProfiles.length
      },
      loading: false
    });
  }

  actionable = (profile) => {
    return({
      editable: this.props.userRole === 'READ_WRITE' && ['Classic', 'Advanced', 'Nested'].includes(profile.profile_type),
      cloneable: this.props.userRole === 'READ_WRITE',
      deletable: false,
    });
  }

  handleCloneProfile = (profile) => {
    if (this.props.history) {
      const clone = { ...profile};
      delete clone.id;
      delete clone.created_by;
      delete clone.updated_by;
      if (profile.type === 'predefined') {
        clone.type = 'custom';
      }

      this.props.history.push(`${this.props.match.path}/clone`,
        {
          dataProfile: clone
        }
      );
    } else {
      window.location.assign(`/data_profiles/${profile.id}/clone`);
    }
  }

  updateState = (newState) => {
    if (newState.viewing) {
      this.setState(prevState => ({
        viewing: prevState.data.find(d => d.id === newState.viewing)
      }));
    }
    else if (newState.editing) {
      if (this.props.history) {
        this.props.history.push(`${this.props.match.path}/${newState.editing}/edit`);
      } else {
        window.location.assign(`/data_profiles/${newState.editing}/edit`);
      }
    }
    else if (newState.cloning) {
      this.handleCloneProfile(this.state.data.find(i => i.id === newState.cloning));
    }
    else {
      this.setState(newState);
      if (newState.deleting) {
        $(`#delete_modal`).modal('show');
      }
    }
  }

  handleUpdateSortControls = ({ sortBy=DATA_PROFILE_COL_UPDATED_AT, sortOrder=ASC }) => {
    this.setState({
      sortBy,
      sortOrder
    }, () => {
      this.dataProfilesList = sortArrObjects(
        this.dataProfilesList,
        this.state.sortBy,
        this.state.sortOrder,
        this.state.sortBy === DATA_PROFILE_COL_UPDATED_AT
      );
      this.requestResults(0, this.state.pageData.size);
    });
  }

  learnMore = (event, type) => {
    event.stopPropagation();
    const fawkes = !!this.props.history;
    if (type === 'v1') {
      window.open(fawkes ? FAWKES_PATTERN_LINK : DLP_PATTERN_LINK);
      return false;
    }
    if (type === 'multi') {
      window.open(fawkes ? FAWKES_MULTI_LINK : DLP_MULTI_LINK);
      return false;
    }
    window.open(fawkes ? FAWKES_HYBRID_LINK : DLP_HYBRID_LINK);
    return false;
  }

  render () {
    const { data, pageData, loading, sortBy, sortOrder } = this.state;
    return (
      <IntlProvider locale='en'>
      <div className='data_profiles'>
        <div className='header main'>
          <div className='breadcrumb'>
            <a className='btn-link' href='/'>{translate('application.dlp')}</a>
            <span className='fa fa-chevron-right separator' />
            {t('title')}
          </div>
          <div className='title'>{t('title')}</div>
        </div>
        <div className='dataProfileTable reactTable'>
          <div className="title card-title">
            <span>
              {t('title')}
              <span className='count'>{`(${pageData.total_effective_elements || 0})`}</span>
            </span>
            {this.props.userRole === 'READ_WRITE' &&
              <span className="actions tw-float-right">
                <Button onClick={this.addNew} appearance='primary'>
                  {t('add')}
                </Button>
              </span>
            }
            <span className='tw-float-right rightInnerAddOn searchBar'>
              <input value={this.state.profileNameSearch} onChange={this.handleNameSearchChange}  placeholder={t('search')} className='form-control' />
              <i><FaSearch /></i>
            </span>
          </div>
          <BulkTable
            data={data}
            defaultSortBy={sortBy}
            defaultSortOrder={sortOrder}
            columns={this.props.columns}
            pageData={
              {
                pageCount: pageData.size,
                currentPage: pageData.number,
                totalCount: pageData.total_effective_elements || data.length,
                totalPages: pageData.total_pages
              }
            }
            requestResults={this.requestResults}
            loading={loading}
            updateState={this.updateState}
            updateSortControls={this.handleUpdateSortControls}
            selectedItems={this.state.selectedItems}
            selectable={false}
            sortable
            actionable={
              this.props.userRole === 'READ_WRITE' ?
              {
                deletable: () => false,
                editable: (row) => this.props.userRole === 'READ_WRITE' &&['Classic', 'Advanced', 'Nested'].includes(row.values.profile_type),
                cloneable: () => this.props.userRole === 'READ_WRITE'
              }
              :
              null
            }
          />
          {this.state.viewing &&
            <DataProfileDetails
              profile={this.state.viewing}
              updateState={this.updateState}
              actionable={this.actionable(this.state.viewing)}
              history={this.props.history} />}
        </div>
        <IntlProvider locale='en'>
          <Modal addClassName='dlp-root modal' size="md" confirmClose={false} onClose={this.closeModal} isOpen={this.state.showNewModal}>
            <ModalHeader title="Add a Data Profile" enableClose />
            <ModalBody addClassName="newModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
              <div>
                <p className='helpTitle'>Select the type of Data Profile to create:</p>
                <div className='formCommon' onClick={() => this.navigateNew('v1')}>
                  <img src={PatternProfileIcon} alt='Pattern Profile' className='text-center profileImg' />
                  <span className='tw-float-right profileDesc'>
                    <h5>Classic Data Profile</h5>
                    <p>Create a classic data profile that is editable on the Panorama management server. Cannot include advanced detection methods such as EDM. <span className='btn-link' onClick={(event) => this.learnMore(event, 'v1')}>Learn More</span></p>
                  </span>
                </div>
                <div className='formCommon' onClick={() => this.navigateNew('v2')}>
                  <img src={HybridProfileIcon} alt='EDM/Hybrid Profile' className='text-center profileImg' />
                  <span className='tw-float-right profileDesc'>
                    <h5>Advanced Data Profile</h5>
                    <p>Create an advanced data profile with data patterns and advanced detection methods. This data profile cannot be edited on the Panorama management server. <span className='btn-link' onClick={(event) => this.learnMore(event, 'v2')}>Learn More</span></p>
                  </span>
                </div>
                <div className='formCommon' onClick={() => this.navigateNew('multi')}>
                  <img src={MultiProfileIcon} alt='Multi Profile' className='text-center profileImg' />
                  <span className='tw-float-right profileDesc'>
                    <h5>With Nested Data Profiles </h5>
                    <p>Create a data profile containing multiple nested data profiles. This data profile cannot be edited on the Panorama management server. <span className='btn-link' onClick={(event) => this.learnMore(event, 'multi')}>Learn More</span></p>
                  </span>
                </div>
                <br />
              </div>
            </ModalBody>
            <ModalFooter>
              <ModalCloseButton>{translate('actions.cancel')}</ModalCloseButton>
            </ModalFooter>
          </Modal>
        </IntlProvider>
      </div>
      </IntlProvider>
    );
  }
}

DataProfileTable.propTypes = {
  lookupURL: PropTypes.string.isRequired,
  lookupMethod: PropTypes.string,
  columns: PropTypes.array,
  datasets: PropTypes.shape({}),
  dataPatterns: PropTypes.shape({}),
  match: PropTypes.any,
  history: PropTypes.shape({
    path: PropTypes.string,
    push: PropTypes.func
  }),
  userRole: PropTypes.string,
};

DataProfileTable.defaultProps = {
  lookupMethod: 'GET',
  columns: [],
  datasets: {},
  dataPatterns: {},
  match: undefined,
  history: null,
  userRole: 'READ_ONLY',
}

export default DataProfileTable;
