import React from 'react';
import axios from 'axios';
import { IntlProvider } from 'react-intl';

import MockAdapter from 'axios-mock-adapter';
import { shallow } from 'enzyme';
import {render} from '@testing-library/react';
import { act } from 'react-dom/test-utils';

import DataTransfer from '../DataTransfer';
import { ALLOW, BLOCK } from '../../constants';

const data = {
  file_max_latency: 6,
  file_action_on_max_latency: BLOCK,
  file_max_size: 6,
  file_action_on_max_size: BLOCK,
  file_log_files_not_scanned: true,
  nonfile_enabled: true,
  nonfile_max_latency: 5,
  nonfile_action_on_max_latency: BLOCK,
  nonfile_min_size: 250,
  nonfile_max_size: 250,
  nonfile_action_on_max_size: BLOCK,
  nonfile_log_files_not_scanned: true,
  action_on_error: ALLOW
};

const axiosMock = new MockAdapter(axios);

describe('<DataTransfer />', () => {
  beforeEach(() => {
    axiosMock.reset();
  });

  it('should match snapshot', () => {
    const wrapper = shallow(<DataTransfer userRole='READ_WRITE' nebulaFlag />);
    expect(wrapper).toMatchSnapshot();
  });

  it('should call correct api', async() => {
    axiosMock.onGet().reply(function (config) {
      expect(config.url).toBe('/v1/dlp-ui/data-filtering-profile/setting');

      return [
        200,
        data
      ];
    });

    await act(async () => {
      render(
        <IntlProvider locale='en'>
          <DataTransfer userRole='READ_WRITE' nebulaFlag />
        </IntlProvider>
      );
    });
  });
});