import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button } from '@panwds/react-ui';
import { MoreIcon } from '@panwds/icons';
import Popup from 'reactjs-popup';

import translate from '../../helpers/translate';
import UserForm from './UserForm';

function t(scope, options) {
  return translate(`incidents.index.${scope}`, options);
}

const AssignMenu = ({users, closePopup, saveUserCallback, deleteUserCallback, userSelected}) => {
  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState();
  const [filteredUsers, setFilteredUsers] = useState(users.filter(u => u.status === 'ACTIVE'));

  const filterUsers = (searchTerm = '') => {
    const filtered = users.filter(user => {
      return user.status === 'ACTIVE' && `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredUsers(filtered);
  }

  const processSearch = (event) => {
    const input = event.target.value;
    setSearch(input);
    filterUsers(input);
  }

  const selectUser = (event, user) => {
    if (!(event.target instanceof (SVGElement))) {
      setSelectedUser(user);
      userSelected(user);
      closePopup();
    }
  }

  const saveUser = async(user) => {
    return saveUserCallback(user);
  }

  const deleteUser = async(user) => {
    return deleteUserCallback(user);
  }

  const userForm = (user) => {
    return (closePopup) =>
      (<UserForm user={user} closePopup={closePopup} deleteUser={deleteUser} saveUser={saveUser}/>);
  }

  const newUserForm = () => {
    const names = search.split(' ');
    return userForm({
      firstName: names[0],
      lastName: names.length > 1? names[1] : '',
      emailAddress: ''
    });
  }

  return (
    <div className='assignMenu'>
      <input placeholder={t('search_or_create_user')} className='searchBox form-control tw-mb-2' value={search} onChange={processSearch}/>
      {filteredUsers.length > 0 &&
        <ul className='listHolder'>
          {
            filteredUsers.map(user => (
              <li className='listItem user tw-items-center' key={user.emailAddress} onClick={ () => selectUser(event, user) }>
                <input className="tw-m-1 tw-mr-4" type='checkbox' value={user.emailAddress} checked={user === selectedUser} onChange={() => {}}/>
                <div className='itemDetails'>
                  <div className='name'>{user.firstName} {user.lastName}</div>
                  <div>{user.emailAddress}</div>
                </div>
                <Popup
                  trigger={<span><MoreIcon className='actionBtn tw-mt-1'/></span>}
                  position='right top'
                  on='click'
                  arrow={false}
                  closeOnEscape
                  >
                    {userForm(user)}
                </Popup>
              </li>
            ))
          }
        </ul>
      }
      {
        filteredUsers.length === 0 && search &&
        <div className='createPane tw-flex'>
          <span className='userToAdd tw-mr-auto tw-ml-2'>{search}</span>
          <Popup
            key='create-user'
            trigger={<Button appearance='clear'>{t('create_user')}</Button>}
            position='right top'
            on='click'
            arrow={false}
            closeOnEscape
            contentStype='margin-left:16px;'
            >
              {newUserForm()}
          </Popup>
        </div>
      }
    </div>
  );
}

AssignMenu.propTypes = {
  users: PropTypes.array.isRequired,
  closePopup: PropTypes.func.isRequired,
  saveUserCallback: PropTypes.func.isRequired,
  deleteUserCallback: PropTypes.func.isRequired,
  userSelected: PropTypes.func.isRequired
}

export default AssignMenu;