// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataProfileDetails> stays empty without profile 1`] = `
<div
  className="tableDetailsView"
  data-test-id="tableDetailsView"
>
  <div
    className="detailHeader detailHeader"
  >
    <span
      className="detailTitle"
    />
    <Button
      addClassName="close tw-float-right"
      appearance="secondary"
      aria-hidden="true"
      buttonType="default"
      data-dismiss="modal"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    />
  </div>
</div>
`;

exports[`<DataProfileDetails> v1 shows details when given profile 1`] = `
<div
  className="tableDetailsView"
  data-test-id="tableDetailsView"
>
  <div
    className="detailHeader detailHeader"
  >
    <span
      className="detailTitle"
    >
      test
    </span>
    <Button
      addClassName="close tw-float-right"
      appearance="secondary"
      aria-hidden="true"
      buttonType="default"
      data-dismiss="modal"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    />
  </div>
  <div>
    <div
      style={
        Object {
          "padding": "15px",
        }
      }
    >
      <br />
      <div
        className="detailSubtitle active tab-details"
        onClick={[Function]}
      >
        data_profiles.view.details
      </div>
      <div
        className="detailSubtitle false ml5 tab-preview"
        onClick={[Function]}
      >
        data_profiles.view.preview
      </div>
      <hr
        className="divider"
      />
      <table
        className="table table-borderless table-condensed"
      >
        <tbody>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.name
            </td>
            <td>
              test
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.type
            </td>
            <td />
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.mode
            </td>
            <td />
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.updated_by
            </td>
            <td>
              undefined + {"platform":true}
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.updated_at
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
`;

exports[`<DataProfileDetails> v1 shows preview when preview state and given profile 1`] = `
<div
  className="tableDetailsView"
  data-test-id="tableDetailsView"
>
  <div
    className="detailHeader detailHeader"
  >
    <span
      className="detailTitle"
    >
      test
    </span>
    <Button
      addClassName="close tw-float-right"
      appearance="secondary"
      aria-hidden="true"
      buttonType="default"
      data-dismiss="modal"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    />
  </div>
  <div>
    <div
      style={
        Object {
          "padding": "15px",
        }
      }
    >
      <div
        className="actionBtns"
      >
        <Button
          addClassName="edit"
          appearance="secondary"
          aria-label="edit"
          buttonType="default"
          disabled={false}
          icon={
            <FaPen
              className="defaultText"
            />
          }
          isMenu={false}
          onClick={[Function]}
          size="md"
        />
        <Button
          addClassName="clone"
          appearance="secondary"
          aria-label="clone"
          buttonType="default"
          disabled={false}
          icon={
            <FaCopy
              className="defaultText"
            />
          }
          isMenu={false}
          onClick={[Function]}
          size="md"
        />
      </div>
      <br />
      <div
        className="detailSubtitle false tab-details"
        onClick={[Function]}
      >
        data_profiles.view.details
      </div>
      <div
        className="detailSubtitle active ml5 tab-preview"
        onClick={[Function]}
      >
        data_profiles.view.preview
      </div>
      <hr
        className="divider"
      />
      <div
        className="preview"
      >
        <div
          className="headerLabel"
        >
          data_profiles.view.primary_rule
        </div>
        <div
          className="bracketPreview bracketPreview"
        >
          <DataProfileBracketPreview
            advanced={true}
            dataPatternRule={
              Object {
                "conditions": Array [
                  Object {
                    "operators": Array [
                      "or",
                    ],
                    "rule_items": Array [
                      Object {
                        "confidence_level": "high",
                        "id": "1234560",
                        "name": "SSN",
                        "occurrence_high": 24,
                        "occurrence_low": 4,
                        "occurrence_operator_type": "between",
                        "supported_confidence_levels": Array [
                          "high",
                          "low",
                        ],
                        "version": 1,
                      },
                    ],
                  },
                ],
                "operators": Array [],
              }
            }
            datasets={Array []}
            expTree={Object {}}
            multi={false}
            profiles={Array []}
            schema={1}
          />
        </div>
        <div
          className="headerLabel"
        >
          data_profiles.view.secondary_rule
        </div>
        <div
          className="bracketPreview"
        >
          <DataProfileBracketPreview
            advanced={true}
            dataPatternRule={Object {}}
            datasets={Array []}
            expTree={Object {}}
            multi={false}
            profiles={Array []}
            schema={1}
          />
        </div>
      </div>
    </div>
  </div>
</div>
`;

exports[`<DataProfileDetails> v2 shows details when given profile 1`] = `
<div
  className="tableDetailsView"
  data-test-id="tableDetailsView"
>
  <div
    className="detailHeader detailHeader"
  >
    <span
      className="detailTitle"
    >
      test
    </span>
    <Button
      addClassName="close tw-float-right"
      appearance="secondary"
      aria-hidden="true"
      buttonType="default"
      data-dismiss="modal"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    />
  </div>
  <div>
    <div
      style={
        Object {
          "padding": "15px",
        }
      }
    >
      <br />
      <div
        className="detailSubtitle active tab-details"
        onClick={[Function]}
      >
        data_profiles.view.details
      </div>
      <div
        className="detailSubtitle false ml5 tab-preview"
        onClick={[Function]}
      >
        data_profiles.view.preview
      </div>
      <hr
        className="divider"
      />
      <table
        className="table table-borderless table-condensed"
      >
        <tbody>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.name
            </td>
            <td>
              test
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.type
            </td>
            <td />
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.mode
            </td>
            <td />
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.updated_by
            </td>
            <td>
              undefined + {"platform":true}
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.updated_at
            </td>
            <td />
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.patterns
            </td>
            <td />
          </tr>
          <tr>
            <td
              className="form-label"
            >
              data_profiles.view.datasets
            </td>
            <td>
              Dataset B, Dataset A
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
`;

exports[`<DataProfileDetails> v2 shows preview when preview state and given profile 1`] = `
<div
  className="tableDetailsView"
  data-test-id="tableDetailsView"
>
  <div
    className="detailHeader detailHeader"
  >
    <span
      className="detailTitle"
    >
      test
    </span>
    <Button
      addClassName="close tw-float-right"
      appearance="secondary"
      aria-hidden="true"
      buttonType="default"
      data-dismiss="modal"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    />
  </div>
  <div>
    <div
      style={
        Object {
          "padding": "15px",
        }
      }
    >
      <div
        className="actionBtns"
      >
        <Button
          addClassName="edit"
          appearance="secondary"
          aria-label="edit"
          buttonType="default"
          disabled={false}
          icon={
            <FaPen
              className="defaultText"
            />
          }
          isMenu={false}
          onClick={[Function]}
          size="md"
        />
        <Button
          addClassName="clone"
          appearance="secondary"
          aria-label="clone"
          buttonType="default"
          disabled={false}
          icon={
            <FaCopy
              className="defaultText"
            />
          }
          isMenu={false}
          onClick={[Function]}
          size="md"
        />
      </div>
      <br />
      <div
        className="detailSubtitle false tab-details"
        onClick={[Function]}
      >
        data_profiles.view.details
      </div>
      <div
        className="detailSubtitle active ml5 tab-preview"
        onClick={[Function]}
      >
        data_profiles.view.preview
      </div>
      <hr
        className="divider"
      />
      <div
        className="preview"
      >
        <div
          className="headerLabel"
        >
          data_profiles.view.primary_rule
        </div>
        <div
          className="bracketPreview bracketPreview"
        >
          <DataProfileBracketPreview
            advanced={true}
            dataPatternRule={Object {}}
            datasets={Array []}
            expTree={
              Object {
                "operator_type": "and",
                "rule_item": null,
                "sub_expressions": Array [
                  Object {
                    "operator_type": null,
                    "rule_item": Object {
                      "confidence_level": "high",
                      "detection_technique": "regex",
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "low",
                      ],
                      "version": 1,
                    },
                    "sub_expressions": Array [],
                  },
                  Object {
                    "operator_type": "or",
                    "rule_item": null,
                    "sub_expressions": Array [
                      Object {
                        "operator_type": "or",
                        "rule_item": null,
                        "sub_expressions": Array [
                          Object {
                            "operator_type": null,
                            "rule_item": Object {
                              "confidence_level": "high",
                              "detection_technique": "regex",
                              "id": "1234561",
                              "name": "CCN",
                              "occurrence_count": null,
                              "occurrence_operator_type": "any",
                              "supported_confidence_levels": Array [
                                "high",
                                "low",
                              ],
                              "version": 1,
                            },
                            "sub_expressions": Array [],
                          },
                          Object {
                            "operator_type": null,
                            "rule_item": Object {
                              "detection_technique": "edm",
                              "edm_dataset_id": "1234565",
                              "name": "Dataset B",
                              "occurrence_count": null,
                              "occurrence_high": null,
                              "occurrence_low": null,
                              "occurrence_operator_type": "any",
                              "primary_fields": Array [
                                "col-1",
                                "col-2",
                              ],
                              "primary_match_any_count": null,
                              "primary_match_criteria": "all",
                              "secondary_fields": Array [],
                              "secondary_match_any_count": null,
                              "secondary_match_criteria": "all",
                            },
                            "sub_expressions": Array [],
                          },
                        ],
                      },
                    ],
                  },
                  Object {
                    "operator_type": "not",
                    "rule_item": null,
                    "sub_expressions": Array [
                      Object {
                        "operator_type": null,
                        "rule_item": Object {
                          "detection_technique": "edm",
                          "edm_dataset_id": "1234564",
                          "name": "Dataset A",
                          "occurrence_count": null,
                          "occurrence_high": null,
                          "occurrence_low": null,
                          "occurrence_operator_type": "any",
                          "primary_fields": Array [
                            "col-1",
                            "col-3",
                          ],
                          "primary_match_any_count": null,
                          "primary_match_criteria": "all",
                          "secondary_fields": Array [],
                          "secondary_match_any_count": null,
                          "secondary_match_criteria": "all",
                        },
                        "sub_expressions": Array [],
                      },
                    ],
                  },
                ],
              }
            }
            multi={false}
            profiles={Array []}
            schema={2}
          />
        </div>
        <div
          className="headerLabel"
        >
          data_profiles.view.secondary_rule
        </div>
        <div
          className="bracketPreview"
        >
          <DataProfileBracketPreview
            advanced={true}
            dataPatternRule={Object {}}
            datasets={Array []}
            expTree={
              Object {
                "sub_expressions": Array [],
              }
            }
            multi={false}
            profiles={Array []}
            schema={2}
          />
        </div>
      </div>
    </div>
  </div>
</div>
`;
