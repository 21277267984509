import React from 'react';
import '@testing-library/jest-dom/extend-expect';
import { shallow } from 'enzyme';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import sinon from 'sinon';

import TestDrawer from './TestDrawer';
import FileUpload from './components/FileUpload';

import {
  FILE_SIZE_FINGERPRINT
} from '../constants';

const axiosMock = new MockAdapter(axios);

describe('<TestDrawer />', () => {
  let sandbox, wrapper;
  const baseProps = {
    apiEndpoint: '/apiEndpoint',
    category: 'category',
    fingerprintObjectPath: 'fingerprintObjectPath',
    id: 666,
    name: 'name'
  };
  const mockView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return shallow(<TestDrawer {...props} />);
  };

  beforeEach(() => {
    axiosMock.reset();
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    sandbox.restore();
    jest.resetAllMocks();
  });

  it('should match snapshot when closed', () => {
    wrapper = mockView();

    expect(wrapper).toMatchSnapshot();
  });

  it('should match snapshot when open', () => {
    wrapper = mockView({ isOpen: true });

    expect(wrapper).toMatchSnapshot();
  });

  it('should render with success test', async () => {
    axiosMock.onPost().reply(config => {
      expect(config.url).toBe('/apiEndpoint/666');

      return [200, { score: 0.666 }]
    });

    wrapper = mockView({ isOpen: true });

    wrapper.find(FileUpload).invoke('onUpload')([
      {
        name: 'name 0',
        size: FILE_SIZE_FINGERPRINT - 1
      }
    ]);
  });

  it('should render with failure test', async () => {
    axiosMock.onPost().reply(config => {
      expect(config.url).toBe('/apiEndpoint/666');

      return [500, { score: 0.666 }]
    });

    wrapper = mockView({ isOpen: true });

    wrapper.find(FileUpload).invoke('onUpload')([
      {
        name: 'name size big',
        size: FILE_SIZE_FINGERPRINT
      }
    ]);

    wrapper.find(FileUpload).invoke('onUpload')([
      {
        name: 'name size big',
        size: FILE_SIZE_FINGERPRINT + 1
      }
    ]);

    wrapper.find(FileUpload).invoke('onUpload')([
      {
        name: 'name 0',
        size: FILE_SIZE_FINGERPRINT
      },
      {
        name: 'name 1',
        size: FILE_SIZE_FINGERPRINT
      },
      {
        name: 'name 2',
        size: FILE_SIZE_FINGERPRINT
      },
      {
        name: 'name 3',
        size: FILE_SIZE_FINGERPRINT
      },
      {
        name: 'name 4',
        size: FILE_SIZE_FINGERPRINT
      },
      {
        name: 'name 5',
        size: FILE_SIZE_FINGERPRINT
      }
    ]);
  });
});