/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import axios from 'axios';
import { Button, Tooltip, LoadingPage } from '@panwds/react-ui';
import { FaTimes, FaPlus, FaExclamationCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import translate from '../../helpers/translate';
import { FILE_PROPERTY } from './DataPatternTable';
import withActions from './withActions';
import SingleSelect from '../SingleSelect';

import {
  FILE_PROPERTY_EXTENDED,
  FILE_PROPERTY_CUSTOM
} from '../constants';

function localizeString(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}
const FilePropertyPatternForm =({
  lookupFilePropertiesURL,
  pattern,
  updateState,
  doValidation
}) => {
  const [ loading, setLoading ] = useState(true);
  const [ filePropertyTypesMap, setFilePropertyTypesMap ] = useState({
    'extended-properties': {
      showName: true,
      canRepeat: true
    },
    'custom': {
      showName: true,
      canRepeat: true
    }
  });
  const [ itemsFilePropertyTypes, setItemsFilePropertyTypes ] = useState([
    {
      value: localizeString(FILE_PROPERTY_EXTENDED),
      key: FILE_PROPERTY_EXTENDED,
      disabled: false
    },
    {
      value: localizeString(FILE_PROPERTY_CUSTOM),
      key: FILE_PROPERTY_CUSTOM,
      disabled: false
    }
  ]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    const metadataCriteria = [ ...pattern.metadataCriteria ];
    let valid = false;

    if (doValidation) {
      valid =
        metadataCriteria.length && metadataCriteria.every(
          item => isValidInputName(item.type, item.name) && item.value
        );
    }

    updateState({
      valid
    });
  }, [ pattern, doValidation ]);

  const fetchDocuments = () => {
    axios.get(lookupFilePropertiesURL)
      .then(response => {
        const filePropertyTypesData = response?.data || [];
        const updatedFilePropertyTypesMap = { ...filePropertyTypesMap };
        const updatedItemsFilePropertyTypes = [];

        filePropertyTypesData.forEach(fileProperty => {
          updatedFilePropertyTypesMap[fileProperty?.value] = {
            showName: fileProperty?.displayName,
            canRepeat: fileProperty?.canBeRepeated
          };

          if (
            fileProperty?.value !== FILE_PROPERTY_EXTENDED &&
            fileProperty?.value !== FILE_PROPERTY_CUSTOM
          ) {
            updatedItemsFilePropertyTypes.push({
              value: localizeString(fileProperty?.value),
              key: fileProperty?.value,
              disabled: false
            })
          }
        });

        setFilePropertyTypesMap(updatedFilePropertyTypesMap);
        setItemsFilePropertyTypes(updatedItemsFilePropertyTypes.concat(itemsFilePropertyTypes));
      })
      .catch(err => {
        console.error('Failed to fetch supported file properties', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filePropertyTypeChanged = (event, index) => {
    const metadataCriteria = [ ...pattern.metadataCriteria ];
    const updatedItemsFilePropertyTypes = [ ...itemsFilePropertyTypes ];
    const prevFilePropertyType = metadataCriteria[index].type;

    metadataCriteria[index].type = event?.selectedItem?.key;

    if (!filePropertyTypesMap[event?.selectedItem?.key].showName) {
      delete metadataCriteria[index]?.name;
    }

    updatedItemsFilePropertyTypes.forEach(item => {
      if (
        item.key === event?.selectedItem?.key &&
        !filePropertyTypesMap[event?.selectedItem?.key].canRepeat
      ) {
        item.disabled = true;
      } else if (item.key === prevFilePropertyType) {
        item.disabled = false;
      }
    });

    updateState({
      pattern: {
        ...pattern,
        metadataCriteria
      }
    });

    setItemsFilePropertyTypes(updatedItemsFilePropertyTypes);
  };

  const filePropertyNameChanged = (event, index) => {
    const metadataCriteria = [ ...pattern.metadataCriteria ];

    metadataCriteria[index].name = event.target.value;

    updateState({
      pattern: {
        ...pattern,
        metadataCriteria
      }
    });
  }

  const filePropertyValueChanged = (event, index) => {
    const metadataCriteria = [ ...pattern.metadataCriteria ];

    metadataCriteria[index].value = event.target.value;

    updateState({
      pattern: {
        ...pattern,
        metadataCriteria
      }
    });
  }

  const addFilePropertyRow = () => {
    const metadataCriteria = [...pattern.metadataCriteria, {}];

    updateState({
        pattern: {
        ...pattern,
        metadataCriteria
      }
    });
  }

  const deleteFilePropertyRow = (index) => {
    const metadataCriteria = [ ...pattern.metadataCriteria ];
    const updatedItemsFilePropertyTypes = [ ...itemsFilePropertyTypes ];
    const prevFilePropertyType = metadataCriteria[index].type;

    metadataCriteria.splice(index, 1);

    updatedItemsFilePropertyTypes.forEach(item => {
      if (item.key === prevFilePropertyType) {
        item.disabled = false;
      }
    });

    updateState({pattern: {
        ...pattern,
        metadataCriteria
      }
    });

    setItemsFilePropertyTypes(updatedItemsFilePropertyTypes);
  }

  const isValidInputName = (type, value) => {
    if (!filePropertyTypesMap[type]?.showName) {
      return true;
    }

    return !!value;
  };

  return (
    <IntlProvider locale='en'>
      <div className='definition-section file-properties'>
        { loading &&
          <div className='loading-wrapper'>
            <LoadingPage />
          </div>
        }

        { !loading &&
          <div className='data-pattern-form'>
            <div className='file-properties-grid'>
              <div className='fp-row row-header tw-flex'>
                <span className='fp-type'>
                  {localizeString('file_property_type')}
                  <Tooltip label={localizeString('file_property_hint')} hasTriangle>
                    <div className='icon-wrapper'><FaExclamationCircle className='info-icon tw-ml-1' /></div>
                  </Tooltip>
                </span>
                <span className='fp-name'>{localizeString('name')}</span>
                <span className='fp-value'>{localizeString('value')}</span>
              </div>

              { pattern?.metadataCriteria?.map((p, i) => (
                <div className='fp-row tw-flex' key={`${p.type}-${i}`}>
                  <span className='fp-type'>
                    <SingleSelect
                      items={itemsFilePropertyTypes} selectedKey={p.type}
                      onChange={(event) => filePropertyTypeChanged(event, i)}
                    />
                  </span>
                  <span className='fp-name'>
                    <input
                      className={`form-control file-property-name ${doValidation && !isValidInputName(p.type, p.name)? 'has-error': ''}`}
                      defaultValue={p.name}
                      onChange={(event) => filePropertyNameChanged(event, i)}
                      disabled={!filePropertyTypesMap[p?.type]?.showName}
                    />
                  </span>
                  <span className='fp-value'>
                    <input
                    className={`form-control file-property-value ${doValidation && !p.value? 'has-error': ''}`}
                    defaultValue={p.value}
                    onChange={(event) => filePropertyValueChanged(event, i)}
                  />
                  </span>
                  <span className='fp-action tw-inline-flex tw-items-center'>
                    <Button
                      addClassName='delete-row'
                      disabled={i===0}
                      aria-label={translate('actions.delete')}
                      icon={<FaTimes/>}
                      onClick={() => deleteFilePropertyRow(i)}
                      appearance='tertiary-clear'
                    />
                  </span>
                </div>
              ))
            }
            </div>

            <div className='action-bar tw-p-4'>
              <Button
                icon={<FaPlus/>}
                appearance='clear'
                onClick={addFilePropertyRow}
              >
                {localizeString('add_file_property')}
              </Button>
            </div>
          </div>
        }
      </div>
    </IntlProvider>
  );
}

FilePropertyPatternForm.propTypes = {
  lookupFilePropertiesURL: PropTypes.string.isRequired,
  pattern: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    metadataCriteria: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string
    })),
    root_type: PropTypes.string,
    detection_technique: PropTypes.string
  }).isRequired,
  updateState: PropTypes.func.isRequired,
  doValidation: PropTypes.bool.isRequired,
}

export default withActions(FilePropertyPatternForm, FILE_PROPERTY);