import React from 'react';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`audit_logs.columns.${scope}`, options);
}

function eventLabel(log, updateState) {
  if (log.event?.toLowerCase() === 'update') {
    return (
      <span>
        {t(log.type?.toLowerCase())} <span onClick={() => log.object ? updateState({ viewingObject: log.object, viewingType: log.type?.toLowerCase()}) : {} } className={log.object ? 'btn-link' : 'helpText'}>{`[${log.name}]`}</span> {t(log.event?.toLowerCase())}
        <span onClick={() => updateState({ viewing: log }) } className='ml5 btn-link'>View Details</span>
      </span>
    );
  }
  if (log.event?.toLowerCase() === 'delete') {
    return (
      <span>
        {`${t(log.type?.toLowerCase())}${log.name?.split(', ').length > 1 ? 's' : ''}`} <span className='helpText'>{log.name}</span> {t(log.event?.toLowerCase())}
      </span>
    );
  }
  return (
    <span>
      {`${t(log.type?.toLowerCase())}${log.name?.split(', ').length > 1 ? 's' : ''}`} <span onClick={() => log.object ? updateState({ viewingObject: log.object, viewingType: log.type?.toLowerCase()}) : {} } className={log.object ? 'btn-link' : 'helpText'}>{log.name}</span> {t(log.event?.toLowerCase())}
    </span>
  )
}

export const logDetails = (log, profiles, patterns, filteringProfiles, updateState) => {
  if (log.event?.toLowerCase() === 'create') {
    log.name = JSON.parse(log.changes)[0].new
  }
  if (log.event?.toLowerCase() === 'delete') {
    log.name = JSON.parse(log.changes)[0].old
  }
  if (log.type?.toLowerCase() === 'dataprofile') {
    log.object = log.object || profiles[log.objectId];
    log.name = log.name || log.object?.name || 'Not Found';
  }
  if (log.type?.toLowerCase() === 'datapattern') {
    log.object = log.object || patterns[log.objectId];
    log.name = log.name || log.object?.name || 'Not Found';
  }
  if (log.type?.toLowerCase() === 'datafilteringprofile') {
    log.object = log.object || filteringProfiles[log.objectId];
    log.name = log.name || log.object?.name || 'Not Found';
  }
  log.changes = JSON.parse(log.changes);
  if (log.event?.toLowerCase() === 'update' && !log.object) {
    log.name = JSON.parse(log.changes.new).name;
  }
  log.eventLabel = eventLabel(log, updateState);
  return log;
}

export default (log, profiles, patterns, filteringProfiles, updateState) => logDetails(log, profiles, patterns, filteringProfiles, updateState);
