import React from 'react';
import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import Modal from './Modal';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<Modal>', () => {
  let wrapper;
  beforeEach(() => {
    wrapper = shallow(
      <Modal
        compId="download_incidents_csv_modal"
        linkElement={() => [
          <i className="glyphicon glyphicon-save downloadIcon" key="i-tag" />,
          'EXPORT',
        ]}
        modalHeader="Modal Title"
        modalBody={<p>Are you sure?</p>}
        actionElement={<button type="button">Download</button>}
      />,
    );
  });

  it('Component exists after loading', () => {
    expect(wrapper.exists()).toBe(true);
  });

  it('Component to have basic classes', () => {
    expect(wrapper.find('.modalComponent')).toHaveLength(1);
    expect(wrapper.find('.fade')).toHaveLength(1);
    expect(wrapper.find('.modal')).toHaveLength(1);
    expect(wrapper.find('.downloadIcon')).toHaveLength(1);
    expect(wrapper.find('.modal-dialog')).toHaveLength(1);
    expect(wrapper.find('.modal-content')).toHaveLength(1);
    expect(wrapper.find('.modal-header')).toHaveLength(1);
    expect(wrapper.find('.modal-body')).toHaveLength(1);
    expect(wrapper.find('.modal-footer')).toHaveLength(1);
  });

  it('Component to have expected text', () => {
    expect(wrapper.find('.modal-title').text()).toBe('Modal Title');
    expect(wrapper.find('.modal-body').text()).toBe('Are you sure?');
    expect(wrapper.find('.modalComponent>a').text()).toBe('EXPORT');
  });

  describe('with custom cancelText', () => {
    const cancelText = 'Custom Text';

    beforeEach(() => {
      wrapper.setProps({ cancelText })
    });

    it('matches a snapshot', () => {
      expect(wrapper).toMatchSnapshot();
    });
  });
});
