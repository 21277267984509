/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  LoadingButton,
  Input,
  Radio,
  RadioGroup,
  Checkbox
} from '@panwds/react-ui';

import translate from '../../../helpers/translate';
import toastWrapper from '../../../helpers/toastr';
import SpinnerIcon from '../../../../assets/images/spinner.gif';
import {
  ALLOW,
  BLOCK,
  NON_FILE_SETTINGS_TYPE
} from '../../constants';

const t = (scope, options) => translate(`data_transfer.non_file_based.${scope}`, options);

const NonFileBasedView = (props) => {
  const [loading, setLoading] = useState(props.loading);
  const [enableNonFile, setEnableNonFile] = useState(props.enableNonFile);
  const [maxLatency, setMaxLatency] = useState(props.maxLatency);
  const [actionMaxLatencyNonFile, setActionMaxLatencyNonFile] = useState(props.actionMaxLatencyNonFile);
  const [minSize, setMinSize] = useState(props.minSize);
  const [maxSize, setMaxSize] = useState(props.maxSize);
  const [actionMaxSizeNonFile, setActionMaxSizeNonFile] = useState(props.actionMaxSizeNonFile);
  const [allowLogData, setAllowLogData] = useState(props.allowLogData);
  const [saveButtonStatus, setSaveButtonStatus] = useState({ loading: false });

  useEffect(() => {
    setLoading(props.loading);
    setEnableNonFile(props.enableNonFile);
    setMaxLatency(props.maxLatency);
    setActionMaxLatencyNonFile(props.actionMaxLatencyNonFile);
    setMinSize(props.minSize);
    setMaxSize(props.maxSize);
    setActionMaxSizeNonFile(props.actionMaxSizeNonFile);
    setAllowLogData(props.allowLogData);
  }, [props]);

  const saveData = async () => {
    setSaveButtonStatus({ loading: true });

    try{
      const { data } = await axios.put('/v1/dlp-ui/data-filtering-profile/setting', {
        type: NON_FILE_SETTINGS_TYPE,
        nonfile_enabled: enableNonFile,
        nonfile_max_latency: maxLatency,
        nonfile_action_on_max_latency: actionMaxLatencyNonFile,
        nonfile_min_size: minSize,
        nonfile_max_size: maxSize,
        nonfile_action_on_max_size: actionMaxSizeNonFile,
        nonfile_log_files_not_scanned: allowLogData
      });

      if (data) {
        props.updateSettings({
          enableNonFile: data.nonfile_enabled,
          maxLatency: data.nonfile_max_latency,
          actionMaxLatencyNonFile: data.nonfile_action_on_max_latency,
          minSize: data.nonfile_min_size,
          maxSize: data.nonfile_max_size,
          actionMaxSizeNonFile: data.nonfile_action_on_max_size,
          allowLogData: data.nonfile_log_files_not_scanned
        });
      }

      toastWrapper.success(t('save_success'));
    } catch (error) {
      toastWrapper.error(t('server_error'));
    } finally {
      setSaveButtonStatus({ loading: false });
    }
  };

  const isFormDataValid = () => (
    Number.isInteger(Number(maxLatency)) &&
    Number.isInteger(Number(minSize)) &&
    Number.isInteger(Number(maxSize)) &&
    (maxLatency > 0 && maxLatency < 31) &&
    (minSize > 239 && minSize < 4001) &&
    (maxSize > 0 && maxSize < 501) &&
    (minSize < maxSize*1024)
  );

  const isFormUpdated = () => (
    (enableNonFile !== props.enableNonFile) ||
    (Number(maxLatency) !== props.maxLatency) ||
    (actionMaxLatencyNonFile !== props.actionMaxLatencyNonFile) ||
    (Number(minSize) !== props.minSize) ||
    (Number(maxSize) !== props.maxSize) ||
    (actionMaxSizeNonFile !== props.actionMaxSizeNonFile) ||
    (allowLogData !== props.allowLogData)
  );

  const isSaveEnabled = () => (
    isFormDataValid() && isFormUpdated()
  );

  return (
    <div className='settings-container non-file-settings'>
      { loading &&
        <div className='empty text-center'>
          <img id='loader' src={SpinnerIcon} alt='loading' />
          <br /><br />
          <p>{t('loading')}</p>
        </div>
      }

      { !loading &&
        <>
          <div className='header-section'>
            <h5>{t('title')}</h5>
            <div className='help-text'>{t('description')}</div>
          </div>

          <div className='data-section'>
            <div className='left-col'>
              <div className='text-label max-latency'>{t('max_latency')}</div>
              <div className='text-label action-latency'>{t('max_latency_action')}</div>
              <div className='text-label min-data'>{t('min_data_size')}</div>
              <div className='text-label max-data'>{t('max_data_size')}</div>
              <div className='text-label action-max'>{t('max_data_action')}</div>
            </div>

            <div className='right-col'>
              <div className='enable-dlp-wrapper'>
                <Checkbox
                  checked={enableNonFile}
                  onChange={() => setEnableNonFile(!enableNonFile)}
                  disabled={props.userRole !== 'READ_WRITE'}
                >
                  {t('enable_non_file')}
                </Checkbox>
              </div>

              <Input
                value={maxLatency}
                addClassName='max-latency-input'
                onChange={(e) => {
                  setMaxLatency(e.currentTarget.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              />
              <div className='text-data max-latency-placeholder'>{t('max_latency_desc')}</div>

              <RadioGroup
                name='actionMaxLatencyNonFile'
                value={actionMaxLatencyNonFile}
                horizontal
                className='radio-horizontal'
                onChange={(event) => {
                  setActionMaxLatencyNonFile(event.target.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              >
                <Radio value={ALLOW}>{t('allow')}</Radio>
                <Radio value={BLOCK}>{t('block')}</Radio>
              </RadioGroup>

              <Input
                value={minSize}
                addClassName='min-data-input'
                onChange={(e) => {
                  setMinSize(e.currentTarget.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              />
              <div className='text-data min-data-placeholder'>{t('min_data_size_desc')}</div>

              <Input
                value={maxSize}
                addClassName='max-data-input'
                onChange={(e) => {
                  setMaxSize(e.currentTarget.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              />
              <div className='text-data max-data-placeholder'>{t('max_data_size_desc')}</div>

              <RadioGroup
                name='actionMaxSizeNonFile'
                value={actionMaxSizeNonFile}
                horizontal
                className='radio-horizontal'
                onChange={(event) => {
                  setActionMaxSizeNonFile(event.target.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              >
                <Radio value={ALLOW}>{t('allow')}</Radio>
                <Radio value={BLOCK}>{t('block')}</Radio>
              </RadioGroup>

              <div className='checkbox-wrapper'>
                <Checkbox
                  checked={allowLogData}
                  onChange={() => setAllowLogData(!allowLogData)}
                  disabled={props.userRole !== 'READ_WRITE'}
                >
                  {t('log_data_not_scanned')}
                </Checkbox>
              </div>
            </div>
          </div>

          <div className='footer-section'>
            {props.userRole === 'READ_WRITE' &&
              <LoadingButton
                dataMetrics='loading-primary-button'
                onClickCallback={saveData}
                dataResult={saveButtonStatus}
                appearance='primary'
                disabled={!isSaveEnabled()}
              >
                {t('save')}
              </LoadingButton>
            }
          </div>
        </>
      }
    </div>
  );
}

NonFileBasedView.propTypes = {
  loading: PropTypes.bool.isRequired,
  enableNonFile: PropTypes.bool.isRequired,
  maxLatency: PropTypes.number.isRequired,
  actionMaxLatencyNonFile: PropTypes.string.isRequired,
  minSize: PropTypes.number.isRequired,
  maxSize: PropTypes.number.isRequired,
  actionMaxSizeNonFile: PropTypes.string.isRequired,
  allowLogData: PropTypes.bool.isRequired,
  updateSettings: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired
}

export default NonFileBasedView;