import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@panwds/react-ui';
import { FaTimes } from 'react-icons/fa';
import IdentifierDropdown from './IdentifierDropdown';
import BasicDropdown from './BasicDropdown';
import occurrences from './occurrences';

import translate from '../../../helpers/translate';

function t(scope, options) {
  return translate(`data_profiles.form.data_pattern_form.${scope}`, options);
}

const DEFAULT_CONF_LEVELS = [
  { label: 'High', value: 'high' },
  { label: 'Low', value: 'low' }
];

const DataPatternRuleItem = ({dataPatterns, dp, position, updateRuleItem, deleteRuleItem, validatePresence}) => {
  const handleChange = (fields) => {
    updateRuleItem(position, { ...dp, ...fields });
  }

  const handleOccurrenceCountChange = (event) => {
    handleChange({'occurrence_count': parseInt(event.target.value, 10), 'occurrence_low': null, 'occurrence_high': null})
  };

  const handleOccurrenceLowChange = (event) => {
    handleChange({'occurrence_low': parseInt(event.target.value, 10), 'occurrence_count': null})
  };

  const handleOccurrenceHighChange = (event) => {
    handleChange({'occurrence_high': parseInt(event.target.value, 10), 'occurrence_count': null})
  };

  const handleToggleUnique = () => {
    handleChange({'by_unique_count': !dp?.by_unique_count});
  };

  const occurrenceLowError = () => {
    if (dp.occurrence_operator_type === 'between') {
      if (!Number.isInteger(dp.occurrence_low)) {
        return t('errors.number_only');
      }
      if (dp.occurrence_low >= dp.occurrence_high) {
        return t('errors.occurrence_low_high');
      }
    }
    return null;
  }

  const occurrenceHighError = () => {
    if (dp.occurrence_operator_type === 'between') {
      if (!Number.isInteger(dp.occurrence_high)) {
        return t('errors.number_only');
      }
      if (dp.occurrence_high > 500) {
        return t('errors.occurrence_cap');
      }
    }
    return null;
  }

  const occurrenceCountError = () => {
    if (dp.occurrence_count && dp.occurrence_count > 500) {
      return t('errors.occurrence_cap');
    }
    return null;
  }

  const isRowValid = () => {
    if (!dp.id || !dp.occurrence_operator_type || !dp.confidence_level) {
      return false;
    }
    if (occurrenceLowError() !== null || occurrenceHighError() !== null || occurrenceCountError() !== null) {
      return false;
    }
    return true;
  }

  return (
    <div>
      <div className='tw-float-right'>
        <FaTimes onClick={() => deleteRuleItem(position)} className='muteText removeBtn' />
      </div>
      <table className={`occurrenceFields-${position}`}>
        <tbody>
          <tr>
            <td>
              <IdentifierDropdown
                onSelect={newPattern => handleChange({'id': newPattern.id, 'name': newPattern.name, 'supported_confidence_levels': newPattern.supported_confidence_levels, 'detection_technique': newPattern.detection_technique, 'version': newPattern.version})}
                value={dataPatterns[dp.id] || {}}
                items={Object.values(dataPatterns)}
                placeholder='Data Pattern'
                error={validatePresence && !dp.id}
              />
            </td>
            <td>
              <BasicDropdown
                onSelect={newOccurrence => handleChange({'occurrence_operator_type': newOccurrence})}
                value={dp.occurrence_operator_type}
                items={occurrences}
                placeholder={t('occurrences_placeholder')}
                dropClass='occurrence'
                error={validatePresence && !dp.occurrence_operator_type}
              />
            </td>
            {['less_than_equal_to', 'more_than_equal_to'].includes(dp.occurrence_operator_type) && <td><input placeholder='Count' defaultValue={dp.occurrence_count || undefined} onChange={(e) => handleOccurrenceCountChange(e)} className={`form-control occurrenceInput ${validatePresence && occurrenceCountError() !== null ? 'has-error' : ''}`} /></td> }
            {dp.occurrence_operator_type === 'between' && <td><input placeholder='Low' defaultValue={dp.occurrence_low || undefined} onChange={(e) => handleOccurrenceLowChange(e)} className={`form-control occurrenceInput ${validatePresence && occurrenceLowError() !== null ? 'has-error' : ''}`} /></td> }
            {dp.occurrence_operator_type === 'between' && <td><input placeholder='High' defaultValue={dp.occurrence_high || undefined} onChange={(e) => handleOccurrenceHighChange(e)} className={`form-control occurrenceInput ${validatePresence && occurrenceHighError() !== null ? 'has-error' : ''}`} /></td> }
            <td>
              <BasicDropdown
                onSelect={newConfidence => handleChange({'confidence_level': newConfidence})}
                value={dp.confidence_level}
                items={dataPatterns[dp.id] ? dataPatterns[dp.id].supported_confidence_levels.map(conf => { return { label: conf.charAt(0).toUpperCase() + conf.slice(1), value: conf } }) : DEFAULT_CONF_LEVELS}
                placeholder={t('confidence_placeholder')}
                dropClass='confidence'
                error={validatePresence && !dp.confidence_level}
              />
            </td>
            { dp.occurrence_operator_type !== 'any' &&
              <td className='unique-toggle'>
                <Checkbox
                  checked={dp?.by_unique_count}
                  onChange={handleToggleUnique}
                  disabled={false}
                >
                  {t('unique')}
                </Checkbox>
              </td>
            }
          </tr>
          { validatePresence && !isRowValid() &&
            <tr>
              { dp.id ?
                <td />
                :
                <td className='errorDetail error'>{t('errors.data_pattern')}</td>
              }
              { dp.occurrence_operator_type ?
                <td />
                :
                <td className='errorDetail error'>{t('errors.occurrence_operator_type')}</td>
              }
              { dp.occurrence_operator_type === 'between' && (occurrenceLowError() !== null || occurrenceHighError() !== null) &&
                <td colSpan='3' className='errorDetail error'>{occurrenceLowError() || occurrenceHighError()}</td>
              }
              { dp.occurrence_operator_type !== 'between' && occurrenceCountError() !== null &&
                <td colSpan='2' className='errorDetail error'>{occurrenceCountError()}</td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
}

DataPatternRuleItem.propTypes = {
  dataPatterns: PropTypes.shape({}),
  dp: PropTypes.shape({
    id: PropTypes.string,
    confidence_level: PropTypes.string,
    occurrence_operator_type: PropTypes.string,
    occurrence_count: PropTypes.number,
    occurrence_high: PropTypes.number,
    occurrence_low: PropTypes.number,
    by_unique_count: PropTypes.bool
  }),
  position: PropTypes.string,
  updateRuleItem: PropTypes.func,
  // updateRuleItems: PropTypes.func,
  deleteRuleItem: PropTypes.func,
  validatePresence: PropTypes.bool,
};

DataPatternRuleItem.defaultProps = {
  dataPatterns: {},
  dp: {},
  position: '0',
  updateRuleItem: undefined,
  deleteRuleItem: undefined,
  validatePresence: false,
}

export default DataPatternRuleItem;