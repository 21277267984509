// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<PreviewToggle /> bracket preview matches snapshot 1`] = `
<div>
  <Tooltip
    hasTriangle={true}
    label="Detailed List View"
  >
    <div
      className="detail unselected"
    >
      <span
        onClick={[Function]}
      >
        <FaLayerGroup />
      </span>
    </div>
  </Tooltip>
  <Tooltip
    hasTriangle={true}
    label="Bracket View"
  >
    <div
      className="bracket selected"
    >
      <span
        onClick={[Function]}
      >
        <img
          alt="brackets"
        />
      </span>
    </div>
  </Tooltip>
</div>
`;

exports[`<PreviewToggle /> detail preview matches snapshot 1`] = `
<div>
  <Tooltip
    hasTriangle={true}
    label="Detailed List View"
  >
    <div
      className="detail selected"
    >
      <span
        onClick={[Function]}
      >
        <FaLayerGroup />
      </span>
    </div>
  </Tooltip>
  <Tooltip
    hasTriangle={true}
    label="Bracket View"
  >
    <div
      className="bracket unselected"
    >
      <span
        onClick={[Function]}
      >
        <img
          alt="brackets"
        />
      </span>
    </div>
  </Tooltip>
</div>
`;
