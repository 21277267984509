import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Tooltip } from '@panwds/react-ui';
import { Multiselect } from 'multiselect-react-dropdown';
import { Link, withRouter } from "react-router-dom";
import _find from 'lodash.find';
import translate from '../../helpers/translate';
import fileTypes from './fileTypes';
import actions from './actions';
import logSeverities from './logSeverities';
import directions from './directions';
import DropDownList from '../DropDown/DropDownList';
import { preprocessProfile } from './helper';
import toastr from '../../helpers/toastr';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

import {
  isFeatureAvailable
} from '../../helpers/utils';

import {
  DOWNLOAD_SUPPORT
} from '../featureVersions'

function t(scope, options) {
  return translate(`data_filtering_profiles.${scope}`, options);
}

class FilteringProfileDefinition extends React.Component {
  state = {
    profileData: {
      name: '',
      description: ''
    },
    fileTypeSelection: 'any',
    selectedFileTypes: []
  };

  actionModified = false;

  async componentDidMount() {
    const response = await axios.get(`${this.props.lookupURL}/${this.props.profileId}`);
    const profile = preprocessProfile(response.data);
    let {file_type: fileType} = profile;
    const anyFileType = fileType && fileType.length === 1 && fileType[0] === 'any';
    if (!anyFileType) {
      const fileTypeVals = fileTypes.map(ft => ft.value);
      fileType = fileType.filter(ft => fileTypeVals.includes(ft));
    }
    profile.file_type = fileType;
    this.setState({profileData: profile});
    const fileTypeOptions = fileType.map(file => _find(fileTypes, {value: file}));
    this.setState({
      fileTypeSelection: anyFileType? 'any' : 'select',
      selectedFileTypes: anyFileType? [] : fileTypeOptions
    });
  }

  fileTypeChanged = (e) => {
    this.setState({fileTypeSelection: e.currentTarget.value});
  }

  criteriaChanged = (type) => {
    if (type === 'file-based') {
      this.setState(prevState => ({
        profileData: {
          ...prevState.profileData,
          fileBased: prevState.profileData.fileBased === 'yes' ? 'no' : 'yes'
        }
      }));
    }

    if (type === 'non-file-based') {
      this.setState(prevState => ({
        profileData: {
          ...prevState.profileData,
          nonFileBased: prevState.profileData.nonFileBased === 'yes' ? 'no' : 'yes'
        }
      }));
    }
  }

  cancelClicked = () => {
    this.props.history.push({pathname: '../dlp-rules'});
  }

  descriptionChanged = (e) => {
    const {value} = e.target;
    this.setState(prevState => ({
      profileData: {
        ...prevState.profileData,
        description: value
      }
    }));
  }

  actionChanged = (newAction) => {
    this.setState(prevState => ({
      profileData: {
        ...prevState.profileData,
        action: newAction
      }
    }));

    this.actionModified = true;
  }

  severityChanged = (newSeverity) => {
    this.setState(prevState => ({
      profileData: {
        ...prevState.profileData,
        log_severity: newSeverity
      }
    }));
  }

  directionChanged = direction => {
    this.setState(prevState => ({
      profileData: {
        ...prevState.profileData,
        direction
      }
    }));
  }

  fileTypesChanged = (fileTypeOptions) => {
    this.setState({
      selectedFileTypes: fileTypeOptions
    });
  }

  saveClicked = () => {
    const {profileData, fileTypeSelection, selectedFileTypes} = this.state;
    if (this.actionModified) {
      profileData.rule1.action = profileData.action;
    }
    profileData.file_type = ['any'];
    if (fileTypeSelection !== 'any') {
      const fileTypeArray = selectedFileTypes.map(file => file.value);
      if (fileTypeArray.length) {
        profileData.file_type = fileTypeArray;
      }
    }
    if (this.props.nebulaFlag) {
      profileData['file-based'] = profileData.fileBased;
      profileData['non-file-based'] = profileData.nonFileBased;
    }
    axios.put(`${this.props.lookupURL}`, {...profileData}).then(() => {
      toastr.success(t('alerts.update_success', {name: profileData.name}));
      this.cancelClicked();
    }).catch(() => {
      toastr.error(translate('errors.server_error'));
    });

  }

  isSaveDisabled = () => {
    const {
      fileBased,
      nonFileBased
    } = this.state.profileData;

    if (
      this.props.userRole !== 'READ_WRITE' ||
      (fileBased === 'no' && nonFileBased === 'no')
    ) {
      return true;
    }

    return false;
  }

  render() {
    const {
      action,
      description,
      direction,
      fileBased, nonFileBased,
      log_severity: logSeverity,
      name
    } = this.state.profileData;
    const {fileTypeSelection} = this.state;
    const { nebulaFlag, panosVersion } = this.props;

    const multiSelectParams = {
      showCheckbox: true,
      displayValue: 'label',
      hidePlaceholder: true,
      showArrow: true,
      avoidHighlightFirstOption: true,
      closeIcon: 'cancel',
      closeOnSelect: false,
      style: {
        searchBox: { width: '500px', background: '#fff' },
        inputField: { width: '200px' },
        optionContainer: { width: '500px', background: '#fff' },
        chips: { background: '#EAEBEB', color: '#333', borderRadius: '4px' }
      }
    };
    return (
      <section className='dlp-rule-root'>

        <section className='definition-body'>
          <div className='definition-section'>
            <Link className='back-nav-link' to='../dlp-rules'>&lt;{t('back_to_dlp_rules')}</Link>
            <div className='profile-form'>
              <h5>{t('edit_dfp')}</h5>
              <div className='helpText'>{t('edit_instruction')}</div>
            </div>
          </div>

          <div className='definition-section meta-data'>
            <h5 className='sub-header'>{t('details')}</h5>
            <div className='profile-form'>
              <div className='form-label mb-1'>{t('name')}</div>
              <input maxLength="32" className="form-control profile-name" disabled
                value={name}/>
              <div className='helpText'>{t('name_hint')}</div>

              <div className='form-label mt-3 mb-1'>{t('description')} {t('optional')}</div>
              <textarea className='desc-input form-control'
                value={description || ''}
                onChange={this.descriptionChanged}
                placeholder={t('description_hint')}/>
            </div>
          </div>

          <div className='definition-section match-criteria'>
            <h5 className='sub-header'>{t('match_criteria')}</h5>
            <div className='profile-form'>
              <div className='row'>
                <div className='col-md-6'>

                  {nebulaFlag &&
                    <>
                      <div className='form-label mb-2'>{t('file_based')}</div>
                      <ToggleSwitch changeAction={() => this.criteriaChanged('file-based')} checked={fileBased === 'yes'} sliderDisabled={false} />
                      <label className='ml-2 mt-1'>{fileBased === 'yes' ? t('criteria_enabled') : t('criteria_disabled')}</label>
                    </>
                  }


                  <div className='form-label mb-2'>{t('file_type')}</div>
                  <div className='file-type-radio-group'>
                    <input className='mr-1' type="radio" id="file-type-any" checked={fileTypeSelection === 'any'}
                    name="file-type" value='any' onChange={this.fileTypeChanged}/>
                    <label className='mr-3' htmlFor="file-type-any">{t('any')}</label>

                    <input className='mr-1' type="radio" id="file-type-select" checked={fileTypeSelection === 'select'}
                    name="file-type" value='select' onChange={this.fileTypeChanged}/>
                    <label htmlFor="file-type-select">{t('select')}</label>
                  </div>

                  <Multiselect
                    selectedValues={this.state.selectedFileTypes}
                    id='fileTypeSelect'
                    onSelect={this.fileTypesChanged}
                    onRemove={this.fileTypesChanged}
                    placeholder={t('select_file_types')}
                    options={fileTypes}
                    displayValue='label'
                    disable={fileTypeSelection !== 'select'}
                    {...multiSelectParams}
                  />

                  <div className='form-label mb-2'>{t('data_profile')}</div>
                  <Button
                    appearance='tertiary'
                    addClassName='data-profile-dropdown'
                    isMenu
                    size='lg'
                    disabled
                  >
                    {name}
                  </Button>

                  <div className='form-label mb-2 mt-3'>{t('direction')}</div>
                  {isFeatureAvailable(panosVersion, DOWNLOAD_SUPPORT) &&
                    <DropDownList
                      onSelect={this.directionChanged}
                      dropClass='direction-dropdown'
                      value={direction}
                      items={directions}
                      size='lg'
                    />
                  }
                  {!isFeatureAvailable(panosVersion, DOWNLOAD_SUPPORT) &&
                    <Button
                      appearance='tertiary'
                      addClassName='direction-dropdown'
                      isMenu
                      size='lg'
                      disabled
                    >
                      {t('upload')}
                    </Button>
                  }
                </div>

                {nebulaFlag &&
                  <div className='col-md-6'>
                    <div className='form-label mb-2'>{t('non_file_based')}</div>
                    <ToggleSwitch changeAction={() => this.criteriaChanged('non-file-based')} checked={nonFileBased === 'yes'} sliderDisabled={false} />
                    <label className='ml-2 mt-1'>{nonFileBased === 'yes' ? t('criteria_enabled') : t('criteria_disabled')}</label>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className='definition-section action-def'>
            <h5 className='sub-header'>{t('action')}</h5>
            <div className='profile-form'>
            <div className='form-label mb-1'>{t('action')}</div>
            {
              !this.state.profileData.rule2 &&
              <DropDownList
                onSelect={this.actionChanged}
                dropClass='action-dropdown'
                value={action}
                items={actions}
                disabled={!!this.state.profileData.rule2}
              />
            }
            {
              !!this.state.profileData.rule2 &&
              <Button
                appearance='tertiary'
                addClassName='action-dropdown'
                isMenu
                size='lg'
                disabled>
                {t('alert_and_block')}
              </Button>
            }

              <div className='form-label mt-3  mb-1'>{t('log_severity')}</div>
              <DropDownList
                onSelect={this.severityChanged}
                dropClass='severity-dropdown'
                value={logSeverity}
                items={logSeverities}
              />
            </div>
          </div>
        </section>

        <div className='button-bar'>
          <Button addClassName='cancel mr-1'
            appearance='secondary'
            onClick={this.cancelClicked}
            size='md'>
              {translate('actions.cancel')}
          </Button>
          { this.isSaveDisabled() ?
            <Tooltip dataMetrics='dlp-rules-save-tooltip' label={t('cannot_disable')} appearance='dark' hasTriangle={false}>
              <Button
                addClassName='save'
                disabled
                appearance='primary' onClick={this.saveClicked}>
                  {translate('actions.save')}
              </Button>
            </Tooltip>
            :
            <Button
              addClassName='save'
              disabled={false}
              appearance='primary' onClick={this.saveClicked}>
                {translate('actions.save')}
            </Button>
          }
        </div>
      </section>

    )
  }
}

FilteringProfileDefinition.propTypes = {
  profileId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lookupURL: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  managementPlatform: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  nebulaFlag: PropTypes.bool,
  panosVersion: PropTypes.string
};

FilteringProfileDefinition.defaultProps = {
  userRole: 'READ_ONLY',
  managementPlatform: null,
  nebulaFlag: false,
  panosVersion: undefined
}

export default withRouter(FilteringProfileDefinition);