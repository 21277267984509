import React from 'react';
import { SvgIcon } from '@panwds/icons';

const CloseIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox='0 0 12 12'>
      <path d='M11.7338 0.275313C11.3788 -0.0796357 10.8055 -0.0796357 10.4505 0.275313L6 4.71672L1.54949 0.266212C1.19454 -0.0887372 0.62116 -0.0887372 0.266212 0.266212C-0.0887372 0.62116 -0.0887372 1.19454 0.266212 1.54949L4.71672 6L0.266212 10.4505C-0.0887372 10.8055 -0.0887372 11.3788 0.266212 11.7338C0.62116 12.0887 1.19454 12.0887 1.54949 11.7338L6 7.28328L10.4505 11.7338C10.8055 12.0887 11.3788 12.0887 11.7338 11.7338C12.0887 11.3788 12.0887 10.8055 11.7338 10.4505L7.28328 6L11.7338 1.54949C12.0796 1.20364 12.0796 0.621161 11.7338 0.275313Z' />
    </SvgIcon>
  );
};

export default CloseIcon;