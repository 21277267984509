// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<BucketSettings> is disabled for read only user 1`] = `
<div
  className="bucketSettings formCommon"
>
  <div>
    <h5>
      settings.index.evidence_storage
    </h5>
    <p>
      settings.index.storage_desc
    </p>
  </div>
  <hr />
  <div
    className="formRow"
  >
    <div
      className="formLabel"
    >
      settings.index.bucket
    </div>
    <div>
      <div
        className="bucketInformation"
      >
        <div
          className="notConfigured"
        >
          settings.index.not_configured
        </div>
        <SingleSelect
          button={
            <Button
              addClassName="configureBtn"
              appearance="primary"
              buttonType="default"
              disabled={false}
              isMenu={true}
              size="md"
            >
              settings.index.configure_bucket
            </Button>
          }
          disabled={true}
          enableSearch={false}
          items={
            Array [
              Object {
                "key": "aws",
                "value": "settings.index.aws",
              },
              Object {
                "key": "azure",
                "value": "settings.index.azure",
              },
            ]
          }
          onChange={[Function]}
          selectedKey={null}
          style={Object {}}
        />
      </div>
    </div>
  </div>
</div>
`;

exports[`<BucketSettings> renders without a fetchUrl 1`] = `
<div
  className="bucketSettings formCommon"
>
  <div>
    <h5>
      settings.index.evidence_storage
    </h5>
    <p>
      settings.index.storage_desc
    </p>
  </div>
  <hr />
  <div
    className="formRow"
  >
    <div
      className="formLabel"
    >
      settings.index.bucket
    </div>
    <div>
      <div
        className="bucketInformation"
      >
        <div
          className="notConfigured"
        >
          settings.index.not_configured
        </div>
        <SingleSelect
          button={
            <Button
              addClassName="configureBtn"
              appearance="primary"
              buttonType="default"
              disabled={false}
              isMenu={true}
              size="md"
            >
              settings.index.configure_bucket
            </Button>
          }
          disabled={false}
          enableSearch={false}
          items={
            Array [
              Object {
                "key": "aws",
                "value": "settings.index.aws",
              },
              Object {
                "key": "azure",
                "value": "settings.index.azure",
              },
            ]
          }
          onChange={[Function]}
          selectedKey={null}
          style={Object {}}
        />
      </div>
    </div>
  </div>
</div>
`;

exports[`<BucketSettings> with a lookupURL with a bucket validation error renders with warning if validation unsuccessful 1`] = `
<div
  className="bucketSettings formCommon"
>
  <div>
    <h5>
      settings.index.evidence_storage
    </h5>
    <p>
      settings.index.storage_desc
    </p>
  </div>
  <hr />
  <div
    className="formRow"
  >
    <div
      className="formLabel"
    >
      settings.index.bucket
    </div>
    <div>
      <FaCheckCircle
        className="success-icon mr5"
      />
      valid_bucket
      <div
        className="editControls tw-flex tw-mt-2"
      >
        <div
          className="btn-link editBucketConfig tw-mr-2"
          onClick={[Function]}
        >
          settings.index.edit
        </div>
        <Popup
          arrow={true}
          arrowStyle={Object {}}
          className=""
          closeOnDocumentClick={true}
          closeOnEscape={true}
          contentStyle={Object {}}
          defaultOpen={false}
          disabled={false}
          keepTooltipInside={false}
          lockScroll={false}
          modal={false}
          mouseEnterDelay={100}
          mouseLeaveDelay={100}
          offsetX={0}
          offsetY={0}
          on="click"
          onClose={[Function]}
          onOpen={[Function]}
          open={false}
          overlayStyle={Object {}}
          position="right center"
          repositionOnResize={true}
          trigger={
            <div
              className="btn-link removeBucketConfig"
            >
              actions.remove
            </div>
          }
        >
          <div>
            <h6
              className="tw-mb-4"
            >
              settings.index.confirm_removal
            </h6>
            <p>
              settings.index.remove_warning
            </p>
            <div
              className="buttonsBar tw-flex tw-justify-center"
            >
              <Button
                appearance="primary-destructive"
                buttonType="default"
                disabled={false}
                isMenu={false}
                onClick={[Function]}
                size="md"
              >
                actions.remove
              </Button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div
      className="formRow infoRow"
    >
      <div
        className="formLabel"
      >
        Sensitive Files
      </div>
      <div>
        <div
          key="access"
          style={
            Object {
              "padding": "5px",
            }
          }
        >
          <span
            className="mr5"
          >
            <ToggleSwitch
              changeAction={[Function]}
              checked={true}
              sliderDisabled={false}
            />
          </span>
          Enabled for Prisma Access
        </div>
        <div
          key="ngfw"
          style={
            Object {
              "padding": "5px",
            }
          }
        >
          <span
            className="mr5"
          >
            <ToggleSwitch
              changeAction={[Function]}
              checked={false}
              sliderDisabled={false}
            />
          </span>
          Disabled for NGFW
        </div>
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      onClose={[Function]}
      size="sm"
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="settings.index.title"
      >
        settings.index.confirm_disable_modal.title + {"service":"settings.index.undefined"}
      </ModalHeader>
      <CardBody
        addClassName="confirmModal modal-dialog tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div>
            <p>
              settings.index.confirm_disable_modal.desc + {"service":"settings.index.undefined"}
            </p>
            <p>
              settings.index.confirm_disable_modal.confirm_message + {"service":"settings.index.undefined"}
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <Button
            addClassName="confirmBtn"
            appearance="primary-destructive"
            buttonType="default"
            disabled={false}
            isMenu={false}
            onClick={[Function]}
            size="md"
          >
            actions.disable
          </Button>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<BucketSettings> with a lookupURL with a success response renders 1`] = `
<div
  className="bucketSettings formCommon"
>
  <div>
    <h5>
      settings.index.evidence_storage
    </h5>
    <p>
      settings.index.storage_desc
    </p>
  </div>
  <hr />
  <div
    className="formRow"
  >
    <div
      className="formLabel"
    >
      settings.index.bucket
    </div>
    <div>
      <FaCheckCircle
        className="success-icon mr5"
      />
      valid_bucket
      <div
        className="editControls tw-flex tw-mt-2"
      >
        <div
          className="btn-link editBucketConfig tw-mr-2"
          onClick={[Function]}
        >
          settings.index.edit
        </div>
        <Popup
          arrow={true}
          arrowStyle={Object {}}
          className=""
          closeOnDocumentClick={true}
          closeOnEscape={true}
          contentStyle={Object {}}
          defaultOpen={false}
          disabled={false}
          keepTooltipInside={false}
          lockScroll={false}
          modal={false}
          mouseEnterDelay={100}
          mouseLeaveDelay={100}
          offsetX={0}
          offsetY={0}
          on="click"
          onClose={[Function]}
          onOpen={[Function]}
          open={false}
          overlayStyle={Object {}}
          position="right center"
          repositionOnResize={true}
          trigger={
            <div
              className="btn-link removeBucketConfig"
            >
              actions.remove
            </div>
          }
        >
          <div>
            <h6
              className="tw-mb-4"
            >
              settings.index.confirm_removal
            </h6>
            <p>
              settings.index.remove_warning
            </p>
            <div
              className="buttonsBar tw-flex tw-justify-center"
            >
              <Button
                appearance="primary-destructive"
                buttonType="default"
                disabled={false}
                isMenu={false}
                onClick={[Function]}
                size="md"
              >
                actions.remove
              </Button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div
      className="formRow infoRow"
    >
      <div
        className="formLabel"
      >
        Sensitive Files
      </div>
      <div>
        <div
          key="access"
          style={
            Object {
              "padding": "5px",
            }
          }
        >
          <span
            className="mr5"
          >
            <ToggleSwitch
              changeAction={[Function]}
              checked={true}
              sliderDisabled={false}
            />
          </span>
          Enabled for Prisma Access
        </div>
        <div
          key="ngfw"
          style={
            Object {
              "padding": "5px",
            }
          }
        >
          <span
            className="mr5"
          >
            <ToggleSwitch
              changeAction={[Function]}
              checked={false}
              sliderDisabled={false}
            />
          </span>
          Disabled for NGFW
        </div>
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      onClose={[Function]}
      size="sm"
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="settings.index.title"
      >
        settings.index.confirm_disable_modal.title + {"service":"settings.index.undefined"}
      </ModalHeader>
      <CardBody
        addClassName="confirmModal modal-dialog tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div>
            <p>
              settings.index.confirm_disable_modal.desc + {"service":"settings.index.undefined"}
            </p>
            <p>
              settings.index.confirm_disable_modal.confirm_message + {"service":"settings.index.undefined"}
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <Button
            addClassName="confirmBtn"
            appearance="primary-destructive"
            buttonType="default"
            disabled={false}
            isMenu={false}
            onClick={[Function]}
            size="md"
          >
            actions.disable
          </Button>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<BucketSettings> with a lookupURL with a success response renders with disabled edit for read only user 1`] = `
<div
  className="bucketSettings formCommon"
>
  <div>
    <h5>
      settings.index.evidence_storage
    </h5>
    <p>
      settings.index.storage_desc
    </p>
  </div>
  <hr />
  <div
    className="formRow"
  >
    <div
      className="formLabel"
    >
      settings.index.bucket
    </div>
    <div>
      <FaCheckCircle
        className="success-icon mr5"
      />
      valid_bucket
    </div>
  </div>
  <div>
    <hr />
    <div
      className="formRow infoRow"
    >
      <div
        className="formLabel"
      >
        Sensitive Files
      </div>
      <div>
        <div
          key="access"
          style={
            Object {
              "padding": "5px",
            }
          }
        >
          <span
            className="mr5"
          >
            <ToggleSwitch
              changeAction={[Function]}
              checked={true}
              sliderDisabled={true}
            />
          </span>
          Enabled for Prisma Access
        </div>
        <div
          key="ngfw"
          style={
            Object {
              "padding": "5px",
            }
          }
        >
          <span
            className="mr5"
          >
            <ToggleSwitch
              changeAction={[Function]}
              checked={false}
              sliderDisabled={true}
            />
          </span>
          Disabled for NGFW
        </div>
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      onClose={[Function]}
      size="sm"
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="settings.index.title"
      >
        settings.index.confirm_disable_modal.title + {"service":"settings.index.undefined"}
      </ModalHeader>
      <CardBody
        addClassName="confirmModal modal-dialog tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div>
            <p>
              settings.index.confirm_disable_modal.desc + {"service":"settings.index.undefined"}
            </p>
            <p>
              settings.index.confirm_disable_modal.confirm_message + {"service":"settings.index.undefined"}
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <Button
            addClassName="confirmBtn"
            appearance="primary-destructive"
            buttonType="default"
            disabled={false}
            isMenu={false}
            onClick={[Function]}
            size="md"
          >
            actions.disable
          </Button>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<BucketSettings> with a lookupURL with an error response renders 1`] = `
<div
  className="bucketSettings formCommon"
>
  <div>
    <h5>
      settings.index.evidence_storage
    </h5>
    <p>
      settings.index.storage_desc
    </p>
  </div>
  <hr />
  <div
    className="formRow"
  >
    <div
      className="formLabel"
    >
      settings.index.bucket
    </div>
    <div>
      <div
        className="bucketInformation"
      >
        <div
          className="notConfigured"
        >
          settings.index.not_configured
        </div>
        <SingleSelect
          button={
            <Button
              addClassName="configureBtn"
              appearance="primary"
              buttonType="default"
              disabled={false}
              isMenu={true}
              size="md"
            >
              settings.index.configure_bucket
            </Button>
          }
          disabled={false}
          enableSearch={false}
          items={
            Array [
              Object {
                "key": "aws",
                "value": "settings.index.aws",
              },
              Object {
                "key": "azure",
                "value": "settings.index.azure",
              },
            ]
          }
          onChange={[Function]}
          selectedKey={null}
          style={Object {}}
        />
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div
      className="formRow infoRow"
    >
      <div
        className="formLabel"
      >
        Sensitive Files
      </div>
      <div>
        <div
          key="access"
          style={
            Object {
              "padding": "5px",
            }
          }
        >
          <Popup
            arrow={true}
            arrowStyle={Object {}}
            className=""
            closeOnDocumentClick={true}
            closeOnEscape={true}
            contentStyle={Object {}}
            defaultOpen={false}
            disabled={false}
            keepTooltipInside={false}
            lockScroll={false}
            modal={false}
            mouseEnterDelay={100}
            mouseLeaveDelay={100}
            offsetX={0}
            offsetY={0}
            on="hover"
            onClose={[Function]}
            onOpen={[Function]}
            open={false}
            overlayStyle={Object {}}
            position="bottom center"
            repositionOnResize={true}
            trigger={
              <span
                className="mr5"
              >
                <ToggleSwitch
                  checked={false}
                  sliderDisabled={true}
                />
              </span>
            }
          >
            <div
              className="popup-box"
            >
              settings.index.configure_to_enable
            </div>
          </Popup>
          Disabled for 
          Prisma Access
        </div>
        <div
          key="ngfw"
          style={
            Object {
              "padding": "5px",
            }
          }
        >
          <Popup
            arrow={true}
            arrowStyle={Object {}}
            className=""
            closeOnDocumentClick={true}
            closeOnEscape={true}
            contentStyle={Object {}}
            defaultOpen={false}
            disabled={false}
            keepTooltipInside={false}
            lockScroll={false}
            modal={false}
            mouseEnterDelay={100}
            mouseLeaveDelay={100}
            offsetX={0}
            offsetY={0}
            on="hover"
            onClose={[Function]}
            onOpen={[Function]}
            open={false}
            overlayStyle={Object {}}
            position="bottom center"
            repositionOnResize={true}
            trigger={
              <span
                className="mr5"
              >
                <ToggleSwitch
                  checked={false}
                  sliderDisabled={true}
                />
              </span>
            }
          >
            <div
              className="popup-box"
            >
              settings.index.configure_to_enable
            </div>
          </Popup>
          Disabled for 
          NGFW
        </div>
      </div>
    </div>
  </div>
</div>
`;
