// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<FileBasedView /> should match snapshot 1`] = `
<div
  className="settings-container file-settings"
>
  <div
    className="header-section"
  >
    <h5>
      File Based Settings
    </h5>
    <div
      className="help-text"
    >
      Configure the network settings for files scanned to the Enterprise Data Loss Prevention cloud service.
    </div>
  </div>
  <div
    className="data-section"
  >
    <div
      className="left-col"
    >
      <div
        className="text-label"
      >
        Max Latency(sec)
      </div>
      <div
        className="text-label action-latency"
      >
        Action On Max Latency
      </div>
      <div
        className="text-label max-file"
      >
        Max File Size(MB)
      </div>
      <div
        className="text-label action-max"
      >
        Action On Max File Size
      </div>
    </div>
    <div
      className="right-col"
    >
      <Input
        addClassName="max-latency-input"
        allowCopy={false}
        danger={false}
        disabled={false}
        onChange={[Function]}
        readOnly={false}
        success={false}
        value={6}
      />
      <div
        className="text-data max-latency-placeholder"
      >
        Enter a value between 1 and 240
      </div>
      <RadioGroup
        className="radio-horizontal"
        disabled={false}
        horizontal={true}
        name="actionMaxLatency"
        onChange={[Function]}
        value="allow"
      >
        <Radio
          value="allow"
        >
          Allow
        </Radio>
        <Radio
          value="block"
        >
          Block
        </Radio>
      </RadioGroup>
      <Input
        addClassName="max-size-input"
        allowCopy={false}
        danger={false}
        disabled={false}
        onChange={[Function]}
        readOnly={false}
        success={false}
        value={6}
      />
      <div
        className="text-data max-size-placeholder"
      >
        Enter a value between 1 and 100
      </div>
      <RadioGroup
        className="radio-horizontal"
        disabled={false}
        horizontal={true}
        name="actionMaxSize"
        onChange={[Function]}
        value="allow"
      >
        <Radio
          value="allow"
        >
          Allow
        </Radio>
        <Radio
          value="block"
        >
          Block
        </Radio>
      </RadioGroup>
      <div
        className="checkbox-wrapper"
      >
        <Checkbox
          checked={true}
          disabled={false}
          onChange={[Function]}
        >
          Log Files Not Scanned
        </Checkbox>
      </div>
    </div>
  </div>
  <div
    className="footer-section"
  >
    <LoadingButton
      appearance="primary"
      dataMetrics="loading-primary-button"
      dataResult={
        Object {
          "loading": false,
        }
      }
      disabled={true}
      onClickCallback={[Function]}
    >
      Save
    </LoadingButton>
  </div>
</div>
`;
