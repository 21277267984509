import React from 'react';

import { configure, shallow, mount } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import IncidentsTable from './IncidentsTable';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import DropDownList from '../DropDown/DropDownList';

jest.mock('../../helpers/translate');
configure({ adapter: new Adapter() });

const axiosMock = new MockAdapter(axios);
const responseData = [];

axiosMock.onGet().reply(200, responseData);
axiosMock.onPut().reply(200, responseData);
axiosMock.onPost().reply(200, responseData);
axiosMock.onDelete().reply(200, responseData);

describe('<IncidentsTable>', () => {
  let wrapper;
  const lookupURL = 'http://localhost/incidents.json';
  const props = {
    lookupURL,
    userRole: 'READ_WRITE',
    columns: [
      { link: false, header: 'Incident ID', accessor: 'id' },
      { link: false, header: 'File Name', accessor: 'file_name' },
      { link: false, header: 'Data Profile', accessor: 'data_profile' },
      { link: false, header: 'Channel', accessor: 'channel' },
      { link: false, header: 'Action', accessor: 'action' },
      { link: false, header: 'Source', accessor: 'source' },
      { link: false, header: 'Detection Time', accessor: 'detection_time' },
      { link: false, header: 'Report ID', accessor: 'report_id' },
    ],
    assigneeLookupURL: '/assignees',
    tenantStatusLookupURL: '/tenant-statuses',
    globalStatusLookupURL: '/global-statuses',
    incidentUpdateURL: '/incident-update',
    environment: 'test'
  };
  const data = [
    { id: '111', file_name: 'Asset A', data_profile: 'Profile A', link: '/incidents/111', channel: 'Prisma Access', report_id: '12345', action: 'quarantine', source: 'Enterprise DLP App', detection_time: 'Mar 6 2021 at 1:00' },
    { id: '112', file_name: 'Asset B', data_profile: 'Profile B', link: '/incidents/112', channel: 'SaaS Security', report_id: '12346', action: 'block', source: 'Prisma Access', detection_time: 'Mar 6 2021 at 1:00' },
    { id: '113', file_name: 'Asset C', data_profile: 'Profile C', link: '/incidents/113', channel: 'Prisma Access', report_id: '12347', action: 'quarantine', source: 'Panorama', detection_time: 'Mar 6 2021 at 1:00' },
    { id: '114', file_name: 'Asset D', data_profile: 'Profile D', link: '/incidents/114', channel: 'SaaS Security', report_id: '12348', action: 'alert', source: 'SaaS Security', detection_time: 'Mar 6 2021 at 1:00' },
    { id: '115', file_name: 'Asset E', data_profile: 'Profile E', link: '/incidents/115', channel: 'Panorama', report_id: '12349', action: 'block', source: 'Enterprise DLP App', detection_time: 'Mar 6 2021 at 1:00' }
  ];
  const pageData = {
    size: 10,
    number: 1,
    total_elements: 5,
    total_effective_elements: 5,
    total_pages: 1
  };

  afterEach(() => {
    localStorage.clear();
  })

  it('renders bulk table', () => {
    wrapper = shallow(<IncidentsTable {...props} />);
    wrapper.setState({ data, pageData, pageCount: 5 })
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.incidentsTable')).toHaveLength(1);
    expect(wrapper.find('.count').text()).toBe('(5)');
  });

  it('renders bulk table for read only user', () => {
    const readOnlyProps = {...props, userRole: 'READ_ONLY'}
    wrapper = shallow(<IncidentsTable {...readOnlyProps} />);
    wrapper.setState({ data, pageData, pageCount: 5 })
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.incidentsTable')).toHaveLength(1);
    expect(wrapper.find('.count').text()).toBe('(5)');
  });

  it('renders bulk table with no data', () => {
    wrapper = shallow(<IncidentsTable {...props} />);
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.incidentsTable')).toHaveLength(1);
    expect(wrapper.find('.count').text()).toBe('(0)');
  });

  describe('requestResults()', () => {
    it('should call API and set state', async() => {
      const pageNum = 1;
      const numPerPage = 20;
      axiosMock.onPost(`${props.lookupURL}?ascending=false&sort_by=createdAt&page_number=${pageNum}&page_size=${numPerPage}`).reply(200, { });
      wrapper = shallow(<IncidentsTable {...props} />);

      await wrapper.instance().requestResults(pageNum, numPerPage);

      expect(wrapper.state('loading')).toBe(false);
    });
  });

  describe('updateFilters()', () => {
    it('should  set state', async() => {
      const pageNum = 1;
      const numPerPage = 20;
      axiosMock.onPost(`${props.lookupURL}?ascending=false&sort_by=createdAt&page_number=${pageNum}&page_size=${numPerPage}`).reply(200, {});
      wrapper = shallow(<IncidentsTable {...props} />);

      wrapper.instance().updateFilters([], {});

      expect(wrapper.state('filters')).toEqual({
        currentFilterSelections: {},
        currentFilters: []
      });
    });
  });

  describe('updateGlobalFilter', () => {
    it('should set state', () => {
      wrapper = shallow(<IncidentsTable {...props} />);
      wrapper.instance().requestAggregation = jest.fn();
      wrapper.instance().requestResults = jest.fn();
      wrapper.instance().updateGlobalFilter({globalFilters: { region: 'ap' }});
      expect(wrapper.state('globalFilters')).toEqual({ region: 'ap'});
      expect(localStorage.getItem('dlpIncidentsRegion')).toEqual('ap');
    });
  });

  describe('updateState()', () => {
    it('should open view portal for data profiles', () => {
      delete window.location;
      window.location = {assign: jest.fn()};
      wrapper = shallow(<IncidentsTable {...props} />);
      wrapper.setState({data: [{id: '12345', filename: 'Incident A', dataProfile: {name: 'Profile A', id: '1111'}}]})
      wrapper.instance().updateState({viewing: '12345'});
      expect(wrapper.state('viewing')).toEqual({name: 'Profile A', id: '1111'});
      expect(wrapper).toMatchSnapshot();
    });

    it('should change state', () => {
      wrapper = shallow(<IncidentsTable {...props} />);
      wrapper.instance().updateState({filter: 'test-filter'});
      expect(wrapper.state('filter')).toBe('test-filter');
    });
  });

  describe('gov_production environment', () => {
    beforeEach(() => {
      const prodProps = {...props, environment: 'gov_production'}
      wrapper = shallow(<IncidentsTable {...prodProps} />);
    });

    it('does not display region dropdown', () => {
      expect(wrapper.find(DropDownList).length).toEqual(2)
      expect(wrapper).toMatchSnapshot();
    });

    describe('updateGlobalFilter', () => {
      it('should not change state', () => {
        wrapper.setState({globalFilters: { region: 'us'}})
        wrapper.instance().updateGlobalFilter({globalFilters: { region: 'ap' }});
        expect(wrapper.state('globalFilters')).toEqual({ region: 'us'});
        expect(localStorage.getItem('dlpIncidentsRegion')).toEqual(null);
      });
    });

    describe('defaultIncidentRegion', () => {
      it('returns us regardless of local storage', () => {
        localStorage.setItem('dlpIncidentsRegion', 'ap');
        const reg = wrapper.instance().defaultIncidentRegion('gov_production');
        expect(reg).toEqual('us');
      });
    });
  });

  describe('commercial production environment', () => {
    beforeEach(() => {
      const prodProps = {...props, environment: 'production'}
      wrapper = shallow(<IncidentsTable {...prodProps} />);
    });

    it('does display region dropdown', () => {
      expect(wrapper.find(DropDownList).length).toEqual(3)
      expect(wrapper).toMatchSnapshot();
    });

    describe('defaultIncidentRegion', () => {
      it('defaults to stored region', () => {
        localStorage.setItem('dlpIncidentsRegion', 'ap');
        const reg = wrapper.instance().defaultIncidentRegion('production');
        expect(reg).toEqual('ap');
      });
      it('defaults to us region for invalid stored value', () => {
        localStorage.setItem('dlpIncidentsRegion', 'invalid');
        const reg = wrapper.instance().defaultIncidentRegion('production');
        expect(reg).toEqual('us');
      });
    });
  });
});
