/* eslint-disable func-names */

import React from 'react';

import { configure, shallow } from 'enzyme';
import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';
import Adapter from 'enzyme-adapter-react-16';
import DataProfileTable from './DataProfileTable';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<DataProfileTable>', () => {
  let wrapper;
  const mock = new MockAdapter(axios);
  const lookupURL = '/data_profiles.json';
  const props = {
    lookupURL,
    edmStatusURL: '/edm-status.json',
    columns: [
      { header: 'Data Profile', accessor: 'name' },
      { header: 'Mode', accessor: 'profile_type' },
      { header: 'Type', accessor: 'type' },
      { header: 'Last Modified', accessor: 'updated_at' },
      { header: 'Last Updated By', accessor: 'updated_by' }
    ],
    history: {
      push: jest.fn()
    },
    match: {
      path: '/data-profiles'
    },
    userRole: 'READ_WRITE'
  };
  const data = [
    { id: '111', name: 'Profile A', profile_type: 'Custom', type: 'Basic', updated_at: 'Apr 7 2021 at 15:23', updated_by: 'Enterprise DLP App' },
    { id: '112', name: 'Profile B', profile_type: 'Predefined', type: 'Advanced', updated_at: 'Apr 1 2021 at 8:00', updated_by: 'Prisma Access' },
    { id: '113', name: 'Profile C', profile_type: 'Custom - EDM', type: 'Advanced', updated_at: 'Apr 8 2021 at 2:00', updated_by: 'Panorama' },
    { id: '114', name: 'Profile D', profile_type: 'Custom', type: 'Basic', updated_at: 'Apr 6 2021 at 9:42', updated_by: 'SaaS Security' },
    { id: '115', name: 'Profile E', profile_type: 'Predefined', type: 'Basic', updated_at: 'Apr 3 2021 at 16:53', updated_by: 'Enterprise DLP App' }
  ];
  const pageData = {
    size: 10,
    number: 1,
    total_elements: 5,
    total_effective_elements: 5,
    total_pages: 1
  };
  const response = {
    resources: data,
    page: pageData
  };
  mock.onGet().reply(200, response);

  beforeEach(() => {
    wrapper = shallow(<DataProfileTable {...props} />);
  });

  it('renders bulk table', () => {
    wrapper.setState({ data, pageData, pageCount: 5 })
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.count').text()).toBe('(5)');
  });

  it('renders bulk table and view modal if state contains viewing profile', () => {
    wrapper.setState({ data, pageData, pageCount: 5, viewing: data[2] });
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.count').text()).toBe('(5)');
  });

  describe('shows correct header', () => {
    it('renders', () => {
      wrapper = shallow(<DataProfileTable {...props}/>);
      expect(wrapper.find('.header')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });
  });

  describe('request results', () => {
    beforeEach(() => {
      mock.onGet(`${lookupURL}?sort=-updated_at`).reply(200, response);
    })

    it('sets page data', async() => {
      wrapper = shallow(<DataProfileTable {...props} />);
      await wrapper.instance().requestResults(1, 10);
      expect(wrapper.state('pageData')).toEqual({
        number: 1,
        size: 10,
        total_effective_elements: 0,
        total_elements: 0,
        total_pages: 0,
      });
      expect(wrapper.state('loading')).toEqual(false);
    });

    it('matches snapshot', async() => {
      wrapper = shallow(<DataProfileTable {...props} />);
      await wrapper.instance().componentDidMount();
      expect(wrapper).toMatchSnapshot();
    });

    it('includes name search', async() => {
      mock.onGet(`${lookupURL}?page=${0}&limit=${15}&sort=-updated_at&name=example`).reply(200, response);
      wrapper = shallow(<DataProfileTable {...props} />);
      wrapper.setState({profileNameSearch: 'example'});
      await wrapper.instance().componentDidMount();
      expect(wrapper).toMatchSnapshot();
    });

    it('filters Data Profiles', async() => {
      wrapper = shallow(<DataProfileTable {...props} />);
      await wrapper.instance().requestResults(1, 10, 'DoesNotExist');
      expect(wrapper.state('data')).toEqual([]);
      expect(wrapper.state('loading')).toEqual(false);
    });
  });

  describe('updateState', () => {
    it('should update "viewing" state', () => {
      const inst = wrapper.instance();
      inst.updateState({viewing: '113'});
      expect(wrapper.state('viewing')).toEqual(expect.objectContaining({
        id: '113'
      }));
    });

    it('should redirect to edit route', () => {
      const inst = wrapper.instance();
      inst.updateState({editing: '113'});
      expect(props.history.push).toHaveBeenCalledWith('/data-profiles/113/edit');
    });

    it('should redirect to clone route', () => {
      const inst = wrapper.instance();
      inst.updateState({cloning: '113'});
      expect(props.history.push).toHaveBeenCalledWith('/data-profiles/clone', {"dataProfile": {"data_patterns": [], "secondary_rule_eligible": true, "multiProfile": false, "name": "Profile C", "profile_type": "data_profiles.index.modes.Custom - EDM + {\"default\":\"Unknown\"}", "schema_version": 1, "type": "Advanced", "updated_at": "Apr 8 2021 at 2:00"}});
    });

    it('should show delete modal', () => {
      jest.spyOn($.fn, 'modal');
      const inst = wrapper.instance();
      inst.updateState({deleting: '113'});
      expect($.fn.modal).toHaveBeenCalled();
    });

    it('should update "sort controls"', () => {
      const instance = wrapper.instance();

      instance.handleUpdateSortControls({
        sortBy: 'profile_type',
        sortOrder: 'DSC'
      });

      expect(wrapper.state('sortBy')).toEqual('profile_type');
      expect(wrapper.state('sortOrder')).toEqual('DSC');
    });
  });

  it('addNew() should open type selection modal', () => {
    const inst = wrapper.instance();
    inst.addNew();
    expect(wrapper.state('showNewModal')).toEqual(true);
  });
});
