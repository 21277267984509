// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<EdmDatasets> edm banner enabled no banner matches snapshot 1`] = `
<div
  className="edm_datasets"
>
  <Header
    activePage="edm_datasets.index.title"
    basePage=""
    basePageLink=""
  />
  <div>
    <EdmSetup
      cliDownloadURL="/edm_datasets/cli_download.json"
      cliOptionsURL="/edm_datasets/cli_options.json"
    />
    <div
      className="datasetTable reactTable"
    >
      <div
        className="title card-title"
      >
        <span>
          edm_datasets.index.table_title
          <span
            className="count"
          >
            (5)
          </span>
        </span>
      </div>
      <BulkTable
        columns={
          Array [
            Object {
              "accessor": "name",
              "header": "edm_datasets.index.columns.name",
              "link": false,
              "minWidth": "300px",
              "viewable": true,
            },
            Object {
              "accessor": "updated_at",
              "header": "edm_datasets.index.columns.updated_at",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "updated_by",
              "header": "edm_datasets.index.columns.updated_by",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "index_status",
              "header": "edm_datasets.index.columns.index_status",
              "link": false,
              "width": "180px",
            },
          ]
        }
        customCellRender={[Function]}
        data={
          Array [
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "111",
              "index_status": <span>
                <FaSync
                  className="mr5 helpText"
                />
                 
                edm_datasets.index.indexing_statuses.progress
              </span>,
              "name": "Dataset A",
              "updated_at": "Apr 7 2021 at 15:23",
              "updated_by": "Enterprise DLP App",
            },
            Object {
              "active_fields": Array [],
              "id": "112",
              "index_status": <span>
                <FaSync
                  className="mr5 helpText"
                />
                 
                edm_datasets.index.indexing_statuses.progress
              </span>,
              "name": "Dataset B",
              "updated_at": "Apr 1 2021 at 8:00",
              "updated_by": "Prisma Access",
            },
            Object {
              "active_fields": Array [],
              "id": "113",
              "index_status": <span>
                <FaTimesCircle
                  className="mr5 error"
                />
                 
                edm_datasets.index.indexing_statuses.failed
              </span>,
              "name": "Dataset C",
              "updated_at": "Apr 8 2021 at 2:00",
              "updated_by": "Panorama",
            },
            Object {
              "active_fields": Array [],
              "id": "114",
              "index_status": <span>
                <FaSync
                  className="mr5 helpText"
                />
                 
                edm_datasets.index.indexing_statuses.progress
              </span>,
              "name": "Dataset D",
              "updated_at": "Apr 6 2021 at 9:42",
              "updated_by": "SaaS Security",
            },
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "115",
              "index_status": <span>
                <FaSync
                  className="mr5 helpText"
                />
                 
                edm_datasets.index.indexing_statuses.progress
              </span>,
              "name": "Dataset E",
              "updated_at": "Apr 3 2021 at 16:53",
              "updated_by": "Enterprise DLP App",
            },
          ]
        }
        defaultSortBy="asc"
        defaultSortOrder="name"
        filters={
          Object {
            "currentFilterSelections": Object {},
            "currentFilters": Array [],
            "filterOptions": Array [],
            "filterable": false,
          }
        }
        loading={false}
        pageData={
          Object {
            "currentPage": 0,
            "pageCount": 5,
            "totalCount": 5,
            "totalPages": 1,
          }
        }
        requestResults={[Function]}
        selectable={false}
        selectedItems={Array []}
        showDateRangePicker={false}
        sortExclusionList={Array []}
        sortable={false}
        updateFilters={null}
        updateSortControls={null}
        updateState={[Function]}
        useRouter={false}
      />
    </div>
  </div>
</div>
`;

exports[`<EdmDatasets> edm banner enabled no results response matches snapshot 1`] = `
<div
  className="edm_datasets"
>
  <Header
    activePage="edm_datasets.index.title"
    basePage=""
    basePageLink=""
  />
  <div>
    <EdmSetup
      cliDownloadURL="/edm_datasets/cli_download.json"
      cliOptionsURL="/edm_datasets/cli_options.json"
    />
    <div
      className="datasetTable reactTable"
    >
      <div
        className="title card-title"
      >
        <span>
          edm_datasets.index.table_title
          <span
            className="count"
          >
            (0)
          </span>
        </span>
      </div>
      <BulkTable
        columns={
          Array [
            Object {
              "accessor": "name",
              "header": "edm_datasets.index.columns.name",
              "link": false,
              "minWidth": "300px",
              "viewable": true,
            },
            Object {
              "accessor": "updated_at",
              "header": "edm_datasets.index.columns.updated_at",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "updated_by",
              "header": "edm_datasets.index.columns.updated_by",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "index_status",
              "header": "edm_datasets.index.columns.index_status",
              "link": false,
              "width": "180px",
            },
          ]
        }
        customCellRender={[Function]}
        data={Array []}
        defaultSortBy="asc"
        defaultSortOrder="name"
        filters={
          Object {
            "currentFilterSelections": Object {},
            "currentFilters": Array [],
            "filterOptions": Array [],
            "filterable": false,
          }
        }
        loading={false}
        pageData={
          Object {
            "currentPage": undefined,
            "pageCount": undefined,
            "totalCount": 0,
            "totalPages": undefined,
          }
        }
        requestResults={[Function]}
        selectable={false}
        selectedItems={Array []}
        showDateRangePicker={false}
        sortExclusionList={Array []}
        sortable={false}
        updateFilters={null}
        updateSortControls={null}
        updateState={[Function]}
        useRouter={false}
      />
    </div>
  </div>
</div>
`;

exports[`<EdmDatasets> edm banner recently enabled matches snapshot 1`] = `
<div
  className="edm_datasets"
>
  <Header
    activePage="edm_datasets.index.title"
    basePage=""
    basePageLink=""
  />
  <div>
    <EdmSetup
      cliDownloadURL="/edm_datasets/cli_download.json"
      cliOptionsURL="/edm_datasets/cli_options.json"
    />
    <div
      className="datasetTable reactTable"
    >
      <div
        className="title card-title"
      >
        <span>
          edm_datasets.index.table_title
          <span
            className="count"
          >
            (5)
          </span>
        </span>
      </div>
      <BulkTable
        columns={
          Array [
            Object {
              "accessor": "name",
              "header": "edm_datasets.index.columns.name",
              "link": false,
              "minWidth": "300px",
              "viewable": true,
            },
            Object {
              "accessor": "updated_at",
              "header": "edm_datasets.index.columns.updated_at",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "updated_by",
              "header": "edm_datasets.index.columns.updated_by",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "index_status",
              "header": "edm_datasets.index.columns.index_status",
              "link": false,
              "width": "180px",
            },
          ]
        }
        customCellRender={[Function]}
        data={
          Array [
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "111",
              "index_status": <span>
                <FaSync
                  className="mr5 helpText"
                />
                 
                edm_datasets.index.indexing_statuses.progress
              </span>,
              "name": "Dataset A",
              "updated_at": "Apr 7 2021 at 15:23",
              "updated_by": "Enterprise DLP App",
            },
            Object {
              "active_fields": Array [],
              "id": "112",
              "index_status": <span>
                <FaSync
                  className="mr5 helpText"
                />
                 
                edm_datasets.index.indexing_statuses.progress
              </span>,
              "name": "Dataset B",
              "updated_at": "Apr 1 2021 at 8:00",
              "updated_by": "Prisma Access",
            },
            Object {
              "active_fields": Array [],
              "id": "113",
              "index_status": <span>
                <FaTimesCircle
                  className="mr5 error"
                />
                 
                edm_datasets.index.indexing_statuses.failed
              </span>,
              "name": "Dataset C",
              "updated_at": "Apr 8 2021 at 2:00",
              "updated_by": "Panorama",
            },
            Object {
              "active_fields": Array [],
              "id": "114",
              "index_status": <span>
                <FaSync
                  className="mr5 helpText"
                />
                 
                edm_datasets.index.indexing_statuses.progress
              </span>,
              "name": "Dataset D",
              "updated_at": "Apr 6 2021 at 9:42",
              "updated_by": "SaaS Security",
            },
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "115",
              "index_status": <span>
                <FaSync
                  className="mr5 helpText"
                />
                 
                edm_datasets.index.indexing_statuses.progress
              </span>,
              "name": "Dataset E",
              "updated_at": "Apr 3 2021 at 16:53",
              "updated_by": "Enterprise DLP App",
            },
          ]
        }
        defaultSortBy="asc"
        defaultSortOrder="name"
        filters={
          Object {
            "currentFilterSelections": Object {},
            "currentFilters": Array [],
            "filterOptions": Array [],
            "filterable": false,
          }
        }
        loading={false}
        pageData={
          Object {
            "currentPage": 0,
            "pageCount": 5,
            "totalCount": 5,
            "totalPages": 1,
          }
        }
        requestResults={[Function]}
        selectable={false}
        selectedItems={Array []}
        showDateRangePicker={false}
        sortExclusionList={Array []}
        sortable={false}
        updateFilters={null}
        updateSortControls={null}
        updateState={[Function]}
        useRouter={false}
      />
    </div>
  </div>
</div>
`;

exports[`<EdmDatasets> renders bulk table 1`] = `
<div
  className="edm_datasets"
>
  <Header
    activePage="edm_datasets.index.title"
    basePage=""
    basePageLink=""
  />
  <div>
    <EdmSetup
      cliDownloadURL="/edm_datasets/cli_download.json"
      cliOptionsURL="/edm_datasets/cli_options.json"
    />
    <div
      className="datasetTable reactTable"
    >
      <div
        className="title card-title"
      >
        <span>
          edm_datasets.index.table_title
          <span
            className="count"
          >
            (5)
          </span>
        </span>
      </div>
      <BulkTable
        columns={
          Array [
            Object {
              "accessor": "name",
              "header": "edm_datasets.index.columns.name",
              "link": false,
              "minWidth": "300px",
              "viewable": true,
            },
            Object {
              "accessor": "updated_at",
              "header": "edm_datasets.index.columns.updated_at",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "updated_by",
              "header": "edm_datasets.index.columns.updated_by",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "index_status",
              "header": "edm_datasets.index.columns.index_status",
              "link": false,
              "width": "180px",
            },
          ]
        }
        customCellRender={[Function]}
        data={
          Array [
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "111",
              "index_status": "complete",
              "name": "Dataset A",
              "updated_at": "Apr 7 2021 at 15:23",
              "updated_by": "Enterprise DLP App",
            },
            Object {
              "active_fields": Array [],
              "id": "112",
              "index_status": "progress",
              "name": "Dataset B",
              "updated_at": "Apr 1 2021 at 8:00",
              "updated_by": "Prisma Access",
            },
            Object {
              "active_fields": Array [],
              "id": "113",
              "index_status": "failed",
              "name": "Dataset C",
              "updated_at": "Apr 8 2021 at 2:00",
              "updated_by": "Panorama",
            },
            Object {
              "active_fields": Array [],
              "id": "114",
              "index_status": "update_failed",
              "name": "Dataset D",
              "updated_at": "Apr 6 2021 at 9:42",
              "updated_by": "SaaS Security",
            },
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "115",
              "index_status": "complete",
              "name": "Dataset E",
              "updated_at": "Apr 3 2021 at 16:53",
              "updated_by": "Enterprise DLP App",
            },
          ]
        }
        defaultSortBy="asc"
        defaultSortOrder="name"
        filters={
          Object {
            "currentFilterSelections": Object {},
            "currentFilters": Array [],
            "filterOptions": Array [],
            "filterable": false,
          }
        }
        loading={false}
        pageData={
          Object {
            "currentPage": undefined,
            "pageCount": undefined,
            "totalCount": 5,
            "totalPages": undefined,
          }
        }
        requestResults={[Function]}
        selectable={false}
        selectedItems={Array []}
        showDateRangePicker={false}
        sortExclusionList={Array []}
        sortable={false}
        updateFilters={null}
        updateSortControls={null}
        updateState={[Function]}
        useRouter={false}
      />
    </div>
  </div>
</div>
`;

exports[`<EdmDatasets> renders bulk table and view modal if state contains viewing id 1`] = `
<div
  className="edm_datasets"
>
  <Header
    activePage="edm_datasets.index.title"
    basePage=""
    basePageLink=""
  />
  <div>
    <EdmSetup
      cliDownloadURL="/edm_datasets/cli_download.json"
      cliOptionsURL="/edm_datasets/cli_options.json"
    />
    <div
      className="datasetTable reactTable"
    >
      <div
        className="title card-title"
      >
        <span>
          edm_datasets.index.table_title
          <span
            className="count"
          >
            (5)
          </span>
        </span>
      </div>
      <BulkTable
        columns={
          Array [
            Object {
              "accessor": "name",
              "header": "edm_datasets.index.columns.name",
              "link": false,
              "minWidth": "300px",
              "viewable": true,
            },
            Object {
              "accessor": "updated_at",
              "header": "edm_datasets.index.columns.updated_at",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "updated_by",
              "header": "edm_datasets.index.columns.updated_by",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "index_status",
              "header": "edm_datasets.index.columns.index_status",
              "link": false,
              "width": "180px",
            },
          ]
        }
        customCellRender={[Function]}
        data={
          Array [
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "111",
              "index_status": "complete",
              "name": "Dataset A",
              "updated_at": "Apr 7 2021 at 15:23",
              "updated_by": "Enterprise DLP App",
            },
            Object {
              "active_fields": Array [],
              "id": "112",
              "index_status": "progress",
              "name": "Dataset B",
              "updated_at": "Apr 1 2021 at 8:00",
              "updated_by": "Prisma Access",
            },
            Object {
              "active_fields": Array [],
              "id": "113",
              "index_status": "failed",
              "name": "Dataset C",
              "updated_at": "Apr 8 2021 at 2:00",
              "updated_by": "Panorama",
            },
            Object {
              "active_fields": Array [],
              "id": "114",
              "index_status": "update_failed",
              "name": "Dataset D",
              "updated_at": "Apr 6 2021 at 9:42",
              "updated_by": "SaaS Security",
            },
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "115",
              "index_status": "complete",
              "name": "Dataset E",
              "updated_at": "Apr 3 2021 at 16:53",
              "updated_by": "Enterprise DLP App",
            },
          ]
        }
        defaultSortBy="asc"
        defaultSortOrder="name"
        filters={
          Object {
            "currentFilterSelections": Object {},
            "currentFilters": Array [],
            "filterOptions": Array [],
            "filterable": false,
          }
        }
        loading={false}
        pageData={
          Object {
            "currentPage": undefined,
            "pageCount": undefined,
            "totalCount": 5,
            "totalPages": undefined,
          }
        }
        requestResults={[Function]}
        selectable={false}
        selectedItems={Array []}
        showDateRangePicker={false}
        sortExclusionList={Array []}
        sortable={false}
        updateFilters={null}
        updateSortControls={null}
        updateState={[Function]}
        useRouter={false}
      />
      <EdmDatasetDetails
        actionable={false}
        dataset={
          Object {
            "active_fields": Array [],
            "id": "113",
            "index_status": "failed",
            "name": "Dataset C",
            "updated_at": "Apr 8 2021 at 2:00",
            "updated_by": "Panorama",
          }
        }
        updateState={[Function]}
      />
    </div>
  </div>
</div>
`;

exports[`<EdmDatasets> renders bulk table without config for read only user 1`] = `
<div
  className="edm_datasets"
>
  <Header
    activePage="edm_datasets.index.title"
    basePage=""
    basePageLink=""
  />
  <div>
    <div
      className="datasetTable reactTable"
    >
      <div
        className="title card-title"
      >
        <span>
          edm_datasets.index.table_title
          <span
            className="count"
          >
            (5)
          </span>
        </span>
      </div>
      <BulkTable
        columns={
          Array [
            Object {
              "accessor": "name",
              "header": "edm_datasets.index.columns.name",
              "link": false,
              "minWidth": "300px",
              "viewable": true,
            },
            Object {
              "accessor": "updated_at",
              "header": "edm_datasets.index.columns.updated_at",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "updated_by",
              "header": "edm_datasets.index.columns.updated_by",
              "link": false,
              "width": "180px",
            },
            Object {
              "accessor": "index_status",
              "header": "edm_datasets.index.columns.index_status",
              "link": false,
              "width": "180px",
            },
          ]
        }
        customCellRender={[Function]}
        data={
          Array [
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "111",
              "index_status": "complete",
              "name": "Dataset A",
              "updated_at": "Apr 7 2021 at 15:23",
              "updated_by": "Enterprise DLP App",
            },
            Object {
              "active_fields": Array [],
              "id": "112",
              "index_status": "progress",
              "name": "Dataset B",
              "updated_at": "Apr 1 2021 at 8:00",
              "updated_by": "Prisma Access",
            },
            Object {
              "active_fields": Array [],
              "id": "113",
              "index_status": "failed",
              "name": "Dataset C",
              "updated_at": "Apr 8 2021 at 2:00",
              "updated_by": "Panorama",
            },
            Object {
              "active_fields": Array [],
              "id": "114",
              "index_status": "update_failed",
              "name": "Dataset D",
              "updated_at": "Apr 6 2021 at 9:42",
              "updated_by": "SaaS Security",
            },
            Object {
              "active_fields": Array [
                Object {
                  "data_type_name": "ssn",
                  "id": "col-1",
                  "name": "ssn",
                },
              ],
              "id": "115",
              "index_status": "complete",
              "name": "Dataset E",
              "updated_at": "Apr 3 2021 at 16:53",
              "updated_by": "Enterprise DLP App",
            },
          ]
        }
        defaultSortBy="asc"
        defaultSortOrder="name"
        filters={
          Object {
            "currentFilterSelections": Object {},
            "currentFilters": Array [],
            "filterOptions": Array [],
            "filterable": false,
          }
        }
        loading={false}
        pageData={
          Object {
            "currentPage": undefined,
            "pageCount": undefined,
            "totalCount": 5,
            "totalPages": undefined,
          }
        }
        requestResults={[Function]}
        selectable={false}
        selectedItems={Array []}
        showDateRangePicker={false}
        sortExclusionList={Array []}
        sortable={false}
        updateFilters={null}
        updateSortControls={null}
        updateState={[Function]}
        useRouter={false}
      />
    </div>
  </div>
</div>
`;

exports[`<EdmDatasets> shows correct header renders 1`] = `
<div
  className="edm_datasets"
>
  <Header
    activePage="edm_datasets.index.title"
    basePage=""
    basePageLink=""
  />
  <EdmConfig
    currentStatus="disabled"
    enableEdm={[Function]}
    userRole="READ_WRITE"
  />
</div>
`;
