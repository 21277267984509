import React from 'react';
import { IntlProvider } from 'react-intl';

import { render, fireEvent, screen } from '@testing-library/react';
import '@testing-library/jest-dom/extend-expect';
import { expect as chaiExpect } from 'chai';

import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { shallow } from 'enzyme';
import { act } from 'react-dom/test-utils';
import sinon from 'sinon';

import NonFileBasedView from './viewNonFile';
import { ALLOW, BLOCK, NON_FILE_SETTINGS_TYPE } from '../../constants';

const axiosMock = new MockAdapter(axios);

describe('<NonFileBasedView />', () => {
  beforeEach(() => {
    axiosMock.reset();
  });

  it('should match snapshot', () => {
    const wrapper = shallow(
      <NonFileBasedView
        loading={false}
        enableNonFile={true}
        maxLatency={6}
        actionMaxLatencyNonFile={ALLOW}
        minSize={6}
        maxSize={6}
        actionMaxSizeNonFile={ALLOW}
        allowLogData={true}
        updateSettings={() => {}}
        userRole='READ_WRITE'
      />
    );

    expect(wrapper).toMatchSnapshot();
  });

  it('should enable/disable save button and call correct api', async() => {
    const updateSettings = sinon.stub();

    axiosMock.onPut().reply(function (config) {
      expect(config.url).toBe('/v1/dlp-ui/data-filtering-profile/setting');
      expect(config.data).toBe(
        '{"type":"nonfile","nonfile_enabled":false,"nonfile_max_latency":"7","nonfile_action_on_max_latency":"block","nonfile_min_size":4000,"nonfile_max_size":4,"nonfile_action_on_max_size":"block","nonfile_log_files_not_scanned":false}'
      );

      return [
        200,
        {
          type: NON_FILE_SETTINGS_TYPE,
          nonfile_max_latency: 7,
          nonfile_action_on_max_latency: BLOCK,
          nonfile_action_on_max_size: BLOCK,
          nonfile_max_size: 4,
          nonfile_min_size: 4000,
          nonfile_log_files_not_scanned: false,
          nonfile_enabled: false
        }
      ];
    });

    const testUtils = render(
      <IntlProvider locale='en'>
        <NonFileBasedView
          loading={false}
          enableNonFile={true}
          maxLatency={1}
          actionMaxLatencyNonFile={ALLOW}
          minSize={4000}
          maxSize={4}
          actionMaxSizeNonFile={BLOCK}
          allowLogData={false}
          updateSettings={updateSettings}
          userRole='READ_WRITE'
        />
      </IntlProvider>
    );

    await act(async () => {
      expect(screen.getAllByRole('checkbox')[0]).toBeChecked();

      expect(screen.getAllByRole('textbox')[0]).toHaveValue('1');

      expect(screen.getAllByRole('radio', { name: 'Allow' })[0]).toBeChecked();
      expect(screen.getAllByRole('radio', { name: 'Block' })[0]).not.toBeChecked();

      expect(screen.getAllByRole('textbox')[1]).toHaveValue('4000');

      expect(screen.getAllByRole('textbox')[2]).toHaveValue('4');

      expect(screen.getAllByRole('radio', { name: 'Allow' })[1]).not.toBeChecked();
      expect(screen.getAllByRole('radio', { name: 'Block' })[1]).toBeChecked();

      expect(screen.getAllByRole('checkbox')[1]).not.toBeChecked();

      expect(screen.getByText(/Save/i).closest('button')).toBeDisabled();

      fireEvent.click(screen.getAllByRole('checkbox')[0]);
      fireEvent.change(screen.getAllByRole('textbox')[0], {target: {value: '7'}})
      fireEvent.click(screen.getAllByRole('radio', { name: 'Block' })[0]);
    });

    await act(async () => {
      expect(screen.getAllByRole('checkbox')[0]).not.toBeChecked();

      expect(screen.getAllByRole('textbox')[0]).toHaveValue('7');

      expect(screen.getAllByRole('radio', { name: 'Allow' })[0]).not.toBeChecked();
      expect(screen.getAllByRole('radio', { name: 'Block' })[0]).toBeChecked();

      expect(screen.getAllByRole('textbox')[1]).toHaveValue('4000');

      expect(screen.getAllByRole('textbox')[2]).toHaveValue('4');

      expect(screen.getAllByRole('radio', { name: 'Allow' })[1]).not.toBeChecked();
      expect(screen.getAllByRole('radio', { name: 'Block' })[1]).toBeChecked();

      expect(screen.getAllByRole('checkbox')[1]).not.toBeChecked();

      expect(screen.getByText(/Save/i).closest('button')).not.toBeDisabled();

      fireEvent.click(screen.getByText(/Save/i));
    });

    chaiExpect(updateSettings.calledWith({
      enableNonFile: false,
      maxLatency: 7,
      actionMaxLatencyNonFile: BLOCK,
      minSize: 4000,
      maxSize: 4,
      actionMaxSizeNonFile: BLOCK,
      allowLogData: false
    })).to.be.true;
  });
});