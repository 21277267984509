// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataProfileFormV1> create form Component has expected classes 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={false}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <div
          id="nothingToPreview"
        >
          <img
            alt="Preview"
            className="text-center"
          />
          <p
            className="text-center"
          >
            <b>
              No Definition Yet
            </b>
            <br />
            <span
              className="helpText"
            >
              Add a rule to start
            </span>
          </p>
        </div>
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name=""
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Define the match criteria for a primary rule by adding data patterns
        </span>
        <hr />
        <Button
          addClassName="addDatasetBtn"
          appearance="tertiary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          Add Data Pattern Group
        </Button>
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
           
          <span
            className="helpText"
          >
            (Optional)
          </span>
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Define the match criteria for a secondary rule by adding data patterns
        </span>
        <hr />
        <Button
          addClassName="addDatasetBtn"
          appearance="tertiary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          Add Data Pattern Group
        </Button>
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormV1> create form componentDidMount() matches snapshot 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={false}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <div
          id="nothingToPreview"
        >
          <img
            alt="Preview"
            className="text-center"
          />
          <p
            className="text-center"
          >
            <b>
              No Definition Yet
            </b>
            <br />
            <span
              className="helpText"
            >
              Add a rule to start
            </span>
          </p>
        </div>
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name=""
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Define the match criteria for a primary rule by adding data patterns
        </span>
        <hr />
        <Button
          addClassName="addDatasetBtn"
          appearance="tertiary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          Add Data Pattern Group
        </Button>
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
           
          <span
            className="helpText"
          >
            (Optional)
          </span>
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Define the match criteria for a secondary rule by adding data patterns
        </span>
        <hr />
        <Button
          addClassName="addDatasetBtn"
          appearance="tertiary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          Add Data Pattern Group
        </Button>
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormV1> create form renders definition preview 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={false}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <DataProfileDetailPreview
          changedPrimaryExpTree={Object {}}
          changedPrimaryProfiles={Array []}
          changedSecondaryExpTree={Object {}}
          changedSecondaryProfiles={Array []}
          dataPatternRules={
            Array [
              Object {
                "conditions": Array [
                  Object {
                    "operators": Array [
                      "and",
                    ],
                    "rule_items": Array [
                      Object {
                        "confidence_level": "high",
                        "id": "1234560",
                        "name": "SSN",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "low",
                        ],
                        "version": 1,
                      },
                      Object {
                        "confidence_level": "high",
                        "id": "1234563",
                        "name": "Pannier",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "low",
                        ],
                        "version": 1,
                      },
                    ],
                  },
                  Object {
                    "operators": Array [
                      "or",
                    ],
                    "rule_items": Array [
                      Object {
                        "confidence_level": "high",
                        "id": "1234561",
                        "name": "Passport - US",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "medium",
                          "low",
                        ],
                        "version": 1,
                      },
                      Object {
                        "confidence_level": "high",
                        "id": "1234562",
                        "name": "Bank - Statements",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "medium",
                          "low",
                        ],
                        "version": 1,
                      },
                    ],
                  },
                ],
                "operators": Array [
                  "and",
                ],
              },
              Object {},
            ]
          }
          datasets={Array []}
          multi={false}
          primaryExpTree={Object {}}
          primaryProfiles={Array []}
          schema={1}
          secondaryExpTree={Object {}}
          secondaryProfiles={Array []}
        />
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name="Test Profile"
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Define the match criteria for a primary rule by adding data patterns
        </span>
        <hr />
        <DataPatternRules
          dataPatterns={
            Object {
              "1234560": Object {
                "name": "SSN",
              },
              "1234561": Object {
                "name": "Passport - US",
              },
              "1234562": Object {
                "name": "Bank - Statements",
              },
              "1234563": Object {
                "name": "CCN",
              },
            }
          }
          readOnly={false}
          rule={
            Object {
              "conditions": Array [
                Object {
                  "operators": Array [
                    "and",
                  ],
                  "rule_items": Array [
                    Object {
                      "confidence_level": "high",
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "low",
                      ],
                      "version": 1,
                    },
                    Object {
                      "confidence_level": "high",
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "low",
                      ],
                      "version": 1,
                    },
                  ],
                },
                Object {
                  "operators": Array [
                    "or",
                  ],
                  "rule_items": Array [
                    Object {
                      "confidence_level": "high",
                      "id": "1234561",
                      "name": "Passport - US",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "medium",
                        "low",
                      ],
                      "version": 1,
                    },
                    Object {
                      "confidence_level": "high",
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "medium",
                        "low",
                      ],
                      "version": 1,
                    },
                  ],
                },
              ],
              "operators": Array [
                "and",
              ],
            }
          }
          ruleIndex={0}
          updateRule={[Function]}
          validatePresence={false}
        />
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
           
          <span
            className="helpText"
          >
            (Optional)
          </span>
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Define the match criteria for a secondary rule by adding data patterns
        </span>
        <hr />
        <Button
          addClassName="addDatasetBtn"
          appearance="tertiary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          Add Data Pattern Group
        </Button>
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormV1> update form componentDidMount() matches snapshot 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={true}
  />
  <div
    className="formArea"
  >
    <div
      className="dataProfileForm"
    >
      <div
        className="tw-float-right form-section preview"
      >
        <h4
          className="mt-2"
        >
          Data Profile Preview
          <span
            className="tw-float-right previewToggle"
          >
            <PreviewToggle
              previewType="detail"
              updateState={[Function]}
            />
          </span>
        </h4>
        <DataProfileDetailPreview
          changedPrimaryExpTree={Object {}}
          changedPrimaryProfiles={Array []}
          changedSecondaryExpTree={Object {}}
          changedSecondaryProfiles={Array []}
          dataPatternRules={
            Array [
              Object {
                "conditions": Array [
                  Object {
                    "operators": Array [
                      "and",
                    ],
                    "rule_items": Array [
                      Object {
                        "confidence_level": "high",
                        "id": "1234560",
                        "name": "SSN",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "low",
                        ],
                        "version": 1,
                      },
                      Object {
                        "confidence_level": "high",
                        "id": "1234563",
                        "name": "CCN",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "low",
                        ],
                        "version": 1,
                      },
                    ],
                  },
                  Object {
                    "operators": Array [
                      "or",
                    ],
                    "rule_items": Array [
                      Object {
                        "confidence_level": "high",
                        "id": "1234561",
                        "name": "Passport - US",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "medium",
                          "low",
                        ],
                        "version": 1,
                      },
                      Object {
                        "confidence_level": "high",
                        "id": "1234562",
                        "name": "Bank - Statements",
                        "occurrence_count": null,
                        "occurrence_operator_type": "any",
                        "supported_confidence_levels": Array [
                          "high",
                          "medium",
                          "low",
                        ],
                        "version": 1,
                      },
                    ],
                  },
                ],
                "operators": Array [
                  "and",
                ],
              },
              Object {},
            ]
          }
          datasets={Array []}
          multi={false}
          primaryExpTree={Object {}}
          primaryProfiles={Array []}
          schema={1}
          secondaryExpTree={Object {}}
          secondaryProfiles={Array []}
        />
      </div>
      <ProfileName
        handleNameChange={[Function]}
        name="existing profile"
        readOnly={false}
        validatePresence={false}
      />
      <div
        className="form-section"
      >
        <h4>
          Primary Rule
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Define the match criteria for a primary rule by adding data patterns
        </span>
        <hr />
        <DataPatternRules
          dataPatterns={
            Object {
              "1234560": Object {
                "name": "SSN",
              },
              "1234561": Object {
                "name": "Passport - US",
              },
              "1234562": Object {
                "name": "Bank - Statements",
              },
              "1234563": Object {
                "name": "CCN",
              },
            }
          }
          readOnly={false}
          rule={
            Object {
              "conditions": Array [
                Object {
                  "operators": Array [
                    "and",
                  ],
                  "rule_items": Array [
                    Object {
                      "confidence_level": "high",
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "low",
                      ],
                      "version": 1,
                    },
                    Object {
                      "confidence_level": "high",
                      "id": "1234563",
                      "name": "CCN",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "low",
                      ],
                      "version": 1,
                    },
                  ],
                },
                Object {
                  "operators": Array [
                    "or",
                  ],
                  "rule_items": Array [
                    Object {
                      "confidence_level": "high",
                      "id": "1234561",
                      "name": "Passport - US",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "medium",
                        "low",
                      ],
                      "version": 1,
                    },
                    Object {
                      "confidence_level": "high",
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_count": null,
                      "occurrence_operator_type": "any",
                      "supported_confidence_levels": Array [
                        "high",
                        "medium",
                        "low",
                      ],
                      "version": 1,
                    },
                  ],
                },
              ],
              "operators": Array [
                "and",
              ],
            }
          }
          ruleIndex={0}
          updateRule={[Function]}
          validatePresence={false}
        />
      </div>
      <div
        className="form-section"
      >
        <h4>
          Secondary Rule
           
          <span
            className="helpText"
          >
            (Optional)
          </span>
        </h4>
        <span
          className="muteText"
          style={
            Object {
              "lineHeight": "20px",
            }
          }
        >
          Define the match criteria for a secondary rule by adding data patterns
        </span>
        <hr />
        <Button
          addClassName="addDatasetBtn"
          appearance="tertiary"
          buttonType="default"
          disabled={false}
          isMenu={false}
          onClick={[Function]}
          size="md"
        >
          Add Data Pattern Group
        </Button>
      </div>
    </div>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;
