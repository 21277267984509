import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`detection_methods.header.${scope}`, options);
}

const Header = ({ activePage, basePageLink, basePage, desc }) => {
  return (
    <>
      <div className='header main'>
        <div className='breadcrumb'>
          <a className='btn-link' href='/'>{translate('application.dlp')}</a>
          <span className='fa fa-chevron-right separator' />
          <a className='btn-link' href='/detection_methods/data_patterns'>{t('detection_methods')}</a>
          {basePage &&
            <>
              <span className='fa fa-chevron-right separator' />
              {basePageLink ?
                <a className='btn-link' href={basePageLink}>{basePage}</a>
                :
                basePage
              }
            </>
          }
          <span className='fa fa-chevron-right separator' />
          {activePage}
        </div>
        <div className='title'>{activePage}</div>
        {desc && <div className='helpText description'>{desc}</div>}
      </div>
    </>
  );
}

Header.propTypes = {
  activePage: PropTypes.string,
  basePage: PropTypes.string,
  basePageLink: PropTypes.string,
  desc: PropTypes.string,
};

Header.defaultProps = {
  activePage: 'Data Patterns',
  basePage: '',
  basePageLink: '',
  desc: undefined,
}

export default Header;
