import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import $ from 'jquery';
import translate from '../../helpers/translate';
import ReportCard from './ReportCard';
import MatchingDataPatterns from './MatchingDataPatterns';
import toastr from '../../helpers/toastr';

const moment = require('moment-timezone');

function t(scope, options) {
  return translate(`incidents.show.${scope}`, options);
}
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
class IncidentDetails extends React.Component {

  state = {
    incident: {},
    patternResults: {},
    highMatches: {},
    medMatches: {},
    lowMatches: {},
    confidence: 'hcf',
    snippets: {},
    feedbackHistory: []
  };

  componentDidMount() {
    const {search} = window.location;
    const params = new URLSearchParams(search);
    const region = params.get('region');
    const reportId = params.get('report_id');
    let url = `${this.props.lookupURL}?region=${region}`;
    if (reportId) {
      url = `${this.props.lookupURL}?region=${region}&report_id=${reportId}`;
    }
    axios.get(url).then(
      result => {
        if (!result.data) {
          if (this.props.history) {
            this.props.history.push('..');
          }
          toastr.error(translate('incidents.show.not_found'));
        }
        const downloadRoute = this.props.fawkes ? `/v1/dlp-ui/evidence/download/${result.data.reportId}` : `/file/${result.data.reportId}.json`
        const high = {};
        const med = {};
        const low = {};
        const dpr = result.data.data_pattern_results;
        Object.keys(dpr).forEach(dpId => {
          const match = dpr[dpId];
          if (match.hcf > 0) { high[dpId] = match; }
          if (match.mcf > 0) { med[dpId] = match; }
          if (match.lcf > 0) { low[dpId] = match; }
        });
        this.setState({
          incident: result.data,
          patternResults: result.data.data_pattern_results,
          highMatches: high,
          medMatches: med,
          lowMatches: low,
          snippets: result.data.snippets,
          downloadURL: `${downloadRoute}?originalFileName=${result.data.filename}&service-name=${result.data.channel || 'ngfw'}&userId=${this.props.userId}`
        });
      }
    ).catch(
      () => {
        $('#flash-messages').html(
        `<div class="alert alert-floating alert-dismissible fade show" role="alert">
          <span class="fa fa-times error mr5"></span>
          <span class="tw-float-right dismiss-flash" data-dismiss="alert">Dismiss</span>
          ${t('not_found')}
        </div>`)
        setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
        toastr.error(translate('incidents.show.not_found'));
        if (this.props.history) {
          this.props.history.push('..');
        }
      }
    );
    axios.get(`${this.props.feedbackHistoryURL}?region=${region}`).then((result) => {
      this.setState({
        feedbackHistory: result.data
      })
    }).catch((error) => {
      console.error(error);
    });
  }

  render() {
    return (
      <div className='detailsView'>
        <div className='header'>
          <div className='breadcrumb'>
            <span className='btn-link'><a href='/'>{translate('application.dlp')}</a></span>
            <span className='fa fa-chevron-right separator' />
            <span className='btn-link'><a href='/incidents'>{t('title')}</a></span>
            <span className='fa fa-chevron-right separator' />
            {t('details')}
          </div>
          <div className='title'>{this.state.incident.filename}</div>
          <div className='description'>
            { this.state.incident.channel &&
              <span>
                <b>{t('report_card.channel')}</b>: {`${this.state.incident.channel} | `}
              </span>
            }
            { this.state.incident.createdAt &&
              <span>
                <b>{t('report_card.created_at')}</b>:  {moment.utc(this.state.incident.createdAt).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z')}
              </span>
            }
            { !this.state.incident.createdAt && this.state.incident.scanDate &&
              <span>
                <b>{t('report_card.scan_date')}</b>:  {moment.utc(this.state.incident.scanDate).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z')}
              </span>
            }
          </div>
        </div>

        <div className='detailsContent tw-flex'>
          <div className='left-column tw-flex-col'>
          { Object.keys(this.state.incident).length > 0 && <ReportCard incident={this.state.incident} downloadURL={this.state.downloadURL} userRole={this.props.userRole} /> }
          { this.state.feedbackHistory && this.state.feedbackHistory.length > 0 &&
            <section className='feedback-history reportDetails'>
              <div className='card-title reportHeader'>
                Response History
              </div>
              <div className='details'>
                {
                  this.state.feedbackHistory.map((entry, index) =>
                    <div className='history-item' key={`history-${index}`}>
                      <div className={`feedback-detail tw-mb-2 ${index === 0? 'active' : ''}`}>{entry.incidentFeedbackDetails}</div>
                      <div className='timestamp tw-mb-2'>{moment.utc(entry.createdAt).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z')}</div>
                    </div>
                  )
                }
              </div>
            </section>
          }
          </div>
          { Object.keys(this.state.patternResults).length > 0 &&
            <MatchingDataPatterns
              highMatches={this.state.highMatches}
              medMatches={this.state.medMatches}
              lowMatches={this.state.lowMatches}
              confidence={this.state.confidence}
              incidentId={this.state.incident.incidentId}
              snippets={this.state.snippets}
              isMultiProfile={this.state.incident.is_multi_profile}
              multiProfileList={this.state.incident.multi_profile_list}
            />
          }
        </div>
      </div>
    );
  }
}

IncidentDetails.propTypes = {
  lookupURL: PropTypes.string.isRequired,
  feedbackHistoryURL: PropTypes.string.isRequired,
  fawkes: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  userRole: PropTypes.string.isRequired
};

IncidentDetails.defaultProps = {
  history: undefined,
}

export default IncidentDetails;
