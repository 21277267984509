export const ALLOW = 'allow';
export const BLOCK = 'block';
export const DATA_TRANSFER = 'dataTransfer';
export const FILE_SETTINGS_TYPE = 'file';
export const NON_FILE_SETTINGS_TYPE = 'nonfile';

export const FILENAME = 'filename';
export const REPORT_ID = 'report_id';
export const USER_ID = 'user_id';

export const ASC = 'asc';
export const DSC = 'dsc';
export const DATA_PATTERN_COL_GEOGRAPHIES = 'tags';
export const DATA_PATTERN_COL_NAME = 'name';
export const DATA_PATTERN_COL_TECHNIQUE = 'detection_technique';
export const DATA_PATTERN_COL_TYPE = 'type';
export const DATA_PATTERN_COL_UPDATED_AT = 'updated_at';
export const DATA_PROFILE_COL_NAME = 'name';
export const DATA_PROFILE_COL_UPDATED_AT = 'updated_at';
export const INCIDENTS_COL_CREATED_AT = 'createdAt';
export const INCIDENTS_COL_REPORT_ID = 'reportId';
export const INCIDENTS_COL_FEEDBACK_STATUS = 'incidentFeedbackStatus';
export const FILTERING_PROFILE_COL_CREATED_AT = 'updated_at';
export const DOCUMENT_TYPES_COL_NAME = 'name';
export const DOCUMENT_TYPES_COL_CREATED_TIME = 'created_at';
export const DOCUMENT_TYPES_COL_LAST_UPDATED_TIME = 'updated_at';

export const AWS = 'aws';
export const AZURE = 'azure';
export const SFTP = 'sftp';
export const CONNECTED = 'connected';
export const DISCONNECTED = 'disconnected';
export const EDIT = 'edit';
export const CREATE = 'create';
export const NEW = 'new';
export const TEST = 'test';
export const DELETE = 'delete';

export const PAST_24_HOURS = 'past_24_hours';
export const PAST_7_DAYS = 'past_7_days';
export const PAST_30_DAYS = 'past_30_days';
export const PAST_90_DAYS = 'past_90_days';
export const PAST_ALL = 'past_all';

export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
  GOV_PRODUCTION: 'gov_production'
};

export const FEDRAMP_MOD_ENDPOINT = 'https://apigov.dlp.pubsec-cloud.paloaltonetworks.com/';

export const FILE_SIZE_FINGERPRINT = 1000000;
export const NUMBER_SIMULTANEOUS_FINGERPRINT_TEST = 5;
export const DOCUMENT_STATUS_SUCCESS = 'completed';
export const DOCUMENT_STATUS_FAILED = 'failed';
export const DOCUMENT_STATUS_IN_PROGRESS = 'in-progress';

export const FILE_PROPERTY_AIP_TAGS = 'aiptags';
export const FILE_PROPERTY_ASSET_NAME = 'assetname';
export const FILE_PROPERTY_AUTHOR = 'author';
export const FILE_PROPERTY_FILE_SHA = 'filesha';
export const FILE_PROPERTY_EXTENSION = 'extension';
export const FILE_PROPERTY_EXTENDED = 'extended-properties';
export const FILE_PROPERTY_CUSTOM = 'custom';

export const RULE_TYPE_DATA_PATTERN = 'Data Pattern';
export const RULE_TYPE_DOCUMENT_TYPES = 'Document Types';
export const RULE_TYPE_EDM_DATASET = 'EDM Dataset';
export const RULE_TYPE_GROUP = 'Group';
export const RULE_TECHNIQUE_DATA_PATTERN_ML = 'ml';
export const RULE_TECHNIQUE_DATA_PATTERN_REGEX = 'regex';
export const RULE_TECHNIQUE_DOCUMENT_TYPE = 'document_fingerprint';
export const RULE_TECHNIQUE_EDM_DATASET = 'edm';

export const PANORAMA = 'panorama';
export const DLP = 'dlp';
export const ENTERPRISE_DLP = 'enterprise_dlp';
export const NG_CASB = 'ng_casb';

// Feature Flags
export const isPanoramaSettingsEnabled = true;
export const isSftpBucketEnabled = true;
