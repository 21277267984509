import {
  ASC,
  DATA_PROFILE_COL_NAME
} from "../components/constants";

export const sortArrObjects = (
  unSortedData=[],
  sortBy=DATA_PROFILE_COL_NAME,
  sortOrder=ASC,
  reverseSort=false
) => {
  const result = JSON.parse(JSON.stringify(unSortedData));
  let weight = sortOrder === ASC ? -1 : 1;

  if (reverseSort) {
    weight *= -1;
  }

  result.sort((objA, objB) => {
    const valueA = objA[sortBy] ? objA[sortBy].toString().toLowerCase() : '';
    const valueB = objB[sortBy] ? objB[sortBy].toString().toLowerCase() : '';

    if (valueA < valueB) {
      return weight;
    }
    if (valueA > valueB) {
      return -weight;
    }

    return 0;
  });

  return result;
};

const convertVersionToCustom = inputStr => {
  let inputVersion = inputStr.toString();
  const idx = inputVersion.indexOf('PAN-OS');

  if (idx !== -1) {
    inputVersion = inputVersion.slice(idx+7, -1);
  }

  const splitInputArr = inputVersion.split('-')[0].replace('*', -1).split('.');

  if (!splitInputArr[1]) {
    splitInputArr[1] = 0;
  }

  if (!splitInputArr[2]) {
    splitInputArr[2] = 0;
  }

  return splitInputArr.map(item => parseInt(item, 10));
};

export const isFeatureAvailable = (panosVersion, featureVersion) => {
  if (!panosVersion || !featureVersion) {
    return false;
  }

  let availability = false;
  const convertedPanosVersion = convertVersionToCustom(panosVersion);

  if (
    typeof featureVersion === 'string'
    || (
      Array.isArray(featureVersion)
      && typeof featureVersion[0] === 'string'
      && featureVersion.length === 1
    )
  ) {
    const convertedFeatureVersion = convertVersionToCustom(featureVersion);

    if (convertedPanosVersion[0] > convertedFeatureVersion[0]) {
      return true;
    }

    if (convertedPanosVersion[0] === convertedFeatureVersion[0]) {
      if (convertedPanosVersion[1] > convertedFeatureVersion[1]) {
        return true;
      }

      if (convertedPanosVersion[1] === convertedFeatureVersion[1]) {
        if (convertedPanosVersion[2] > convertedFeatureVersion[2]) {
          return true;
        }

        if (convertedPanosVersion[2] === convertedFeatureVersion[2]) {
          return true;
        }
      }
    }
  } else if (typeof featureVersion[0] === 'string' && featureVersion.length === 2) {
    const convertedFeatureVersionStart = convertVersionToCustom(featureVersion[0]);
    const convertedFeatureVersionEnd = convertVersionToCustom(featureVersion[1]);

    if ((convertedFeatureVersionStart[0] <= convertedPanosVersion[0]) && (convertedPanosVersion[0] <= convertedFeatureVersionEnd[0])) {
      if ((convertedFeatureVersionStart[1] <= convertedPanosVersion[1]) && (convertedPanosVersion[1] <= convertedFeatureVersionEnd[1])) {
        if ((convertedFeatureVersionStart[2] <= convertedPanosVersion[2]) && (convertedPanosVersion[2] <= convertedFeatureVersionEnd[2])) {
          return true;
        }
      }
    }
  } else if (Array.isArray(featureVersion[0])) {
    featureVersion.forEach(item => {
      availability = availability || isFeatureAvailable(panosVersion, item);
    });
  }

  return availability;
};