import React from 'react';
import PropTypes from 'prop-types';

const ColumnTable = ({ columns }) => {
  return (
    <table className='table table-borderless table-hover displayTable'>
      <thead className='table-bordered'>
        <tr>
          <th className='name table-bordered'>Field Name</th>
          <th className='type table-bordered'>Data Type</th>
        </tr>
      </thead>
      <tbody>
        {columns.map(column =>
          <tr key={column.name}>
            <td style={{textAlign:'left'}}>{column.name}</td>
            <td style={{textAlign:'left'}}>{column.data_type_name}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

ColumnTable.propTypes = {
  columns: PropTypes.array
};

ColumnTable.defaultProps = {
  columns: [],
}

export default ColumnTable;
