import React from 'react';
import { render } from '@testing-library/react';
import '@testing-library/jest-dom';
import { IntlProvider } from 'react-intl';
import { MemoryRouter } from 'react-router-dom';

import UserInfo  from './UserInfo';

const props = {
  userInfo: {
    tenantIdDLP: '1234567890',
    email: 'test@test.com',
    role: 'READ_WRITE'
  }
};


describe('<UserInfo />', () => {
  let getByText;

  beforeEach(() => {
    ({ getByText } = render(
      <MemoryRouter initialEntries={['/home/settings']}>
        <IntlProvider locale='en'>
          <UserInfo {...props}/>
        </IntlProvider>
      </MemoryRouter>
    ));
  });

  test('should render the component', async() => {
    expect(getByText('1234567890')).toBeInTheDocument();
    expect(getByText('test@test.com')).toBeInTheDocument();
  });

});