/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { FaInfoCircle } from 'react-icons/fa';
import Popup from 'reactjs-popup';

import { Link } from 'react-router-dom';
import translate from '../../helpers/translate';
import dateTranslator from '../../helpers/dateTranslator';

function t(scope, options) {
  return translate(`incidents.index.columns.${scope}`, options);
}

const DLP_DOC = 'https://docs.paloaltonetworks.com/enterprise-dlp/enterprise-dlp-admin/configure-enterprise-dlp/enterprise-dlp-end-user-alerting-with-cortex-xsoar.html';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default ({fawkes, assignees, portalType, basePath}) => [{
    "link": false,
    "header": t('created_at'),
    "accessor": "createdAt",
    Cell: ({ row }) => (
      <span>
        { moment.utc(dateTranslator(row.values.createdAt)).tz(timezone).format('MMMM D[,] YYYY[,] h:mm A z') }
      </span>
    )
  },
  {
    id: 'assignee',
    link: false,
    disableSortBy: true,
    header: t('assigned_to'),
    accessor: row => {
      const assignee = assignees.find(a => a.id === row.assigneeId);
      if (assignee) {
        return `${assignee.firstName} ${assignee.lastName} ${assignee.status === 'ACTIVE'?'' : '(Inactive)'}`;
      }
      return row.assigneeId;
    }
  },
  {
    "link": true,
    "header": t('file_name'),
    "accessor": "filename"
  },
  {
    "link": false,
    "viewable": true,
    "header": t('data_profile'),
    disableSortBy: true,
    "accessor": "dataProfileId"
  },
  {
    "link": false,
    "header": t('channel'),
    disableSortBy: true,
    "accessor": "channel"
  },
  {
    "link": false,
    "header": t('action'),
    disableSortBy: true,
    "accessor": "action"
  },
  {
    "link": false,
    "header": t('source'),
    disableSortBy: true,
    "accessor": "source"
  },
  {
    "link": false,
    "header": t('user_id'),
    "accessor": "userId"
  },
  {
    "link": false,
    disableSortBy: true,
    "header": t('report_id'),
    "accessor": "reportId"
  },
  {
    "link": false,
    disableSortBy: true,
    header: () => {
      return (
        <span className='feedback-status-header'>
          <span>{t('feedback')}</span>
          <Popup trigger={<span><FaInfoCircle className='infoIcon' /></span>} position='top center' on='hover'>
            <>
              <div dangerouslySetInnerHTML={{__html: t('feedback_tip', { 'interpolation': {'escapeValue': false} })}}/>
              <div className='link'>
                {
                  fawkes && <Link to={`${portalType === 'panorama-casb' ? '/casb/data-loss-prevention' : basePath}/settings`}>{t('enable_alerts')}</Link>
                }
                {
                  !fawkes && <a href="/settings">{t('enable_alerts')}</a>
                }
              </div>
              <div className='link'>
                <a href={DLP_DOC} rel="noreferrer" target="_blank">{t('view_documentation')}</a>
              </div>
            </>
          </Popup>
        </span>
      );
    },
    accessor: 'incidentFeedbackStatus'
  },
  {
    link: false,
    header: t('resolution'),
    disableSortBy: true,
    accessor: 'resolutionStatus'
  },
  {
    link: false,
    header: t('notes'),
    disableSortBy: true,
    accessor: 'incidentNotes'
  }
];
