jest.mock('../../../helpers/translate');
import formatRequest  from './requestFormatter';

describe('requestFormatter', () => {
  let profile;

  describe('hybrid profile', () => {
    describe('with 1 edm dataset and 1 data pattern', () => {
      it('formats detection rules', () => {
        profile = {
          name: 'test',
          primaryExpTree: {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "between",
                  "occurrence_count": undefined,
                  "occurrence_low": 4,
                  "occurrence_high": 24,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "detection_technique": "regex",
                  "match_type": "include",
                  "version": 1
                },
                sub_expressions: []
              },
              {
                operator_type: null,
                rule_item: {
                  "edm_dataset_id": "dataset-id-1",
                  "occurrence_operator_type": "between",
                  "occurrence_low": 5,
                  "occurrence_high": 28,
                  "primary_fields": ['col-1', 'col-2'],
                  "primary_match_criteria": "any",
                  "primary_match_any_count": 1,
                  "confidence_level": "high",
                  "detection_technique": "edm"
                },
                sub_expressions: []
              }
            ]
          },
          secondaryExpTree: {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: []
          },
          create: true,
          schema_version: 2,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.detection_rules).toEqual([
          {
            rule_type: 'expression_tree',
            expression_tree: {
              operator_type: 'or',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_operator_type": "between",
                    "occurrence_count": undefined,
                    "occurrence_low": 4,
                    "occurrence_high": 24,
                    "confidence_level": "high",
                    "supported_confidence_levels": ["high","low"],
                    "detection_technique": "regex",
                    "match_type": "include",
                    "version": 1
                  },
                  sub_expressions: []
                },
                {
                  operator_type: null,
                  rule_item: {
                    "edm_dataset_id": "dataset-id-1",
                    "occurrence_operator_type": "between",
                    "occurrence_low": 5,
                    "occurrence_high": 28,
                    "primary_fields": ['col-1', 'col-2'],
                    "primary_match_criteria": "any",
                    "primary_match_any_count": 1,
                    "confidence_level": "high",
                    "detection_technique": "edm"
                  },
                  sub_expressions: []
                }
              ]
            }
          }
        ]);
        expect(formatted.name).toEqual('test');
        expect(formatted.type).toEqual('advanced');
        expect(formatted.schema_version).toEqual(2);
        expect(formatted.profile_type).toEqual('custom');
        expect(formatted.created_by).toEqual('application.dlp_ui');
      })
    });

    describe('with weighted_regex data pattern', () => {
      it('formats detection rules', () => {
        profile = {
          name: '  test with whitespace   ',
          primaryExpTree: {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "between",
                  "occurrence_count": undefined,
                  "occurrence_low": 4,
                  "occurrence_high": 24,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "detection_technique": "weighted_regex",
                  "match_type": "include",
                  "version": 1
                },
                sub_expressions: []
              },
              {
                operator_type: null,
                rule_item: {
                  "edm_dataset_id": "dataset-id-1",
                  "occurrence_operator_type": "between",
                  "occurrence_low": 5,
                  "occurrence_high": 28,
                  "primary_fields": ['col-1', 'col-2'],
                  "primary_match_criteria": "any",
                  "primary_match_any_count": 1,
                  "confidence_level": "high",
                  "detection_technique": "edm"
                },
                sub_expressions: []
              }
            ]
          },
          secondaryExpTree: {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: []
          },
          create: true,
          schema_version: 2,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.detection_rules).toEqual([
          {
            rule_type: 'expression_tree',
            expression_tree: {
              operator_type: 'or',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_operator_type": "between",
                    "occurrence_count": undefined,
                    "occurrence_low": 4,
                    "occurrence_high": 24,
                    "confidence_level": "high",
                    "supported_confidence_levels": ["high","low"],
                    "detection_technique": "weighted_regex",
                    "match_type": "include",
                    "version": 1
                  },
                  sub_expressions: []
                },
                {
                  operator_type: null,
                  rule_item: {
                    "edm_dataset_id": "dataset-id-1",
                    "occurrence_operator_type": "between",
                    "occurrence_low": 5,
                    "occurrence_high": 28,
                    "primary_fields": ['col-1', 'col-2'],
                    "primary_match_criteria": "any",
                    "primary_match_any_count": 1,
                    "confidence_level": "high",
                    "detection_technique": "edm"
                  },
                  sub_expressions: []
                }
              ]
            }
          }
        ]);
        expect(formatted.name).toEqual('test with whitespace');
        expect(formatted.type).toEqual('advanced');
        expect(formatted.schema_version).toEqual(2);
        expect(formatted.profile_type).toEqual('custom');
        expect(formatted.created_by).toEqual('application.dlp_ui');
      })
    });

    describe('with 2 edm datasets and 1 data pattern', () => {
      it('formats detection rules', () => {
        profile = {
          name: 'test',
          primaryExpTree: {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "between",
                  "occurrence_count": undefined,
                  "occurrence_low": 4,
                  "occurrence_high": 24,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "detection_technique": "ml",
                  "match_type": "include",
                  "version": 1
                },
                sub_expressions: []
              },
              {
                operator_type: 'and',
                rule_item: null,
                sub_expressions: [
                  {
                    operator_type: null,
                    rule_item: {
                      "edm_dataset_id": "dataset-id-1",
                      "occurrence_operator_type": "between",
                      "occurrence_low": 5,
                      "occurrence_high": 28,
                      "primary_fields": ['col-1', 'col-2'],
                      "primary_match_criteria": "any",
                      "primary_match_any_count": 1,
                      "confidence_level": "high",
                      "detection_technique": "edm"
                    },
                    sub_expressions: []
                  },
                  {
                    operator_type: null,
                    rule_item: {
                      "edm_dataset_id": "dataset-id-2",
                      "occurrence_operator_type": "more_than_equal_to",
                      "occurrence_count": 15,
                      "primary_fields": ['col-1', 'col-3'],
                      "primary_match_criteria": "all",
                      "confidence_level": "high",
                      "detection_technique": "edm"
                    },
                    sub_expressions: []
                  }
                ]
              }
            ]
          },
          secondaryExpTree: {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: []
          },
          create: true,
          schema_version: 2,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.detection_rules).toEqual([
          {
            rule_type: 'expression_tree',
            expression_tree: {
              operator_type: 'or',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_operator_type": "between",
                    "occurrence_count": undefined,
                    "occurrence_low": 4,
                    "occurrence_high": 24,
                    "confidence_level": "high",
                    "supported_confidence_levels": ["high","low"],
                    "detection_technique": "ml",
                    "match_type": "include",
                    "version": 1
                  },
                  sub_expressions: []
                },
                {
                  operator_type: 'and',
                  rule_item: null,
                  sub_expressions: [
                    {
                      operator_type: null,
                      rule_item: {
                        "edm_dataset_id": "dataset-id-1",
                        "occurrence_operator_type": "between",
                        "occurrence_low": 5,
                        "occurrence_high": 28,
                        "primary_fields": ['col-1', 'col-2'],
                        "primary_match_criteria": "any",
                        "primary_match_any_count": 1,
                        "confidence_level": "high",
                        "detection_technique": "edm"
                      },
                      sub_expressions: []
                    },
                    {
                      operator_type: null,
                      rule_item: {
                        "edm_dataset_id": "dataset-id-2",
                        "occurrence_operator_type": "more_than_equal_to",
                        "occurrence_count": 15,
                        "primary_fields": ['col-1', 'col-3'],
                        "primary_match_criteria": "all",
                        "confidence_level": "high",
                        "detection_technique": "edm"
                      },
                      sub_expressions: []
                    }
                  ]
                }
              ]
            }
          }
        ]);
        expect(formatted.name).toEqual('test');
        expect(formatted.type).toEqual('advanced');
        expect(formatted.schema_version).toEqual(2);
        expect(formatted.profile_type).toEqual('custom');
        expect(formatted.created_by).toEqual('application.dlp_ui');
      })
    });

    describe('with 2 edm datasets and a and not b data patterns', () => {
      it('formats detection rules', () => {
        profile = {
          name: 'test',
          primaryExpTree: {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: 'and',
                rule_item: null,
                sub_expressions: [
                  {
                    operator_type: null,
                    rule_item: {
                      "id": "1234560",
                      "name": "DP1",
                      "occurrence_operator_type": "between",
                      "occurrence_count": undefined,
                      "occurrence_low": 4,
                      "occurrence_high": 24,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "detection_technique": "ml",
                      "match_type": "include",
                      "version": 1
                    },
                    sub_expressions: []
                  },
                  {
                    operator_type: 'not',
                    rule_item: null,
                    sub_expressions: [
                      {
                        operator_type: null,
                        rule_item: {
                          id: "1234561",
                          name: "DP2",
                          detection_technique: "regex",
                          match_type: "include",
                          occurrence_operator_type: "any",
                          confidence_level: "high",
                          supported_confidence_levels: ["high", "low"],
                          version: 2
                        },
                        sub_expressions: []
                      }
                    ]
                  }
                ]
              },
              {
                operator_type: 'and',
                rule_item: null,
                sub_expressions: [
                  {
                    operator_type: null,
                    rule_item: {
                      "edm_dataset_id": "dataset-id-1",
                      "occurrence_operator_type": "between",
                      "occurrence_low": 5,
                      "occurrence_high": 28,
                      "primary_fields": ['col-1', 'col-2'],
                      "primary_match_criteria": "any",
                      "primary_match_any_count": 1,
                      "confidence_level": "high",
                      "detection_technique": "edm"
                    },
                    sub_expressions: []
                  },
                  {
                    operator_type: null,
                    rule_item: {
                      "edm_dataset_id": "dataset-id-2",
                      "occurrence_operator_type": "more_than_equal_to",
                      "occurrence_count": 15,
                      "primary_fields": ['col-1', 'col-3'],
                      "primary_match_criteria": "all",
                      "confidence_level": "high",
                      "detection_technique": "edm"
                    },
                    sub_expressions: []
                  }
                ]
              }
            ]
          },
          secondaryExpTree: {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: []
          },
          create: true,
          schema_version: 2,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.detection_rules).toEqual([
          {
            rule_type: 'expression_tree',
            expression_tree: {
              operator_type: 'or',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: 'and',
                  rule_item: null,
                  sub_expressions: [
                    {
                      operator_type: null,
                      rule_item: {
                        "id": "1234560",
                        "name": "DP1",
                        "occurrence_operator_type": "between",
                        "occurrence_count": undefined,
                        "occurrence_low": 4,
                        "occurrence_high": 24,
                        "confidence_level": "high",
                        "supported_confidence_levels": ["high","low"],
                        "detection_technique": "ml",
                        "match_type": "include",
                        "version": 1
                      },
                      sub_expressions: []
                    },
                    {
                      operator_type: 'not',
                      rule_item: null,
                      sub_expressions: [
                        {
                          operator_type: null,
                          rule_item: {
                            id: "1234561",
                            name: "DP2",
                            detection_technique: "regex",
                            match_type: "include",
                            occurrence_operator_type: "any",
                            confidence_level: "high",
                            supported_confidence_levels: ["high", "low"],
                            version: 2
                          },
                          sub_expressions: []
                        }
                      ]
                    }
                  ]
                },
                {
                  operator_type: 'and',
                  rule_item: null,
                  sub_expressions: [
                    {
                      operator_type: null,
                      rule_item: {
                        "edm_dataset_id": "dataset-id-1",
                        "occurrence_operator_type": "between",
                        "occurrence_low": 5,
                        "occurrence_high": 28,
                        "primary_fields": ['col-1', 'col-2'],
                        "primary_match_criteria": "any",
                        "primary_match_any_count": 1,
                        "confidence_level": "high",
                        "detection_technique": "edm"
                      },
                      sub_expressions: []
                    },
                    {
                      operator_type: null,
                      rule_item: {
                        "edm_dataset_id": "dataset-id-2",
                        "occurrence_operator_type": "more_than_equal_to",
                        "occurrence_count": 15,
                        "primary_fields": ['col-1', 'col-3'],
                        "primary_match_criteria": "all",
                        "confidence_level": "high",
                        "detection_technique": "edm"
                      },
                      sub_expressions: []
                    }
                  ]
                }
              ]
            }
          }
        ]);
        expect(formatted.name).toEqual('test');
        expect(formatted.type).toEqual('advanced');
        expect(formatted.schema_version).toEqual(2);
        expect(formatted.profile_type).toEqual('custom');
        expect(formatted.created_by).toEqual('application.dlp_ui');
      })
    });

    it('should return correct format for ((p1 or p2) or edm)', () => {
      profile = {
        name: 'test',
        primaryExpTree: {
          operator_type: 'or',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: 'or',
              rule_item: null,
              sub_expressions: [
                {
                  operator_type: null,
                  rule_item: {
                    "id": "1234560",
                    "name": "SSN",
                    "occurrence_operator_type": "any",
                    "occurrence_count": null,
                    "confidence_level": "high",
                    "supported_confidence_levels": ["high","low"],
                    "version": 1,
                    "occurrence_high": undefined,
                    "occurrence_low": undefined,
                    "detection_technique": "regex",
                    "match_type": "include"
                  },
                  sub_expressions: []
                },
                {
                  operator_type: null,
                  rule_item: {
                    "id": "1234561",
                    "occurrence_operator_type": "any",
                    "occurrence_count": null,
                    "confidence_level": "high",
                    "name": "Passport - US",
                    "supported_confidence_levels": ["high","medium","low"],
                    "version": 1,
                    "occurrence_high": undefined,
                    "occurrence_low": undefined,
                    "detection_technique": "regex",
                    "match_type": "include"
                  },
                  sub_expressions: []
                }
              ]
            },
            {
              operator_type: null,
              rule_item: {
                "edm_dataset_id": "dataset-id-1",
                "occurrence_operator_type": "between",
                "occurrence_low": 5,
                "occurrence_high": 28,
                "primary_fields": ['col-1', 'col-2'],
                "primary_match_criteria": "any",
                "primary_match_any_count": 1,
                "confidence_level": "high",
                "detection_technique": "edm"
              },
              sub_expressions: []
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        },
        create: true,
        schema_version: 2,
        advanced: true
      }
      const formatted = formatRequest(profile);
      expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
      expect(formatted.detection_rules).toEqual([
        {
          rule_type: 'expression_tree',
          expression_tree: {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: 'or',
                rule_item: null,
                sub_expressions: [
                  {
                    operator_type: null,
                    rule_item: {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1,
                      "occurrence_high": undefined,
                      "occurrence_low": undefined,
                      "detection_technique": "regex",
                      "match_type": "include"
                    },
                    sub_expressions: []
                  },
                  {
                    operator_type: null,
                    rule_item: {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1,
                      "occurrence_high": undefined,
                      "occurrence_low": undefined,
                      "detection_technique": "regex",
                      "match_type": "include"
                    },
                    sub_expressions: []
                  }
                ]
              },
              {
                operator_type: null,
                rule_item: {
                  "edm_dataset_id": "dataset-id-1",
                  "occurrence_operator_type": "between",
                  "occurrence_low": 5,
                  "occurrence_high": 28,
                  "primary_fields": ['col-1', 'col-2'],
                  "primary_match_criteria": "any",
                  "primary_match_any_count": 1,
                  "confidence_level": "high",
                  "detection_technique": "edm"
                },
                sub_expressions: []
              }
            ]
          }
        }
      ]);
      expect(formatted.name).toEqual('test');
      expect(formatted.type).toEqual('advanced');
      expect(formatted.schema_version).toEqual(2);
      expect(formatted.profile_type).toEqual('custom');
      expect(formatted.created_by).toEqual('application.dlp_ui');
    });
  });

  describe('mulitple edm datasets', () => {
    it('formats detection rules', () => {
      profile = {
        name: 'test',
        primaryExpTree: {
          operator_type: 'or',
          rule_item: null,
          sub_expressions: [
            {
              operator_type: null,
              rule_item: {
                "edm_dataset_id": "dataset-id-1",
                "occurrence_operator_type": "between",
                "occurrence_low": 5,
                "occurrence_high": 28,
                "primary_fields": ['col-1', 'col-2'],
                "primary_match_criteria": "any",
                "primary_match_any_count": 1,
                "confidence_level": "high",
                "detection_technique": "edm"
              },
              sub_expressions: []
            },
            {
              operator_type: null,
              rule_item: {
                "edm_dataset_id": "dataset-id-2",
                "occurrence_operator_type": "more_than_equal_to",
                "occurrence_count": 15,
                "primary_fields": ['col-1', 'col-3'],
                "primary_match_criteria": "all",
                "confidence_level": "high",
                "detection_technique": "edm"
              },
              sub_expressions: []
            }
          ]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        },
        create: true,
        schema_version: 2,
        advanced: true
      }
      const formatted = formatRequest(profile);
      expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
      expect(formatted.detection_rules).toEqual([
        {
          rule_type: 'expression_tree',
          expression_tree: {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  "edm_dataset_id": "dataset-id-1",
                  "occurrence_operator_type": "between",
                  "occurrence_low": 5,
                  "occurrence_high": 28,
                  "primary_fields": ['col-1', 'col-2'],
                  "primary_match_criteria": "any",
                  "primary_match_any_count": 1,
                  "confidence_level": "high",
                  "detection_technique": "edm"
                },
                sub_expressions: []
              },
              {
                operator_type: null,
                rule_item: {
                  "edm_dataset_id": "dataset-id-2",
                  "occurrence_operator_type": "more_than_equal_to",
                  "occurrence_count": 15,
                  "primary_fields": ['col-1', 'col-3'],
                  "primary_match_criteria": "all",
                  "confidence_level": "high",
                  "detection_technique": "edm"
                },
                sub_expressions: []
              }
            ]
          }
        }
      ]);
      expect(formatted.name).toEqual('test');
      expect(formatted.type).toEqual('advanced');
      expect(formatted.schema_version).toEqual(2);
      expect(formatted.profile_type).toEqual('custom');
      expect(formatted.created_by).toEqual('application.dlp_ui');
    })
  });

  describe('single edm dataset', () => {
    it('formats detection rules with operator type', () => {
      profile = {
        name: 'test',
        primaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: [{
            operator_type: null,
            rule_item: {
              "edm_dataset_id": "dataset-id-1",
              "occurrence_operator_type": "between",
              "occurrence_low": 5,
              "occurrence_high": 28,
              "primary_fields": ['col-1', 'col-2'],
              "primary_match_criteria": "any",
              "primary_match_any_count": 1,
              "confidence_level": "high",
              "detection_technique": "edm"
            },
            sub_expressions: []
          }]
        },
        secondaryExpTree: {
          operator_type: 'and',
          rule_item: null,
          sub_expressions: []
        },
        create: true,
        schema_version: 2,
        advanced: true
      }
      const formatted = formatRequest(profile);
      expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
      expect(formatted.detection_rules).toEqual([
        {
          rule_type: 'expression_tree',
          expression_tree: {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  "edm_dataset_id": "dataset-id-1",
                  "occurrence_operator_type": "between",
                  "occurrence_low": 5,
                  "occurrence_high": 28,
                  "primary_fields": ['col-1', 'col-2'],
                  "primary_match_criteria": "any",
                  "primary_match_any_count": 1,
                  "confidence_level": "high",
                  "detection_technique": "edm"
                },
                sub_expressions: []
              }
            ]
          }
        }
      ]);
      expect(formatted.name).toEqual('test');
      expect(formatted.type).toEqual('advanced');
      expect(formatted.schema_version).toEqual(2);
      expect(formatted.profile_type).toEqual('custom');
      expect(formatted.created_by).toEqual('application.dlp_ui');
    })
  })

  describe('advanced pattern only profile', () => {
    describe('v1 update', () => {
      it('should return adv rule request string', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: false,
          schema_version: 1,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(['(SSN high any and Pannier high any) and (Passport - US high any or Bank - Statements high any)']);
        expect(formatted.detection_rules).toEqual(undefined);
        expect(formatted.name).toEqual('test');
        expect(formatted.type).toEqual('advanced');
        expect(formatted.schema_version).toEqual(1);
        expect(formatted.profile_type).toEqual('custom');
        expect(formatted.created_by).toEqual(undefined);
      });

      it('should return multiple adv rule request strings', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and", "and_not", "or", "or_not"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "low",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and_not", "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "less_than_equal_to",
                      "occurrence_count": 10,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234564",
                      "name": "Credit Card Number",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234567",
                      "name": "Address",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234565",
                      "name": "Cardguard",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            },
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }
          ],
          create: false,
          schema_version: 1,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual([
          '(SSN high any and Pannier high any) and (Passport - US high any) and (Bank - Statements low any) or (Pannier high less_than_equal_to 10 and_not Credit Card Number high any or Address high any) or_not (Cardguard high any)',
          '(SSN high any and Pannier high any) and (Passport - US high any or Bank - Statements high any)'
        ]);
      });

      it('should flip double negative for v1 adv with 1 rule item', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": ["or_not"],
              "conditions": [
                {
                  "operators": ["and"],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "Tax Id - UK - UTR",
                      "detection_technique": "regex",
                      "match_type": "include",
                      "occurrence_operator_type": "more_than_equal_to",
                      "occurrence_count": 1,
                      "confidence_level": "high",
                      "supported_confidence_levels": [
                        "high",
                        "low"
                      ],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": ["and_not"],
                  "rule_items": [
                    {
                      "id": "1234564",
                      "name": "National Id - UK National Insurance Number - NINO",
                      "detection_technique": "regex",
                      "match_type": "exclude",
                      "occurrence_operator_type": "more_than_equal_to",
                      "occurrence_count": 1,
                      "confidence_level": "high",
                      "supported_confidence_levels": [
                        "high",
                        "low"
                      ],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: false,
          schema_version: 1,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual([
          '(Tax Id - UK - UTR high more_than_equal_to 1) or (National Id - UK National Insurance Number - NINO high more_than_equal_to 1)',
        ]);
      });
    });

    describe('v1 create', () => {
      it('should return adv rule request string', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: true,
          schema_version: 1,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(['(SSN high any and Pannier high any) and (Passport - US high any or Bank - Statements high any)']);
        expect(formatted.detection_rules).toEqual(undefined);
        expect(formatted.name).toEqual('test');
        expect(formatted.type).toEqual('advanced');
        expect(formatted.schema_version).toEqual(1);
        expect(formatted.profile_type).toEqual('custom');
        expect(formatted.created_by).toEqual('application.dlp_ui');
      });

      it('should return multiple adv rule request strings', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and", "and_not", "or", "or_not"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "low",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and_not", "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "less_than_equal_to",
                      "occurrence_count": 10,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234564",
                      "name": "Credit Card Number",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234567",
                      "name": "Address",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234565",
                      "name": "Cardguard",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            },
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }
          ],
          create: true,
          schema_version: 1,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual([
          '(SSN high any and Pannier high any) and (Passport - US high any) and (Bank - Statements low any) or (Pannier high less_than_equal_to 10 and_not Credit Card Number high any or Address high any) or_not (Cardguard high any)',
          '(SSN high any and Pannier high any) and (Passport - US high any or Bank - Statements high any)'
        ]);
      });

      it('should return adv request string for single data pattern', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "between",
                      "occurrence_low": 3,
                      "occurrence_high": 23,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          schema_version: 1,
          create: true,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual([
          '(SSN high between 3 23)'
        ]);
        expect(formatted.detection_rules).toEqual(undefined);
        expect(formatted.name).toEqual('test');
        expect(formatted.type).toEqual('advanced');
        expect(formatted.schema_version).toEqual(1);
        expect(formatted.profile_type).toEqual('custom');
        expect(formatted.created_by).toEqual('application.dlp_ui');
      });

      it('should move not operator to in between for a and not b', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: true,
          schema_version: 1,
          advanced: true
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(['(SSN high any) and_not (Passport - US high any)']);
        expect(formatted.detection_rules).toEqual(undefined);
        expect(formatted.name).toEqual('test');
        expect(formatted.type).toEqual('advanced');
        expect(formatted.schema_version).toEqual(1);
        expect(formatted.profile_type).toEqual('custom');
        expect(formatted.created_by).toEqual('application.dlp_ui');
      });
    });
  })

  describe('basic pattern only profile', () => {
    describe('v1 update', () => {
      it('should return basic rule', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: false,
          schema_version: 1,
          advanced: false
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.data_patterns_rule_1).toEqual({
            operator_type: 'and',
            data_pattern_rules: [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234563",
                  "name": "Pannier",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234561",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "name": "Passport - US",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234562",
                  "name": "Bank - Statements",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1,
                  "match_type": "include"
                }
            ]
        });
      });

      it('should return basic rule with correct match types', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: false,
          schema_version: 1,
          advanced: false
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.data_patterns_rule_1).toEqual({
            operator_type: 'and',
            data_pattern_rules: [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234563",
                  "name": "Pannier",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234561",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "name": "Passport - US",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234562",
                  "name": "Bank - Statements",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1,
                  "match_type": "exclude"
                }
            ]
        });
      });

      it('should sort out double negative', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and_not"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: false,
          schema_version: 1,
          advanced: false
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.data_patterns_rule_1).toEqual({
            operator_type: 'and',
            data_pattern_rules: [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234563",
                  "name": "Pannier",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234561",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "name": "Passport - US",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1,
                  "match_type": "exclude"
                },
                {
                  "id": "1234562",
                  "name": "Bank - Statements",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1,
                  "match_type": "include"
                }
            ]
        });
      });

      it('should return basic rule with multiple rules', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "or"
              ],
              "conditions": [
                {
                  "operators": [
                    "or_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            },
            {
              "operators": [],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234564",
                      "name": "Credit Card Number",
                      "occurrence_operator_type": "between",
                      "occurrence_low": 5,
                      "occurrence_high": 25,
                      "confidence_level": "medium",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234565",
                      "name": "Cardguard",
                      "occurrence_operator_type": "more_than_equal_to",
                      "occurrence_count": 10,
                      "confidence_level": "low",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }
          ],
          create: false,
          schema_version: 1,
          advanced: false
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.data_patterns_rule_1).toEqual({
            operator_type: 'or',
            data_pattern_rules: [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234563",
                  "name": "Pannier",
                  "occurrence_operator_type": "any",
                  "occurrence_count": null,
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "exclude"
                }
            ]
        });
        expect(formatted.data_patterns_rule_2).toEqual({
            operator_type: 'and',
            data_pattern_rules: [
                {
                  "id": "1234564",
                  "name": "Credit Card Number",
                  "occurrence_operator_type": "between",
                  "occurrence_low": 5,
                  "occurrence_high": 25,
                  "confidence_level": "medium",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234565",
                  "name": "Cardguard",
                  "occurrence_operator_type": "more_than_equal_to",
                  "occurrence_count": 10,
                  "confidence_level": "low",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                }
            ]
        });
      });
    });

    describe('v1 clone', () => {
      it('should return basic rule', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "or_not"
              ],
              "conditions": [
                {
                  "operators": [
                      "or_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "detection_technique": "regex",
                      "match_type": "include",
                      "occurrence_operator_type": "any",
                      "confidence_level": "high",
                      "supported_confidence_levels": [
                          "high",
                          "low"
                      ],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "detection_technique": "regex",
                      "match_type": "include",
                      "occurrence_operator_type": "any",
                      "confidence_level": "high",
                      "supported_confidence_levels": [
                          "high",
                          "low"
                      ],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [],
                  "rule_items": [
                    {
                        "id": "1234561",
                        "name": "Passport - US",
                        "detection_technique": "regex",
                        "match_type": "exclude",
                        "occurrence_operator_type": "any",
                        "confidence_level": "high",
                        "supported_confidence_levels": [
                            "high",
                            "medium",
                            "low"
                        ],
                        "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: false,
          schema_version: 1,
          advanced: false
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.data_patterns_rule_1).toEqual({
            operator_type: 'or',
            data_pattern_rules: [
                {
                  "id": "1234560",
                  "name": "SSN",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "include"
                },
                {
                  "id": "1234563",
                  "name": "Pannier",
                  "detection_technique": "regex",
                  "occurrence_operator_type": "any",
                  "confidence_level": "high",
                  "supported_confidence_levels": ["high","low"],
                  "version": 1,
                  "match_type": "exclude"
                },
                {
                  "id": "1234561",
                  "occurrence_operator_type": "any",
                  "detection_technique": "regex",
                  "confidence_level": "high",
                  "name": "Passport - US",
                  "supported_confidence_levels": ["high","medium","low"],
                  "version": 1,
                  "match_type": "exclude"
                }
            ]
        });
      });
    });

    describe('v1 create', () => {
      it('should return adv rule request', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: true,
          advanced: true,
          schema_version: 1
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual([
          '(SSN high any and Pannier high any) and (Passport - US high any and Bank - Statements high any)'
        ]);
        expect(formatted.data_patterns_rule_1).toEqual(undefined);
        expect(formatted.detection_rules).toEqual(undefined);
        expect(formatted.schema_version).toEqual(1);
      });

      it('should return adv rule request with not operators', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: true,
          advanced: true,
          schema_version: 1
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual([
          '(SSN high any and Pannier high any) and (Passport - US high any and_not Bank - Statements high any)'
        ]);
        expect(formatted.data_patterns_rule_1).toEqual(undefined);
        expect(formatted.data_patterns_rule_2).toEqual(undefined);
        expect(formatted.detection_rules).toEqual(undefined);
        expect(formatted.schema_version).toEqual(1);
      });

      it('should return adv rule request with multiple rules', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "or"
              ],
              "conditions": [
                {
                  "operators": [
                    "or"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "or_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            },
            {
              "operators": [],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234564",
                      "name": "Credit Card Number",
                      "occurrence_operator_type": "between",
                      "occurrence_low": 5,
                      "occurrence_high": 25,
                      "confidence_level": "medium",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234565",
                      "name": "Cardguard",
                      "occurrence_operator_type": "more_than_equal_to",
                      "occurrence_count": 10,
                      "confidence_level": "low",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }
          ],
          create: true,
          advanced: true,
          schema_version: 1
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual([
          '(SSN high any) or_not (Pannier high any)',
          '(Credit Card Number medium between 5 25 and Cardguard low more_than_equal_to 10)'
        ]);
        expect(formatted.data_patterns_rule_1).toEqual(undefined);
        expect(formatted.data_patterns_rule_2).toEqual(undefined);
        expect(formatted.detection_rules).toEqual(undefined);
        expect(formatted.schema_version).toEqual(1);
      });

      it('should convert not operators to match types', () => {
        profile = {
          name: 'test',
          advance_data_patterns_rules: [
            {
              "operators": [
                "and"
              ],
              "conditions": [
                {
                  "operators": [
                    "and"
                  ],
                  "rule_items": [
                    {
                      "id": "1234560",
                      "name": "SSN",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    },
                    {
                      "id": "1234563",
                      "name": "Pannier",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","low"],
                      "version": 1
                    }
                  ]
                },
                {
                  "operators": [
                    "and_not"
                  ],
                  "rule_items": [
                    {
                      "id": "1234561",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "name": "Passport - US",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    },
                    {
                      "id": "1234562",
                      "name": "Bank - Statements",
                      "occurrence_operator_type": "any",
                      "occurrence_count": null,
                      "confidence_level": "high",
                      "supported_confidence_levels": ["high","medium","low"],
                      "version": 1
                    }
                  ]
                }
              ]
            }, {}
          ],
          create: true,
          advanced: false,
          schema_version: 1
        }
        const formatted = formatRequest(profile);
        expect(formatted.advance_data_patterns_rule_request).toEqual(undefined);
        expect(formatted.data_patterns_rule_1).toEqual({
          "data_pattern_rules": [
            {
              "confidence_level": "high",
              "id": "1234560",
              "match_type": "include",
              "name": "SSN",
              "occurrence_count": null,
              "occurrence_operator_type": "any",
              "supported_confidence_levels": ["high", "low"],
              "version": 1
            },
            {
              "confidence_level": "high",
              "id": "1234563",
              "match_type": "include",
              "name": "Pannier",
              "occurrence_count": null,
              "occurrence_operator_type": "any",
              "supported_confidence_levels": ["high", "low"],
              "version": 1
            },
            {
              "confidence_level": "high",
              "id": "1234561",
              "match_type": "include",
              "name": "Passport - US",
              "occurrence_count": null,
              "occurrence_operator_type": "any",
              "supported_confidence_levels": ["high", "medium", "low"],
              "version": 1
            },
            {
              "confidence_level": "high",
              "id": "1234562",
              "match_type": "exclude",
              "name": "Bank - Statements",
              "occurrence_count": null,
              "occurrence_operator_type": "any",
              "supported_confidence_levels": ["high", "medium", "low"],
              "version": 1
            }
          ],
          "operator_type": "and"
        });
        expect(formatted.data_patterns_rule_2).toEqual(null);
        expect(formatted.detection_rules).toEqual(undefined);
        expect(formatted.schema_version).toEqual(1);
      });
    });
  });
});