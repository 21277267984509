/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import axios from 'axios';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@panwds/react-ui';
import { IntlProvider } from 'react-intl';
import $ from 'jquery';

import toastr from '../../helpers/toastr';
import translate from '../../helpers/translate';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import SingleSelect from '../SingleSelect';
import SpinnerIcon from '../../../assets/images/spinner.gif';

import AwsConfigureModal from './AwsConfigureModal';
import AzureConfigureModal from './AzureConfigureModal';
import SftpConfigureModal from './SftpConfigureModal';

import {
  AWS,
  AZURE,
  SFTP,
  CONNECTED,
  DISCONNECTED
} from '../constants';

function t(scope, options) {
  return translate(`settings.index.${scope}`, options);
}

const BUCKET_TYPES = [
  {
    key: AWS,
    value: t(AWS)
  },
  {
    key: AZURE,
    value: t(AZURE)
  },
  {
    key: SFTP,
    value: t(SFTP)
  }
];

const BUCKET_TYPES_NO_SFTP = [
  {
    key: AWS,
    value: t(AWS)
  },
  {
    key: AZURE,
    value: t(AZURE)
  }
];

class Settings extends React.Component {
  state = {
    data: {},
    services: this.props.services,
    errorMessage: '',
    bucketType: null,
    showAzureModal: false,
    showAwsModal: false,
    showSftpModal: false
  };

  componentDidMount() {
    this.fetchBucketData();
  }

  fetchBucketData = () => {
    if (!this.props.lookupURL) {
      return undefined;
    }

    return axios(this.props.lookupURL)
      .then(result => {
        this.setState({ data: result.data });
        if (result.data.connection_status === CONNECTED) {
          this.setState({ validated: true });
        } else if (result.data.connection_status === DISCONNECTED) {
          this.setState({
            validated: true,
            errorMessage: t('alerts.bucket_disconnected')
          });
        }
      })
      .catch(err => {
        const result = err.response;

        if (result?.status === 404) {
          this.setState({ data: result.data });
        } else {
          this.setState({ data: [] });

          $('#flash-messages').html(
            `<div class="alert alert-floating alert-dismissible fade show" role="alert">
              <span class="fa fa-times error mr5"></span>
              <span class="tw-float-right dismiss-flash" data-dismiss="alert">Dismiss</span>
              ${t('alerts.unable_to_fetch_data')}
            </div>`);
          setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
        }
      });
  }

  handleOpenModal = value => {
    const key = value?.selectedItem?.key || value?.key;

    this.setState({
      bucketType: key,
      showAwsModal: key === AWS,
      showAzureModal: key === AZURE,
      showSftpModal: key === SFTP
    });
  };

  handleCloseModal = () => {
    this.setState({
      showAwsModal: false,
      showAzureModal: false,
      showSftpModal: false,
      bucketType: null
    });

    this.fetchBucketData();
  };

  handleDeleteConfig = async () => {
    try {
      await axios.delete(this.props.lookupURL);

      this.setState({ data: {} });

      if (!this.props.fawkes) {
        window.location.assign('/settings/');
      } else {
        toastr.success(t('evidence_storage_remove_success', { storage_type: this.state.data.connection_type }));
      }
    } catch (error) {
      console.error('Failed to delete evidence storage config', error);
    }
  };

  renderEditRemoveButtons = () => {
    const triggerOpenModal = () => {
      this.handleOpenModal({ key: this.state.data.connection_type });
    };

    if (this.props.userRole === 'READ_WRITE') {
      return (
        <div className='editControls tw-flex tw-mt-2'>
          <div className='btn-link editBucketConfig tw-mr-2'
            onClick={triggerOpenModal}
          >
            {t('edit')}
          </div>

          <Popup
            trigger={<div className='btn-link removeBucketConfig'>{translate('actions.remove')}</div>}
            position='right center'
            on='click'
            closeOnEscape
          >
            <div>
              <h6 className='tw-mb-4'>{t('confirm_removal')}</h6>
              <p>{t('remove_warning')}</p>
              <div className='buttonsBar tw-flex tw-justify-center'>
                <Button appearance='primary-destructive' onClick={this.handleDeleteConfig}>{translate('actions.remove')}</Button>
              </div>
            </div>
          </Popup>
        </div>
      );
    }

    return (<></>);
  };

  renderBucketState = () => {
    if (Object.keys(this.state.data).length > 0) {
      if (this.state.data.connection_status === CONNECTED || this.state.data.connection_status === DISCONNECTED) {
        if (this.state.validated) {
          switch (this.state.data.connection_type) {
            case AWS:
              return (
                <>
                  { this.state.errorMessage ?
                    <FaTimesCircle className='error mr5' /> :
                    <FaCheckCircle className='success-icon mr5'/>
                  }
                  { this.state.data.connection_data.aws_bucket_name }
                  { this.renderEditRemoveButtons() }
                  { this.state.showAwsModal &&
                    <AwsConfigureModal
                      accessEnabled={this.state.data.enabled_for_prisma_access}
                      connectionData={this.state.data.connection_data}
                      editing
                      error={this.state.errorMessage}
                      fawkes={this.props.fawkes}
                      initialState={2}
                      modalClosed={this.handleCloseModal}
                      ngfwEnabled={this.state.data.enabled_for_ngfw}
                      showModal={this.state.showAwsModal}
                      environment={this.props.environment}
                      validateURL={this.props.fawkes ? `${this.props.lookupURL}/connection/validate` : '/settings/validate.json'}
                    />
                  }
                </>
              );

            case AZURE: {
              const { azure_tenant_id, azure_end_point } = this.state.data.connection_data;

              return (
                <>
                  { this.state.errorMessage ?
                    <FaTimesCircle className='error mr5' /> :
                    <FaCheckCircle className='success-icon mr5'/>
                  }
                  { azure_tenant_id || 'Unknown' }
                  { this.renderEditRemoveButtons() }
                  { this.state.showAzureModal &&
                    <AzureConfigureModal
                      accessEnabled={this.state.data.enabled_for_prisma_access}
                      edit
                      errorMessage={this.state.errorMessage}
                      fawkes={this.props.fawkes}
                      initialEndpoint={azure_end_point}
                      initialState={2}
                      initialTenantId={azure_tenant_id}
                      modalClosed={this.handleCloseModal}
                      ngfwEnabled={this.state.data.enabled_for_ngfw}
                      showModal={this.state.showAzureModal}
                      updateURL={this.props.fawkes ? `/v1/dlp-ui/evidence` : '/settings.json'}
                      validateURL={this.props.fawkes ? `${this.props.lookupURL}/connection/validate` : '/settings/validate.json'}
                    />
                  }
                </>
              );
            }

            case SFTP:
            default: {
              const {
                sftp_host_name,
                sftp_path,
                sftp_pgp_key,
                sftp_port,
                sftp_private_key,
                sftp_user_name
              } = this.state.data.connection_data;

              return (
                <>
                  { this.state.errorMessage ?
                    <FaTimesCircle className='error mr5' /> :
                    <FaCheckCircle className='success-icon mr5'/>
                  }
                  { sftp_host_name || 'Unknown' }
                  { this.renderEditRemoveButtons() }
                  { this.state.showSftpModal &&
                    <SftpConfigureModal
                      accessEnabled={this.state.data.enabled_for_prisma_access}
                      edit
                      errorMessage={this.state.errorMessage}
                      fawkes={this.props.fawkes}
                      initialStep={2}
                      initialFolderPath={sftp_path}
                      initialHostname={sftp_host_name}
                      initialPortNumber={sftp_port}
                      initialPrivateKey={sftp_private_key?.replace(/(\\n)/g, '\n').replace(/(\\r)/g, '').replace(/(\r)/g, '')}
                      initialPgpKey={sftp_pgp_key?.replace(/(\\n)/g, '\n').replace(/(\\r)/g, '').replace(/(\r)/g, '')}
                      initialUsername={sftp_user_name}
                      ngfwEnabled={this.state.data.enabled_for_ngfw}
                      onModalClose={this.handleCloseModal}
                      showModal={this.state.showSftpModal}
                      updateURL={this.props.fawkes ? `/v1/dlp-ui/evidence` : '/settings.json'}
                      validateURL={this.props.fawkes ? `${this.props.lookupURL}/connection/validate` : '/settings/validate.json'}
                    />
                  }
                </>
              );
            }
          }
        } else {
          const entityName =
            this.state.data.connection_data.aws_bucket_name ||
            this.state.data.connection_data.azure_tenant_id ||
            this.state.data.connection_data.sftp_host_name;

          return (
            <>
              <img id='loader' className='mr5' src={SpinnerIcon} alt='loading' />
              {entityName}
            </>
          );
        }
      }
    }

    return (
      <div className='bucketInformation'>
        <div className='notConfigured'>
          {t('not_configured')}
        </div>

        <SingleSelect
          button={
            <Button isMenu appearance='primary' addClassName='configureBtn'>
              {t('configure_bucket')}
            </Button>
          }
          items={this.props.enableSftpBucket ? BUCKET_TYPES : BUCKET_TYPES_NO_SFTP}
          selectedKey={this.state.bucketType}
          onChange={this.handleOpenModal}
          disabled={this.props.userRole !== 'READ_WRITE'}
        />

        { this.state.showAwsModal &&
          <AwsConfigureModal
            showModal={this.state.showAwsModal}
            validateURL={this.props.fawkes ? `${this.props.lookupURL}/connection/validate` : '/settings/validate.json'}
            fawkes={this.props.fawkes}
            environment={this.props.environment}
            modalClosed={this.handleCloseModal}
          />
        }
        { this.state.showAzureModal &&
          <AzureConfigureModal
            fawkes={this.props.fawkes}
            initialState={1}
            edit={false}
            showModal={this.state.showAzureModal}
            validateURL={this.props.fawkes ? `${this.props.lookupURL}/connection/validate` : '/settings/validate.json'}
            updateURL={this.props.fawkes ? `/v1/dlp-ui/evidence` : '/settings.json'}
            modalClosed={this.handleCloseModal}
          />
        }
        { this.state.showSftpModal &&
          <SftpConfigureModal
            edit={false}
            fawkes={this.props.fawkes}
            initialStep={1}
            onModalClose={this.handleCloseModal}
            showModal={this.state.showSftpModal}
            updateURL={this.props.fawkes ? `/v1/dlp-ui/evidence` : '/settings.json'}
            validateURL={this.props.fawkes ? `${this.props.lookupURL}/connection/validate` : '/settings/validate.json'}
          />
        }
      </div>
    );
  }

  confirm = () => {
    this.setState({ processing: true });

    const { services, serviceToChange, enableService } = this.state;
    const { enabled_for_prisma_access, enabled_for_ngfw, connection_type, connection_data } = this.state.data;
    const payload = {
      connection_status: CONNECTED,
      connection_type,
      connection_data,
      flash_params: {
        action: enableService ? 'enable' : 'disable',
        service: serviceToChange
      }
    }

    if (services.includes('access')) {
      payload.enabled_for_prisma_access = serviceToChange === 'access' ? enableService : enabled_for_prisma_access;
    }
    if (services.includes('ngfw')) {
      payload.enabled_for_ngfw = serviceToChange === 'ngfw' ? enableService :  enabled_for_ngfw;
    }

    axios.put(this.props.fawkes ? '/v1/dlp-ui/evidence' : '/settings.json', payload)
      .then(() => {
        this.setState({ processing: false });

        if (this.props.fawkes) {
          toastr.success(t(`evidence_storage_${enableService ? 'enabled' : 'disabled'}`, {service: serviceToChange === 'access' ? 'Prisma Access' : 'NGFW'}));
          if (serviceToChange === 'access') {
            this.setState(prevState => ({
              showConfirmModal: false,
              data: {...prevState.data, enabled_for_prisma_access: enableService}
            }));
          }
          else {
            this.setState(prevState => ({
              showConfirmModal: false,
              data: {...prevState.data, enabled_for_ngfw: enableService}
            }));
          }
        }
        else {
          window.location.assign('/settings/');
        }
      })
      .catch(() => {
        this.setState({ processing: false });

        if (this.props.fawkes) {
          toastr.success(translate('errors.server_error'));
          this.setState({ showConfirmModal: false });
        }
        else {
          window.location.assign('/settings/');
        }
      });
  };

  serviceEnabled = (service) => {
    if (Object.keys(this.state.data).length > 0) {
      if (this.state.data.connection_status === CONNECTED) {
        const enabled = service === 'access' ? this.state.data.enabled_for_prisma_access : this.state.data.enabled_for_ngfw;

        return (
          <div key={service} style={{padding: '5px'}}>
            <span className='mr5'><ToggleSwitch sliderDisabled={this.props.userRole !== 'READ_WRITE'} changeAction={() => this.updateStatus(service, !enabled)} checked={enabled} /></span>
            {enabled ? `Enabled for ${service === 'access' ? 'Prisma Access' : 'NGFW'}` : `Disabled for ${service === 'access' ? 'Prisma Access' : 'NGFW'}`}
          </div>
        )
      }
    }

    return (
      <div key={service} style={{padding: '5px'}}>
        <Popup trigger={<span className='mr5'><ToggleSwitch sliderDisabled /></span>} position='bottom center' on='hover'>
          <div className="popup-box">{t('configure_to_enable')}</div>
        </Popup>
        Disabled for {service === 'access' ? 'Prisma Access' : 'NGFW'}
      </div>
    );
  };

  updateStatus = (service, enable) => {
    this.setState({
      serviceToChange: service,
      enableService: enable,
      showConfirmModal: true
    });
  };

  confirmModals = () => {
    const { enableService, serviceToChange } = this.state;

    return (
      <IntlProvider locale='en'>
        <Modal size="sm" style={{fontSize: '12px'}} confirmClose={false} onClose={() => this.setState({ showConfirmModal: false })} isOpen={this.state.showConfirmModal}>
          <ModalHeader title={t('title')} enableClose>
            {enableService ? t('confirm_enable_modal.title', {service: t(serviceToChange)}) : t('confirm_disable_modal.title', {service: t(serviceToChange)})}
          </ModalHeader>
          <ModalBody addClassName="confirmModal modal-dialog tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
            <div className='modal-content modal-body'>
              { enableService ?
                <div>
                  <p>{t('confirm_enable_modal.desc', {service: t(serviceToChange)})}</p>
                  <p>{t('confirm_enable_modal.confirm_message', {service: t(serviceToChange)})}</p>
                </div>
              :
                <div>
                  <p>{t('confirm_disable_modal.desc', {service: t(serviceToChange)})}</p>
                  <p>{t('confirm_disable_modal.confirm_message', {service: t(serviceToChange)})}</p>
                </div>
              }
            </div>
          </ModalBody>
          <ModalFooter>
            <span className="tw-flex tw-justify-end tw-space-x-1">
              <Button appearance={enableService? 'primary': 'primary-destructive'} disabled={this.state.processing} addClassName='confirmBtn' onClick={() => this.confirm()} >
                {enableService ? translate('actions.enable') : translate('actions.disable')}
              </Button>
            </span>

          </ModalFooter>
        </Modal>
      </IntlProvider>
    )
  };

  renderServiceStatus = () => (
    <div>
      <hr />
      <div className='formRow infoRow'>
        <div className='formLabel'>
          Sensitive Files
        </div>
        <div>
          { this.state.services.map(service => this.serviceEnabled(service)) }
        </div>
      </div>
    </div>
  );

  render () {
    return (
      <div className='bucketSettings formCommon'>
        <div>
          <h5>{t('evidence_storage')}</h5>
          <p>{t('storage_desc')}</p>
        </div>
        <hr />
        <div className='formRow'>
          <div className='formLabel'>
            { t('bucket') }
          </div>
          <div>
            { this.renderBucketState() }
          </div>
        </div>
        { this.state.services.length > 0 &&
          <hr /> && this.renderServiceStatus()
        }
        { this.state.data.connection_status === CONNECTED &&
          this.confirmModals()
        }
      </div>
    );
  }
}

Settings.propTypes = {
  fawkes: PropTypes.bool,
  lookupURL: PropTypes.string,
  services: PropTypes.array,
  userRole: PropTypes.string,
  enableSftpBucket: PropTypes.bool,
  environment: PropTypes.string.isRequired,
};

Settings.defaultProps = {
  fawkes: true,
  lookupURL: undefined,
  services: [],
  userRole: 'READ_ONLY',
  enableSftpBucket: false,
}

export default Settings;