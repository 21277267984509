import React from 'react';
import PropTypes from 'prop-types';
import { FaExpandAlt, FaExclamationTriangle } from 'react-icons/fa';
import axios from 'axios';
import $ from 'jquery';
import Modal from '../Modal/Modal';
import translate from '../../helpers/translate';
import SpinnerIcon from '../../../assets/images/spinner.gif';
import EmptyListIcon from '../../../assets/images/empty-list.svg';

function t(scope, options) {
  return translate(`incidents.show.${scope}`, options);
}

class MatchingDataPatterns extends React.Component {
  state = {
    snippets: {},
    snippetStatus: 'notRequested',
    highMatches: this.props.highMatches,
    medMatches: this.props.medMatches,
    lowMatches: this.props.lowMatches,
    currentMatches: this.props.currentMatches || this.props.highMatches,
    confidence: this.props.confidence,
    report_id: this.props.report_id,
    viewAll: false,
  };

  componentDidMount () {
    this.setState({ snippetStatus: 'loading' });
    axios(
      `/snippets/${this.state.report_id}.json`
    ).then(
      result => {
        if (result.data.snippets) {
          this.setState({ snippets: result.data.snippets, snippetStatus: 'loaded' });
        }
        else {
          this.setState({ snippetStatus: 'error' })
        }
      }
    );
  }

  truncateSnippet = (str, num) => {
    if (num <= 0) { return '' }
    if (str.length <= num) { return str; }
    return `${str.slice(0, num)} ...`;
  }

  minSnippetView = (detections) => {
    let remainingSpace = 250;
    const snippets = [];
    if (detections) {
      detections.forEach(({ left, detection, right }, idx) => {
        left = left || '';
        right = right || '';
        const allowedSpace = remainingSpace;
        remainingSpace -= detection.length;
        if (allowedSpace > 0) {
          snippets.push(
            <span className='snippet' key={idx}>
              <span>{this.truncateSnippet(left, remainingSpace)} </span>
              {remainingSpace - left.length > 0 && <span className='highlight'>{this.truncateSnippet(detection, allowedSpace)}</span> }
              <span> {this.truncateSnippet(right, remainingSpace - left.length - detection.length)} </span>
            </span>
          );
        }
        remainingSpace = remainingSpace - left.length - detection.length - right.length;
      });
    }
    return snippets;
  }

  maxSnippetView = (detections) => {
    return (
      <div className='snippets'>
        {(detections || []).map((detection, idx) =>
          <span className='snippet' key={idx}>
            <span>{detection.left} </span>
            <span className='highlight'>{detection.detection}</span>
            <span> {detection.right} </span>
            <br/ ><br/ >
          </span>
        )}
      </div>
    )
  }

  showMore = () => {
    this.setState({ viewAll: true });
    $('.viewMore').hide();
  }

  edmColumns = (pattern) => {
    if (pattern.edm_columns && pattern.edm_columns.length) {
      return (
        <div>
          <span className='edmColumnHeader tw-mr-2'>{t('matched_columns')}:</span>
          <span className='edmColumnValue'>{pattern.edm_columns.join(', ')}</span>
        </div>
      );
    }
    return (<></>);
  }

  patternRows = (confidence) => {
    let matches = this.state.highMatches;
    if (confidence === 'medium_confidence_frequency') { matches = this.state.medMatches }
    if (confidence === 'low_confidence_frequency') { matches = this.state.lowMatches }
    const rows = [];
    Object.keys(matches).forEach((id, count) => {
      const snippets = this.state.snippets ? this.state.snippets[id] : null;
      const pattern = matches[id];
      rows.push(
        <tr key={id} className={count < 5 || this.state.viewAll ? 'visible' : 'hidden'}>
          <td className='col-md-4'>
            <div className='patternName'>{`${pattern.technique === 'edm' ? '' : 'Data Pattern - '}${pattern.name}`}</div>
            {
              this.edmColumns(pattern)
            }
            <span className='occurrenceCount'>{pattern[confidence]} {t('occurrences')}</span>
          </td>
          { this.state.snippetStatus === 'loaded' ?
            <td className='col-md-8 snippetResults'>
              {
                snippets ?
                <div className='snippets min'>
                  <FaExpandAlt className='tw-float-right muteText expandAlt' onClick={() => $(`#snippets-${id}`).modal('show')} />
                  { this.minSnippetView(snippets) }
                  <Modal
                    key={`snippets-${id}`}
                    compId={`snippets-${id}`}
                    modalHeader={t('view_snippet')}
                    modalBody={
                      <div>
                        <div className='patternName'>{pattern.name}</div>
                        {this.edmColumns(pattern)}
                        <div className='muteText'>{t('occurrences_by_confidence')}
                          <div className='occurrenceList'>
                            <span className='tabRight'>{t('high')}: {pattern.high_confidence_frequency}</span>
                            <span className='tabRight'>{t('medium')}: {pattern.medium_confidence_frequency}</span>
                            <span className='tabRight'>{t('low')}: {pattern.low_confidence_frequency}</span>
                          </div>
                        </div>
                        { this.maxSnippetView(snippets) }
                      </div>
                    }
                  />
                </div>
                :
                <div className='snippetsCenter'>
                  <div>
                    <FaExclamationTriangle className='warning mr5' />
                    {t('not_found')}
                  </div>
                </div>
              }
            </td>
            :
            <td className='col-md-8 snippetResults'>
              <div className='snippetsCenter'>
                  <div>
                    <img id='loader' src={SpinnerIcon} alt='loading' />
                  </div>
                </div>
            </td>
         }
        </tr>
      );
    });
    return rows;
  }

  render() {
    const { highMatches, medMatches, lowMatches, confidence, currentMatches } = this.state;
    return (
      <div className='dataPatterns'>
        <div className='card-title patternTitle'>
          {t('matches_within_profile')}
        </div>
        <div className='subHeading'>
          <span className='subHeaderTab muteText'>{t('confidence_level')}:</span>
          <span className={`subHeaderTab confSelector ${confidence === 'high_confidence_frequency' && 'active'}`} onClick={()=> this.setState({confidence: 'high_confidence_frequency', currentMatches: highMatches })}>
            {t('high')}
            <span className='helpText'>  ({Object.keys(highMatches).length} Patterns)</span>
          </span>
          <span className={`subHeaderTab confSelector ${confidence === 'medium_confidence_frequency' && 'active'}`} onClick={()=> this.setState({confidence: 'medium_confidence_frequency', currentMatches: medMatches })}>
            {t('medium')}
            <span className='helpText'>  ({Object.keys(medMatches).length} Patterns)</span>
          </span>
          <span className={`subHeaderTab confSelector ${confidence === 'low_confidence_frequency' && 'active'}`} onClick={()=> this.setState({confidence: 'low_confidence_frequency', currentMatches: lowMatches })}>
            {t('low')}
            <span className='helpText'>  ({Object.keys(lowMatches).length} Patterns)</span>
          </span>
        </div>
        { Object.keys(currentMatches).length > 0 ?
          <table className='table table-condensed matchTable'>
            <tbody>
              { this.patternRows(confidence, currentMatches)}
              { Object.keys(currentMatches).length > 5 &&
                <tr className='viewMore'>
                  <td className='btn-link' onClick={() => this.showMore()}>
                    {t('show_more', {count: Object.keys(currentMatches).length - 5})}
                  </td>
                  <td />
                </tr>
              }
            </tbody>
          </table>
          :
          <div className='empty text-center'>
            <img src={EmptyListIcon} alt='empty' />
            <br /><br />
            <p>{t('no_matches_within_profile')}</p>
          </div>
        }
      </div>
    );
  }
}

MatchingDataPatterns.propTypes = {
  highMatches: PropTypes.shape({}),
  medMatches: PropTypes.shape({}),
  lowMatches: PropTypes.shape({}),
  currentMatches: PropTypes.shape({}),
  confidence: PropTypes.string,
  report_id: PropTypes.string,
};

MatchingDataPatterns.defaultProps = {
  highMatches: {},
  medMatches: {},
  lowMatches: {},
  currentMatches: {},
  confidence: 'high_confidence_frequency',
  report_id: '',
};

export default MatchingDataPatterns;