/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  LoadingButton,
  Radio,
  RadioGroup
} from '@panwds/react-ui';

import translate from '../../../helpers/translate';
import toastWrapper from '../../../helpers/toastr';
import SpinnerIcon from '../../../../assets/images/spinner.gif';
import {
  ALLOW,
  BLOCK,
  FILE_SETTINGS_TYPE,
  NON_FILE_SETTINGS_TYPE
} from '../../constants';

const t = (scope, options) => translate(`data_transfer.dlp.${scope}`, options);

const DlpView = (props) => {
  const [loading, setLoading] = useState(props.loading);
  const [actionOnError, setActionOnError] = useState(props.actionOnError);
  const [saveButtonStatus, setSaveButtonStatus] = useState({ loading: false });

  useEffect(() => {
    setLoading(props.loading);
    setActionOnError(props.actionOnError);
  }, [props]);

  const saveData = async () => {
    setSaveButtonStatus({ loading: true });

    try{
      const response = await Promise.all([
        axios.put('/v1/dlp-ui/data-filtering-profile/setting', {
          type: FILE_SETTINGS_TYPE,
          action_on_error: actionOnError
        }),
        axios.put('/v1/dlp-ui/data-filtering-profile/setting', {
          type: NON_FILE_SETTINGS_TYPE,
          action_on_error: actionOnError
        })
      ]);

      if (response) {
        props.updateSettings({
          actionOnError: response[0]?.data?.action_on_error
        });
      }

      toastWrapper.success(t('save_success'));
    } catch (error) {
      toastWrapper.error(t('server_error'));
    } finally {
      setSaveButtonStatus({ loading: false });
    }
  };

  const isFormUpdated = () => (
    (actionOnError !== props.actionOnError)
  );

  return (
    <div className='settings-container dlp-settings'>
      { loading &&
        <div className='empty text-center'>
          <img id='loader' src={SpinnerIcon} alt='loading' />
          <br /><br />
          <p>{t('loading')}</p>
        </div>
      }

      { !loading &&
        <>
          <div className='header-section'>
            <h5>{t('title')}</h5>
            <div className='help-text'>{t('description')}</div>
          </div>

          <div className='data-section'>
            <div className='left-col'>
              <div className='text-label'>{t('action_on_error')}</div>
            </div>

            <div className='right-col'>
              <RadioGroup
                name='actionOnError'
                value={actionOnError}
                horizontal
                className='radio-horizontal'
                onChange={(event) => {
                  setActionOnError(event.target.value);
                }}
                disabled={props.userRole !== 'READ_WRITE'}
              >
                <Radio value={ALLOW}>{t('allow')}</Radio>
                <Radio value={BLOCK}>{t('block')}</Radio>
              </RadioGroup>
            </div>
          </div>

          <div className='footer-section'>
            {props.userRole === 'READ_WRITE' &&
              <LoadingButton
                dataMetrics='loading-primary-button'
                onClickCallback={saveData}
                dataResult={saveButtonStatus}
                appearance='primary'
                disabled={!isFormUpdated()}
              >
                {t('save')}
              </LoadingButton>
            }
          </div>
        </>
      }
    </div>
  );
}

DlpView.propTypes = {
  loading: PropTypes.bool.isRequired,
  actionOnError: PropTypes.string.isRequired,
  updateSettings: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired
}

export default DlpView;