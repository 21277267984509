import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import ReportDetails from './ReportDetails';
import ReportCard from './ReportCard';
import MatchingDataPatterns from './MatchingDataPatterns';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<ReportDetails> with data patterns', () => {
  let wrapper;
  const props = {
    userRole: 'READ_WRITE',
    report: {
      report_id: '12345',
      file_name: 'Test Asset',
      asset_id: '987654',
      data_profile_name: 'Bank Account Number North American',
      channel: 'Prisma Access',
      detection_time: '2020-07-09T20:56:14.620Z',
      file_size_in_bytes: 45986,
      data_pattern_results: [
        { 'data_pattern_id': 'pattern1', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
        { 'data_pattern_id': 'pattern2', 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 },
        { 'data_pattern_id': 'pattern3', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
      ]
    }
  };
  const expHighMatches = {
    'pattern2': { 'data_pattern_id': 'pattern2', 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 }
  };
  const expMedMatches = {
    'pattern1': { 'data_pattern_id': 'pattern1', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
    'pattern3': { 'data_pattern_id': 'pattern3', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
  };
  const expLowMatches = {
    'pattern1': { 'data_pattern_id': 'pattern1', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
    'pattern2': { 'data_pattern_id': 'pattern2', 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 },
    'pattern3': { 'data_pattern_id': 'pattern3', 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
  };
  const patternProps = {
    highMatches: expHighMatches,
    medMatches: expMedMatches,
    lowMatches: expLowMatches,
    report_id: '12345',
    confidence: 'high_confidence_frequency',
    currentMatches: expHighMatches
  }

  it('renders report details with data pattern widget when present', () => {
    wrapper = shallow(<ReportDetails {...props} />);
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.containsMatchingElement(<ReportCard {...props} />)).toEqual(true);
    expect(wrapper.containsMatchingElement(<MatchingDataPatterns {...patternProps} />)).toEqual(true);
  });
});

describe('<ReportDetails> without data patterns', () => {
  let wrapper;
  const props = {
    userRole: 'READ_WRITE',
    report: {
      report_id: '12345',
      file_name: 'Test Asset',
      asset_id: '987654',
      data_profile_name: 'Bank Account Number North American',
      channel: 'Prisma Access',
      detection_time: '2020-07-09T20:56:14.620Z',
      file_size_in_bytes: 45986,
      data_pattern_results: []
    }
  };

  it('does not render data pattern widget without data pattern results', () => {
    wrapper = shallow(<ReportDetails {...props} />);
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.containsMatchingElement(<ReportCard {...props} />)).toEqual(true);
    expect(wrapper.containsMatchingElement(<MatchingDataPatterns />)).toEqual(false);
  });
});