// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataProfileFormMulti> create form Component has expected classes 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={false}
  />
  <div
    className="empty text-center"
  >
    <img
      alt="loading"
      id="loader"
      style={
        Object {
          "marginTop": "100px",
          "width": "75px",
        }
      }
    />
    <br />
    <br />
    <p>
      Loading...
    </p>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;

exports[`<DataProfileFormMulti> update form Component has expected classes 1`] = `
<div>
  <BreadcrumbHeader
    clone={false}
    dataProfile={true}
  />
  <div
    className="empty text-center"
  >
    <img
      alt="loading"
      id="loader"
      style={
        Object {
          "marginTop": "100px",
          "width": "75px",
        }
      }
    />
    <br />
    <br />
    <p>
      Loading...
    </p>
  </div>
  <div
    className="formFooter"
  >
    <div
      className="tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1"
    >
      <Button
        appearance="secondary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Cancel
      </Button>
      <Button
        appearance="primary"
        buttonType="default"
        disabled={false}
        isMenu={false}
        onClick={[Function]}
        size="md"
      >
        Save
      </Button>
    </div>
  </div>
</div>
`;
