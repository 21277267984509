// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DataPatternRules /> edit v1 setup matches snapshot 1`] = `
<DocumentFragment>
  <div
    class="formCommon dataPatternConditions"
  >
    <p
      class="form-label conditionLabel"
    >
      Data Pattern Conditions
    </p>
    <div>
      <div
        class="formCommon v1Form"
      >
        <div
          class="condition"
        >
          <div>
            <div
              class="ruleItem"
            >
              <div
                class="tw-float-right"
              >
                <svg
                  class="muteText removeBtn"
                  fill="currentColor"
                  height="1em"
                  stroke="currentColor"
                  stroke-width="0"
                  viewBox="0 0 352 512"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  />
                </svg>
              </div>
              <table
                class="occurrenceFields 0-0"
              >
                <tbody>
                  <tr>
                    <td>
                      <div
                        class="identifier-dropdown false css-2b097c-container"
                      >
                        <span
                          aria-atomic="false"
                          aria-live="polite"
                          aria-relevant="additions text"
                          class="css-1f43avz-a11yText-A11yText"
                        />
                        <div
                          class=" css-1y0yxj9-control"
                        >
                          <div
                            class=" css-l1xr2i-Component"
                          >
                            <div
                              class=" css-1uccc91-singleValue"
                            >
                              CCN
                            </div>
                            <div
                              class="css-xry0bu-Component"
                            >
                              <div
                                class=""
                                style="display: inline-block;"
                              >
                                <input
                                  aria-autocomplete="list"
                                  autocapitalize="none"
                                  autocomplete="off"
                                  autocorrect="off"
                                  id="react-select-7-input"
                                  spellcheck="false"
                                  style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                  tabindex="0"
                                  type="text"
                                  value=""
                                />
                                <div
                                  style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class=" css-ny0e4k-Component"
                          >
                            <span
                              class=" css-ds71as-Component"
                            />
                            <div
                              aria-hidden="true"
                              class=" css-tlfecz-indicatorContainer"
                            >
                              <svg
                                aria-hidden="true"
                                class="css-tj5bde-Svg"
                                focusable="false"
                                height="20"
                                viewBox="0 0 20 20"
                                width="20"
                              >
                                <path
                                  d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        class="dd-menu dd-menu-left"
                      >
                        <div>
                          <button
                            aria-expanded="false"
                            class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                            data-testid="button-false"
                            type="button"
                          >
                            <span>
                              Any
                            </span>
                            <span
                              class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <svg
                                aria-hidden="true"
                                class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                focusable="false"
                                role="img"
                                viewBox="0 0 256 512"
                              >
                                <path
                                  d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div
                          class="dd-menu-items"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="dd-menu dd-menu-left"
                      >
                        <div>
                          <button
                            aria-expanded="false"
                            class="basic-dropdown false confidence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                            data-testid="button-false"
                            type="button"
                          >
                            <span>
                              High
                            </span>
                            <span
                              class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <svg
                                aria-hidden="true"
                                class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                focusable="false"
                                role="img"
                                viewBox="0 0 256 512"
                              >
                                <path
                                  d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div
                          class="dd-menu-items"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="v1OperatorGroup"
        >
          <div
            class="dd-menu dd-menu-left"
          >
            <div>
              <button
                aria-expanded="false"
                class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                data-testid="button-false"
                type="button"
              >
                <span>
                  All (AND)
                </span>
                <span
                  class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                >
                  <svg
                    aria-hidden="true"
                    class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                    focusable="false"
                    role="img"
                    viewBox="0 0 256 512"
                  >
                    <path
                      d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div
              class="dd-menu-items"
            />
          </div>
          <div
            class="condition"
          >
            <div>
              <div
                class="ruleItem"
              >
                <div
                  class="tw-float-right"
                >
                  <svg
                    class="muteText removeBtn"
                    fill="currentColor"
                    height="1em"
                    stroke="currentColor"
                    stroke-width="0"
                    viewBox="0 0 352 512"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                    />
                  </svg>
                </div>
                <table
                  class="occurrenceFields 0-0"
                >
                  <tbody>
                    <tr>
                      <td>
                        <div
                          class="identifier-dropdown false css-2b097c-container"
                        >
                          <span
                            aria-atomic="false"
                            aria-live="polite"
                            aria-relevant="additions text"
                            class="css-1f43avz-a11yText-A11yText"
                          />
                          <div
                            class=" css-1y0yxj9-control"
                          >
                            <div
                              class=" css-l1xr2i-Component"
                            >
                              <div
                                class=" css-1uccc91-singleValue"
                              >
                                SSN
                              </div>
                              <div
                                class="css-xry0bu-Component"
                              >
                                <div
                                  class=""
                                  style="display: inline-block;"
                                >
                                  <input
                                    aria-autocomplete="list"
                                    autocapitalize="none"
                                    autocomplete="off"
                                    autocorrect="off"
                                    id="react-select-8-input"
                                    spellcheck="false"
                                    style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                    tabindex="0"
                                    type="text"
                                    value=""
                                  />
                                  <div
                                    style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class=" css-ny0e4k-Component"
                            >
                              <span
                                class=" css-ds71as-Component"
                              />
                              <div
                                aria-hidden="true"
                                class=" css-tlfecz-indicatorContainer"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="css-tj5bde-Svg"
                                  focusable="false"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  width="20"
                                >
                                  <path
                                    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          class="dd-menu dd-menu-left"
                        >
                          <div>
                            <button
                              aria-expanded="false"
                              class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                              data-testid="button-false"
                              type="button"
                            >
                              <span>
                                Between (inclusive)
                              </span>
                              <span
                                class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable="false"
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div
                            class="dd-menu-items"
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          class="form-control occurrenceInput "
                          placeholder="Low"
                          value="5"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control occurrenceInput "
                          placeholder="High"
                          value="25"
                        />
                      </td>
                      <td>
                        <div
                          class="dd-menu dd-menu-left"
                        >
                          <div>
                            <button
                              aria-expanded="false"
                              class="basic-dropdown false confidence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                              data-testid="button-false"
                              type="button"
                            >
                              <span>
                                High
                              </span>
                              <span
                                class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable="false"
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div
                            class="dd-menu-items"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          class="v1OperatorGroup"
        >
          <div
            class="dd-menu dd-menu-left"
          >
            <div>
              <button
                aria-expanded="false"
                class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                data-testid="button-false"
                type="button"
              >
                <span>
                  Exclude any (OR NOT)
                </span>
                <span
                  class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                >
                  <svg
                    aria-hidden="true"
                    class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                    focusable="false"
                    role="img"
                    viewBox="0 0 256 512"
                  >
                    <path
                      d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div
              class="dd-menu-items"
            />
          </div>
          <div
            class="condition"
          >
            <div>
              <div
                class="ruleItem"
              >
                <div
                  class="tw-float-right"
                >
                  <svg
                    class="muteText removeBtn"
                    fill="currentColor"
                    height="1em"
                    stroke="currentColor"
                    stroke-width="0"
                    viewBox="0 0 352 512"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                    />
                  </svg>
                </div>
                <table
                  class="occurrenceFields 0-0"
                >
                  <tbody>
                    <tr>
                      <td>
                        <div
                          class="identifier-dropdown false css-2b097c-container"
                        >
                          <span
                            aria-atomic="false"
                            aria-live="polite"
                            aria-relevant="additions text"
                            class="css-1f43avz-a11yText-A11yText"
                          />
                          <div
                            class=" css-1y0yxj9-control"
                          >
                            <div
                              class=" css-l1xr2i-Component"
                            >
                              <div
                                class=" css-z0s8u7-placeholder"
                              >
                                Data Pattern
                              </div>
                              <div
                                class="css-xry0bu-Component"
                              >
                                <div
                                  class=""
                                  style="display: inline-block;"
                                >
                                  <input
                                    aria-autocomplete="list"
                                    autocapitalize="none"
                                    autocomplete="off"
                                    autocorrect="off"
                                    id="react-select-9-input"
                                    spellcheck="false"
                                    style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                    tabindex="0"
                                    type="text"
                                    value=""
                                  />
                                  <div
                                    style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class=" css-ny0e4k-Component"
                            >
                              <span
                                class=" css-ds71as-Component"
                              />
                              <div
                                aria-hidden="true"
                                class=" css-tlfecz-indicatorContainer"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="css-tj5bde-Svg"
                                  focusable="false"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  width="20"
                                >
                                  <path
                                    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          class="dd-menu dd-menu-left"
                        >
                          <div>
                            <button
                              aria-expanded="false"
                              class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                              data-testid="button-false"
                              type="button"
                            >
                              <span>
                                Less than or equal to
                              </span>
                              <span
                                class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable="false"
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div
                            class="dd-menu-items"
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          class="form-control occurrenceInput "
                          placeholder="Count"
                          value="12"
                        />
                      </td>
                      <td>
                        <div
                          class="dd-menu dd-menu-left"
                        >
                          <div>
                            <button
                              aria-expanded="false"
                              class="basic-dropdown false confidence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                              data-testid="button-false"
                              type="button"
                            >
                              <span>
                                Low
                              </span>
                              <span
                                class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable="false"
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div
                            class="dd-menu-items"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          class="v1OperatorGroup"
        >
          <div
            class="dd-menu dd-menu-left"
          >
            <div>
              <button
                aria-expanded="false"
                class="basic-dropdown false  tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                data-testid="button-false"
                type="button"
              >
                <span>
                  Any (OR)
                </span>
                <span
                  class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                >
                  <svg
                    aria-hidden="true"
                    class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                    focusable="false"
                    role="img"
                    viewBox="0 0 256 512"
                  >
                    <path
                      d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div
              class="dd-menu-items"
            />
          </div>
          <div
            class="condition"
          >
            <div>
              <div
                class="ruleItem"
              >
                <div
                  class="tw-float-right"
                >
                  <svg
                    class="muteText removeBtn"
                    fill="currentColor"
                    height="1em"
                    stroke="currentColor"
                    stroke-width="0"
                    viewBox="0 0 352 512"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                    />
                  </svg>
                </div>
                <table
                  class="occurrenceFields 0-0"
                >
                  <tbody>
                    <tr>
                      <td>
                        <div
                          class="identifier-dropdown false css-2b097c-container"
                        >
                          <span
                            aria-atomic="false"
                            aria-live="polite"
                            aria-relevant="additions text"
                            class="css-1f43avz-a11yText-A11yText"
                          />
                          <div
                            class=" css-1y0yxj9-control"
                          >
                            <div
                              class=" css-l1xr2i-Component"
                            >
                              <div
                                class=" css-z0s8u7-placeholder"
                              >
                                Data Pattern
                              </div>
                              <div
                                class="css-xry0bu-Component"
                              >
                                <div
                                  class=""
                                  style="display: inline-block;"
                                >
                                  <input
                                    aria-autocomplete="list"
                                    autocapitalize="none"
                                    autocomplete="off"
                                    autocorrect="off"
                                    id="react-select-10-input"
                                    spellcheck="false"
                                    style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                    tabindex="0"
                                    type="text"
                                    value=""
                                  />
                                  <div
                                    style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class=" css-ny0e4k-Component"
                            >
                              <span
                                class=" css-ds71as-Component"
                              />
                              <div
                                aria-hidden="true"
                                class=" css-tlfecz-indicatorContainer"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="css-tj5bde-Svg"
                                  focusable="false"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  width="20"
                                >
                                  <path
                                    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          class="dd-menu dd-menu-left"
                        >
                          <div>
                            <button
                              aria-expanded="false"
                              class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                              data-testid="button-false"
                              type="button"
                            >
                              <span>
                                More than or equal to
                              </span>
                              <span
                                class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable="false"
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div
                            class="dd-menu-items"
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          class="form-control occurrenceInput "
                          placeholder="Count"
                          value="10"
                        />
                      </td>
                      <td>
                        <div
                          class="dd-menu dd-menu-left"
                        >
                          <div>
                            <button
                              aria-expanded="false"
                              class="basic-dropdown false confidence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                              data-testid="button-false"
                              type="button"
                            >
                              <span
                                class="placeholder"
                              >
                                Confidence
                              </span>
                              <span
                                class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                  focusable="false"
                                  role="img"
                                  viewBox="0 0 256 512"
                                >
                                  <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div
                            class="dd-menu-items"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <button
          class="addDataPatternBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
          data-testid="button-add-data-pattern"
          type="button"
        >
          Add Data Pattern
        </button>
      </div>
      <div
        class="dd-menu dd-menu-left"
      >
        <div>
          <button
            aria-expanded="false"
            class="operatorBox tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-6 tw-py-2 tw-pl-2 tw-pr-0.5 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
            data-testid="button-false"
            type="button"
          >
            <span>
              AND
            </span>
            <span
              class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-0.5"
            >
              <svg
                aria-hidden="true"
                class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-3.5 tw-w-3.5 tw-transform tw-transition-transform tw-duration-200"
                focusable="false"
                role="img"
                viewBox="0 0 256 512"
              >
                <path
                  d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </button>
        </div>
        <div
          class="dd-menu-items"
        />
      </div>
    </div>
    <div>
      <div
        class="formCommon v1Form"
      >
        <div
          class="condition"
        >
          <div>
            <div
              class="ruleItem"
            >
              <div
                class="tw-float-right"
              >
                <svg
                  class="muteText removeBtn"
                  fill="currentColor"
                  height="1em"
                  stroke="currentColor"
                  stroke-width="0"
                  viewBox="0 0 352 512"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  />
                </svg>
              </div>
              <table
                class="occurrenceFields 1-0"
              >
                <tbody>
                  <tr>
                    <td>
                      <div
                        class="identifier-dropdown false css-2b097c-container"
                      >
                        <span
                          aria-atomic="false"
                          aria-live="polite"
                          aria-relevant="additions text"
                          class="css-1f43avz-a11yText-A11yText"
                        />
                        <div
                          class=" css-1y0yxj9-control"
                        >
                          <div
                            class=" css-l1xr2i-Component"
                          >
                            <div
                              class=" css-z0s8u7-placeholder"
                            >
                              Data Pattern
                            </div>
                            <div
                              class="css-xry0bu-Component"
                            >
                              <div
                                class=""
                                style="display: inline-block;"
                              >
                                <input
                                  aria-autocomplete="list"
                                  autocapitalize="none"
                                  autocomplete="off"
                                  autocorrect="off"
                                  id="react-select-11-input"
                                  spellcheck="false"
                                  style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                                  tabindex="0"
                                  type="text"
                                  value=""
                                />
                                <div
                                  style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class=" css-ny0e4k-Component"
                          >
                            <span
                              class=" css-ds71as-Component"
                            />
                            <div
                              aria-hidden="true"
                              class=" css-tlfecz-indicatorContainer"
                            >
                              <svg
                                aria-hidden="true"
                                class="css-tj5bde-Svg"
                                focusable="false"
                                height="20"
                                viewBox="0 0 20 20"
                                width="20"
                              >
                                <path
                                  d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        class="dd-menu dd-menu-left"
                      >
                        <div>
                          <button
                            aria-expanded="false"
                            class="basic-dropdown false occurrence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                            data-testid="button-false"
                            type="button"
                          >
                            <span>
                              Any
                            </span>
                            <span
                              class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <svg
                                aria-hidden="true"
                                class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                focusable="false"
                                role="img"
                                viewBox="0 0 256 512"
                              >
                                <path
                                  d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div
                          class="dd-menu-items"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="dd-menu dd-menu-left"
                      >
                        <div>
                          <button
                            aria-expanded="false"
                            class="basic-dropdown false confidence tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-pl-4 tw-pr-2 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
                            data-testid="button-false"
                            type="button"
                          >
                            <span>
                              High
                            </span>
                            <span
                              class="tw-w-5 tw-h-5 tw-grid tw-items-center tw-justify-items-center tw-ml-1"
                            >
                              <svg
                                aria-hidden="true"
                                class="tw-inline-block tw-overflow-visible tw-align-middle tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
                                focusable="false"
                                role="img"
                                viewBox="0 0 256 512"
                              >
                                <path
                                  d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div
                          class="dd-menu-items"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button
          class="addDataPatternBtn tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
          data-testid="button-add-data-pattern"
          type="button"
        >
          Add Data Pattern
        </button>
      </div>
    </div>
    <button
      class="tw-relative tw-p-0 tw-border-solid tw-font-lato tw-font-semibold tw-cursor-pointer tw-whitespace-nowrap tw-box-border tw-rounded tw-leading-none tw-outline-none focus:tw-ring-0 tw-flex tw-items-center tw-relative tw-max-w-full focus-visible:tw-custom-ring-2 dark:focus-visible:tw-dark-custom-ring-2 focus-visible:tw-ring-offset-1 active:tw-ring-0 tw-text-xs tw-h-8 tw-py-2 tw-px-4 tw-border tw-group tw-bg-white dark:tw-bg-blue-steel-950 tw-border-gray-300 dark:tw-border-blue-steel-800 dark:tw-text-blue-steel-400 active:tw-border-gray-900 dark:active:tw-border-blue-steel-500 active:tw-text-gray-900 dark:active:tw-text-blue-steel-200 active:tw-bg-gray-300 dark:active:tw-bg-blue-steel-700 focus-visible:tw-text-gray-800 dark:focus-visible:tw-text-blue-steel-300 focus-visible:tw-bg-gray-200 dark:focus-visible:tw-bg-blue-steel-800 focus-visible:tw-border-gray-800 dark:focus-visible:tw-border-blue-steel-600 hover:tw-text-gray-800 dark:hover:tw-text-blue-steel-300 hover:tw-bg-gray-200 dark:hover:tw-bg-blue-steel-800 hover:tw-border-gray-800 dark:hover:tw-border-blue-steel-600 tw-text-gray-700"
      data-testid="button-add-data-pattern-group"
      type="button"
    >
      Add Data Pattern Group
    </button>
  </div>
</DocumentFragment>
`;
