/* eslint-disable camelcase */
export function regexArrayToString(regexes, detection_technique, delimiter) {
  if (!regexes) {
    return '';
  }
  return regexes.map(item => {
    if (detection_technique === 'regex') {
      return item.regex;
    }
      return `${item.regex}${delimiter}${item.weight}`;

  }).join('\n');
}

export function regexStringToArray(regexInput, detection_technique, delimiter) {
  if (!regexInput) {
    return [];
  }
  return regexInput.split('\n').map(line => {
    if (detection_technique === 'regex') {
      return {
        regex: line,
        weight: 1
      };
    }
    const parts = line.split(delimiter);
    return {
      regex: parts[0].toString(),
      weight: parts.length > 1? parseInt(parts[1], 10) : 1
    };
  });
}