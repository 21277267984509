export function preprocessProfile(profile) {
  const action = profile.rule2? 'alert and block' : profile.rule1.action;
  return {
    ...profile,
    file_type: profile.file_type || profile.fileType,
    log_severity: profile.log_severity || profile.logSeverity,
    tenant_id: profile.tenant_id || profile.tenantId,
    created_at: profile.created_at || profile.createdDated,
    updated_at: profile.updated_at || profile.updatedDate,
    profile_type: profile.profile_type || profile.profileType,
    updated_by: profile.updated_by || profile.updatedBy,
    profile_id: profile.profile_id || profile.dataProfileId,
    fileBased: profile.fileBased || profile['file-based'],
    nonFileBased: profile.nonFileBased || profile['non-file-based'],
    action
  };
}
