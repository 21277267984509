import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from '../DropDown/DropDownList';
import regions from './regions';

const RegionDropdown = (props) => {
  const { value, onSelect } = props;
  return (
    <DropDownList
      onSelect={onSelect}
      dropClass='regions-dropdown'
      value={value}
      items={regions}
    />
  );
}

RegionDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
};

RegionDropdown.defaultProps = {
  value: 'us-east-1'
}

export default RegionDropdown;
