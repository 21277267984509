/* eslint-disable camelcase */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@panwds/react-ui';
import translate from '../../helpers/translate';
import BucketSettings from './BucketSettings';
import ApiTokenTable from './ApiTokenTable/ApiTokenTable';
import SnippetsConfig from '../SnippetsConfig/SnippetsConfig';
import DataTransfer from './DataTransfer';
import AlertSettings from './AlertSettings';
import UserInfo from './UserInfo';

import {
  DATA_TRANSFER,
  isPanoramaSettingsEnabled,
  isSftpBucketEnabled
} from '../constants';

const Settings = (
  {
    userInfo,
    snippetLookupURL,
    bucketLookupURL,
    apiLookupURL,
    initialPage,
    services,
    BlockResponse,
    fawkes,
    saasEnterpriseOnly,
    apiParams,
    settingsPermissions,
    portalType,
    emailDlpEnabled,
    environment,
    nebulaFlag,
    panosVersion
  }) => {
  const [currentView, setCurrentView] = useState(saasEnterpriseOnly && apiParams.showApiTokens ? 'apiTokens' : initialPage || 'sensitiveData');
  const [tokenLookupURL, setTokenLookupURL] = useState(apiLookupURL);

  const switchToApiTokens = () => {
    setCurrentView('apiTokens');
    setTokenLookupURL(apiLookupURL.split('?')[0]);
  }

  return (
    <div className='settings'>
      <div className='settingDetails tw-float-right'>
        {currentView === 'sensitiveData' && settingsPermissions.sensitive_data !== 'NO_ACCESS' &&
          <div>
            { portalType !== 'casb' && <SnippetsConfig lookupURL={snippetLookupURL} userRole={settingsPermissions.sensitive_data} services={services} />}
            <BucketSettings
              lookupURL={bucketLookupURL}
              services={services}
              fawkes={fawkes}
              userRole={settingsPermissions.sensitive_data}
              enableSftpBucket={isSftpBucketEnabled}
              environment={environment}
            />
          </div>
        }
        {currentView === 'apiTokens' && settingsPermissions.api_tokens !== 'NO_ACCESS' &&
          <div className='api_tokens formCommon'>
            <ApiTokenTable
              lookupURL={tokenLookupURL}
              fawkes={fawkes}
              apiError={apiParams.apiError}
              tokenData={apiParams.tokenData}
              userRole={settingsPermissions.api_tokens}
            />
          </div>
        }
        {currentView === 'dataFiltering' && settingsPermissions.data_filtering !== 'NO_ACCESS' &&
          <div>
            <BlockResponse userRole={settingsPermissions.data_filtering} />
          </div>
        }
        { isPanoramaSettingsEnabled && fawkes && currentView === DATA_TRANSFER && settingsPermissions.data_transfer !== 'NO_ACCESS' &&
          <DataTransfer nebulaFlag={nebulaFlag} panosVersion={panosVersion} userRole={settingsPermissions.data_transfer} />
        }
        {currentView === 'notifications' && settingsPermissions.alert !== 'NO_ACCESS' &&
          <div className='notifications'>
            <AlertSettings fawkes={fawkes} userRole={settingsPermissions.alert} />
          </div>
        }
        {currentView === 'userInfo' &&
          <UserInfo
            userInfo={userInfo}
            panosVersion={panosVersion}
          />
        }
      </div>

      <div className='settingHeaders tw-float-left formCommon'>
        { saasEnterpriseOnly && portalType !== 'casb' &&
          <Tooltip label={translate('saas_locked')} hasTriangle>
            <div className='settingToggle disabled'>Sensitive Data</div>
          </Tooltip>
        }
        { !saasEnterpriseOnly && (portalType !== 'casb' || emailDlpEnabled) &&
            settingsPermissions.sensitive_data !== 'NO_ACCESS' &&
            <div onClick={() => setCurrentView('sensitiveData')} className={`settingToggle${currentView === 'sensitiveData' ? ' active' : ''}`}>Sensitive Data</div>
        }
        <hr />
        { fawkes && !['casb', 'panorama-casb', 'enterprise-dlp'].includes(portalType) && settingsPermissions.sensitive_data !== 'NO_ACCESS' &&
          <>
            <div onClick={() => setCurrentView('dataFiltering')} className={`settingToggle${currentView === 'dataFiltering' ? ' active' : ''}`}>Data Filtering</div>
            <hr />
          </>
        }
        { isPanoramaSettingsEnabled && fawkes && !['casb', 'panorama-casb', 'enterprise-dlp'].includes(portalType) && settingsPermissions.data_transfer !== 'NO_ACCESS' &&
          <>
            <div onClick={() => setCurrentView(DATA_TRANSFER)} className={`settingToggle${currentView === DATA_TRANSFER ? ' active' : ''}`}>Data Transfer</div>
            <hr />
          </>
        }
        {apiParams.showApiTokens && settingsPermissions.api_tokens !== 'NO_ACCESS' &&
          <>
            <div onClick={() => switchToApiTokens()} className={`settingToggle${currentView === 'apiTokens' ? ' active' : ''}`}>API Tokens</div>
            <hr />
          </>
        }
        { saasEnterpriseOnly && portalType !== 'casb' &&
            <Tooltip label={translate('saas_locked')} hasTriangle>
              <div className='settingToggle disabled'>Alerts</div>
            </Tooltip>
        }
        { !saasEnterpriseOnly && portalType !== 'casb' &&
            settingsPermissions.alert !== 'NO_ACCESS' &&
              <>
                <div onClick={() => setCurrentView('notifications')} className={`settingToggle${currentView === 'notifications' ? ' active' : ''}`}>Alerts</div>
                <hr />
              </>
        }

        { userInfo && userInfo.tenantIdDLP &&
          <div onClick={() => setCurrentView('userInfo')} className={`settingToggle${currentView === 'userInfo' ? ' active' : ''}`}>User Information</div>
        }
      </div>

    </div>
  );
}

Settings.propTypes = {
  userInfo: PropTypes.shape({
    tenantIdDLP: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string
  }),
  bucketLookupURL: PropTypes.string,
  snippetLookupURL: PropTypes.string,
  apiLookupURL: PropTypes.string,
  initialPage: PropTypes.string,
  services: PropTypes.array,
  BlockResponse: PropTypes.element,
  fawkes: PropTypes.bool,
  saasEnterpriseOnly: PropTypes.bool,
  apiParams: PropTypes.shape({
    showApiTokens: PropTypes.bool,
    apiError: PropTypes.string,
    tokenData: PropTypes.shape({})
  }),
  settingsPermissions: PropTypes.shape({
    sensitive_data: PropTypes.string,
    data_filtering: PropTypes.string,
    data_transfer: PropTypes.string,
    api_tokens: PropTypes.string,
    alert: PropTypes.string
  }),
  portalType: PropTypes.string,
  emailDlpEnabled: PropTypes.bool,
  environment: PropTypes.string.isRequired,
  nebulaFlag: PropTypes.bool,
  panosVersion: PropTypes.string
};

Settings.defaultProps = {
  userInfo: undefined,
  bucketLookupURL: undefined,
  snippetLookupURL: undefined,
  apiLookupURL: undefined,
  initialPage: undefined,
  services: [],
  BlockResponse: undefined,
  fawkes: true,
  saasEnterpriseOnly: false,
  apiParams: {
    showApiTokens: true,
    apiError: undefined,
    tokenData: {}
  },
  settingsPermissions: PropTypes.shape({
    sensitive_data: 'NO_ACCESS',
    data_filtering: 'NO_ACCESS',
    data_transfer: 'NO_ACCESS',
    api_tokens: 'NO_ACCESS',
    alert: 'NO_ACCESS'
  }),
  portalType: undefined,
  emailDlpEnabled: false,
  nebulaFlag: false,
  panosVersion: undefined
}

export default Settings;