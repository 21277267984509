import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton } from '@panwds/react-ui';
import { IntlProvider } from 'react-intl';

import $ from 'jquery';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`edm_datasets.config.${scope}`, options);
}

const EdmConfig = ({currentStatus, enableEdm, userRole}) => {
  const [edmStatus, setEdmStatus] = useState('disabled');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (currentStatus) {
      setEdmStatus(currentStatus);
    }
  }, [currentStatus]);

  useEffect(() => {
    if (edmStatus === 'requested') {
      $('.banner-area').html(
        `<div class='infoBanner'>
          <span class="fa fa-info-circle info flash-icon"></span>
          <span class="infoMessage">${t('message')}</span>
          <span class="tw-float-right close"></span>
        </div>`);
        $('.banner-area span.close').on('click', () => $('.banner-area').html(''));
    }
  }, [edmStatus]);

  const closeModal = () => {
    setModalOpen(false);
  }

  const handleChange = () => {
    if (edmStatus !== 'requested') {
      setModalOpen(true);
      enableEdm();
    }
    setEdmStatus('requested');
  }

  return (
    <div className='reactTable edmConfig settings'>
      <div className="title card-title">
        <span>
          {t('title')}
        </span>

      </div>
      <div className='edmForm'>
        <div className='enablement'>
          <div>
            <span className='form-label'>{t('subTitle')}</span>
            <p>{t('description')}</p>
          </div>
          <span className='toggle-wrapper'>
            <span className='toggle-label'>{t(edmStatus)}</span>
            <ToggleSwitch changeAction={handleChange} checked={edmStatus !== 'disabled'} sliderDisabled={userRole !== 'READ_WRITE' || edmStatus === 'requested'}/>
          </span>
        </div>

        <IntlProvider locale='en'>
          <Modal addClassName='dlp-root' size="md" confirmClose={false} onClose={closeModal} isOpen={modalOpen}>
            <ModalHeader title={t('modal_title')} enableClose />
            <ModalBody addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
              <div className="message-wrapper">
                <p>{t('message')}</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <ModalCloseButton>{t('close')}</ModalCloseButton>
            </ModalFooter>
          </Modal>
        </IntlProvider>
      </div>
    </div>
  );
}

EdmConfig.propTypes = {
  enableEdm: PropTypes.func.isRequired,
  currentStatus: PropTypes.string.isRequired,
  userRole: PropTypes.string
}

EdmConfig.defaultProps = {
  userRole: 'READ_ONLY'
}

export default EdmConfig;