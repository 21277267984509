import { expect as chaiExpect } from 'chai';

import { isFileSizeAllowed } from './helpers';

describe('isFileSizeAllowed', () => {
  it('should return false', () => {
    chaiExpect(isFileSizeAllowed()).to.be.false;
    chaiExpect(isFileSizeAllowed(1)).to.be.false;
    chaiExpect(isFileSizeAllowed({})).to.be.false;
    chaiExpect(isFileSizeAllowed({ size: 1000001 })).to.be.false;
  });

  it('should return true', () => {
    chaiExpect(isFileSizeAllowed({ size: 100000 })).to.be.true;
    chaiExpect(isFileSizeAllowed({ size: 1000000 })).to.be.true;
  });
});