import React from 'react';
import '@testing-library/react/dont-cleanup-after-each';
import { screen, waitFor, fireEvent } from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import DataPatternTable from './DataPatternTable';
import renderWithRouter, {history} from '../../helpers/setupTests';

const axiosMock = new MockAdapter(axios);
axiosMock.onDelete().reply(200, {});
const dataPatterns = [
  {
    "id": "test-id-1",
    "name": "Address - US",
    "description": "Address - US",
    "detection_technique": "regex",
    "status": "active",
    "type": "custom",
    "created_at": "2021-07-28T02:18:18.983+00:00",
    "updated_at": "2021-07-28T02:18:18.983+00:00",
    "created_by": "System",
    "updated_by": "System",
    "version": 1,
    "supported_confidence_levels": [
        "high",
        "low"
    ],
    "license_type": "standard"
  },
  {
    "id": "618ab6de73140028fb341a3e",
    "name": "test pattern 1",
    "tenant": "8794271414508985344",
    "detection_technique": "weighted_regex",
    "status": "active",
    "type": "custom",
    "delimiter": ";",
    "regexes": [
        {
            "regex": "test1",
            "weight": 1
        }
    ],
    "created_at": "2020-10-14T18:41:34.474+00:00",
    "updated_at": "2020-10-14T18:41:34.474+00:00",
    "created_by": "qa3testuser1@panw.com",
    "updated_by": "qa3testuser1@panw.com",
    "version": 1,
    "supported_confidence_levels": [
        "high",
        "low"
    ],
    "license_type": "standard"
  }
];


describe('<DataPatternTable/>', () => {
  let testUtils;
  const match = {
    path: '/test/data-patterns/'
  };
  test('Renders the title and rows', () => {
    testUtils = renderWithRouter(<DataPatternTable dataPatterns={dataPatterns} apiBaseURL='/data-patterns' history={history} match={match}/>);
    expect(testUtils.getAllByText('Data Patterns')[0]).toBeInTheDocument();
    expect(testUtils.getByText('Address - US')).toBeInTheDocument();
  });

  test('search works', () => {
    const searchBox = testUtils.container.querySelector('input.search');
    fireEvent.focus(searchBox);
    fireEvent.input(searchBox, { target: { value: 'add' } });
    fireEvent.keyPress(searchBox, {key: 'Enter', charCode: 13});
    expect(testUtils.getByText('Address - US')).toBeInTheDocument();
    expect(testUtils.queryByText('test pattern 1')).not.toBeInTheDocument();
  });

  test('viewing detail and cloning works', () => {
    const nameCell = testUtils.container.querySelector('tr > td > span.itemName');
    fireEvent.click(nameCell);
    expect(testUtils.container.querySelector('.tableDetailsView')).toBeInTheDocument();
    const cloneButton = testUtils.container.querySelector('button.clone');
    fireEvent.click(cloneButton);
    expect(testUtils.history.push).toHaveBeenCalled();
  });

  test('deleting does not work', () => {
    testUtils = renderWithRouter(<DataPatternTable dataPatterns={dataPatterns} apiBaseURL='/data-patterns' history={history} match={match}/>);
    const nameCell = testUtils.container.querySelector('tr > td > span.itemName');
    fireEvent.click(nameCell);
    expect(testUtils.container.querySelector('.tableDetailsView')).toBeInTheDocument();
    const deleteButton = testUtils.container.querySelector('button.delete');
    expect(deleteButton).not.toBeInTheDocument();
    // fireEvent.click(deleteButton);
    // const confirmButton = testUtils.getByText('Yes, Delete');
    // fireEvent.click(confirmButton);
    // expect(axiosMock.history.delete.length).toBe(1);
  });

  test('editing works', async() => {
    const nameCell = testUtils.container.querySelector('tr > td > span.itemName');
    fireEvent.click(nameCell);
    expect(testUtils.container.querySelector('.tableDetailsView')).toBeInTheDocument();
    const editButton = testUtils.container.querySelector('button.edit');
    fireEvent.click(editButton);
    expect(testUtils.history.push).toHaveBeenCalled();
  });
});
