import translate from '../../../helpers/translate';

const dropdownNumbers = [];

for (let num = 50; num < 101; num += 1 ) {
  dropdownNumbers.push({
    value: num,
    label: num
  });
}

export const occurrenceNumbers = [ ...dropdownNumbers ];

export const occurrenceDocumentTypes = [
  {
    value: 'more_than_equal_to',
    label: translate('data_profiles.form.occurrence.more_than_equal_to'),
  },
  {
    value: 'between',
    label: translate('data_profiles.form.occurrence.between'),
  },
];

export default [
  {
    value: 'any',
    label: translate('data_profiles.form.occurrence.any'),
  },
  {
    value: 'less_than_equal_to',
    label: translate('data_profiles.form.occurrence.less_than_equal_to'),
  },
  {
    value: 'more_than_equal_to',
    label: translate('data_profiles.form.occurrence.more_than_equal_to'),
  },
  {
    value: 'between',
    label: translate('data_profiles.form.occurrence.between'),
  },
];