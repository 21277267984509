import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import ClassNames from 'classnames';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton } from '@panwds/react-ui';
import { IntlProvider } from 'react-intl';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaTimesCircle
} from 'react-icons/fa';

import translate from '../../helpers/translate';
import ConnectWarning from './ConnectWarning';
import toastr from '../../helpers/toastr';

import {
  CONNECTED,
  CREATE,
  EDIT,
  SFTP
} from '../constants';

function t(scope, options) {
  return translate(`settings.sftp_configure_modal.${scope}`, options);
}

const SftpConfigureModal = ({
  accessEnabled,
  edit,
  fawkes,
  initialFolderPath,
  initialHostname,
  initialPortNumber,
  initialPrivateKey,
  initialPgpKey,
  initialStep,
  initialUsername,
  ngfwEnabled,
  onModalClose,
  showModal,
  updateURL,
  validateURL
}) => {
  const [username, setUsername] = useState(initialUsername);
  const [privateKey, setPrivateKey] = useState(initialPrivateKey);
  const [pgpKey, setPgpKey] = useState(initialPgpKey);
  const [hostname, setHostname] = useState(initialHostname);
  const [folderPath, setFolderPath] = useState(initialFolderPath);
  const [portNumber, setPortNumber] = useState(initialPortNumber);
  const [validatePresence, setValidatePresence] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeStep, setActiveStep] = useState(initialStep);
  const [modalOpen, setModalOpen] = useState(showModal);

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const sanitizeKey = (key) => {
    if (!key || key?.indexOf('\r\n') !== -1) {
      return key;
    }

    return key.replace(/(\n)/g, '\r\n');
  };

  const isRsaKeyValid = (key) => {
    if (!key) {
      return false;
    }

    return (
      key.toString().startsWith('-----BEGIN RSA PRIVATE KEY-----') &&
      key.toString().endsWith('-----END RSA PRIVATE KEY-----')
    );
  };

  const isPgpKeyValid = (key) => {
    if (!key) {
      return true;
    }

    return (
      key.toString().startsWith('-----BEGIN PGP PUBLIC KEY BLOCK-----') &&
      key.toString().endsWith('-----END PGP PUBLIC KEY BLOCK-----')
    );
  };

  const isFormValid = () => (
    !!username &&
    isRsaKeyValid(privateKey) &&
    isPgpKeyValid(pgpKey) &&
    !!hostname &&
    !!portNumber
  );

  const handleUpdateStep = (step) => {
    setActiveStep(step);
  }

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case 'username':
        setUsername(event.target.value);
        break;

      case 'privateKey':
        setPrivateKey(event.target.value);
        break;

      case 'pgpKey':
        setPgpKey(event.target.value ? event.target.value : null);
        break;

      case 'hostname':
        setHostname(event.target.value);
        break;

      case 'folderPath':
        setFolderPath(event.target.value);
        break;

      case 'portNumber':
      default:
        setPortNumber(
          !parseFloat(event.target.value) ?
            '' : parseFloat(event.target.value)
        );
        break;
    }

    setErrorMessage('');
  }

  const handleModalClose = () => {
    setModalOpen(false);
    onModalClose();
    setActiveStep(initialStep);
    setValidatePresence(false);
  }

  const renderCompletedMark = (step) => {
    if (step === 2 && errorMessage) {
      return (
        <FaExclamationTriangle className='tw-float-right error' />
      )
    }

    if (step < activeStep) {
      return (
        <FaCheckCircle className='tw-float-right' />
      )
    }

    return '';
  }

  const renderStepList = () => {
    return (
      <div className='step-list'>
        <div
          className={`step-item ${activeStep === 1 ? 'active' : 'btn-link'}`}
          onClick={() => handleUpdateStep(1)}
        >
          {t('instructions')}{renderCompletedMark(1)}
        </div>
        <div
          className={`step-item ${errorMessage && 'error'} ${activeStep === 2 ? 'active' : 'btn-link'}`}
        >
          {t('input_details')}{renderCompletedMark(2)}
        </div>
        <div
          className={`step-item ${activeStep === 3 ? 'active' : 'btn-link'}`}>
          {t('status')}
        </div>
      </div>
    )
  }

  const handleClickPrevious = () => {
    switch (activeStep) {
      case 2:
        setActiveStep(1);
        break;

      case 3:
        setActiveStep(2);
        break;

      default:
        break;
    }
  }

  const handleClickNext = () => {
    switch (activeStep) {
      case 1: {
        setActiveStep(2);
        break;
      }

      case 2: {
        if (isFormValid()) {
          const payload = {
            connection_type: SFTP,
            connection_data: {
              aws_bucket_name: null,
              aws_kms_enabled: null,
              aws_role_arn: null,
              azure_end_point: null,
              azure_tenant_id: null,
              region: null,
              sftp_host_name: hostname,
              sftp_path: folderPath,
              sftp_pgp_key: sanitizeKey(pgpKey),
              sftp_port: portNumber,
              sftp_private_key: sanitizeKey(privateKey),
              sftp_user_name: username
            }
          };

          $('.nextBtn').addClass('disabled');

          axios.post(validateURL, payload)
            .then((result) => {
              $('.nextBtn').removeClass('disabled');
              setErrorMessage(result.data.hasError === false ? '' : result?.data?.errorMessage || translate('errors.server_error'));
              setActiveStep(3);
            });
        } else {
          setValidatePresence(true);
        }
        break;
      }

      case 3:
      default: {
        $('.nextBtn').addClass('disabled');

        const payload = {
          enabled_for_prisma_access: accessEnabled,
          enabled_for_ngfw: ngfwEnabled,
          connection_status: CONNECTED,
          connection_type: SFTP,
          connection_data: {
            aws_bucket_name: null,
            aws_kms_enabled: null,
            aws_role_arn: null,
            azure_end_point: null,
            azure_tenant_id: null,
            region: null,
            sftp_host_name: hostname,
            sftp_path: folderPath,
            sftp_pgp_key: pgpKey?.replace(/(\n)/g, '\r\n'),
            sftp_port: portNumber,
            sftp_private_key: privateKey.replace(/(\n)/g, '\r\n'),
            sftp_user_name: username
          },
          flash_params: {
            action: edit ? EDIT : CREATE
          }
        };

        if (edit) {
          axios.put(updateURL, payload)
            .then(() => {
              if (fawkes) {
                toastr.success(translate('settings.index.storage_changed'));
              }
              else {
                window.location.assign('/settings/');
              }

              $('.nextBtn').removeClass('disabled');
              handleModalClose();
            })
            .catch((err) => {
              const result = err.response;

              $('.nextBtn').removeClass('disabled');
              setErrorMessage(result?.data?.errorMessage || translate('errors.server_error'));
              setActiveStep(2);
            });
        } else {
          axios.post(updateURL, payload)
            .then(() => {
              if (fawkes) {
                toastr.success(translate('settings.index.storage_changed'));
              }
              else {
                window.location.assign('/settings/');
              }

              $('.nextBtn').removeClass('disabled');
              handleModalClose();
            })
            .catch((err) => {
              const result = err.response;

              $('.nextBtn').removeClass('disabled');
              setErrorMessage(result?.data?.errorMessage || translate('errors.server_error'));
              setActiveStep(2);
            });
        }
        break;
      }
    }
  }

  const renderInstructionsView = () => (
    <div className='instructions'>
      <h3 className='modal-section-title'>{t('instructions')}</h3>

      <p>{t('instruction_list.step_one')}</p>
      <ul className='tw-mt-1 tw-pl-8'>
        <li>{t('instruction_list.provide_username')}</li>
        <li>{t('instruction_list.provide_private_key')}</li>
        <li>{t('instruction_list.provide_hostname')}</li>
        <li>{t('instruction_list.provide_port_number')}</li>
      </ul>

      <p>{t('instruction_list.step_two')}</p>
      <ul className='tw-mt-1 tw-pl-8'>
        <li>{t('instruction_list.optional_pgp_key')}</li>
        <li>{t('instruction_list.optional_folder_path')}</li>
      </ul>
    </div>
  );

  const renderBucketDetailsView = () => (
    <div className='bucketDetails sftp'>
      <h3 className='modal-section-title'>{t('bucket_details.title')}</h3>

      <div className='helpText'>{t('bucket_details.instructions')}</div>

      { edit &&
        <div className='helpText'>
          <br />
          <FaExclamationTriangle className='warning mr5' style={{width: '15px', height: '15px'}}/>
          {t('bucket_details.edit_warning')}
        </div>
      }

      <div className='errorMessage' style={{display: errorMessage ? '' : 'none'}}>
        <FaExclamationCircle className='error mr5' />
        {errorMessage}
      </div>

      <div className='bucketForm'>
        <p className={`inputLabel ${validatePresence && !username && 'error'}`}>
          {t('bucket_details.username')}<span className='required-indicator' />
        </p>
        <input
          className={`form-control username ${validatePresence && !username && 'has-error'}`}
          placeholder={t('bucket_details.username_example')}
          name='username'
          value={username}
          onChange={handleInputChange}
          data-testid='username-input'
        />
        { validatePresence && !username &&
          <p className='error errorDetail'>
            {t('bucket_details.field_required', {field: t('bucket_details.username')})}
          </p>
        }

        <p className={ClassNames('inputLabel', { error: (validatePresence && (!privateKey || !isRsaKeyValid(privateKey))) })}>
          {t('bucket_details.private_key')}<span className='required-indicator' />
        </p>
        <textarea
          className={`form-control privateKey ${validatePresence && (!privateKey || !isRsaKeyValid(privateKey)) && 'has-error'}`}
          placeholder={t('bucket_details.private_key_example')}
          name='privateKey'
          value={privateKey}
          onChange={handleInputChange}
          data-testid='privateKey-input'
          rows={6}
        />
        { validatePresence && !privateKey &&
          <p className='error errorDetail'>
            {t('bucket_details.field_required', {field: t('bucket_details.private_key')})}
          </p>
        }
        { validatePresence && privateKey && !isRsaKeyValid(privateKey) &&
          <p className='error errorDetail'>
            {t('bucket_details.field_invalid', {field: t('bucket_details.private_key')})}
          </p>
        }

        <p className={ClassNames('inputLabel', { error: (validatePresence && !isPgpKeyValid(pgpKey)) })}>
          {t('bucket_details.pgp_key')}
        </p>
        <textarea
          className={`form-control pgpKey ${validatePresence && (!privateKey || !isRsaKeyValid(pgpKey)) && 'has-error'}`}
          placeholder={t('bucket_details.pgp_key_example')}
          name='pgpKey'
          value={pgpKey}
          onChange={handleInputChange}
          data-testid='pgpKey-input'
          rows={6}
        />
        { validatePresence && !isPgpKeyValid(pgpKey) &&
          <p className='error errorDetail'>
            {t('bucket_details.field_invalid', {field: t('bucket_details.pgp_key')})}
          </p>
        }

        <p className={`inputLabel ${validatePresence && !hostname && 'error'}`}>
          {t('bucket_details.hostname')}<span className='required-indicator' />
        </p>
        <input
          className={`form-control hostname ${validatePresence && !hostname && 'has-error'}`}
          placeholder={t('bucket_details.hostname_example')}
          name='hostname'
          value={hostname}
          onChange={handleInputChange}
          data-testid='hostname-input'
        />
        { validatePresence && !hostname &&
          <p className='error errorDetail'>
            {t('bucket_details.field_required', {field: t('bucket_details.hostname')})}
          </p>
        }

        <p className='inputLabel'>
          {t('bucket_details.folder_path')}
        </p>
        <input
          className='form-control folderPath'
          placeholder={t('bucket_details.folder_path_example')}
          name='folderPath'
          value={folderPath}
          onChange={handleInputChange}
          data-testid='folderPath-input'
        />

        <p className={`inputLabel ${validatePresence && !portNumber && 'error'}`}>
          {t('bucket_details.port_number')}<span className='required-indicator' />
        </p>
        <input
          className={`form-control portNumber ${validatePresence && !portNumber && 'has-error'}`}
          placeholder={t('bucket_details.port_number_example')}
          name='portNumber'
          value={portNumber}
          onChange={handleInputChange}
          data-testid='portNumber-input'
        />
        { validatePresence && !portNumber &&
          <p className='error errorDetail'>
            {t('bucket_details.field_required', {field: t('bucket_details.port_number')})}
          </p>
        }
      </div>

      <ConnectWarning service='Sftp' />
    </div>
  );

  const renderConnectionStatusView = () => (
    <div className='connectionStatus'>
      <h3 className='modal-section-title'>{t('connection_status.title')}</h3>
      <div className='helpText'>{t('bucket_details.instructions')}</div>
      <p className='tw-mt-4'>
        { errorMessage ?
          <FaTimesCircle className='mr5 error' />
          : <FaCheckCircle className='success-icon mr5' />
        }
        {hostname}
        <span className='connectionMessage'>{errorMessage || t('connection_status.success')}</span>
      </p>

    </div>
  );

  return (
    <IntlProvider locale='en'>
      <Modal size="xl" style={{maxWidth: '900px', width: '100%'}} confirmClose={false} onClose={handleModalClose} isOpen={modalOpen}>
        <ModalHeader title={t('title')} enableClose />
        <ModalBody addClassName="dlp-root sftpConfigureModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
          <div className='tw-flex modal-body' data-testid='testRoot'>
            {renderStepList()}
            <div className='configBody'>
              { activeStep === 1 && renderInstructionsView() }
              { activeStep === 2 && renderBucketDetailsView() }
              { activeStep === 3 && renderConnectionStatusView() }
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <span className="tw-flex tw-justify-end tw-space-x-1">
            <ModalCloseButton>{translate('actions.cancel')}</ModalCloseButton>
            <Button addClassName="prevBtn" disabled={activeStep === 1} onClick={handleClickPrevious } >
              {t('previous')}
            </Button>

            <Button appearance='primary' addClassName="nextBtn" disabled={activeStep === 3 && errorMessage} onClick={handleClickNext} >
              { activeStep === 1 && translate('actions.next') }
              { activeStep === 2 && translate('actions.connect') }
              { activeStep === 3 && translate('actions.save') }
            </Button>
          </span>
        </ModalFooter>
      </Modal>
    </IntlProvider>
  );
}

SftpConfigureModal.propTypes = {
  accessEnabled: PropTypes.bool,
  edit: PropTypes.bool,
  fawkes: PropTypes.bool,
  initialFolderPath: PropTypes.string,
  initialHostname: PropTypes.string,
  initialPortNumber: PropTypes.number,
  initialPrivateKey: PropTypes.string,
  initialPgpKey: PropTypes.string,
  initialStep: PropTypes.number,
  initialUsername: PropTypes.string,
  ngfwEnabled: PropTypes.bool,
  onModalClose: PropTypes.func,
  showModal: PropTypes.bool,
  updateURL: PropTypes.string,
  validateURL: PropTypes.string
}

SftpConfigureModal.defaultProps = {
  accessEnabled: false,
  edit: false,
  fawkes: true,
  initialFolderPath: '',
  initialHostname: '',
  initialPortNumber: 22,
  initialPrivateKey: '',
  initialPgpKey: '',
  initialStep: 1,
  initialUsername: '',
  ngfwEnabled: false,
  onModalClose: undefined,
  showModal: true,
  updateURL: '/settings.json',
  validateURL: '/settings/validate.json'
}

export default SftpConfigureModal;