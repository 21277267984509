import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import DropDown from 'react-dd-menu';
import { Button } from '@panwds/react-ui';

const DropDownList = ({value, items, onSelect, dropClass, placeholder, preLabel, upwards, buttonInput, labelFreeze, size, disabled}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState(value !== null ? (items.find(item => { return item.value === value; }) || {}).label : '');

  useEffect(() => {
    if (value != null) {
      const item = items.find(i => { return i.value === value; });
      setLabel(item? item.label : '');
    }

  }, [value]);

  const toggle = () => {
    setIsOpen(!isOpen)
  };

  const close = () => {
    setIsOpen(false);
  };

  const handleInputChange = (event) => {
    setLabel(event.target.value);
  }

  const newSelection = (newValue) => {
    setLabel(items.find(item => { return item.value === newValue; }).label)
    onSelect(newValue)
  }

  const handleSubmit = (event) => {
    const submitItem = items.find(item => { return item.label === parseInt(label, 10); });
    if (submitItem && submitItem.value && event.key === 'Enter') {
      onSelect(submitItem.value)
      close()
    }
  }

  let buttonLabel = '';
  if (buttonInput) {
    buttonLabel = <input onKeyPress={handleSubmit} onChange={(event) => handleInputChange(event)} value={label} style={{ top: '0', border: 'none', width: '53px' }} />
  }
  else if (label && !labelFreeze) {
    buttonLabel = <span>{preLabel}{label}</span>
  }
  else {
    buttonLabel = <span className='placeholder'>
                    {placeholder}
                  </span>
  }


  return (
    <DropDown
      isOpen={isOpen}
      close={close}
      animate={false}
      enterTimeout={10}
      leaveTimeout={10}
      closeOnInsideClick
      upwards={upwards}
      toggle={
        <div>
          <Button
            appearance='tertiary'
            key={dropClass}
            addClassName={`${dropClass}`}
            onClick={toggle}
            aria-expanded={isOpen}
            isMenu
            size={size}
            disabled={disabled}
          >
            {buttonLabel}
            {}
          </Button>

        </div>
      }
      align="left"
    >
      {items.map(item => (
        <li key={item.value}>
          <Button
            key={item.value}
            type="button"
            onClick={() => newSelection(item.value)}
          >
            {item.label}
          </Button>
        </li>
      ))}
    </DropDown>
  );
}

DropDownList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  dropClass: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  items: PropTypes.array,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  preLabel: PropTypes.object,
  upwards: PropTypes.bool,
  buttonInput: PropTypes.bool,
  labelFreeze: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool
};

DropDownList.defaultProps = {
  value: null,
  items: [],
  placeholder: 'Select an Item',
  preLabel: undefined,
  upwards: false,
  buttonInput: undefined,
  labelFreeze: false,
  size: undefined,
  disabled: false
}

export default DropDownList;
