import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import moment from 'moment';
import Item from './Item';

jest.mock('../../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<Item>', () => {
  let wrapper;
  const props = {
    name: 'SaaS Security',
    image: {
      url: '/url/for/image.svg',
      alt: 'Image',
    },
    status: 'normal',
    lastUpdatedAt: moment('2020-07-08', 'YYYY-MM-DD').toISOString(),
  };

  it('renders', () => {
    wrapper = shallow(<Item {...props} />);
    expect(wrapper).toMatchSnapshot();
  });
});
