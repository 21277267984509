// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import './styles.scss';
import 'bootstrap';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { flatten } from 'flat';

import setupCSRFToken from '../helpers/setupCSRFToken';

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);

require("@rails/ujs").start()
require("turbolinks").start()
// require("channels")
require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:

setupCSRFToken();
const translations = require('../i18n/en.json');

const flattened = flatten(translations);
const i18nextOptions = {
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {
      translation: flattened
    }
  }
};
i18next.use(LanguageDetector).init(i18nextOptions);

