import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import MockAdapter from 'axios-mock-adapter'
import axios from 'axios';
import Reports from './Reports';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

const axiosMock = new MockAdapter(axios);

const flushPromises = () => new Promise(setImmediate);

describe('<Reports>', () => {
  let wrapper;

  it('renders search bar', () => {
    wrapper = shallow(<Reports />);
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.searchArea')).toHaveLength(1);
  });

  describe('handleSearchChange()', () => {
    it('shoud set state', () => {
      wrapper = shallow(<Reports />);
      wrapper.instance().handleSearchChange({target: {
        value: 'abcd'
      }});
      expect(wrapper.state('reportId')).toBe('abcd');
    });
  });

  describe('handleSubmit()', () => {
    wrapper = shallow(<Reports />);
    wrapper.instance().search = jest.fn();
    wrapper.instance().handleSubmit({key: 'Enter'});
    expect(wrapper.instance().search).toHaveBeenCalled();
  });

  describe('search()', async() => {
    const response = [{
      name: 'abcd'
    }];
    axiosMock.onGet(`/reports/12345.json`).reply(200, response);
    wrapper = shallow(<Reports />);
    wrapper.setState({reportId: '12345'});
    wrapper.instance().search();
    await flushPromises();
    expect(wrapper.state('found')).toBe(true);
  });
});
