import React, { useState, useEffect } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaExclamationTriangle, FaTimesCircle, FaCopy, FaAngleDown, FaAngleUp } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import _get from 'lodash.get';
import axios from 'axios';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton } from '@panwds/react-ui';
import { IntlProvider } from 'react-intl';
import $ from 'jquery';
import toastr from '../../helpers/toastr';
import translate from '../../helpers/translate';
import RegionDropdown from './RegionDropdown';
import ConnectWarning from './ConnectWarning';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { ENVIRONMENTS } from '../constants';

function t(scope, options) {
  return translate(`settings.configure_modal.${scope}`, options);
}

const AwsConfigureModal = (
  { initialState,
    connectionData,
    validateURL,
    fawkes,
    error,
    editing,
    accessEnabled,
    ngfwEnabled,
    showModal,
    modalClosed,
    environment
  }) => {
  const [activeState, setActiveState] = useState(initialState);
  const [bucketName, setBucketName] = useState(connectionData.aws_bucket_name);
  const [roleArn, setRoleArn] = useState(connectionData.aws_role_arn);
  const [region, setRegion] = useState(connectionData.region || 'us-east-1');
  const [validatePresence, setValidatePresence] = useState(false);
  const [errorMessage, setErrorMessage] = useState(error);
  const [edit, setEdit] = useState(editing);
  const [iamCollapsed, setIamCollapsed] = useState(true);
  const [policyCollapsed, setPolicyCollapsed] = useState(true);
  const [copied, setCopied] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [kmsEnabled, setKmsEnabled] = useState(connectionData.aws_kms_enabled || false);
  const [kmsWarning, setKmsWarning] = useState(false);
  const [kmsUpdated, setKmsUpdated] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
  }, [bucketName, roleArn, region]);

  useEffect(() => {
    if (kmsUpdated) {
      setKmsWarning(true)
      setActiveState(1)
    }
  }, [kmsEnabled]);

  const iamJson = {
    "Version": "2012-10-17",
    "Statement": [
      {
        "Effect": "Allow",
        "Principal": {
          "AWS": `arn:aws${environment === ENVIRONMENTS.GOV_PRODUCTION ? '-us-gov' : ''}:iam::289013992286:user/panw_dlp_evidence_storage`
        },
        "Action": "sts:AssumeRole"
      }
    ]
  }

  const policyJson = {
    "Version": "2012-10-17",
    "Statement": [
      {
        "Effect": "Allow",
        "Action": [
          "s3:GetEncryptionConfiguration",
          "s3:ListBucket",
          "s3:GetBucketAcl",
          "s3:GetBucketLocation"
        ],
        "Resource": `arn:aws${environment === ENVIRONMENTS.GOV_PRODUCTION ? '-us-gov' : ''}:s3:::bucket_name_to_be_replaced`
      },
      {
        "Effect": "Allow",
        "Action": [
          "s3:PutObject",
          "s3:GetObject"
        ],
        "Resource": `arn:aws${environment === ENVIRONMENTS.GOV_PRODUCTION ? '-us-gov' : ''}:s3:::bucket_name_to_be_replaced/*`
      },
      {
        "Effect": "Allow",
        "Action": "s3:GetBucketPublicAccessBlock",
        "Resource": `arn:aws${environment === ENVIRONMENTS.GOV_PRODUCTION ? '-us-gov' : ''}:s3:::bucket_name_to_be_replaced`
      }
    ]
  }

  const kmsPolicy = {
      "Effect": "Allow",
      "Action": [
          "kms:Decrypt",
          "kms:GenerateDataKey"
      ],
      "Resource": [
          `arn:aws${environment === ENVIRONMENTS.GOV_PRODUCTION ? '-us-gov' : ''}:kms:kms_key_arm_to_be_replaced`
      ]
  }

  const policyJsonKms = {...policyJson, "Statement": [...policyJson.Statement, kmsPolicy]}

  const kmsChange = () => {
    setKmsEnabled(!kmsEnabled);
    setKmsUpdated(true);
  }

  const copy = (id) => {
    const copyText = document.getElementById(id);
    copyText.select();
    document.execCommand('copy');
    setCopied(id);
  }

  const resetFields = () => {
    setValidatePresence(false);
    setEdit(editing);
    setActiveState(initialState);
    setBucketName(connectionData.aws_bucket_name);
    setRoleArn(connectionData.aws_role_arn);
    setRegion(connectionData.region || 'us-east-1');
    modalClosed();
  };

  const completedMark = (state) => {
    if (state === 2 && errorMessage) {
      return (
        <FaExclamationTriangle className='tw-float-right error' />
      )
    }
    if (state < activeState) {
      return (
        <FaCheckCircle className='tw-float-right' />
      )
    }
    return '';
  }

  const stepList = () => {
    return (
      <div className='stepList'>
        <div className={`stepItem ${activeState === 1 ? 'active' : 'btn-link'}`} onClick={() => setActiveState(1)}>{t('instructions')}{completedMark(1)}</div>
        <div className={`stepItem ${errorMessage && 'error'} ${activeState === 2 ? 'active' : 'btn-link'}`} onClick={() => setActiveState(2)}>{t('input_details')}{completedMark(2)}</div>
        <div className={`stepItem ${activeState === 3 ? 'active' : 'btn-link'}`}>{t('status')}</div>
      </div>
    )
  }

  const instructionsView = () => {
    return (
      <div className='instructions'>
        <h3 className='modal-section-title'>{t('instructions')}</h3>
        <p>
          {t('instruction_list.step_one')}
          { kmsWarning &&
            <span className='error ml5'>{t('instruction_list.kms_warning')}</span>
          }
        </p>
        <p>
          <ToggleSwitch changeAction={() => kmsChange() } checked={kmsEnabled} />
          <span className='ml5'>{t('bucket_details.kms_enabled')}</span>
        </p>
        <p>{t('instruction_list.step_two')}</p>
        <p>{t('instruction_list.step_three')}</p>
        <div className={`rightInnerAddOn ${copied==='iamJSON' ? 'min' : 'max'}`}>
          <Popup trigger={ <i onClick={ () => copy('iamJSON') }><FaCopy /></i> } position='top center' on='hover' mouseLeaveDelay={0}>
            <div className='popup-box'>{copied==='iamJSON' ? t('instruction_list.copied') : t('instruction_list.copy')}</div>
          </Popup>
          <textarea readOnly className={`json ${iamCollapsed ? 'min' : ''}`} id='iamJSON' value={JSON.stringify(iamJson, null, 2)} />
          { iamCollapsed ?
            <span className='tw-float-right muteText expandToggle' onClick={() => setIamCollapsed(false)}>
              {t('instruction_list.show_more')}
              <FaAngleDown />
            </span>
            :
            <span className='tw-float-right muteText expandToggle expanded' onClick={() => setIamCollapsed(true)}>
              {t('instruction_list.show_less')}
              <FaAngleUp />
            </span>
          }
        </div>
        <p>{t('instruction_list.step_four')}</p>
        <div className={`rightInnerAddOn ${copied==='policyJSON' ? 'min' : 'max'}`}>
          <Popup trigger={ <i onClick={ () => copy('policyJSON') }><FaCopy /></i> } position='top center' on='hover' mouseLeaveDelay={0}>
            <div className="popup-box">{copied==='policyJSON' ? t('instruction_list.copied') : t('instruction_list.copy')}</div>
          </Popup>
          <textarea readOnly className={`json ${policyCollapsed ? 'min' : ''} ${kmsEnabled ? 'kms' : ''}`} id='policyJSON' value={JSON.stringify(kmsEnabled ? policyJsonKms : policyJson, null, 2)} />
          { policyCollapsed ?
            <span className='tw-float-right muteText expandToggle' onClick={() => setPolicyCollapsed(false)}>
              {t('instruction_list.show_more')}
              <FaAngleDown />
            </span>
            :
            <span className='tw-float-right muteText expandToggle expanded' onClick={() => setPolicyCollapsed(true)}>
              {t('instruction_list.show_less')}
              <FaAngleUp />
            </span>
          }
          <br />
        </div>
      </div>
    )
  }

  const bucketDetailsView = () => {
    return (
      <div className='bucketDetails'>
        <h3 className='modal-section-title'>{t('bucket_details.title')}</h3>
        <div className='helpText'>{t('bucket_details.instructions')}</div>
        {edit && <div className='helpText'>
          <br />
          <FaExclamationTriangle className='warning mr5' style={{width: '15px', height: '15px'}}/>
          {t('bucket_details.edit_warning')}
        </div>}
        <div className='errorMessage' style={{display: errorMessage ? '' : 'none'}}>
          <FaExclamationCircle className='error mr5' />
          {errorMessage}
        </div>
        <div className='bucketForm'>
          <p className={`inputLabel ${validatePresence && !bucketName && 'error'}`}>{t('bucket_details.bucket_name')}</p>
          <input className={`form-control bucketName ${validatePresence && !bucketName && 'has-error'}`} placeholder={t('bucket_details.bucket_name')} defaultValue={bucketName} onChange={ (event) => setBucketName(event.target.value) } />
          {validatePresence && !bucketName && <p className='error errorDetail'>{t('bucket_details.field_required', {field: t('bucket_details.bucket_name')})}</p>}

          <p className={`inputLabel ${validatePresence && !roleArn && 'error'}`}>{t('bucket_details.role_arn')}</p>
          <input className={`form-control roleArn ${validatePresence && !roleArn && 'has-error'}`} placeholder={t('bucket_details.role_arn')} defaultValue={roleArn} onChange={ (event) => setRoleArn(event.target.value) } />
          {validatePresence && !roleArn && <p className='error errorDetail'>{t('bucket_details.field_required', {field: t('bucket_details.role_arn')})}</p>}

          <p className='inputLabel'>{t('bucket_details.kms_enabled')}</p>
          <ToggleSwitch changeAction={() => kmsChange()} checked={kmsEnabled} />

          <p className='inputLabel'>{t('bucket_details.region')}</p>
          <RegionDropdown
            onSelect={regionSelection => setRegion(regionSelection)}
            value={region}
          />
        </div>
        <ConnectWarning service="AWS"/>
      </div>
    )
  }

  const connectionStatusView = () => {
    return (
      <div className='connectionStatus'>
        <h3 className='modal-section-title'>{t('connection_status.title')}</h3>
        <div className='helpText'>{t('bucket_details.instructions')}</div>
        <h4 className='bucketLabel'>
          { errorMessage ?
            <FaTimesCircle className='mr5 error' />
            : <FaCheckCircle className='success-icon mr5' />
          }
          {bucketName}
        </h4>
        <p className='connectionMessage'>{errorMessage || t('connection_status.success')}</p>
      </div>
    )
  }

  const prev = () => {
    if (activeState === 2) {
      setActiveState(1);
    }
    if (activeState === 3) {
      setActiveState(2);
    }
  }

  const next = () => {
    if (activeState === 1) {
      setActiveState(2);
    }
    if (activeState === 2) {
      if (bucketName && roleArn && region) {
        const payload = {
          connection_type: 'aws',
          connection_data: {
            aws_role_arn: roleArn,
            aws_bucket_name: bucketName,
            aws_kms_enabled: kmsEnabled,
            region
          }
        }
        setProcessing(true);
        axios.post(validateURL, payload)
        .then(
          (result) => {
            setProcessing(false);
            setErrorMessage(result.data.hasError === false ? '' : result.data.errorMessage || translate('errors.server_error'));
            setActiveState(3);
          }
        ).catch((e) => {
          setErrorMessage(_get(e, 'response.data.errorMessage') || translate('errors.server_error'));
          setProcessing(false);
          setActiveState(3);
        });
      }
      else {
        setValidatePresence(true);
      }
    }
    if (activeState === 3) {
      setProcessing(true);
      const payload = {
        enabled_for_prisma_access: accessEnabled,
        enabled_for_ngfw: ngfwEnabled,
        connection_status: 'connected',
        connection_type: 'aws',
        connection_data: {
          aws_role_arn: roleArn,
          aws_bucket_name: bucketName,
          aws_kms_enabled: kmsEnabled,
          region
        },
        flash_params: {
          action: edit ? 'edit' : 'create'
        }
      }
      if (edit) {
        axios.put(fawkes ? '/v1/dlp-ui/evidence' : '/settings.json', payload)
        .then(
          () => {
            if (fawkes) {
              toastr.success(translate('settings.index.bucket_changed'));
              resetFields();
            }
            else {
              window.location.assign('/settings/');
            }
            setProcessing(false);
          }
        )
        .catch(
          (err) => {
            const result = err.response;
            setProcessing(false);
            setErrorMessage(result.data.errorMessage || translate('errors.server_error'));
            setActiveState(2);
          }
        )
      }
      else {
        axios.post(fawkes ? '/v1/dlp-ui/evidence' : '/settings.json', payload)
        .then(
          () => {
            if (fawkes) {
              toastr.success(translate('settings.index.bucket_configured'));
              resetFields();
            }
            else {
              window.location.assign('/settings/');
            }
            setProcessing(false);
          }
        )
        .catch(
          (err) => {
            const result = err.response;
            setProcessing(false);
            setErrorMessage(result.data.errorMessage || translate('errors.server_error'));
            setActiveState(2);
          }
        )
      }
    }
  }

  const buttonText = () => {
    if (activeState === 1) {
      return t('next');
    }
    if (activeState === 2) {
      return translate('actions.connect');
    }
    return translate('actions.save');
  }

  $('.rightInnerAddOn i').mouseleave(() => {
    setCopied('');
  });

  return (
    <IntlProvider locale='en'>
      <Modal size="xl" style={{width: '900px', height: '622px'}} confirmClose={false} onClose={resetFields} isOpen={showModal}>
        <ModalHeader title={t('title')} enableClose />
        <ModalBody addClassName="dlp-root awsConfigureModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
          <div className='modal-body'>
            {stepList()}
            <div className='configBody tw-float-right'>
              {activeState === 1 && instructionsView()}
              {activeState === 2 && bucketDetailsView()}
              {activeState === 3 && connectionStatusView()}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <span className="tw-flex tw-justify-end tw-space-x-1">
            <ModalCloseButton>{translate('actions.cancel')}</ModalCloseButton>
            <Button addClassName="prevBtn" disabled={activeState === 1} onClick={() => prev()} >
              {t('previous')}
            </Button>
            <Button appearance='primary' addClassName="nextBtn" disabled={processing || (activeState === 3 && errorMessage)} onClick={() => next()} >
              {buttonText()}
            </Button>
          </span>

        </ModalFooter>
      </Modal>
    </IntlProvider>
  );
}

AwsConfigureModal.propTypes = {
  initialState: PropTypes.number,
  connectionData: PropTypes.shape({
    aws_bucket_name: PropTypes.string,
    aws_role_arn: PropTypes.string,
    region: PropTypes.string,
    aws_kms_enabled: PropTypes.bool,
  }),
  validateURL: PropTypes.string,
  fawkes: PropTypes.bool,
  error: PropTypes.string,
  editing: PropTypes.bool,
  accessEnabled: PropTypes.bool,
  ngfwEnabled: PropTypes.bool,
  showModal: PropTypes.bool,
  modalClosed: PropTypes.func,
  environment: PropTypes.string.isRequired,
};

AwsConfigureModal.defaultProps = {
  initialState: 1,
  connectionData: {},
  validateURL: '',
  fawkes: true,
  error: '',
  editing: false,
  accessEnabled: false,
  ngfwEnabled: false,
  showModal: true,
  modalClosed: undefined,
};

export default AwsConfigureModal;