import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Button } from '@panwds/react-ui';

import translate from '../../helpers/translate';
import EmptyListIcon from '../../../assets/images/empty-list.svg';
import SingleSelect from '../SingleSelect';
import {
  RULE_TECHNIQUE_DATA_PATTERN_REGEX,
  RULE_TECHNIQUE_DOCUMENT_TYPE,
  RULE_TECHNIQUE_EDM_DATASET
} from '../constants';

function localizeString(scope, options) {
  return translate(`incidents.show.${scope}`, options);
}

const MatchingDataPatterns = ({
  highMatches,
  medMatches,
  lowMatches,
  confidence,
  snippets,
  isMultiProfile,
  multiProfileList
}) => {
  const [ currentMatches, setCurrentMatches ] = useState(highMatches);
  const [ currentConfidence, setCurrentConfidence ] = useState(confidence);
  const [ multiProfileView, setMultiProfileView ] = useState('All');
  const [ matchesByProfile, setMatchesByProfile ] = useState({
    hcf: highMatches,
    mcf: medMatches,
    lcf: lowMatches
  });

  useEffect(() => {
    const highMatchesByProfile = {...highMatches};
    const medMatchesByProfile = {...medMatches};
    const lowMatchesByProfile = {...lowMatches};

    if(isMultiProfile && multiProfileView !== 'All') {
      const patterns = multiProfileList.find(dp => dp.data_profile_name === multiProfileView)?.data_pattern_list || [];
      const patternIds = patterns.map(p => p.id);

      Object.keys(highMatchesByProfile).forEach(cm => { if (!patternIds.includes(cm)) { delete highMatchesByProfile[cm]; } });
      Object.keys(medMatchesByProfile).forEach(cm => { if (!patternIds.includes(cm)) { delete medMatchesByProfile[cm]; } });
      Object.keys(lowMatchesByProfile).forEach(cm => { if (!patternIds.includes(cm)) { delete lowMatchesByProfile[cm]; } });
    }

    let newCurrentMatches = {};

    if (currentConfidence === 'hcf') { newCurrentMatches = {...highMatchesByProfile}; }
    if (currentConfidence === 'mcf') { newCurrentMatches = {...medMatchesByProfile}; }
    if (currentConfidence === 'lcf') { newCurrentMatches = {...lowMatchesByProfile}; }

    setCurrentMatches(newCurrentMatches);
    setMatchesByProfile({ hcf: highMatchesByProfile, mcf: medMatchesByProfile, lcf: lowMatchesByProfile });
  }, [ currentConfidence, multiProfileView ]);

  const maxSnippetView = (detections) => {
    return (
      <div className='snippets'>
        {(detections || []).map((detection, idx) =>
          <span className='snippet' key={idx}>
            <span>{detection.left} </span>
            <span className='highlight'>{detection.detection}</span>
            <span> {detection.right} </span>
            <br/ ><br/ >
          </span>
        )}
      </div>
    )
  };

  const snippetsByConfidence = (patternSnippets) => {
    if (!patternSnippets || Object.keys(patternSnippets).length === 0) {
      return null;
    }
    if (currentConfidence === 'hcf') {
      return patternSnippets.high_confidence_detections;
    }
    if (currentConfidence === 'mcf') {
      return patternSnippets.medium_confidence_detections;
    }
    return patternSnippets.low_confidence_detections;
  };

  const edmColumns = (pattern) => {
    if (pattern.edm_columns && pattern.edm_columns.length) {
      return (
        <div>
          <span className='edmColumnHeader tw-mr-2'>{localizeString('matched_columns')}:</span>
          <span className='edmColumnValue'>{pattern.edm_columns.join(', ')}</span>
        </div>
      );
    }
    return (<></>);
  };

  const renderDetailsBasedOnDetectionTechnique = (pattern={}) => {
    switch (pattern.detection_technique) {
      case RULE_TECHNIQUE_DOCUMENT_TYPE: {
        return (
          <>
            <div className='patternName'>
              {`Document Type - ${pattern.name}`}
            </div>

            <span className='occurrenceCount'>{localizeString('score')} {pattern.score}</span>
          </>
        );
      }

      case RULE_TECHNIQUE_DATA_PATTERN_REGEX:
      case RULE_TECHNIQUE_EDM_DATASET:
      default:
        return (
          <>
            <div className='patternName'>
              {(/EDM - .*/).test(pattern.name) ? pattern.name : `Data Pattern - ${pattern.name}`}
            </div>

            { edmColumns(pattern) }

            <span className='occurrenceCount'>{pattern[currentConfidence]} {localizeString('occurrences')}</span>

            { pattern[`u${confidence}`] > 0 &&
              <span className='occurrenceCount'>{pattern[`u${confidence}`]} {localizeString('unique')}</span>
            }
          </>
        );
    };
  };

  const patternRows = () => {
    const rows = [];

    Object.keys(currentMatches).forEach((id) => {
      const pattern = currentMatches[id];
      const patternSnippets = snippets[id];
      const confSnippets = snippetsByConfidence(patternSnippets);

      rows.push(
        <tr key={id}>
          <td className='col-md-4'>
            { renderDetailsBasedOnDetectionTechnique(pattern) }
          </td>
          <td className='col-md-8 snippetResults'>
            { confSnippets &&
              <div className='snippets'>
                { maxSnippetView(confSnippets) }
              </div>
            }
            { !confSnippets &&
              <div className='snippetsCenter'>
                <div>
                  <FaExclamationTriangle className='warning mr5' />
                  { pattern?.detection_technique === RULE_TECHNIQUE_DOCUMENT_TYPE ?
                    localizeString('snippets_not_available')
                    : localizeString('snippets_not_found')
                  }
                </div>
              </div>
            }
          </td>
        </tr>
      );
    });

    return rows;
  };

  return (
    <div className='dataPatterns'>
      <div className='card-title patternTitle'>
        {isMultiProfile ?
          <span className='matchingProfileHeader'>
            {localizeString('matching_profiles')}
            <SingleSelect
              items={[...[{key: 'All', value: 'All'}], ...multiProfileList.map(dp => {return {value: dp.data_profile_name, key: dp.data_profile_name}})]}
              className='profilerSelect'
              button={
                <Button isMenu appearance='tertiary' addClassName='multiProfileSelector'>
                  {multiProfileView}
                </Button>
              }
              onChange={newMultiProfileView => setMultiProfileView(newMultiProfileView.selectedItem.value)}
            />
          </span>
          :
          localizeString('matches_within_profile')
        }
      </div>
      <div className='subHeading'>
        <span className='subHeaderTab muteText'>{localizeString('confidence_level')}:</span>
        <span className={`subHeaderTab confSelector ${currentConfidence === 'hcf' && 'active'}`} onClick={()=> setCurrentConfidence('hcf')}>
          {localizeString('high')}
          <span className='helpText'>  ({Object.keys(matchesByProfile.hcf).length} Patterns)</span>
        </span>
        <span className={`subHeaderTab confSelector ${currentConfidence === 'mcf' && 'active'}`} onClick={()=> setCurrentConfidence('mcf')}>
          {localizeString('medium')}
          <span className='helpText'>  ({Object.keys(matchesByProfile.mcf).length} Patterns)</span>
        </span>
        <span className={`subHeaderTab confSelector ${currentConfidence === 'lcf' && 'active'}`} onClick={()=> setCurrentConfidence('lcf')}>
          {localizeString('low')}
          <span className='helpText'>  ({Object.keys(matchesByProfile.lcf).length} Patterns)</span>
        </span>
      </div>
      { Object.keys(currentMatches).length > 0 ?
        <table className='table table-condensed matchTable'>
          <tbody>
            { patternRows()}
          </tbody>
        </table>
        :
        <div className='empty text-center'>
          <img src={EmptyListIcon} alt='empty' />
          <br /><br />
          <p>
            {localizeString('no_matches_within_profile')}
          </p>
        </div>
      }
    </div>
  );
};

MatchingDataPatterns.propTypes = {
  highMatches: PropTypes.shape({}),
  medMatches: PropTypes.shape({}),
  lowMatches: PropTypes.shape({}),
  confidence: PropTypes.string,
  snippets: PropTypes.shape({}),
  isMultiProfile: PropTypes.bool,
  multiProfileList: PropTypes.array,
};

MatchingDataPatterns.defaultProps = {
  highMatches: {},
  medMatches: {},
  lowMatches: {},
  confidence: 'hcf',
  snippets: {},
  isMultiProfile: false,
  multiProfileList: [],
};

export default MatchingDataPatterns;