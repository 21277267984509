import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { Button } from '@panwds/react-ui';
import $ from 'jquery';
import { IntlProvider } from 'react-intl';

import BulkTable from '../BulkTable/BulkTable';
import Header from '../DetectionMethods/Header';
import columns from './columns';
import translate from '../../helpers/translate';
import DataPatternDetails from './DataPatternDetails';
import SingleSelect from '../SingleSelect';

import {
  ASC,
  DATA_PATTERN_COL_GEOGRAPHIES,
  DATA_PATTERN_COL_TECHNIQUE,
  DATA_PATTERN_COL_TYPE,
  DATA_PATTERN_COL_UPDATED_AT
} from '../constants';

import { sortArrObjects } from '../../helpers/utils';

function t(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}

export const CUSTOM = 'custom';
export const FILE_PROPERTY = 'file property';
const DATA_PATTERN_TYPES = [
  {
    key: CUSTOM,
    value: t(CUSTOM)
  },
  {
    key: FILE_PROPERTY,
    value: t('file_property')
  }
];

function filterOptions(uniqTags) {
  const filters = {
    filterable: true,
    filterOptions: [
      { name: t('technique'), id: DATA_PATTERN_COL_TECHNIQUE, multiple: true },
      { name: t(DATA_PATTERN_COL_TYPE), id: DATA_PATTERN_COL_TYPE, multiple: true },
    ],
    type: [
      { name: 'Custom', id: CUSTOM },
      { name: 'File Property', id: 'file_property' },
      { name: 'Predefined', id: 'predefined' },
    ],
    detection_technique: [
      { name: t('file_property'), id: 'file_property'},
      { name: t('ml'), id: 'ml'},
      { name: t('regex'), id: 'regex'},
      { name: t('weighted_regex'), id: 'weighted-regex'}
    ],
    currentFilters: [],
    currentFilterSelections: {}
  }
  if (uniqTags.length === 0) { return(filters) };

  filters.filterOptions = [{ name: t('geographies'), id: 'geographies', multiple: true }, ...filters.filterOptions]
  filters.geographies = uniqTags.sort().map( geo => { return { name: geo, id: geo } })

  return filters;
}

class DataPatternTable extends React.Component {
  state = {
    data: [],
    pageData: {},
    filters: filterOptions([].concat(...this.props.dataPatterns.map(dp => dp.tags?.geography)).filter((v, i, a) => v && a.indexOf(v) === i)),
    nameSearch: '',
    viewing: null,
    loading: false,
    sortBy: DATA_PATTERN_COL_UPDATED_AT,
    sortOrder: ASC
  };

  componentDidMount() {
    $('.banner-area').html('');
    this.requestResults(0, 10);
  }

  requestResults = (pageNum, numPerPage, nameSearch = this.state.nameSearch, filterSelections = this.state.filters.currentFilterSelections) => {
    this.setState({ loading: true });

    const { sortBy, sortOrder } = this.state;
    const {dataPatterns} = this.props;
    let filteredPatterns = dataPatterns.filter( dp => dp.name.toLowerCase().includes(nameSearch.toLowerCase()));

    Object.keys(filterSelections).forEach((key) => {
      const selectedTypes = filterSelections[key].map(v => v.id);
      if (selectedTypes.length > 0) {
        if ([DATA_PATTERN_COL_TYPE, DATA_PATTERN_COL_TECHNIQUE].includes(key)) {
          filteredPatterns = filteredPatterns.filter( dp => selectedTypes.includes(dp[key]));
        }
        else if (key === 'geographies') {
          filteredPatterns = filteredPatterns.filter( dp => selectedTypes.filter(element => (dp.tags?.geography || []).includes(element)).length > 0);
        }
      }
    });
    const totalPages = Math.ceil(filteredPatterns.length / numPerPage);
    const startIndex = pageNum * numPerPage;
    const endIndex = (pageNum + 1) * numPerPage;
    const pagedPatterns = sortArrObjects(filteredPatterns, sortBy, sortOrder, sortBy === DATA_PATTERN_COL_UPDATED_AT).slice(startIndex, endIndex);

    this.setState({
      data: pagedPatterns,
      pageData: {
        size: numPerPage,
        number: pageNum,
        total_elements: filteredPatterns.length,
        total_pages: totalPages,
        total_effective_elements: filteredPatterns.length
      },
      loading: false
    });
  }

  updateState = (newState, requestNewResults = false) => {
    if (newState.viewing) {
      this.setState(prevState => {
        const row = prevState.data.find(i => i.id === newState.viewing);
        return {
          viewing: row
        }
      });
    }
    else if (newState.editing) {
      if (this.props.history) {
        this.props.history.push(`${this.props.match.path}/${newState.editing}/edit`);
      } else {
        window.location.assign(`/data_patterns/${newState.editing}/edit`);
      }
    }
    else if (newState.cloning) {
      this.handleClonePattern(this.state.data.find(i => i.id === newState.cloning));
    }
    else {
      this.setState(newState);
    }
    if (requestNewResults) {
      this.requestResults(0, this.state.pageData.size, newState.nameSearch === undefined ? this.state.nameSearch : newState.nameSearch);
    }
  }

  handleUpdateSortControls = ({ sortBy=DATA_PATTERN_COL_UPDATED_AT, sortOrder=ASC }) => {
    this.setState({
      sortBy,
      sortOrder
    }, () => {
      this.requestResults(0, this.state.pageData.size);
    });
  }

  handleAddPattern = (event) => {
    const {key} = event.selectedItem;
    if (this.props.history) {
      this.props.history.push(`${this.props.match.path}/new`,
        {
          type: key
        }
      );
    }
    else {
      window.location.assign(`/data_patterns/new?type=${key}`);
    }
  }

  handleClonePattern = (pattern) => {
    if (this.props.history) {
      const clone = { ...pattern};
      delete clone.id;
      delete clone.created_by;
      delete clone.updated_by;
      clone.name = `Copy - ${pattern.name}`;
      clone.root_type = pattern.type;
      clone.parent_id = pattern.id;
      if (pattern.type === 'predefined') {
        clone.type = CUSTOM;
      }

      this.props.history.push(`${this.props.match.path}/clone`,
        {
          dataPattern: clone
        }
      );
    }
    else {
      window.location.assign(`/data_patterns/${pattern.id}/clone`);
    }
  }

  actionable = (dataPattern) => {
    return(this.props.userRole === 'READ_WRITE' ? {
      editable: (dataPattern.type === CUSTOM || dataPattern.type === 'file_property'),
      deletable: false,
      cloneable: dataPattern.detection_technique !== 'ml'
    } : null);
  }

  closeModal = () => {
    this.setState({
      deleting: null
    });
  }

  handleUpdateFilters = (newFiltersList, newFilterSelections, requestNewResults = false, reset = false) => {
    const updatedFilters = { currentFilters: newFiltersList, currentFilterSelections: newFilterSelections };
    this.setState(prevState => ({
      filters: { ...prevState.filters, ...updatedFilters }
    }));
    if (reset) {
      this.setState({ nameSearch: '' })
    }
    if (requestNewResults) {
      this.requestResults(0, this.state.pageData.size, reset ? '' : this.state.nameSearch, newFilterSelections)
    }
  };

  render() {
    const { data, pageData, loading, filters, sortBy, sortOrder } = this.state;
    const actionable = this.props.userRole === 'READ_WRITE' ? {
      deletable: () => false,
      cloneable: (row) => row.original.detection_technique !== 'ml',
      editable: (row) => (row.values.type === CUSTOM || row.values.type === 'file_property')
    } : null;
    return (
      <IntlProvider locale='en'>
        <div className='data-patterns-root'>
          <Header activePage={t('data_patterns')} />
          <div className='reactTable dataPatternTable'>
            <div className="title card-title">
              <span>
                {t('data_patterns')}
                <span className='count'>{` (${pageData.total_elements || 0})`}</span>
              </span>
              <span className='tw-float-right tw-space-x-2 actions-bar'>
                <span className='input-wrapper'>
                  <input value={this.state.nameSearch} onChange={(event) => this.updateState({ nameSearch: event.target.value }, true)}  placeholder={t('search')} className='form-control search' />
                  <i><FaSearch /></i>
                </span>
                {this.props.userRole === 'READ_WRITE' &&
                  <SingleSelect
                    button={
                      <Button isMenu appearance="primary" addClassName='dropdown-anchor'>
                        {t('add_data_pattern')}
                      </Button>
                    }
                    items={DATA_PATTERN_TYPES}
                    onChange={this.handleAddPattern}
                  />
                }
              </span>
            </div>
            <BulkTable
              data={data}
              defaultSortBy={sortBy}
              defaultSortOrder={sortOrder}
              columns={columns}
              pageData={
                {
                  pageCount: pageData.size,
                  currentPage: pageData.number,
                  totalCount: pageData.total_effective_elements || data.length,
                  totalPages: pageData.total_pages
                }
              }
              requestResults={this.requestResults}
              updateState={this.updateState}
              updateSortControls={this.handleUpdateSortControls}
              updateFilters={this.handleUpdateFilters}
              selectable={false}
              sortable
              sortExclusionList={[DATA_PATTERN_COL_GEOGRAPHIES]}
              loading={loading}
              filters={filters}
              actionable={actionable}/>
            { this.state.viewing &&
              <DataPatternDetails pattern={this.state.viewing}
                updateState={this.updateState} actionable={this.actionable(this.state.viewing)}
                handleClonePattern={this.handleClonePattern} />}
          </div>
        </div>
      </IntlProvider>
    )
  }
}

DataPatternTable.propTypes = {
  dataPatterns: PropTypes.arrayOf(PropTypes.any).isRequired,
  userRole: PropTypes.string,
  match: PropTypes.any,
  history: PropTypes.shape({
    path: PropTypes.string,
    push: PropTypes.func
  }),
  apiBaseURL: PropTypes.string.isRequired,
  fawkes: PropTypes.bool,
};

DataPatternTable.defaultProps = {
  userRole: 'READ_WRITE',
  match: undefined,
  history: undefined,
  fawkes: true,
}

export default DataPatternTable;