import React from 'react';

import { configure, shallow } from 'enzyme';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import Adapter from 'enzyme-adapter-react-16';
import DataProfileFormMulti from './DataProfileFormMulti';

configure({ adapter: new Adapter() });

const flushPromises = () => new Promise(setImmediate);

describe('<DataProfileFormMulti>', () => {
  let wrapper;
  const mock = new MockAdapter(axios);
  const existingMultiProfile = {
    name: 'existing profile',
    type: 'advanced',
    id: '12345',
    profile_type: 'custom',
    detection_rules: [
      {
        "rule_type": "multi_profile",
        "multi_profile": {
          "operator_type": "or",
          "data_profile_ids": [
            11995039,
            11995038
          ]
        }
      }
    ],
    schema_version: 2,
    multiProfile: true
  };
  const history = {
    push: jest.fn()
  };
  const props = {
    history
  };
  const updateProps = {
    dataProfile: existingMultiProfile,
    dataProfileId: '12345'
  };

  describe('create form', () => {
    beforeEach(() => {
      wrapper = shallow(<DataProfileFormMulti {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper).toMatchSnapshot();
    });
  });

  describe('update form', () => {
    beforeEach(() => {
      wrapper = shallow(<DataProfileFormMulti {...updateProps}/>);
      mock.onGet().reply(200, {});
    });

    it('Component has expected classes', () => {
      expect(wrapper).toMatchSnapshot();
    });
  });
});