import React from 'react';

import { configure, shallow, mount, render } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import EdmConfig from './EdmConfig';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { act } from 'react-dom/test-utils';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

const flushPromises = () => new Promise(setImmediate);
describe('<EdmConfig/>', () => {
  let wrapper;
  const mockCallback = jest.fn();

  describe('', () => {
    it('should render disabled for read only user', () => {
      wrapper = shallow(<EdmConfig userRole='READ_ONLY' currentStatus='disabled' enableEdm={mockCallback}/>);
      expect(wrapper).toMatchSnapshot();
    });

    it('should render', () => {
      wrapper = shallow(<EdmConfig userRole='READ_WRITE' currentStatus='disabled' enableEdm={mockCallback}/>);
      expect(wrapper).toMatchSnapshot();
    });

    it('should call callback', async() => {

      wrapper = mount(<EdmConfig userRole='READ_WRITE' currentStatus='disabled' enableEdm={mockCallback}/>);
      const toggle = wrapper.find(ToggleSwitch).at(0);
      act(() => {
        toggle.prop('changeAction')();
      });

      await flushPromises();
      expect(mockCallback).toHaveBeenCalled();
    });
  });

});