import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch, FaCalendarAlt } from 'react-icons/fa';
import axios from 'axios';
import $ from 'jquery';
import { IntlProvider } from 'react-intl';

import BulkTable from '../BulkTable/BulkTable';
import columns from './columns';
import translate from '../../helpers/translate';
import { profileDetails } from '../DataProfiles/profileDataTransformer';
import { logDetails } from './logDataTransformer';
import DataProfileDetails from '../DataProfiles/DataProfileDetails';
import DataPatternDetails from '../DataPatterns/DataPatternDetails';
import AuditLogDetails from './AuditLogDetails';
import FilteringProfileDetails from '../DataFilteringProfiles/FilteringProfileDetails';
import { preprocessProfile } from '../DataFilteringProfiles/helper';

function t(scope, options) {
  return translate(`audit_logs.${scope}`, options);
}

function filterOptions() {
  const customText = <span className='bulkTableFilterIcon'><FaCalendarAlt /> Custom</span>;
  return(
    {
      filterable: true,
      filterOptions: [
        { name: t('columns.time'), id: 'time', multiple: false, sticky: true },
        { name: t('columns.platform'), id: 'clientName', multiple: true },
        { name: t('columns.event'), id: 'event', multiple: true }
      ],
      time: [
        { name: 'Past 60 Minutes', id: 'past_60_minutes' },
        { name: 'Past 24 Hours', id: 'past_24_hours' },
        { name: 'Past 7 Days', id: 'past_7_days' },
        { name: 'Past 30 Days', id: 'past_30_days' },
        { name: 'All', id: 'all' },
        { name: customText, id: 'custom' }
      ],
      clientName: [
        { name: translate('dlp', {platform: true}), id: 'Enterprise DLP'},
        { name: translate('Fawkes', {platform: true}), id: 'dlp-ui'},
        { name: translate('ng-casb', {platform: true}), id: 'ng-casb'},
        { name: translate('ngfw', {platform: true}), id: 'ngfw'},
        { name: translate('panorama', {platform: true}), id: 'panorama'},
        { name: translate('prisma-access', {platform: true}), id: 'prisma-access'},
        { name: translate('prisma-saas', {platform: true}), id: 'prisma-saas'}
      ],
      event: [
        { name: 'Create', id: 'Create'},
        { name: 'Delete', id: 'Delete'},
        { name: 'Update', id: 'Update'}
      ],
      currentFilters: [{ name: t('columns.time'), id: 'time', multiple: false, sticky: true }],
      currentFilterSelections: {
        time: [{name: 'Past 30 Days', id: 'past_30_days'}]
      }
    }
  )
}

class AuditLogTable extends React.Component {
  state = {
    data: [],
    pageData: {},
    filters: filterOptions(),
    profiles: {},
    filteringProfiles: {},
    userSearch: '',
    loading: true,
    showDateRangePicker: false,
  };

  fetchDataProfiles = async () => {
    try {
      const dpResponse = await axios({
        url: '/v1/dlp-ui/data-profile',
        method: 'GET'
      });
      return dpResponse.data.resources;
    } catch (error) {
      console.error('Fetching data profiles failed', error);
    }
    return [];
  }

  fetchDataFilteringProfiles = async () => {
    if (this.props.portalType === 'casb') {
      return [];
    }
    try {
      const dfpResponse = await axios({
        url: '/v1/dlp-ui/data-filtering-profile',
        method: 'GET'
      });
      return dfpResponse.data.resources;
    } catch (error) {
      console.error('Fetching data filtering profiles failed', error);
    }
    return [];
  }

  componentDidMount= async() => {
    const profiles = this.props.fawkes ? await this.fetchDataProfiles() : this.props.profiles;
    const filteringProfiles = this.props.fawkes ? await this.fetchDataFilteringProfiles() : this.props.filteringProfiles;
    const multiProfileList = profiles.map(profile => {
      return {
        value: profile.name,
        key: profile.id
      }});

    const formattedProfiles = profiles.map(profile => profileDetails(profile, this.props.patterns, this.props.datasets, false, multiProfileList)).reduce(function(map, obj) {
      map[obj.id] = obj;
      return map;
    }, {});;
    const formattedFilteringProfiles = filteringProfiles.map(preprocessProfile).reduce(function(map, obj) {
      map[obj.profile_id] = obj;
      return map;
    }, {});;
    this.setState({ profiles: formattedProfiles, filteringProfiles: formattedFilteringProfiles });
    this.requestResults(0, 10);
  }

  formatFilters = (filterSelections, userId) => {
    const expressions = [];

    Object.keys(filterSelections).forEach((key) => {
      if (key !== 'time' && filterSelections[key].length) {
        expressions.push({
          category_values: filterSelections[key].map(item => item.id),
          category_type: key,
          type: filterSelections[key].length > 1 ? 'in' : 'eq'
        });
      }
    });
    if (userId !== '') {
      expressions.push({
        category_values: [userId],
        category_type: 'userId',
        type: 'like'
      });
    }
    if (expressions.length === 0) {
      return {};
    }
    const searchExp = {
      search_expression: {
        type: 'and',
        expressions
      }
    };

    return this.props.fawkes ? searchExp : { search: searchExp };
  }

  timeFilter = (filterSelections) => {
    if (filterSelections.time) {
      if (typeof filterSelections.time[0].id === 'object') {
        this.setState({showDateRangePicker: false});
        const range = filterSelections.time[0].id;
        const start = Math.round(new Date(range.start).getTime() / 1000);
        const end = Math.round(new Date(range.end).getTime() / 1000);
        return `starts_at=${start}&ends_at=${end}`;
      }
      return `timestamp_unit=${filterSelections.time[0].id}`;
    }
    return 'timestamp_unit=past_30_days';
  }

  filterLogs = (log) => {
    if (this.props.portalType === 'casb') {
      return log.type !== 'DataFilteringProfile';
    }
    return true;
  }

  requestResults = (pageNum, numPerPage, filterSelections = this.state.filters.currentFilterSelections, userId = this.state.userSearch) => {
    this.setState({ loading: true });
    const url = `${this.props.lookupURL}?page_number=${pageNum}&page_size=${numPerPage}&${this.timeFilter(filterSelections)}`;

    const filterDefinition = this.formatFilters(filterSelections, userId);

    return axios.post(url, filterDefinition).then(
      result => {
        const formattedResults = (this.props.fawkes ? result.data?.content : result.data?.body?.content || []).filter(log => this.filterLogs(log)).map(log => logDetails(log, this.state.profiles, this.props.patterns, this.state.filteringProfiles, this.updateState));
        this.setState({
          data: formattedResults,
          pageData: {
            totalElements: this.props.fawkes ? result.data?.totalElements : result.data?.body?.totalElements,
            totalPages: this.props.fawkes ? result.data?.totalPages : result.data?.body?.totalPages,
            size: this.props.fawkes ? result.data?.size : result.data?.body?.size,
            number: this.props.fawkes ? result.data?.number : result.data?.body?.number
          },
          loading: false
        });
      }
    ).catch(
      (error) => {
        console.error(error);
        this.setState({ data: [], loading: false }); }
    );
  }

  handleSubmit = (event) => {
    this.updateState({ userSearch: event.target.value })
    if (event.key === 'Enter') {
      this.requestResults(0, this.state.pageData.size, this.state.filters.currentFilterSelections, event.target.value);
    }
  };

  updateState = (state) => {
    if (state.userSearch) {
      this.setState({ userSearch: state.userSearch });
    }
    else if (state.searching) {
      this.setState({ userSearch: state.searching });
      this.requestResults(0, this.state.pageData.size, this.state.filters.currentFilterSelections, state.searching);
    }
    else if (state.detailExpand) {
      if ($(`.toggleDetail${state.detailExpand}`).text() === 'Show More') {
        $(`.toggleDetail${state.detailExpand}`).text('Show Less');
        $(`.toggleChanges${state.detailExpand}`).removeClass('hidden');
      }
      else {
        $(`.toggleDetail${state.detailExpand}`).text('Show More');
        $(`.toggleChanges${state.detailExpand}`).addClass('hidden');
      }
    }
    else if (state.viewing) {
      if (Object.keys(state.viewing).length > 0) {
        this.setState({ viewing: state.viewing, viewingObject: null, viewingType: null });
      }
    }
    else if (state.viewingObject) {
      if (Object.keys(state.viewingObject).length > 0) {
        this.setState({ viewingObject: state.viewingObject, viewingType: state.viewingType, viewing: null });
      }
    }
    else if (Object.keys(state).includes('showDateRangePicker')) {
      this.setState({showDateRangePicker: false});
    }
    else {
      this.setState({viewing: null, viewingObject: null, viewingType: null});
    }
  };

  updateFilters = (newFiltersList, newFilterSelections, requestNewResults = false, reset = false) => {
    if (newFilterSelections.time && newFilterSelections.time[0].id === 'custom') {
      requestNewResults = false;
      this.setState({showDateRangePicker: true});
    }
    else {
      const updatedFilters = { currentFilters: newFiltersList, currentFilterSelections: newFilterSelections };
      this.setState(prevState => ({
        filters: { ...prevState.filters, ...updatedFilters }
      }));
      if (reset) {
        this.setState({ userSearch: '' })
      }
      if (requestNewResults) {
        this.requestResults(0, this.state.pageData.size, newFilterSelections, reset ? '' : this.state.userSearch)
      }
    }
  };

  render() {
    const { pageData, loading } = this.state;
    return (
      <IntlProvider locale='en'>
        <div className='audit-log-root'>
          <div className='reactTable auditLogTable'>
            <div className="title">
              <span className='actions-bar rightInnerAddOn'>
                <span className='input-wrapper'>
                  <input defaultValue={this.state.userSearch} onKeyPress={this.handleSubmit} onChange={(event) => this.updateState({ userSearch: event.target.value })}  placeholder={t('search_by_user')} className='form-control search' />
                  <i><FaSearch /></i>
                </span>
              </span>
            </div>
            <BulkTable
              data={this.state.data}
              columns={columns}
              pageData={
                {
                  pageCount: pageData.size,
                  currentPage: pageData.number,
                  totalCount: pageData.totalElements || this.state.data?.length || 0,
                  totalPages: pageData.totalPages
                }
              }
              requestResults={this.requestResults}
              updateState={this.updateState}
              updateFilters={this.updateFilters}
              selectable={false}
              sortable={false}
              loading={loading}
              filters={this.state.filters}
              showDateRangePicker={this.state.showDateRangePicker}
            />
            {this.state.viewing &&
              <AuditLogDetails
                auditLog={this.state.viewing}
                updateState={this.updateState}
                datasets={this.props.datasets}
                patterns={this.props.patterns}
                fawkes={this.props.fawkes}
              />
            }
            {this.state.viewingObject && this.state.viewingType === 'dataprofile' &&
              <div className='data_profiles'>
                <DataProfileDetails
                  profile={this.state.viewingObject}
                  updateState={this.updateState}
                />
              </div>
            }
            {this.state.viewingObject && this.state.viewingType === 'datafilteringprofile' &&
              <div className='data-filtering-profiles-root'>
                <FilteringProfileDetails
                  profile={this.state.viewingObject}
                  updateState={this.updateState}
                  fawkes={this.props.fawkes}
                />
              </div>
            }
            {this.state.viewingObject && this.state.viewingType === 'datapattern' &&
              <div className='data-patterns-root'>
                <div className='dataPatternTable'>
                  <DataPatternDetails
                    pattern={this.state.viewingObject}
                    updateState={this.updateState}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </IntlProvider>
    )
  }
}

AuditLogTable.propTypes = {
  lookupURL: PropTypes.string.isRequired,
  profiles: PropTypes.array,
  patterns: PropTypes.shape({}),
  datasets: PropTypes.shape({}),
  filteringProfiles: PropTypes.array,
  fawkes: PropTypes.bool,
  portalType: PropTypes.string,
};

AuditLogTable.defaultProps = {
  profiles: [],
  patterns: {},
  datasets: {},
  filteringProfiles: [],
  fawkes: true,
  portalType: ''
}

export default AuditLogTable;