import logDetails  from './logDataTransformer';

describe('logDataTransformer', () => {
  //     "changes": "{\"new\":\"{\\\"id\\\":\\\"63185ad6e4ceb0581f850eb4\\\",\\\"dataProfileId\\\":11995100,\\\"name\\\":\\\"data-profile-test1\\\",\\\"description\\\":null,\\\"direction\\\":\\\"c2s\\\",\\\"exclusionList\\\":{},\\\"logSeverity\\\":\\\"low\\\",\\\"fileBased\\\":\\\"yes\\\",\\\"fileType\\\":[\\\"any\\\"],\\\"nonFileBased\\\":\\\"no\\\",\\\"rule1\\\":{\\\"action\\\":\\\"block\\\",\\\"response_page\\\":\\\"This file has dlp issues\\\",\\\"show_rsp_page\\\":\\\"no\\\"},\\\"rule2\\\":null,\\\"createdAt\\\":1662540502184,\\\"updatedAt\\\":1662731108598,\\\"createdBy\\\":\\\"Prisma Access Cloud Management\\\",\\\"updatedBy\\\":\\\"Prisma Access Cloud Management\\\",\\\"version\\\":2,\\\"profileType\\\":\\\"custom\\\",\\\"scanType\\\":null,\\\"fileTypeArray\\\":null}\",\"old\":\"{\\\"id\\\":\\\"63185ad6e4ceb0581f850eb4\\\",\\\"dataProfileId\\\":11995100,\\\"name\\\":\\\"data-profile-test1\\\",\\\"description\\\":null,\\\"direction\\\":\\\"c2s\\\",\\\"exclusionList\\\":{},\\\"logSeverity\\\":\\\"low\\\",\\\"fileBased\\\":\\\"yes\\\",\\\"fileType\\\":[\\\"any\\\"],\\\"nonFileBased\\\":\\\"no\\\",\\\"rule1\\\":{\\\"action\\\":\\\"alert\\\",\\\"response_page\\\":\\\"This file has dlp issues\\\",\\\"show_rsp_page\\\":\\\"no\\\"},\\\"rule2\\\":null,\\\"createdAt\\\":1662540502184,\\\"updatedAt\\\":1662540502184,\\\"createdBy\\\":\\\"Prisma Access Cloud Management\\\",\\\"updatedBy\\\":\\\"Prisma Access Cloud Management\\\",\\\"version\\\":1,\\\"profileType\\\":\\\"custom\\\",\\\"scanType\\\":null,\\\"fileTypeArray\\\":null}\"}",

  let log = {
    auditId: "c5c99bb8-6fa8-435f-8e7c-68449f005de3",
    tenantId: "test",
    userId: "AdelphiTestUser6@panw.com",
    clientName: "dlp-ui",
    createdAt: "2022-Sep-09 13:45:08 UTC"
  };
  let profiles = {
    '1234561': {
      name: 'profile A'
    },
    '1234564': {
      name: 'profile B'
    }
  };
  let patterns = {
    1234560: {
      name: 'SSN'
    },
    1234561: {
      name: 'Passport - US'
    },
    1234562: {
      name: 'Bank - Statements'
    },
    1234563: {
      name: 'CCN'
    },

  };
  let filteringProfiles = {
    '1234561': {
      name: 'fp A'
    },
    '1234564': {
      name: 'fp B'
    }
  };

  describe('data profile logs', () => {{
    it('returns data profile create log', () => {
      log.event = 'create';
      log.type = 'DataProfile';
      log.objectId = '1234561';
      log.changes = "[{\"new\":\"\\\"[data-profile-test1]\\\"\",\"old\":\"null\"}]";
      const formatted = logDetails(log, profiles, patterns, filteringProfiles);
      expect(Object.keys(formatted)).toEqual(['auditId', 'tenantId', 'userId', 'clientName', 'createdAt', 'event', 'type', 'objectId', 'changes', 'name', 'object', 'eventLabel']);
      expect(formatted.name = 'profile A');
    });

    it('persists name if present', () => {
      log.event = 'create';
      log.type = 'DataProfile';
      log.objectId = '1234561';
      log.changes = "[{\"new\":\"\\\"[data-profile-test1]\\\"\",\"old\":\"null\"}]";
      log.name = 'already set name';
      const formatted = logDetails(log, profiles, patterns, filteringProfiles);
      expect(Object.keys(formatted)).toEqual(['auditId', 'tenantId', 'userId', 'clientName', 'createdAt', 'event', 'type', 'objectId', 'changes', 'name', 'object', 'eventLabel']);
      expect(formatted.name = 'already set name');
    });
  }});

  describe('data filtering profile logs', () => {{
    it('returns data filtering profile create log', () => {
      log.event = 'create';
      log.type = 'DataFilteringProfile';
      log.objectId = '1234561';
      log.changes = "[{\"new\":\"\\\"[data-filtering-profile-test1]\\\"\",\"old\":\"null\"}]";
      const formatted = logDetails(log, profiles, patterns, filteringProfiles);
      expect(Object.keys(formatted)).toEqual(['auditId', 'tenantId', 'userId', 'clientName', 'createdAt', 'event', 'type', 'objectId', 'changes', 'name', 'object', 'eventLabel']);
      expect(formatted.name = 'fp A');
    });

    it('persists name if present', () => {
      log.event = 'create';
      log.type = 'DataFilteringProfile';
      log.objectId = '1234561';
      log.changes = "[{\"new\":\"\\\"[data-filtering-profile-test1]\\\"\",\"old\":\"null\"}]";
      log.name = 'already set name';
      const formatted = logDetails(log, profiles, patterns, filteringProfiles);
      expect(Object.keys(formatted)).toEqual(['auditId', 'tenantId', 'userId', 'clientName', 'createdAt', 'event', 'type', 'objectId', 'changes', 'name', 'object', 'eventLabel']);
      expect(formatted.name = 'already set name');
    });
  }});

  describe('data pattern logs', () => {{
    it('returns data pattern create log', () => {
      log.event = 'create';
      log.type = 'DataPattern';
      log.objectId = '1234561';
      log.changes = "[{\"new\":\"\\\"[data-pattern-test1]\\\"\",\"old\":\"null\"}]";
      const formatted = logDetails(log, profiles, patterns, filteringProfiles);
      expect(Object.keys(formatted)).toEqual(['auditId', 'tenantId', 'userId', 'clientName', 'createdAt', 'event', 'type', 'objectId', 'changes', 'name', 'object', 'eventLabel']);
      expect(formatted.name = 'Passport - US');
    });

    it('persists name if present', () => {
      log.event = 'create';
      log.type = 'DataPattern';
      log.objectId = '1234561';
      log.changes = "[{\"new\":\"\\\"[data-pattern-test1]\\\"\",\"old\":\"null\"}]";
      log.name = 'already set name';
      const formatted = logDetails(log, profiles, patterns, filteringProfiles);
      expect(Object.keys(formatted)).toEqual(['auditId', 'tenantId', 'userId', 'clientName', 'createdAt', 'event', 'type', 'objectId', 'changes', 'name', 'object', 'eventLabel']);
      expect(formatted.name = 'already set name');
    });
  }});

});