// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<ReportCard /> should match snapshot 1`] = `
<div
  className="reportDetails"
>
  <div
    className="card-title reportHeader"
  >
    Incident Details
  </div>
  <div
    className="details"
  >
    <table
      className="table table-borderless table-condensed"
    >
      <tbody>
        <tr>
          <td
            className="detailCategory"
          >
            Channel
          </td>
          <td>
            Prisma Access
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            Report ID
          </td>
          <td>
            12345
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            Source
          </td>
          <td />
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            Action
          </td>
          <td />
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            Data Profile
          </td>
          <td />
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            File
          </td>
          <td>
            <span
              className="dlp-report-card-filename"
            >
              Test Asset
            </span>
            <FaFileDownload
              className="btn-link downloadIcon"
              data-testid="trigger-download"
              onClick={[Function]}
            />
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            Scan Date
          </td>
          <td>
            July 9, 2020, 1:56 PM PDT
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            User ID
          </td>
          <td />
        </tr>
      </tbody>
    </table>
  </div>
</div>
`;
