// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DLPView /> should match snapshot 1`] = `
<div
  className="settings-container dlp-settings"
>
  <div
    className="header-section"
  >
    <h5>
      DLP Settings
    </h5>
    <div
      className="help-text"
    >
      Configure the action taken in the event an error is encountered when a file is scanned by the Enterprise Data Loss Prevention cloud service.
    </div>
  </div>
  <div
    className="data-section"
  >
    <div
      className="left-col"
    >
      <div
        className="text-label"
      >
        Action on any Error
      </div>
    </div>
    <div
      className="right-col"
    >
      <RadioGroup
        className="radio-horizontal"
        disabled={false}
        horizontal={true}
        name="actionOnError"
        onChange={[Function]}
        value="allow"
      >
        <Radio
          value="allow"
        >
          Allow
        </Radio>
        <Radio
          value="block"
        >
          Block
        </Radio>
      </RadioGroup>
    </div>
  </div>
  <div
    className="footer-section"
  >
    <LoadingButton
      appearance="primary"
      dataMetrics="loading-primary-button"
      dataResult={
        Object {
          "loading": false,
        }
      }
      disabled={true}
      onClickCallback={[Function]}
    >
      Save
    </LoadingButton>
  </div>
</div>
`;
