// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<TestDrawer /> should match snapshot when closed 1`] = `
<SidePanel
  addClassName="drawer-container"
  isOpen={false}
  onClose={[Function]}
  showMask={true}
  title="name"
>
  <main
    className="test-drawer-main"
  >
    <div
      className="form-label"
    >
      Technique
    </div>
    <div
      className="name"
    >
      Indexed Document Match (IDM)
    </div>
    <div
      className="form-label"
    >
      Category
    </div>
    <div
      className="category"
    >
      category
    </div>
    <section
      className="title-area"
    >
      Upload Test Files
    </section>
    <FileUpload
      buttonLabel="Browse Files"
      files={Array []}
      maxFilesAtOnce={5}
      onUpload={[Function]}
      textLabel="Drag and drop your files here"
    />
    <div
      className="upload-info"
    >
      Maximum of 5 files can be tested simultaneously. Each file must be 1 MB or less.
    </div>
    <section
      className="files-queue"
    >
      <div
        className="error-message"
      />
    </section>
    <section
      className="result-section in-active"
    >
      <section
        className="result-body"
      />
      <section
        className="result-header"
      >
        <div
          className="column-1"
        >
          Test File Name
        </div>
        <div
          className="column-2"
        >
          Overlapping Score
          <FaInfoCircle
            className="score-tooltip-icon"
            data-class="place-top score-tooltip"
            data-tip="Overlapping score is from 0 to 100. 0 represents no overlap with fingerprint, 100 represents a tight overlap."
          />
          <ReactTooltip
            clickable={false}
            insecure={true}
            resizeHide={true}
            wrapper="div"
          />
        </div>
      </section>
    </section>
  </main>
  <section
    className="drawer-footer tw-flex tw-justify-end tw-space-x-1"
  >
    <Button
      addClassName="cancel"
      appearance="secondary"
      buttonType="default"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    >
      Close
    </Button>
  </section>
</SidePanel>
`;

exports[`<TestDrawer /> should match snapshot when open 1`] = `
<SidePanel
  addClassName="drawer-container"
  isOpen={true}
  onClose={[Function]}
  showMask={true}
  title="name"
>
  <main
    className="test-drawer-main"
  >
    <div
      className="form-label"
    >
      Technique
    </div>
    <div
      className="name"
    >
      Indexed Document Match (IDM)
    </div>
    <div
      className="form-label"
    >
      Category
    </div>
    <div
      className="category"
    >
      category
    </div>
    <section
      className="title-area"
    >
      Upload Test Files
    </section>
    <FileUpload
      buttonLabel="Browse Files"
      files={Array []}
      maxFilesAtOnce={5}
      onUpload={[Function]}
      textLabel="Drag and drop your files here"
    />
    <div
      className="upload-info"
    >
      Maximum of 5 files can be tested simultaneously. Each file must be 1 MB or less.
    </div>
    <section
      className="files-queue"
    >
      <div
        className="error-message"
      />
    </section>
    <section
      className="result-section in-active"
    >
      <section
        className="result-body"
      />
      <section
        className="result-header"
      >
        <div
          className="column-1"
        >
          Test File Name
        </div>
        <div
          className="column-2"
        >
          Overlapping Score
          <FaInfoCircle
            className="score-tooltip-icon"
            data-class="place-top score-tooltip"
            data-tip="Overlapping score is from 0 to 100. 0 represents no overlap with fingerprint, 100 represents a tight overlap."
          />
          <ReactTooltip
            clickable={false}
            insecure={true}
            resizeHide={true}
            wrapper="div"
          />
        </div>
      </section>
    </section>
  </main>
  <section
    className="drawer-footer tw-flex tw-justify-end tw-space-x-1"
  >
    <Button
      addClassName="cancel"
      appearance="secondary"
      buttonType="default"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    >
      Close
    </Button>
  </section>
</SidePanel>
`;
