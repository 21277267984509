/* eslint-disable camelcase */
import React from "react";
import PropTypes from 'prop-types';

import translate from "../../helpers/translate";
import humanize from "../../helpers/humanize";
import './UserInfo.scss';

const t = (scope, options) => translate(`user_info.${scope}`, options);

const UserInfo = ({ userInfo, panosVersion }) => {
  return (
    <div className='userInfo' data-testid='testRoot'>
      <div className='header-section'>
        <h5>{t('title')}</h5>
        <div className="help-text">{t('description')}</div>
      </div>

      <div className='data-section'>
        <div className='left-col'>
          <div className='text-label'>{t('tenant_id_dlp')}</div>
          { panosVersion &&
            <div className='text-label'>{t('panos_version')}</div>
          }
          <div className='text-label'>{t('email')}</div>
          <div className='text-label'>{t('role')}</div>
        </div>

        <div className='right-col'>
          <div className='text-data'>{userInfo.tenantIdDLP}</div>
          { panosVersion &&
            <div className='text-data'>{panosVersion}</div>
          }
          <div className='text-data'>{userInfo.email}</div>
          <div className='text-data'>{humanize(userInfo.role)}</div>
        </div>
      </div>
    </div>
  );
}

UserInfo.propTypes = {
  userInfo: PropTypes.shape({
    tenantIdDLP: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string
  }),
  panosVersion: PropTypes.string
}

UserInfo.defaultProps = {
  userInfo: undefined,
  panosVersion: undefined
}

export default UserInfo;