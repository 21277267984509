import _compact from 'lodash.compact';
import translate from '../../../helpers/translate';

function t(scope, options) {
  return translate(`${scope}`, options);
}

function conditionStr(condition) {
  let str = '('
  condition.rule_items.forEach((item, itemIndex) => {
    str += `${item.name} ${item.confidence_level} ${item.occurrence_operator_type}`
    if (['more_than_equal_to', 'less_than_equal_to'].includes(item.occurrence_operator_type)) {
      str += ` ${item.occurrence_count}`
    }
    if (item.occurrence_operator_type === 'between') {
      str += ` ${item.occurrence_low} ${item.occurrence_high}`
    }
    if (condition.rule_items.length > 1 && itemIndex < condition.rule_items.length - 1) {
      str += ` ${condition.operators[itemIndex] || condition.operators[0]} `
    }
  });
  str += ')'
  return str;
};

function ruleStr(rule) {
  if (Object.keys(rule).length === 0) {
    return null;
  }
  let str = '';
  rule.conditions.forEach((condition, conditionIndex) => {
    if (rule.conditions.length > 1 && conditionIndex > 0) {
      if (condition.rule_items.length === 1 && condition.operators[0]?.includes('not') && !rule.operators[conditionIndex - 1]?.includes('not')) {
        str += ` ${condition.operators[0]} `;
      }
      else if (condition.rule_items.length === 1 && condition.operators[0]?.includes('not') && rule.operators[conditionIndex - 1]?.includes('not')) {
        str += ` ${rule.operators[conditionIndex - 1].split('_')[0]} `;
      }
      else {
        str += ` ${rule.operators[conditionIndex - 1]} `;
      }
    }
    str += conditionStr(condition)
  })
  return str;
};

function detectionRules(profile) {
  const tree = [
    {
      rule_type: 'expression_tree',
      expression_tree: profile.primaryExpTree
    }
  ]
  if (profile.secondaryExpTree.rule_item || profile.secondaryExpTree.sub_expressions.length > 0) {
    return tree.concat([
      {
        rule_type: 'expression_tree',
        expression_tree: profile.secondaryExpTree
      }
    ])
  }

  return tree;
}

function multiProfileDetectionRules(profile) {
  const tree = [
    {
      rule_type: 'multi_profile',
      multi_profile: {
        operator_type: 'or',
        data_profile_ids: profile.selectedPrimaryProfiles.map(p => p.key)
      }
    }
  ]
  if (profile.selectedSecondaryProfiles.length > 0) {
    return tree.concat([
      {
        rule_type: 'multi_profile',
        multi_profile: {
          operator_type: 'or',
          data_profile_ids: profile.selectedSecondaryProfiles.map(p => p.key)
        }
      }
    ])
  }

  return tree;
}

function basicRule(rule) {
  if (Object.keys(rule).length === 0) {
    return null;
  }
  const patternRules = [];
  rule.conditions.forEach((condition, conditionIndex) => {
    let matchType = 'include';
    if (conditionIndex > 0) {
      matchType = rule.operators[conditionIndex - 1]?.includes('not') ? 'exclude' : 'include';
    }
    condition.rule_items.forEach((item, itemIndex) => {
      if (itemIndex > 0 && condition.operators[itemIndex - 1].includes('not')) {
        matchType = matchType === 'include' ? 'exclude' : 'include';
      }
      patternRules.push({...item, match_type: matchType})
    })
  })
  return ({
    operator_type: rule.operators[0] ? rule.operators[0].split('_')[0] : rule.conditions[0].operators[0].split('_')[0],
    data_pattern_rules: patternRules
  })
}

export const formatRequest = (profile) => {
  const requestBody = {
    updated_by: t('application.dlp_ui'),
    name: profile.name.trim(),
    profile_status: 'active',
    profile_type: 'custom',
    status: 'non_hidden',
    type: profile.advanced ? 'advanced' : 'basic',
    schema_version: profile.schema_version
  };

  if (profile.schema_version === 1) {
    if (profile.advanced) {
      requestBody.advance_data_patterns_rule_request = profile.advance_data_patterns_rules.map(rule => ruleStr(rule));
      // Remove falsy values such as undefined, null and empty strings
      requestBody.advance_data_patterns_rule_request = _compact(requestBody.advance_data_patterns_rule_request);
    }
    else {
      requestBody.data_patterns_rule_1 = basicRule(profile.advance_data_patterns_rules[0]);
      requestBody.data_patterns_rule_2 = basicRule(profile.advance_data_patterns_rules[1]);
    }
  }
  else if (profile.multi) {
    requestBody.detection_rules = multiProfileDetectionRules(profile);
  }
  else {
    requestBody.detection_rules = detectionRules(profile);
  }

  if (profile.create) {
    requestBody.created_by = t('application.dlp_ui')
  }

  return requestBody;
};

export default (profile) => formatRequest(profile);
