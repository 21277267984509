import { Button } from '@panwds/react-ui';
import React, {useState, useEffect} from 'react';
import Popup from 'reactjs-popup';
import axios from 'axios';
import PropTypes from 'prop-types';
import $ from 'jquery';

import translate from '../../helpers/translate';
import AssignMenu from './AssignMenu';
import ResolveMenu from './ResolveMenu';
import NotesForm from './NotesForm';

function t(scope, options) {
  return translate(`incidents.index.${scope}`, options);
}

const BulkActions = ({selectedItems, assignees, saveAssignee, deleteAssignee, globalStatusLookupURL,
  tenantStatusLookupURL, incidentUpdateURL, incidentsUpdated}) => {
  const [statuses, setStatuses] = useState([]);
  const [menuOpen, setMenuOpen] = useState({
    assign: false,
    resolution: false,
    notes: false,
  });

  const fetchStatuses = async() => {
    const {data: globalStatuses} = await axios.get(globalStatusLookupURL);
    const {data: tenantStatuses} = await axios.get(tenantStatusLookupURL);
    globalStatuses.forEach(s => { s.global = true });
    setStatuses([...globalStatuses, ...tenantStatuses]);
  }

  useEffect(() => {
    fetchStatuses();
  }, []);

  const updateStatus = async(status) => {
    const payload = {
      id: status.id,
      resolution_status: status.resolutionStatus,
      created_at: status.createdAt
    };
    const response = await axios.put(tenantStatusLookupURL, payload);
    if (response.status === 200) {
      const statusList = [...statuses];
      if (status.id) {
        const index = statuses.findIndex(s => s.id === status.id);
        statusList.splice(index, 1, status);
      } else {
        statusList.push(response.data);
      }
      setStatuses(statusList);
      return true;
    }
    return false;
  }

  const hasSelection = (selection) => selection && selection.length;

  const updateIncidents = async(updateDetails) => {
    const payload = {
      incident_list: selectedItems.map(incident => incident.id),
      update_details: updateDetails
    }
    const response = await axios.put(incidentUpdateURL, payload);
    incidentsUpdated();
    return response;
  }

  const userSelected = async(user) => {
    selectedItems.forEach(incident => { incident.assigneeId = user.id });
    const response = updateIncidents({
      assignee_id: user.id
    });
    return response;
  }

  const statusSelected = async(status) => {
    selectedItems.forEach(incident => { incident.resolutionStatus = status.resolutionStatus });
    const response = updateIncidents({
      resolution_status: status.resolutionStatus
    });
    return response;
  }

  const createTrigger = (className, label, enabled) => {
    return (
      <Button isMenu appearance="secondary" addClassName={`${className} bulkActionBtn tw-mr-1`} disabled={!enabled} >
        {label}
      </Button>
    );
  }

  const createAssignMenu = () => {
    return (
      <AssignMenu users={assignees}
        closePopup={() => setMenuOpen({ assign: false, resolution: false, notes: false })}
        saveUserCallback={saveAssignee}
        deleteUserCallback={deleteAssignee}
        userSelected={userSelected}/>
    );
  }

  const createResolutionMenu = () => {
    return (
      <ResolveMenu statusList={statuses}
        closePopup={() => setMenuOpen({ assign: false, resolution: false, notes: false })}
        updateStatus={updateStatus}
        statusSelected={statusSelected}/>
    );
  }

  const deleteNotes = async() => {
    selectedItems.forEach(incident => { incident.incidentNotes = undefined });
    return updateIncidents({
      clear_notes: true
    });
  }

  const saveNotes = async(notes) => {
    selectedItems.forEach(incident => { incident.incidentNotes = notes });
    const response = updateIncidents({
      notes
    });
    return response;
  }

  const createNotesForm = () => {
    const notes = selectedItems.map(incident => incident.incidentNotes);

    return (
      <NotesForm notes={notes} saveNotes={saveNotes} deleteNotes={deleteNotes} closePopup={() => setMenuOpen({ assign: false, resolution: false, notes: false })}/>
    );
  }

  $(document).on('click.bulkTable.bulkActionBtn', function checkFilterDropDowns(event) {
    const target = $(event.target);
    if (!target.hasClass('bulkActionBtn') && target.closest('.popup-content').length === 0 && (menuOpen.assign || menuOpen.resolution || menuOpen.notes)) {
      setMenuOpen({ assign: false, resolution: false, notes: false })
    }
  });

  return (
    <div className='bulkActionsBar tw-flex tw-m-2'>
      <Popup
        className='assignOwnerPopup'
        trigger={createTrigger('assignOwner', t('assign_to'), hasSelection(selectedItems))}
        position='bottom left'
        on='click'
        arrow={false}
        open={menuOpen.assign}
        onOpen={() => setMenuOpen({ assign: true, resolution: false, notes: false })}
        closeOnEscape
        >
        {createAssignMenu}
      </Popup>
      <Popup
        trigger={createTrigger('changeResolution', t('change_resolution'), hasSelection(selectedItems))}
        position='bottom left'
        on='click'
        arrow={false}
        open={menuOpen.resolution}
        closeOnEscape
        onOpen={() => setMenuOpen({ assign: false, resolution: true, notes: false })}
        >
        {createResolutionMenu}
      </Popup>
      <Popup
        className='notesPopup'
        trigger={createTrigger('editNotes', t('edit_notes'), hasSelection(selectedItems))}
        position='bottom left'
        on='click'
        arrow={false}
        open={menuOpen.notes}
        onOpen={() => setMenuOpen({ assign: false, resolution: false, notes: true })}
        closeOnEscape
        >
        {createNotesForm}
      </Popup>
    </div>
  );
}

BulkActions.propTypes = {
  tenantStatusLookupURL: PropTypes.string.isRequired,
  globalStatusLookupURL: PropTypes.string.isRequired,
  incidentUpdateURL: PropTypes.string.isRequired,
  selectedItems: PropTypes.array,
  assignees: PropTypes.array,
  saveAssignee: PropTypes.func.isRequired,
  deleteAssignee: PropTypes.func.isRequired,
  incidentsUpdated: PropTypes.func.isRequired,
};

BulkActions.defaultProps = {
  selectedItems: [],
  assignees: [],
}
export default BulkActions;