// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<EdmDatasetDetails> actionable renders 1`] = `
<div
  className="tableDetailsView"
>
  <div
    className="detailHeader"
  >
    <span
      className="detailTitle"
    >
      EDM Dataset A
    </span>
    <Button
      addClassName="text-lg tw-float-right"
      appearance="tertiary-clear"
      aria-hidden="true"
      buttonType="default"
      data-dismiss="modal"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    />
  </div>
  <div>
    <div
      style={
        Object {
          "padding": "15px",
        }
      }
    >
      <div
        className="actionBtns"
      >
        <Button
          appearance="secondary"
          aria-label="edit"
          buttonType="default"
          disabled={false}
          icon={
            <FaPen
              className="muteText"
            />
          }
          isMenu={false}
          size="md"
        />
        <Button
          appearance="secondary"
          aria-label="delete"
          buttonType="default"
          disabled={false}
          icon={
            <FaTrash
              className="muteText"
            />
          }
          isMenu={false}
          size="md"
        />
      </div>
      <br />
      <p
        className="detailSubtitle"
      >
        edm_datasets.view.details
      </p>
      <table
        className="table table-borderless table-condensed"
      >
        <tbody>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.name
            </td>
            <td>
              EDM Dataset A
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.updated_by
            </td>
            <td>
              Prisma Access
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.updated_at
            </td>
            <td>
              March 22, 2021, 11:45 AM PDT
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  </div>
</div>
`;

exports[`<EdmDatasetDetails> with active fields renders 1`] = `
<div
  className="tableDetailsView"
>
  <div
    className="detailHeader"
  >
    <span
      className="detailTitle"
    >
      EDM Dataset A
    </span>
    <Button
      addClassName="text-lg tw-float-right"
      appearance="tertiary-clear"
      aria-hidden="true"
      buttonType="default"
      data-dismiss="modal"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    />
  </div>
  <div>
    <div
      style={
        Object {
          "padding": "15px",
        }
      }
    >
      <br />
      <p
        className="detailSubtitle"
      >
        edm_datasets.view.details
      </p>
      <table
        className="table table-borderless table-condensed"
      >
        <tbody>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.name
            </td>
            <td>
              EDM Dataset A
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.updated_by
            </td>
            <td>
              Prisma Access
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.updated_at
            </td>
            <td>
              March 22, 2021, 11:45 AM PDT
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  </div>
</div>
`;

exports[`<EdmDatasetDetails> without active fields renders 1`] = `
<div
  className="tableDetailsView"
>
  <div
    className="detailHeader"
  >
    <span
      className="detailTitle"
    >
      EDM Dataset A
    </span>
    <Button
      addClassName="text-lg tw-float-right"
      appearance="tertiary-clear"
      aria-hidden="true"
      buttonType="default"
      data-dismiss="modal"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    />
  </div>
  <div>
    <div
      style={
        Object {
          "padding": "15px",
        }
      }
    >
      <br />
      <p
        className="detailSubtitle"
      >
        edm_datasets.view.details
      </p>
      <table
        className="table table-borderless table-condensed"
      >
        <tbody>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.name
            </td>
            <td>
              EDM Dataset A
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.updated_by
            </td>
            <td>
              Prisma Access
            </td>
          </tr>
          <tr>
            <td
              className="form-label"
            >
              edm_datasets.view.updated_at
            </td>
            <td>
              March 22, 2021, 11:45 AM PDT
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  </div>
</div>
`;
