import { profileDetails } from '../DataProfiles/profileDataTransformer';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`${scope}`, options);
}

function transformAggRows(item, key, profiles = {}) {
  const aggData = { item: item[key], itemId: item[key], incidentCount: item.incidentCount, sensitiveFileCount: item.sensitiveFileCount, date: item.aggregationUntilTimestamp};
  if (key === 'dataProfileId') {
    const profileName = profiles[item[key]] ? profiles[item[key]].name : t('errors.not_found')
    return { ...aggData, item: profileName}
  }

  if (key === 'action') {
    return {...aggData, item: `${item[key].charAt(0).toUpperCase()}${item[key].slice(1)}`}
  }

  return {...aggData, item: translate(item[key], {platform: true})}
}

function incidentsAggregation(aggregation, profiles) {
  return {
    action: (aggregation.actionAggregationList || []).map(item => transformAggRows(item, 'action')),
    source: (aggregation.sourceAggregationList || []).map(item => transformAggRows(item, 'source')),
    dataProfileId: (aggregation.dataProfileAggregationList || []).map(item => transformAggRows(item, 'dataProfileId', profiles)),
    lastAggregationTimestamp: aggregation.lastAggregationTimestamp
  };
}

function filterOptions(profiles) {
  return(
    {
      filterable: true,
      filterOptions: [
        { name: t('incidents.index.columns.action'), id: 'action', multiple: true },
        { name: t('incidents.index.columns.channel'), id: 'channel', multiple: true },
        { name: t('incidents.index.columns.data_profile'), id: 'dataProfile', multiple: true },
        { name: t('incidents.index.columns.feedback'), id: 'feedback_status', multiple: true, groupBy: true }
      ],
      action: [
        { name: 'Alert', id: 'alert' },
        { name: 'Block', id: 'block' },
        { name: 'Quarantine', id: 'quarantine' }
      ],
      channel: [
        { name: 'Prisma Access', id: 'prisma-access'},
        { name: 'NGFW', id: 'ngfw'}
      ],
      feedback_status: [
        { name: 'No Response', id: null, category: 'No Status'},
        { name: 'Confirmed Sensitive', id: 'Confirmed sensitive', category: 'Has Status'},
        { name: 'False Positive', id: 'Confirmed false positive', category: 'Has Status'},
        { name: 'Exception Requested', id: 'Exception requested', category: 'Has Status'}
      ],
      dataProfile: Object.values(profiles).map(profile => { return( {id: profile.id, name: profile.name } ) } )
    }
  )
}

function incidentsRows(resources, profiles, datasets, patterns, region) {
  return(
    resources.map(incident => {
      const profile = profiles[incident.dataProfileId] || {};

      return({
        id: incident.incidentId,
        filename: incident.filename,
        dataProfileId: profile.name || t('errors.not_found'),
        dataProfile: Object.keys(profile).length === 0 ? {} : profileDetails(profile, patterns, datasets, false, profiles),
        dataProfileVersion: profile.version,
        incidentDataProfileVersion: incident.dataProfileVersion,
        outdated: profile && profile.version !== incident.dataProfileVersion,
        channel: translate(incident.channel, {platform: true}),
        action: `${incident.action.charAt(0).toUpperCase()}${incident.action.slice(1)}`,
        source: translate(incident.source, {platform: true}),
        createdAt: incident.createdAt,
        reportId: incident.reportId,
        link: `./incidents/${incident.incidentId}?region=${region}`,
        viewableOverride: Object.keys(profile).length === 0,
        incidentFeedbackStatus: incident.incidentFeedbackStatus || '-',
        userId: incident.userId,
        assigneeId: incident.assigneeId,
        resolutionStatus: incident.resolutionStatus,
        incidentNotes: incident.incidentNotes
      })
    })
  )
}

export const transformIncidentData = (incidents, aggregation, profiles, datasets, patterns, region) => {
  return (
    {
      aggregationData: incidentsAggregation(aggregation, profiles),
      pageData: {
        size: incidents.body ? incidents.body.size : 0,
        number: incidents.body ? incidents.body.number : 0,
        total_effective_elements: incidents.body ? incidents.body.totalElements : 0,
        total_pages: incidents.body ? incidents.body.totalPages : 0
      },
      filters: filterOptions(profiles),
      incidents: incidentsRows(incidents.content || [], profiles, datasets, patterns, region)
    }
  );
}

export default (incidents, aggregation, profiles, datasets, patterns, region) => transformIncidentData(incidents, aggregation, profiles, datasets, patterns, region);
