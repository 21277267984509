import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

class ColumnTableForm extends React.Component {
  checkAll = () => {
    if ($('input.checkAll').is(':checked')) {
      $('.includeChkBox input').prop('checked', 'checked');
      this.props.updateParentState({saveDisabled: false});
    }
    else {
      $('.includeChkBox input').prop('checked', false);
      this.props.updateParentState({saveDisabled: true});
    }
  }

  checkCheckAll = () => {
    if ($('.includeChkBox input:checked').length === $('.includeChkBox input').length) {
      $('input.checkAll').prop('checked', 'checked');
    }
    else {
      $('input.checkAll').prop('checked', false);
    }
    if ($('.includeChkBox input:checked').length === 0) {
      this.props.updateParentState({saveDisabled: true});
    }
    else {
      this.props.updateParentState({saveDisabled: false});
    }
  }

  render() {
    return (
      <table className='edmFormTable table table-striped table-condensed table-hover'>
        <thead className='table-bordered'>
          <tr>
            <th className='name table-bordered'>Field Name</th>
            <th className='type table-bordered'>Data Type</th>
            <th className='include table-bordered'>
              Include?
              <input className='checkAll tw-float-right' type='checkbox' onClick={ () => {this.checkAll()}} />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.columns.map(column =>
            <tr key={column.name}>
              <td style={{textAlign:'left'}}>{column.name}</td>
              <td style={{textAlign:'left'}}>{column.data_type_name}</td>
              <td className='includeChkBox'><input type='checkbox' value={column.id} onClick={ () => {this.checkCheckAll()}} id={`check-${column.id}`}/></td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

ColumnTableForm.propTypes = {
  columns: PropTypes.array,
  updateParentState: PropTypes.func.isRequired,
};

ColumnTableForm.defaultProps = {
  columns: [],
}

export default ColumnTableForm;
