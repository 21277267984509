import React from 'react';
import '@testing-library/jest-dom/extend-expect';
import { shallow } from 'enzyme';
import { act } from 'react-dom/test-utils';
import { render, fireEvent, screen } from '@testing-library/react';
import sinon from 'sinon';

import FileUpload from '../FileUpload';

describe('<FileUpload />', () => {
  let sandbox, wrapper;
  const baseProps = {
    onUpload: () => {}
  };
  const mockView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    return shallow(<FileUpload { ...props } />);
  };
  const renderView = (customProps = {}) => {
    const props = { ...baseProps, ...customProps };

    render(
      <FileUpload
        {...props}
      />
    );
  };

  beforeEach(() => {
    sandbox = sinon.createSandbox();
  });

  afterEach(() => {
    sandbox.restore();
  });

  it('should match snapshot', () => {
    wrapper = mockView({
      maxFilesAtOnce: 0
    });

    expect(wrapper).toMatchSnapshot();
  });

  it('should handle close and delete', async () => {
    renderView({});

    await act(async () => {
      expect(screen.queryByTestId('file-upload-button')).toBeInTheDocument();
      fireEvent.click(screen.queryByTestId('file-upload-button'));
    });
  });
});