import React from 'react';

import { configure, shallow } from 'enzyme';
import { render, fireEvent } from '@testing-library/react';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import Adapter from 'enzyme-adapter-react-16';
import DataProfileFormV2 from './DataProfileFormV2';

configure({ adapter: new Adapter() });

const flushPromises = () => new Promise(setImmediate);

describe('<DataProfileFormV2>', () => {
  let wrapper;
  const mock = new MockAdapter(axios);
  const primaryExpTree = {
    operator_type: 'and',
    rule_item: null,
    sub_expressions: [
      {
        operator_type: null,
        rule_item: {
          id: '1234560',
          occurrence_operator_type: 'any',
          occurrence_count: null,
          confidence_level: 'high',
          name: 'SSN',
          supported_confidence_levels: [
            'high',
            'low'
          ],
          detection_technique: 'regex',
          version: 1
        },
        sub_expressions: [],
      },
      {
        operator_type: 'or',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: 'or',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  id: '1234561',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  confidence_level: 'high',
                  name: 'CCN',
                  supported_confidence_levels: [
                    'high',
                    'low'
                  ],
                  detection_technique: 'regex',
                  version: 1
                },
                sub_expressions: []
              },
              {
                operator_type: null,
                rule_item: {
                  edm_dataset_id: '1234565',
                  name: 'Dataset B',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  occurrence_low: null,
                  occurrence_high: null,
                  primary_fields: [
                    'col-1',
                    'col-2'
                  ],
                  primary_match_criteria: 'all',
                  primary_match_any_count: null,
                  secondary_fields: [],
                  secondary_match_criteria: 'all',
                  secondary_match_any_count: null,
                  detection_technique: 'edm'
              },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      {
        operator_type: 'not',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: null,
            rule_item: {
                edm_dataset_id: '1234564',
                name: 'Dataset A',
                occurrence_operator_type: 'any',
                occurrence_count: null,
                occurrence_low: null,
                occurrence_high: null,
                primary_fields: [
                  'col-1',
                  'col-3'
                ],
                primary_match_criteria: 'all',
                primary_match_any_count: null,
                secondary_fields: [],
                secondary_match_criteria: 'all',
                secondary_match_any_count: null,
                detection_technique: 'edm'
            },
            sub_expressions: []
          }
        ]
      }
    ]
  };
  const secondaryExpTree = {
    operator_type: 'or',
    rule_item: null,
    sub_expressions: [
      {
        operator_type: null,
        rule_item: {
          id: '1234560',
          occurrence_operator_type: 'any',
          occurrence_count: null,
          confidence_level: 'high',
          name: 'SSN',
          supported_confidence_levels: [
            'high',
            'low'
          ],
          detection_technique: 'regex',
          version: 1
        },
        sub_expressions: [],
      },
      {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  id: '1234561',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  confidence_level: 'high',
                  name: 'CCN',
                  supported_confidence_levels: [
                    'high',
                    'low'
                  ],
                  detection_technique: 'regex',
                  version: 1
                },
                sub_expressions: []
              },
              {
                operator_type: null,
                rule_item: {
                  edm_dataset_id: '1234565',
                  name: 'Dataset B',
                  occurrence_operator_type: 'any',
                  occurrence_count: null,
                  occurrence_low: null,
                  occurrence_high: null,
                  primary_fields: [
                    'col-1',
                    'col-2'
                  ],
                  primary_match_criteria: 'all',
                  primary_match_any_count: null,
                  secondary_fields: [],
                  secondary_match_criteria: 'all',
                  secondary_match_any_count: null,
                  detection_technique: 'edm'
              },
                sub_expressions: []
              }
            ]
          }
        ]
      },
      {
        operator_type: 'and',
        rule_item: {
          edm_dataset_id: '1234564',
          name: 'Dataset A',
          occurrence_operator_type: 'any',
          occurrence_count: null,
          occurrence_low: null,
          occurrence_high: null,
          primary_fields: [
            'col-1',
            'col-3'
          ],
          primary_match_criteria: 'all',
          primary_match_any_count: null,
          secondary_fields: [],
          secondary_match_criteria: 'all',
          secondary_match_any_count: null,
          detection_technique: 'edm'
        },
        sub_expressions: []
      }
    ]
  };
  const datasetA = {
    id: '1234564',
    name: 'Dataset A',
    index_version: 1,
    index_status: 'success',
    all_fields: [],
    active_fields: [
      {id: 'col-1', name: 'SSN', data_type_name: 'Social Security Number'},
      {id: 'col-2', name: 'CCN', data_type_name: 'Credit Card Number'},
      {id: 'col-3', name: 'Subscriber ID', data_type_name: 'Subscriber ID'},
      {id: 'col-4', name: 'Member ID', data_type_name: 'Member ID'}
    ]
  };
  const datasetB = {
    id: '1234565',
    name: 'Dataset B',
    index_version: 1,
    index_status: 'success',
    active_fields: [
      {id: 'col-1', name: 'SSN', data_type_name: 'Social Security Number'},
      {id: 'col-2', name: 'CCN', data_type_name: 'Credit Card Number'},
      {id: 'col-3', name: 'BankNum', data_type_name: 'Bank Account Numbers'}
    ]
  };
  const datasets = [datasetA, datasetB];
  const existingV2Profile = {
    name: 'existing profile',
    type: 'advanced',
    id: '12345',
    profile_type: 'custom',
    primaryExpTree,
    secondaryExpTree,
    schema_version: 2,
    secondary_rule_eligible: true
  };
  const existingV2ProfileNoSecondary = {
    name: 'existing profile',
    type: 'advanced',
    id: '12345',
    profile_type: 'custom',
    primaryExpTree,
    secondaryExpTree,
    schema_version: 2,
    secondary_rule_eligible: false
  };
  const predefinedProfile = {
    profile_type: 'predefined'
  }
  const history = {
    push: jest.fn()
  };
  const props = {
    history,
    datasets
  };
  const updateProps = {
    dataProfile: existingV2Profile,
    history,
    datasets
  };
  const updatePropsNoSecondary = {
    dataProfile: existingV2ProfileNoSecondary,
    history,
    datasets
  };

  describe('create form', () => {
    beforeEach(() => {
      wrapper = shallow(<DataProfileFormV2 {...props}/>);
    });

    it('Component has expected classes', () => {
      expect(wrapper.find('.dataProfileForm')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });

    it('renders definition preview', () => {
      wrapper.setState({ name: 'Test Profile', primaryExpTree, secondaryExpTree});
      expect(wrapper).toMatchSnapshot();
    });

    it('renders bracket preview', () => {
      wrapper.setState({ name: 'Test Profile', primaryExpTree, secondaryExpTree, previewType: 'bracket'});
      expect(wrapper).toMatchSnapshot();
    });

    it('should delete rule item', () => {
      wrapper.setState({primaryExpTree: {
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: [
              {
                operator_type: null,
                rule_item: {
                  id: ''
                },
                sub_expressions: []
              }
            ]
          }
        ]
      }})
      const comp = wrapper.instance();
      comp.deleteRuleItem('0-0-0');
      expect(wrapper.state('primaryExpTree')).toEqual({
        operator_type: 'and',
        rule_item: null,
        sub_expressions: [
          {
            operator_type: 'and',
            rule_item: null,
            sub_expressions: []
          }
        ]
      });
    });

    describe('add buttons', () => {
      beforeEach(() => {
        wrapper = render(<DataProfileFormV2 {...props} />);
      });

      it('adds data pattern rule item', () => {
        const btn = wrapper.container.querySelector('#addPattern0');
        fireEvent.click(btn);
        expect(wrapper.container.querySelectorAll('.dp.ruleItem')).toHaveLength(1);
        expect(wrapper).toMatchSnapshot();
      });

      it('adds edm dataset rule item', () => {
        const btn = wrapper.container.querySelector('#addDataset0');
        fireEvent.click(btn);
        expect(wrapper.container.querySelectorAll('.edm.ruleItem')).toHaveLength(1);
        expect(wrapper).toMatchSnapshot();
      });

      it('adds group', () => {
        const btn = wrapper.container.querySelector('#addGroup0');
        fireEvent.click(btn);
        expect(wrapper.container.querySelectorAll('.nest')).toHaveLength(1);
        expect(wrapper.container.querySelectorAll('#addPattern0-0')).toHaveLength(1);
        expect(wrapper.container.querySelectorAll('#addDataset0-0')).toHaveLength(1);
        expect(wrapper.container.querySelectorAll('#addGroup0-0')).toHaveLength(1);
        expect(wrapper).toMatchSnapshot();
      });
    });

    describe('save()', () => {
      it('should call API with valid profile', async() => {
        wrapper.setState({
          name: 'test-profile',
          primaryExpTree, secondaryExpTree
        });
        mock.onPost().reply(200, {});
        wrapper.instance().save();

        await flushPromises();
      });

      it('should set nameError', async() => {
        wrapper.setState({
          name: 'test-profile',
          primaryExpTree, secondaryExpTree
        });
        mock.onPost().reply(500, {
          message: 'Duplicate data profile name'
        });
        wrapper.instance().save();

        await flushPromises();
        expect(wrapper.instance().state['nameError']).toBeDefined();
      });

      it('should set correct state if profile is invalid', () => {
        jest.mock('./profileValidatorV2', () => ({
          isProfileValid: jest.fn(() => false)
        }));

        wrapper.instance().save();
        expect(wrapper.state('validatePresence')).toEqual(true);
      });
    });

    describe('componentDidMount()', () => {
      it('leaves default profile data', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper.state('name')).toEqual('');
      });

      it ('matches snapshot', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper).toMatchSnapshot();
      });
    });
  })

  describe('update form', () => {
    beforeEach(() => {
      wrapper = shallow(<DataProfileFormV2 {...updateProps}/>);
    });

    describe('componentDidMount()', () => {
      it('sets profile data', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper.state('name')).toEqual('existing profile');
      });

      it ('matches snapshot', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper).toMatchSnapshot();
      });

    });

    describe('save()', () => {
      it('should call update API with valid profile', async() => {
        wrapper.setState({
          name: 'test-profile',
          primaryExpTree, secondaryExpTree
        });
        mock.onPut().reply(200, {});


        wrapper.instance().save();

        await flushPromises();
      });

      it('should set nameError', async() => {
        wrapper.setState({
          name: 'test-profile',
          primaryExpTree, secondaryExpTree
        });
        mock.onPut().reply(500, {
          message: 'Duplicate data profile name'
        });
        window.scrollTo = jest.fn();
        wrapper.instance().save();

        await flushPromises();
        expect(wrapper.instance().state['nameError']).toBeDefined();
      });

      it('should call update API with valid profile and persist advanced type', async() => {
        mock.reset();
        wrapper = shallow(<DataProfileFormV2 {...updateProps} />);
        wrapper.setState({
          name: 'test-profile',
          primaryExpTree, secondaryExpTree
        });
        mock.onPut().reply(200, {});

        wrapper.instance().save();
        expect(JSON.parse(mock.history.put[0].data)).toEqual(expect.objectContaining({
          dataProfile: expect.objectContaining({
            detection_rules: [
              {
                rule_type: 'expression_tree',
                expression_tree: primaryExpTree
              },
              {
                rule_type: 'expression_tree',
                expression_tree: secondaryExpTree
              }
            ]
          })
        }));
      });

      it('should set correct state if profile is invalid', () => {
        wrapper.setState({name: ''});

        wrapper.instance().save();
        expect(wrapper.state('validatePresence')).toEqual(true);
      });
    });

    it('updateRuleItem should update expression tree', () => {
      const comp = wrapper.instance();
      expect(comp.state['primaryExpTree'].sub_expressions[0].rule_item.confidence_level).toEqual('high');
      comp.updateRuleItem('0-0', {
        id: '1234560',
        occurrence_operator_type: 'any',
        occurrence_count: null,
        confidence_level: 'low',
        name: 'SSN',
        supported_confidence_levels: [
          'high',
          'low'
        ],
        detection_technique: 'regex',
        version: 1
      });
      expect(comp.state['primaryExpTree'].sub_expressions[0].rule_item.confidence_level).toEqual('low');
    });

    it('handleNameChange() should update name', () => {
      const comp = wrapper.instance();
      comp.handleNameChange({
        target: {
          value: 'abc'
        }
      });
      expect(comp.state.name).toEqual('abc');
    });
  });

  describe('update form not secondary rule eligible', () => {
    beforeEach(() => {
      wrapper = shallow(<DataProfileFormV2 {...updatePropsNoSecondary}/>);
    });

    describe('componentDidMount()', () => {
      it('sets profile data', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper.state('name')).toEqual('existing profile');
      });

      it ('matches snapshot', async() => {
        await wrapper.instance().componentDidMount();
        expect(wrapper).toMatchSnapshot();
      });

    });
  });

  describe('update predefined profile', () => {
    it('redirects back', async() => {
      const predefinedProps = {
        dataProfile: predefinedProfile,
        history,
        datasets
      }
      wrapper = shallow(<DataProfileFormV2 {...predefinedProps}/>);
      await wrapper.instance().componentDidMount();
      expect(history.push).toHaveBeenCalled();
    });
  });
});