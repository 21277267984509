// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<DeleteModal /> should match snapshot 1 1`] = `
<Modal
  addClassName="delete-fingerprint-modal"
  isOpen={false}
  onClose={[Function]}
  showMask={true}
  title="Delete Confirmation"
>
  <section
    className="modal-body"
  >
    <p>
      Are you sure you would like to delete
       
      <span
        className="special"
      >
        DeleteModal
      </span>
      ?
    </p>
    <p>
      Document Types part of active Data Profiles cannot be deleted. This action cannot be undone.
    </p>
    <p
      className="error-message"
    />
  </section>
  <section
    className="modal-footer tw-flex tw-justify-end tw-space-x-1"
  >
    <Button
      addClassName="cancel"
      appearance="tertiary"
      buttonType="default"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    >
      Cancel
    </Button>
    <LoadingButton
      appearance="primary-destructive"
      dataMetrics="loading-primary-button"
      dataResult={
        Object {
          "loading": false,
        }
      }
      disabled={false}
      onClickCallback={[Function]}
    >
      Delete Fingerprint
    </LoadingButton>
  </section>
</Modal>
`;

exports[`<DeleteModal /> should match snapshot 2 1`] = `
<Modal
  addClassName="delete-fingerprint-modal"
  isOpen={true}
  onClose={[Function]}
  showMask={true}
  title="Delete Confirmation"
>
  <section
    className="modal-body"
  >
    <p>
      Are you sure you would like to delete
       
      <span
        className="special"
      >
        DeleteModal
      </span>
      ?
    </p>
    <p>
      Document Types part of active Data Profiles cannot be deleted. This action cannot be undone.
    </p>
    <p
      className="error-message"
    />
  </section>
  <section
    className="modal-footer tw-flex tw-justify-end tw-space-x-1"
  >
    <Button
      addClassName="cancel"
      appearance="tertiary"
      buttonType="default"
      disabled={false}
      isMenu={false}
      onClick={[Function]}
      size="md"
    >
      Cancel
    </Button>
    <LoadingButton
      appearance="primary-destructive"
      dataMetrics="loading-primary-button"
      dataResult={
        Object {
          "loading": false,
        }
      }
      disabled={false}
      onClickCallback={[Function]}
    >
      Delete Fingerprint
    </LoadingButton>
  </section>
</Modal>
`;
