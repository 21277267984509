import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { CircleCheckIcon } from '@panwds/icons';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`settings.block_response.${scope}`, options);
}


const FileUpload = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const {fileSelected} = props;

  const onFileChange = event => {    
    // Update the state
    setSelectedFile(event.target.files[0]);  
    fileSelected(event.target.files[0]);
  };

  return (
    <div className='block-page-upload'>        
        <div className='helpText'>{t('edit_page_instructions')}</div>  
        <div className="form-section">
            <span className="file-info">{selectedFile && selectedFile.name}</span>
            <label className="file-input-label" htmlFor="block-page-file-input">{t('choose_file')}</label>
            <input id="block-page-file-input" type="file" onChange={onFileChange} accept="text/html"/>            
        </div>      
        <div className="status-section">
          { selectedFile && 
            <CircleCheckIcon className="file-status-icon"/> }                   
          <span className="helpText">{(selectedFile && selectedFile.name) || t('no_file_chosen')}</span>
        </div>
    </div>
  );
};

FileUpload.propTypes = {
  fileSelected: PropTypes.func.isRequired
}

export default FileUpload;