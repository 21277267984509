import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Tooltip } from '@panwds/react-ui';
import $ from 'jquery';
import { FaTimes } from 'react-icons/fa';
import { IntlProvider } from 'react-intl';

import translate from '../../../helpers/translate';
import BasicDropdown from './BasicDropdown';
import { isProfileValid } from './profileValidatorV2';
import { returnToIndex, saveProfile, updateProfile } from './profileActions';
import matchTypes from './matchTypes';
import PreviewToggle from './PreviewToggle';
import { formatRequest } from './requestFormatter';
import { profileDetails } from '../profileDataTransformer';
import toastr from '../../../helpers/toastr';
import DataProfileDefinition from '../DataProfileDefinition';
import DataProfileDetailPreview from '../DataProfileDetailPreview';
import DataPatternRuleItem from './DataPatternRuleItem';
import RuleItemDocumentType from './RuleItemDocumentType';
import EdmDatasetRuleItem from './EdmDatasetRuleItem';
import BuilderIcon from '../../../../assets/images/builder.svg';
import BreadcrumbHeader from './BreadcrumbHeader';
import ProfileName from './ProfileName';
import SingleSelect from '../../SingleSelect';

import {
  RULE_TYPE_DATA_PATTERN,
  RULE_TYPE_DOCUMENT_TYPES,
  RULE_TYPE_EDM_DATASET,
  RULE_TYPE_GROUP,
  RULE_TECHNIQUE_DATA_PATTERN_REGEX,
  RULE_TECHNIQUE_DATA_PATTERN_ML,
  RULE_TECHNIQUE_DOCUMENT_TYPE,
  RULE_TECHNIQUE_EDM_DATASET
} from '../../constants';

function t(scope, options) {
  return translate(`data_profiles.form.${scope}`, options);
}

const NOT_OPERATORS = [{value: 'not', label: 'NOT'}];

class DataProfileFormV2 extends React.Component {
  state = {
    isEdmEnabled: false,
    datasets: Object.values(this.props.datasets).filter((dataset) => dataset.active_fields && dataset.active_fields.length > 0),
    validatePresence: false,
    nameError: undefined,
    name: '',
    previewType: 'detail',
    clone: window.location.pathname.includes('clone'),
    primaryExpTree: {
      operator_type: 'and',
      rule_item: null,
      sub_expressions: []
    },
    secondaryExpTree: {
      operator_type: 'and',
      rule_item: null,
      sub_expressions: []
    }
   };

   dataProfile;

   secondaryRuleEligible;

  componentDidMount() {
    const {
      enableDocumentTypes,
      lookupDocumentsApi,
      lookupEdmStatusApi,
      apiPostfix
    } = this.props;
    const originalProfile = this.props.dataProfile;
    const {clone} = this.state;

    axios.get(`${lookupEdmStatusApi}${apiPostfix}`)
      .then(response => {
        this.setState({
          isEdmEnabled: response?.data?.edm_enablement === 'enabled'
        });
      })
      .catch((error) => {
        console.error(error);
      });

    if (enableDocumentTypes && lookupDocumentsApi) {
      axios.get(`${lookupDocumentsApi}${apiPostfix}`)
        .then(response => {
          const documents = response?.data || [];

          this.setState({
            documentsList: documents
              .filter(doc => doc.status === 'completed')
              .map((doc) => ({
                id: doc?.id,
                name: doc?.name
              }))
          });
        });
    }

    if (originalProfile && originalProfile.profile_type === 'predefined' && !clone) {
      this.props.history.push('..');
      toastr.error(translate('data_profiles.alerts.unable_to_edit'));
      return;
    }

    if (originalProfile) {
      const formattedProfile = profileDetails(originalProfile, this.props.dataPatterns, this.props.datasets, clone);

      this.dataProfile = formattedProfile;
      this.secondaryRuleEligible = this.props.dataProfileId && !this.state.clone ? this.dataProfile && this.dataProfile.secondary_rule_eligible : true;
      this.setState({
        name: formattedProfile.name,
        primaryExpTree: formattedProfile.primaryExpTree,
        secondaryExpTree: formattedProfile.secondaryExpTree
      });
    } else {
      this.secondaryRuleEligible = true;
    }
  }

  handleNameChange = (event) => {
    this.setState({
      name: event.target.value
    });
  };

  save = async() => {
    $('button:contains("Save")').prop('disabled', true);
    const params = {
      name: this.state.name,
      primaryExpTree: this.state.primaryExpTree,
      secondaryExpTree: this.state.secondaryExpTree,
      schema_version: 2,
      create: this.state.clone || !this.dataProfile,
      advanced: true
    }
    const valid = isProfileValid(params);
    if (valid) {
      const dataProfile = formatRequest(params);
      let error = '';
      if (!this.state.clone && (this.props.dataProfile || this.props.dataProfileId)) {
        error = await updateProfile(dataProfile, this.props);
      }
      else {
        error = await saveProfile(dataProfile, this.props);
      }
      if (error) {
        this.setState({ nameError: error });
      }
    }
    else {
      $('button:contains("Save")').prop('disabled', false);
      this.setState({ validatePresence: true });
    }
  }

  handleChange = (newState) => {
    this.setState(newState);
  }

  treeAtPosition = (position) => {
    const indices = position.split('-');
    let tree = indices[0] === '0' ? this.state.primaryExpTree : this.state.secondaryExpTree;
    for (let i=1; i<indices.length; i+=1) {
      tree = tree.sub_expressions[parseInt(indices[i], 10)];
    }
    return tree;
  }

  handleAddRuleItem = (ruleType, position) => {
    switch (ruleType) {
      case RULE_TYPE_DATA_PATTERN:
        this.addPatternRule(position);
        break;

      case RULE_TYPE_DOCUMENT_TYPES:
        this.addDocumentTypeRule(position);
        break;

      case RULE_TYPE_EDM_DATASET:
        this.addDataset(position);
        break;

      case RULE_TYPE_GROUP:
      default:
        this.addGroup(position);
        break;
    }
  };

  createDropdownItems = () => {
    if (this.state.isEdmEnabled && this.props.enableDocumentTypes) {
      return [
        { value: RULE_TYPE_DATA_PATTERN },
        { value: RULE_TYPE_DOCUMENT_TYPES },
        { value: RULE_TYPE_EDM_DATASET }
      ];
    }

    if (this.state.isEdmEnabled) {
      return [
        { value: RULE_TYPE_DATA_PATTERN },
        { value: RULE_TYPE_EDM_DATASET }
      ];
    }

    if (this.props.enableDocumentTypes) {
      return [
        { value: RULE_TYPE_DATA_PATTERN },
        { value: RULE_TYPE_DOCUMENT_TYPES },
      ];
    }

    return [
      { value: RULE_TYPE_DATA_PATTERN }
    ];
  };

  addButtons = (position) => {
    const tree = this.treeAtPosition(position);
    const operator = tree.operator_type;
    const emptyGroupError = this.state.validatePresence && tree.sub_expressions.length === 0 && (position[0] === '0' || position.split('-').length > 1);
    const disabled = position[0] === '1' && this.secondaryRuleEligible === false;
    const items = this.createDropdownItems();

    if (position.split('-').length <= 3) {
      items.push({ value: RULE_TYPE_GROUP })
    }

    return (
      <div className={`btnGroup ${emptyGroupError ? 'has-error' : ''}`}>
        <BasicDropdown
          onSelect={newMatchType => this.updateOperator(position, newMatchType)}
          value={operator}
          items={position.split('-').length > 1 ? matchTypes.concat(NOT_OPERATORS) : matchTypes}
          disabled={disabled}
        />

        { operator === 'not' && tree.sub_expressions.length > 0 ?
          <span key='addBtns' className='tw-float-right ruleBtns dlp-flex-center'>
            <Tooltip label={t('tooltip_not_operator_single_addition')} hasTriangle>
              <Button isSplit disabled append appearance="secondary" isMenu>Add</Button>
            </Tooltip>

            { position.split('-').length > 1 &&
              <FaTimes onClick={() => this.removeGroup(position)} className='muteText removeBtn ml5' />
            }
          </span>
          :
          <span key='addBtns' className='tw-float-right ruleBtns'>
            <div style={{display: 'inline-block'}}>
              <SingleSelect
                button={<Button append appearance="secondary" isMenu>Add</Button>}
                items={items}
                onChange={(selection) => this.handleAddRuleItem(selection.selectedItem.value, position)}
                selectedItem={[]}
              />
            </div>
            { position.split('-').length > 1 &&
              <FaTimes onClick={() => this.removeGroup(position)} className='muteText removeBtn ml5' />
            }
          </span>
        }

        {emptyGroupError &&
          <span className='error ml10'>Group cannot be empty</span>
        }
      </div>
    )
  }

  addItemAtPosition = (ruleItem, position) => {
    const item = {
      operator_type: null,
      rule_item: ruleItem,
      sub_expressions: []
    };
    const indices = position.split('-');
    let tree = indices[0] === '0' ? this.state.primaryExpTree : this.state.secondaryExpTree;

    for (let i=1; i<indices.length; i+=1) {
      tree = tree.sub_expressions[parseInt(indices[i], 10)];
    }

    tree.sub_expressions.push(item);
    this.updateState(indices[0]);
  }

  addPatternRule = (position) => {
    const patternRuleItem = {
      id: null,
      occurrence_operator_type: 'any',
      occurrence_count: null,
      confidence_level: 'high',
      by_unique_count: false
    }
    this.addItemAtPosition(patternRuleItem, position);
  }

  addDocumentTypeRule = (position) => {
    const patternRuleItem = {
      id: null,
      occurrence_operator_type: 'more_than_equal_to',
      occurrence_count: 50,
      confidence_level: 'high',
      name: null,
      supported_confidence_levels: [
        'high',
        'low'
      ],
      detection_technique: RULE_TECHNIQUE_DOCUMENT_TYPE,
      version: 1
    };

    this.addItemAtPosition(patternRuleItem, position);
  };

  addDataset = (position) => {
    const datasetRuleItem = {
      edm_dataset_id: '',
      occurrence_operator_type: 'any',
      occurrence_count: null,
      occurrence_low: null,
      occurrence_high: null,
      primary_fields: [],
      primary_match_criteria: 'all',
      primary_match_any_count: null,
      secondary_fields: [],
      secondary_match_criteria: 'all',
      secondary_match_any_count: null,
      detection_technique: RULE_TECHNIQUE_EDM_DATASET
    }
    this.addItemAtPosition(datasetRuleItem, position);
  }

  addGroup = (position) => {
    const groupItem = {
      operator_type: 'and',
      rule_item: null,
      sub_expressions: []
    }
    const indices = position.split('-');
    let tree = indices[0] === '0' ? this.state.primaryExpTree : this.state.secondaryExpTree;
    for (let i=1; i<indices.length; i+=1) {
      tree = tree.sub_expressions[parseInt(indices[i], 10)];
    }
    tree.sub_expressions.push(groupItem);
    this.updateState(indices[0]);
  }

  removeGroup = (position) => {
    const indices = position.split('-');
    let tree = indices[0] === '0' ? this.state.primaryExpTree : this.state.secondaryExpTree;
    for (let i=1; i<indices.length; i+=1) {
      if (i === indices.length - 1) {
        tree.sub_expressions.splice(parseInt(indices[i], 10), 1);
      }
      else {
        tree = tree.sub_expressions[parseInt(indices[i], 10)];
      }
    }
    this.updateState(indices[0]);
  }

  updateState = (rulePos) => {
    if (rulePos === '0') {
      this.setState(prevState => ({
        primaryExpTree: prevState.primaryExpTree
      }))
    }
    else {
      this.setState(prevState => ({
        secondaryExpTree: prevState.secondaryExpTree
      }))
    }
  }

  updateRuleItem = (position, rule) => {
    const indices = position.split('-');
    let tree = indices[0] === '0' ? this.state.primaryExpTree : this.state.secondaryExpTree;

    for (let i=1; i<indices.length; i+=1) {
      tree = tree.sub_expressions[parseInt(indices[i], 10)];
    }
    tree.rule_item = rule;
    this.updateState(indices[0]);
  }

  updateOperator = (position, operator) => {
    const indices = position.split('-');
    let tree = indices[0] === '0' ? this.state.primaryExpTree : this.state.secondaryExpTree;
    for (let i=1; i<indices.length; i+=1) {
      tree = tree.sub_expressions[parseInt(indices[i], 10)];
    }
    tree.operator_type = operator;
    if (operator === 'not' && tree.sub_expressions.length > 1) {
      tree.sub_expressions = [tree.sub_expressions[0]];
    }
    this.updateState(indices[0]);
  }

  deleteRuleItem = (position) => {
    const indices = position.split('-');
    let tree = indices[0] === '0' ? this.state.primaryExpTree : this.state.secondaryExpTree;
    for (let i=1; i<indices.length; i+=1) {
      if (i === indices.length - 1) {
        tree.sub_expressions.splice(parseInt(indices[i], 10), 1);
      }
      else {
        tree = tree.sub_expressions[parseInt(indices[i], 10)];
      }
    }
    this.updateState(indices[0]);
  }

  generateSubs = (subExpressions, position) => {
    const group = [];
    const {dataPatterns} = this.props;

    subExpressions.forEach((subExp, index) => {
      const subExpPos = `${position}-${index}`;

      if (subExp.rule_item !== null) {
        const ruleItem = subExp.rule_item;

        switch (subExp.rule_item.detection_technique) {
          case RULE_TECHNIQUE_DOCUMENT_TYPE: {
            group.push(
              <div className='document-types ruleItem' key={`edm-${ruleItem?.name}-${subExpPos}`}>
                <RuleItemDocumentType
                  documentsList={this.state.documentsList}
                  onDeleteRuleItem={this.deleteRuleItem}
                  onUpdateRuleItem={this.updateRuleItem}
                  position={subExpPos}
                  ruleItem={ruleItem}
                  shouldValidatePresence={this.state.validatePresence}
                />
              </div>
            );
            break;
          }

          case RULE_TECHNIQUE_EDM_DATASET: {
            group.push(
              <div className='edm ruleItem' key={`edm-${ruleItem?.name}-${subExpPos}`}>
                <EdmDatasetRuleItem
                  datasets={this.state.datasets}
                  def={ruleItem}
                  position={subExpPos}
                  updateRuleItem={this.updateRuleItem}
                  deleteRuleItem={this.deleteRuleItem}
                  validatePresence={this.state.validatePresence}
                />
              </div>
            );
            break;
          }

          case RULE_TECHNIQUE_DATA_PATTERN_REGEX:
          case RULE_TECHNIQUE_DATA_PATTERN_ML:
          default: {
            group.push(
              <div className='dp ruleItem' key={`dp-${ruleItem && ruleItem.id ? dataPatterns[ruleItem.id]?.name : 'undefined'}-${subExpPos}`}>
                <DataPatternRuleItem
                  dataPatterns={dataPatterns}
                  dp={ruleItem}
                  position={subExpPos}
                  updateRuleItem={this.updateRuleItem}
                  deleteRuleItem={this.deleteRuleItem}
                  validatePresence={this.state.validatePresence}
                />
              </div>
            );
            break;
          }
        }
      }
      else {
        group.push(this.generateSubs(subExp.sub_expressions, subExpPos));
      }
    });

    return (
      <div key={position} className={position.split('-').length === 1 ? 'headGroup' : 'nest'}>
        {this.addButtons(position)}
        <div className='conditionGroup'>
          {group}
        </div>
      </div>
    )
  }

  profileDataPresent = () => {
    return this.state.primaryExpTree.rule_item || this.state.primaryExpTree.sub_expressions.length > 0 ||
      this.state.secondaryExpTree.rule_item || this.state.secondaryExpTree.sub_expressions.length > 0
  }

  render () {
    const canSave = this.props.dataProfileId? this.dataProfile && this.dataProfile.profile_type.toLowerCase() !== 'predefined' : true;

    return (
      <IntlProvider locale='en'>
      <div>
        <BreadcrumbHeader dataProfile={!!this.dataProfile} clone={this.state.clone} />

        <div className='formArea'>
          <div className='dataProfileForm'>
            <div className='tw-float-right form-section preview'>
              <h4 className="mt-2">
                {t('preview')}
                <span className='tw-float-right previewToggle'>
                  <PreviewToggle previewType={this.state.previewType} updateState={this.handleChange} />
                </span>
              </h4>
              {this.state.previewType === 'detail' && this.profileDataPresent() &&
                <DataProfileDetailPreview primaryExpTree={this.state.primaryExpTree} secondaryExpTree={this.state.secondaryExpTree} datasets={this.state.datasets} schema={2} />
              }
              {this.state.previewType === 'bracket' && this.profileDataPresent() &&
                <DataProfileDefinition primaryExpTree={this.state.primaryExpTree} secondaryExpTree={this.state.secondaryExpTree} schema={this.dataProfile?.schema_version} datasets={this.state.datasets} />
              }
              {!this.profileDataPresent() &&
                <div id='nothingToPreview'>
                  <img src={BuilderIcon} alt='Preview' className='text-center' />
                  <p className='text-center'>
                    <b>{t('no_definition')}</b>
                    <br />
                    <span className='helpText'>{t('add_a_rule')}</span>
                  </p>
                </div>
              }
            </div>

            <ProfileName validatePresence={this.state.validatePresence} name={this.state.name} handleNameChange={this.handleNameChange} nameError={this.state.nameError} />

            <div className='form-section'>
              <h4>{t('primary_rule')}</h4>
              <span className='muteText' style={{lineHeight: '20px'}}>{t('primary_rule_desc')}</span>
              <hr />
              <>
                {this.generateSubs(this.state.primaryExpTree.sub_expressions, '0')}
              </>
            </div>

            <div className='form-section'>
              <h4>{t('secondary_rule')}</h4>
              <span className='muteText' style={{lineHeight: '20px'}}>{t('secondary_rule_desc')}</span>
              <hr />
              <>
                {this.secondaryRuleEligible && this.generateSubs(this.state.secondaryExpTree.sub_expressions, '1')}
                {this.secondaryRuleEligible === false &&
                  <Tooltip label={t('secondary_rule_ineligible')} appearance='dark' hasTriangle>
                    {this.generateSubs(this.state.secondaryExpTree.sub_expressions, '1')}
                  </Tooltip>
                }
              </>
            </div>
          </div>
        </div>
        <div className='formFooter'>
          <div className='tw-float-right submitActions tw-flex tw-justify-end tw-space-x-1'>
            <Button onClick={() => returnToIndex(this.props.history, this.props.dataProfileId)} >
              {translate('actions.cancel')}
            </Button>
            <Button disabled={!canSave} appearance='primary' onClick={ () => this.save(false) } >
              {translate('actions.save')}
            </Button>
          </div>
        </div>
      </div>
      </IntlProvider>
    );
  }
}

DataProfileFormV2.propTypes = {
  enableDocumentTypes: PropTypes.bool,
  lookupDocumentsApi: PropTypes.string,
  lookupEdmStatusApi: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  createURL: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  updateURL: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  apiPostfix: PropTypes.string,
  dataProfile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    profile_type: PropTypes.string,
    detection_rules: PropTypes.array,
    schema_version: PropTypes.number,
  }),
  dataProfileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  datasets: PropTypes.shape({}),
  dataPatterns: PropTypes.shape({}),
  // history will be provided by react-router withRouter HOC
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

DataProfileFormV2.defaultProps = {
  enableDocumentTypes: false,
  lookupDocumentsApi: '/v1/dlp-ui/document-types',
  lookupEdmStatusApi: '/v1/dlp-ui/edm-dataset/status',
  dataProfileId: undefined,
  createURL: '/data_profiles',
  updateURL: '/data_profiles',
  apiPostfix: '.json',
  dataProfile: undefined,
  datasets: {},
  dataPatterns: {},
  history: undefined
};

export default DataProfileFormV2;