// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<ServiceStatusTable> renders without a fetchUrl 1`] = `
<Fragment>
  <div
    className="title card-title"
  >
    dashboards.index.table_title
  </div>
  <div
    className="body"
  >
    <div
      className="empty"
    >
      <img
        alt="loading"
        id="loader"
      />
      <br />
      <br />
      <p>
        dashboards.index.loading
      </p>
    </div>
  </div>
</Fragment>
`;

exports[`<ServiceStatusTable> with a lookupURL with a success response for casb portal renders 1`] = `
<Fragment>
  <div
    className="title card-title"
  >
    dashboards.index.table_title
  </div>
  <div
    className="body"
  >
    <div
      className="empty"
    >
      <img
        alt="loading"
        id="loader"
      />
      <br />
      <br />
      <p>
        dashboards.index.loading
      </p>
    </div>
  </div>
</Fragment>
`;

exports[`<ServiceStatusTable> with a lookupURL with an error response renders 1`] = `
<Fragment>
  <div
    className="title card-title"
  >
    dashboards.index.table_title
  </div>
  <div
    className="body"
  >
    <div
      className="empty"
    >
      <img
        alt="loading"
        id="loader"
      />
      <br />
      <br />
      <p>
        dashboards.index.loading
      </p>
    </div>
  </div>
</Fragment>
`;
