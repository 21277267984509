import translate from '../../../helpers/translate';
import {
  PAST_24_HOURS,
  PAST_7_DAYS,
  PAST_30_DAYS,
  PAST_90_DAYS,
  PAST_ALL
} from '../../constants';

function localizeString(scope, options) {
  return translate(`document_types.misc.${scope}`, options);
}

export const dateRangeOptions = [{
  value: PAST_24_HOURS,
  label: localizeString('select_day'),
}, {
  value: PAST_7_DAYS,
  label: localizeString('select_week'),
}, {
  value: PAST_30_DAYS,
  label: localizeString('select_month'),
}, {
  value: PAST_90_DAYS,
  label: localizeString('select_three_months'),
}, {
  value: PAST_ALL,
  label: localizeString('select_all'),
}];

export const documentCategories = [{
  value: localizeString('category_academic'),
}, {
  value: localizeString('category_confidential'),
}, {
  value: localizeString('category_employment'),
}, {
  value: localizeString('category_financial'),
}, {
  value: localizeString('category_government'),
}, {
  value: localizeString('category_legal'),
}, {
  value: localizeString('category_marketing'),
}, {
  value: localizeString('category_source_code'),
}];