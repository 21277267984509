const months = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
}

export default (date) => {
  const m = date.split('-')[1];
  const mNum = m in months ? months[m] : m;
  return date.replace(' UTC', 'Z').replace(' ', 'T').replace(m, mNum);
}