import React, {useState} from 'react';
import { Button } from '@panwds/react-ui';
import translate from '../../helpers/translate';

function t(scope, options) {
  return translate(`incidents.index.${scope}`, options);
}

const StatusForm = ({status, closePopup, saveStatus}) => {
  const [tagName, setTagName] = useState(status.resolutionStatus);

  const saveClicked = async() => {
    status.resolutionStatus = tagName
    const success = await saveStatus(status);
    if(success) {
      closePopup();
    }      
  }

  const tagNameChanged = (event) => {
    setTagName(event.target.value);
  }

  return (
   <div className='statusForm'> 
    <div className='statusFormInner tw-p-2'>
      <input className='resolutionTag form-control' value={tagName} onChange={tagNameChanged}/>      
    </div>

    <div className='actionsPane'>     
      <Button appearance='clear' addClassName='save' onClick={saveClicked}>
        {translate('actions.save')}
      </Button>
    </div>
  </div>
  );
};

export default StatusForm;