import React from 'react';
import {within, fireEvent} from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import renderWithRouter from '../../helpers/setupTests';

import FilteringProfileDefinition from './FilteringProfileDefinition';

const mockAdapter = new MockAdapter(axios);
const profile =
  {
    id: 1,
    name: 'Test profile 1',
    rule1: {
      action: 'alert'
    },
    file_type: ['any']
  };
mockAdapter.onGet().reply(200, profile);
mockAdapter.onPut().reply(200, profile);

test('renders the title', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDefinition lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1}/>);
  expect(getByText('Edit Data Filtering Profile')).toBeInTheDocument();
});

test('changing action works', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDefinition lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1}/>);
  const actionDropdown = container.querySelector('.definition-section.action-def .dd-menu');
  const actionButton = actionDropdown.querySelector('button.action-dropdown');
  fireEvent.click(actionButton);
  const blockAction = getByText('Block');
  fireEvent.click(blockAction);
  expect(within(actionButton).getByText('Block')).toBeInTheDocument();
});

test('changing file type works', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDefinition lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1}/>);
  const selectRadio = container.querySelector('input#file-type-select');
  fireEvent.click(selectRadio);
  const multiselect = container.querySelector('div#fileTypeSelect')
  fireEvent.click(multiselect.querySelector('.search-wrapper'));
  expect(within(multiselect).getByText('asm')).toBeInTheDocument();
  const fileType = multiselect.querySelector('.optionListContainer li input');
  fireEvent.click(fileType);
  expect(multiselect.querySelector('span.chip')).toBeInTheDocument();
});

test('changing severity works', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDefinition lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1}/>);
  const severityDropdown = getByText('Log Severity').nextSibling;
  const dropdownButton = severityDropdown.querySelector('button.severity-dropdown');
  fireEvent.click(dropdownButton);
  const high = getByText('High');
  fireEvent.click(high);
  expect(within(dropdownButton).getByText('High')).toBeInTheDocument();
});

test('clicking save should call API', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDefinition lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1}/>);
  const descInput = container.querySelector('textarea.desc-input');
  fireEvent.focus(descInput);
  fireEvent.input(descInput, { target: { value: 'Test description' } });
  const saveButton = container.querySelector('button.save');
  fireEvent.click(saveButton);
  expect(JSON.parse(mockAdapter.history.put[0].data)).toEqual(expect.objectContaining({
    description: 'Test description'
  }));
});

test('readonly Direction when panos version not supported', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDefinition
    lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1} panosVersion={'10.2.3'}
  />);

  const directionDropdown = container.querySelector('button.direction-dropdown');
  expect(directionDropdown.disabled).toEqual(true);
});

test('NOT readonly Direction when panos version supported', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDefinition
    lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1} panosVersion={'10.2.4'}
  />);

  const directionDropdown = container.querySelector('button.direction-dropdown');
  expect(directionDropdown.disabled).toEqual(false);
});

test('changing direction works', () => {
  const {container, getByText} = renderWithRouter(<FilteringProfileDefinition
    lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1} panosVersion={'10.2.4'}
  />);
  const severityDropdown = getByText('Direction').nextSibling;
  const dropdownButton = severityDropdown.querySelector('button.direction-dropdown');
  fireEvent.click(dropdownButton);
  const both = getByText('Both');
  fireEvent.click(both);
  expect(within(dropdownButton).getByText('Both')).toBeInTheDocument();
});

test('changing File/Non-File Settings and Save gets disabled', () => {
  const {getByText} = renderWithRouter(<FilteringProfileDefinition
    lookupURL={'/dlp_rules'} userRole={'READ_WRITE'} profileId={1} panosVersion={'10.2.4'} nebulaFlag
  />);

  const fileSettingsToggle = getByText('File Based Match Criteria').nextSibling;
  const nonFileSettingsToggle = getByText('Non-File Based Match Criteria').nextSibling;

  fireEvent.click(fileSettingsToggle.children[1]);
  fireEvent.click(fileSettingsToggle.children[1]);
  fireEvent.click(nonFileSettingsToggle.children[1]);
  fireEvent.click(nonFileSettingsToggle.children[1]);

  expect(getByText(/Save/i).closest('button')).toBeDisabled();
});