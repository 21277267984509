import React from 'react';

import {render} from '@testing-library/react';
import '@testing-library/jest-dom';
import TokenModals from './TokenModals';

describe('<TokenModals>', () => {
  let testUtils;

  it('render create modal body', () => {
    testUtils = render(<TokenModals type='createModal' area='body' /> );
    expect(testUtils.getByText('Token Name')).toBeInTheDocument();
  });

  it('render create modal footer', () => {
    testUtils = render(<TokenModals type='createModal' area='footer' /> );
    expect(testUtils.getByText('Create')).toBeInTheDocument();
  });

  it('render error modal body', () => {
    testUtils = render(<TokenModals type='errorModal' area='body' errorMessage='name already taken'/> );
    expect(testUtils.getByText('name already taken')).toBeInTheDocument();
  });

  it('render error modal footer', () => {
    testUtils = render(<TokenModals type='errorModal' area='footer' /> );
    expect(testUtils.getByText('Retry')).toBeInTheDocument();
  });

  it('render create modal output body', () => {
    testUtils = render(<TokenModals type='createModalOutput' area='body' accessToken='abc123' refreshToken='def456' /> );
    expect(testUtils.getByText('Access Token')).toBeInTheDocument();
    expect(testUtils.getByText('Refresh Token')).toBeInTheDocument();
  });

  it('render create modal output footer', () => {
    testUtils = render(<TokenModals type='createModalOutput' area='footer' closeModal={() => {}} /> );
    expect(testUtils.getByText('Close')).toBeInTheDocument();
  });

  it('render renewRevoke modal body', () => {
    testUtils = render(<TokenModals type='renewRevokeModal' area='body' status='renew' /> );
    expect(testUtils.getByText('This will invalidate old tokens. Are you sure you want to renew?')).toBeInTheDocument();
  });

  it('render renewRevoke modal footer', () => {
    testUtils = render(<TokenModals type='renewRevokeModal' area='footer' status='renew' /> );
    expect(testUtils.getByText('Renew')).toBeInTheDocument();
  });

  it('render confirm modal body', () => {
    testUtils = render(<TokenModals type='confirmModal' area='body' /> );
    expect(testUtils.getByText('For security reasons, it is highly recommended to store these tokens in a safe manner, and never share your API tokens with anyone.')).toBeInTheDocument();
  });

  it('render confirm modal footer', () => {
    testUtils = render(<TokenModals type='confirmModal' area='footer' /> );
    expect(testUtils.getByText('Continue')).toBeInTheDocument();
  });
});
