// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<Nav> hides menu items based on props 1`] = `
<BrowserRouter>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <div
      className="dlp-nav-wrapper"
    >
      <Navigation
        clickToExpand={true}
        hasCollapseController={true}
        isTwoLines={true}
        logoUrl="/"
        navRef={
          Object {
            "current": null,
          }
        }
      >
        <TopNav />
        <BottomNav>
          <div
            onClick={[Function]}
          >
            <NavItem
              icon={
                <ThAppSwitcherIcon
                  size="sm"
                />
              }
              id="nav-hub"
              title="left_nav.hub"
            />
          </div>
        </BottomNav>
      </Navigation>
      <div
        className="dlp-nav-routing"
      >
        <Switch>
          <Route
            exact={true}
            path="/"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/logout"
            render={[Function]}
          />
        </Switch>
      </div>
    </div>
  </IntlProvider>
</BrowserRouter>
`;

exports[`<Nav> renders 1`] = `
<BrowserRouter>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <div
      className="dlp-nav-wrapper"
    >
      <Navigation
        clickToExpand={true}
        hasCollapseController={true}
        isTwoLines={true}
        logoUrl="/"
        navRef={
          Object {
            "current": null,
          }
        }
      >
        <TopNav>
          <NavItem
            icon={
              <IncidentsIcon
                size="sm"
              />
            }
            id="nav-incidents"
            isSimpleNav={true}
            title="left_nav.incidents"
            url="/incidents"
          />
          <NavItem
            icon={<FaClipboardList />}
            id="nav-data-profiles"
            isSimpleNav={true}
            title="left_nav.data_profiles"
            url="/data_profiles"
          />
          <SubNavList
            defaultOpen={false}
            icon={<DetectionMethodsIcon />}
            id="nav-detection-methods"
            isSimpleNav={true}
            title="left_nav.detection_methods"
            url="/detection_methods"
          >
            <SubNavListItem
              dataTestId="DLP-nav-data_patterns"
              isSimpleNav={true}
              title="left_nav.data_patterns"
              url="/detection_methods/data_patterns"
            />
            <SubNavListItem
              dataTestId="DLP-nav-edm_datasets"
              isSimpleNav={true}
              title="left_nav.edm_datasets"
              url="/detection_methods/edm_datasets"
            />
            <SubNavListItem
              dataTestId="DLP-nav-ocr"
              isSimpleNav={true}
              title="left_nav.ocr"
              url="/detection_methods/ocr"
            />
          </SubNavList>
          <NavItem
            icon={
              <FileCertificateIcon
                size="sm"
              />
            }
            id="nav-document-types"
            isSimpleNav={true}
            title="left_nav.document_types"
            url="/document_types"
          />
          <NavItem
            icon={<FaHeartbeat />}
            id="nav-dashboards"
            isSimpleNav={true}
            title="left_nav.dashboards"
            url="/dashboards"
          />
          <NavItem
            icon={
              <ReportsIcon
                size="sm"
              />
            }
            id="nav-reports"
            isSimpleNav={true}
            title="left_nav.reports"
            url="/reports"
          />
          <NavItem
            icon={
              <AuditLogsIcon
                size="sm"
              />
            }
            id="nav-audit_logs"
            isSimpleNav={true}
            title="left_nav.audit_logs"
            url="/audit_logs"
          />
          <NavItem
            icon={
              <SettingsIcon
                size="sm"
              />
            }
            id="nav-settings"
            isSimpleNav={true}
            title="left_nav.settings"
            url="/settings"
          />
        </TopNav>
        <BottomNav>
          <div
            className="nav-tenant-id-wrapper"
          >
            <div
              className="nav-tenant-id"
            >
              Tenant ID: 
              1234
            </div>
          </div>
          <div
            className="nav-sign-out-wrapper"
          >
            <div
              className="custom-sign-out"
              onClick={[Function]}
            >
              <ProfileNavigationIcon
                size="sm"
              />
              <div
                className="custom-sign-out-name"
              >
                Test User
              </div>
              <div
                className="custom-sign-out-pipe"
              >
                |
              </div>
              <div
                className="custom-sign-out-label"
              >
                left_nav.sign_out
              </div>
            </div>
            <NavItem
              displayActiveBorder={true}
              icon={
                <ProfileNavigationIcon
                  size="sm"
                />
              }
              id="nav-sign_out"
              title="left_nav.sign_out"
              url="/logout"
            />
          </div>
          <div
            onClick={[Function]}
          >
            <NavItem
              icon={
                <ThAppSwitcherIcon
                  size="sm"
                />
              }
              id="nav-hub"
              title="left_nav.hub"
            />
          </div>
        </BottomNav>
      </Navigation>
      <div
        className="dlp-nav-routing"
      >
        <Switch>
          <Route
            exact={true}
            path="/"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/incidents"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/data_profiles"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/detection_methods/data_patterns"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/detection_methods/edm_datasets"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/detection_methods/ocr"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/document_types"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/dashboards"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/reports"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/audit_logs"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/settings"
            render={[Function]}
          />
          <Route
            exact={true}
            path="/logout"
            render={[Function]}
          />
        </Switch>
      </div>
    </div>
  </IntlProvider>
</BrowserRouter>
`;
