// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<IdentifierDropdown/> matches snapshot 1`] = `
<StateManager
  className="identifier-dropdown false"
  defaultInputValue=""
  defaultMenuIsOpen={false}
  defaultValue={
    Object {
      "label": "test",
      "name": "test",
      "value": undefined,
    }
  }
  isSearchable={true}
  onChange={[MockFunction]}
  options={Array []}
  placeholder="data_profiles.form.dataset_form.identifier_placeholder"
  styles={
    Object {
      "control": [Function],
      "indicatorSeparator": [Function],
      "menu": [Function],
      "menuPlacer": [Function],
      "menuPortal": [Function],
      "option": [Function],
      "placeholder": [Function],
    }
  }
/>
`;
