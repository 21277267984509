import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import translate from '../../helpers/translate';
import isValidName from "../../helpers/nameValidator";

function t(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}
const DataPatternMetaData = ({name, duplicateNameError, allowNameChange, nameChanged, description, descriptionChanged, doValidation}) => {
  const [nameError, setNameError] = useState(false);

  const validateName = (value) => {
    return !!value && isValidName(value);
  }

  const handleNameChange = (event) => {
    const newName = event.target.value;
    if (doValidation) {
      setNameError(validateName(newName));
    }
    nameChanged(event);
  }

  useEffect(() => {
    if (doValidation && !validateName(name)) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }, [doValidation]);

  const hasError = nameError || duplicateNameError;
  return (
    <div className='definition-section meta-data'>            
      <div className='data-pattern-form'>
        <div className={`form-label mb-1 ${hasError && 'error'}`}>
          {t('name')}
        </div>
        <input maxLength="64" 
          disabled={!allowNameChange}
          className={`form-control data-pattern-name ${hasError && 'has-error'}`} 
          onChange={handleNameChange}
          value={name}/>
        {
          duplicateNameError &&
          <div className="helpText errorDetail error">{t('duplicate_name_error')}</div>
        }
        {
          !duplicateNameError &&
          <div className={`helpText ${nameError && 'errorDetail error'}`}>{t('name_hint')}</div> 
        }         

        <div className='form-label mt-3 mb-1'>{t('description')} {t('optional')}</div>
        <textarea className='desc-input form-control' 
          value={description || ''} 
          onChange={descriptionChanged}
          placeholder={t('description_hint')}/>
      </div>
    </div>
  )
};

DataPatternMetaData.propTypes = {
  name: PropTypes.string.isRequired, 
  allowNameChange: PropTypes.bool.isRequired, 
  nameChanged: PropTypes.func.isRequired, 
  description: PropTypes.string.isRequired, 
  descriptionChanged: PropTypes.func.isRequired, 
  doValidation: PropTypes.bool.isRequired,
  duplicateNameError: PropTypes.bool
};

DataPatternMetaData.defaultProps = {
  duplicateNameError: false
}

export default DataPatternMetaData;