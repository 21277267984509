import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button } from '@panwds/react-ui';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
  } from 'reactstrap';
import Table from '../../Table/Table';
import columns from './columns';
import translate from '../../../helpers/translate';
import EmptySvg from '../../../../assets/images/empty.svg';
import SpinnerIcon from '../../../../assets/images/spinner.gif';
import TokenModals from './TokenModals';
import toastr from '../../../helpers/toastr';

function t(scope, options) {
  return translate(`api_tokens.index.${scope}`, options);
}

const ApiTokenTable = ({lookupURL, fawkes, apiError, tokenData, userRole}) => {
  const [data, setData] = useState([]);
  const [tokenName, setTokenName] = useState('');
  const [accessToken, setAccessToken] = useState(tokenData.access_token || '');
  const [refreshToken, setRefreshToken] = useState(tokenData.refresh_token || '');
  const [errorMessage, setErrorMessage] = useState(apiError);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(localStorage.getItem('retryAction') || 'create');
  const [showModal, setShowModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actionItem, setActionItem] = useState(JSON.parse(localStorage.getItem('actionItem')) || null);
  const [processing, setProcessing] = useState(false);
  const fawkesPath = window.location.pathname.replace('/settings', '');
  const redirectUri = `${window.location.origin}${fawkes ? fawkesPath : ''}/v1/public/oauth/callback`;


  async function fetchData() {
    await axios(
      `${lookupURL}?redirectUri=${redirectUri}`
    ).then(
      result => {
        setLoading(false);
        if(result.data.token) {
          setData(result.data.data);
          setAccessToken(result.data.token.access_token);
          setRefreshToken(result.data.token.refresh_token);
          setShowModal('createModalOutput');
        }
        else {
          setData(result.data.data);
          if (result.data.error) {
            setErrorMessage(result.data.error);
            setShowModal('errorModal');
          }
        }
      }
    ).catch(
      () => { setLoading(false); }
    );
  }

  useEffect(() => {
    if (errorMessage) {
      setShowModal('errorModal');
    }
    if (accessToken && refreshToken) {
      setShowModal('createModalOutput');
    }
    if (window.location.pathname === ('/api_tokens')) {
      window.history.replaceState({}, 'Settings | DLP', '/settings');
    }
    fetchData();
  }, []);

  const handleNameChange = (event) => {
    setError(false);
    setErrorMessage(null);
    setTokenName(event.target.value);
  };

  const closeModal = () => {
    setShowModal(null);
    setError(false);
    setErrorMessage(null);
    setProcessing(false);
    setTokenName('');
    setAccessToken('');
    setRefreshToken('');
    setActionItem(null);
    setStatus(null);
  };

  const truncateName = (str) => {
    if (str.length <= 25) {
      return str
    }
    return `${str.slice(0, 25)}...`
  }

  const revoke = () => {
    setProcessing(true);
    const url = fawkes ? lookupURL : '/api_tokens';
    if (fawkes) {
      axios({
        url: `${url}/${actionItem.id}`,
        method: 'DELETE'
      })
      .then(
        () => {
          closeModal();
          setLoading(true);
          fetchData();
          toastr.success(t('token_deleted', {name: truncateName(actionItem.appName)}));
        }
      ).catch(
        () => { closeModal(); toastr.error(translate('errors.server_error')); }
      )
    }
    else {
      axios({
        url: `${url}/${actionItem.id}.json?name=${actionItem.appName}`,
        method: 'DELETE'
      })
      .then(
        () => {
          window.location.assign('/api_tokens');
        }
      ).catch(
        () => {
          window.location.assign('/api_tokens');
        }
      );
    }
  };

  const create=(confirmed = false) => {
    setError(false);
    if (confirmed) {
      localStorage.setItem('retryAction', 'create');
      setProcessing(true);
      const body = { clientApp: { appName: tokenName, redirect_uri: redirectUri } };
      axios.post(lookupURL, body)
        .then(
          result => {
            const callback = fawkes ? result.data.data.callBackURL : result.data.callBackURL;
            if (callback) {
              window.location.href = callback;
            }
            else {
              setTokenName('');
              setShowModal('errorModal');
            }
          }
        ).catch(
          err => {
            const result = err.response;
            setProcessing(false);
            if (['4000', '4001'].includes(String(result.data.errorCode))) {
              setShowModal('createModal');
              setError(true);
              setErrorMessage(t(`errors.${result.data.errorCode}`));
            } else if (String(result.data.errorCode) === '4002') {
              window.location.assign('/api_tokens');
            } else {
              setTokenName('');
              setShowModal('errorModal');
            }
          }
        );
    }
    else {
      setShowModal('confirmModal');
      setStatus('create');
    }
  };

  const renew = (confirmed=false) => {
    if(confirmed) {
      localStorage.setItem('retryAction', 'renew');
      localStorage.setItem('actionItem', JSON.stringify(actionItem));
      setProcessing(true);
      window.location.href = actionItem.callBackURL;
    }
    else {
      setShowModal('confirmModal');
      setStatus('renew');
    }
  };

  const updateState = (modal, renewOrRevoke, item) => {
    setStatus(renewOrRevoke);
    setShowModal(modal);
    setActionItem(item);
  }

  const confirm = () => {
    if (status === 'renew') {
      return renew(true);
    }
    return create(true);
  }

  const update = () => {
    if (status === 'renew') {
      renew();
    }
    else {
      revoke();
    }
  };

  const retry = () => {
    setErrorMessage('');
    if (status === 'create') {
      setShowModal('createModal');
    }
    else {
      setShowModal('renewRevokeModal');
    }
  }

  return (
    <div className='apiTable reactTable'>
      <div className="title">
        <span className="card-title">{t('table_title')}</span>
        {userRole === 'READ_WRITE' &&
          <span className="actions tw-float-right">
            <Button appearance='secondary' onClick={() => { setShowModal('createModal'); }}>
              {t('create_token')}
            </Button>
          </span>
        }
      </div>
      <Table columns={columns} data={data} updateState={updateState} userRole={userRole} />
      {
        data.length === 0 &&
          <div className="tableArea">
            {loading &&
              <div className='no_tokens'>
                <img id='loader' src={SpinnerIcon} alt='loading' />
              </div>
            }
            {!loading &&
              <div className='no_tokens'>
                <div className='empty_icon'>
                  <img src={EmptySvg} alt='Empty' />
                </div>
                No API tokens here. <a onClick={() => {
                  setShowModal('createModal');
                }}>Create a token</a> to start.
              </div>
            }
          </div>
      }

      { showModal &&
        <Modal
          className={`apiToken ${showModal}`}
          centered
          onClosed={() => closeModal()}
          isOpen={!!showModal}>

          <ModalHeader toggle={() => closeModal()}>
            {showModal === 'createModal' && t('create_modal.title')}
            {showModal === 'errorModal' && t(`${status}_modal.title`)}
            {showModal === 'createModalOutput' && (status === 'renew' ? t(`token_renewed`) : t(`token_created`))}
            {showModal === 'renewRevokeModal' && t(`${status}_modal.title`)}
            {showModal === 'confirmModal' && t(`${status}_modal.title`)}
          </ModalHeader>
          <ModalBody>
            <TokenModals
              type={showModal}
              area='body'
              error={error}
              errorMessage={(errorMessage || '').split('?')[0]}
              tokenName={tokenName}
              handleNameChange={handleNameChange}
              processing={processing}
              create={create}
              retry={retry}
              status={status}
              accessToken={accessToken}
              refreshToken={(refreshToken || '').split('?')[0]}
              closeModal={closeModal}
              update={update}
              confirm={confirm}
            />
          </ModalBody>
          <ModalFooter>

          <TokenModals
              type={showModal}
              area='footer'
              error={error}
              errorMessage={errorMessage}
              tokenName={tokenName}
              handleNameChange={handleNameChange}
              processing={processing}
              create={create}
              retry={retry}
              status={status}
              accessToken={accessToken}
              refreshToken={refreshToken}
              closeModal={() => closeModal}
              update={update}
              confirm={confirm}
            />
          </ModalFooter>
        </Modal>
      }
    </div>
  );
}

ApiTokenTable.propTypes = {
  lookupURL: PropTypes.string,
  fawkes: PropTypes.bool,
  apiError: PropTypes.string,
  tokenData: PropTypes.shape({
    access_token: PropTypes.string,
    refresh_token: PropTypes.string,
  }),
  userRole: PropTypes.string,
};

ApiTokenTable.defaultProps = {
  lookupURL: undefined,
  fawkes: true,
  apiError: undefined,
  tokenData: {},
  userRole: 'READ_ONLY',
}
export default ApiTokenTable;
