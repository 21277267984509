import React from "react";
import { fireEvent, render, screen, waitFor, within, prettyDOM } from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import BulkActions from "./BulkActions";


const MOCK_GLOBAL_STATUS = [
  {
    id: '1',
    resolutionStatus: 'Open'
  },
  {
    id: '2',
    resolutionStatus: 'Closed'
  }
];

const MOCK_TENANT_STATUS = [
  {
    id: '1',
    resolutionStatus: 'Custom1'
  },
  {
    id: '2',
    resolutionStatus: 'Custom2'
  }
];

const axiosMock = new MockAdapter(axios);
axiosMock.onGet('/global-resolution-statuses.json').reply(200, MOCK_GLOBAL_STATUS);
axiosMock.onGet('/tenant-resolution-statuses.json').reply(200, MOCK_TENANT_STATUS);
axiosMock.onPut('/update-incidents.json').reply(200, {});

describe('<BulkActions/>', () => {
  const MOCK_USERS = [
    {
      id: '1',
      firstName: 'Maureen',
      lastName: 'Douglas',
      emailAddress: 'mdouglas@gmail.com',
      status: 'ACTIVE'
    },
    {
      id: '2',
      firstName: 'Marco',
      lastName: 'Harber',
      emailAddress: 'mharber@gmail.com',
      status: 'ACTIVE'
    },
    {
      id: '3',
      firstName: 'Randal',
      lastName: 'Hermann',
      emailAddress: 'rhermann@gmail.com',
      status: 'ACTIVE'
    }
  ];

  const MOCK_INCIDENTS = [
    {
      id: "incident1",
    },
    {
      id: "incident2"
    }
  ];

  const saveAssignee = jest.fn();
  const deleteAssignee = jest.fn();
  const updateIncidents = jest.fn();

  let testUtils;
  beforeEach(() => {
    testUtils = render(<BulkActions
      globalStatusLookupURL='/global-resolution-statuses.json'
      tenantStatusLookupURL='/tenant-resolution-statuses.json'
      incidentUpdateURL='/update-incidents.json'
      selectedItems={MOCK_INCIDENTS}
      assignees={MOCK_USERS}
      saveAssignee={saveAssignee}
      deleteAssignee={deleteAssignee}
      incidentsUpdated={updateIncidents}/>)
  });

  it('Assign assignee flow works', async() => {
    const button = testUtils.container.querySelector(`button.assignOwner`);
    fireEvent.click(button);

    const menu = testUtils.container.querySelector('.assignMenu');
    await waitFor(() => expect(menu).toBeVisible());

    const checkbox = testUtils.container.querySelector('input[type="checkbox"]');

    fireEvent.click(checkbox);
    await waitFor(() => expect(updateIncidents).toHaveBeenCalled());
  });

  it('Assign resolution status flow works', async() => {
    const button = testUtils.container.querySelector(`button.changeResolution`);
    fireEvent.click(button);

    const menu = testUtils.container.querySelector('.resolveMenu');
    await waitFor(() => expect(menu).toBeVisible());

    const item = menu.querySelector('.listItem');

    fireEvent.click(item);
    await waitFor(() => expect(updateIncidents).toHaveBeenCalled());
  });

  it('Attach notes flow works', async() => {
    const button = testUtils.container.querySelector(`button.editNotes`);
    fireEvent.click(button);

    const form = testUtils.container.querySelector('.notesForm');
    await waitFor(() => expect(form).toBeVisible());

    const input = form.querySelector('textarea');
    fireEvent.focus(input);
    fireEvent.input(input, { target: { value: 'Test notes' } });

    const save = form.querySelector('button.save');
    fireEvent.click(save);

    await waitFor(() => expect(updateIncidents).toHaveBeenCalled());
  });
});