// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<OcrSettings> Component to have basic classes renders 1`] = `
<div
  className="ocr settings"
>
  <Header
    activePage="ocr.index.title"
    basePage=""
    basePageLink=""
  />
  <div
    className="ocrTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        ocr.index.title
      </span>
    </div>
    <div
      className="ocrForm"
    >
      <div
        className="enablement"
      >
        <div>
          <span
            className="form-label"
          >
            ocr.index.ocr
          </span>
          <p>
            ocr.index.enable_ocr
          </p>
        </div>
        <div
          className="tw-float-right"
        >
          <div
            className="ocrItem"
            key="saas"
          >
            <span
              className="toggle-wrapper"
            >
              <span
                className="toggle-label"
              >
                ocr.index.enabled for ocr.index.saas
              </span>
              <ToggleSwitch
                changeAction={[Function]}
                checked={true}
                sliderDisabled={false}
              />
            </span>
          </div>
          <div
            className="ocrItem"
            key="access"
          >
            <span
              className="toggle-wrapper"
            >
              <span
                className="toggle-label"
              >
                ocr.index.enabled for ocr.index.access
              </span>
              <ToggleSwitch
                changeAction={[Function]}
                checked={true}
                sliderDisabled={false}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="ocr.index.modal_title"
      />
      <CardBody
        addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div
            className="message-wrapper"
          >
            <p>
              ocr.index.ocr_enabledocr.index.null
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <ModalCloseButton
            appearance="primary"
          >
            ocr.index.close
          </ModalCloseButton>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<OcrSettings> Component to have basic classes renders read only 1`] = `
<div
  className="ocr settings"
>
  <Header
    activePage="ocr.index.title"
    basePage=""
    basePageLink=""
  />
  <div
    className="ocrTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        ocr.index.title
      </span>
    </div>
    <div
      className="ocrForm"
    >
      <div
        className="enablement"
      >
        <div>
          <span
            className="form-label"
          >
            ocr.index.ocr
          </span>
          <p>
            ocr.index.enable_ocr
          </p>
        </div>
        <div
          className="tw-float-right"
        >
          <div
            className="ocrItem"
            key="saas"
          >
            <span
              className="toggle-wrapper"
            >
              <span
                className="toggle-label"
              >
                ocr.index.enabled for ocr.index.saas
              </span>
              <ToggleSwitch
                changeAction={[Function]}
                checked={true}
                sliderDisabled={true}
              />
            </span>
          </div>
          <div
            className="ocrItem"
            key="access"
          >
            <span
              className="toggle-wrapper"
            >
              <span
                className="toggle-label"
              >
                ocr.index.enabled for ocr.index.access
              </span>
              <ToggleSwitch
                changeAction={[Function]}
                checked={true}
                sliderDisabled={true}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="ocr.index.modal_title"
      />
      <CardBody
        addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div
            className="message-wrapper"
          >
            <p>
              ocr.index.ocr_enabledocr.index.null
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <ModalCloseButton
            appearance="primary"
          >
            ocr.index.close
          </ModalCloseButton>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<OcrSettings> Component to have basic classes renders saas 1`] = `
<div
  className="ocr settings"
>
  <Header
    activePage="ocr.index.title"
    basePage=""
    basePageLink=""
  />
  <div
    className="ocrTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        ocr.index.title
      </span>
    </div>
    <div
      className="ocrForm"
    >
      <div
        className="enablement"
      >
        <div>
          <span
            className="form-label"
          >
            ocr.index.ocr
          </span>
          <p>
            ocr.index.enable_ocr
          </p>
        </div>
        <div
          className="tw-float-right"
        >
          <div
            className="text-center"
          >
            <p>
              <img
                alt="loading"
                id="loader"
              />
              actions.loading
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="ocr.index.modal_title"
      />
      <CardBody
        addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div
            className="message-wrapper"
          >
            <p>
              ocr.index.ocr_enabledocr.index.null
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <ModalCloseButton
            appearance="primary"
          >
            ocr.index.close
          </ModalCloseButton>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<OcrSettings> componentDidMount should call API for access/ngfw 1`] = `
<div
  className="ocr settings"
>
  <Header
    activePage="ocr.index.title"
    basePage=""
    basePageLink=""
  />
  <div
    className="ocrTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        ocr.index.title
      </span>
    </div>
    <div
      className="ocrForm"
    >
      <div
        className="enablement"
      >
        <div>
          <span
            className="form-label"
          >
            ocr.index.ocr
          </span>
          <p>
            ocr.index.enable_ocr
          </p>
        </div>
        <div
          className="tw-float-right"
        />
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="ocr.index.modal_title"
      />
      <CardBody
        addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div
            className="message-wrapper"
          >
            <p>
              ocr.index.ocr_enabledocr.index.null
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <ModalCloseButton
            appearance="primary"
          >
            ocr.index.close
          </ModalCloseButton>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<OcrSettings> renders saas banner requested 1`] = `
<div
  className="ocr settings"
>
  <Header
    activePage="ocr.index.title"
    basePage=""
    basePageLink=""
  />
  <div
    className="ocrTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        ocr.index.title
      </span>
    </div>
    <div
      className="ocrForm"
    >
      <div
        className="enablement"
      >
        <div>
          <span
            className="form-label"
          >
            ocr.index.ocr
          </span>
          <p>
            ocr.index.enable_ocr
          </p>
        </div>
        <div
          className="tw-float-right"
        >
          <div
            className="text-center"
          >
            <p>
              <img
                alt="loading"
                id="loader"
              />
              actions.loading
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="ocr.index.modal_title"
      />
      <CardBody
        addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div
            className="message-wrapper"
          >
            <p>
              ocr.index.ocr_enabledocr.index.null
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <ModalCloseButton
            appearance="primary"
          >
            ocr.index.close
          </ModalCloseButton>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;

exports[`<OcrSettings> shows correct header renders 1`] = `
<div
  className="ocr settings"
>
  <Header
    activePage="ocr.index.title"
    basePage=""
    basePageLink=""
  />
  <div
    className="ocrTable reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        ocr.index.title
      </span>
    </div>
    <div
      className="ocrForm"
    >
      <div
        className="enablement"
      >
        <div>
          <span
            className="form-label"
          >
            ocr.index.ocr
          </span>
          <p>
            ocr.index.enable_ocr
          </p>
        </div>
        <div
          className="tw-float-right"
        />
      </div>
    </div>
  </div>
  <IntlProvider
    defaultFormats={Object {}}
    defaultLocale="en"
    fallbackOnEmptyString={true}
    formats={Object {}}
    locale="en"
    messages={Object {}}
    onError={[Function]}
    onWarn={[Function]}
    textComponent={Symbol(react.fragment)}
  >
    <Modal
      confirmClose={false}
      isOpen={false}
      onClose={[Function]}
      style={
        Object {
          "fontSize": "12px",
        }
      }
    >
      <ModalHeader
        enableClose={true}
        title="ocr.index.modal_title"
      />
      <CardBody
        addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark"
      >
        <div
          className="modal-content modal-body"
        >
          <div
            className="message-wrapper"
          >
            <p>
              ocr.index.ocr_enabledocr.index.null
            </p>
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <span
          className="tw-flex tw-justify-end tw-space-x-1"
        >
          <ModalCloseButton
            appearance="primary"
          >
            ocr.index.close
          </ModalCloseButton>
        </span>
      </ModalFooter>
    </Modal>
  </IntlProvider>
</div>
`;
