/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton } from '@panwds/react-ui';
import { IntlProvider } from 'react-intl';

import $ from 'jquery';
import translate from '../../helpers/translate';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Header from '../DetectionMethods/Header';
import toastrWrapper from '../../helpers/toastr';
import SpinnerIcon from '../../../assets/images/spinner.gif';

function t(scope, options) {
  return translate(`ocr.index.${scope}`, options);
}

const DISABLED = 'disabled';
const ENABLED = 'enabled';
const REQUESTED = 'requested';

class OcrSettings extends React.Component {
  state = {
    ocrConfig: {},
    modalOpen: false,
    displayStatus: null,
    displayService: null,
    loading: true,
  };

  componentDidMount() {
    this.loadTenantConfig();
  }

  loadTenantConfig = async() => {
    if (this.props.services.includes('access') && this.props.services.includes('ngfw')) {
      const config = await this.fetchConfigByService('prisma-access');
      this.setState(prevState => ({
        ocrConfig: {...prevState.ocrConfig, 'access-ngfw': config}
      }));
    }
    else if (this.props.services.includes('access')) {
      const config = await this.fetchConfigByService('prisma-access');
      this.setState(prevState => ({
        ocrConfig: {...prevState.ocrConfig, 'prisma-access': config}
      }));
    }
    else if (this.props.services.includes('ngfw')) {
      const config = await this.fetchConfigByService('ngfw');
      this.setState(prevState => ({
        ocrConfig: {...prevState.ocrConfig, 'ngfw': config}
      }));
    }
    if (this.props.services.includes('saas')) {
      const config = await this.fetchConfigByService('prisma-saas');
      this.setState(prevState => ({
        ocrConfig: {...prevState.ocrConfig, 'prisma-saas': config}
      }));
    }
    if (this.props.emailFlag) {
      const config = await this.fetchConfigByService('email-dlp');
      this.setState(prevState => ({
        ocrConfig: {...prevState.ocrConfig, 'email-dlp': config}
      }));
    }
    this.setState({loading: false});
  }

  fetchConfigByService = async (service) => {
    const config = await axios.get(
      `${this.props.lookupURL}?serviceName=${service}`,
    ).then( result => {
      return result.data;
    }).catch(() => {
      return {};
    });
    return config;
  }

  handleChange = async(service) => {
    const ocr_enablement = this.state.ocrConfig[service]?.ocr_enablement?.toLowerCase() || DISABLED;
    let newStatus;
    if (ocr_enablement === DISABLED) {
      newStatus = ENABLED;
    } else {
      newStatus = DISABLED;
    }

    try {
      await this.updateOCRStatus(newStatus, service)

      this.setState(prevState => ({
        ocrConfig: {
          ...prevState.ocrConfig, [service]: {...prevState.ocrConfig[service], ocr_enablement: newStatus}
        }
      }));
      const config = await this.fetchConfigByService(service === 'access-ngfw' ? 'prisma-access' : service);
      if (newStatus !== DISABLED) {
        this.setState({
          modalOpen: true,
          displayStatus: config.ocr_enablement,
          displayService: service
        });
      }
    } catch (error) {
      this.showFlashMessage(t('server_error'), 'error');
      toastrWrapper.error(t('server_error'));
    }
  }

  updateOCRStatus = async(ocrStatus, service) => {
    const request = {
      service_name: service === 'access-ngfw' ? 'prisma-access' : service,
      ocr_enablement: ocrStatus
    }
    await axios.put(this.props.lookupURL, request);
  }

  closeModal = async() => {
    this.setState({
      modalOpen: false
    });
  }

  closeBanner = async (service) => {
    const serviceConfig = this.state.ocrConfig[service];
    this.setState(prevState => ({
      ocrConfig: {
        ...prevState.ocrConfig,
        [service]: {...prevState.ocrConfig[service], ocr_enablement_banner: false}
      }
    }));
    $('.banner-area').html('');
    try {
      await axios.post(this.props.lookupURL, {...serviceConfig, ocr_enablement_banner: false});
    } catch (error) {
      console.error('Failed to turn off banner message', error);
    }
  }

  showFlashMessage = (message, iconType) => {
    $('#flash-messages').html(
      `<div class="alert alert-floating alert-dismissible fade show" role="alert">
        <span class="fa fa-check-circle ${iconType} flash-icon"></span>
        <span class="tw-float-right btn-link dismiss-flash" data-dismiss="alert">Dismiss</span>
        ${message}
      </div>`)
    setTimeout(function hideAlert() { $('.alert').hide(); }, 5000);
  }

  render () {
    const {userRole} = this.props;
    const {ocrConfig, modalOpen, loading, displayStatus, displayService} = this.state;
    Object.keys(ocrConfig).forEach(service => {
      if (ocrConfig[service]?.ocr_enablement_banner && ocrConfig[service]?.ocr_enablement?.toLowerCase() === ENABLED) {
        $('.banner-area').html(
          `<div class='infoBanner'>
            <span class="fa fa-info-circle flash-icon"></span>
            <span class="infoMessage">${t('ocr_enabled')}${t(service)}</span>
            <span class="tw-float-right close"></span>
          </div>`);
        $('.banner-area span.close').on('click', () => this.closeBanner(service));
      }
      else if (ocrConfig[service]?.ocr_enablement?.toLowerCase() === REQUESTED) {
        $('.banner-area').html(
          `<div class='infoBanner'>
            <span class="fa fa-info-circle flash-icon"></span>
            <span class="infoMessage">${t('ocr_requested')}</span>
          </div>`);
      }
      else {
        $('.banner-area').html('');
      }
    })

    return (
      <div className='ocr settings'>
        <Header activePage={t('title')} />
        <div className='ocrTable reactTable'>
          <div className='title card-title'>
            <span>{t('title')}</span>
          </div>
          <div className='ocrForm'>
            <div className='enablement'>
              <div>
                <span className='form-label'>{t('ocr')}</span>
                <p>{t('enable_ocr')}</p>
              </div>
              <div className='tw-float-right'>
                { loading ?
                  <div className='text-center'>
                    <p>
                      <img id='loader' src={SpinnerIcon} alt='loading' />
                      {translate('actions.loading')}
                    </p>
                  </div>
                  :
                  Object.keys(ocrConfig).map(service => {
                    return (
                      <div className='ocrItem' key={service}>
                        <span className='toggle-wrapper'>
                          <span className='toggle-label'>{ `${t(ocrConfig[service]?.ocr_enablement?.toLowerCase() || DISABLED)} for ${t(service)}`  }</span>
                          <ToggleSwitch changeAction={() => this.handleChange(service)} checked={ocrConfig[service]?.ocr_enablement?.toLowerCase() === ENABLED} sliderDisabled={userRole !== 'READ_WRITE'} />
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>

          </div>

        </div>
        <IntlProvider locale='en'>
          <Modal style={{fontSize: '12px'}} confirmClose={false} onClose={this.closeModal} isOpen={modalOpen}>
            <ModalHeader title={t('modal_title')} enableClose />
            <ModalBody addClassName="tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
              <div className='modal-content modal-body'>
                <div className="message-wrapper">
                  <p>{displayStatus === REQUESTED? t('message'): `${t('ocr_enabled')}${t(displayService)}`}</p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <span className="tw-flex tw-justify-end tw-space-x-1">
                <ModalCloseButton appearance='primary'>{t('close')}</ModalCloseButton>
              </span>

            </ModalFooter>
          </Modal>
        </IntlProvider>
      </div>
      )
  }
}

OcrSettings.propTypes = {
    lookupURL: PropTypes.string.isRequired,
    userRole: PropTypes.string,
    services: PropTypes.array,
    emailFlag: PropTypes.bool
};

OcrSettings.defaultProps = {
    userRole: 'READ_ONLY',
    services: [],
    emailFlag: false
}

export default OcrSettings;
