import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import IdentifierDropdown from './IdentifierDropdown';
import BasicDropdown from './BasicDropdown';
import occurrences from './occurrences';

import translate from '../../../helpers/translate';

function t(scope, options) {
  return translate(`data_profiles.form.data_pattern_form.${scope}`, options);
}

const DEFAULT_CONF_LEVELS = [
  { label: 'High', value: 'high' },
  { label: 'Low', value: 'low' }
];

const DataPatternCondition = ({dataPatterns, ruleItems, conditionIndex, v1Index, updateRuleItems, deleteRuleItem, validatePresence, readOnly}) => {
  const handleChange = (ruleItemIndex, fields) => {
    const item = ruleItems[ruleItemIndex];
    Object.keys(fields).forEach(field => {
      item[field] = fields[field]
    });
    ruleItems[ruleItemIndex] = item;
    updateRuleItems(ruleItems, conditionIndex, v1Index);
  }

  const handleOccurrenceCountChange = (event, index) => {
    handleChange(index, {'occurrence_count': parseInt(event.target.value, 10), 'occurrence_low': null, 'occurrence_high': null})
  };

  const handleOccurrenceLowChange = (event, index) => {
    handleChange(index, {'occurrence_low': parseInt(event.target.value, 10), 'occurrence_count': null})
  };

  const handleOccurrenceHighChange = (event, index) => {
    handleChange(index, {'occurrence_high': parseInt(event.target.value, 10), 'occurrence_count': null})
  };

  const occurrenceLowError = (dp) => {
    if (dp.occurrence_operator_type === 'between') {
      if (!Number.isInteger(dp.occurrence_low)) {
        return t('errors.number_only');
      }
      if (dp.occurrence_low >= dp.occurrence_high) {
        return t('errors.occurrence_low_high');
      }
    }
    return null;
  }

  const occurrenceHighError = (dp) => {
    if (dp.occurrence_operator_type === 'between') {
      if (!Number.isInteger(dp.occurrence_high)) {
        return t('errors.number_only');
      }
      if (dp.occurrence_high > 500) {
        return t('errors.occurrence_cap');
      }
    }
    return null;
  }

  const occurrenceCountError = (dp) => {
    if (dp.occurrence_count && dp.occurrence_count > 500) {
      return t('errors.occurrence_cap');
    }
    return null;
  }

  const isRowValid = (dp) => {
    if (!dp.id || !dp.occurrence_operator_type || !dp.confidence_level) {
      return false;
    }
    if (occurrenceLowError(dp) !== null || occurrenceHighError(dp) !== null || occurrenceCountError(dp) !== null) {
      return false;
    }
    return true;
  }

  return (
    <div>
      { ruleItems.map ((dp, index) =>
          <div className='ruleItem' key={`${dp && dp.id ? dataPatterns[dp.id]?.name : 'undefined'}-${index}`}>
            <div className='tw-float-right'>
              {!readOnly && <FaTimes onClick={() => deleteRuleItem(conditionIndex, index, v1Index)} className='muteText removeBtn' />}
            </div>
            <table className={`occurrenceFields ${conditionIndex}-${index}`}>
              <tbody>
                <tr>
                  <td>
                    <IdentifierDropdown
                      onSelect={newPattern => handleChange(index, {'id': newPattern.id, 'name': newPattern.name, 'supported_confidence_levels': newPattern.supported_confidence_levels, 'detection_technique': newPattern.detection_technique, 'version': newPattern.version})}
                      value={dataPatterns[dp.id] || {}}
                      items={Object.values(dataPatterns)}
                      placeholder='Data Pattern'
                      error={validatePresence && !dp.id}
                    />
                  </td>
                  <td>
                    <BasicDropdown
                      onSelect={newOccurrence => handleChange(index, {'occurrence_operator_type': newOccurrence})}
                      value={dp.occurrence_operator_type}
                      items={occurrences}
                      placeholder={t('occurrences_placeholder')}
                      dropClass='occurrence'
                      error={validatePresence && !dp.occurrence_operator_type}
                    />
                  </td>
                  {['less_than_equal_to', 'more_than_equal_to'].includes(dp.occurrence_operator_type) && <td><input placeholder='Count' defaultValue={dp.occurrence_count || undefined} onChange={(e) => handleOccurrenceCountChange(e, index)} className={`form-control occurrenceInput ${validatePresence && occurrenceCountError(dp) !== null ? 'has-error' : ''}`} /></td> }
                  {dp.occurrence_operator_type === 'between' && <td><input placeholder='Low' defaultValue={dp.occurrence_low || undefined} onChange={(e) => handleOccurrenceLowChange(e, index)} className={`form-control occurrenceInput ${validatePresence && occurrenceLowError(dp) !== null ? 'has-error' : ''}`} /></td> }
                  {dp.occurrence_operator_type === 'between' && <td><input placeholder='High' defaultValue={dp.occurrence_high || undefined} onChange={(e) => handleOccurrenceHighChange(e, index)} className={`form-control occurrenceInput ${validatePresence && occurrenceHighError(dp) !== null ? 'has-error' : ''}`} /></td> }
                  <td>
                    <BasicDropdown
                      onSelect={newConfidence => handleChange(index, {'confidence_level': newConfidence})}
                      value={dp.confidence_level}
                      items={dataPatterns[dp.id] ? dataPatterns[dp.id].supported_confidence_levels.map(conf => { return { label: conf.charAt(0).toUpperCase() + conf.slice(1), value: conf } }) : DEFAULT_CONF_LEVELS}
                      placeholder={t('confidence_placeholder')}
                      dropClass='confidence'
                      error={validatePresence && !dp.confidence_level}
                    />
                  </td>
                </tr>
                { validatePresence && !isRowValid(dp) &&
                  <tr>
                    { dp.id ?
                      <td />
                      :
                      <td className='errorDetail error'>{t('errors.data_pattern')}</td>
                    }
                    { dp.occurrence_operator_type ?
                      <td />
                      :
                      <td className='errorDetail error'>{t('errors.occurrence_operator_type')}</td>
                    }
                    { dp.occurrence_operator_type === 'between' && (occurrenceLowError(dp) !== null || occurrenceHighError(dp) !== null) &&
                      <td colSpan='3' className='errorDetail error'>{occurrenceLowError(dp) || occurrenceHighError(dp)}</td>
                    }
                    { dp.occurrence_operator_type !== 'between' && occurrenceCountError(dp) !== null &&
                      <td colSpan='2' className='errorDetail error'>{occurrenceCountError(dp)}</td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
        )
      }

    </div>
  );
}

DataPatternCondition.propTypes = {
  dataPatterns: PropTypes.shape({}),
  ruleItems: PropTypes.array,
  conditionIndex: PropTypes.number,
  v1Index: PropTypes.number,
  updateRuleItems: PropTypes.func,
  deleteRuleItem: PropTypes.func,
  validatePresence: PropTypes.bool,
  readOnly: PropTypes.bool,
};

DataPatternCondition.defaultProps = {
  dataPatterns: {},
  ruleItems: [],
  conditionIndex: 0,
  v1Index: undefined,
  updateRuleItems: undefined,
  deleteRuleItem: undefined,
  validatePresence: false,
  readOnly: false,
}

export default DataPatternCondition;