/* eslint-disable func-names */

import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import EdmDatasetDetails from './EdmDatasetDetails';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<EdmDatasetDetails>', () => {
  let wrapper;
  const dataset = {
    id: 'dataset-id-1',
    name: 'EDM Dataset A',
    updated_by: 'Prisma Access',
    updated_at: '2021-03-22T13:45:43.068-05:00',
    active_fields: []
  };
  const fields = [
    { id: 'col-1', name: 'social_security', data_type_name: 'ssn' },
    { id: 'col-2', name: 'credit_card_number', data_type_name: 'ccn' },
  ];
  const datasetWithFields = { ...dataset, fields }
  const updateState = () => {};

  describe('with active fields', () => {
    it('renders', () => {
      wrapper = shallow(<EdmDatasetDetails dataset={datasetWithFields} updateState={updateState} />);
      expect(wrapper.find('.actionBtns')).toHaveLength(0);
      expect(wrapper.find('.tableDetailsView')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });
  });

  describe('without active fields', () => {
    it('renders', () => {
      wrapper = shallow(<EdmDatasetDetails dataset={dataset} updateState={updateState} />);
      expect(wrapper.find('.actionBtns')).toHaveLength(0);
      expect(wrapper.find('.tableDetailsView')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });
  });

  describe('actionable', () => {
    it('renders', () => {
      wrapper = shallow(<EdmDatasetDetails dataset={dataset} updateState={updateState} actionable />);
      expect(wrapper.find('.actionBtns')).toHaveLength(1);
      expect(wrapper.find('.tableDetailsView')).toHaveLength(1);
      expect(wrapper).toMatchSnapshot();
    });
  });
});
