// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<Item> renders 1`] = `
<div
  className="telemetryItem"
>
  <div
    className="name"
  >
    SaaS Security
  </div>
  <img
    alt="dashboards.index.normal"
  />
  <div
    className="status"
  >
    dashboards.index.normal
  </div>
  <div
    className="label"
  >
    dashboards.index.last_updated
  </div>
  <div
    className="value"
  >
    July 8 2020 at 12:00 PDT
  </div>
</div>
`;
