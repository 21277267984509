/* eslint-disable func-names */

import React from 'react';

import { configure, shallow } from 'enzyme';
import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';
import Adapter from 'enzyme-adapter-react-16';
import EdmDatasets from './EdmDatasets';
import Header from '../DetectionMethods/Header';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<EdmDatasets>', () => {
  let wrapper;
  const mock = new MockAdapter(axios);
  const lookupURL = 'http://localhost/edm_datasets.json';
  const statusURL = '/edm_datasets/edm_status.json';
  const props = {
    lookupURL,
    statusURL,
    userRole: 'READ_WRITE',
    columns: [
      { header: 'EDM Dataset', accessor: 'name' },
      { header: 'Last Modified', accessor: 'updated_at' },
      { header: 'Last Updated By', accessor: 'updated_by' },
      { header: 'Indexing Status', accessor: 'index_status' }
    ]
  };
  const pageData = {
    size: 5,
    number: 0,
    total_effective_elements: 5,
    total_pages: 1
  }
  const data = [
    { id: '111', name: 'Dataset A', updated_at: 'Apr 7 2021 at 15:23', updated_by: 'Enterprise DLP App', index_status: 'complete', active_fields: [{ id: 'col-1', name: 'ssn', data_type_name: 'ssn' }] },
    { id: '112', name: 'Dataset B', updated_at: 'Apr 1 2021 at 8:00', updated_by: 'Prisma Access', index_status: 'progress', active_fields: [] },
    { id: '113', name: 'Dataset C', updated_at: 'Apr 8 2021 at 2:00', updated_by: 'Panorama' , index_status: 'failed', active_fields: []},
    { id: '114', name: 'Dataset D', updated_at: 'Apr 6 2021 at 9:42', updated_by: 'SaaS Security', index_status: 'update_failed', active_fields: [] },
    { id: '115', name: 'Dataset E', updated_at: 'Apr 3 2021 at 16:53', updated_by: 'Enterprise DLP App', index_status: 'complete', active_fields: [{ id: 'col-1', name: 'ssn', data_type_name: 'ssn' }] }
  ];

  beforeEach(() => {
    wrapper = shallow(<EdmDatasets {...props} />);
  });

  it('renders bulk table', () => {
    wrapper.setState({ data, pageData: {}, pageCount: 5, edmStatus: 'enabled' })
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.count').text()).toBe('(5)');
  });

  it('renders bulk table without config for read only user', () => {
    const readOnlyProps = {...props, userRole: 'READ_ONLY'}
    wrapper = shallow(<EdmDatasets {...readOnlyProps} />);
    wrapper.setState({ data, pageData: {}, pageCount: 5, edmStatus: 'enabled' })
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.count').text()).toBe('(5)');
  });

  it('renders bulk table and view modal if state contains viewing id', () => {
    wrapper.setState({ data, pageData: {}, pageCount: 5, viewing: '113', edmStatus: 'enabled' });
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find('.count').text()).toBe('(5)');
  });

  describe('shows correct header', () => {
    it('renders', () => {
      wrapper = shallow(<EdmDatasets {...props}/>);
      expect(wrapper.containsMatchingElement(<Header activePage='edm_datasets.index.title' basePage='' basePageLink='' />)).toEqual(true);
      expect(wrapper).toMatchSnapshot();
    });
  });

  describe('edm banner', () => {
    let response;
    const edmResponse = {
      data,
      pageData
    };

    describe('recently enabled', () => {
      beforeEach(() => {
        response = {
          edm_enablement: 'enabled',
          edm_enablement_banner: true
        };

        mock.onGet('/edm_datasets/edm_status.json').reply(200, response);
        mock.onGet(`${lookupURL}?page=${0}&limit=${15}`).reply(200, edmResponse);
      })

      it('renders enabled banner', async() => {
        wrapper = shallow(<EdmDatasets {...props} />);
        await wrapper.instance().componentDidMount();
        expect(wrapper.state('edmStatus')).toEqual('enabled');
        expect(wrapper.state('showEnabledBanner')).toEqual(true);
        expect(wrapper.state('pageData')).toEqual(pageData);
        expect(wrapper.state('loading')).toEqual(false);
      });

      it('matches snapshot', async() => {
        wrapper = shallow(<EdmDatasets {...props} />);
        await wrapper.instance().componentDidMount();
        expect(wrapper).toMatchSnapshot();
      })
    });

    describe('enabled no banner', () => {
      beforeEach(() => {
        response = {
          edm_enablement: 'enabled',
          edm_enablement_banner: false
        };

        mock.onGet('/edm_datasets/edm_status.json').reply(200, response);
        mock.onGet(`${lookupURL}?page=${0}&limit=${15}`).reply(200, edmResponse);
      })

      it('does not render enabled banner', async() => {
        wrapper = shallow(<EdmDatasets {...props} />);
        await wrapper.instance().componentDidMount();
        expect(wrapper.state('edmStatus')).toEqual('enabled');
        expect(wrapper.state('showEnabledBanner')).toEqual(false);
        expect(wrapper.state('pageData')).toEqual(pageData);
        expect(wrapper.state('loading')).toEqual(false);
      });

      it('matches snapshot', async() => {
        wrapper = shallow(<EdmDatasets {...props} />);
        await wrapper.instance().componentDidMount();
        expect(wrapper).toMatchSnapshot();
      })
    })

    describe('enabled no results response', () => {
      beforeEach(() => {
        response = {
          edm_enablement: 'enabled',
          edm_enablement_banner: false
        };

        mock.onGet('/edm_datasets/edm_status.json').reply(200, response);
        mock.onGet(`${lookupURL}?page=${0}&limit=${15}`).reply(500, {});
      })

      it('does not render enabled banner', async() => {
        wrapper = shallow(<EdmDatasets {...props} />);
        await wrapper.instance().componentDidMount();
        expect(wrapper.state('edmStatus')).toEqual('enabled');
        expect(wrapper.state('showEnabledBanner')).toEqual(false);
        expect(wrapper.state('data')).toEqual([]);
        expect(wrapper.state('loading')).toEqual(false);
      });

      it('matches snapshot', async() => {
        wrapper = shallow(<EdmDatasets {...props} />);
        await wrapper.instance().componentDidMount();
        expect(wrapper).toMatchSnapshot();
      })
    })
  });
});
