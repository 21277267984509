import React from 'react';

import { render } from '@testing-library/react';
import '@testing-library/jest-dom';
import MatchingDataPatterns from './MatchingDataPatterns';
// import { configure, shallow } from 'enzyme';
// import Adapter from 'enzyme-adapter-react-16';

jest.mock('../../helpers/translate');

// configure({ adapter: new Adapter() });

describe('<MatchingDataPatterns>', () => {
  let testUtils;
  const snippet1 = [{ 'left': null, 'detection': '***2', 'right': 'def' }];
  const snippet2 = [{ 'left': '123', 'detection': '***1', 'right': null }, { 'left': 'really long string '.repeat(15), 'detection': '***4', 'right': 'pqr' }];
  const snippet3 = [{ 'left': 'ghi', 'detection': '***3', 'right': 'jkl' }];
  let props = {
    highMatches: {
      'pattern2': { 'name': 'EDM - Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 }
    },
    medMatches: {
      'pattern1': { 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
      'pattern3': { 'name': 'Pattern3', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
    },
    lowMatches: {
      'pattern1': { 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
      'pattern2': { 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 },
      'pattern3': { 'name': 'Pattern3', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
    },
    confidence: 'hcf',
    snippets: {
      'pattern1': {
        medium_confidence_detections: snippet1,
        low_confidence_detections: snippet1
      },
      'pattern2': {
        high_confidence_detections: snippet2,
        medium_confidence_detections: snippet2,
        low_confidence_detections: snippet2
      },
      'pattern3': {
        low_confidence_detections: snippet3
      }
    }
  };

  it('should render patterns and snippets', () => {
    testUtils = render(<MatchingDataPatterns {...props} />);
    expect(testUtils.container.querySelector('.dataPatterns')).toBeInTheDocument();
    expect(testUtils.container.querySelector('.snippets')).toBeInTheDocument();
    expect(testUtils.container.querySelector('span.highlight')).toHaveTextContent('***1');
    expect(testUtils.container.querySelector('.multiProfileSelector')).not.toBeInTheDocument();
  });

  it('renders EDM as EDM', () => {
    const {getByText} = render(<MatchingDataPatterns {...props} />);
    expect(getByText('EDM - Pattern2')).toBeInTheDocument();
    expect(() => screen.getByText('Data Pattern - EDM - Pattern2')).toThrow();
  });

  it('should render document types', () => {
    const propsForDocumentTypes = {
      highMatches: {
        doge: {
          name: 'Its a fingerprint',
          hcf: 6,
          mcf: 6,
          lcf: 6,
          uhcf: 6,
          umcf: 6,
          ulcf: 6,
          score: 6,
          detection_technique: 'document_fingerprint'
        }
      },
      medMatches: {},
      lowMatches: {},
      confidence: 'hcf',
      snippets: {},
      isMultiProfile: null,
      multiProfileList: null
    };

    testUtils = render(<MatchingDataPatterns { ...propsForDocumentTypes } />);

    expect(testUtils.getByText('Document Type - Its a fingerprint')).toBeInTheDocument();
  });

  describe('multiProfileView', () => {
    it ('shows dropdown', () => {
      props = {...props, isMultiProfile: true, multiProfileList: [{
        data_profile_name: 'Credit Card',
        data_pattern_list: [{ id: 'pattern2', name: 'Pattern 2' }]
      }] };
      testUtils = render(<MatchingDataPatterns {...props} />);
      expect(testUtils.container.querySelector('.multiProfileSelector')).toBeInTheDocument();
    })
  })
});
