import React from 'react';
import { IntlProvider } from 'react-intl';

import { render, fireEvent, screen } from '@testing-library/react';
import '@testing-library/jest-dom/extend-expect';
import { expect as chaiExpect } from 'chai';

import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { shallow } from 'enzyme';
import { act } from 'react-dom/test-utils';
import sinon from 'sinon';

import FileBasedView from './viewFile';
import {
  ALLOW,
  BLOCK,
  FILE_SETTINGS_TYPE
} from '../../constants';

const axiosMock = new MockAdapter(axios);

describe('<FileBasedView />', () => {
  beforeEach(() => {
    axiosMock.reset();
  });

  it('should match snapshot', () => {
    const wrapper = shallow(
      <FileBasedView
        loading={false}
        maxLatency={6}
        actionMaxLatency={ALLOW}
        maxSize={6}
        actionMaxSize={ALLOW}
        allowLogFiles={true}
        updateSettings={() => {}}
        userRole='READ_WRITE'
      />
    );

    expect(wrapper).toMatchSnapshot();
  });

  it('should enable/disable save button and call correct api', async() => {
    const updateSettings = sinon.stub();

    axiosMock.onPut().reply(function (config) {
      expect(config.url).toBe('/v1/dlp-ui/data-filtering-profile/setting');
      expect(config.data).toBe(
        '{"type":"file","file_max_latency":"7","file_action_on_max_latency":"block","file_max_size":2,"file_action_on_max_size":"block","file_log_files_not_scanned":false}'
      );

      return [
        200,
        {
          type: FILE_SETTINGS_TYPE,
          file_max_latency: 7,
          file_action_on_max_latency: BLOCK,
          file_max_size: 2,
          file_action_on_max_size: BLOCK,
          file_log_files_not_scanned: false
        }
      ];
    });

    render(
      <IntlProvider locale='en'>
        <FileBasedView
          loading={false}
          maxLatency={1}
          actionMaxLatency={ALLOW}
          maxSize={2}
          actionMaxSize={BLOCK}
          allowLogFiles={true}
          updateSettings={updateSettings}
          userRole='READ_WRITE'
        />
      </IntlProvider>
    );

    await act(async () => {
      expect(screen.getAllByRole('textbox')[0]).toHaveValue('1');

      expect(screen.getAllByRole('radio', { name: 'Allow' })[0]).toBeChecked();
      expect(screen.getAllByRole('radio', { name: 'Block' })[0]).not.toBeChecked();

      expect(screen.getAllByRole('textbox')[1]).toHaveValue('2');

      expect(screen.getAllByRole('radio', { name: 'Allow' })[1]).not.toBeChecked();
      expect(screen.getAllByRole('radio', { name: 'Block' })[1]).toBeChecked();

      expect(screen.getAllByRole('checkbox')[0]).toBeChecked();

      expect(screen.getByText(/Save/i).closest('button')).toBeDisabled();

      fireEvent.change(screen.getAllByRole('textbox')[0], {target: {value: '7'}});
      fireEvent.click(screen.getAllByRole('radio', { name: 'Block' })[0]);
      fireEvent.click(screen.getAllByRole('checkbox')[0]);
    });

    await act(async () => {
      expect(screen.getAllByRole('textbox')[0]).toHaveValue('7');

      expect(screen.getAllByRole('radio', { name: 'Allow' })[0]).not.toBeChecked();
      expect(screen.getAllByRole('radio', { name: 'Block' })[0]).toBeChecked();

      expect(screen.getAllByRole('textbox')[1]).toHaveValue('2');

      expect(screen.getAllByRole('radio', { name: 'Allow' })[1]).not.toBeChecked();
      expect(screen.getAllByRole('radio', { name: 'Block' })[1]).toBeChecked();

      expect(screen.getAllByRole('checkbox')[0]).not.toBeChecked();

      expect(screen.getByText(/Save/i).closest('button')).not.toBeDisabled();

      fireEvent.click(screen.getByText(/Save/i));
    });

    chaiExpect(updateSettings.calledWith({
      maxLatency: 7,
      actionMaxLatency: BLOCK,
      maxSize: 2,
      actionMaxSize: BLOCK,
      allowLogFiles: false
    })).to.be.true;
  });
});