// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<Modal> with custom cancelText matches a snapshot 1`] = `
<div
  className="modalComponent"
>
  <a
    data-toggle="modal"
    href="#download_incidents_csv_modal"
  >
    <i
      className="glyphicon glyphicon-save downloadIcon"
      key="i-tag"
    />
    EXPORT
  </a>
  <div
    aria-hidden="true"
    className="modal fade"
    data-backdrop="static"
    data-focus="true"
    data-keyboard="false"
    id="download_incidents_csv_modal"
    role="dialog"
    tabIndex="-1"
  >
    <div
      className="modal-dialog"
    >
      <div
        className="modal-content"
      >
        <div
          className="modal-header"
        >
          <Button
            addClassName="close"
            appearance="tertiary-clear"
            buttonType="default"
            data-dismiss="modal"
            disabled={false}
            isMenu={false}
            onClick={[Function]}
            size="md"
          />
          <h4
            className="modal-title"
          >
            Modal Title
          </h4>
        </div>
        <div
          className="modal-body"
        >
          <p>
            Are you sure?
          </p>
        </div>
        <div
          className="modal-footer tw-flex justify-end tw-space-x-1"
        >
          <Button
            appearance="secondary"
            buttonType="default"
            data-dismiss="modal"
            disabled={false}
            isMenu={false}
            onClick={[Function]}
            size="md"
          >
            Custom Text
          </Button>
          <button
            type="button"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
