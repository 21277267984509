import translate from '../../../helpers/translate';

export default [
  {
    value: 'all',
    label: translate('data_profiles.form.match_criterion.all'),
  },
  {
    value: 'any',
    label: translate('data_profiles.form.match_criterion.any'),
  }
];
