/* eslint-disable camelcase */
import React from "react";
import PropTypes from 'prop-types';
import translate from "../../helpers/translate";

function t(scope, options) {
  return translate(`data_patterns.${scope}`, options);
}
const ConfidenceLevels = ({supported_confidence_levels, detection_technique}) => {
  let patternType;
  if (detection_technique === 'ml') {
    patternType = 'ml';
  }
  patternType = patternType || (supported_confidence_levels.length === 3) ? 'ccn' : 'other';
  return (
    <>
      <div className='form-label'>{t('confidence_levels')}</div>
      {
        patternType === 'ccn' &&
        <>
          <div className='form-value'>
            <span className='confidence-header tw-mr-1'>{t('low_confidence')}: </span>
            <span className='confidence-detail'>{t('low_confidence_ccn')}</span>
          </div>

          <div className='form-value'>
            <span className='confidence-header tw-mr-1'>{t('med_confidence')}: </span>
            <span className='confidence-detail'>{t('med_confidence_ccn')}</span>
          </div>

          <div className='form-value'>
            <span className='confidence-header tw-mr-1'>{t('high_confidence')}: </span>
            <span className='confidence-detail'>{t('high_confidence_ccn')}</span>
          </div>
        </>
      }
      {
        patternType === 'ml' &&
        <>
          <div className='form-value'>
            <span className='confidence-header tw-mr-1'>{t('low_confidence')}: </span>
            <span className='confidence-detail'>{supported_confidence_levels.length === 3 ? t('low_confidence_ml') : t('low_confidence_ml_no_med')}</span>
          </div>

          {supported_confidence_levels.length === 3 &&
            <div className='form-value'>
              <span className='confidence-header tw-mr-1'>{t('med_confidence')}: </span>
              <span className='confidence-detail'>{t('med_confidence_ml')}</span>
            </div>
          }

          <div className='form-value'>
            <span className='confidence-header tw-mr-1'>{t('high_confidence')}: </span>
            <span className='confidence-detail'>{supported_confidence_levels.length === 3 ? t('high_confidence_ml') : t('high_confidence_ml_no_med')}</span>
          </div>
        </>
      }
      {
        patternType === 'other' &&
        <div className='form-value'>
          {
            supported_confidence_levels.map(level => (
              <span key={level} className='confidence-pill tw-mr-3'>{level}</span>
            ))
          }
        </div>
      }
    </>
  );
};

ConfidenceLevels.propTypes = {
  supported_confidence_levels: PropTypes.arrayOf(PropTypes.string),
  detection_technique: PropTypes.string
}

ConfidenceLevels.defaultProps = {
  supported_confidence_levels: [],
  detection_technique: 'other'
}

export default ConfidenceLevels;