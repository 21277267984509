import React from 'react';

import { configure, shallow } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import ReportCard, {download} from './ReportCard';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

const axiosMock = new MockAdapter(axios);
const flushPromises = () => new Promise(setImmediate);

describe('<ReportCard>', () => {
  let wrapper;
  const report = {
    report_id: '12345',
    file_name: 'Test Asset',
    asset_id: '987654',
    data_profile_name: 'Bank Account Number North American',
    data_pattern_rule_1_verdict: 'MATCHED',
    data_pattern_rule_2_verdict: 'NOT_MATCHED',
    detection_time: '2020-07-09T20:56:14.620Z',
    file_size_in_bytes: 45986,
  };
  const props = { report, userRole: 'READ_WRITE' };

  it('renders', () => {
    wrapper = shallow(<ReportCard {...props} />);
    expect(wrapper.find('.downloadIcon')).toHaveLength(1);
    expect(wrapper).toMatchSnapshot();
  });

  it('does not show download icon if read only', () => {
    const readOnlyProps = {...props, userRole: 'READ_ONLY'};
    wrapper = shallow(<ReportCard {...readOnlyProps} />);
    expect(wrapper.find('.downloadIcon')).toHaveLength(0);
    expect(wrapper).toMatchSnapshot();
  });

  it('does not show download icon if not MATCHED', () => {
    report.data_pattern_rule_1_verdict = 'NOT_MATCHED'
    wrapper = shallow(<ReportCard {...props} />);
    expect(wrapper.find('.downloadIcon')).toHaveLength(0);
    expect(wrapper).toMatchSnapshot();
  });
});

describe("download()", () => {
  const report = {
    report_id: '12345',
    file_name: 'test_report',
    channel: 'abcd'
  };
  beforeEach(() => {
    document.body.innerHTML =
    `<div>
      <span class="downloadIcon"/>
      <span id="flash-messages"/>
    </div>`;
  });
  it('should change url', async() => {
    delete window.location;
    window.location = { assign: jest.fn() };
    axiosMock.onGet(`/file/${report.report_id}.json?originalFileName=${report.file_name}&service-name=${report.channel}`).reply(200, {additionalDetails: { URL: 'my_url' }, status: 200});
    download(report);
    await flushPromises();
    expect(window.location.assign).toBeCalledWith('my_url');
  });
});
