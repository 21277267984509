import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton } from '@panwds/react-ui';
import { IntlProvider } from 'react-intl';
import Popup from 'reactjs-popup';
import { FaCheckCircle, FaExclamationCircle, FaExclamationTriangle, FaTimesCircle, FaCopy } from 'react-icons/fa';
import axios from 'axios';
import $ from 'jquery';
import translate from '../../helpers/translate';
import ConnectWarning from './ConnectWarning';
import toastr from '../../helpers/toastr';


function t(scope, options) {
  return translate(`settings.azure_configure_modal.${scope}`, options);
}

const AzureConfigureModal = ({initialState, initialTenantId,
  initialEndpoint, edit, showModal, modalClosed, validateURL, updateURL, accessEnabled, ngfwEnabled, fawkes}) => {
  const [copied, setCopied] = useState(false);
  const [tenantId, setTenantId] = useState(initialTenantId);
  const [endpoint, setEndpoint] = useState(initialEndpoint);
  const [validatePresence, setValidatePresence] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeState, setActiveState] = useState(initialState);
  const [modalOpen, setModalOpen] = useState(showModal);

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const copy = (id) => {
    const copyText = document.getElementById(id);
    copyText.select();
    document.execCommand('copy');
    setCopied(true);
  }

  const instructionsView = () => {
    const shellCommand = "Connect-AzureAD -TenantID <Your_Tenant_ID>\nNew-AzureADServicePrincipal -AppId 65def4b7-bae6-4bff-ab73-63fe8c9a3c8d";

    return (
      <div className='instructions'>
        <h3 className='modal-section-title'>{t('instructions')}</h3>
        <p>{t('instruction_list.step_one')}</p>
        <div className='rightInnerAddOn'>
          <Popup trigger={ <i onClick={ () => copy('shellCommand') }><FaCopy /></i> } position='top center' on='hover' mouseLeaveDelay={0}>
            <div className='popup-box'>{copied ? t('instruction_list.copied') : t('instruction_list.copy')}</div>
          </Popup>
          <textarea readOnly className='json' id='shellCommand' value={shellCommand} />
        </div>
        <p>{t('instruction_list.step_two')}</p>
        <p>{t('instruction_list.step_three')}</p>

        <p className='tw-mb-0'>{t('instruction_list.step_four')}</p>
        <ul className='tw-mt-1 tw-pl-8'>
          <li>{t('instruction_list.provide_tenant_id')}</li>
          <li>{t('instruction_list.provide_endpoint')}</li>
        </ul>
      </div>
    )
  }

  const tenantIdChanged = (event) => {
    setTenantId(event.target.value);
    setErrorMessage('');
  }

  const endpointChanged = (event) => {
    setEndpoint(event.target.value);
    setErrorMessage('');
  }

  const bucketDetailsView = () => {
    return (
      <div className='bucketDetails azure'>
        <h3 className='modal-section-title'>{t('bucket_details.title')}</h3>
        <div className='helpText'>{t('bucket_details.instructions')}</div>
        {edit && <div className='helpText'>

          <br />
          <FaExclamationTriangle className='warning mr5' style={{width: '15px', height: '15px'}}/>
          {t('bucket_details.edit_warning')}
        </div>}

        <div className='errorMessage' style={{display: errorMessage ? '' : 'none'}}>
          <FaExclamationCircle className='error mr5' />

          {errorMessage}
        </div>
        <div className='bucketForm'>
          <p className={`inputLabel ${validatePresence && !tenantId && 'error'}`}>{t('bucket_details.tenant_id')}</p>
          <input className={`form-control tenantId ${validatePresence && !tenantId && 'has-error'}`} placeholder={t('bucket_details.tenant_id_example')} value={tenantId} onChange={ tenantIdChanged } data-testid='tenantId-input'/>
          {validatePresence && !tenantId && <p className='error errorDetail'>{t('bucket_details.field_required', {field: t('bucket_details.tenant_id')})}</p>}

          <p className={`inputLabel ${validatePresence && !endpoint && 'error'}`}>{t('bucket_details.storage_endpoint')}</p>
          <input className={`form-control endpoint ${validatePresence && !endpoint && 'has-error'}`} placeholder={t('bucket_details.storage_endpoint_example')} value={endpoint} onChange={ endpointChanged } data-testid='endpoint-input' />
          {validatePresence && !endpoint && <p className='error errorDetail'>{t('bucket_details.field_required', {field: t('bucket_details.storage_endpoint')})}</p>}
        </div>
        <ConnectWarning service="Azure"/>
      </div>
    )
  }

  const connectionStatusView = () => {
    return (
      <div className='connectionStatus'>
        <h3 className='modal-section-title'>{t('connection_status.title')}</h3>
        <div className='helpText'>{t('bucket_details.instructions')}</div>
        <p className='tw-mt-4'>
          { errorMessage ?
            <FaTimesCircle className='mr5 error' />
            : <FaCheckCircle className='success-icon mr5' />
          }
          {tenantId}
          <span className='connectionMessage'>{errorMessage || t('connection_status.success')}</span>
        </p>

      </div>
    )
  }

  const completedMark = (state) => {
    if (state === 2 && errorMessage) {
      return (
        <FaExclamationTriangle className='tw-float-right error' />
      )
    }
    if (state < activeState) {
      return (
        <FaCheckCircle className='tw-float-right' />
      )
    }
    return '';
  }

  const updateStep = (step) => {
    setActiveState(step);
  }

  const stepList = () => {
    return (
      <div className='stepList'>
        <div className={`stepItem ${activeState === 1 ? 'active' : 'btn-link'}`} onClick={() => updateStep(1)}>{t('instructions')}{completedMark(1)}</div>
        <div className={`stepItem ${errorMessage && 'error'} ${activeState === 2 ? 'active' : 'btn-link'}`}>{t('input_details')}{completedMark(2)}</div>
        <div className={`stepItem ${activeState === 3 ? 'active' : 'btn-link'}`}>{t('status')}</div>
      </div>
    )
  }

  const prev = () => {
    if (activeState === 2) {
      setActiveState(1);
    }
    if (activeState === 3) {
      setActiveState(2);
    }
  }

  const next = () => {
    if (activeState === 1) {
      setActiveState(2);
    }
    if (activeState === 2) {

      if (tenantId && endpoint) {
        const payload = {
          connection_type: 'azure',
          connection_data: {
            aws_role_arn: null,
            aws_bucket_name: null,
            region: null,
            azure_tenant_id: tenantId,
            azure_end_point: endpoint
          }
        }
        $('.nextBtn').addClass('disabled');
        axios.post(validateURL, payload)
        .then(
          (result) => {
            $('.nextBtn').removeClass('disabled');
            setErrorMessage(result.data.hasError === false ? '' : result.data.errorMessage || translate('errors.server_error'));
            setActiveState(3);
          }
        )
      }
      else {
        setValidatePresence(true);
      }
    }
    if (activeState === 3) {
      $('.nextBtn').addClass('disabled');

      const payload = {
        enabled_for_prisma_access: accessEnabled,
        enabled_for_ngfw: ngfwEnabled,
        connection_status: 'connected',
        connection_type: 'azure',
        connection_data: {
          aws_role_arn: null,
          aws_bucket_name: null,
          region: null,
          azure_tenant_id: tenantId,
          azure_end_point: endpoint
        },
        flash_params: {
          action: edit ? 'edit' : 'create'
        }
      }
      if (edit) {
        axios.put(updateURL, payload)
        .then(
          () => {
            if (fawkes) {
              toastr.success(translate('settings.index.storage_changed'));
            }
            else {
              window.location.assign('/settings/');
            }
            $('.nextBtn').removeClass('disabled');
            closeModal();
          }
        )
        .catch(
          (err) => {
            const result = err.response;
            $('.nextBtn').removeClass('disabled');
            setErrorMessage(result.data.errorMessage || translate('errors.server_error'));
            setActiveState(2);
          }
        )
      }
      else {
        axios.post(updateURL, payload)
        .then(
          () => {
            if (fawkes) {
              toastr.success(translate('settings.index.storage_changed'));
            }
            else {
              window.location.assign('/settings/');
            }
            $('.nextBtn').removeClass('disabled');
            closeModal();
          }
        )
        .catch(
          (err) => {
            const result = err.response;
            $('.nextBtn').removeClass('disabled');
            setErrorMessage(result.data.errorMessage || translate('errors.server_error'));
            setActiveState(2);
          }
        )
      }
    }
  }

  const closeModal= () => {
    setModalOpen(false);
    modalClosed();
    setActiveState(initialState);
    setEndpoint(initialEndpoint);
    setValidatePresence(false);
  }

  return (
    <IntlProvider locale='en'>
      <Modal style={{maxWidth: '900px', width: '100%'}} confirmClose={false} onClose={closeModal} isOpen={modalOpen}>
        <ModalHeader title={t('title')} enableClose />
        <ModalBody addClassName="dlp-root azureConfigureModal tw-overflow-auto tw-scrollbar-default dark:tw-scrollbar-track-dark dark:tw-scrollbar-thumb-dark dark:tw-scrollbar-corner-dark">
          <div className='modal-body'>
            <div data-testid='testRoot'>
              {stepList()}
              <div className='configBody tw-float-right'>
                {activeState === 1 && instructionsView()}
                {activeState === 2 && bucketDetailsView()}
                {activeState === 3 && connectionStatusView()}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <span className="tw-flex tw-justify-end tw-space-x-1">
            <ModalCloseButton>{translate('actions.cancel')}</ModalCloseButton>
            <Button addClassName="prevBtn" disabled={activeState === 1} onClick={prev } >
              {t('previous')}
            </Button>
            <Button appearance='primary' addClassName="nextBtn" disabled={activeState === 3 && errorMessage} onClick={next} >
              {activeState === 1? t('next') : activeState === 2? translate('actions.connect'): translate('actions.save')}
            </Button>
          </span>

        </ModalFooter>
      </Modal>
    </IntlProvider>
  )
}

AzureConfigureModal.propTypes = {
  initialState: PropTypes.number,
  initialTenantId: PropTypes.string,
  initialEndpoint: PropTypes.string,
  edit: PropTypes.bool,
  accessEnabled: PropTypes.bool,
  ngfwEnabled: PropTypes.bool,
  showModal: PropTypes.bool,
  modalClosed: PropTypes.func,
  validateURL: PropTypes.string,
  updateURL: PropTypes.string
}

AzureConfigureModal.defaultProps = {
  initialState: 1,
  initialTenantId: '',
  initialEndpoint: '',
  edit: false,
  accessEnabled: false,
  ngfwEnabled: false,
  showModal: true,
  modalClosed: undefined,
  validateURL: '/settings/validate.json',
  updateURL: '/settings.json'
}

export default AzureConfigureModal;