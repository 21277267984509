import React from 'react';
import PropTypes, { string } from 'prop-types';
import { FaPen } from 'react-icons/fa';
import { Button } from '@panwds/react-ui';
import translate from '../../helpers/translate';
import SpinnerIcon from '../../../assets/images/spinner.gif';
import FilteringProfileDetailsInfo from './FilteringProfileDetailsInfo';


function t(scope, options) {
  return translate(`data_filtering_profiles.${scope}`, options);
}

const FilteringProfileDetails = ({ profile, updateState, actionable, fawkes }) => {

  return(
    <div className='tableDetailsView'>
      <div className='detailHeader'>
        <span className='detailTitle'>
          {profile.name ? profile.name : <img id='loader' src={SpinnerIcon} alt='loading' />}
        </span>
        <Button addClassName='close tw-float-right' data-dismiss='modal' aria-hidden='true' onClick={ () => updateState({viewing: ''}) } />
      </div>
      {
        profile.id &&
        <div>
          <div className="detailContent">
            { actionable.editable &&
              <div className='action-btns'>
                <Button appearance='tertiary' size='lg' onClick={() => updateState({editing: profile.id})} aria-label='edit' icon={<FaPen className='defaultText' />}>
                  {t('edit')}
                </Button>
              </div>
            }

            <p className='detail-subtitle'>{t('details')}</p>
            <FilteringProfileDetailsInfo profile={profile} fawkes={fawkes} />

            <hr />
          </div>
        </div>
      }
    </div>
  )
}

FilteringProfileDetails.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profile_id: PropTypes.number,
    profile_type: PropTypes.string,
    description: PropTypes.string,
    file_type: PropTypes.array,
    action: string,
    log_severity: string
  }),
  updateState: PropTypes.func,
  actionable: PropTypes.shape({
    editable: PropTypes.bool
  }),
  fawkes: PropTypes.bool,
};

FilteringProfileDetails.defaultProps = {
  profile: {},
  actionable: {},
  fawkes: true,
  updateState: undefined,
}

export default FilteringProfileDetails;
