import React from 'react';

import { configure, shallow } from 'enzyme';
import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';
import Adapter from 'enzyme-adapter-react-16';
import MatchingDataPatterns from './MatchingDataPatterns';

jest.mock('../../helpers/translate');

configure({ adapter: new Adapter() });

describe('<MatchingDataPatterns>', () => {
  let wrapper;
  const props = {
    snippets: {
      'pattern1': [{ 'left': '123', 'detection': '***1', 'right': '456' }],
      'pattern2': [{ 'left': null, 'detection': '***2', 'right': 'really long string '.repeat(15) }],
      'pattern3': [{ 'left': 'ghi', 'detection': '***3', 'right': 'jki' }],
      'dataset1': [{ 'left': 'abc', 'detection': '***d', 'right': 'fgh' }],
    },
    highMatches: {
      'pattern2': { 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 },
      'dataset1': { 'name': 'Dataset1', 'high_confidence_frequency': 1, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 1 }
    },
    medMatches: {
      'pattern1': { 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
      'pattern3': { 'name': 'Pattern3', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
    },
    lowMatches: {
      'pattern1': { 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
      'pattern2': { 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 },
      'pattern3': { 'name': 'Pattern3', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
    },
    currentMatches: {
      'pattern2': { 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 }
    },
    confidence: 'high_confidence_frequency'
  };

  it('renders', () => {
    wrapper = shallow(<MatchingDataPatterns {...props} />);
    expect(wrapper).toMatchSnapshot();
  });

  describe('truncateSnippet()', () => {
    it('should return empty string', () => {
      wrapper = shallow(<MatchingDataPatterns {...props} />);
      expect(wrapper.instance().truncateSnippet('abc', -1)).toBe('');
    });

    it('should return original string', () => {
      wrapper = shallow(<MatchingDataPatterns {...props} />);
      expect(wrapper.instance().truncateSnippet('abc', 4)).toBe('abc');
    });

    it('should return truncated string', () => {
      wrapper = shallow(<MatchingDataPatterns {...props} />);
      expect(wrapper.instance().truncateSnippet('abcdefgh', 4)).toBe('abcd ...');
    });

  });

  describe('minSnippetView', () => {
    it('should render truncated snippets', () => {
      const detections = [
        {
          left: 'abcdefgh',
          detection: '12345678',
          right: 'lmnopqrstuv'
        }
      ];
      wrapper = shallow(<MatchingDataPatterns {...props} />);
      const views = wrapper.instance().minSnippetView(detections);
      expect(views.length).toBe(1);
    });

    it('should handle null snippets', () => {
      const detections = [
        {
          left: null,
          detection: '12345678',
          right: 'abcdef'
        }
      ];
      wrapper = shallow(<MatchingDataPatterns {...props} />);
      const views = wrapper.instance().minSnippetView(detections);
      expect(views).toMatchSnapshot();
    });

    it('should prioritize detection with long left field', () => {
      const detections = [
        {
          left: 'really long string '.repeat(15),
          detection: '12345678',
          right: 'abcdef'
        }
      ];
      wrapper = shallow(<MatchingDataPatterns {...props} />);
      const views = wrapper.instance().minSnippetView(detections);
      expect(views).toMatchSnapshot();
    });
  });

  describe('maxSnippetView', () => {
    it('should render original snippets', () => {
      const detections = [
        {
          left: 'abcdefgh',
          detection: '12345678',
          right: 'lmnopqrstuv'
        }
      ];
      wrapper = shallow(<MatchingDataPatterns {...props} />);
      const views = shallow(wrapper.instance().maxSnippetView(detections));
      expect(views.find('span.highlight').text()).toEqual('12345678');
    });
  });
});

describe('<MatchingDataPatterns> with snippets requested', () => {
  let response;
  const mock = new MockAdapter(axios);
  let wrapper;
  const props = {
    highMatches: {
      'pattern2': { 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 }
    },
    medMatches: {
      'pattern1': { 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
      'pattern3': { 'name': 'Pattern3', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
    },
    lowMatches: {
      'pattern1': { 'name': 'Pattern1', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 2, 'low_confidence_frequency': 5 },
      'pattern2': { 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 },
      'pattern3': { 'name': 'Pattern3', 'high_confidence_frequency': 0, 'medium_confidence_frequency': 1, 'low_confidence_frequency': 4 }
    },
    currentMatches: {
      'pattern2': { 'name': 'Pattern2', 'high_confidence_frequency': 2, 'medium_confidence_frequency': 0, 'low_confidence_frequency': 5 }
    },
    confidence: 'high_confidence_frequency',
    report_id: '12345'
  };

  beforeEach(() => {
    response = {
      tenant_id: 'tenant',
      report_id: '12345',
      file_sha: 'abc123',
      message: 'test',
      snippets: {
        'pattern1' : [
          { left: 'abc', right: 'def', detection: '***' },
          { left: 'ghi', right: 'jkl', detection: '***' },
        ],
        'pattern2' : [
          { left: '123', right: '456', detection: '***' },
          { left: '789', right: '987', detection: '***' },
        ],
        'pattern3' : [
          { left: '444', right: '888', detection: '***' }
        ]
      }
    };

    mock.onGet('/snippets/12345.json').reply(200, response);
  });

  it('renders loading state', () => {
    wrapper = shallow(<MatchingDataPatterns {...props} />);
    expect(wrapper.state('snippetStatus')).toEqual('loading');
    expect(wrapper.find('#loader')).toHaveLength(1);
    expect(wrapper).toMatchSnapshot();
  });
});