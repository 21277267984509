// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<AggregationCard/> should render 1`] = `
<div
  className="aggregationCard"
>
  <div
    className="reactTable"
  >
    <div
      className="title card-title"
    >
      <span>
        incidents.index.aggregations.test-title
      </span>
      <span
        className="info"
      >
        <span>
          <FaInfoCircle
            className="muteText small"
            data-class="aggregation-tooltip"
            data-effect="solid"
            data-tip="incidents.index.aggregations.info + {\\"date\\":\\"June 27, 2021, 3:07 AM PDT\\"}"
          />
          <ReactTooltip
            clickable={false}
            insecure={true}
            resizeHide={true}
            wrapper="div"
          />
        </span>
      </span>
    </div>
    <table
      className="table table-borderless table-hover"
    >
      <thead>
        <tr>
          <th>
            incidents.index.aggregations.test-column
          </th>
          <th>
            incidents.index.aggregations.sensitive_files
            <FaArrowDown />
          </th>
          <th>
            incidents.index.aggregations.incidents
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          className="selected"
          key="item1"
        >
          <td>
            item1
          </td>
          <td>
            file1, file2
          </td>
          <td>
            <Popup
              arrow={true}
              arrowStyle={Object {}}
              className=""
              closeOnDocumentClick={true}
              closeOnEscape={true}
              contentStyle={Object {}}
              defaultOpen={false}
              disabled={false}
              keepTooltipInside={false}
              lockScroll={false}
              modal={false}
              mouseEnterDelay={100}
              mouseLeaveDelay={100}
              offsetX={0}
              offsetY={0}
              on="hover"
              onClose={[Function]}
              onOpen={[Function]}
              open={false}
              overlayStyle={Object {}}
              position="top center"
              repositionOnResize={true}
              trigger={
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  3
                </span>
              }
            >
              incidents.index.aggregations.filter_by + {"name":"item1"}
            </Popup>
            <FaTimes
              className="removeAggFilter"
              onClick={[Function]}
            />
          </td>
        </tr>
        <tr
          className=""
          key="item2"
        >
          <td>
            item2
          </td>
          <td>
            file3, file4
          </td>
          <td>
            <Popup
              arrow={true}
              arrowStyle={Object {}}
              className=""
              closeOnDocumentClick={true}
              closeOnEscape={true}
              contentStyle={Object {}}
              defaultOpen={false}
              disabled={false}
              keepTooltipInside={false}
              lockScroll={false}
              modal={false}
              mouseEnterDelay={100}
              mouseLeaveDelay={100}
              offsetX={0}
              offsetY={0}
              on="hover"
              onClose={[Function]}
              onOpen={[Function]}
              open={false}
              overlayStyle={Object {}}
              position="top center"
              repositionOnResize={true}
              trigger={
                <span
                  className="btn-link"
                  onClick={[Function]}
                >
                  4
                </span>
              }
            >
              incidents.index.aggregations.filter_by + {"name":"item2"}
            </Popup>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
`;
