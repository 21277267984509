// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<ReportCard> does not show download icon if not MATCHED 1`] = `
<div
  className="reportDetails tw-float-left"
>
  <div
    className="card-title reportHeader"
  >
    reports.index.general
  </div>
  <div
    className="details"
  >
    <table
      className="table table-borderless table-condensed"
    >
      <tbody>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.report_id
          </td>
          <td>
            12345
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.scan_date
          </td>
          <td>
            July 9 2020 at 1:56 PDT
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.data_profile
          </td>
          <td>
            Bank Account Number North American
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset
          </td>
          <td>
            <span>
              Test Asset
            </span>
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset_type
          </td>
          <td />
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset_size
          </td>
          <td>
            45 KB
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
`;

exports[`<ReportCard> does not show download icon if read only 1`] = `
<div
  className="reportDetails tw-float-left"
>
  <div
    className="card-title reportHeader"
  >
    reports.index.general
  </div>
  <div
    className="details"
  >
    <table
      className="table table-borderless table-condensed"
    >
      <tbody>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.report_id
          </td>
          <td>
            12345
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.scan_date
          </td>
          <td>
            July 9 2020 at 1:56 PDT
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.data_profile
          </td>
          <td>
            Bank Account Number North American
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset
          </td>
          <td>
            <span>
              Test Asset
            </span>
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset_type
          </td>
          <td />
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset_size
          </td>
          <td>
            45 KB
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
`;

exports[`<ReportCard> renders 1`] = `
<div
  className="reportDetails tw-float-left"
>
  <div
    className="card-title reportHeader"
  >
    reports.index.general
  </div>
  <div
    className="details"
  >
    <table
      className="table table-borderless table-condensed"
    >
      <tbody>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.report_id
          </td>
          <td>
            12345
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.scan_date
          </td>
          <td>
            July 9 2020 at 1:56 PDT
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.data_profile
          </td>
          <td>
            Bank Account Number North American
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset
          </td>
          <td>
            <span>
              Test Asset
            </span>
            <FaFileDownload
              className="btn-link downloadIcon"
              onClick={[Function]}
            />
          </td>
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset_type
          </td>
          <td />
        </tr>
        <tr>
          <td
            className="detailCategory"
          >
            reports.index.asset_size
          </td>
          <td>
            45 KB
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
`;
