import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { IntlProvider } from 'react-intl';
import { FaCalendar, FaGlobeAmericas, FaSearch } from 'react-icons/fa';
import translate from '../../helpers/translate';
import BulkTable from '../BulkTable/BulkTable';
import AggregationCard from './AggregationCard';
import DropDownList from '../DropDown/DropDownList';
import DataProfileDetails from '../DataProfiles/DataProfileDetails';
import scanDateOptions from './scanDateOptions';
import SpinnerIcon from '../../../assets/images/spinner.gif';
import { transformIncidentData } from './incidentDataTransformer';
import getColumns from './columns';
import BulkActions from './BulkActions';

import {
  FILENAME,
  REPORT_ID,
  USER_ID,
  ASC,
  INCIDENTS_COL_CREATED_AT,
  INCIDENTS_COL_FEEDBACK_STATUS,
  INCIDENTS_COL_REPORT_ID,
  ENVIRONMENTS
} from '../constants';

function t(scope, options) {
  return translate(`incidents.index.${scope}`, options);
}

class IncidentsTable extends React.Component {
  defaultIncidentRegion = (environment, regionCodes) => {
    if (environment === ENVIRONMENTS.GOV_PRODUCTION) {
      return 'us';
    }
    const preset = localStorage.getItem('dlpIncidentsRegion');
    if (preset && (regionCodes || []).includes(preset)) {
      return preset;
    }
    return 'us';
  };

  state = {
    data: [],
    pageData: {},
    filters: {
      currentFilters: [],
      currentFilterSelections: {}
    },
    searchType: FILENAME,
    aggregationData: {},
    globalFilters: {
      scanDateRange: 'past_90_days',
      region: 'us',
    },
    sortBy: INCIDENTS_COL_CREATED_AT,
    sortOrder: ASC,
    selectedItems: [],
    assignees: [],
    loading: true
  };

  fetchAssignees = async() => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const response = await axios.get(`${this.props.assigneeLookupURL}?region=${this.state.globalFilters.region}`);
    this.setState({assignees: response.data});
  }

  saveAssignee = async(user) => {
    const {assignees} = this.state;

    const payload = {
      id: user.id,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.emailAddress,
      status: user.status,
      created_at: user.createdAt
    }
    const response = await axios.put(`${this.props.assigneeLookupURL}?region=${this.state.globalFilters.region}`, payload);
    if (response.status === 200) {
      const index = assignees.findIndex(a => a.id === user.id);
      const newAssignees = [...assignees];
      if (user.id) {
        newAssignees.splice(index, 1, response.data);
      } else {
        newAssignees.push(response.data);
      }
      this.setState({assignees: newAssignees});
      return true;
    }

    return false;
  }

  deleteAssignee = async(user) => {
    const {assignees} = this.state;
    const response = await axios.delete(`${this.props.assigneeLookupURL}?region=${this.state.globalFilters.region}`, {data: user});
    if  (response.status === 204 || response.status === 200) {
      user.status = 'INACTIVE';
      const newAssignees = [...assignees];
      this.setState({assignees: newAssignees});
      return true;
    }
    return false;
  }

  incidentsUpdated = () => {
    this.setState(prevState => ({
      data: [...prevState.data],
    }));
  }

  componentDidMount = async() => {
    const regionCodes = ['us', 'ap', 'ap-southeast-2', 'eu', 'ap-south-1', 'eu-west-2'];
    if (this.props.caJpFlag) {
      regionCodes.splice(3, 0, 'ca-central-1');
      regionCodes.splice(6, 0, 'ap-northeast-1');
    }

    this.regions = regionCodes.map(reg => {
        return {
          value: reg,
          label: translate(`incidents.index.dlp_regions.${reg}`)
        }
      });
    this.setState(prevState => ({globalFilters: { ...prevState.globalFilters, region: this.defaultIncidentRegion(this.props.environment, regionCodes) }}));
    await this.fetchAssignees();
    this.requestResults(0, 10, this.state.globalFilters);
  };

  outdatedLabel = (dpVersion, incidentDpVersion) => {
    if (dpVersion && (dpVersion !== incidentDpVersion)) {
      return (
        <span className='oldLabel'>Old</span>
      )
    }
    return '';
  };

  requestResults = (
    pageNum,
    numPerPage,
    globalFilters=this.state.globalFilters,
    filterSelections=this.state.filters.currentFilterSelections,
    sortBy=this.state.sortBy,
    sortOrder=this.state.sortOrder,
  ) => {
    this.setState({ loading: true });

    if (this.state.searchValue) {
      filterSelections = { ...filterSelections, [this.state.searchType]: [{ id: this.state.searchValue }] };
    }

    const nonEmptyFilters = {};

    Object.keys(filterSelections).forEach((key) => {
      if (filterSelections[key].length) {
        nonEmptyFilters[key] = filterSelections[key];
      }
    });

    const filterDefinition = Object.keys(nonEmptyFilters).length? { filters: filterSelections } : undefined;

    return (
      axios.post(
        `${this.props.lookupURL}?ascending=${sortOrder !== ASC}&sort_by=${sortBy}&page_number=${pageNum}&page_size=${numPerPage || 10}&timestamp_unit=${globalFilters.scanDateRange}&region=${globalFilters.region}`,
        filterDefinition
      )
      .then(response => {
        const data = transformIncidentData(
          response?.data?.incidents,
          response?.data?.aggregation,
          response?.data?.profiles,
          response?.data?.datasets,
          response?.data?.patterns,
          this.state.globalFilters.region
        );

        this.setState(prevState => ({
          data: data.incidents,
          filters: {...data.filters, currentFilters: prevState.filters.currentFilters, currentFilterSelections: prevState.filters.currentFilterSelections},
          pageData: data.pageData || {},
          aggregationData: data.aggregationData || {},
          loading: false
        }));
      })
      .catch(() => {
        this.setState({ data: [], loading: false });
      })
    );
  }

  incidentsHeader = () => {
    return (
      <div className='header main'>
        <div className='breadcrumb'>
          <span className='btn-link'><a href='/'>{translate('application.dlp')}</a></span>
          <span className='fa fa-chevron-right separator' />
          {t('title')}
        </div>
        <div className='title'>{t('title')}</div>
      </div>
    )
  }

  updateState = (newState, requestNewResults = false) => {
    if (newState.viewing) {
      this.setState(prevState => ({
        viewing: prevState.data.find(i => i.id === newState.viewing).dataProfile
      }));
    }
    else {
      this.setState(newState);
    }
    if (requestNewResults) {
      this.requestResults(
        0,
        this.state.pageData.size,
        this.state.globalFilters,
        this.state.currentFilterSelections
      );
    }
  }

  handleSubmit = (event) => {
    if (event.key === 'Enter') {
      this.requestResults(0, this.state.pageData.size);
    }
  };

  updateFilters = (newFiltersList, newFilterSelections, requestNewResults = false) => {
    const updatedFilters = { currentFilters: newFiltersList, currentFilterSelections: newFilterSelections };
    this.setState(prevState => ({
      filters: { ...prevState.filters, ...updatedFilters }
    }));
    if (requestNewResults) {
      this.requestResults(0, this.state.pageData.size, this.state.globalFilters, newFilterSelections)
    }
  }

  updateGlobalFilter = (newGlobalFilters) => {
    if (this.props.environment !== ENVIRONMENTS.GOV_PRODUCTION) {
      localStorage.setItem('dlpIncidentsRegion', newGlobalFilters.globalFilters.region);
      this.setState(newGlobalFilters);
      this.requestResults(0, this.state.pageData.size, newGlobalFilters.globalFilters, this.state.currentFilterSelections);
    }
  }

  inputSearchOptions = () => {
    return([
      {
        value: FILENAME,
        label: t('columns.file_name')
      },
      {
        value: REPORT_ID,
        label: t('columns.report_id')
      },
      {
        value: USER_ID,
        label: t('columns.user_id')
      }
    ])
  }

  handleUpdateSortControls = ({ sortBy=INCIDENTS_COL_CREATED_AT, sortOrder=ASC }) => {
    this.setState({
      sortBy,
      sortOrder
    }, () => {
      this.requestResults(0, this.state.pageData.size);
    });
  }

  render() {
    const { data, pageData, loading, filters, aggregationData, selectedItems, assignees, sortBy, sortOrder } = this.state;
    const columns = getColumns({
      fawkes: this.props.fawkes,
      portalType: this.props.portalType,
      basePath: this.props.basePath,
      assignees
    });
    const selectedIncidents = selectedItems.map(id => data.find(incident => incident.id === id));

    if (this.props.caJpFlag) {
      $('.banner-area').html(
        `<div class='infoBanner'>
          <span class="fa fa-info-circle flash-icon"></span>
          <span class="infoMessage">New dedicated regions of Japan and Canada are now available for DLP processing and incidents. View incidents by selecting those regions in the “Region” filter.</span>
          <span class="tw-float-right close"></span>
        </div>`);
        $('.banner-area span.close').on('click', () => $('.banner-area').html(''));
    }

    return (
      <IntlProvider locale='en'>
      <div id='incidentsPage'>
        {this.incidentsHeader()}


        {loading ?
          <div className='header'>
            <img id='loader' src={SpinnerIcon} alt='loading' />
          </div>
          :
          <div className='header'>
            <DropDownList
              onSelect={newScanDate => this.updateGlobalFilter({globalFilters: { scanDateRange: newScanDate, region: this.state.globalFilters.region }})}
              value={this.state.globalFilters.scanDateRange}
              items={scanDateOptions}
              dropClass='scanDateSelection'
              preLabel={<span className='globalFilterLabel'><FaCalendar className='mr5' />{t('scan_date')}</span>}
            />
            {this.props.environment !== ENVIRONMENTS.GOV_PRODUCTION &&
              <DropDownList
                onSelect={region => this.updateGlobalFilter({globalFilters: { scanDateRange: this.state.globalFilters.scanDateRange, region }})}
                value={this.state.globalFilters.region}
                items={this.regions}
                dropClass='regionSelection'
                preLabel={<span className='globalFilterLabel'><FaGlobeAmericas className='mr5' />{t('region')}</span>}
              />
            }
          </div>
        }
        <div className='aggregation'>
          <AggregationCard type='dataProfile' title='top_data_profiles' columnLabel='data_profile' data={aggregationData.dataProfileId} updateFilters={this.updateFilters} filters={filters} timestamp={aggregationData.lastAggregationTimestamp} />
          <AggregationCard type='source' title='top_sources' columnLabel='source' data={aggregationData.source} updateFilters={this.updateFilters} filters={filters} timestamp={aggregationData.lastAggregationTimestamp} />
          <AggregationCard type='action' title='files_by_action' columnLabel='action' data={aggregationData.action} updateFilters={this.updateFilters} filters={filters} timestamp={aggregationData.lastAggregationTimestamp} />
        </div>
        <div className='reactTable incidentsTable'>
          <div className="title card-title">
            <span>
              {t('title')}
              <span className='count'>{`(${pageData.total_effective_elements || 0})`}</span>
              <span className='subText'>{t('updated_real_time')}</span>
            </span>
            <span className='tw-float-right actionItems'>
              <DropDownList
                onSelect={searchType => this.setState({searchType, searchValue: ''})}
                value={this.state.searchType || null}
                items={this.inputSearchOptions()}
                dropClass='fileReportFilter'
                placeholder={t('filter_by')}
              />
              <span className='rightInnerAddOn inputSearch'>
                <input
                  value={this.state.searchValue}
                  onKeyPress={this.handleSubmit}
                  onChange={(event) => this.updateState({ searchValue: event.target.value })}
                  placeholder={t(`search_by.${this.state.searchType}`)}
                  className='form-control'
                />
                <i><FaSearch /></i>
              </span>
            </span>
          </div>
          <BulkTable
            data={data}
            defaultSortBy={sortBy}
            defaultSortOrder={sortOrder}
            columns={columns}
            pageData={
              {
                pageCount: pageData.size,
                currentPage: pageData.number,
                totalCount: pageData.total_effective_elements || data.length,
                totalPages: pageData.total_pages
              }
            }
            requestResults={this.requestResults}
            updateState={this.updateState}
            updateSortControls={this.handleUpdateSortControls}
            updateFilters={this.updateFilters}
            selectable
            selectedItems={this.state.selectedItems}
            sortable
            sortExclusionList={[INCIDENTS_COL_REPORT_ID, INCIDENTS_COL_FEEDBACK_STATUS]}
            loading={loading}
            filters={filters}
            useRouter={!!this.props.history}
            bulkActionsComponent={
              this.props.userRole === 'READ_WRITE' ?
                <BulkActions
                  selectedItems={selectedIncidents}
                  assignees={assignees}
                  saveAssignee={this.saveAssignee}
                  deleteAssignee={this.deleteAssignee}
                  incidentsUpdated={this.incidentsUpdated}
                  globalStatusLookupURL={`${this.props.globalStatusLookupURL}?region=${this.state.globalFilters.region}`}
                  tenantStatusLookupURL={`${this.props.tenantStatusLookupURL}?region=${this.state.globalFilters.region}`}
                  incidentUpdateURL={`${this.props.incidentUpdateURL}?region=${this.state.globalFilters.region}`}
                />
                : <></>
            }
          />
          {this.state.viewing && <div className='data_profiles'><DataProfileDetails profile={this.state.viewing} updateState={this.updateState} history={this.props.history} /></div>}
        </div>
      </div>
      </IntlProvider>
    );
  }
}

IncidentsTable.propTypes = {
  lookupURL: PropTypes.string,
  tenantStatusLookupURL: PropTypes.string.isRequired,
  globalStatusLookupURL: PropTypes.string.isRequired,
  incidentUpdateURL: PropTypes.string.isRequired,
  assigneeLookupURL: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  history: PropTypes.any,
  userRole: PropTypes.string,
  fawkes: PropTypes.bool,
  basePath: PropTypes.string,
  portalType: PropTypes.string,
  caJpFlag: PropTypes.bool,
};

IncidentsTable.defaultProps = {
  lookupURL: undefined,
  history: undefined,
  userRole: 'READ_ONLY',
  fawkes: false,
  basePath: '/manage/security-services',
  portalType: undefined,
  caJpFlag: false,
}

export default IncidentsTable;
