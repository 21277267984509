import React from "react";
import '@testing-library/react/dont-cleanup-after-each';
import { fireEvent } from '@testing-library/react';
import '@testing-library/jest-dom';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import renderWithRouter from "../../helpers/setupTests";
import CustomPatternForm from "./CustomPatternForm";


describe('<CustomPatternForm/>', () => {
  let testUtils;
  let axiosMock;
  const pattern = {
    "detection_technique": "regex",
    "status": "active",
    "type": "custom",
    "delimiter": ";",
    "name": "DP1",
    "description": "",
    "regexes": [
    ],
    "proximity_keywords": ['visa', 'ccn']
  };

  beforeEach(() => {
    axiosMock = new MockAdapter(axios);
    axiosMock.onPost().reply(200, {});
  })


  test('Render the form', () => {
    testUtils = renderWithRouter(
      <CustomPatternForm dataPattern={pattern} lookupURL='/data-patterns/'/>);
    expect(testUtils.getByText('Add Custom Data Pattern')).toBeInTheDocument();
  });

  test('Basic validation works', () => {
    const saveButton = testUtils.container.querySelector('button.save');
    fireEvent.click(saveButton);
    const errorElements = testUtils.container.querySelectorAll('.error');
    expect(errorElements.length).toBeGreaterThan(0);
  });

  test('remove keywords', () => {
    const keywordsInput = testUtils.container.querySelector('.key-words-input');
    keywordsInput.focus();
    fireEvent.change(keywordsInput, {target: {value: ''}});
    const regexInput = testUtils.container.querySelector('.regex-input');
    regexInput.focus();
    fireEvent.change(regexInput, {target: {value: '[a-z0-9]9'}});
    const saveButton = testUtils.container.querySelector('button.save');
    saveButton.focus();
    fireEvent.click(saveButton);
    expect(JSON.parse(axiosMock.history.post[0].data).proximity_keywords).toEqual([]);
  });

  test('"Save" calls API', () => {
    const nameInput = testUtils.container.querySelector('input.data-pattern-name');
    fireEvent.focus(nameInput);
    fireEvent.input(nameInput, { target: { value: 'test-data-pattern' } });
    const regexInput = testUtils.container.querySelector('.regex-input');
    regexInput.focus();
    fireEvent.change(regexInput, {target: {value: '[a-z0-9]9'}});
    const keywordsInput = testUtils.container.querySelector('.key-words-input');
    keywordsInput.focus();
    fireEvent.change(keywordsInput, {target: {value: 'credit, card'}});
    const saveButton = testUtils.container.querySelector('button.save');
    saveButton.focus();
    fireEvent.click(saveButton);
    expect(axiosMock.history.post.length).toBe(1);
    expect(JSON.parse(axiosMock.history.post[0].data).proximity_keywords).toEqual(['credit', 'card']);
  });
});