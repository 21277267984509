import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import BreadcrumbHeader from './BreadcrumbHeader';

const DataProfileLookup = ({dataProfileId, history}) => {
  const[profile, setProfile] = useState(null);

  useEffect(() => {
    axios.get(`/v1/dlp-ui/data-profile/${dataProfileId}`).then(
      result => {
        const profileResponse = result.data;

        if (profileResponse.detection_rules && profileResponse.detection_rules.length > 0) {
          if (profileResponse.detection_rules[0].rule_type === 'expression_tree') {
            profileResponse.profileType = 'edm';
          }
          else if (profileResponse.detection_rules[0].rule_type === 'multi_profile' || Array.isArray(profileResponse.detection_rules[0])) {
            profileResponse.profileType = 'multi';
            profileResponse.multiProfile = true;
          }
        }
        setProfile(profileResponse);
      }
    )
  }, []);

  useEffect(() => {
    if (profile) {
      history.push(history.location.pathname, {
        dataProfile: profile
      });
    }
  }, [ profile ]);

  return (
    <div>
      <BreadcrumbHeader dataProfile={!!dataProfileId} clone={false} />
    </div>
  );
}

DataProfileLookup.propTypes = {
  dataProfileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    })
  }),
};

DataProfileLookup.defaultProps = {
  dataProfileId: undefined,
  history: undefined
}
export default DataProfileLookup
